/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { FlexedDiv, RegulartTooltipTemplate, Tooltip } from '../../../components';
import { IcoMoon } from '../../../icons';
import {
    DateColumnItem,
    InvestorColumnItem,
    TotalInvestmentColumnItem,
    TransactionNumberColumnItem,
} from '../../../templates';

const CustomItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const Remarks = () => {
        const tooltipItems: Array<ITooltip> = [];
        data && data.remarks.length !== 0
            ? data.remarks.map((item: { title: string; content: string[] }) => {
                  const tempObject = {
                      label: '',
                      content: [''],
                  };

                  tempObject.label = item.title;
                  tempObject.content = item.content;
                  tooltipItems.push(tempObject);
              })
            : null;
        return (
            <Fragment>
                {data.remarks.length !== 0 ? (
                    <Tooltip
                        tooTipContent={<RegulartTooltipTemplate message={tooltipItems} />}
                        style={{ padding: '1rem' }}
                        data-testid="customTip"
                    >
                        <div>
                            <IcoMoon name="warning" color="#E89700" size="1.667vw" />
                        </div>
                    </Tooltip>
                ) : (
                    <div>--</div>
                )}
            </Fragment>
        );
    };
    const renderItems = (key: string) => {
        switch (key) {
            case 'remarks':
                return <Remarks />;
            case 'totalInvestment':
                return <TotalInvestmentColumnItem isSortedColumn={isSortedColumn} data={data} itemKey={itemKey} />;
            case 'totalUnits':
                return (
                    <FlexedDiv
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-end"
                        flex={1}
                        style={{ fontWeight: isSortedColumn === 'totalUnits' ? 700 : 400 }}
                    >
                        {data.totalUnits || '-'}
                    </FlexedDiv>
                );
            case 'lastUpdated':
            case 'createdOn':
            case 'submittedOn':
            case 'targetDate':
                return <DateColumnItem data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />;
            case 'orderNo':
                return <TransactionNumberColumnItem data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />;
        }
    };
    return (
        <Fragment>
            {itemKey.length === 1 ? (
                renderItems(itemKey[0].key)
            ) : (
                <Fragment>
                    {itemKey[0].key === 'clientName' ? (
                        <InvestorColumnItem isSortedColumn={isSortedColumn} data={data} itemKey={itemKey} />
                    ) : null}
                </Fragment>
            )}
        </Fragment>
    );
};
export default CustomItem;
