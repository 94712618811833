import React, { useState, FunctionComponent, useEffect } from 'react';
import { SYSTEM_ADMIN } from '../../../../../constants/labels';
import { FlexedDiv, CustomSpacer } from '../../../../../components';
import FlexSpacer from '../../../../../components/atoms/FlexSpacer';
import { IcoMoon } from '../../../../../icons';
import {
    ILoginRoleSection,
    ICustomerExperienceSection,
    IUserManagementSection,
    IActivityLogsSection,
    IBranchOfficeSection,
    IEDDCasesSection,
    IFinanceDashboardSection,
    IFinanceCasesSection,
    IOperationDashboard,
    IOperationCases,
    customerExperienceInitialData,
    branchOfficesInitialData,
    productSettingsInitialData,
    fundUploadsInitialData,
    eddCaseInitialData,
    ISystemSettingsSection,
    IHQRoleProductSettings,
    IHQRoleFundUploads,
    IReportsSection,
    reportsInitialData,
} from '../../AddRoles/HqPrivileges/hqRolesInitialData';
import { LoginPrivileges } from './LoginPrivileges';
import { CustomerPrivileges } from './CustomerPrivileges';
import { UserPrivileges } from './UserPrivileges';
import { EddPrivileges } from './EddPrivileges';
import { FundPrivileges } from './FundPrivileges';
import { OperationPrivileges } from './OperationPrivileges';
import { TextDarkGrey6, TextNavyBlue } from '../../../../../constants';
import { CollapseLabel } from '../BranchPrivileges';
import styled, { css } from 'styled-components';
import { setInitialData } from '../../utils/functions';
import { ReportPrivileges } from './ReportPrivileges';

interface ISectionRowCollapsible {
    title: string;
    actions: string[];
    reviewApproval?: string[];
}

export interface RoleCollapsible {
    header?: string;
    title: string;
    subtitle?: string;
    permission: boolean;
    branchData: ISectionRowCollapsible[];
}

export interface HqPrivilegesProps {
    activityLogs: IActivityLogsSection;
    approval: boolean;
    branchOffices: IBranchOfficeSection;
    customerExpDashboard: ICustomerExperienceSection;
    eddCase: IEDDCasesSection;
    financeCases: IFinanceCasesSection;
    financeDashboard: IFinanceDashboardSection;
    fundUploads: IHQRoleFundUploads;
    hqLoginAccess: ILoginRoleSection;
    operationCases: IOperationCases;
    operationDashBoard: IOperationDashboard;
    productSettings: IHQRoleProductSettings;
    reports: IReportsSection;
    setActivityLogs: (data: IActivityLogsSection) => void;
    setBranchOffices: (data: IBranchOfficeSection) => void;
    setCustomerExpDashboard: (data: ICustomerExperienceSection) => void;
    setEddCase: (data: IEDDCasesSection) => void;
    setFinanceCases: (data: IFinanceCasesSection) => void;
    setFinanceDashboard: (data: IFinanceDashboardSection) => void;
    setFundUploads: (data: IHQRoleFundUploads) => void;
    setHqLoginAccess: (data: ILoginRoleSection) => void;
    setOperationCases: (data: IOperationCases) => void;
    setOperationDashBoard: (data: IOperationDashboard) => void;
    setProductSettings: (data: IHQRoleProductSettings) => void;
    setReports: (data: IReportsSection) => void;
    setSystemSettings: (data: ISystemSettingsSection) => void;
    setUserManagement: (data: IUserManagementSection) => void;
    systemSettings: ISystemSettingsSection;
    userManagement: IUserManagementSection;
}

export const HqPrivileges: FunctionComponent<HqPrivilegesProps> = ({
    activityLogs,
    approval,
    branchOffices,
    customerExpDashboard,
    eddCase,
    financeCases,
    financeDashboard,
    fundUploads,
    hqLoginAccess,
    operationCases,
    operationDashBoard,
    productSettings,
    reports,
    setActivityLogs,
    setBranchOffices,
    setCustomerExpDashboard,
    setEddCase,
    setFinanceCases,
    setFinanceDashboard,
    setFundUploads,
    setHqLoginAccess,
    setOperationCases,
    setOperationDashBoard,
    setProductSettings,
    setReports,
    setSystemSettings,
    setUserManagement,
    systemSettings,
    userManagement,
}: HqPrivilegesProps) => {
    const [expandArray, setExpandArray] = useState<boolean[]>([
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ]);
    const [systemAdmin, setSystemAdmin] = useState<boolean>(false);

    const handleExpandAll = () => {
        if (approval === false) {
            const toggle = expandArray.filter((element) => element === true).length === 10 ? false : true;
            const updatedArray = [...expandArray];
            updatedArray.fill(toggle);
            setExpandArray(updatedArray);
        }
    };

    const handleExpandCollapsible = (index: number) => {
        const tempArray = [...expandArray];
        tempArray[index] = !tempArray[index];
        setExpandArray(tempArray);
    };

    const setSystemAdminData = (
        data: IUserManagementSection | IActivityLogsSection | ISystemSettingsSection,
        module: string,
    ) => {
        if (
            (module === 'userManagement' &&
                userManagement.isAll === true &&
                activityLogs.isAll === false &&
                systemSettings.isAll === false) ||
            (module === 'activityLogs' &&
                userManagement.isAll === false &&
                activityLogs.isAll === true &&
                systemSettings.isAll === false) ||
            (module === 'systemSettings' &&
                userManagement.isAll === false &&
                activityLogs.isAll === false &&
                systemSettings.isAll === true)
        ) {
            setSystemAdmin(false);
            if (module === 'activityLogs') {
                setActivityLogs(data as IActivityLogsSection);
            } else if (module === 'systemSettings') {
                setSystemSettings(data as ISystemSettingsSection);
            } else {
                setUserManagement(data as IUserManagementSection);
            }
        } else {
            const newHqCustomerExpDashboard = setInitialData(
                customerExperienceInitialData,
                customerExpDashboard,
                'hq',
                'ceDashboard',
            ) as ICustomerExperienceSection;
            setCustomerExpDashboard(newHqCustomerExpDashboard);
            const newBranchOffices = setInitialData(
                branchOfficesInitialData,
                branchOffices,
                'hq',
                'branchOffices',
            ) as IBranchOfficeSection;
            setBranchOffices(newBranchOffices);

            const newProductSettings = setInitialData(
                productSettingsInitialData,
                productSettings,
                'hq',
                'productSettings',
            ) as IHQRoleProductSettings;
            setProductSettings(newProductSettings);
            const newFundUploads = setInitialData(
                fundUploadsInitialData,
                fundUploads,
                'hq',
                'uploads',
            ) as IHQRoleFundUploads;
            setFundUploads(newFundUploads);
            const newEddManagement = setInitialData(eddCaseInitialData, eddCase, 'hq', 'eddCase') as IEDDCasesSection;
            setEddCase(newEddManagement);
            const newReports = setInitialData(reportsInitialData, reports, 'hq', 'reports') as IReportsSection;
            setReports(newReports);
            setSystemAdmin(true);
            if (module === 'activityLogs') {
                setActivityLogs(data as IActivityLogsSection);
            } else if (module === 'systemSettings') {
                setSystemSettings(data as ISystemSettingsSection);
            } else {
                setUserManagement(data as IUserManagementSection);
            }
        }
    };

    useEffect(() => {
        if (userManagement.isAll === true || activityLogs.isAll === true || systemSettings.isAll === true) {
            setSystemAdmin(true);
        }
    }, [userManagement, activityLogs, systemSettings]);

    const expandLabel =
        expandArray.filter((element) => element === true).length === 10
            ? SYSTEM_ADMIN.ADD_ROLE.LABEL_COLLAPSE_ALL
            : SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPAND_ALL;
    const expandIcon = expandArray.filter((element) => element === true).length === 10 ? 'collapse' : 'expand';

    return (
        <FlexedDiv direction="column" margin="auto" style={{ maxWidth: '1188px' }}>
            <FlexedDiv>
                <FlexSpacer />
                <CollapseLabel onClick={handleExpandAll}>
                    <DisabledView disabled={approval}>
                        <FlexedDiv direction="row" style={{ cursor: 'pointer', alignItems: 'center' }}>
                            <TextNavyBlue size="12px" weight="bold" onClick={handleExpandAll}>
                                {expandLabel}
                            </TextNavyBlue>
                            <CustomSpacer horizontal={true} space={'.25rem'} />
                            <IcoMoon name={expandIcon} size="20px" style={{ alignSelf: 'center' }} />
                        </FlexedDiv>
                    </DisabledView>
                </CollapseLabel>
            </FlexedDiv>
            <TextDarkGrey6 size="12px" weight="700" style={{ lineHeight: '1.5rem' }}>
                {SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_LOGIN}
            </TextDarkGrey6>
            <LoginPrivileges
                approval={approval}
                expanded={expandArray[0]}
                handleExpand={handleExpandCollapsible}
                loginAccess={hqLoginAccess}
                setLoginAccess={setHqLoginAccess}
            />
            <CustomSpacer space={'1rem'} />

            <TextDarkGrey6 size="0.75rem" lineHeight="15.6px" weight={'700'}>
                {SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_MANAGEMENT}
            </TextDarkGrey6>
            <CustomSpacer space={'1rem'} />
            <UserPrivileges
                approval={approval}
                expanded={[expandArray[1], expandArray[2], expandArray[8]]}
                userManagement={userManagement}
                handleExpand={handleExpandCollapsible}
                setUserManagement={setUserManagement}
                activityLogs={activityLogs}
                setActivityLogs={setActivityLogs}
                systemSettings={systemSettings}
                setSystemSettings={setSystemSettings}
                setSystemAdminData={setSystemAdminData}
            />
            <CustomSpacer space={'1.5rem'} />
            <TextDarkGrey6 size="0.75rem" lineHeight="15.6px" weight={'700'}>
                {SYSTEM_ADMIN.ADD_ROLE.LABEL_CUSTOMER_EXP}
            </TextDarkGrey6>
            <CustomSpacer space={'1rem'} />

            <DisabledView disabled={systemAdmin}>
                <CustomerPrivileges
                    approval={approval}
                    expanded={[expandArray[3], expandArray[4]]}
                    customerExpDashboard={customerExpDashboard}
                    handleExpand={handleExpandCollapsible}
                    setCustomerExpDashboard={setCustomerExpDashboard}
                    branchOffices={branchOffices}
                    setBranchOffices={setBranchOffices}
                />
            </DisabledView>
            <CustomSpacer space={'1.5rem'} />
            <TextDarkGrey6 size="0.75rem" lineHeight="15.6px" weight={'700'}>
                {SYSTEM_ADMIN.ADD_ROLE.LABEL_RESOURCES}
            </TextDarkGrey6>
            <CustomSpacer space={'1rem'} />

            <DisabledView disabled={false}>
                <ReportPrivileges
                    approval={approval}
                    expanded={[expandArray[9]]}
                    reports={reports}
                    handleExpand={handleExpandCollapsible}
                    setReports={setReports}
                />
            </DisabledView>
            <CustomSpacer space={'1.5rem'} />
            <TextDarkGrey6 size="0.75rem" lineHeight="15.6px" weight={'700'}>
                {SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD}
            </TextDarkGrey6>
            <CustomSpacer space={'1rem'} />
            <DisabledView disabled={systemAdmin}>
                <EddPrivileges
                    approval={approval}
                    expanded={expandArray[7]}
                    eddCase={eddCase}
                    setEddCase={setEddCase}
                    handleExpand={handleExpandCollapsible}
                />
            </DisabledView>
            <CustomSpacer space={'1.5rem'} />
            {/* <TextDarkGrey6 size="0.75rem" lineHeight="15.6px" weight={'700'}>{SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE}</TextDarkGrey6>
            <CustomSpacer space={'1rem'} />
            <DisabledView disabled>
                <FinancePrivileges
                    approval={approval}
                    expanded={false}
                    financeDashboard={financeDashboard}
                    setFinanceDashboard={setFinanceDashboard}
                    financeCases={financeCases}
                    setFinanceCases={setFinanceCases}
                />
            </DisabledView> */}
            <CustomSpacer space={'1.5rem'} />
            <TextDarkGrey6 size="0.75rem" lineHeight="15.6px" weight={'700'}>
                {SYSTEM_ADMIN.ADD_ROLE.LABEL_FUND_MANAGEMENT}
            </TextDarkGrey6>
            <CustomSpacer space={'1rem'} />

            <DisabledView disabled={systemAdmin}>
                <FundPrivileges
                    approval={approval}
                    expanded={[expandArray[5], expandArray[6]]}
                    handleExpand={handleExpandCollapsible}
                    productSettings={productSettings}
                    setProductSettings={setProductSettings}
                    fundUploads={fundUploads}
                    setFundUploads={setFundUploads}
                />
            </DisabledView>

            <CustomSpacer space={'1.5rem'} />
            <TextDarkGrey6 size="0.75rem" lineHeight="15.6px" weight={'700'}>
                {SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATION}
            </TextDarkGrey6>
            <CustomSpacer space={'1rem'} />
            <DisabledView disabled>
                <OperationPrivileges
                    approval={approval}
                    expanded={false}
                    operationDashboard={operationDashBoard}
                    setOperationDashboard={setOperationDashBoard}
                    operationCases={operationCases}
                    setOperationCases={setOperationCases}
                />
            </DisabledView>
        </FlexedDiv>
    );
};

interface IDisableView {
    disabled: boolean;
}
const DisabledView = styled.div`
    ${(props: IDisableView) =>
        props.disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`;
