/* TO DO 
    Export list is not working
*/
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, Fragment, useEffect, ChangeEvent, useMemo, useRef, useReducer, useContext } from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components';
//Context
import AuthContext from '../../context/AuthContext';
import AdvisersContext from '../../context/AdviserContext/AdvisersContext';
//graphQL
//Components & templates
import { CustomButton, CustomSpacer, ComponentLoader, SelectDropdown } from '../../components';
import { AdvisersTab } from './tabs/AdvisersTab';
import { SearchBar, Dashboard, DatePicker } from '../../components/organisms';
import { SearchOptionsAdvisers } from './searchDropdown';
import { dateSortingDropDownAdvisers } from './filterData';
//Type, utils and constants
import { clearContextOnNavigation } from '../../utils';
import { LABEL, Fs12BoldPrimaryBlack, TextDarkBlack } from '../../constants';
import { useHistory } from 'react-router';

export interface IColumnValue {
    column: string;
    value: string;
}

export interface ISortValues {
    Advisers: IColumnValue[];
}

type FilterState = {
    statusFilter: IColumnValue;
    dateFilter: IColumnValue[];
    dateRangeVal: [Moment | null, Moment | null];
};
type FilterPayload = {
    data: FilterState;
    action: string;
};

const statusOptions = [
    { value: '', label: LABEL.all },
    { value: 'active', label: LABEL.active },
    { value: 'suspended', label: LABEL.suspended },
    { value: 'terminated', label: LABEL.terminated },
];
const adviderRef: IAdviserTab = {
    isAll: '',
    actions: { canExportList: 'maker', canViewAdviserList: 'maker', canViewAdvisersProfile: 'maker' },
};

const initialFilters: FilterState = {
    statusFilter: { column: 'status', value: '' },
    dateFilter: [{ column: 'createdOn', value: '' }],
    dateRangeVal: [null, null],
};

const AdviserDashboard: React.FC = () => {
    const history = useHistory();
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const {
        adviserCount,
        adviserData,
        adviserDataGrouped,
        currentPage,
        loading,
        maxPages,
        resultLimit,
        searchInput,
        tab,
        getDashboardCallBack,
        setCurrentPage,
        setFilters,
        setResultLimit,
        setSearchInput,
        setSortData,
        setTab,
    } = useContext(AdvisersContext);
    // const { branchesId } = useContext(BranchOfficeContext);
    // Error handling
    // const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    // const idTokenHeader =
    //     userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
    //         ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
    //         : undefined;
    //Auth states
    const parsedPermission: IPermissionObject = JSON.parse(userLoginContext.permission);
    const adviserTabPermissionRef = useRef<IAdviserTab>(adviderRef);
    const hqRef = useRef<boolean>(false);

    //Filter reducer
    const handleFilterDispatch = (filterState: FilterState, payload: FilterPayload) => {
        const _temp = { ...filterState };
        switch (payload.action) {
            case 'SET_FILTER':
                return payload.data;
            case 'SET_DATE_RANGE':
                return payload.data;
            case 'SET_RESET_FILTERS':
                return payload.data;
            default:
                return _temp;
        }
    };
    const [filterState, filterDispatch] = useReducer(handleFilterDispatch, initialFilters); //temp filter state
    //UI states
    const [expand, setExpand] = useState<boolean>(false);
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>('');
    const [tabs, setTabs] = useState<IDashboardTabs[]>([]);
    const isFilters = useRef<boolean>(false);

    const checkPermissions = (permissonObj: IPermissionObject) => {
        if (permissonObj.hq.grant === 'hq') {
            hqRef.current = true;
            adviserTabPermissionRef.current = permissonObj.hq.permission.branchOffices.advisersTab;
        } else {
            hqRef.current = false;
            adviserTabPermissionRef.current = permissonObj.branch.permission.adviser.advisersTab;
        }
    };
    const switchTabs = (index: number) => {
        setTab(index);
    };

    const handleNext = () => {
        if (currentPage < maxPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    //Fn to reset filters
    const handleReset = () => {
        const _resetFilters: FilterState = {
            statusFilter: { column: 'status', value: '' },
            dateFilter: [{ column: 'createdOn', value: '' }],
            dateRangeVal: [null, null],
        };

        isFilters.current = false;
        filterDispatch({ data: _resetFilters, action: 'SET_RESET_FILTERS' });
        setExpand(!expand);
        setFilters([]);
    };

    const handleApply = () => {
        let _filters: IColumnValue[] = [];
        if (filterState.dateFilter[0].value !== '') _filters = [...filterState.dateFilter];
        if (filterState.statusFilter.value !== '') _filters.push(filterState.statusFilter);

        setExpand(false);
        setFilters(_filters);
    };
    //Fn to handle date range
    const handleDateRange = (date: [moment.Moment | null, moment.Moment | null]) => {
        const _tempObj = { ...filterState };
        const _dateRangeVal = [...date] as [moment.Moment | null, moment.Moment | null];
        //Check for NaN
        _tempObj.dateFilter[0].value = `${
            !isNaN(moment(_dateRangeVal[0]).valueOf()) ? moment(_dateRangeVal[0]).valueOf() : ''
        }~${!isNaN(moment(_dateRangeVal[1]).valueOf()) ? moment(_dateRangeVal[1]).valueOf() : ''}`;
        _tempObj.dateRangeVal = date;
        isFilters.current = true;
        filterDispatch({ data: _tempObj, action: 'SET_DATE_RANGE' });
    };
    //Fn to handle date range picker validation
    const handleDateValidation = (date: [moment.Moment | null, moment.Moment | null]) => {
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item, '"M/D/YYYY H:mm"').valueOf();
            time.push(milliSeconds);
        });
        const _validFrom = time[0] < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = time[1] < moment().add(1, 'day').startOf('day').valueOf() && time[1] > time[0];
        if (_validFrom && _validTo) {
            // Date range in milliseconds
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            time[1] < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            time[1] < time[0] && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
        }
    };

    //Fn to handle date filter
    const handleDateFilter = (value: string | number) => {
        const _tempObj = { ...filterState };

        const obj: IColumnValue = {
            column: value!.toString(),
            value:
                _tempObj.dateRangeVal[0] !== null
                    ? `${moment(_tempObj.dateRangeVal[0]).valueOf()}~${moment(_tempObj.dateRangeVal[1]).valueOf()}`
                    : '',
        };
        _tempObj.dateFilter[0] = obj;

        isFilters.current = true;
        filterDispatch({ data: _tempObj, action: 'SET_FILTER' });
    };
    //Fn to handle status filter
    const handleStatus = (text: string | number) => {
        const _tempObj = { ...filterState };

        _tempObj.statusFilter.value = text!.toString();
        isFilters.current = true;
        filterDispatch({ data: _tempObj, action: 'SET_FILTER' });
    };
    //Fn to handle export list
    // const handleSecondary = () => {
    //     getExportList();
    // };
    //Fn to call export list query
    // const getExportList = async () => {
    //     const query =
    //         filterState.dateFilter[0].column === 'createdOn'
    //             ? adviserDashboardExportListCreatedOn
    //             : adviserDashboardExportListLastUpdated;
    //     loadingHandler();
    //     try {
    //         const response: any = await API.graphql(
    //             graphqlOperation(query, {
    //                 input: {
    //                     search: {
    //                         column: searchInput.column,
    //                         value: searchInput.value,
    //                     },
    //                     sort: sortData,
    //                     filter: [...filters],
    //                     endpoint: 'adviserdashboard',
    //                     branchId: branchesId !== undefined && branchesId !== '' ? branchesId : '',
    //                 },
    //             }),
    //             idTokenHeader,
    //         );

    //         const resultCheck = response.data.dashboardExportList.data;

    //         // checks if error exists
    //         if (resultCheck.error !== undefined) throw resultCheck.error;
    //         // continue to show results
    //         const { result } = response.data.dashboardExportList.data;

    //         const prefix = branchesId !== undefined && branchesId !== '' ? 'Hq' : 'Branch';
    //         exportList(prefix, 'adviserdashboard', result.adviserdashboard);
    //         loadingHandler();
    //     } catch (error) {
    //         const _error = error as IErrorHandling;
    //         loadingHandler();
    //         if (Object.keys(_error).includes('errorCode')) {
    //             handleErrorHandler();
    //             setErrorMessage({
    //                 ...errorMessage,
    //                 message: _error.message,
    //                 errorCode: _error.errorCode,
    //                 title: 'Cannot Generate Export list',
    //                 testId: 'adviserbranch-modal',
    //             });
    //         } else {
    //             setErrorMessage({
    //                 ...errorMessage,
    //                 message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
    //                 title: 'Something Went Wrong',
    //                 testId: 'gql-error-modal',
    //             });
    //             handleErrorHandler();
    //         }
    //     }
    // };
    /** * end */

    const clearContext = () => {
        const _resetFilters: FilterState = {
            statusFilter: { column: 'status', value: '' },
            dateFilter: [{ column: 'createdOn', value: '' }],
            dateRangeVal: [null, null],
        };
        isFilters.current = false;
        setCurrentPage(1);
        setResultLimit(10);
        setSearchInput({ value: '', column: 'all' });
        setFilters([]);
        setSortData([{ column: 'agentName', value: 'ascending' }]);
        filterDispatch({ data: _resetFilters, action: 'SET_RESET_FILTERS' });
    };

    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        SearchOptionsAdvisers.advisers.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInput({ ...searchInput, column: value }),
                testId: `advisers-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };

    //Fn to generate search placeholder text
    const generatePlaceHolderText = (input: string): string => {
        let _temp = '';
        switch (input) {
            case 'agentName':
                _temp = 'by Adviser Name';
                break;
            case 'agentCode':
                _temp = 'by Adviser Code';
                break;
        }

        return _temp;
    };

    useEffect(() => {
        checkPermissions(parsedPermission);
        return function cleanup() {
            clearContextOnNavigation('advisersDashboard', history.location.pathname) === false ? clearContext() : null;
        };
    }, []);

    useMemo(() => {
        const _tabs = [...tabs];
        if (adviserTabPermissionRef.current?.isAll === 'true') {
            _tabs.push({
                name: 'Advisers',
                table: (
                    <AdvisersTab
                        adviserTab={adviserTabPermissionRef.current}
                        hq={hqRef.current ? hqRef.current : false}
                        testId="advisers-tb"
                    />
                ),
                testId: 'advisers-tab',
            });
        }
        setTabs(_tabs);
    }, [adviserTabPermissionRef.current]);

    useEffect(() => {
        getDashboardCallBack();
    }, [getDashboardCallBack]);

    return (
        <Fragment>
            <SearchBar
                expand={expand}
                setExpand={setExpand}
                placeHolder={`Search Advisers ${generatePlaceHolderText(searchInput.column)}`}
                searchTitle={LABEL.advisers}
                dropDownOptions={searchOptions()}
                // handleSecondary={handleSecondary}
                // secondaryButton={
                //     adviserTabPermissionRef.current?.actions.canExportList
                //         ? [
                //               {
                //                   type: 'secondary',
                //                   title: 'Export List',
                //                   actionIcon: 'export',
                //                   testId: 'advisers-export-btn',
                //               },
                //           ]
                //         : []
                // }
                searchInput={searchInput}
                onSearch={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchInput({ ...searchInput, value: e.currentTarget.value });
                }}
                clearSearchInput={() => {
                    setSearchInput({ ...searchInput, value: '' });
                }}
                testId="advisers"
                filter={true}
            >
                <SearchInner>
                    <TextDarkBlack weight="700">Filter Advisers by</TextDarkBlack>
                    <FromRow>
                        <InputWrap>
                            <SelectDropdown
                                label={LABEL.dateSorting}
                                onChange={handleDateFilter}
                                options={dateSortingDropDownAdvisers}
                                testId={`date-dropdown-filter`}
                                selectedValue={filterState.dateFilter[0].column}
                            />
                        </InputWrap>
                        <InputWrap>
                            <DatePicker
                                setTargetDate={handleDateRange}
                                targetDate={filterState.dateRangeVal}
                                range={true}
                                width="360"
                                primaryBorderColor="#002043"
                                handleDate={handleDateValidation}
                                errorMessage={datePickerInvalidMessage}
                                setErrorMessage={setDatePickerInvalidMessage}
                                label={LABEL.dateRange}
                                disable={
                                    !filterState.dateFilter.find(
                                        (obj: IColumnValue) =>
                                            obj.column === 'lastUpdated' || obj.column === 'createdOn',
                                    )
                                }
                            />
                        </InputWrap>
                    </FromRow>
                    <FromRow>
                        <InputWrap>
                            <SelectDropdown
                                label={LABEL.status}
                                onChange={handleStatus}
                                options={statusOptions}
                                testId={`account-dropdown-filter`}
                                selectedValue={filterState.statusFilter.value}
                            />
                        </InputWrap>
                    </FromRow>
                    <FromRow>
                        <ButtonContainer>
                            <CustomButton
                                onClick={handleReset}
                                style={{ width: '240px', height: '48px' }}
                                id="advisers-reset-btn"
                                disabled={!isFilters.current}
                            >
                                <Fs12BoldPrimaryBlack>{LABEL.reset}</Fs12BoldPrimaryBlack>
                            </CustomButton>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <CustomButton
                                primary={true}
                                onClick={handleApply}
                                style={{ width: '240px', height: '48px' }}
                                id="advisers-apply-btn"
                                disabled={!isFilters.current}
                            >
                                {LABEL.apply}
                            </CustomButton>
                        </ButtonContainer>
                    </FromRow>
                </SearchInner>
            </SearchBar>
            <TabWrapper>
                <Dashboard
                    tabs={tabs}
                    page={currentPage}
                    maxPage={maxPages}
                    selectedTab={tab}
                    setResultLimit={setResultLimit}
                    orderCounter={[adviserCount]}
                    switchTabs={switchTabs}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    testId="advisers"
                    setCurrentPage={setCurrentPage}
                    resultLimit={resultLimit}
                    disableResultLimit={hqRef.current ? adviserDataGrouped.length === 0 : adviserData.length === 0}
                />
                {loading ? <ComponentLoader /> : null}
            </TabWrapper>
        </Fragment>
    );
};

export default AdviserDashboard;

const TabWrapper = styled.div`
    position: relative;
`;

const SearchInner = styled.div`
    padding: 1rem 0px;
`;
const FromRow = styled.div`
    display: flex;
    margin: 1rem 0px;
`;
const InputWrap = styled.div`
    width: 100%;
    max-width: 360px;
    margin-right: 1rem;
`;
const ButtonContainer = styled.div`
    display: flex;
    margin: 0 auto;
    padding: 25px 0px;
`;
