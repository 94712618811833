import { LABEL } from '../../../constants';

export const downloadModalContent = (type: string, length: number): string => {
    switch (type) {
        case LABEL.dailySummaryReportFormA:
            return LABEL.downloadDailySummaryReport;
        case LABEL.bulkVerify:
            return `${length} ${LABEL.verifyBulkTransactionsMessage}`;
        case LABEL.transactionsReport:
            return length > 1
                ? `${length} ${LABEL.downloadTransactionReportsSuccessfullyMessage}`
                : `${LABEL.downloadTransactionReportSuccessfullyMessage}`;
        case LABEL.ppaOnlineAccountOpeningForm:
            return length > 1
                ? `${length} ${LABEL.downloadPPAOnlineAOFormsSuccessfullyMessage}`
                : `${LABEL.downloadPPAOnlineAOFormSuccessfullyMessage}`;
        default:
            return '';
    }
};
