import moment from 'moment';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FlexedDiv } from '../../../components';
import { subTitleStyle } from '../../../styles';

export const TargetDateColumnItem = ({ data, itemKey }: ITableCustomItem): JSX.Element => {
    let day = '';
    let agingTime = '';
    itemKey.map(({ key }) => {
        day = moment(data[key], 'x').format('DD/MM/YYYY ');
        agingTime = data.aging;
    });

    return (
        <Fragment>
            <FlexedDiv>
                <FlexedDiv direction="column">
                    <Day>{day}</Day>
                    <div style={{ ...subTitleStyle }}>{agingTime}</div>
                </FlexedDiv>
            </FlexedDiv>
        </Fragment>
    );
};

const Day = styled.div`
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: -0.2px;
    color: #002043;
`;
