/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { StaffNameColumnItem } from '../../../../templates';
import { BranchColumnItem } from './BranchColumnItem';

const CustomItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const renderItems = (key: string) => {
        switch (key) {
            case 'groupName':
            case 'roleName':
                return <StaffNameColumnItem data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />;
            case 'branch':
                return <BranchColumnItem data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />;
        }
    };
    return <Fragment>{renderItems(itemKey[0].key)}</Fragment>;
};
export default CustomItem;
