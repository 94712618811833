import React, { Fragment, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Banner, Collapsible, FlexedDiv, Modal } from '../../../../../components';
import { EDD, LABEL } from '../../../../../constants';
import { ROUTES } from '../../../..';
import { useHistory, useLocation } from 'react-router-dom';
import { sh174 } from '../../../../../styles';
import { IcoMoonSelection } from '../../../../../icons';
import { QuestionaryTemplate } from '../../../Helpers';
import { ResponseInfo } from '../../../Helpers/common';

import EDDContext from '../../../../../context/EDDContext/EDDContext';
import IcomoonReact from 'icomoon-react';

export const Responses: React.FC = () => {
    const { getCaseDetails, caseDetails, setResponseId, updateCaseStatus, showModal, setShowModal } =
        useContext(EDDContext);

    const history = useHistory();
    const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const state: any = location.state;

    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [expands, setExpands] = useState([true]);

    useEffect(() => {
        setResponseId(0);
        if (state.callFrom === 'dashboard') {
            setShowConfirmModal(true);
        }
    }, []);

    /* Function to expand/close collapsable toggle */
    const updateExpands = async (index: number, responseId?: number) => {
        if (responseId) {
            setResponseId(responseId);
            await getCaseDetails(responseId);
        }
        const exp = [...expands];
        if (exp[index] === undefined) {
            exp[index] = true;
        } else {
            exp[index] = !exp[index];
        }
        setExpands(exp);
    };

    return (
        <Fragment>
            <DetailsArea>
                <SpaceDivider />
                {caseDetails && caseDetails.headings && caseDetails.response ? (
                    caseDetails.headings.map((heading, i) => {
                        const response = caseDetails.response;
                        return (
                            <Fragment key={i}>
                                <PageHeader>
                                    {`Response ${caseDetails.headings.length - i}`}
                                    <Line />
                                </PageHeader>
                                <ResponseInfo
                                    name={caseDetails.assignedAdviser.personalDetails.agentName}
                                    timestamp={heading.submittedOn}
                                    status="Read"
                                    badgeStyle={{ color: '#A82252' }}
                                />
                                <Collapsible
                                    title={LABEL.answers}
                                    isCheckable={false}
                                    key={i}
                                    expanded={expands[i]}
                                    noXMargin={true}
                                    noMargin={true}
                                    showBoxShadow={true}
                                    handleExpand={() => updateExpands(i, heading.responseId)}
                                    content={
                                        <QuestionaryTemplate
                                            type="satisfactory"
                                            content={response}
                                            key={i}
                                            onlyRemarks={caseDetails.headings.length - 1 !== i}
                                            status={i == 0 ? caseDetails.status : 'Submitted'}
                                            showConclusion={true}
                                        ></QuestionaryTemplate>
                                    }
                                    index={i}
                                />
                                {i + 1 === caseDetails.headings.length ? (
                                    <Fragment>
                                        <ResponseInfo
                                            timestamp={caseDetails.caseSummary.caseDetails.createdOn}
                                            status={caseDetails.status}
                                            style={{ margin: '12px 12px 10px 28px' }}
                                            name={EDD.LABEL_AMLA}
                                            badgeStyle={{ background: '#132B53' }}
                                        />
                                    </Fragment>
                                ) : null}
                                <SpaceDivider />
                            </Fragment>
                        );
                    })
                ) : (
                    <DivWrapper>
                        <IcomoonReact
                            data-testid="iconset"
                            iconSet={IcoMoonSelection}
                            icon={'empty-state'}
                            size={sh174}
                        />
                        <EmptyLabel>{EDD.PENDING_RESPONSE_TITLE}</EmptyLabel>
                        <EmptySubLabel>{EDD.PENDING_RESPONSE_DESCRIPTION}</EmptySubLabel>
                    </DivWrapper>
                )}
                <SpaceDivider />
            </DetailsArea>
            {caseDetails.headings && caseDetails.response && caseDetails.status.toLowerCase() === 'submitted' ? (
                <FlexCol>
                    <Banner
                        toggle={true}
                        title={EDD.LABEL_SATISFACTORY_BANNER_TITLE}
                        description={`${caseDetails.name} (${caseDetails.clientIdNum})`}
                        handleClose={() => console.log('handle close')}
                        maxWidth="1152"
                        primaryButtonObject={{
                            handlePrimaryBtn: () => setShowConfirmModal(true),
                            label: EDD.LABEL_SATISFACTORY,
                            icon: 'check',
                        }}
                        secondaryButtonObject={{
                            handleSecondaryBtn: () => history.push(ROUTES.unsatisfactoryCase, { tab: state.tab }),
                            label: EDD.LABEL_NOT_SATISFACTORY,
                            icon: 'Icon_reroute',
                        }}
                    />
                </FlexCol>
            ) : null}
            {showConfirmModal ? (
                <Modal
                    modalActive={showConfirmModal}
                    setModalActive={setShowConfirmModal}
                    title={EDD.LABEL_EDD_MODAL_CONFIRM_TITLE}
                    primaryBtn={{
                        onClick: async () => {
                            updateCaseStatus('Satisfactory');
                            setShowConfirmModal(false);
                            setShowModal(true);
                        },
                        label: EDD.BUTTON_YES,
                        primary: true,
                        size: 'large',
                    }}
                    secondaryBtn={{
                        onClick: async () => {
                            setShowConfirmModal(false);
                        },
                        label: EDD.BUTTON_NO,
                        primary: true,
                        size: 'large',
                    }}
                    testId="custom_modal"
                    contentAlignment="left"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {EDD.LABEL_EDD_MODAL_CONFIRM_TITLE_SUB}
                    </FlexedDiv>
                </Modal>
            ) : null}
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={EDD.LABEL_EDD_MODAL_SATISFACTORY_TITLE}
                    primaryBtn={{
                        onClick: async () => {
                            setShowModal(false);
                            history.push(ROUTES.amlaEDDManagement, { tab: 'Closed', refresh: 'refresh' });
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    icon="cancel-request-done"
                    testId="custom_modal"
                    contentAlignment="center"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <b>{`${caseDetails.caseSummary.caseDetails.caseNo}`}</b> {`${EDD.LABEL_EDD_MODAL_SUB}`}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};
const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;
const DetailsArea = styled.div`
    margin-right: 0.75rem;
    display: flex;
    padding: 40px 94px;
    flex-direction: column;
`;

const SpaceDivider = styled.div`
    margin-bottom: 16px;
`;

const Line = styled.span`
    width: 90%;
    height: 0px;
    margin: 9px 0px;
    border: 1px solid #c6cbd4;
`;
const PageHeader = styled.div`
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    font-size: 16px;
    color: #171717;
    line-height: 148%;
`;
const EmptyLabel = styled.div`
    font-weight: bold;
    font-size: 16px;
    color: #002043;
`;
const EmptySubLabel = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #4d637b;
`;
const DivWrapper = styled.div`
    align-items: center;
    text-align: center;
`;
