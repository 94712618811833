import React from 'react';
import { EDD } from '../../../../constants';
import { IcoMoon } from '../../../../icons';
import styled from 'styled-components';
import { Pointer } from '../../../../components';

interface ReroutedActions extends ITableOptions {
    handleDownloadReportPDF: (item: string) => void;
}

export const ReroutedActions = ({ data, handleDownloadReportPDF }: ReroutedActions): JSX.Element => {
    const handleReportPdf = (data: IColumnItemAccordion) => {
        handleDownloadReportPDF(data.rawData.caseId);
    };

    const status: string = data.rawData.status.toString().toLowerCase();

    return (
        <>
            {status !== 'completed' ? (
                <Pointer onClick={() => handleReportPdf(data)}>
                    <ToolTip>
                        <ToolTipText>{EDD.LABEL_EDD_MODAL_DOWNLOAD_TITLE}</ToolTipText>
                        <IcoMoon name="download" size="1.5rem" />
                    </ToolTip>
                </Pointer>
            ) : (
                ''
            )}
        </>
    );
};

const ToolTip = styled('div')({
    position: 'relative',
    display: 'inline-block',
    ':hover span': {
        visibility: 'visible',
    },
});
const ToolTipText = styled('span')({
    visibility: 'hidden',
    width: '120px',
    backgroundColor: '#000',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '6px',
    padding: '5px 0',
    position: 'absolute',
    zIndex: 1,
    top: '150%',
    left: '50%',
    marginLeft: '-60px',
    ':after': {
        content: '""',
        position: 'absolute',
        bottom: '100%',
        left: '50%',
        'margin-left': '-5px',
        'border-width': '5px',
        'border-style': 'solid',
        'border-color': 'transparent transparent black transparent',
    },
});
