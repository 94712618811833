import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomDropdown, IDropdownItem, Pointer } from '../../../../components';
import { LABEL, SYSTEM_ADMIN } from '../../../../constants';
import { addUserModes } from '../../../../constants/addUserModes';
import { IcoMoon } from '../../../../icons';
import * as ROUTES from '../../../../routes';

interface SystemAdminAllUsersActionsProps extends ITableOptions {
    handleAction: (action: string, rawData: ITableData | undefined) => void;
    permissions: IUserTab;
}
export const SystemAdminAllUsersActions = ({
    data,
    handleAction,
    permissions,
}: SystemAdminAllUsersActionsProps): JSX.Element => {
    const history = useHistory();

    const status: string = data.rawData.status.toString().toLowerCase();

    const canApprove = () => {
        const source = data.rawData.source;

        if (source) {
            if (
                source === 'createUser' &&
                (permissions.reviewApproval.canApproveCreateNewUser === 'checker' ||
                    permissions.reviewApproval.canApproveCreateNewUser === 'auto-authorizer')
            )
                return true;
            if (
                source === 'userBulkUpload' &&
                (permissions.reviewApproval.canApproveBulkImport === 'checker' ||
                    permissions.reviewApproval.canApproveBulkImport === 'auto-authorizer')
            )
                return true;
            if (
                (source === 'editUser' && permissions.reviewApproval.canApproveEditUserDetails === 'checker') ||
                permissions.reviewApproval.canApproveEditUserDetails === 'auto-authorizer'
            )
                return true;
            if (
                source === 'suspendUser' &&
                (permissions.reviewApproval.canApproveSuspendUser === 'checker' ||
                    permissions.reviewApproval.canApproveSuspendUser === 'auto-authorizer')
            )
                return true;
            if (
                source === 'enableUser' &&
                (permissions.reviewApproval.canApproveReactivateUser === 'checker' ||
                    permissions.reviewApproval.canApproveReactivateUser === 'auto-authorizer')
            )
                return true;
            if (
                source === 'terminateUser' &&
                (permissions.reviewApproval.canApproveTerminateUser === 'checker' ||
                    permissions.reviewApproval.canApproveTerminateUser === 'auto-authorizer')
            )
                return true;
        }
        return false;
    };

    const activeMenu: IDropdownItem[] = [];
    if (permissions.actions.canSuspendUser === 'maker' || permissions.actions.canSuspendUser === 'auto-authorizer') {
        activeMenu.push({
            item: SYSTEM_ADMIN.LABEL_SUSPEND_USER,
            icon: 'lock',
            handleItem: handleAction,
            rawData: data.rawData,
            testId: `allusers-suspend-btn`,
        });
    }
    if (
        permissions.actions.canTerminateUser === 'maker' ||
        permissions.actions.canTerminateUser === 'auto-authorizer'
    ) {
        activeMenu.push({
            item: SYSTEM_ADMIN.LABEL_TERMINATE_USER,
            icon: 'terminate',
            danger: true,
            handleItem: handleAction,
            rawData: data.rawData,
            testId: 'allusers-terminate-btn',
        });
    }

    const pendingMenu: IDropdownItem[] = [];

    if (status === 'pending approval' && canApprove()) {
        pendingMenu.push({
            item: LABEL.approveRequest,
            icon: 'check',
            // danger: true,
            handleItem: () => handleAction('approve', data.rawData),
            rawData: data.rawData,
            testId: 'allusers-approve-btn',
        });
        pendingMenu.push({
            item: LABEL.rejectRequest,
            icon: 'reject',
            danger: true,
            handleItem: () =>
                history.push(ROUTES.addUser, {
                    mode: addUserModes.reviewUser,
                    data: data.rawData,
                    canApprove: true,
                    reject: true,
                }),
            rawData: data.rawData,
            testId: 'allusers-reject-btn',
        });
    }
    const suspendedMenu: IDropdownItem[] = [];

    if (
        permissions.actions.canReactivateUser === 'maker' ||
        permissions.actions.canReactivateUser === 'auto-authorizer'
    ) {
        suspendedMenu.push({
            item: SYSTEM_ADMIN.LABEL_REACTIVATE_USER,
            icon: 'unlock',
            handleItem: handleAction,
            rawData: data.rawData,
            testId: 'allusers-reactivate-btn',
        });
    }
    if (
        permissions.actions.canTerminateUser === 'maker' ||
        permissions.actions.canTerminateUser === 'auto-authorizer'
    ) {
        suspendedMenu.push({
            item: SYSTEM_ADMIN.LABEL_TERMINATE_USER,
            icon: 'terminate',
            danger: true,
            handleItem: handleAction,
            rawData: data.rawData,
            testId: 'allusers-terminate-btn',
        });
    }

    const menu = (status: string) => {
        switch (status) {
            case 'active':
                return activeMenu.length > 0 ? activeMenu : undefined;
            case 'suspended':
                return suspendedMenu.length > 0 ? suspendedMenu : undefined;
            case 'pending approval':
                return pendingMenu.length > 0 ? pendingMenu : undefined;
        }
    };
    let items: IDropdownItem[] = [];
    let showAction = false;
    const item = menu(status);

    if (item !== undefined) {
        items = item;
        showAction = true;
    }

    return (
        <Fragment>
            {showAction ? (
                <CustomDropdown items={items} data={data.rawData}>
                    <Pointer>
                        <IcoMoon name="action-menu" size="1.5rem" />
                    </Pointer>
                </CustomDropdown>
            ) : null}
        </Fragment>
    );
};
