import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FlexedDiv } from '../../../../components';
import { IcoMoon } from '../../../../icons';

interface TopBannerProps {
    icon?: string;
    title: string;
    subTitle?: string | React.ReactNode;
    text?: string | React.ReactNode;
}

export const TopBanner: React.FC<TopBannerProps> = ({ icon, title, subTitle, text }: TopBannerProps) => {
    return (
        <Fragment>
            <MainDiv>
                <FlexedDiv>
                    {icon ? <IcoMoon name={icon} size="1.4rem" color="#E84C3D" /> : null}
                    <Title>{title}</Title>
                </FlexedDiv>
                <FlexedDiv>
                    <SubTitle>{subTitle}</SubTitle>
                    <Text>{text}</Text>
                </FlexedDiv>
            </MainDiv>
        </Fragment>
    );
};

interface IStatusIndicatorProps {
    background: string;
    color: string;
}

const MainDiv = styled.div`
    padding: 16px 24px;
    background: #faf2f1;
    border: 1px solid #e84c3d;
    box-sizing: border-box;
    border-radius: 16px;
    margin: 0px 0px 32px 40px;
    max-width: 1100px;
`;
const Title = styled.span`
    font-weight: bold;
    font-size: 14px;
    line-height: 130%;
    color: #171717;
    margin: 2px 0px 0px 6px;
`;
const SubTitle = styled.span`
    font-weight: bold;
    font-size: 12px;
    color: #333333;
    margin: 6px 0px 0px 30px;
`;
const Text = styled.span`
    font-size: 12px;
    color: #4d4d4d;
    margin: 6px 0px 0px 6px;
    white-space: break-spaces;
`;
export default TopBanner;
