/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const userLogin = /* GraphQL */ `
  mutation UserLogin($input: LoginInput) {
    userLogin(input: $input) {
      data {
        result {
          identityId
          accessKeyId
          sessionToken
          secretAccessKey
          name
          email
          role
          group
          department
          accessLevel
          permission
          username
          inboxCount
          branchName
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const approveRejectSettings = /* GraphQL */ `
  mutation ApproveRejectSettings($input: ApproveRejectInput) {
    approveRejectSettings(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const approveReject = /* GraphQL */ `
  mutation ApproveReject($input: ApproveRejectInput) {
    approveReject(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const bulkUploadStatus = /* GraphQL */ `
  mutation BulkUploadStatus($input: BulkUploadStatusInput) {
    bulkUploadStatus(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const bulkUpload = /* GraphQL */ `
  mutation BulkUpload($input: BulkUploadInput) {
    bulkUpload(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const cancelAuditedActivity = /* GraphQL */ `
  mutation CancelAuditedActivity($input: cancelAuditedActivityInput) {
    cancelAuditedActivity(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const caseStatus = /* GraphQL */ `
  mutation CaseStatus($input: CaseStatusInput) {
    caseStatus(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const createCase = /* GraphQL */ `
  mutation CreateCase($input: NewCaseInput) {
    createCase(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup($input: GroupInput) {
    createGroup(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole($input: RoleInput) {
    createRole(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const createSettings = /* GraphQL */ `
  mutation CreateSettings($input: CreateSettingsInput) {
    createSettings(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const createTransactionsRef = /* GraphQL */ `
  mutation CreateTransactionsRef($input: CreateTransactionsRefInput) {
    createTransactionsRef(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: UserAdminInput) {
    createUser(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const dailySummaryReport = /* GraphQL */ `
  mutation DailySummaryReport($input: DailySummaryReportInput) {
    dailySummaryReport(input: $input) {
      data {
        result {
          status
          message
          path
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const exportInBackground = /* GraphQL */ `
  mutation ExportInBackground($input: ExportInBackgroundInput) {
    exportInBackground(input: $input) {
      data {
        result {
          url
          endpoint
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const downloadDaily = /* GraphQL */ `
  mutation DownloadDaily($input: DownloadDailyInput) {
    downloadDaily(input: $input) {
      data {
        result {
          message
          link
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const eddReportsPdf = /* GraphQL */ `
  mutation EddReportsPdf($input: EddReportPdf) {
    eddReportsPdf(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const editGroup = /* GraphQL */ `
  mutation EditGroup($input: EditGroupInput) {
    editGroup(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const editRole = /* GraphQL */ `
  mutation EditRole($input: EditRoleInput) {
    editRole(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const editUser = /* GraphQL */ `
  mutation EditUser($input: EditUserAdminInput) {
    editUser(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const enableOmni = /* GraphQL */ `
  mutation EnableOmni($input: IdActionInput) {
    enableOmni(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const financeStatus = /* GraphQL */ `
  mutation FinanceStatus($input: FinanceStatusInput) {
    financeStatus(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const fundStatus = /* GraphQL */ `
  mutation FundStatus($input: FundStatusInput) {
    fundStatus(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const fundUpload = /* GraphQL */ `
  mutation FundUpload($input: FundInput) {
    fundUpload(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const opsStatus = /* GraphQL */ `
  mutation OpsStatus($input: OpsStatusInput) {
    opsStatus(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const targetDateRequest = /* GraphQL */ `
  mutation TargetDateRequest($input: TargetDateRequestInput) {
    targetDateRequest(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const targetDateResponse = /* GraphQL */ `
  mutation TargetDateResponse($input: TargetDateResponseInput) {
    targetDateResponse(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const transactionCheckerStatus = /* GraphQL */ `
  mutation TransactionCheckerStatus($input: ApproveRejectInput) {
    transactionCheckerStatus(input: $input) {
      data {
        result {
          status
          message
          transactionAffected
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const transactionStatus = /* GraphQL */ `
  mutation TransactionStatus($input: TransactionStatusInput) {
    transactionStatus(input: $input) {
      data {
        result {
          status
          message
          transactionAffected
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const transactionTrackingReport = /* GraphQL */ `
  mutation TransactionTrackingReport($input: TransactionTrackingReportInput) {
    transactionTrackingReport(input: $input) {
      data {
        result {
          message
          status
          path
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const updateInbox = /* GraphQL */ `
  mutation UpdateInbox($input: UpdateInboxInput) {
    updateInbox(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const updateSeen = /* GraphQL */ `
  mutation UpdateSeen($input: SeenInput) {
    updateSeen(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const updateTransactionReportStatus = /* GraphQL */ `
  mutation UpdateTransactionReportStatus($input: DownloadDailyInput) {
    updateTransactionReportStatus(input: $input) {
      data {
        result {
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const userBulkUpload = /* GraphQL */ `
  mutation UserBulkUpload($input: UserBulkUploadInput) {
    userBulkUpload(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const userUpdateStatus = /* GraphQL */ `
  mutation UserUpdateStatus($input: IdActionUpdateInput) {
    userUpdateStatus(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const validateDropdown = /* GraphQL */ `
  mutation ValidateDropdown($input: VDropDownInput) {
    validateDropdown(input: $input) {
      data {
        result {
          roles
          grant
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const dailySummaryReportProcess = /* GraphQL */ `
  mutation DailySummaryReportProcess($input: dailySummaryReportProcessInput) {
    dailySummaryReportProcess(input: $input) {
      data {
        result {
          message
          event
          id
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const operationReportProcess = /* GraphQL */ `
  mutation OperationReportProcess($input: operationReportProgressInput) {
    operationReportProcess(input: $input) {
      data {
        result {
          message
          event
          id
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
