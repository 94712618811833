/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { ChangeEvent, Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { initialOverView, searchPlaceholder } from './helper';
import {
    ComponentLoader,
    CustomButton,
    CustomSpacer,
    Dashboard,
    DatePicker,
    FlexedDiv,
    SearchBar,
    SelectDropdown,
    SliderOverview,
} from '../../../components';
import {
    Fs12BoldPrimaryBlack,
    Fs24BoldSecNavyBlue,
    Fs24RegSecNavyBlue,
    LABEL,
    SearchOptionsSystemAdmin,
    SYSTEM_ADMIN,
} from '../../../constants';
import AuthContext from '../../../context/AuthContext';
import SystemAdminContext from '../../../context/SystemAdminContext/SAContext';
import * as Routes from '../../../routes';
import { addUserModes } from '../../../constants/addUserModes';
import { SystemAdminAllUsers } from '..';
import styled from 'styled-components';
import moment from 'moment';
import { SystemAdminUserGroups } from './UserGroups';
import { SystemAdminRolesPermissions } from './RolesPermissions/RolesPermissions';
import { clearContextOnNavigation, updateIsSeenDashboard } from '../../../utils';
import { useDebounce } from '../../../customHooks';
import { SearchBarFilterTags } from '../../../templates';

declare interface ILocation {
    tab: string;
    refresh: string;
}

export const tabsLabels: string[] = [];

const initialDateFilterSA: ISearchInput = {
    column: 'createdOn',
    value: '',
};
const dateSorting: ISelectValue[] = [
    { label: 'Created On', value: 'createdOn' },
    { label: 'Last Updated', value: 'lastUpdated' },
];

const statusStates: Array<ISelectOption> = [
    { value: 'Active', label: 'Active' },
    { value: 'Pending Approval', label: 'Pending Approval' },
    { value: 'Suspended', label: 'Suspended' },
    { value: 'Terminated', label: 'Terminated' },
];
const statusStates2: Array<ISelectOption> = [
    { value: 'Active', label: 'Active' },
    { value: 'Pending Approval', label: 'Pending Approval' },
    { value: 'Suspended', label: 'Suspended' },
];

const primaryButtons = ['Add User', 'Add User Group', 'Add Role'];

export const SystemAdminDashboard = (): JSX.Element => {
    const {
        branchOptions,
        departmentOptions,
        disableBulkButton,
        disableResultLimit,
        fetchAllUsers,
        getDropdown,
        getExportList,
        getTab,
        groupsOptions,
        loading,
        orderCounter,
        overview,
        page,
        pageMax,
        paginationClicked,
        resultLimit,
        searchInput,
        setPage,
        setPaginationClicked,
        setResultLimit,
        setSearchInput,
        setTab,
        setTarget,
        setTempTarget,
        tab,
        tabs,
        tempTargetDate,
        uploadId,
        setCreatedOnDropdown,
        setSearchbarFilterTags,
        searchbarFilterTags,
        tempState,
        dispatch,
        initialRender,
    } = useContext(SystemAdminContext);

    const { userLoginContext } = useContext(AuthContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const userManagementPermission: IUserManagement = parsedPermission.hq.permission.userManagement;
    const { userTab, userGroupTab, rolesPermissionTab } = userManagementPermission;
    //Tab permission states
    const history = useHistory();
    const [tabPrimaryButtons, setTabPrimaryButtons] = useState<IActionButton>();
    const [tabSecondaryButtons, setTabSecondaryButtons] = useState<IActionButton[]>();
    const [expand, setExpand] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [showAllTags, setShowAllTags] = useState<boolean>(false);
    // Temp state to handle date range picker's to and from states
    const [targetDateRange, setTargetDateRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>('');
    const isSettingFilter = useRef<boolean>(false);

    // get tab from location
    const location: any = useLocation();
    const locationState: ILocation = location.state;

    const tabName = locationState !== undefined && locationState ? locationState.tab : 'All Users';

    if (locationState)
        if (locationState.refresh === 'refresh') {
            setRefresh(true);
            locationState.refresh = '';
        }
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const handleOverviewClick = (tabInput: number, title: string) => {
        if (title === initialOverView[0][1].label) {
            if (uploadId !== '') {
                history.push({
                    pathname: Routes.reviewBulkImportUsers,
                    state: {
                        uploadId: uploadId,
                    },
                });
            }
        } else {
            dispatch({ type: 'overviewClicked', payload: tabInput });
        }
    };

    const debouncedSearchTerm = useDebounce(searchInput, 1000);

    const handleResultLimit = (limit: number) => {
        setPage(1);
        setResultLimit(limit);
    };
    const accessLevelOptions = [
        { id: 'hq', value: 'HQ BackOffice', label: 'HQ BackOffice' },
        { id: 'branch', value: 'Branch BackOffice', label: 'Branch BackOffice' },
    ];

    useEffect(() => {
        const tabIndex = tabs.findIndex((tab: IDashboardTabs) => tab.name === tabName);
        setTab(tabIndex);
        getDropdown();
    }, []);

    if (userTab.isAll === 'true' && tabs.length < 3) {
        tabsLabels.push('users');
        tabs.push({
            name: 'All Users',
            table: <SystemAdminAllUsers isSearchResult={searchInput.value.length > 0} />,
        });
    }
    if (userGroupTab.isAll === 'true' && tabs.length < 3) {
        tabsLabels.push('groups');
        tabs.push({
            name: 'User Groups',
            table: <SystemAdminUserGroups isSearchResult={searchInput.value.length > 0} />,
        });
    }
    if (rolesPermissionTab.isAll === 'true' && tabs.length < 3) {
        tabsLabels.push('roles');
        tabs.push({
            name: 'Roles & Permissions',
            table: (
                <SystemAdminRolesPermissions setRefresh={setRefresh} isSearchResult={searchInput.value.length > 0} />
            ),
        });
    }
    const prevTempStateItem = useRef(tempState);

    useEffect(() => {
        /*** @setTimeOut for recurring API calls */
        // const timer = setTimeout(() => {
        if (isSettingFilter.current === false) {
            fetchAllUsers();
        } else if (refresh === true) {
            fetchAllUsers();
            setRefresh(false);
        }

        handleSearchbarButtons();
        return function cleanup() {
            tab !== undefined && history.location.pathname !== '/'
                ? updateIsSeenDashboard('systemAdminDashboard', [tabsLabels[tab]], idTokenHeader)
                : null;
        };
    }, [tab, page, resultLimit, refresh, debouncedSearchTerm]);

    useMemo(() => {
        if (
            isSettingFilter.current === false &&
            JSON.stringify(tempState) !== JSON.stringify(prevTempStateItem.current)
        ) {
            fetchAllUsers();
            prevTempStateItem.current = tempState;
        }
    }, [tempState]);

    useEffect(() => {
        setRefresh(false);
        handleSearchbarButtons();
    }, [disableBulkButton]);

    const clearContext = () => {
        handleReset();
        setResultLimit(10);
        setPage(1);
    };

    useEffect(() => {
        return function cleanup() {
            clearContextOnNavigation('systemAdminDashboard', history.location.pathname) === false
                ? clearContext()
                : null;
        };
    }, []);

    //function to set primary and secondary button permissions for each tab
    const handleSearchbarButtons = () => {
        const _tempSecondary: IActionButton[] = [];
        const hq = parsedPermission.hq.permission.userManagement;
        switch (tabs[tab].name) {
            case 'All Users':
                if (
                    hq.userTab.actions.canExportList === 'maker' ||
                    hq.userTab.actions.canExportList === 'checker' ||
                    hq.userTab.actions.canExportList === 'auto-authorizer'
                ) {
                    _tempSecondary.push({
                        type: 'secondary',
                        title: 'Export List',
                        actionIcon: 'export',
                        testId: 'allusers-exportlist-btn',
                    });
                }
                if (
                    hq.userTab.actions.canBulkImport === 'maker' ||
                    hq.userTab.actions.canBulkImport === 'auto-authorizer'
                ) {
                    _tempSecondary.push({
                        type: 'secondary',
                        title: 'Bulk Import Users',
                        actionIcon: 'bulk',
                        width: '11rem',
                        disabled: disableBulkButton,
                        testId: 'allusers-bulkimportusers-btn',
                    });
                }

                if (
                    hq.userTab.actions.canCreateNewUser === 'maker' ||
                    hq.userTab.actions.canCreateNewUser === 'auto-authorizer'
                ) {
                    setTabPrimaryButtons({
                        type: 'primary',
                        title: 'Add User',
                        actionIcon: 'plus',
                        testId: 'allusers-adduser-btn',
                    });
                }
                break;
            case 'User Groups':
                if (
                    hq.userGroupTab.actions.canExportList === 'maker' ||
                    hq.userGroupTab.actions.canExportList === 'checker' ||
                    hq.userGroupTab.actions.canExportList === 'auto-authorizer'
                ) {
                    _tempSecondary.push({
                        type: 'secondary',
                        title: 'Export List',
                        actionIcon: 'export',
                        testId: 'usergroups-exportlist-btn',
                    });
                }
                if (
                    hq.userGroupTab.actions.canCreateNewUserGroup === 'maker' ||
                    hq.userGroupTab.actions.canCreateNewUserGroup === 'auto-authorizer'
                ) {
                    setTabPrimaryButtons({
                        type: 'primary',
                        title: 'Add User Group',
                        actionIcon: 'plus',
                        testId: 'usergroups-addusergroup-btn',
                    });
                }
                break;
            case 'Roles & Permissions':
                if (
                    hq.rolesPermissionTab.actions.canExportList === 'maker' ||
                    hq.rolesPermissionTab.actions.canExportList === 'checker' ||
                    hq.rolesPermissionTab.actions.canExportList === 'auto-authorizer'
                ) {
                    _tempSecondary.push({
                        type: 'secondary',
                        title: 'Export List',
                        actionIcon: 'export',
                        testId: 'roles-exportlist-btn',
                    });
                }
                if (
                    hq.rolesPermissionTab.actions.canCreateUserRole === 'maker' ||
                    hq.rolesPermissionTab.actions.canCreateUserRole === 'auto-authorizer'
                ) {
                    setTabPrimaryButtons({
                        type: 'primary',
                        title: 'Add Role',
                        actionIcon: 'plus',
                        testId: 'roles-addrole-btn',
                    });
                }
                break;
            default:
        }
        setTabSecondaryButtons(_tempSecondary);
    };

    const handleSearchActions = (action: IActionButton) => {
        switch (action.title) {
            case primaryButtons[0]: {
                //Add User
                history.push(Routes.addUser, {
                    mode: addUserModes.addUser,
                    isAA: userTab.actions.canCreateNewUser === 'auto-authorizer',
                });
                break;
            }
            case primaryButtons[1]: {
                //Add User Group
                history.push(Routes.addUserGroup);
                break;
            }
            case primaryButtons[2]: {
                //Add Role
                history.push(Routes.addRoles);
                break;
            }
            case 'Bulk Import Users': {
                // Bulk Import Users
                history.push(Routes.bulkImportUsers);
                break;
            }
            case 'Export List': {
                getExportList();
                break;
            }
        }
    };

    const switchTabs = async (index: number) => {
        setTab(index);
        handleReset();
        setPage(1);
        setSearchInput({ value: '', column: SearchOptionsSystemAdmin[getTab(index)][0].value });
        initialRender.current = true;
    };

    const handleNext = () => {
        if (paginationClicked) return;
        if (page < pageMax) {
            setPaginationClicked(true);
            setPage(page + 1);
        }
    };
    const handlePrevious = () => {
        if (paginationClicked) return;
        if (page > 1) {
            setPaginationClicked(true);
            setPage(page - 1);
        }
    };

    const getFilterTagLabels = (tags: ISelectValue[], _tempState: ISAStates): ISelectValue[] => {
        const temp = [...tags];
        Object.entries(_tempState).forEach(([key, value]) => {
            const tempObj: ISelectValue = {
                label: '',
                value: '',
            };

            const tagLabel = (label: string, value: string) => {
                if (value !== '') {
                    tempObj.label = label;
                    tempObj.value = value;
                }
                return { label, value };
            };

            switch (key) {
                case 'dateRange':
                    if (value !== '') {
                        tempObj.label = _tempState.date === 'createdOn' ? LABEL.createdOn : LABEL.lastUpdated;
                        tempObj.value = value
                            .split('~')
                            .map((time: any) => moment(time, 'x').format('DD/MM/YYYY'))
                            .join('-');
                    }
                    break;
                case 'status':
                    tagLabel(LABEL.status, value);
                    break;
                case 'department':
                    tagLabel(LABEL.department, value);
                    break;
                case 'group':
                    tagLabel(LABEL.assignedUserGroup, value);
                    break;
                case 'accessLevel':
                    tagLabel(`Access Level`, value);
                    break;
                case 'branch':
                    tagLabel(LABEL.branch, value);
                    break;
                default:
                    break;
            }
            if (tempObj.label !== '') {
                temp.push(tempObj);
            }
        });

        return temp;
    };

    const handleRemoveFilter = (item: ISelectValue) => {
        let tempValues = [...searchbarFilterTags];
        tempValues = tempValues.filter((filter) => {
            return filter.value.toLowerCase() !== item.value.toLowerCase();
        });
        setSearchbarFilterTags(tempValues);
        switch (item.label) {
            case LABEL.department:
                dispatch({ type: 'department', payload: '' });
                break;
            case LABEL.assignedUserGroup:
                dispatch({ type: 'group', payload: '' });
                break;
            case LABEL.branch:
                dispatch({ type: 'branch', payload: '' });
                break;
            case 'accessLevel':
                dispatch({ type: 'accessLevel', payload: '' });
                break;
            case LABEL.status:
                dispatch({ type: 'status', payload: '' });
                break;
            case LABEL.lastUpdated:
            case LABEL.createdOn:
                dispatch({ type: 'dateRange', payload: '' });
                setTargetDateRange([null, null]);

                break;
            default:
                break;
        }
    };

    const handleReset = () => {
        dispatch({ type: 'reset', payload: [] });
        setDatePickerInvalidMessage('');
        setTarget(initialDateFilterSA);
        setTempTarget(initialDateFilterSA);
        setTargetDateRange([null, null]);
        setExpand(false);
        setSearchbarFilterTags([]);
        if (searchbarFilterTags.length !== 0) {
            setPage(1);
        }
    };

    const handleApply = () => {
        isSettingFilter.current = false;
        dispatch({ type: 'overviewClicked', payload: tempState.overviewClicked });
        setExpand(false);
        tempState.date === 'createdOn'
            ? setCreatedOnDropdown(LABEL.createdOn)
            : setCreatedOnDropdown(LABEL.lastUpdated);
        setTarget(tempTargetDate);
        let _searchFilterTags: ISelectValue[] = [];
        _searchFilterTags = getFilterTagLabels(_searchFilterTags, tempState);
        setSearchbarFilterTags(_searchFilterTags);
        _searchFilterTags.length !== 0 ? setPage(1) : undefined;
    };

    const tabsTitles =
        tabs.length < 1
            ? LABEL.filterUsersBy
            : tabs[tab].name === 'All Users'
            ? LABEL.filterUsersBy
            : tabs[tab].name === 'User Groups'
            ? LABEL.filterGroupsBy
            : LABEL.filterRolesBy;
    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        const options = SearchOptionsSystemAdmin[getTab(tab)];
        options.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInput({ ...searchInput, column: value }),
                testId: `systemadmin-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };

    if (locationState)
        if (locationState.refresh === 'refresh') {
            setRefresh(true);
            locationState.refresh = '';
        }

    const handleTargetDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const dates = [...date] as [moment.Moment | null, moment.Moment | null];
        setTargetDateRange(dates);
    };

    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        isSettingFilter.current = true;
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item, '"M/D/YYYY H:mm"').valueOf();
            time.push(milliSeconds);
        });
        const _validFrom = time[0] < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = time[1] < moment().add(1, 'day').startOf('day').valueOf() && time[1] > time[0];
        // Date range in milliseconds
        if (_validFrom && _validTo) {
            // Date range in milliseconds
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
            const range = time.toString().replace(',', '~');
            dispatch({ type: 'dateRange', payload: range });
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            time[1] < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            time[1] < time[0] && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
            dispatch({ type: 'dateRange', payload: '' });
        }
    };

    const handleSelectFilters = (type: string, payload: string) => {
        isSettingFilter.current = true;
        switch (type) {
            case 'date':
            case 'status':
            case 'department':
            case 'group':
            case 'accessLevel':
            case 'branch':
                dispatch({ payload, type: type });
                break;
        }
    };

    return (
        <Fragment>
            <AuthContext.Consumer>
                {({ userLoginContext }) => (
                    <FlexedDiv direction="row">
                        <Fs24RegSecNavyBlue>{`${SYSTEM_ADMIN.LABEL_WELCOME_BACK},`}</Fs24RegSecNavyBlue>
                        <CustomSpacer horizontal={true} space={'0.25rem'} />
                        <Fs24BoldSecNavyBlue>{`${userLoginContext.username}.`}</Fs24BoldSecNavyBlue>
                    </FlexedDiv>
                )}
            </AuthContext.Consumer>
            <CustomSpacer space={'1.5rem'} />
            <SliderOverview
                maxVisibleSlides={2}
                slideMargin={10}
                overviewData={overview[tab]}
                handleCardClick={handleOverviewClick}
            />

            <SearchBar
                expand={expand}
                setExpand={setExpand}
                placeHolder={searchPlaceholder[getTab(tab)]}
                searchTitle={LABEL.users}
                dropDownOptions={searchOptions()}
                searchInput={searchInput}
                onSearch={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchInput({ ...searchInput, value: e.currentTarget.value });
                }}
                primaryButton={tabPrimaryButtons && tabPrimaryButtons}
                secondaryButton={tabSecondaryButtons && tabSecondaryButtons}
                handlePrimary={handleSearchActions}
                handleSecondary={handleSearchActions}
                testId="systemadmin"
                clearSearchInput={() => {
                    setSearchInput({ ...searchInput, value: '' });
                }}
                filter
                filterTags={searchbarFilterTags}
                // showTags={showAllTags}
            >
                {expand ? (
                    <>
                        <SearchInner>
                            <SearchInnerTitle>{tabsTitles}</SearchInnerTitle>

                            <FlexedDiv direction="row" style={{ paddingBottom: '1.6rem' }}>
                                <InputWrap>
                                    <SelectDropdown
                                        onChange={(item: string | number) =>
                                            handleSelectFilters('date', item.toString())
                                        }
                                        options={dateSorting}
                                        testId={`date-dropdown-filter`}
                                        selectedValue={tempState.date}
                                        label="Date"
                                    />
                                </InputWrap>
                                <InputWrap>
                                    <DatePicker
                                        label="Date Range"
                                        setTargetDate={handleTargetDate}
                                        targetDate={targetDateRange}
                                        range={true}
                                        width="360"
                                        primaryBorderColor="#002043"
                                        handleDate={handleDate}
                                        errorMessage={datePickerInvalidMessage}
                                        setErrorMessage={setDatePickerInvalidMessage}
                                    />
                                </InputWrap>
                            </FlexedDiv>
                            <FlexedDiv
                                direction="row"
                                style={{ borderTop: '1px dashed #CECECE', paddingTop: '1.6rem' }}
                            >
                                {tabs.length > 0 && tabs[tab].name === 'All Users' ? (
                                    <Fragment>
                                        <InputWrap>
                                            <SelectDropdown
                                                onChange={(item: string | number) =>
                                                    handleSelectFilters('department', item.toString())
                                                }
                                                options={departmentOptions}
                                                testId={`department-dropdown-filter`}
                                                selectedValue={tempState.department}
                                                label={LABEL.department}
                                                placeHolder={LABEL.selectOne}
                                            />
                                        </InputWrap>
                                        <InputWrap>
                                            <SelectDropdown
                                                onChange={(item: string | number) =>
                                                    handleSelectFilters('group', item.toString())
                                                }
                                                options={groupsOptions}
                                                testId={`group-dropdown-filter`}
                                                selectedValue={tempState.group}
                                                label={LABEL.assignedUserGroup}
                                                placeHolder={LABEL.selectOne}
                                            />
                                        </InputWrap>
                                    </Fragment>
                                ) : null}

                                {tabs.length > 0 && tabs[tab].name === 'User Groups' ? (
                                    <InputWrap>
                                        <SelectDropdown
                                            onChange={(item: string | number) =>
                                                handleSelectFilters('branch', item.toString())
                                            }
                                            options={branchOptions}
                                            testId={`branch-dropdown-filter`}
                                            selectedValue={tempState.branch}
                                            label={LABEL.branchOffice}
                                            placeHolder={LABEL.selectOne}
                                        />
                                    </InputWrap>
                                ) : null}
                                {tabs.length > 0 && tabs[tab].name === 'Roles & Permissions' ? (
                                    <InputWrap>
                                        <SelectDropdown
                                            onChange={(item: string | number) =>
                                                handleSelectFilters('accessLevel', item.toString())
                                            }
                                            options={accessLevelOptions}
                                            testId={`accesslevel-dropdown-filter`}
                                            selectedValue={tempState.accessLevel}
                                            label={SYSTEM_ADMIN.LABEL_ACCESS_LEVEL}
                                            placeHolder={LABEL.selectOne}
                                        />
                                    </InputWrap>
                                ) : null}
                                <InputWrap>
                                    <SelectDropdown
                                        onChange={(item: string | number) =>
                                            handleSelectFilters('status', item.toString())
                                        }
                                        options={
                                            tabs.length < 1 || tabs[tab].name === 'All Users'
                                                ? statusStates
                                                : statusStates2
                                        }
                                        testId={`status-dropdown-filter`}
                                        selectedValue={tempState.status}
                                        label={LABEL.status}
                                        placeHolder={LABEL.selectOne}
                                    />
                                </InputWrap>
                            </FlexedDiv>
                        </SearchInner>

                        <FlexedDiv direction="column">
                            <FlexedDiv direction="column"></FlexedDiv>
                            <FlexedDiv style={{ margin: '1.6rem 0rem' }} justifyContent="center">
                                <CustomButton onClick={handleReset} style={{ width: '16.67vw' }}>
                                    <Fs12BoldPrimaryBlack>{LABEL.reset}</Fs12BoldPrimaryBlack>
                                </CustomButton>
                                <CustomSpacer horizontal={true} space={'1rem'} />
                                <CustomButton
                                    primary={true}
                                    onClick={handleApply}
                                    style={{ width: '16.67vw', padding: '14px 0' }}
                                >
                                    {LABEL.apply}
                                </CustomButton>
                            </FlexedDiv>
                        </FlexedDiv>
                    </>
                ) : (
                    <SearchBarFilterTags
                        tags={searchbarFilterTags}
                        disabled={loading}
                        showAll={true}
                        handleShowAll={() => setShowAllTags(!showAllTags)}
                        handleClearAll={handleReset}
                        handleRemoveTag={handleRemoveFilter}
                        testId="systemAdmin-dashboard"
                    />
                )}
            </SearchBar>

            <div style={{ position: 'relative' }}>
                <Dashboard
                    tabs={tabs}
                    page={page}
                    maxPage={pageMax}
                    selectedTab={tab}
                    orderCounter={orderCounter}
                    nextStyle={{ cursor: paginationClicked ? 'default' : 'pointer' }}
                    switchTabs={switchTabs}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    resultLimit={resultLimit}
                    setResultLimit={(limit) => handleResultLimit(limit)}
                    testId="systemadmin"
                    setCurrentPage={setPage}
                    disableResultLimit={disableResultLimit}
                    adjustTabMargin={searchbarFilterTags.length !== 0}
                />
                {loading ? <ComponentLoader /> : null}
            </div>
        </Fragment>
    );
};

const InputWrap = styled.div`
    width: 100%;
    max-width: 360px;
    margin-right: 1rem;
`;
const SearchInner = styled.div``;
const SearchInnerTitle = styled.h2`
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 1rem;
`;
export default SystemAdminDashboard;
