import { LABEL } from '../../../constants/labels';

export const getOrderSummaryType = (result: IOrderSummaryResult): string => {
    let _type = '';
    if (result.transactionType?.toLowerCase() === 'sales' && result.isEtb) {
        _type = 'sales';
    } else if (result.transactionType?.toLowerCase() === 'switch' && result.isEtb) {
        _type = 'switch';
    } else if (result.transactionType?.toLowerCase() === 'redeem' && result.isEtb) {
        _type = 'redeem';
    }
    return _type;
};

export const getTotalInvestmentAmt = (value: Array<IInvestment>): string => {
    let _totalInvestmentAmt = '';
    value.forEach((result, index) => {
        _totalInvestmentAmt =
            _totalInvestmentAmt + `${index > 0 ? ' + ' : ''} ${result.fundCurrency} ${result.investmentAmount}`;
    });

    return _totalInvestmentAmt;
};

export const getTotalLabel = (result: IOrderSummaryResult): string => {
    let _label = '';
    if (result.orderNo && result.orderNo.toLowerCase().includes('z')) {
        _label = LABEL.totalRecurring;
    } else if (result.transactionType?.toLowerCase() === 'sales' && result.isEtb) {
        _label = LABEL.totalSales;
    } else if (result.transactionType?.toLowerCase() === 'switch' && result.isEtb) {
        _label = LABEL.totalSwitchOut;
    } else if (result.transactionType?.toLowerCase() === 'redeem' && result.isEtb) {
        _label = result.redemptionSummary.find((ele) => ele.fundType === 'AMP')
            ? LABEL.totalRedeemAmount
            : LABEL.totalRedeemUnits;
    } else {
        _label = LABEL.totalInvestment;
    }
    return _label;
};

//Fn returns order or transaction depending on location route
export const getSummaryTitle = (_location?: IOrderSummaryRouteProps, status?: string): string => {
    let _title = LABEL.transaction;

    if (
        status?.toLowerCase() === 'pending doc' ||
        status?.toLowerCase() === 'pending payment' ||
        status?.toLowerCase() === 'pending doc & payment' ||
        status?.toLowerCase() === 'void' ||
        status?.toLowerCase() === 'pending physical doc' ||
        status?.toLowerCase() === 'pending initial order' ||
        status?.toLowerCase() === 'pending receive'
    ) {
        _title = LABEL.order;
    }
    return _title;
};
