export interface ILoginRoleSection {
    permissionLevel: string;
    isAll: boolean;
    login: {
        isAll: boolean;
        actions: boolean[];
    };
    inbox: {
        isAll: boolean;
        actions: boolean[];
    };
    profile: {
        isAll: boolean;
        actions: boolean[];
    };
}

export interface ICustomerExpSection {
    permissionLevel: string;
    isAll: boolean;
    pendingTab: {
        isAll: boolean;
        actions: boolean[];
    };
    upcomingTab: {
        isAll: boolean;
        actions: boolean[];
    };
    hardCopyTab: {
        isAll: boolean;
        actions: boolean[];
    };
    reRoutedTab: {
        isAll: boolean;
        actions: boolean[];
    };
    historyTab: {
        isAll: boolean;
        actions: boolean[];
    };
}

export interface IAdviserSection {
    permissionLevel: string;
    isAll: boolean;
    advisersTab: {
        isAll: boolean;
        actions: boolean[];
    };
    investorsTab: {
        isAll: boolean;
        actions: boolean[];
    };
}

export const loginAccessInitialData: ILoginRoleSection = {
    permissionLevel: 'General Access',
    isAll: true,
    login: {
        isAll: true,
        actions: [],
    },
    inbox: {
        isAll: true,
        actions: [true],
    },
    profile: {
        isAll: true,
        actions: [true],
    },
};

export const customerExpInitialData: ICustomerExpSection = {
    permissionLevel: 'Maker',
    isAll: false,
    pendingTab: {
        isAll: false,
        actions: [false, false, false, false, false, false, false, false],
    },
    upcomingTab: {
        isAll: false,
        actions: [false, false, false],
    },
    hardCopyTab: {
        isAll: false,
        actions: [false, false, false, false],
    },
    reRoutedTab: {
        isAll: false,
        actions: [false, false, false, false],
    },
    historyTab: {
        isAll: false,
        actions: [false, false, false, false],
    },
};

export const adviserInitialData: IAdviserSection = {
    permissionLevel: 'General Access',
    isAll: false,
    advisersTab: {
        isAll: false,
        actions: [false, false, false],
    },
    investorsTab: {
        isAll: false,
        actions: [false],
    },
};
