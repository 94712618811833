const REMARKS_CONFIG = {
    id: 'remarks',
    toggle: {
        id: 'remarks',
        label: 'Others',
        elementType: 'toggle',
        placeHolder: '',
        value: '',
    },
    body: [
        {
            id: 'remarks',
            label: 'Reason',
            elementType: 'checkboxWithInput',
            placeHolder: 'Add your remarks here.',
            value: '',
        },
    ],
};

export const buildRejectFormConfig = (data: ReasonsResult[], transactionType: string): IRerouteFormSection[] => {
    const formConfig: IRerouteFormSection[] = [];

    data.forEach((item) => {
        switch (item.category.toLowerCase()) {
            case 'payment':
                const _paymentObj: IRerouteFormSection = {
                    id: 'payment',
                    toggle: {
                        id: '',
                        label: 'Payment',
                        elementType: 'toggle',
                        placeHolder: '',
                        value: '',
                    },
                    body: [
                        {
                            id: 'payment',
                            label: 'Reason',
                            elementType: 'multiselect',
                            multiSelectOptions: [],
                            placeHolder: 'Select one or more',
                            value: '',
                        },
                    ],
                };
                // push payment options into multiselect
                item.subCategory?.forEach((category) => _paymentObj.body[0].multiSelectOptions?.push(category[1]));
                _paymentObj.toggle.id = item.reasonId ? item.reasonId : 'payment';
                formConfig.unshift(_paymentObj);
                break;
            case 'document':
                // push document options into multiselect
                const _document: IRerouteFormSection = {
                    id: 'document',
                    toggle: {
                        id: '',
                        label: ['switch', 'redeem'].includes(transactionType.toLowerCase())
                            ? 'Physical Document'
                            : 'Document',
                        elementType: 'toggle',
                        placeHolder: '',
                        value: '',
                    },
                    body: [
                        {
                            id: 'document',
                            label: 'Reason',
                            elementType: 'multiselect',
                            multiSelectOptions: [],
                            placeHolder: 'Select one or more',
                            value: '',
                        },
                    ],
                };
                item.subCategory?.forEach((category) => _document.body[0].multiSelectOptions?.push(category[1]));
                _document.toggle.id = item.reasonId ? item.reasonId : 'document';
                formConfig.length > 1 ? formConfig.splice(1, 0, _document) : formConfig.splice(0, 0, _document);
                break;
            case 'invalid information':
                // push invalid information options into multiselect
                const _invalidInformation: IRerouteFormSection = {
                    id: 'invalidInformation',
                    toggle: {
                        id: '',
                        label: 'Invalid Information',
                        elementType: 'toggle',
                        placeHolder: '',
                        value: '',
                    },
                    body: [
                        {
                            id: 'invalidInformation',
                            label: 'Reason',
                            elementType: 'multiselect',
                            multiSelectOptions: [],
                            placeHolder: 'Select one or more',
                            value: '',
                        },
                    ],
                };
                item.subCategory?.forEach((category) =>
                    _invalidInformation.body[0].multiSelectOptions?.push(category[1]),
                );
                _invalidInformation.toggle.id = item.reasonId ? item.reasonId : 'invalidInformation';
                formConfig.length > 2
                    ? formConfig.splice(2, 0, _invalidInformation)
                    : formConfig.splice(1, 0, _invalidInformation);
                break;
        }
    });

    formConfig.push(REMARKS_CONFIG);

    return formConfig;
};
