/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { RegulartTooltipTemplate, Tooltip } from '../../../../components';
import { IcoMoon } from '../../../../icons';
import { Aging, DateColumnItem, InvestorColumnItem, TotalInvestmentColumnItem } from '../../../../templates';

const CustomItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const Remarks = () => {
        return data && data.remarks.length !== 0 ? (
            <Tooltip tooTipContent={<RegulartTooltipTemplate message={data.reasons} />}>
                <div>
                    <IcoMoon name="warning" color="#E89700" size="1.667vw" />
                </div>
            </Tooltip>
        ) : (
            <div>--</div>
        );
    };
    const renderItems = (key: string) => {
        switch (key) {
            case 'remarks':
                return <Remarks />;
            case 'totalInvestment':
                return <TotalInvestmentColumnItem isSortedColumn={isSortedColumn} data={data} itemKey={itemKey} />;
            case 'lastUpdated':
            case 'createdOn':
            case 'submittedOn':
            case 'targetDate':
                return <DateColumnItem data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />;
        }
    };

    return (
        <Fragment>
            {itemKey.length === 1 ? (
                renderItems(itemKey[0].key)
            ) : (
                <Fragment>
                    {itemKey[0].key === 'clientName' ? (
                        <InvestorColumnItem
                            isSortedColumn={isSortedColumn}
                            data={data}
                            itemKey={itemKey}
                            type="noIcon"
                        />
                    ) : (
                        <Fragment>
                            <Aging isSortedColumn={isSortedColumn} data={data} itemKey={itemKey} />
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};
export default CustomItem;
