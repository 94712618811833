import React, { FunctionComponent, Fragment, ReactNode } from 'react';
import styled from 'styled-components';

declare interface SwitchProps {
    disabled?: boolean;
    selected: boolean;
    onClick: (index: number) => void;
    index: number;
}

declare interface StyledSwitchProps {
    children: ReactNode;
    disabled?: boolean;
    selected: boolean;
    onClick: (index: number) => void;
    index: number;
}

const StyledSwitch: FunctionComponent<StyledSwitchProps> = ({
    children,
    disabled,
    onClick,
    selected,
    index,
}: StyledSwitchProps) => {
    const disabledStyle: React.CSSProperties = disabled === true ? { opacity: 0.5, pointerEvents: 'none' } : {};
    return (
        <Fragment>
            <div onClick={() => onClick(index)} style={disabledStyle}>
                {selected === true ? (
                    <SelectedSwitch>{children}</SelectedSwitch>
                ) : (
                    <SwitchUnSelected>{children}</SwitchUnSelected>
                )}
            </div>
        </Fragment>
    );
};

export const CustomRadioButton: FunctionComponent<SwitchProps> = ({
    disabled,
    onClick,
    selected,
    index,
}: SwitchProps) => {
    return (
        <Fragment>
            <StyledSwitch onClick={() => onClick(index)} selected={selected} disabled={disabled} index={index}>
                <InsideFragment />
            </StyledSwitch>
        </Fragment>
    );
};

const SelectedSwitch = styled.div`
    display: flex;
    background-color: #c61230;
    border-radius: 16px;
    height: 1rem;
    width: 2rem;
    justify-content: flex-end;
    padding-right: 0.1875rem;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`;

const SwitchUnSelected = styled.div`
    display: flex;
    background-color: #a3a3a3;
    border-radius: 16px;
    height: 1rem;
    width: 2rem;
    align-items: center;
    padding-left: 0.1875rem;
    &:hover {
        cursor: pointer;
    }
`;

const InsideFragment = styled.div`
    background-color: #ffffff;
    border-radius: 100px;
    height: 0.625rem;
    width: 0.625rem;
`;

export default CustomRadioButton;
