import React, { useContext } from 'react';
import { LABEL } from '../../../constants';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';
import {
    sh100,
    sh104,
    sh108,
    sh120,
    sh128,
    sh130,
    sh136,
    sh138,
    sh144,
    sh148,
    sh150,
    sh156,
    sh192,
    sh200,
    sh288,
    sh296,
    sh40,
    sh48,
    sh60,
    sh64,
    sh69,
    sh72,
    sh80,
    sh88,
    sh90,
    sh96,
    sh98,
    subTitleStyle,
} from '../../../styles';
import { statusStyle, stringToCamelCase } from '../../../utils';

import { DateHeader } from '../DateHeaderMenu';

interface IDashboardBranchTableColumnData {
    showCheckBox: boolean;
    pendingTab: IPendingTab;
    submittedOnDropdown: string;
    setSubmittedOnDropdown: (value: string) => void;
    handleViewDetails: (item: IColumnItemAccordion) => void;
    filters: ISort_FilterInput;
    setFilters: (data: ISort_FilterInput) => void;
    changeRequestColumn: boolean;
}
interface IColumnReturnType {
    columns: ITableColumn[];
}
export const useDashboardTransactionTableColumn = ({
    showCheckBox,
    pendingTab,
    submittedOnDropdown,
    setSubmittedOnDropdown,
    handleViewDetails,
    changeRequestColumn,
}: IDashboardBranchTableColumnData): IColumnReturnType => {
    //Context
    const { dateFilter, setDateFilter, sortData, setSortData } = useContext(DashboardBranchContext);

    // Fn to handle table column sorting
    const handleSort = (keyName: string) => {
        const tempSort = [...sortData];
        sortData.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });

        setSortData(tempSort);
    };
    // Fn to handle sort arrow icon
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortData.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };

    // Fn to handle date filter
    const handleDateFilter = (item: string, value: string) => {
        setSubmittedOnDropdown(item);
        let currentFilter = 'submittedOn';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
            case LABEL.submittedOn:
                currentFilter = 'submittedOn';
                break;
        }
        setDateFilter({ ...dateFilter, dateSort: currentFilter });
        setSortData([{ column: currentFilter, value: value }]);
    };

    const dateHeaderFilter = (menu: IHeaderMenu) => {
        return (
            <DateHeader
                {...menu}
                dropdownData={[
                    submittedOnDropdown,
                    // eslint-disable-next-line react/prop-types
                    sortData[0].column === stringToCamelCase(submittedOnDropdown) ? sortData[0].value : '',
                ]}
                setData={handleDateFilter}
            />
        );
    };

    const columnsTransaction: ITableColumn[] = [
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [{ key: stringToCamelCase(submittedOnDropdown) }],
            viewStyle: {
                width: showCheckBox ? sh100 : sh108,
                padding: '0rem 0rem 0rem 1rem',
            },
            title: submittedOnDropdown,
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
            customItem: true,
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase(submittedOnDropdown) ? 700 : 400,
            },
            itemStyle: {
                position: 'relative',
            },
        },
        {
            icon: {
                name: handleSortIcon('transactionRef'),
                size: '1rem',
            },
            key: [{ key: 'orderNo' }, { key: 'transactionRef', textStyle: subTitleStyle }],

            viewStyle: {
                width: showCheckBox ? sh90 : sh98,
            },
            title: LABEL.transNo,
            onPressHeader: () => handleSort('transactionRef'),
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400,
            },
            customItem: true,
        },
        {
            key: [
                {
                    key: 'transactionType',
                    textStyle: { fontWeight: sortData[0].column === 'transactionType' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: showCheckBox ? sh80 : sh88,
            },
            title: LABEL.transTypes,
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionType' ? 700 : 400,
            },
            onPressHeader: () => handleSort('transactionType'),
            icon: {
                name: handleSortIcon('transactionType'),
                size: 16,
            },
        },
        {
            icon: {
                name: handleSortIcon('fundType'),
                size: '1rem',
            },
            key: [{ key: 'fundType', textStyle: { fontWeight: sortData[0].column === 'fundType' ? 700 : 400 } }],
            viewStyle: {
                width: showCheckBox ? sh60 : sh69,
            },
            title: LABEL.products,
            onPressHeader: () => handleSort('fundType'),
            titleStyle: {
                fontWeight: sortData[0].column === 'fundType' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('paymentMethod'),
                size: '1rem',
            },
            key: [
                { key: 'paymentMethod', textStyle: { fontWeight: sortData[0].column === 'paymentMethod' ? 700 : 400 } },
            ],
            viewStyle: {
                width: showCheckBox ? sh64 : sh72,
            },
            title: LABEL.funding,
            onPressHeader: () => handleSort('paymentMethod'),
            titleStyle: {
                fontWeight: sortData[0].column === 'paymentMethod' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'totalInvestment' }],
            viewStyle: {
                width: showCheckBox ? sh148 : sh156,
            },
            title: LABEL.totalAmount,
            icon: {
                name: handleSortIcon('totalInvestment'),
                size: '1rem',
            },
            customItem: true,
            onPressHeader: () => handleSort('totalInvestment'),
            titleStyle: {
                fontWeight: sortData[0].column === 'totalInvestment' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'totalUnits' }],
            viewStyle: {
                width: showCheckBox ? sh130 : sh138,
            },
            title: LABEL.totalUnits,
            icon: {
                name: handleSortIcon('totalUnits'),
                size: '1rem',
            },
            customItem: true,
            onPressHeader: () => handleSort('totalUnits'),
            titleStyle: {
                fontWeight: sortData[0].column === 'totalUnits' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: showCheckBox ? sh96 : sh104,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: showCheckBox ? sh144 : sh150,
            },
            title: LABEL.status,
            onPressHeader: () => handleSort('status'),
            titleStyle: {
                fontWeight: sortData[0].column === 'status' ? 700 : 400,
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
        {
            key: [],
            viewStyle: {
                width: showCheckBox ? sh40 : sh48,
            },
            itemIcon: {
                name: pendingTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                pendingTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'daily-view-column',
        },
    ];

    const columnsChangeRequest: ITableColumn[] = [
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [{ key: stringToCamelCase(submittedOnDropdown) }],
            viewStyle: {
                width: showCheckBox ? sh120 : sh128,
            },
            title: submittedOnDropdown,
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
            customItem: true,
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase(submittedOnDropdown) ? 700 : 400,
            },
            itemStyle: {
                position: 'relative',
            },
        },
        {
            icon: {
                name: handleSortIcon('transactionRef'),
                size: '1rem',
            },
            key: [{ key: 'orderNo' }, { key: 'transactionRef', textStyle: subTitleStyle }],

            viewStyle: {
                width: showCheckBox ? sh136 : sh144,
            },
            title: LABEL.transNo,
            onPressHeader: () => handleSort('transactionRef'),
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400,
            },
            customItem: true,
        },
        {
            key: [
                {
                    key: 'transactionType',
                    textStyle: { fontWeight: sortData[0].column === 'transactionType' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: showCheckBox ? sh120 : sh128,
            },
            title: LABEL.transTypes,
            icon: {
                name: 'arrow-down',
                size: 16,
                style: { opacity: '0.2' },
            },
        },
        {
            icon: {
                name: handleSortIcon('riskCategory'),
                size: '1rem',
            },
            key: [
                { key: 'riskCategory', textStyle: { fontWeight: sortData[0].column === 'riskCategory' ? 700 : 400 } },
            ],
            viewStyle: {
                width: showCheckBox ? sh120 : sh128,
            },
            title: LABEL.risk,
            onPressHeader: () => handleSort('riskCategory'),
            titleStyle: {
                fontWeight: sortData[0].column === 'riskCategory' ? 700 : 400,
            },
        },
        // {
        //     icon: {
        //         name: handleSortIcon('mobileNumber'),
        //         size: '1rem',
        //     },
        //     key: [
        //         { key: 'mobileNumber', textStyle: { fontWeight: sortData[0].column === 'mobileNumber' ? 700 : 400 } },
        //     ],
        //     viewStyle: {
        //         width: showCheckBox ? sh128 : sh136,
        //     },
        //     title: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_MOBILE,
        //     onPressHeader: () => handleSort('mobileNumber'),
        //     titleStyle: {
        //         fontWeight: sortData[0].column === 'mobileNumber' ? 700 : 400,
        //     },
        // },
        // {
        //     key: [{ key: 'email' }],
        //     viewStyle: {
        //         width: showCheckBox ? sh176 : sh184,
        //     },
        //     title: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_EMAIL,
        //     icon: {
        //         name: handleSortIcon('email'),
        //         size: '1rem',
        //     },
        //     customItem: true,
        //     onPressHeader: () => handleSort('email'),
        //     titleStyle: {
        //         fontWeight: sortData[0].column === 'email' ? 700 : 400,
        //     },
        // },
        {
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: showCheckBox ? sh288 : sh296,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: showCheckBox ? sh192 : sh200,
            },
            title: LABEL.status,
            onPressHeader: () => handleSort('status'),
            titleStyle: {
                fontWeight: sortData[0].column === 'status' ? 700 : 400,
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
        {
            key: [],
            viewStyle: {
                width: showCheckBox ? sh40 : sh48,
            },
            itemIcon: {
                name: pendingTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                pendingTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'daily-view-column',
        },
    ];

    return {
        columns: changeRequestColumn ? columnsChangeRequest : columnsTransaction,
    };
};
