import React from 'react';
import { FlexedDiv } from '../../../components/atoms/FlexDiv';
import { CE_DASHBOARD_LABELS } from '../../../constants/labels';
import styled from 'styled-components';
import { CustomSpacer } from '../../../components/atoms/CustomSpacer/Spacer';

export type ModalContent = {
    heading: string;
    content: JSX.Element;
    icon: string;
    type: string;
};
export interface IgetOrderModalContentCb {
    modalContent: ModalContent[];
}
export const getOrderModalContent = (type: string): IgetOrderModalContentCb => {
    const modalContent: ModalContent[] = [];
    const _confirmationObj: ModalContent = {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    };

    const _feedbackObj: ModalContent = {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    };
    switch (type.toLowerCase()) {
        case 'reroute':
            _confirmationObj.heading = CE_DASHBOARD_LABELS.DAILY.approveRerouteOrderRequest;
            _confirmationObj.content = (
                <FlexedDiv direction="column">
                    <Subtitle>{CE_DASHBOARD_LABELS.DAILY.approveRerouteOrderRequestMessage}</Subtitle>
                    <CustomSpacer space={'.5rem'} />
                    <Subtitle>{CE_DASHBOARD_LABELS.DAILY.yesOrNoApprove}</Subtitle>
                </FlexedDiv>
            );
            _confirmationObj.type = 'approve request';
            _feedbackObj.heading = CE_DASHBOARD_LABELS.DAILY.rerouteRequestApproved;
            _feedbackObj.icon = 'trx-verification-success';
            break;
        default:
            _confirmationObj.heading = CE_DASHBOARD_LABELS.DAILY.approveTransactionRequestOrderSummary;
            _confirmationObj.content = (
                <FlexedDiv direction="column">
                    <Subtitle>{CE_DASHBOARD_LABELS.DAILY.verifyThisTransactionMessage}</Subtitle>
                    <CustomSpacer space={'.5rem'} />
                    <Subtitle>{CE_DASHBOARD_LABELS.DAILY.yesOrNoApprove}</Subtitle>
                </FlexedDiv>
            );
            _confirmationObj.type = 'approve request';
            _feedbackObj.heading = CE_DASHBOARD_LABELS.DAILY.transactionRequestApproved;
            _feedbackObj.icon = 'trx-verification-request-approve';
    }
    modalContent.push(_confirmationObj, _feedbackObj);
    return {
        modalContent,
    };
};

const Subtitle = styled.div`
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;

    color: #333333;
`;
