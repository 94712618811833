import React, { ChangeEvent, Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
//Context
import ReportContext from '../../context/ReportContext/ReportContext';
//Components
import { LABEL } from '../../constants/labels';
import { SearchBar } from '../../components/organisms/Searchbar/SearchBar';
import Dashboard from '../../components/organisms/Dashboard/Dashboard';
import ReportTab from './ReportTab';
import styled from 'styled-components';
import { getReportDashboardData } from './getReportDashboardData';
import { useComponentLoader, useDebounce } from '../../customHooks';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';

import { handleReportsDashboardResults } from './reportUtils';
import { ComponentLoader } from '../../components/molecules/ComponentLoader';

const ReportDashboard: React.FC = () => {
    const { searchInput, setSearchInput, reportData, setReportData } = useContext(ReportContext);
    const { loading, loadingHandler } = useComponentLoader();
    //UI states
    const [tab, setTab] = useState(0);
    const [tabs, setTabs] = useState<IDashboardTabs[]>([]);
    const [expand, setExpand] = useState<boolean>(false);
    const debouncedSearchTerm = useDebounce(searchInput, 700);
    const switchTabs = (index: number) => {
        setTab(index);
    };

    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const parsedPermission = JSON.parse(userLoginContext.permission);

    const permissionObject = window.location.pathname.includes('hq')
        ? (parsedPermission.hq.permission as IHqPermissions['permission'])
        : (parsedPermission.branch.permission as IBranchPermissions['permission']);
    // const history = useHistory();
    // const route = history.location.pathname;

    const memoizedDashboardInput: MemoizedReportDashboardInput = useMemo(() => {
        return {
            debouncedSearchTerm,
        };
    }, [debouncedSearchTerm]);
    const getDashboardCallBack = useCallback(() => {
        getReportDashboard();
    }, [memoizedDashboardInput]);
    useEffect(() => {
        getDashboardCallBack();
    }, [getDashboardCallBack]);
    const getReportDashboard = async () => {
        // await API CAll
        async function fetchData() {
            try {
                loadingHandler();
                const response = await getReportDashboardData(idTokenHeader, searchInput.value, parsedPermission); //if the data is there set the report data if is not then make the modal of handleErrorhandler
                const data = response?.data?.result;
                const error = response?.error;
                if (data) {
                    // Do the functions for the permissions and set the data
                    const reportDetailsResult: IGetOperationalReportDashboardResult[] = data.map((item) => ({
                        reportName: item.reportName,
                        lastDownloaded: item.lastDownloaded,
                    }));

                    const temp = handleReportsDashboardResults(reportDetailsResult, permissionObject);
                    setReportData(temp);
                    loadingHandler();
                } else {
                    loadingHandler();
                    setReportData([]);
                    throw error;
                }
            } catch (error) {
                const _error = error as IErrorHandling;

                if (Object.keys(_error).includes('errorCode')) {
                    setErrorMessage({
                        ...errorMessage,
                        errorCode: _error.errorCode,
                        message: _error.message,
                        title: 'Cannot Fetch Reports Data',
                        testId: 'reports-dashboard-error-modal',
                    });
                    handleErrorHandler();
                } else {
                    setErrorMessage({
                        ...errorMessage,
                        message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                        title: 'Something Went Wrong',
                        testId: 'gql-error-modal',
                    });
                    handleErrorHandler();
                }
            }
        }
        await fetchData();
    };

    useMemo(() => {
        const _tabs = [...tabs];
        _tabs.push({
            name: 'Reports',
            table: <ReportTab testId="report-tb" />,
            testId: 'report-tab',
            dashboardId: 'report',
        });
        setTabs(_tabs);
    }, []);

    //Reset search data when back
    useEffect(() => {
        return () => {
            setSearchInput({ ...searchInput, value: '' });
        };
    }, []);

    return (
        <Fragment>
            <SearchBar
                expand={expand}
                setExpand={setExpand}
                placeHolder={`Search Reports by Report Name`}
                searchTitle={LABEL.report}
                searchInput={searchInput}
                onSearch={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchInput({ ...searchInput, value: e.currentTarget.value });
                }}
                clearSearchInput={() => {
                    setSearchInput({ ...searchInput, value: '' });
                }}
                testId="reports-dashboard"
                filter={false}
                isSimpleSearch
            />
            <TabWrapper>
                <Dashboard
                    tabs={tabs}
                    selectedTab={tab !== undefined ? tab : 0}
                    setResultLimit={() => null}
                    orderCounter={[reportData.length]}
                    switchTabs={switchTabs}
                    handleNext={() => null}
                    handlePrevious={() => null}
                    testId="reports-dashboard"
                    setCurrentPage={() => null}
                    disableResultLimit={true}
                    disablePagination={true}
                />
                {loading ? <ComponentLoader /> : null}
            </TabWrapper>
        </Fragment>
    );
};
const TabWrapper = styled.div`
    position: relative;
`;

export default ReportDashboard;
