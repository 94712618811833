import React, { Fragment, useContext, useEffect, useState } from 'react';
import FlexSpacer, { FlexedDiv, Avatar } from '../../../components';
import { Collapsible, DataWithLabel } from '../../../components/molecules';
import { LABEL, TextNavyBlue } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { MultiSection } from '../../../templates';
import { getInitialsByFirstTwo, userColorPicker } from '../../../utils';

import styled from 'styled-components';
import AuthContext from '../../../context/AuthContext';

interface staffProfileProps {
    profile: IStaffProfileDetails;
}

export const StaffProfile: React.FC<staffProfileProps> = ({ profile }: staffProfileProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const [staffProfile] = useState<IStaffProfileDetails>(profile);

    //Accordion states
    const [expandAll, setExpandAll] = useState<boolean>(false);
    const [expands, setExpands] = useState<boolean[]>([true, false]);

    const permissionJson = JSON.parse(userLoginContext.permission);
    const tempColor = Object.keys(permissionJson.hq.permission).length === 0 ? 'branch' : 'hq';
    const color = userColorPicker(tempColor);

    // Fn to handle accordion collapse
    const toggleCollapse = (index: number) => {
        const exp = [...expands];
        const _tempExpandAll: boolean[] = [];
        if (index === -1) {
            if (!expandAll) {
                exp.forEach(() => _tempExpandAll.push(true));
                setExpandAll(true);
                setExpands(_tempExpandAll);
            } else {
                exp.forEach(() => _tempExpandAll.push(false));
                setExpandAll(false);
                setExpands(_tempExpandAll);
            }

            return;
        }
        exp[index] = !exp[index];

        setExpands(exp);
    };
    useEffect(() => {
        const _expands: boolean[] = [];

        profile.details.forEach((result, index) => {
            if (index === 0) {
                _expands.push(true);
            } else {
                _expands.push(false);
            }
        });

        setExpands(_expands);
    }, []);
    return (
        <Fragment>
            <Profile id="staff-profile">
                <FlexedDiv alignItems="flex-start">
                    <Avatar
                        size={120}
                        customStyles={{
                            color: '#fff',
                            fontWeight: 700,
                            fontSize: '2.5rem',
                        }}
                        backgroundColor={color}
                    >
                        <span id="profile-initials">{getInitialsByFirstTwo(staffProfile.name)}</span>
                    </Avatar>
                    <FlexedDiv direction="column" style={{ marginLeft: '2.5rem', width: '16.667vw' }}>
                        <DataWithLabel
                            direction="column"
                            label={LABEL.staffName}
                            data={staffProfile.name}
                            testId="profile-name"
                        />
                        <DataWithLabel
                            direction="column"
                            label={LABEL.status}
                            data={staffProfile.status}
                            testId="profile-status"
                        />
                    </FlexedDiv>
                    <FlexSpacer grow={2} />
                    <FlexedDiv
                        direction="column"
                        grow={1}
                        alignItems="flex-end"
                        justifyContent="space-between"
                        style={{ height: 'inherit' }}
                    >
                        <div style={{ height: '6.5rem' }} />
                        <FlexedDiv
                            style={{ cursor: 'pointer', alignItems: 'center' }}
                            onClick={() => toggleCollapse(-1)}
                            id="accordion-expand-toggle"
                        >
                            <TextNavyBlue size="12px" weight="700" style={{ marginRight: '4px' }}>
                                {expandAll ? LABEL.collapseAll : LABEL.expandAll}
                            </TextNavyBlue>
                            <IcoMoon name={expandAll ? LABEL.collapse : LABEL.expand} size="18px" />
                        </FlexedDiv>
                    </FlexedDiv>
                </FlexedDiv>
            </Profile>

            {staffProfile ? (
                staffProfile.details.map((elm, index) => {
                    return (
                        <AccordionContainer
                            key={`Collapse${index + 1}`}
                            id={`${elm.title.toLocaleLowerCase()}-accordion`}
                        >
                            <Collapsible
                                title={elm.title}
                                isCheckable={false}
                                expanded={expands[index]}
                                noMargin={true}
                                noXMargin={true}
                                showBoxShadow={true}
                                content={<MultiSection sections={elm.sections} />}
                                handleExpand={() => toggleCollapse(index)}
                                testId={
                                    elm.title && elm.title.toLocaleLowerCase().replace(/,/g, '').replace(/\s/g, '-')
                                }
                            />
                        </AccordionContainer>
                    );
                })
            ) : (
                <div>Data not available</div>
            )}
        </Fragment>
    );
};

const Profile = styled.div`
    margin: 2rem;
    height: 7.5rem;
`;

const AccordionContainer = styled.div`
    margin: 2rem;
`;
