import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { BasicInput, CollapsibleTooltipMessage, DropdownBase, InputText } from '..';
import { CustomDropdownV2, CustomSpacer, FlexedDiv, Tooltip } from '../../../../../components';
import { Collapsible } from '../../../../../components/molecules/Collapsible/Collapsible';
import { HQ_ROLE } from '../../../../../constants/hqRoleCollapsible';
import { SYSTEM_ADMIN } from '../../../../../constants/labels';
import { IcoMoon } from '../../../../../icons';
import { handleChangeData, handleUserChange, hasKey } from '../../utils/functions';
import { CollapsibleContent, ISetSectionTypes } from '../../AddRoles/CollapsibleContent';
import { IBranchOfficeSection, ICustomerExperienceSection } from '../../AddRoles/HqPrivileges/hqRolesInitialData';
import { PermissionLevelInfoTemplate } from '../../../../../components/atoms/TooltipTemplates';

export interface CustomerPrivilegesProps {
    approval: boolean;
    expanded: boolean[];
    customerExpDashboard: ICustomerExperienceSection;
    handleExpand: (index: number) => void;
    setCustomerExpDashboard: (data: ICustomerExperienceSection) => void;
    branchOffices: IBranchOfficeSection;
    setBranchOffices: (data: IBranchOfficeSection) => void;
}

export const CustomerPrivileges: FunctionComponent<CustomerPrivilegesProps> = ({
    approval,
    expanded,
    customerExpDashboard,
    setCustomerExpDashboard,
    handleExpand,
    branchOffices,
    setBranchOffices,
}: CustomerPrivilegesProps) => {
    const setInitialValue = () => {
        let accessLevelObject = { label: '', value: '' };
        switch (customerExpDashboard.permissionLevel.toLowerCase()) {
            case 'maker':
                accessLevelObject = { label: 'Maker', value: 'maker' };
                break;
            case 'maker (edited)':
                accessLevelObject = { label: 'Maker (Edited)', value: 'maker' };
                break;
            case 'checker':
                accessLevelObject = { label: 'Checker', value: 'checker' };
                break;
            case 'checker (edited)':
                accessLevelObject = { label: 'Checker (Edited)', value: 'checker' };
                break;
            case 'auto-authorizer':
                accessLevelObject = { label: 'Auto-Authorizer', value: 'autoAuthorizer' };
                break;
            case 'auto-authorizer (edited)':
                accessLevelObject = { label: 'Auto-Authorizer (Edited)', value: 'autoAuthorizer' };
                break;
            default:
                accessLevelObject = { label: 'Maker', value: 'maker' };
        }
        return accessLevelObject;
    };
    const [customerExpDropdown, setCustomerExpDropdown] = useState<ISelectValue>(setInitialValue());
    const [initial, setInitial] = useState<boolean>(true);

    const handleCustomerExpDropdown = (item: string) => {
        let temp: ISelectValue = {
            label: '',
            value: '',
        };
        switch (item) {
            case 'Maker':
                temp = { label: item, value: 'maker' };
                break;
            case 'Checker':
                temp = { label: item, value: 'checker' };
                break;
            case 'Auto-Authorizer':
                temp = { label: item, value: 'autoAuthorizer' };
                break;
        }
        setCustomerExpDropdown(temp);
    };

    const handleCheckCustomerExperience = () => {
        if (approval === false) {
            const temp = { ...customerExpDashboard };
            const newData = handleChangeData(temp);
            setCustomerExpDashboard(newData as ICustomerExperienceSection);
        }
    };

    const handleBranchOffices = () => {
        if (approval === false) {
            const temp = {
                ...branchOffices,
                permissionLevel: branchOffices.isAll ? 'General Access' : 'General Access',
            };
            const newData = handleChangeData(temp);
            setBranchOffices(newData as IBranchOfficeSection);
        }
    };

    useEffect(() => {
        if (initial === false) {
            const temp = customerExpData;
            const stateData = { ...customerExpDashboard };
            const newData: ICustomerExperienceSection = handleUserChange(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                temp!,
                stateData,
                customerExpDropdown,
            ) as ICustomerExperienceSection;
            setCustomerExpDashboard(newData);
        }
    }, [customerExpDropdown]);

    useEffect(() => {
        setInitial(false);
    }, []);

    const customerExpData = hasKey(HQ_ROLE.ceDashboard.data, customerExpDropdown.value)
        ? HQ_ROLE.ceDashboard.data[customerExpDropdown.value]
        : undefined;
    const options = [
        { item: 'Maker', value: 'maker', handleItem: handleCustomerExpDropdown },
        { item: 'Checker', value: 'checker', handleItem: handleCustomerExpDropdown },
        { item: 'Auto-Authorizer', value: 'autoAuthorizer', handleItem: handleCustomerExpDropdown },
    ];
    return (
        <Fragment>
            <Collapsible
                checked={customerExpDashboard.isAll}
                checkboxDisabled={approval}
                expanded={expanded[0]}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv direction="row" style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                <CustomDropdownV2
                                    items={options}
                                    noMinWidth={false}
                                    showDivider={false}
                                    maxWidth={'22.5rem'}
                                    disableDropdown={approval}
                                >
                                    <DropdownBase>
                                        <BasicInput value={customerExpDashboard.permissionLevel} />
                                        <IcoMoon name="caret-down" size={'1.5rem'} />
                                    </DropdownBase>
                                </CustomDropdownV2>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={customerExpDashboard.isAll}
                            isDisabled={approval}
                            item={customerExpData}
                            data={customerExpDashboard}
                            setData={setCustomerExpDashboard as ISetSectionTypes}
                            offsetArray={[0, 0, 5, 0, 0, 0, 0, 0]}
                            user={customerExpDropdown.value}
                        />
                    </Fragment>
                }
                handleChecked={handleCheckCustomerExperience}
                handleExpand={handleExpand}
                index={3}
                isCheckable={true}
                title={HQ_ROLE.ceDashboard.title}
                subtitle={HQ_ROLE.ceDashboard.subtitle}
                testId="customer-ux-accordion"
                noXMargin={true}
                noMargin={true}
            />
            <CustomSpacer space={'1rem'} />
            <Collapsible
                checked={branchOffices.isAll}
                checkboxDisabled={approval}
                expanded={expanded[1]}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                <DropdownBase style={{ opacity: 0.5 }}>
                                    <BasicInput disabled={true} value={branchOffices.permissionLevel} />
                                    <IcoMoon name="caret-down" size={'1.5rem'} />
                                </DropdownBase>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={branchOffices.isAll}
                            isDisabled={approval}
                            item={HQ_ROLE.branchOffices.data.general}
                            data={branchOffices}
                            setData={setBranchOffices as ISetSectionTypes}
                        />
                    </Fragment>
                }
                handleChecked={handleBranchOffices}
                handleExpand={handleExpand}
                index={4}
                isCheckable={true}
                title={HQ_ROLE.branchOffices.title}
                subtitle={HQ_ROLE.branchOffices.subtitle}
                testId="branch-office-accordion"
                noXMargin={true}
                noMargin={true}
            />
        </Fragment>
    );
};
