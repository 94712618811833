import React, { Fragment } from 'react';
import { EMPTY_STATES, LABEL, TextDarkBlack } from '../../../constants';
import { AdvanceTable } from '../../../components/organisms/AdvancedTable';
import CustomItem from './CustomItem';
import ReroutedActions from './ReroutedActions';

import { FlexedDiv, Modal } from '../../../components';
import * as Routes from '../../../routes';
import { useReroutedHandler } from './useReroutedHandler';
import { useHistory } from 'react-router-dom';

export interface ReroutedProps {
    dueDateTab?: IDueDateTab;
    filters: ISort_FilterInput;
    idTokenHeader?: {
        Authorization: string;
        strategy: string;
    };
    isFilterApplied?: boolean;
    isSearchResult?: boolean;
    submittedOnDropdown: string;
    rerouteTab: IReroutedTab;
    searchInput: string;
    sortData: ISort_FilterInput;
    setErrorHandler: () => void;
    setErrorMessage: (error: IErrorHandling) => void;
    setFilters: (data: ISort_FilterInput) => void;
    setSubmittedOnDropdown: (value: string) => void;
    showResultCount?: boolean;
}

export const Rerouted: React.FC<ReroutedProps> = ({
    sortData,
    dueDateTab,
    filters,
    idTokenHeader,
    isFilterApplied,
    isSearchResult,
    submittedOnDropdown,
    rerouteTab,
    searchInput,
    setErrorHandler,
    setErrorMessage,
    setFilters,
    setSubmittedOnDropdown,
    showResultCount,
}: ReroutedProps) => {
    const {
        columnsRerouted,
        handleApproveTargetDate,
        handleExtendTargetDate,
        handleRejectTargetDate,
        modalSubtitle,
        modalTitle,
        reroutedGrouped,
        setShowModal,
        setTargetDate,
        showModal,
    } = useReroutedHandler({
        filters,
        rerouteTab,
        searchInput,
        setErrorHandler,
        setErrorMessage,
        setFilters,
        setSubmittedOnDropdown,
        submittedOnDropdown,
        dueDateTab,
        idTokenHeader,
        isFilterApplied,
        isSearchResult,
        sortData,
    });

    const history = useHistory();
    return (
        <Fragment>
            {showResultCount && searchInput ? (
                <TextDarkBlack
                    size="16px"
                    weight="700"
                    style={{ margin: '24px' }}
                >{`${reroutedGrouped.current.length} result(s) found for '${searchInput}'`}</TextDarkBlack>
            ) : null}
            <AdvanceTable
                data={reroutedGrouped.current}
                columns={columnsRerouted}
                RenderOptions={
                    rerouteTab.actions.canDoDueDateExtension === 'maker' ||
                    rerouteTab.actions.canDoDueDateExtension === 'auto-authorizer' ||
                    (dueDateTab !== undefined &&
                        (dueDateTab.reviewApproval.canApproveDueDateExtension === 'checker' ||
                            dueDateTab.reviewApproval.canApproveDueDateExtension === 'auto-authorizer'))
                        ? (props: ITableOptions) => (
                              <ReroutedActions
                                  {...props}
                                  handleExtendTargetDate={handleExtendTargetDate}
                                  handleApproveTargetDate={handleApproveTargetDate}
                                  handleRejectTargetDate={handleRejectTargetDate}
                                  dueDateTab={dueDateTab}
                                  rerouteTab={rerouteTab}
                                  setTargetDate={setTargetDate}
                              />
                          )
                        : undefined
                }
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortData[0].column} {...props} />}
                onEmptyState={
                    isFilterApplied ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateTableRerouted
                }
                groupedRow={true}
                isSearchResult={isSearchResult}
                searchInput={searchInput}
                testId="branchrerouted"
            />

            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalTitle}
                    primaryBtn={{
                        onClick: async () => {
                            setShowModal(false);
                            history.push(Routes.dashboard);
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    icon="targetdate-modal"
                    testId="branchrerouted-modal"
                    contentAlignment="center"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {modalSubtitle}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};
