import React, { Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { EMPTY_STATES, LABEL, TextDarkBlack } from '../../../constants';
import { AdvanceTable } from '../../../components';
import { MasterFundActions } from './';
import { sh144, sh96, sh120, sh247, sh48 } from '../../../styles';

import * as Routes from '../../../routes';
import AuthContext from '../../../context/AuthContext';
import UploadsContext from '../../../context/UploadsContext';
import CustomItem from '../Shared/CustomItem';
import WebSocketContext from '../../../context/WebSocketContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';

export interface IAllMasterFundProps {
    allMasterFundData: ITableData[];
    permissions: IUserActionsValues;
    getTabIndex: () => void;
    handleDashboardModal: (heading: string, subHeading: string) => void;
    showResultCount?: boolean;
}

export const MasterFund: React.FC<IAllMasterFundProps> = ({
    allMasterFundData,
    permissions,
    getTabIndex,
    handleDashboardModal,
    showResultCount,
}: IAllMasterFundProps) => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    const { ws } = useContext(WebSocketContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const { fundStatusMutation, loadingHandler, searchInput, tempState, dispatch } = useContext(UploadsContext);

    const history = useHistory();
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { masterFundTab } = parsedPermission.hq.permission.uploads;

    const handleSort = (keyName: string) => {
        const tempSort = { ...tempState.sortData };
        const sortType = tempState.sortData.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
        tempSort.value = sortType;
        tempSort.column = keyName;
        dispatch({ type: 'sortData', payload: tempSort });
        // setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        if (tempState.sortData.column === keyName) {
            const icon = tempState.sortData.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
            sortIcon = icon;
        } else {
            sortIcon = 'arrow-down';
        }
        return sortIcon;
    };
    const handleViewDetails = (item: IColumnItemAccordion) => {
        history.push({
            pathname: Routes.financeReviewImportFile,
            state: {
                fundDocumentId: item.rawData.fundDocumentId,
                title: 'Master Fund',
                status: item.rawData.status,
                tabIndex: getTabIndex(),
            },
        });
    };
    // fn to approve masterfund from dashboard
    const handleApprove = async (item: IColumnItemAccordion) => {
        const _checkerModal = {
            fundDocumentId: item.rawData.fundDocumentId,
            tab: 'masterfund',
            status: 'approve',
            remark: '',
        };

        initiateWebsocket() ? handleWebsocket(_checkerModal) : console.log('Unable to establlish WS');
    };

    const handleReject = (item: IColumnItemAccordion) => {
        history.push({
            pathname: Routes.financeRejectImport,
            state: {
                fundDocumentId: item.rawData.fundDocumentId,
                type: 'Master Fund',
                tab: 'masterfund',
                tabIndex: getTabIndex(),
            },
        });
    };
    //Fn to handle websocket listener for fundStatus
    const handleWebsocket = async (checkerModal: IFundApproval) => {
        if (ws !== undefined) {
            fundStatusMutation(checkerModal);
            ws.addEventListener('message', (event) => {
                if (event.data !== 'PONG' && event.data !== 'pong') {
                    const data = JSON.parse(event.data);
                    if (data.body.data !== undefined) {
                        const { result } = data.body.data;

                        if (result.status) {
                            loadingHandler();
                            handleDashboardModal('Master Fund', 'All Fund Data');
                        }
                    }
                    if (data.body.error !== undefined) {
                        const { error } = data.body;

                        setErrorMessage({
                            ...errorMessage,
                            errorCode: error.errorCode,
                            message: error.message,
                            title: `Approving master fund request failed`,
                            testId: 'fund-status-error-modal',
                        });
                        handleErrorHandler();
                        loadingHandler();
                    }
                }
            });
        } else {
            return false;
        }
    };
    //Fn to initiate websocket connection
    const initiateWebsocket = (): boolean => {
        if (ws !== undefined) {
            loadingHandler();
            ws.send('Ping');
            return true;
        } else {
            return false;
        }
    };
    const allMasterFundsCols: ITableColumn[] = [
        {
            icon: {
                name: handleSortIcon('fileName'),
                size: '1rem',
            },
            key: [{ key: 'fileName', textStyle: { textTransform: 'none', opacity: 1 } }],
            viewStyle: {
                width: sh247,
            },
            title: LABEL.fileName,
            titleStyle: {
                fontWeight: tempState.sortData.column === 'fileName' ? 700 : 400,
            },
            customItem: true,
            onPressHeader: () => handleSort('fileName'),
        },
        {
            icon: {
                name: handleSortIcon('fileSize'),
                size: '1rem',
            },
            key: [{ key: 'fileSize' }],
            viewStyle: {
                width: sh120,
            },
            title: LABEL.fileSize,
            titleStyle: {
                fontWeight: tempState.sortData.column === 'fileSize' ? 700 : 400,
            },
            customItem: true,
            onPressHeader: () => handleSort('fileSize'),
        },
        {
            // icon: {
            //     name: 'caret-down',
            //     size: '16px',
            // },
            key: [{ key: 'fileType', textStyle: { textTransform: 'lowercase' } }],
            viewStyle: {
                width: sh120,
            },
            title: LABEL.type,
            customItem: true,
        },
        {
            icon: {
                name: handleSortIcon('uploadedOn'),
                size: '1rem',
            },
            key: [{ key: 'uploadedOn' }],
            viewStyle: {
                width: sh120,
            },
            title: LABEL.uploadedOn,
            titleStyle: {
                fontWeight: tempState.sortData.column === 'uploadedOn' ? 700 : 400,
            },
            customItem: true,
            onPressHeader: () => handleSort('uploadedOn'),
        },
        {
            icon: {
                name: handleSortIcon('lastUpdate'),
                size: '1rem',
            },
            key: [{ key: 'lastUpdate' }],
            viewStyle: {
                width: sh120,
            },
            title: LABEL.lastUpdated,
            titleStyle: {
                fontWeight: tempState.sortData.column === 'lastUpdate' ? 700 : 400,
            },
            customItem: true,
            onPressHeader: () => handleSort('lastUpdate'),
        },
        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh144,
            },
            title: LABEL.status,
            titleStyle: { fontWeight: tempState.sortData.column === 'status' ? 700 : 400 },
            onPressHeader: () => handleSort('status'),
            customItem: true,
        },
        {
            key: [{ key: 'remark' }],
            viewStyle: {
                width: sh96,
            },
            title: LABEL.remarks,
            customItem: true,
        },

        {
            key: [],
            viewStyle: {
                width: sh48,
            },
            itemIcon: {
                name: masterFundTab.actions.canPreviewMasterFund === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                masterFundTab.actions.canPreviewMasterFund === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'masterfund-view-btn',
        },
    ];
    return (
        <Fragment>
            {showResultCount && searchInput.value ? (
                <TextDarkBlack
                    size="16px"
                    weight="700"
                    style={{ margin: '24px' }}
                >{`${allMasterFundData.length} result(s) found for '${searchInput.value}'`}</TextDarkBlack>
            ) : null}
            <AdvanceTable
                data={allMasterFundData}
                columns={allMasterFundsCols}
                onEmptyState={EMPTY_STATES.emptyStateImportMasterFund}
                RenderOptions={(props: ITableOptions) => (
                    <MasterFundActions
                        {...props}
                        handleApprove={handleApprove}
                        handleReject={handleReject}
                        permissions={permissions}
                    />
                )}
                RenderItem={(props: ITableCustomItem) => (
                    <CustomItem isSortedColumn={tempState.sortData.column} {...props} />
                )}
            />
        </Fragment>
    );
};
