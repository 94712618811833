import React from 'react';
import { CustomDropdownV2, IDropdownItemV2 } from '../../../../components';
import { LABEL } from '../../../../constants';
import { IcoMoon } from '../../../../icons';

interface HistoryDateHeaderProps extends IHeaderMenu {
    dropdownData: string[];
    setData: (item: string, value: string) => void;
}

export const HistoryDateHeader = ({ dropdownData, setData }: HistoryDateHeaderProps): JSX.Element => {
    const handleAction = (item: string, rawData: ITableData | undefined, value: string | undefined) => {
        value && setData(item, value);
    };
    const handleIcon = (item: string) => {
        if (item === LABEL.createdOn && dropdownData[0] === LABEL.createdOn)
            return dropdownData[1] === 'descending'
                ? 'arrow-down'
                : dropdownData[1] === 'ascending'
                ? 'arrow-up'
                : undefined;
        else if (item === LABEL.closedOn && dropdownData[0] === LABEL.closedOn)
            return dropdownData[1] === 'descending'
                ? 'arrow-down'
                : dropdownData[1] === 'ascending'
                ? 'arrow-up'
                : undefined;
        else return undefined;
    };
    const handleValue = (value: string) => {
        return value === 'descending' ? 'ascending' : 'descending';
    };
    const items: IDropdownItemV2[] = [
        {
            item: LABEL.createdOn,
            handleItem: handleAction,
            icon: handleIcon(LABEL.createdOn),
            value: handleValue(dropdownData[1]),
        },
        {
            item: LABEL.closedOn,
            handleItem: handleAction,
            icon: handleIcon(LABEL.closedOn),
            value: handleValue(dropdownData[1]),
        },
    ];
    return (
        <CustomDropdownV2 items={items} selectIcon={true} showDivider={false} iconPosition="after" noMinWidth>
            <IcoMoon name="caret-down" size="1rem" />
        </CustomDropdownV2>
    );
};
