/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, graphqlOperation } from 'aws-amplify';
import React, { useContext, useRef, useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LinkButton } from '../../components/atoms/LinkButton';
import { CE_DASHBOARD_LABELS, LABEL, SearchOptionsBranch } from '../../constants';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import TrackOrderContext from '../../context/TrackOrderContext/TrackOrderContext';
import WebSocketContext from '../../context/WebSocketContext';
import DashboardBranchContext from '../../context/BranchContext/DashboardBranchContext';
import { useDebounce } from '../../customHooks';
import { clearContextOnNavigation, ROUTES, updateIsSeenDashboard } from '../../utils';
import { initialDateFilterBranch, initialFilterSelectStatesBranch } from './dashboardBranchTypes';
import { decoupleGroupedTrx, decoupleNonGroupedTrx } from './decoupleGroupedTrx';
import { exportListQueryPicker } from './helpers/exportListQueryPicker';
import { getFilterTagLabels, removeSearchFilterTagFromFilter } from './helpers/getFilterTagLabels';
import { pickSearchLabel } from './helpers/pickSearchLabel';
import { getTabDisplayName, selectedTab } from './helpers/selectedTab';
import { Rerouted } from './Rerouted';
import { Upcoming } from './Upcoming';
import { WithHardcopy } from './WithHardCopy';
import { History } from '../DashboardBranch/History/History';
import { transactionReportZip } from '../../_graphql/mutations/branchCE/downloadDaily/downloadDaily';
import { CollapsibleDisplay, CustomSpacer, FlexedDiv, IDropdownItem } from '../../components';
import styled from 'styled-components';
import transactionStatusMutation from '../../_graphql/mutations/branchCE/afterReviewUpdate/transactionStatus';
import { dailySummaryReportFormA } from '../../_graphql/mutations/branchCE/dailySummaryRport/dailySummaryReport';
import { downloadPPAQuery } from '../../_graphql/queries/branchCE/transaction/downloadPPA/downloadPPAQuery';
import moment from 'moment';
import { tempFilterFunctions } from './helpers/tempFilter';
import { hasKey } from '../SystemAdmin/RoleSettings/utils/functions';
import Daily from './Daily';
import { allExportListQueryPicker } from './helpers/allExportListQueryPicker';
import { updateTransactionReportStatus } from '../../_graphql/mutations/branchCE/updateTransactionReportStatus/updateTransactionReportStatus';
import * as subscriptions from '../../graphql/subscriptions';

const initialDailyFilter: ISort_FilterInput = [
    { column: 'moneySighted', value: 'true' },
    { column: 'orderType', value: 'Transaction' },
];
const dailyPendingFilter: ISort_FilterInput = [
    { column: 'orderType', value: 'Transaction' },
    { column: 'status', value: 'pending' },
];
const initialDailyFilterNonMoney: ISort_FilterInput = [
    { column: 'moneySighted', value: 'false' },
    { column: 'orderType', value: 'Transaction' },
];
const initialOtherTabFilter: ISort_FilterInput = [{ column: 'orderType', value: 'Transaction' }];
const initialHistoryTabFilter: ISort_FilterInput = [{ column: 'orderType', value: 'Transaction' }];
const modalMessages = {
    confirmation: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
    feedback: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
};

export interface IDailySummaryState {
    count: number;
    result: string[];
}
interface IDashboardBranchPageProps {
    fromInbox?: {
        currentBranchId: string;
        status: boolean;
    };
    currentTab?: string;
}
type confirmVerify = {
    state: boolean;
    trxRef: string[];
    expand: boolean;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const UseDashboardBranchHandler = () => {
    //Context
    const { userLoginContext } = useContext(AuthContext);

    const { ws } = useContext(WebSocketContext);
    const {
        // batchCutOffCount,
        cutOffTimePills,
        dailySummaryTrxCount,
        dailyV2,
        dateFilter,
        enableBulkVerify,
        filterFundingTypes,
        filterInput,
        filterInputDaily,
        filterProductTypes,
        filterRiskTypes,
        filterStatusTypes,
        filterBatchCutoffTypes,
        fundTypePills,
        filterTransactionTypes,
        getBranchDashboardDailyV2,
        getDailySummaryCount,
        getOtherTabsData,
        hardcopy,
        history,
        loading,
        loadingHandler,
        page,
        pages,
        rerouted,
        resultLimit,
        searchbarFilterTags,
        searchInput,
        setBatchCutOffCount,
        setCutOffTimePills,
        setDateFilter,
        setDisableResultLimit,
        setFilterFundingTypes,
        setFilterInput,
        setFilterInputDaily,
        setFilterProductTypes,
        setFilterSelectStates,
        setFundTypePills,
        setPage,
        setResultLimit,
        setSearchbarFilterTags,
        setSearchInput,
        setSortData,
        setTab,
        setTempFilters,
        setTempTarget,
        sortData,
        tab,
        tabsCount,
        tempFilters,
        upcoming,
        filterAccountTypes,
        setFilterAccountTypes,
        setOrderTypePills,
        orderTypePills,
        setFilterRiskTypes,
        setFilterBatchCutoffTypes,
        setFilterStatusTypes,
        setFilterTransactionTypes,
        getCrOrders,
        getAllCrOrders,
        getAllDailyV2,
        getAllOtherTabsData,
        branchStatusOptions,
        fundTypeOptions,
        accountTypeOptions,
        branchRiskOptions,
        paymentMethodOptions,
        transactionTypeOptions,
        branchBatchCutoffOptions,
    } = useContext(DashboardBranchContext);

    const { setPreviousPageURL } = useContext(TrackOrderContext);

    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const parsedPermissionPrivilege: ICeDashboard =
        parsedPermission.branch.grant !== undefined
            ? parsedPermission.branch.permission.ceTransaction
            : parsedPermission.hq.permission.ceDashboard;
    const { pendingTab, upcomingTab, hardCopyTab, reRoutedTab, historyTab } = parsedPermissionPrivilege;
    const _history = useHistory();
    const branchId = sessionStorage.getItem('branchId');
    // Common States
    const [lastUpdated, setLastUpdated] = useState<string>('Last Updated'); //Header dropdown states
    const [submittedOn, setSubmittedOn] = useState<string>('Submitted On');
    const [expand, setExpand] = useState<boolean>(false); //Filter expansion Panel Visibility State
    const [showAllTags, setShowAllTags] = useState<boolean>(false); //FilterTags expansion Panel Visibility State
    const TrackOrderButton: IActionButton = {
        title: 'Track Order',
        type: 'secondary',
        isDropdown: false,
        actionIcon: 'trackIcon',
        testId: 'track-order',
        disabled: loading || expand,
    };

    //Location Variable
    const locationState = useLocation<IDashboardBranchPageProps>();
    const [pillFundType, setPillFundType] = useState<number>(0); //FundType Pill State
    // const [pillCutOffTime, setPillCutOffTime] = useState<number>(-1); //CutOff Time Pill State
    const [showCheckBox, setShowCheckbox] = useState<boolean>(false); // Toggle Checkbox
    const [showBanner, setShowBanner] = useState<boolean>(false); // Toggle Banner
    const [selectedRows, setSelectedRows] = useState<ITableData[]>([]); // Selected Rows State
    const [showModal, setShowModal] = useState<boolean>(false); // Toggle Action Modals
    const [bulkModal, setBulkModal] = useState<boolean>(false); //Toggle bulk verify modal
    const [trackModal, setTrackModal] = useState<boolean>(false); //modal for track order
    const [bannerType, setBannerType] = useState<string>(''); // Toggle Banner Type - Bulk Verify,Transaction,Daily Summary
    const [downloadLink, setDownloadLink] = useState<string>(''); //Downloadable link returned from APIs
    const [updateTrxRefStatus, setUpdateTrxRefStatus] = useState<string[]>([]); //Set of Transaction Refs that must be updated after report is downloaded
    const [progressModal, setProgressModal] = useState<boolean>(false); //Progress Modal for transaction report generation
    const [progressWidth, setProgressWidth] = useState(0);
    const [toggleDailyReportProgress, setToggleDailyReportProgress] = useState<boolean>(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const [startTime, setStartTime] = useState(60); //60Seconds
    const [prevFilterState, setPrevFilterState] = useState<ISort_FilterInput>(initialDailyFilter); // Previous state for filters before export report
    const [selectAllFromBanner, setSelectAllFromBanner] = useState<boolean>(false); //To handle the select all shortcut from the Banner for export reports and Bulk verify
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>(''); // datepicker error messages state
    const [confirmVerify, setConfirmVerify] = useState<confirmVerify>({ state: false, trxRef: [], expand: false }); // Modal state for Confirm Modal for Bulk Verify Transactions
    const [disableSearchBarButtons, setDisableSearchBarButtons] = useState<boolean>(false); //Disabled state for Bulk Verify and Export Report in SearchBar for Daily tab
    const [subscription, setSubscription] = useState<ZenObservable.Subscription | null>(null);
    // Other Tabs Data
    const [received, setReceived] = useState(false); //Hardcopy received or not
    // Debounce
    const debouncedSearchTerm = useDebounce(searchInput, 700);

    const dueDateTab = parsedPermissionPrivilege.dueDateTab;
    const initialRenderTabs = useRef(true);
    const initialRenderFilters = useRef(true);
    const renderBulkVerify = useRef(true);
    /** @function Handle Search Placeholders for each Tab  */
    const handlePlaceHolder = () => {
        let placeholder = '';
        //
        switch (tabs[tab].name.toLowerCase()) {
            case 'daily': {
                placeholder =
                    LABEL.branchDailySearch +
                    ' ' +
                    `${searchInput.column !== 'all' ? `by ${pickSearchLabel(searchInput.column)}` : ''}`;
                break;
            }
            case 'upcoming': {
                placeholder =
                    LABEL.branchUpcomingSearch +
                    ' ' +
                    `${searchInput.column !== 'all' ? `by ${pickSearchLabel(searchInput.column)}` : ''}`;
                break;
            }
            case 'physical doc': {
                placeholder =
                    LABEL.branchHardCopySearch +
                    ' ' +
                    `${searchInput.column !== 'all' ? `by ${pickSearchLabel(searchInput.column)}` : ''}`;
                break;
            }
            case 'rerouted': {
                placeholder =
                    LABEL.branchReroutedSearch +
                    ' ' +
                    `${searchInput.column !== 'all' ? `by ${pickSearchLabel(searchInput.column)}` : ''}`;
                break;
            }
            case 'history': {
                placeholder =
                    LABEL.branchHistorySearch +
                    ' ' +
                    `${searchInput.column !== 'all' ? `by ${pickSearchLabel(searchInput.column)}` : ''}`;
                break;
            }
        }
        return placeholder;
    };

    const reInitializeDashboard = () => {
        if (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker) {
            setFundTypePills({
                tab: 0,
                label: 'Fund Types:',
                tags: [
                    {
                        active:
                            locationState.state?.fromInbox !== undefined && locationState.state.fromInbox.status
                                ? false
                                : true,
                        index: 0,
                        title: 'Money Sighted',
                        testId: 'nmoney-sighted',
                    },
                    { active: false, index: 1, title: 'Non-Money Sighted', testId: 'nonmoney-sighted' },
                ],
            });
        }
        if (
            // (tabs[tab].name.toLowerCase() === 'rerouted' &&
            //     (reRoutedTab.isAll === 'true' || reRoutedTab.actions.canViewTransactionList === LABEL.maker)) ||
            (tabs[tab].name.toLowerCase() === 'history' &&
                (historyTab.isAll === 'true' || historyTab.actions.canViewTransactionList === LABEL.maker)) ||
            (tabs[tab].name.toLowerCase() === 'daily' &&
                (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker)) ||
            (tabs[tab].name.toLowerCase() === 'with physical doc' &&
                (hardCopyTab.isAll === 'true' || hardCopyTab.actions.canViewTransactionList === LABEL.maker))
        ) {
            setOrderTypePills({
                tab: tab,
                label: 'Order Types:',
                tags: [
                    { active: true, index: 0, title: 'Transactions', testId: 'transaction' },
                    { active: false, index: 1, title: 'Change Request', testId: 'change-request' },
                ],
            });
        } else if (
            (tabs[tab].name.toLowerCase() === 'rerouted' &&
                (reRoutedTab.isAll === 'true' || reRoutedTab.actions.canViewTransactionList === LABEL.maker)) ||
            (tabs[tab].name.toLowerCase() === 'upcoming' &&
                (upcomingTab.isAll === 'true' || upcomingTab.actions.canViewTransactionList === LABEL.maker))
        ) {
            setOrderTypePills({
                tab: 0,
                label: 'Order Types:',
                tags: [
                    { active: true, index: 0, title: 'Transactions', testId: 'transaction' },
                    { active: false, index: 1, title: 'Change Request', testId: 'change-request' },
                ],
            });
        }
        setSearchbarFilterTags([]);
        showBanner ? setShowBanner(!showBanner) : null;
        setBannerType('');
        tabs[tab].name.toLowerCase() === 'daily' ? setTempFilters(initialDailyFilter) : null;
        setPillFundType(0);
        setBatchCutOffCount({ batchCount: [], batchTags: [] });
        setFilterProductTypes([]);
        setFilterFundingTypes([]);
        setFilterAccountTypes([]);
        setFilterRiskTypes([]);
        setFilterTransactionTypes([]);
        setLastUpdated('Last Updated');
        setSubmittedOn('Submitted On');
        setFilterSelectStates(initialFilterSelectStatesBranch);
        setShowCheckbox(false);
        setDisableSearchBarButtons(false);
        setTempTarget([null, null] as [moment.Moment | null, moment.Moment | null]);
        setPage(1);
        setSearchInput({ value: '', column: 'all' });
        setFilterInputDaily(initialDailyFilter);
        if (tabs[tab].name.toLowerCase() === 'history') {
            setDateFilter(initialDateFilterBranch);
            setTempFilters(initialHistoryTabFilter);
            setFilterInput(initialHistoryTabFilter);
        } else {
            setDateFilter({ dateSort: 'submittedOn', range: '' });
            if (tabs[tab].name.toLowerCase() !== 'daily') {
                setTempFilters(initialOtherTabFilter);
                setFilterInput(initialOtherTabFilter);
            }
        }

        tabs[tab].name.toLowerCase() === 'history'
            ? setSortData([{ column: 'lastUpdated', value: 'ascending' }])
            : setSortData([{ column: 'submittedOn', value: 'ascending' }]);
    };
    const clearContext = () => {
        if (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker) {
            setFundTypePills({
                tab: 0,
                label: 'Fund Types:',
                tags: [
                    {
                        active: true,
                        index: 0,
                        title: 'Money Sighted',
                        testId: 'nmoney-sighted',
                    },
                    { active: false, index: 1, title: 'Non-Money Sighted', testId: 'nonmoney-sighted' },
                ],
            });
        }
        if (
            // (tabs[tab].name.toLowerCase() === 'rerouted' &&
            //     (reRoutedTab.isAll === 'true' || reRoutedTab.actions.canViewTransactionList === LABEL.maker)) ||
            // (tabs[tab].name.toLowerCase() === 'upcoming' &&
            //     (upcomingTab.isAll === 'true' || upcomingTab.actions.canViewTransactionList === LABEL.maker)) ||
            (tabs[tab].name.toLowerCase() === 'with physical doc' &&
                (hardCopyTab.isAll === 'true' || hardCopyTab.actions.canViewTransactionList === LABEL.maker)) ||
            (tabs[tab].name.toLowerCase() === 'history' &&
                (historyTab.isAll === 'true' || historyTab.actions.canViewTransactionList === LABEL.maker)) ||
            (tabs[tab].name.toLowerCase() === 'daily' &&
                (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker))
        ) {
            setOrderTypePills({
                tab: 0,
                label: 'Order Types:',
                tags: [
                    { active: true, index: 0, title: 'Transactions', testId: 'transaction' },
                    { active: false, index: 1, title: 'Change Request', testId: 'change-request' },
                ],
            });
        }
        setPage(1);
        setSearchInput({ value: '', column: 'all' });
        setFilterInputDaily(initialDailyFilter);
        setFilterSelectStates(initialFilterSelectStatesBranch);
        if (tabs[tab].name.toLowerCase() === 'history') {
            setDateFilter(initialDateFilterBranch);
            setTempFilters(initialHistoryTabFilter);
            setFilterInput(initialHistoryTabFilter);
        } else {
            setDateFilter({ dateSort: 'submittedOn', range: '' });
            if (tabs[tab].name.toLowerCase() !== 'daily') {
                setTempFilters(initialOtherTabFilter);
                setFilterInput(initialOtherTabFilter);
            }
        }
        tabs[tab].name.toLowerCase() === 'daily' ? setTempFilters(initialDailyFilter) : null;
        setPillFundType(0);
        setBatchCutOffCount({ batchCount: [], batchTags: [] });
        setFilterProductTypes([]);
        setFilterTransactionTypes([]);
        setFilterFundingTypes([]);
        setFilterAccountTypes([]);
        setFilterRiskTypes([]);
        setFilterStatusTypes([]);
        setFilterBatchCutoffTypes([]);
        setLastUpdated('Last Updated');
        setSubmittedOn('Submitted On');
        setTab(0);
        setTempTarget([null, null] as [moment.Moment | null, moment.Moment | null]);
        setSearchbarFilterTags([]);
    };

    /**@function Fetch data based  tab given @param tab number */
    const fetchData = (_tab: string) => {
        const isChangeRequestTab = _tab.toLowerCase() !== 'rerouted';
        if (_history.location.pathname.includes('all-branches')) {
            if (
                orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request').length !== 0 &&
                isChangeRequestTab
            ) {
                getAllCrOrders(selectedTab(tabs[tab].name.toLowerCase()));
            } else
                _tab.toLowerCase() === 'daily'
                    ? getAllDailyV2()
                    : getAllOtherTabsData(selectedTab(tabs[tab].name.toLowerCase()));
        } else {
            if (
                orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request').length !== 0 &&
                isChangeRequestTab
            ) {
                getCrOrders(selectedTab(tabs[tab].name.toLowerCase()));
            } else
                _tab.toLowerCase() === 'daily'
                    ? getBranchDashboardDailyV2()
                    : getOtherTabsData(selectedTab(tabs[tab].name.toLowerCase()));
        }
    };

    //Subscription
    const exportListSubscription = async () => {
        const sub = await API.graphql(graphqlOperation(subscriptions.onDashboardExportList)).subscribe({
            next: (eventData: ISubscriptionResponse<OnDashboardExportListSubscription>) => {
                const { data } = eventData.value;
                if (data) {
                    if (data.onDashboardExportList?.data) {
                        const { result } = data.onDashboardExportList.data;
                        if (result) {
                            let exportWindow = null;
                            result.url !== null && result.url !== undefined
                                ? (exportWindow = window.open(result.url, '_blank'))
                                : null;
                            exportWindow?.addEventListener('beforeunload', () => {
                                setSubscription(null);
                            });
                        }
                    } else if (
                        data.onDashboardExportList?.error !== null &&
                        data.onDashboardExportList?.error !== undefined
                    ) {
                        const _error = data.onDashboardExportList?.error;
                        if (Object.keys(_error).includes('errorCode')) {
                            handleErrorHandler();
                            setErrorMessage({
                                ...errorMessage,
                                message: _error.message as string,
                                errorCode: _error.errorCode as string,
                                title: 'Cannot Generate Export List',
                                testId: 'hq-dashboard-error-modal',
                            });
                        } else {
                            setErrorMessage({
                                errorCode: '',
                                message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                                title: 'Something Went Wrong',
                                testId: 'gql-error-modal',
                            });
                            handleErrorHandler();
                        }
                    }
                }
            },
            error: (error: any) => {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
                console.warn(error);
            },
        });
        setSubscription(sub);
    };
    const dailySummarySubscription = async () => {
        const sub = await API.graphql(graphqlOperation(subscriptions.dailySummaryReportProgress)).subscribe({
            next: (eventData: ISubscriptionResponse<IDailySummaryReportProgress>) => {
                const { data } = eventData.value;
                if (data) {
                    if (data.dailySummaryReportProgress?.data) {
                        const { result } = data.dailySummaryReportProgress.data;
                        if (result) {
                            setToggleDailyReportProgress(true);
                            setProgressWidth(1);
                            if (result.event === 'progress') {
                                setProgressWidth(parseInt(result.message?.toString() ?? '0'));
                            } else if (result.event === 'result') {
                                setProgressWidth(100);
                                setToggleDailyReportProgress(false);
                                setShowModal(!showModal);
                                result.message && setDownloadLink(result.message);
                                modalMessages.feedback.content = <div />;
                                subscription && subscription.unsubscribe();
                                setSubscription(null);
                            }
                        }
                    } else if (
                        data.dailySummaryReportProgress?.error !== null &&
                        data.dailySummaryReportProgress?.error !== undefined
                    ) {
                        setToggleDailyReportProgress(false);
                        const _error = data.dailySummaryReportProgress?.error;
                        if (Object.keys(_error).includes('errorCode')) {
                            handleErrorHandler();
                            setErrorMessage({
                                ...errorMessage,
                                message: _error.message as string,
                                errorCode: _error.errorCode as string,
                                title: 'Cannot Generate Export List',
                                testId: 'hq-dashboard-error-modal',
                            });
                        } else {
                            setErrorMessage({
                                errorCode: '',
                                message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                                title: 'Something Went Wrong',
                                testId: 'gql-error-modal',
                            });
                            handleErrorHandler();
                        }
                    }
                }
            },
            error: (error: any) => {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
                console.warn(error);
            },
        });
        setSubscription(sub);
    };
    //Queries

    const getExportList = async () => {
        try {
            const exportQuery =
                selectedTab(tabs[tab].name.toLowerCase()) !== 'with physical doc'
                    ? exportListQueryPicker(lastUpdated)
                    : exportListQueryPicker('submittedOn');
            const response: any = await API.graphql(
                graphqlOperation(exportQuery, {
                    input: {
                        tab: selectedTab(tabs[tab].name.toLowerCase()),
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        branchId: branchId !== null ? branchId : '',
                        filter: tabs[tab].name.toLowerCase() === 'daily' ? filterInputDaily : filterInput,
                        endpoint: 'branchdashboardv2',
                        backgroundProcess: true,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.dashboardExportList;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            // const { result } = response.data.dashboardExportList.data;
            exportListSubscription();
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Generate Export List',
                    testId: 'branch-error-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const getExportListAllSubmitted = async () => {
        try {
            const exportQuery =
                selectedTab(tabs[tab].name) !== 'with physical doc'
                    ? allExportListQueryPicker(lastUpdated)
                    : allExportListQueryPicker('submittedOn');
            const response: any = await API.graphql(
                graphqlOperation(exportQuery, {
                    input: {
                        tab: selectedTab(tabs[tab].name),
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        branchId: '',
                        filter: tabs[tab].name === 'daily' ? filterInputDaily : filterInput,
                        endpoint: 'allsubmitteddashboard',
                        backgroundProcess: true,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.dashboardExportList;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            // const { result } = response.data.dashboardExportList.data;
            // handleExportListProgress(result.message);
            exportListSubscription();
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Generate Export List',
                    testId: 'branch-error-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const handleExportList = async () => {
        setProgressWidth(0);
        _history.location.pathname.includes('all-branches') ? getExportListAllSubmitted() : getExportList();
    };

    // Mutation
    /** @description: The Parent function for transaction report download */
    const downloadTransactionReport = async () => {
        // Retrieving the transaction Refs of the selected rows
        setProgressWidth(0);
        setProgressModal(true);
        // setTimeLeft(selectedRows.length * 25);
        const temp = [...selectedRows];
        const transactionRefs = temp.map((item) => {
            return item.transactionRef as string;
        });
        if (ws !== undefined) {
            downloadReportAsZip(transactionRefs);
            ws.addEventListener('message', (event) => {
                if (event.data !== 'PONG' && event.data !== 'pong') {
                    const data = JSON.parse(event.data);
                    if (data.body.data !== undefined) {
                        const { result } = data.body.data;
                        if (result.link !== null) {
                            setDownloadLink(result.link);
                            // FileSaver.saveAs(result.link);
                            setProgressWidth(100);
                            setTimeLeft(0);
                            setTimeout(() => {
                                setProgressModal(false);
                                setUpdateTrxRefStatus(transactionRefs);
                                setShowModal(!showModal);
                            }, 500);
                        }
                    }
                    if (data.body.error !== undefined) {
                        const { error } = data.body;
                        handleErrorHandler();
                        setErrorMessage({
                            ...errorMessage,
                            message: error.message,
                            errorCode: error.errorCode,
                            title: 'Cannot Generate Zip File',
                            testId: 'branch-error-modal',
                        });
                        setProgressModal(false);
                        setProgressWidth(0);
                        setSelectedRows([]);
                    }
                }
            });
        }
    };
    /**
     * @param ref: Array of selected transaction refs
     * @description This query is called once the function downloadReports is executed to get the download link for the zip file containing the reports
     * */
    const downloadReportAsZip = async (transactionRefs: string[]) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(transactionReportZip, {
                    input: {
                        transactionRef: transactionRefs,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.downloadDaily;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.downloadDaily.data;

            if (result.link !== null) {
                typeof result.link === 'string' ? setTimeLeft(parseInt(result.link)) : setTimeLeft(result.link);

                setStartTime(parseInt(result.link));
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Generate Zip File',
                    testId: 'branch-error-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
            setProgressModal(false);
            setProgressWidth(0);
        }
    };

    /** */
    const updateStatusAfterTrxReportDownload = async (transactionRefs: string[]) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(updateTransactionReportStatus, {
                    input: {
                        transactionRef: transactionRefs,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.updateTransactionReportStatus;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = resultCheck.data;
            result.message === 'Transaction report status changed successfully.' ? setUpdateTrxRefStatus([]) : null;
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Update Status of Transactions',
                    testId: 'branch-error-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
            setUpdateTrxRefStatus([]);
        }
    };
    const handleConfirmVerifyModal = () => {
        setConfirmVerify({ ...confirmVerify, expand: !confirmVerify.expand });
    };
    const getModalMessage = (selectedTrxns: ITableData[]) => {
        const temp = [...selectedTrxns];
        const transactionRefs = temp.map((item) => {
            const tempRef = `${item.orderNo}${item.transactionRef}`;
            return tempRef as string;
        });
        return (
            <FlexedDiv direction="column">
                {orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request').length !== 0 ? (
                    pendingTab.actions.canVerifyTransactions === 'auto-authorizer' ? (
                        <Subtitle>{CE_DASHBOARD_LABELS.DAILY.verifyOnlyThisTransactionMessageBulk}</Subtitle>
                    ) : (
                        <Subtitle>{CE_DASHBOARD_LABELS.DAILY.verifyThisTransactionMessageCrBulk}</Subtitle>
                    )
                ) : (
                    <Subtitle>{CE_DASHBOARD_LABELS.DAILY.verifyThisTransactionMessage}</Subtitle>
                )}
                <CustomSpacer space={'.5rem'} />
                <Subtitle>{CE_DASHBOARD_LABELS.DAILY.yesOrNoMessage}</Subtitle>
                <CustomSpacer space={'1rem'} />
                <CollapseWrapper removeBorder={confirmVerify.expand}>
                    <CollapsibleDisplay
                        displayTextForExpand={CE_DASHBOARD_LABELS.DAILY.viewMore}
                        displayTextForCollapse={CE_DASHBOARD_LABELS.DAILY.viewLess}
                        textColor="#002043"
                        expanded={confirmVerify.expand}
                        handleExpand={() => handleConfirmVerifyModal()}
                        testId="confirm"
                        content={
                            <div>
                                <Bold
                                    style={{ fontSize: '0.75rem' }}
                                >{`${transactionRefs.length} selected transactions`}</Bold>
                                <TransactionRefList>
                                    {transactionRefs.map((item, index) => (
                                        <li key={index + 1}>{item}</li>
                                    ))}
                                </TransactionRefList>
                            </div>
                        }
                    />
                </CollapseWrapper>
            </FlexedDiv>
        );
    };
    const handleBulkVerifyTransactions = () => {
        const temp = [...selectedRows];
        const transactionRefs = temp.map((item) => {
            return item.transactionRef as string;
        });
        setConfirmVerify({ ...confirmVerify, trxRef: transactionRefs, state: true });

        // set confirmation modal data
        modalMessages.confirmation.heading = `Verify ${transactionRefs.length} transactions?`;
        modalMessages.confirmation.type = 'verify';
        if (pendingTab.actions.canVerifyTransactions === 'auto-authorizer') {
            // set feedback modal data
            modalMessages.feedback.content =
                orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request').length !== 0 ? (
                    <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px' }}>
                        <Bold>{`${transactionRefs.length} transactions`}</Bold>
                        &nbsp; has been verified. Adviser will be notified.
                    </p>
                ) : (
                    <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px' }}>
                        <Bold>{`${transactionRefs.length} transactions`}</Bold>
                        &nbsp; has been verified. Adviser will be notified once all transactions in this order has been
                        verified.
                    </p>
                );
        } else {
            // set feedback modal data
            modalMessages.feedback.content = (
                <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px', marginTop: '-1rem' }}>
                    This request has been submitted and pending for review.
                </p>
            );
        }
    };
    const bulkVerifyTransactions = async () => {
        const temp = [...selectedRows];
        const transactionRefs = temp.map((item) => {
            return item.transactionRef as string;
        });
        try {
            const response: any = await API.graphql(
                graphqlOperation(transactionStatusMutation, {
                    input: {
                        transactionRef: transactionRefs,
                        action: 'Approve',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionStatus;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.transactionStatus.data;

            if (result.status === true) {
                loadingHandler();
                setBulkModal(!bulkModal);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Bulk Verify Transactions',
                    testId: 'branch-error-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const trackOrder = () => {
        if (_history.location.pathname.includes('all-branches')) _history.push(ROUTES.hqAllSubmissionsTrackOrder);
        else
            _history.push(
                branchId !== null && branchId !== '' ? ROUTES.hqSingleBranchTrackOrder : ROUTES.branchTrackOrder,
            );
    };

    const downloadDailySummary = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(dailySummaryReportFormA, {
                    input: {
                        branchId: branchId !== null ? branchId : '',
                        backgroundProcess: true,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.dailySummaryReport;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = response.data.dailySummaryReport.data;
            if (result) {
                loadingHandler();
                dailySummarySubscription();
            }
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Download Daily Summary Report',
                    testId: 'branch-error-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
            fetchData('daily');
        }
    };
    const downloadPPA = async () => {
        const temp = [...selectedRows];
        const transactionRefs = temp.map((item) => {
            return item.transactionRef as string;
        });
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(downloadPPAQuery, {
                    input: {
                        transactionRef: transactionRefs,
                        tab: 'daily',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.ppaDownloadV2;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.ppaDownloadV2.data;

            setDownloadLink(result.link);
            loadingHandler();
            setShowModal(!showModal);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    errorList: _error.errorList,
                    title: 'Cannot Download PPA Account Opening Form',
                    testId: 'branch-error-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    //Pagination Functions
    const handleNext = () => {
        if (page < pages) {
            setPage(page + 1);
        }
    };

    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };
    // Receive Hardcopy
    const handleReceived = () => {
        setReceived(!received);
        setTimeout(() => {
            fetchData('hardcopy');
        }, 1000);
    };
    const switchTabs = (index: number) => {
        setTab(index);
    };
    // Search Primary
    const handleExportReportDropdown = (item: string) => {
        item !== undefined ? setShowCheckbox(!showCheckBox) : null;
        switch (item) {
            case LABEL.transactionsReport:
                setSearchbarFilterTags([{ label: 'Status', value: 'Pending' }]);
                handleFilterTableForExportReport(LABEL.transactionsReport);
                setBannerType(LABEL.transactionsReport);
                setResultLimit(10); //Restricting download limit to 10
                setDisableResultLimit(true); //Disabling Result limit dropdown
                break;
            case LABEL.dailySummaryReportFormA:
                setBannerType(LABEL.dailySummaryReportFormA);
                setSearchbarFilterTags([{ label: 'Status', value: 'Completed' }]);
                handleFilterTableForExportReport(LABEL.dailySummaryReportFormA);
                break;
            case LABEL.ppaOnlineAccountOpeningForm:
                setBannerType(LABEL.ppaOnlineAccountOpeningForm);
                setResultLimit(10); //Restricting download limit to 10
                setDisableResultLimit(true); //Disabling Result limit dropdown
                setSearchbarFilterTags([
                    { label: 'Status', value: 'Completed' },
                    { label: 'Product Type', value: 'PRS' },
                    { label: 'Product Type', value: 'PRS Default' },
                    { label: 'Trans Type', value: 'Sales - AO' },
                ]);
                handleFilterTableForExportReport(LABEL.ppaOnlineAccountOpeningForm);
                break;
        }
        setDisableSearchBarButtons(!disableSearchBarButtons);
        setShowBanner(!showBanner);
    };
    const handleFilterTableForExportReport = (filterAction: string) => {
        const currentFilter = [...filterInputDaily];
        switch (filterAction) {
            case LABEL.transactionsReport:
                setPrevFilterState(currentFilter);
                const tempTrx = [...currentFilter];
                const trxFilter = tempTrx.filter((item) => {
                    return item.column !== 'status';
                });
                trxFilter.push({ column: 'status', value: 'pending' });
                setTempFilters(trxFilter);
                setFilterInputDaily(trxFilter);
                break;
            case LABEL.dailySummaryReportFormA:
                setPrevFilterState(currentFilter);
                getDailySummaryCount();
                const tempDsa = [...currentFilter];
                const dsaFilter = tempDsa.filter((item) => {
                    return item.column !== 'status' && item.column !== 'moneySighted';
                });
                dsaFilter.push({ column: 'status', value: 'completed' });
                setTempFilters(dsaFilter);
                setFilterInputDaily(dsaFilter);
                break;
            case LABEL.ppaOnlineAccountOpeningForm:
                setPrevFilterState(currentFilter);
                const tempPpa = [...currentFilter];
                const ppaFilter = tempPpa.filter((item) => {
                    return item.column !== 'fundType' && item.column !== 'transactionType' && item.column !== 'status';
                });
                ppaFilter.push(
                    { column: 'fundType', value: 'prs' },
                    { column: 'fundType', value: 'prs default' },
                    { column: 'status', value: 'completed' },
                    { column: 'utmc', value: LABEL.kib.toLowerCase() },
                    { column: 'transactionType', value: 'sales-ao' },
                );

                setTempFilters(ppaFilter);
                setFilterInputDaily(ppaFilter);
                break;
            case LABEL.bulkTransactionVerification:
                setPrevFilterState(currentFilter);
                const tempBulkVerify = [...currentFilter];
                const bulkFilter = tempBulkVerify.filter((item) => {
                    return item.column !== 'status';
                });
                bulkFilter.push({ column: 'status', value: 'in review' });
                setTempFilters(bulkFilter);
                setFilterInputDaily(bulkFilter);
                break;
            case 'revert':
                const orderType = prevFilterState.filter((item) => {
                    return item.column === 'orderType';
                });
                const _pills = { ...orderTypePills };

                switch (orderType[0].value.toLowerCase()) {
                    case 'transaction':
                        _pills.tags.map((pill) => {
                            pill.index === 0 && pill.active === false ? (pill.active = true) : null;
                            pill.index === 1 && pill.active === true ? (pill.active = false) : null;
                        });
                        break;
                    case 'change request':
                        _pills.tags.map((pill) => {
                            pill.index === 1 && pill.active === false ? (pill.active = true) : null;
                            pill.index === 0 && pill.active === true ? (pill.active = false) : null;
                        });
                        break;
                }
                setOrderTypePills(_pills);
                setTempFilters(prevFilterState);
                let _searchFilterTags: ISelectValue[] = [];
                prevFilterState.map((item) => {
                    switch (item.column) {
                        case 'lastUpdated':
                            setSubmittedOn(LABEL.lastUpdated);
                            break;
                        case 'submittedOn':
                            setSubmittedOn(LABEL.submittedOn);
                            break;
                        case 'createdOn':
                            setSubmittedOn(LABEL.createdOn);
                    }
                });
                _searchFilterTags = getFilterTagLabels(_searchFilterTags, prevFilterState);
                setSearchbarFilterTags(_searchFilterTags);
                setFilterInputDaily(prevFilterState);
                break;
        }
    };
    // Search Secondary Actions
    const handleSearchActions = (action: IActionButton) => {
        action.title === 'Export List' ? handleExportList() : null;
        action.title === 'Bulk Verify' ? handleBulkVerify() : null;
    };

    // opens modal for track order
    const OpenTrackOrderModal = () => {
        setTrackModal(true);
    };

    const closeTrackModal = () => {
        setTrackModal(false);
    };
    // Handle Bulk Verify
    const handleBulkVerify = () => {
        handleFilterTableForExportReport(LABEL.bulkTransactionVerification);
        setShowCheckbox(!showCheckBox);
        setBannerType(LABEL.bulkTransactionVerification);
        setDisableSearchBarButtons(!disableSearchBarButtons);
        setSearchbarFilterTags([{ label: 'Status', value: 'In Review' }]);
        setShowBanner(!showBanner);
    };

    const handleDownload = () => {
        switch (bannerType) {
            case LABEL.transactionsReport:
                setDisableResultLimit(false);
                ws !== undefined ? ws.send('Ping') : null;
                downloadTransactionReport();
                break;
            case LABEL.bulkTransactionVerification:
                handleBulkVerifyTransactions();
                break;
            case LABEL.dailySummaryReportFormA:
                downloadDailySummary();
                break;
            case LABEL.ppaOnlineAccountOpeningForm:
                setDisableResultLimit(false);
                loadingHandler();
                downloadPPA();
                break;
            default:
                break;
        }
    };
    //Daily Filter functions
    const handlePillFundType = (item: IDashboardFilterTags) => {
        let temp = [...tempFilters];

        const tempPill = { ...fundTypePills };
        if (item.index === 0 && item.active === false) {
            temp = temp.filter((item) => {
                return item.column !== 'moneySighted';
            });
            tempPill.tags[item.index].active = true;
            tempPill.tags[1].active = false;
            temp.push({ column: 'moneySighted', value: 'true' });
        } else if (item.index === 0 && item.active) {
            temp = temp.filter((item) => {
                return item.column !== 'moneySighted';
            });
            tempPill.tags[item.index].active = false;
            // temp.push({ column: 'fundCategory', value: '' });
        }
        if (item.index === 1 && item.active === false) {
            temp = temp.filter((item) => {
                return item.column !== 'moneySighted';
            });
            tempPill.tags[item.index].active = true;
            tempPill.tags[0].active = false;
            temp.push({ column: 'moneySighted', value: 'false' });
        } else if (item.index === 1 && item.active) {
            temp = temp.filter((item) => {
                return item.column !== 'moneySighted';
            });
            tempPill.tags[item.index].active = false;
        }

        setPillFundType(item.index);
        setFundTypePills(tempPill);
        setTempFilters(temp);
        setFilterInputDaily(temp);
    };
    const handlePillCutOffTime = (item: IDashboardFilterTags) => {
        const temp = [...tempFilters];
        const tempPill = cutOffTimePills;

        const itemIndex = item.index;
        if (itemIndex === 0 && item.active === false) {
            temp[1] = { column: 'batchTime', value: 'all' };
            tempPill.tags[0].active = true;
            for (let i = 0; i < tempPill.tags.length; i++) {
                i !== 0 ? (tempPill.tags[i].active = false) : null;
            }
        } else if (item.active === true && itemIndex === 0) {
            temp[1] = { column: 'batchTime', value: '' };
            tempPill.tags[0].active = false;
        } else if (itemIndex !== 0 && item.active === false) {
            temp[1] = { column: 'batchTime', value: item.title };
            tempPill.tags[itemIndex].active = true;
            for (let i = 0; i < tempPill.tags.length; i++) {
                i !== itemIndex ? (tempPill.tags[i].active = false) : null;
            }
        } else if (itemIndex !== 0 && item.active === true) {
            temp[1] = { column: 'batchTime', value: '' };
            tempPill.tags[itemIndex].active = false;
        }
        setCutOffTimePills(tempPill);
        setTempFilters(temp);
        setFilterInputDaily(temp);
    };

    // Common filters
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item, '"M/D/YYYY H:mm"').valueOf();
            time.push(milliSeconds);
        });
        const _validFrom = time[0] < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = time[1] < moment().add(1, 'day').startOf('day').valueOf() && time[1] > time[0];
        // Date range in milliseconds
        if (_validFrom && _validTo) {
            // Date range in milliseconds
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
            const range = time.toString().replace(',', '~');
            setDateFilter({ dateSort: dateFilter.dateSort, range: range });
            const obj: ISearchInput = {
                column: dateFilter.dateSort,
                value: range,
            };
            let tmp = [...tempFilters];
            tmp = tmp.filter((item) => {
                return item.column !== 'lastUpdated' && item.column !== 'createdOn' && item.column !== 'submittedOn';
            });
            tmp.push(obj);
            setTempFilters(tmp);
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            time[1] < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            time[1] < time[0] && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
            let tmp = [...tempFilters];
            tmp = tmp.filter((item) => {
                return item.column !== 'lastUpdated' && item.column !== 'createdOn' && item.column !== 'submittedOn';
            });
            tmp.push({
                column: dateFilter.dateSort,
                value: '',
            });
            setTempFilters(tmp);
        }
    };

    const handleDateSorting = (value: string | number) => {
        let tmp = [...tempFilters];
        setDateFilter({ ...dateFilter, dateSort: value.toString() });
        let range = '';
        if (dateFilter.range !== '') range = dateFilter.range;
        tmp = tmp.filter((item) => {
            return item.column !== 'lastUpdated' && item.column !== 'createdOn' && item.column !== 'submittedOn';
        });
        tmp.push({ column: value.toString(), value: range });
        setTempFilters(tmp);
    };

    const handleTransactionTypes = (item: React.ReactText[]) => {
        setFilterTransactionTypes(item);
        const formattedData = item.map((item) => {
            return item.toString().toLowerCase();
        });
        const tmp = [...tempFilters];
        setTempFilters(tempFilterFunctions(tmp, 'transactionType', formattedData));
    };

    const handleProductTypes = (item: React.ReactText[]) => {
        setFilterProductTypes(item);
        const formattedData = item.map((item) => {
            return item.toString().toLowerCase();
        });
        const tmp = [...tempFilters];
        setTempFilters(tempFilterFunctions(tmp, 'fundType', formattedData));
    };

    const handleFundingOptions = (item: React.ReactText[]) => {
        setFilterFundingTypes(item);
        const tmp = [...tempFilters];
        setTempFilters(tempFilterFunctions(tmp, 'paymentMethod', item));
    };

    const handleAccountTypes = (item: React.ReactText[]) => {
        setFilterAccountTypes(item);
        const tmp = [...tempFilters];
        setTempFilters(tempFilterFunctions(tmp, 'accountType', item));
    };
    const handleStatusTypeFilter = (item: React.ReactText[]) => {
        setFilterStatusTypes(item);
        const tmp = [...tempFilters].filter((filter) => filter.column !== 'status');
        item.map((filterItem) => {
            tmp.push({ column: 'status', value: filterItem as string });
        }),
            setTempFilters(tmp);
    };

    const handleRiskTypeFilter = (item: React.ReactText[]) => {
        setFilterRiskTypes(item);
        const tmp = [...tempFilters];
        setTempFilters(tempFilterFunctions(tmp, 'riskCategory', item));
    };

    const handleBatchCutoffTypeFilter = (item: React.ReactText[]) => {
        setFilterBatchCutoffTypes(item);
        const tmp = [...tempFilters].filter((filter) => filter.column !== 'cutOffTime');
        item.map((filterItem) => {
            tmp.push({ column: 'cutOffTime', value: filterItem as string });
        }),
            setTempFilters(tmp);
    };

    const handleCancelFilter = () => {
        const tempDaily = [...tempFilters];
        const tempSort = [...sortData];
        if (tabs[tab].name.toLowerCase() === 'history') {
            setLastUpdated(LABEL.lastUpdated);
            setDateFilter(initialDateFilterBranch);
            tempSort[0].column === 'submittedOn' ? setSortData([{ column: 'lastUpdated', value: 'ascending' }]) : null;
        } else {
            setSubmittedOn(LABEL.submittedOn);
            setDateFilter({ dateSort: 'submittedOn', range: '' });
            tempSort[0].column === 'lastUpdated' ? setSortData([{ column: 'submittedOn', value: 'ascending' }]) : null;
        }
        setSearchbarFilterTags([]);
        setFilterSelectStates(initialFilterSelectStatesBranch);
        setFilterProductTypes([]);
        setFilterTransactionTypes([]);
        setFilterFundingTypes([]);
        setFilterAccountTypes([]);
        setFilterStatusTypes([]);
        setFilterBatchCutoffTypes([]);
        setFilterRiskTypes([]);
        setTempTarget([null, null]);
        if (tabs[tab].name.toLowerCase() === 'daily') removeDailyFilters(tempDaily);
        else {
            setTempFilters([{ column: 'orderType', value: 'Transaction' }]);
            setFilterInput([{ column: 'orderType', value: 'Transaction' }]);
        }
    };

    const removeDailyFilters = (temp: ISort_FilterInput) => {
        const removedFilterArray: ISort_FilterInput = [];
        temp.map((item) => {
            switch (item.column) {
                case 'moneySighted':
                    removedFilterArray.push(item);
                    break;
                case 'batchTime':
                    removedFilterArray.push(item);
                    break;
                case 'orderType':
                    removedFilterArray.push(item);
                    break;
            }
        });
        setTempFilters(removedFilterArray);
        setFilterInputDaily(removedFilterArray);
    };

    const handleApplyFilter = () => {
        let _searchFilterTags: ISelectValue[] = [];
        switch (tabs[tab].name.toLowerCase()) {
            case 'daily':
                tempFilters.map((item) => {
                    switch (item.column) {
                        case 'lastUpdated':
                            setSubmittedOn(LABEL.lastUpdated);
                            break;
                        case 'submittedOn':
                            setSubmittedOn(LABEL.submittedOn);
                            break;
                        case 'createdOn':
                            setSubmittedOn(LABEL.createdOn);
                    }
                });
                _searchFilterTags = getFilterTagLabels(_searchFilterTags, tempFilters);
                setSearchbarFilterTags(_searchFilterTags);
                setFilterInputDaily(tempFilters);
                break;
            case 'history':
                tempFilters.map((item) => {
                    switch (item.column) {
                        case 'lastUpdated':
                            setLastUpdated(LABEL.lastUpdated);
                            break;
                        case 'submittedOn':
                            setLastUpdated(LABEL.submittedOn);
                            break;
                        case 'createdOn':
                            setLastUpdated(LABEL.createdOn);
                            break;
                    }
                });
                _searchFilterTags = getFilterTagLabels(_searchFilterTags, tempFilters);
                setSearchbarFilterTags(_searchFilterTags);
                setFilterInput(tempFilters);
                break;

            default:
                tempFilters.map((item) => {
                    switch (item.column) {
                        case 'lastUpdated':
                            setSubmittedOn(LABEL.lastUpdated);
                            break;
                        case 'submittedOn':
                            setSubmittedOn(LABEL.submittedOn);
                            break;
                        case 'createdOn':
                            setSubmittedOn(LABEL.createdOn);
                            break;
                    }
                });
                _searchFilterTags = getFilterTagLabels(_searchFilterTags, tempFilters);
                setSearchbarFilterTags(_searchFilterTags);
                setFilterInput(tempFilters);
                break;
        }
        setSortData([{ column: dateFilter.dateSort, value: 'ascending' }]);
        setExpand(!expand);
    };

    const getSecondaryButtons = () => {
        const secondaryButtons: IActionButton[] = [];
        let section = 'pendingTab';
        switch (tabs[tab].name.toLowerCase()) {
            case 'daily': {
                section = 'pendingTab';
                break;
            }
            case 'upcoming': {
                section = 'upcomingTab';
                break;
            }
            case 'with physical doc': {
                section = 'hardCopyTab';
                break;
            }
            case 'rerouted': {
                section = 'reRoutedTab';
                break;
            }
            case 'history': {
                section = 'historyTab';
                break;
            }
        }
        const currentSection: any = hasKey(parsedPermissionPrivilege, section)
            ? parsedPermissionPrivilege[section]
            : parsedPermissionPrivilege.pendingTab;
        if (currentSection.actions.canExportList === LABEL.maker) {
            secondaryButtons.push({
                type: 'secondary',
                title: 'Export List',
                actionIcon: 'export',
                disabled:
                    tabsCount[tab] === 0 ||
                    loading ||
                    disableSearchBarButtons ||
                    (tabs[tab].name.toLowerCase() === 'daily'
                        ? decoupleGroupedTrx(dailyV2).length === 0 || expand
                        : decoupleNonGroupedTrx(getTabs(selectedTab(tabs[tab].name.toLowerCase()))).length === 0 ||
                          expand),
                testId: 'export-list-btn',
            });
        }
        if (
            currentSection.actions.canVerifyTransactions === LABEL.maker ||
            currentSection.actions.canVerifyTransactions === LABEL.autoAuthorizer
        ) {
            secondaryButtons.push({
                type: 'secondary',
                title: 'Bulk Verify',
                actionIcon: 'bulk',
                testId: 'bulk-verify-btn',
                disabled:
                    tabsCount[tab] === 0 || enableBulkVerify === false || disableSearchBarButtons || loading || expand,
            });
        }
        return secondaryButtons;
    };

    const getExportReportPermissionsCheck = (): IDropdownItem[] => {
        const dropdown: IDropdownItem[] = [];
        const menuItemDailySummaryFormA: IDropdownItem =
            dailySummaryTrxCount.count !== 0
                ? {
                      item: LABEL.dailySummaryReportFormA,
                      handleItem: handleExportReportDropdown,
                      testId: `daily-summary-report-btn`,
                      disabled: loading,
                  }
                : {
                      item: LABEL.dailySummaryReportFormA,
                      handleItem: handleExportReportDropdown,
                      disabled: true,
                      tooltipMessage: LABEL.noCompletedTransactionMessage,
                      testId: `daily-summary-report-btn`,
                  };
        const menuItemTransactionReport: IDropdownItem = {
            item: LABEL.transactionsReport,
            handleItem: handleExportReportDropdown,
            testId: `daily-trnsactions-report-btn`,
            disabled: loading,
        };
        const isPpaConditionsPresent = () => {
            return (
                branchStatusOptions.filter((filter) => {
                    return filter.toString().toLowerCase() === 'completed';
                }).length === 0 ||
                fundTypeOptions.filter((filter) => {
                    return filter.toString().toLowerCase().includes('prs');
                }).length === 0
            );
        };
        const menuItemPPAOnlineAccountOpeningForm: IDropdownItem = {
            item: LABEL.ppaOnlineAccountOpeningForm,
            handleItem: handleExportReportDropdown,
            testId: `daily-ppa-form-btn`,
            disabled: loading || isPpaConditionsPresent(),
            tooltipMessage: isPpaConditionsPresent() ? LABEL.noCompletedTransactionMessage : undefined,
        };
        pendingTab.actions.canDownloadDailySummary === LABEL.maker &&
        !_history.location.pathname.includes(ROUTES.hqAllSubmissions)
            ? dropdown.push(menuItemDailySummaryFormA)
            : null;
        pendingTab.actions.canDownloadTransactionReport === LABEL.maker
            ? dropdown.push(menuItemPPAOnlineAccountOpeningForm)
            : null;
        pendingTab.actions.canDownloadTransactionReport === LABEL.maker
            ? dropdown.push(menuItemTransactionReport)
            : null;
        return dropdown;
    };

    const disableBannerButton = (): boolean => {
        let temp = false;
        if (
            bannerType === LABEL.transactionsReport ||
            bannerType === LABEL.bulkTransactionVerification ||
            bannerType === LABEL.ppaOnlineAccountOpeningForm
        ) {
            temp = selectedRows.length === 0;
        } else if (bannerType === LABEL.dailySummaryReportFormA) {
            temp = dailySummaryTrxCount.count === 0;
        }
        return temp;
    };
    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        SearchOptionsBranch.daily.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInput({ ...searchInput, column: value }),
                testId: `branch-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };

    const handleOrderTypePills = (item: IDashboardFilterTags) => {
        if (bannerType === '') {
            setOrderTypePills({
                ...orderTypePills,
                tags: orderTypePills.tags.map((pill) => {
                    return { ...pill, active: pill.title === item.title && pill.index === item.index };
                }),
            });
            const temp = [
                {
                    column: 'orderType',
                    value: item.index === 0 ? 'Transaction' : 'Change Request',
                },
            ];
            setSearchbarFilterTags([]);
            setFilterProductTypes([]);
            setFilterTransactionTypes([]);
            setFilterFundingTypes([]);
            setFilterAccountTypes([]);
            setFilterStatusTypes([]);
            setFilterBatchCutoffTypes([]);
            setFilterRiskTypes([]);
            setTempFilters(temp);
            switch (tabs[tab].name.toLowerCase()) {
                case 'daily':
                    setFilterInputDaily(temp);
                    break;
                default:
                    setFilterInput(temp);
                    break;
            }
        } else {
            let temp = [...filterInputDaily];
            setOrderTypePills({
                ...orderTypePills,
                tags: orderTypePills.tags.map((pill) => {
                    return { ...pill, active: pill.title === item.title && pill.index === item.index };
                }),
            });
            temp = temp.filter((item) => {
                return item.column !== 'orderType' && item.column !== 'moneySighted';
            });
            temp.splice(0, 0, {
                column: 'orderType',
                value: item.index === 0 ? 'Transaction' : 'Change Request',
            });
            setFilterInputDaily(temp);
        }
    };

    const getTabs = (item: string) => {
        const temp: ITransactionWithoutGrouping = {
            data: [],
        };
        switch (item) {
            case 'reroute':
                temp.data = rerouted.current;
                break;
            case 'hardcopy':
                temp.data = hardcopy.current;
                break;
            case 'upcoming':
                temp.data = upcoming.current;
                break;
            default:
                temp.data = history.current;
                break;
        }
        return temp;
    };

    const tabs: IDashboardTabs[] = [];
    if (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker) {
        tabs.push({
            name: 'daily',
            table: (
                <Daily
                    filters={filterInputDaily}
                    submittedOnDropdown={submittedOn}
                    searchInput={searchInput.value}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    setFilters={setFilterInputDaily}
                    setSubmittedOnDropdown={setSubmittedOn}
                    setSortData={setSortData}
                    showCheckBox={showCheckBox}
                    sortData={sortData}
                    redirect={fetchData}
                    downloadType={bannerType}
                    pendingTab={pendingTab}
                    dailySummaryState={dailySummaryTrxCount}
                    isSearchResult={searchInput.value.length !== 0}
                    isFilterApplied={
                        pillFundType === 0
                            ? JSON.stringify(initialDailyFilter) !== JSON.stringify(filterInputDaily)
                            : JSON.stringify(initialDailyFilterNonMoney) !== JSON.stringify(filterInputDaily)
                    }
                    loadingHandler={loadingHandler}
                    setErrorHandler={handleErrorHandler}
                    setErrorMessage={setErrorMessage}
                    idTokenHeader={idTokenHeader}
                    selectAllFromBanner={selectAllFromBanner}
                />
            ),
            testId: 'daily-tab',
        });
    }
    if (upcomingTab.isAll === 'true' || upcomingTab.actions.canViewTransactionList === LABEL.maker) {
        tabs.push({
            name: 'upcoming',
            table: (
                <Upcoming
                    filters={filterInput}
                    submittedOnDropdown={submittedOn}
                    searchInput={searchInput.value}
                    setFilters={setFilterInput}
                    setSubmittedOnDropdown={setSubmittedOn}
                    upcomingTab={upcomingTab}
                    isSearchResult={searchInput.value.length !== 0}
                    isFilterApplied={JSON.stringify(filterInput) !== JSON.stringify(initialOtherTabFilter)}
                    setErrorHandler={handleErrorHandler}
                    setErrorMessage={setErrorMessage}
                />
            ),
            testId: 'upcoming-tab',
        });
    }
    if (hardCopyTab.isAll === 'true' || hardCopyTab.actions.canViewTransactionList === LABEL.maker) {
        tabs.push({
            name: 'with physical doc',
            table: (
                <WithHardcopy
                    handleReceived={handleReceived}
                    searchInput={searchInput.value}
                    setSortData={setSortData}
                    sortData={sortData}
                    hardcopyTab={hardCopyTab}
                    isSearchResult={searchInput.value.length !== 0}
                    isFilterApplied={JSON.stringify(filterInput) !== JSON.stringify(initialOtherTabFilter)}
                    setErrorHandler={handleErrorHandler}
                    setErrorMessage={setErrorMessage}
                    idTokenHeader={idTokenHeader}
                    setSubmittedOnDropdown={setSubmittedOn}
                    submittedOnDropdown={submittedOn}
                />
            ),
            testId: 'withhardcopy-tab',
        });
    }
    if (reRoutedTab.isAll === 'true' || reRoutedTab.actions.canViewTransactionList === LABEL.maker) {
        tabs.push({
            name: 'rerouted',
            table: (
                <Rerouted
                    filters={filterInput}
                    submittedOnDropdown={submittedOn}
                    searchInput={searchInput.value}
                    setFilters={setFilterInput}
                    setSubmittedOnDropdown={setSubmittedOn}
                    sortData={sortData}
                    rerouteTab={reRoutedTab}
                    dueDateTab={dueDateTab}
                    isSearchResult={searchInput.value.length !== 0}
                    isFilterApplied={JSON.stringify(filterInput) !== JSON.stringify(initialOtherTabFilter)}
                    setErrorHandler={handleErrorHandler}
                    setErrorMessage={setErrorMessage}
                    idTokenHeader={idTokenHeader}
                />
            ),
            testId: 'rerouted-tab',
        });
    }
    if (historyTab.isAll === 'true' || historyTab.actions.canViewTransactionList === LABEL.maker) {
        tabs.push({
            name: 'history',
            table: (
                <History
                    filters={filterInput}
                    lastUpdatedDropdown={lastUpdated}
                    searchInput={searchInput.value}
                    setFilters={setFilterInput}
                    setLastUpdatedDropdown={setLastUpdated}
                    setSortData={setSortData}
                    sortData={sortData}
                    historyTab={historyTab}
                    loadingHandler={loadingHandler}
                    isSearchResult={searchInput.value.length !== 0}
                    isFilterApplied={JSON.stringify(filterInput) !== JSON.stringify(initialOtherTabFilter)}
                    setErrorHandler={handleErrorHandler}
                    setErrorMessage={setErrorMessage}
                    idTokenHeader={idTokenHeader}
                />
            ),
            testId: 'history-tab',
        });
    }

    const SelectAllBannerShortcut = () => {
        return (
            <LinkButton buttonColor="#0089EC" onClick={() => setSelectAllFromBanner(!selectAllFromBanner)}>
                {decoupleGroupedTrx(dailyV2).length === 1
                    ? `Select 1 Order`
                    : `Select All ${decoupleGroupedTrx(dailyV2).length} Orders`}
            </LinkButton>
        );
    };
    //Fn for ExternalLink for Searchbar
    const getExternalLink = (): ExternalLink | undefined => {
        let temp = undefined;
        if (_history.location.pathname === '/hq/dashboard/view-branch')
            temp = { title: 'View Pending Submission Order', link: '/hq/dashboard/view-branch/pending-submission' };
        return temp;
    };
    // Fn to toggle confirmation modal
    const handleConfirmModal = () => {
        setConfirmVerify({ ...confirmVerify, state: !confirmVerify.state, expand: false });
    };

    const handleRemoveFilter = (item: ISelectValue) => {
        let tempValues = [...searchbarFilterTags];
        tempValues = tempValues.filter((filter) => {
            return filter.value.toLowerCase() !== item.value.toLowerCase();
        });
        setSearchbarFilterTags(tempValues);
        let arrayIndex = -1;
        switch (item.label) {
            case LABEL.status:
                const tempStatus = [...filterStatusTypes];
                arrayIndex = tempStatus.indexOf(item.value);
                arrayIndex !== -1 ? tempStatus.splice(arrayIndex, 1) : null;
                setFilterStatusTypes(tempStatus);
                break;
            case LABEL.batchCutOffTime2:
                const tempBatchCutOff = [...filterBatchCutoffTypes];
                arrayIndex = tempBatchCutOff.indexOf(item.value);
                arrayIndex !== -1 ? tempBatchCutOff.splice(arrayIndex, 1) : null;
                setFilterBatchCutoffTypes(tempBatchCutOff);
                break;
            case LABEL.accountTypes:
                const tempAccountTypes = [...filterAccountTypes];
                arrayIndex = tempAccountTypes.indexOf(item.value);
                arrayIndex !== -1 ? tempAccountTypes.splice(arrayIndex, 1) : null;
                setFilterAccountTypes(tempAccountTypes);
                break;
            case LABEL.productTypes:
                const tempProductTypes = [...filterProductTypes];
                arrayIndex = tempProductTypes.indexOf(item.value);
                arrayIndex !== -1 ? tempProductTypes.splice(arrayIndex, 1) : null;
                setFilterProductTypes(tempProductTypes);
                break;
            case LABEL.transTypes:
                const tempTrxTypes = [...filterTransactionTypes];
                arrayIndex = tempTrxTypes.indexOf(item.value);
                arrayIndex !== -1 ? tempTrxTypes.splice(arrayIndex, 1) : null;
                setFilterTransactionTypes(tempTrxTypes);
                break;
            case LABEL.fundingOptions:
                const tempFundingOptions = [...filterFundingTypes];
                arrayIndex = tempFundingOptions.indexOf(item.value);
                arrayIndex !== -1 ? tempFundingOptions.splice(arrayIndex, 1) : null;
                setFilterFundingTypes(tempFundingOptions);
                break;
            case LABEL.lastUpdated:
            case LABEL.submittedOn:
            case LABEL.createdOn:
                setTempTarget([null, null]);
                break;
            case LABEL.risk:
                const tempRiskTypes = [...filterRiskTypes];
                arrayIndex = tempRiskTypes.indexOf(item.value);
                arrayIndex !== -1 ? tempRiskTypes.splice(arrayIndex, 1) : null;
                setFilterRiskTypes(tempRiskTypes);
                break;
        }

        let _tempFilters = [...tempFilters];
        _tempFilters = removeSearchFilterTagFromFilter(item, tempFilters);

        setTempFilters(_tempFilters);

        tabs[tab].name.toLowerCase() === 'daily' ? setFilterInputDaily(_tempFilters) : setFilterInput(_tempFilters);
    };

    const filterSearchTags = () => {
        return searchbarFilterTags.filter((tag) => {
            if (tag.label === 'Product Type') {
                return fundTypeOptions
                    .map((item) => (item = item.toString().toLowerCase()))
                    .includes(tag.value.toLowerCase());
            } else if (tag.label === 'Funding Option') {
                return paymentMethodOptions.includes(tag.value);
            } else if (tag.label === 'Account Type') {
                return accountTypeOptions.includes(tag.value);
            }
            // else if (tag.label === 'Status') {
            //     return branchStatusOptions.includes(tag.value);
            // }
            else if (tag.label === LABEL.batchCutOffTime2) {
                return branchBatchCutoffOptions.includes(tag.value);
            } else if (tag.label === LABEL.risk) {
                return branchRiskOptions
                    .map((item) => (item = item.toString().toLowerCase()))
                    .includes(tag.value.toLowerCase());
            } else if (tag.label === LABEL.transTypes) {
                return transactionTypeOptions.includes(tag.value);
            } else {
                return true;
            }
        });
    };

    const disableResultLimitForDownload = (banner: string): boolean => {
        switch (banner) {
            case LABEL.transactionsReport:
            case LABEL.ppaOnlineAccountOpeningForm:
                return true;
            default:
                return false;
        }
    };
    const handleCancelDailySummaryReport = () => {
        setToggleDailyReportProgress(false);
        subscription && subscription.unsubscribe();
        setSubscription(null);
    };
    // Main LifeCycle Hooks Resets everything
    useEffect(() => {
        setPreviousPageURL(window.location.pathname);
        if (initialRenderTabs.current) {
            if (locationState.state?.currentTab !== undefined) {
                const name = getTabDisplayName(locationState.state.currentTab);
                tabs.map((ele, index) => {
                    ele.name === name ? setTab(index) : null;
                });
            }
            if (locationState.state?.fromInbox !== undefined && locationState.state.fromInbox.status) {
                setFilterInputDaily(dailyPendingFilter);
            }
            if (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker) {
                setFundTypePills({
                    tab: 0,
                    label: 'Fund Types:',
                    tags: [
                        {
                            active:
                                filterInputDaily.filter((item) => {
                                    return item.column === 'moneySighted' && item.value.toLowerCase() === 'true';
                                }).length !== 0
                                    ? true
                                    : false,
                            index: 0,
                            title: 'Money Sighted',
                            testId: 'money-sighted',
                        },
                        {
                            active:
                                filterInputDaily.filter((item) => {
                                    return item.column === 'moneySighted' && item.value.toLowerCase() === 'false';
                                }).length !== 0
                                    ? true
                                    : false,
                            index: 1,
                            title: 'Non-Money Sighted',
                            testId: 'nonmoney-sighted',
                        },
                    ],
                });
            }
            tabs[tab].name.toLowerCase() === 'history'
                ? setDateFilter(initialDateFilterBranch)
                : setDateFilter({ dateSort: 'submittedOn', range: '' });
            fetchData(tabs[tab].name);
            initialRenderTabs.current = false;
            pendingTab.actions.canDownloadDailySummary === LABEL.maker && tabs[tab].name.toLowerCase() === 'daily'
                ? getDailySummaryCount()
                : null;
        } else {
            initialRenderFilters.current = true;
            reInitializeDashboard();
            pendingTab.actions.canDownloadDailySummary === LABEL.maker && tabs[tab].name.toLowerCase() === 'daily'
                ? getDailySummaryCount()
                : null;
        }
        return function cleanup() {
            if (_history.location.pathname.includes('all-branches')) {
                _history.location.pathname !== '/'
                    ? updateIsSeenDashboard('allSubmittedDashboard', [selectedTab(tabs[tab].name)], idTokenHeader)
                    : null;
                clearContextOnNavigation('allSubmittedDashboard', _history.location.pathname) === false
                    ? clearContext()
                    : null;
            } else {
                _history.location.pathname !== '/'
                    ? updateIsSeenDashboard(
                          'branchDashboardV2',
                          [selectedTab(tabs[tab].name.toLowerCase())],
                          idTokenHeader,
                      )
                    : null;
                clearContextOnNavigation(
                    branchId !== null && branchId !== '' ? 'viewBranch' : 'branchDashboard',
                    _history.location.pathname,
                ) === false
                    ? clearContext()
                    : null;
            }
        };
    }, [tab]);
    //  Fetch data based on Updates in any dependents
    useMemo(() => {
        if (initialRenderFilters.current) {
            initialRenderFilters.current = false;
        } else {
            if (pendingTab.isAll === 'true' || pendingTab.actions.canViewTransactionList === LABEL.maker) {
                setFundTypePills({
                    tab: 0,
                    label: 'Fund Types:',
                    tags: [
                        {
                            active:
                                filterInputDaily.filter((item) => {
                                    return item.column === 'moneySighted' && item.value.toLowerCase() === 'true';
                                }).length !== 0
                                    ? true
                                    : false,
                            index: 0,
                            title: 'Money Sighted',
                            testId: 'money-sighted',
                        },
                        {
                            active:
                                filterInputDaily.filter((item) => {
                                    return item.column === 'moneySighted' && item.value.toLowerCase() === 'false';
                                }).length !== 0
                                    ? true
                                    : false,
                            index: 1,
                            title: 'Non-Money Sighted',
                            testId: 'nonmoney-sighted',
                        },
                    ],
                });
            }
            fetchData(tabs[tab].name);
            pendingTab.actions.canDownloadDailySummary === LABEL.maker && tabs[tab].name.toLowerCase() === 'daily'
                ? getDailySummaryCount()
                : null;
            if (bannerType === LABEL.bulkTransactionVerification) {
                renderBulkVerify.current = false;
            }
            setSearchbarFilterTags(filterSearchTags);
        }
    }, [page, resultLimit, debouncedSearchTerm, filterInput, filterInputDaily, sortData]);

    useEffect(() => {
        return () => {
            // Stop receiving data updates from the subscription
            subscription?.unsubscribe();
            loading ? loadingHandler() : null;
        };
    }, [subscription]);

    return {
        handleAccountTypes,
        handleApplyFilter,
        handleCancelFilter,
        handleConfirmModal,
        handleDate,
        handleDateSorting,
        handleDownload,
        handleFilterTableForExportReport,
        handleFundingOptions,
        handleNext,
        handlePillCutOffTime,
        handlePillFundType,
        handlePlaceHolder,
        handleStatusTypeFilter,
        handlePrevious,
        handleProductTypes,
        handleTransactionTypes,
        handleReceived,
        handleSearchActions,
        handleRemoveFilter,
        getExternalLink,
        searchOptions,
        getSecondaryButtons,
        OpenTrackOrderModal,
        getExportReportPermissionsCheck,
        switchTabs,
        disableBannerButton,
        SelectAllBannerShortcut,
        trackOrder,
        closeTrackModal,
        bulkVerifyTransactions,
        getModalMessage,
        TrackOrderButton,
        tabs,
        expand,
        setExpand,
        showAllTags,
        showCheckBox,
        showBanner,
        selectedRows,
        showModal,
        bulkModal,
        trackModal,
        bannerType,
        downloadLink,
        progressModal,
        progressWidth,
        timeLeft,
        startTime,
        datePickerInvalidMessage,
        confirmVerify,
        disableSearchBarButtons,
        received,
        pendingTab,
        upcomingTab,
        hardCopyTab,
        reRoutedTab,
        historyTab,
        setShowAllTags,
        setShowCheckbox,
        setShowBanner,
        setSelectedRows,
        setShowModal,
        setBulkModal,
        setTrackModal,
        setBannerType,
        setProgressModal,
        setProgressWidth,
        setTimeLeft,
        setDatePickerInvalidMessage,
        setDisableSearchBarButtons,
        handleOrderTypePills,
        handleRiskTypeFilter,
        handleBatchCutoffTypeFilter,
        modalMessages,
        updateStatusAfterTrxReportDownload,
        updateTrxRefStatus,
        disableResultLimitForDownload,
        toggleDailyReportProgress,
        setToggleDailyReportProgress,
        handleCancelDailySummaryReport,
    };
};

const Subtitle = styled.div`
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #333333;
`;
const Bold = styled.span`
    font-weight: 700;
`;
const CollapseWrapper = styled.div<IOnPropStyles>`
    box-shadow: ${(props) => (props.removeBorder ? `inset 0px 1px 0px #66798e` : `unset`)};
    padding-top: ${(props) => (props.removeBorder ? '1rem' : '0')};
`;
const TransactionRefList = styled.ul`
    margin: 0;
    list-style-position: inside;
    padding: 0px 8px;
    font-weight: 400;
    font-size: 0.75rem;
    color: #333333;
    max-height: 9.5rem;
    overflow: auto;
`;
