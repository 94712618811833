import { useContext, useEffect, useRef } from 'react';
import { TableGroupingTemplates } from '../../../components';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';
import { getGroupingFromData, getGroupStyling } from '../../../utils';

import { getGroupedTrx } from '../decoupleGroupedTrx';
import { useDashboardTransactionUpcomingTableColumn } from './useUpcomingTableColumn';

interface IUpcomingHandlerProps {
    filters: ISort_FilterInput;
    submittedOnDropdown: string;
    upcomingTab: IUpcomingTab;
    setFilters: (data: ISort_FilterInput) => void;
    setSubmittedOnDropdown: (value: string) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUpcomingHandler = ({
    filters,
    setFilters,
    setSubmittedOnDropdown,
    submittedOnDropdown,
    upcomingTab,
}: IUpcomingHandlerProps) => {
    const { upcoming, orderTypePills, upcomingCr } = useContext(DashboardBranchContext);
    const upcomingGroupedTable = useRef<(IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[]>([]);

    const { columns } = useDashboardTransactionUpcomingTableColumn({
        upcomingTab,
        submittedOnDropdown,
        setSubmittedOnDropdown,
        filters,
        setFilters,
        changeRequestColumn:
            orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request').length !== 0,
    });
    useEffect(() => {
        let _upcoming = [];
        if (!window.location.pathname.includes('all-branches')) {
            _upcoming = getGroupedTrx(upcoming.current, 'orderNo');
        } else {
            _upcoming =
                orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request').length !== 0
                    ? upcomingCr.current
                    : getGroupedTrx(upcoming.current, 'orderNo');
        }
        let data: (IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[] = [];
        data = getGroupingFromData(_upcoming, TableGroupingTemplates, getGroupStyling, true);
        upcomingGroupedTable.current = [...data];
    }, [upcoming.current, upcomingCr.current]);
    return {
        columnsUpcoming: columns,
        upcomingGrouped: upcomingGroupedTable,
    };
};
