import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LABEL } from '../../../constants';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';
import {
    sh128,
    sh144,
    sh296,
    sh200,
    sh48,
    subTitleStyle,
    sh108,
    sh98,
    sh88,
    sh69,
    sh72,
    sh138,
    sh120,
    sh56,
    sh148,
    sh156,
} from '../../../styles';
import { statusStyle, stringToCamelCase } from '../../../utils';
import { DateHeader } from '../DateHeaderMenu';
import * as ROUTES from '../../../routes';

interface IDashboardTransactionUpcomingTableColumnData {
    upcomingTab: IUpcomingTab;
    submittedOnDropdown: string;
    setSubmittedOnDropdown: (value: string) => void;
    filters: ISort_FilterInput;
    setFilters: (data: ISort_FilterInput) => void;
    changeRequestColumn: boolean;
}
interface IColumnReturnType {
    columns: ITableColumn[];
}
export const useDashboardTransactionUpcomingTableColumn = ({
    upcomingTab,
    submittedOnDropdown,
    setSubmittedOnDropdown,
    changeRequestColumn,
}: IDashboardTransactionUpcomingTableColumnData): IColumnReturnType => {
    //Context
    const { dateFilter, setDateFilter, sortData, setSortData } = useContext(DashboardBranchContext);

    const history = useHistory();

    const handleSort = (keyName: string) => {
        const tempSort = [...sortData];
        sortData.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortData.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };

    const dateHeaderFilter = (menu: IHeaderMenu) => {
        return (
            <DateHeader
                {...menu}
                dropdownData={[
                    submittedOnDropdown,
                    // eslint-disable-next-line react/prop-types
                    sortData[0].column === stringToCamelCase(submittedOnDropdown) ? sortData[0].value : '',
                ]}
                setData={handleDateFilter}
            />
        );
    };

    const handleDateFilter = (item: string, value: string) => {
        setSubmittedOnDropdown(item);
        let currentFilter = 'submittedOn';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.submittedOn:
                currentFilter = 'submittedOn';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
        }
        setDateFilter({ ...dateFilter, dateSort: currentFilter });
        setSortData([{ column: currentFilter, value: value }]);
    };
    const handleViewDetails = (item: IColumnItemAccordion) => {
        const data = {
            transactionRef: item.rawData.transactionRef,
            orderNumber: item.rawData.orderNo,
            tab: 'upcoming',
            endpoint: 'branchDashboard',
            url: history.location.pathname,
        };
        const trxType = item.rawData.transactionType as string;
        const result = trxType && trxType.toLowerCase() !== 'cr' && trxType.toLowerCase() !== 'change request';
        result
            ? history.push(`${history.location.pathname}/${ROUTES.orderSummary}`, data)
            : history.push(`${history.location.pathname}/${ROUTES.orderSummaryChangeRequest}`, data);
    };

    const columnsTransaction: ITableColumn[] = [
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [{ key: stringToCamelCase(submittedOnDropdown) }],
            viewStyle: {
                width: sh108,
            },
            title: submittedOnDropdown,
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
            customItem: true,
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase(submittedOnDropdown) ? 700 : 400,
            },
            itemStyle: {
                position: 'relative',
            },
        },
        {
            icon: {
                name: handleSortIcon('transactionRef'),
                size: '1rem',
            },
            key: [{ key: 'orderNo' }, { key: 'transactionRef', textStyle: subTitleStyle }],

            viewStyle: {
                width: sh98,
            },
            title: LABEL.transNo,
            onPressHeader: () => handleSort('transactionRef'),
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400,
            },
            customItem: true,
        },
        {
            key: [
                {
                    key: 'transactionType',
                    textStyle: { fontWeight: sortData[0].column === 'transactionType' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: sh88,
            },
            title: LABEL.transTypes,
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionType' ? 700 : 400,
            },
            onPressHeader: () => handleSort('transactionType'),
            icon: {
                name: handleSortIcon('transactionType'),
                size: 16,
            },
        },
        {
            icon: {
                name: handleSortIcon('fundType'),
                size: '1rem',
            },
            key: [{ key: 'fundType', textStyle: { fontWeight: sortData[0].column === 'fundType' ? 700 : 400 } }],
            viewStyle: {
                width: sh69,
            },
            title: LABEL.products,
            onPressHeader: () => handleSort('fundType'),
            titleStyle: {
                fontWeight: sortData[0].column === 'fundType' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('paymentMethod'),
                size: '1rem',
            },
            key: [
                { key: 'paymentMethod', textStyle: { fontWeight: sortData[0].column === 'paymentMethod' ? 700 : 400 } },
            ],
            viewStyle: {
                width: sh72,
            },
            title: LABEL.funding,
            onPressHeader: () => handleSort('paymentMethod'),
            titleStyle: {
                fontWeight: sortData[0].column === 'paymentMethod' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'totalInvestment' }],
            viewStyle: {
                width: sh156,
            },
            title: LABEL.totalAmount,
            icon: {
                name: handleSortIcon('totalInvestment'),
                size: '1rem',
            },
            customItem: true,
            onPressHeader: () => handleSort('totalInvestment'),
            titleStyle: {
                fontWeight: sortData[0].column === 'totalInvestment' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'totalUnits' }],
            viewStyle: {
                width: sh138,
            },
            title: LABEL.totalUnits,
            icon: {
                name: handleSortIcon('totalUnits'),
                size: '1rem',
            },
            customItem: true,
            onPressHeader: () => handleSort('totalUnits'),
            titleStyle: {
                fontWeight: sortData[0].column === 'totalUnits' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: sh120,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh148,
            },
            title: LABEL.status,
            onPressHeader: () => handleSort('status'),
            titleStyle: {
                fontWeight: sortData[0].column === 'status' ? 700 : 400,
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
        {
            key: [],
            viewStyle: {
                width: sh56,
            },
            itemIcon: {
                name: upcomingTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                upcomingTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'daily-view-column',
        },
    ];

    const columnsChangeRequest: ITableColumn[] = [
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [{ key: stringToCamelCase(submittedOnDropdown) }],
            viewStyle: {
                width: sh128,
            },
            title: submittedOnDropdown,
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
            customItem: true,
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase(submittedOnDropdown) ? 700 : 400,
            },
            itemStyle: {
                position: 'relative',
            },
        },
        {
            icon: {
                name: handleSortIcon('transactionRef'),
                size: '1rem',
            },
            key: [{ key: 'orderNo' }, { key: 'transactionRef', textStyle: subTitleStyle }],

            viewStyle: {
                width: sh144,
            },
            title: LABEL.transNo,
            onPressHeader: () => handleSort('transactionRef'),
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400,
            },
            customItem: true,
        },
        {
            key: [
                {
                    key: 'transactionType',
                    textStyle: { fontWeight: sortData[0].column === 'transactionType' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: sh128,
            },
            title: LABEL.transTypes,
            icon: {
                name: 'arrow-down',
                size: 16,
                style: { opacity: '0.2' },
            },
        },
        {
            icon: {
                name: handleSortIcon('riskCategory'),
                size: '1rem',
            },
            key: [
                { key: 'riskCategory', textStyle: { fontWeight: sortData[0].column === 'riskCategory' ? 700 : 400 } },
            ],
            viewStyle: {
                width: sh128,
            },
            title: LABEL.risk,
            onPressHeader: () => handleSort('riskCategory'),
            titleStyle: {
                fontWeight: sortData[0].column === 'riskCategory' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: sh296,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh200,
            },
            title: LABEL.status,
            onPressHeader: () => handleSort('status'),
            titleStyle: {
                fontWeight: sortData[0].column === 'status' ? 700 : 400,
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
        {
            key: [],
            viewStyle: {
                width: sh48,
            },
            itemIcon: {
                name: upcomingTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                upcomingTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'daily-view-column',
        },
    ];

    return {
        columns: changeRequestColumn ? columnsChangeRequest : columnsTransaction,
    };
};
