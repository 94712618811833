export const decoupleGroupedTrx = (groupedTrx: TGroupingInfo[]): ITableData[] => {
    let temp: ITableData[] = [];
    groupedTrx.map((item) => {
        item.transactionData.grouping
            ? (temp = recursiveMap(item.transactionData.grouping, temp))
            : temp.push(...item.transactionData.data);
    });
    return temp;
};
const recursiveMap = (groupedTrx: TGroupingInfo[], data: ITableData[]): ITableData[] => {
    let tempArray: ITableData[] = [...data];
    const trx = [...groupedTrx];
    trx.map((item) => {
        if (item.transactionData.data !== undefined) {
            tempArray.push(...item.transactionData.data);
        } else tempArray = recursiveMap(item.transactionData.grouping, tempArray);
    });
    return tempArray;
};
export const decoupleNonGroupedTrx = (groupedTrx: ITransactionWithoutGrouping): ITableData[] => {
    let temp: ITableData[] = [];
    if (groupedTrx.data !== undefined) {
        temp = groupedTrx.data;
    }
    return temp;
};

export const getGroupedTrx = (trx: ITableData[], orderBy: string): TGroupingInfo[] => {
    const temp: TGroupingInfo[] = [];
    if (orderBy === 'orderNo') {
        const tempGroup: string[] = [];
        trx.forEach((item) => {
            Object.keys(item).includes(orderBy) ? tempGroup.push(item.orderNo) : null;
        });
        const orderGroup = [...new Set(tempGroup)];
        orderGroup.forEach((_order) => {
            const obj: TGroupingInfo = {
                type: 'orderNo',
                groupingData: {
                    principalName: '',
                    principalNumber: '',
                    jointName: '',
                    jointNumber: '',
                    orderNumber: '',
                    sameBranch: 'true',
                    accountNo: '',
                    noTransactions: '',
                },
                transactionData: {
                    data: [],
                },
            };
            const tempData: ITableData[] = [];
            trx.forEach((item) => {
                if (item.orderNo === _order) {
                    obj.groupingData.principalName = item.clientName ?? '';
                    obj.groupingData.principalNumber = item.clientIdNum ?? '';
                    obj.groupingData.jointName = item.jointName ?? '';
                    obj.groupingData.jointNumber = item.jointNumber ?? '';
                    obj.groupingData.orderNumber = item.orderNo ?? '';
                    obj.groupingData.sameBranch = item.sameBranch ?? 'true';
                    obj.groupingData.accountNo = item.accountNo ?? '-';
                    obj.groupingData.noTransactions = item.noTransactions ?? '-';
                    tempData.push(item);
                }
                obj.transactionData.data = tempData;
            });
            temp.push(obj);
        });
    }
    return temp;
};
