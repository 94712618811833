import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { LABEL, TextDarkBlack } from '../../../../constants';

import { MultiSection } from '../../../../templates';
// import { ITransactionsSummaryTrx } from '../../orderSummaryTypes';
import styled from 'styled-components';
import { FlexedDiv } from '../../../../components/atoms';
import { IcoMoon } from '../../../../icons';
import { LinkButton } from '../../../../components/atoms/LinkButton';
import { useOrderDetailsTrxHandler } from '../../useOrderSummaryTrxHandler';

interface ITransactionsSummaryProps {
    transactions: ITransactionsSummaryTrx;
    branch?: string;
    fromInbox?: boolean;
}

/**
 * Transaction summary/Order summary tab in order summary page
 * @category Pages
 * @component
 * @namespace OrderTabTrx
 * @returns OrderTabTrx tab in order summary page.
 */

const OrderTabTrx: React.FC<ITransactionsSummaryProps> = ({
    transactions,
    branch,
    fromInbox,
}: ITransactionsSummaryProps) => {
    const [transactionSections, setTransactionSections] = useState<Array<IProfileSection | IInvestmentSummarySection>>(
        [],
    );
    const { handleViewStatusOverview, activeTabRef } = useOrderDetailsTrxHandler();
    useEffect(() => {
        const _tempSections: Array<IProfileSection | IInvestmentSummarySection> = [];
        const _expands: boolean[] = [];
        Object.keys(transactions).forEach((key) => {
            switch (key) {
                case 'investorOverview':
                case 'transactionDetails':
                case 'paymentSummary':
                case 'riskAssessment':
                    if (transactions[key] !== undefined && !transactions[key]?.hidden) {
                        _tempSections.push(transactions[key] as IProfileSection);
                    }
                    break;
                case 'investmentSummary':
                    if (transactions[key] !== undefined) {
                        _tempSections.push(transactions[key] as IInvestmentSummarySection);
                    }
                    break;
                case 'switchingSummary':
                    if (transactions[key] !== undefined) {
                        transactions[key]?.forEach((switchGroup) => {
                            _tempSections.push(switchGroup as IInvestmentSummarySection);
                        });
                    }
                    break;
                case 'redemptionTypeSummary':
                    if (transactions[key] !== undefined) {
                        _tempSections.push(transactions[key] as IProfileSection);
                    }
                    break;
                case 'redemptionSummary':
                    if (transactions[key] !== undefined) {
                        _tempSections.push(transactions[key] as IInvestmentSummarySection);
                    }
                    break;
                case 'proceedPayableSummary':
                    if (transactions[key] !== undefined) {
                        _tempSections.push(transactions[key] as IInvestmentSummarySection);
                    }
                    break;
            }
        });

        _tempSections.forEach(() => {
            _expands.push(true);
        });

        setTransactionSections(_tempSections);
    }, [transactions]);

    return (
        <Fragment>
            <FlexedDiv direction="column">
                {transactions.approval !== undefined && transactions.approval !== null && fromInbox === true ? (
                    <ApprovalBanner direction="column">
                        <Title>{`This request was ${transactions.approval.status.toLowerCase()} by ${
                            transactions.approval.name
                        }`}</Title>

                        <FlexedDiv
                            style={{
                                paddingTop: '0.5rem',
                                width: '100%',
                                wordBreak: 'break-all',
                                whiteSpace: 'break-spaces',
                            }}
                            justifyContent="space-between"
                        >
                            {transactions.approval.remarks !== undefined && transactions.approval.remarks !== null
                                ? `${LABEL.rejectedReason}: ${transactions.approval.remarks}`
                                : ''}
                        </FlexedDiv>
                    </ApprovalBanner>
                ) : null}
                {transactions.extensionRemarks !== undefined && transactions.extensionRemarks !== null ? (
                    <ExtensionsBanner direction="column">
                        <Title>{`${LABEL.dueDateExtension} (${moment(transactions.extensionRemarks?.date, 'x').format(
                            'DD/MM/YYYY',
                        )}) ${LABEL.rejected.toLowerCase()} ${LABEL.by} ${LABEL.hq}`}</Title>
                        <FlexedDiv
                            style={{ paddingTop: '0.5rem', width: '100%', wordBreak: 'break-all' }}
                            justifyContent="space-between"
                        >
                            {`${LABEL.remarks}: ${transactions.extensionRemarks?.remarks}`}
                        </FlexedDiv>
                    </ExtensionsBanner>
                ) : null}
                {transactions.remarks !== undefined && transactions.remarks !== null ? (
                    <RerouteRemarksBanner status={transactions.status.toLowerCase()} direction="column">
                        <FlexedDiv justifyContent="space-between" alignItems="baseline">
                            <TextDarkBlack weight={'700'} size="14px" lineHeight="18.2px">
                                <IcoMoon name="warning" color="#E89700" size="1rem" style={{ marginRight: '8px' }} />
                                {`${
                                    transactions.status === LABEL.rejected
                                        ? LABEL.orderSummaryRejectRemarks
                                        : LABEL.orderSummaryRerouteRemarks
                                } ${branch}.`}
                            </TextDarkBlack>
                            <LinkButton
                                noFill
                                suffixIcon="arrow-right"
                                iconStyle={{
                                    marginLeft: '8px',
                                    height: '14px',
                                    width: '14px',
                                }}
                                style={{
                                    background: 'rgba(255, 255, 255, 0.01)',
                                    boxShadow: 'inset 0px -2px 0px #C61230',
                                }}
                                onClick={() => handleViewStatusOverview(activeTabRef.current)}
                            >
                                Track Status
                            </LinkButton>
                        </FlexedDiv>
                    </RerouteRemarksBanner>
                ) : null}
            </FlexedDiv>
            {transactionSections.length !== 0 ? (
                transactionSections.map((elm, index) => {
                    return (
                        <BlockContainer
                            key={`container${index + 1}`}
                            id={`${elm.title?.toLocaleLowerCase()}-container`}
                        >
                            <BlockContainerHeader>
                                <TextDarkBlack weight="700" size="18px">
                                    {elm.title}
                                </TextDarkBlack>
                            </BlockContainerHeader>
                            <BlockContainerContent>
                                <MultiSection sections={elm.sections} noSectionImage="empty-payment-info" />
                            </BlockContainerContent>
                        </BlockContainer>
                    );
                })
            ) : (
                <div>Data not available</div>
            )}
        </Fragment>
    );
};

type IRemarksStatus = {
    status: string;
};

const BlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px #0020430f;
    border-radius: 8px;
    margin-bottom: 1rem;
`;
const BlockContainerHeader = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ececec;
    padding: 0.75rem 1.5rem;
    border-radius: 8px 8px 0 0;
`;
const BlockContainerContent = styled.div`
    padding: 1rem 1.5rem 1.5rem;
`;

const RerouteRemarksBanner = styled((props) => <FlexedDiv {...props} />)<IRemarksStatus>`
    background: ${(props: IRemarksStatus) =>
        props.status === 'br - rerouted' || props.status === 'hq - rerouted' || props.status === 'pending due date'
            ? '#FDF7EB'
            : props.status === 'rejected'
            ? '#faf2f1'
            : '#f5f0fa'};
    border: ${(props: IRemarksStatus) =>
        props.status === 'br - rerouted' || props.status === 'hq - rerouted' || props.status === 'pending due date'
            ? '1px solid #e89700'
            : props.status === 'rejected'
            ? '1px solid #e84c3d'
            : '1px solid #e82c97'};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1rem 1.5rem;
    margin-bottom: 2rem;
`;
const ExtensionsBanner = styled((props) => <FlexedDiv {...props} />)`
    background: rgba(232, 76, 61, 0.08);
    border: 1px solid #e84c3d;
    box-sizing: border-box;
    border-radius: 16px;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    margin-bottom: 0.5rem;
`;
const ApprovalBanner = styled((props) => <FlexedDiv {...props} />)`
    background: #fefaf2;
    border: 1px solid #e89700;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 1.5rem;
    align-items: flex-start;
    margin-bottom: 1rem;
`;
const Title = styled((props) => <FlexedDiv {...props} />)`
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
`;
export default OrderTabTrx;
