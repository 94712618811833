/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, graphqlOperation } from 'aws-amplify';
import React, { Fragment, ReactText, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//Routes
import * as ROUTES from '../../routes';
//Libraries
import styled from 'styled-components';
//Context
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
//graphQL
import transactionStatusMutation from '../../_graphql/mutations/branchCE/afterReviewUpdate/transactionStatus';
import { ceCheckerViewRequest } from '../../_graphql/queries/branchCE/checkerViewRequest/checkerViewRequest';
import { ceCheckerReviewRequest } from '../../_graphql/mutations/branchCE/ceCheckerReviewRequest/ceCheckerReviewRequest';
import { transactionReasonList } from '../../_graphql/queries/common/transactionReasonList';
//Components
import RejectRemarks from '../RejectRemarks/RejectRemarks';
import { Modal } from '../../components';

import Toggle from '../../components/atoms/Toggle/Toggle';
import MultiSelect from '../../components/molecules/MultiSelect/MultiSelect';
import { CustomButton, CustomSpacer, FlexedDiv, TextArea } from '../../components/atoms';
import {
    Banner,
    PreviousPage,
    ComponentLoader,
    IMultiSelectLabel,
    CollapsibleDisplay,
} from '../../components/molecules';
//Utils Types

import { buildRejectFormConfig } from './rejectFormConfig';
import { getIdsByValue } from '../RerouteTransaction/rerouteUtils';
import { getDisabledStatus, updateFormState } from './rejectUtils';
import { CE_DASHBOARD_LABELS, LABEL, TextDarkGrey4, SYSTEM_ADMIN } from '../../constants';
import { reasonsPageButtons } from '../../styles';
import { ContentWrapperWithTitle } from '../../components/organisms/ContentWrapperWithTitle';
import { useComponentLoader } from '../../customHooks';
export interface IReasons {
    title: string;
    reasons: IMultiSelectLabel[];
}
interface IUpdateTransactionStatusProps {
    remark: string;
    transactionRef: any | string;
    action: string;
    reasonId: Array<ReactText>;
}

interface IRejectTransactionPageProps {
    data: ITableData;
    isReject?: boolean;
}
interface StyledInnerProps {
    paddingBottom: number;
}

const modalMessages = {
    confirmation: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
    feedback: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
};
type listTransactionRefs = {
    transactions: string[];
    showTransactions: boolean;
};
type ToggleWrapProps = {
    expanded: boolean;
};
type InputWrapProps = {
    maxWidth: string;
    mb?: string;
};

type MemoizedRerouteInput = {
    requestData: IRerouteRequest;
};

export const RejectTransaction: React.FC = () => {
    // Reducer form state
    // keys names have to match form element ids
    const initialState: IRejectFormState = {
        targetDate: '',
        payment: [],
        document: [],
        invalidInformation: [],
        checkedDocs: [],
        sectionToggle: [],
        remarksCheck: { id: 'remarks', checked: false },
        remarks: '',
    };

    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const branchId = sessionStorage.getItem('branchId');
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const parsedPermissionPrivilege: ICeDashboard =
        parsedPermission.branch.grant !== undefined
            ? parsedPermission.branch.permission.ceTransaction
            : parsedPermission.hq.permission.ceDashboard;
    //Permission obj
    const { pendingTab } = parsedPermissionPrivilege;
    // Data states
    const [rejectResult, setRejectResult] = useState<ReasonsResult[]>([]);

    // Form reducer state
    const handleFilterDispatch = (formState: IRejectFormState, payload: RejectFormPayload) => {
        const _temp = { ...formState };
        switch (payload.action) {
            case 'SET_TOGGLE':
                return payload.data;
            case 'SET_SELECTED_OPTIONS':
                return payload.data;
            case 'SET_UPDATE':
                return payload.data;
            case 'SET_REMARKS':
                return payload.data;
            default:
                return _temp;
        }
    };

    const [formState, filterDispatch] = useReducer(handleFilterDispatch, initialState);

    // Form UI elements state
    const [formConfig, setFormConfig] = useState<IRerouteFormSection[]>([]);
    const [bannerHeight, setBannerHeight] = useState<number>(0);

    const [isRequest, setIsRequest] = useState<boolean>(false);
    const [requestData, setRequestData] = useState<IRerouteRequest>({
        eventType: '',
        requestorName: '',
        reasons: [],
        requestId: '',
        approval: '',
        orderNo: '',
        targetDate: '',
    });
    const [approval, setApproval] = useState<IApproval>({
        eventType: 'reroute',
        name: '',
        remarks: null,
        status: '',
    });
    const [rejectReq, setRejectReq] = useState<boolean>(false);
    const [rejectRemarks, setRejectRemarks] = useState<string>('');

    // Modal and form UI states

    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [showTransactionRefs, setShowTransactionRefs] = useState<listTransactionRefs>({
        transactions: [],
        showTransactions: false,
    });
    //Location data
    const history = useHistory();
    const location = useLocation<IRejectTransactionPageProps>();
    const transaction = location.state.data as ITableData;
    const isRejectRequest = location.state?.isReject;

    // Custom hooks
    const { loading, loadingHandler } = useComponentLoader();

    const memoizedDashboardInput: MemoizedRerouteInput = useMemo(() => {
        return {
            requestData,
        };
    }, [requestData]);

    // Fn to call transactionStatus mutation
    const updateTransactionStatus = async (payload: IUpdateTransactionStatusProps) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(transactionStatusMutation, {
                    input: payload,
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionStatus;
            if (resultCheck.error !== null) throw resultCheck.error;
            if (resultCheck.data.result && resultCheck.data.result.status === true) {
                setShowTransactionRefs({
                    ...showTransactionRefs,
                    transactions: resultCheck.data.result.transactionAffected,
                });
                handleFeedbackModal();
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(error as IErrorHandling).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Update Transaction Status',
                    testId: 'reject-transaction-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    // Fn to call transactionCheckerStatus mutation
    const reviewRequest = async (requestId: string, action: string, remarks?: string) => {
        if (action === 'approve request') handleConfirmModal();
        const payload =
            remarks !== undefined
                ? {
                      requestId: requestId,
                      action: action,
                      remarks: remarks,
                  }
                : {
                      requestId: requestId,
                      action: action,
                  };

        try {
            const response: any = await API.graphql(
                graphqlOperation(ceCheckerReviewRequest, {
                    input: payload,
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionCheckerStatus;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = response.data.transactionCheckerStatus.data;
            if (result.status) {
                setShowTransactionRefs({
                    ...showTransactionRefs,
                    transactions: resultCheck.data.result.transactionAffected,
                });
                setShowFeedbackModal((prevState) => !prevState);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(error as IErrorHandling).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Review Request',
                    testId: 'reject-transaction-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const handleRemarks = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const _tempObj = { ...formState };
        _tempObj.remarks = event.target.value;
        filterDispatch({ data: _tempObj, action: 'SET_REMARKS' });
    };

    // Fn to handle route back from feedback modal
    const handleDone = () => {
        if (parsedPermission.hq.grant !== undefined) {
            if (window.location.pathname.includes(ROUTES.hqAllSubmissions)) history.push(ROUTES.hqAllSubmissions);
            else if (branchId !== null && transaction.fromInbox !== true) history.push(ROUTES.viewBranch);
            else history.push(ROUTES.dashboard);
        } else {
            history.push(ROUTES.dashboardBranch);
        }
    };

    // Fn to handle reject order request
    const handleSubmit = () => {
        const payload: IUpdateTransactionStatusProps = {
            transactionRef: [transaction.transactionRef],
            action: 'Reject',
            reasonId: [],
            remark: formState.remarks.replace(/\s+/g, ' ').trim(),
        };
        const _paymentIds = getIdsByValue(formState.payment, rejectResult);
        const _documentIds = getIdsByValue(formState.document, rejectResult);
        const _invalidInformation = getIdsByValue(formState.invalidInformation, rejectResult);
        payload.reasonId = _invalidInformation.concat(_paymentIds, _documentIds);

        // set feedback modal data
        if (pendingTab.actions.canReject === LABEL.autoAuthorizer) {
            modalMessages.feedback.heading = LABEL.orderRejected;
            modalMessages.feedback.icon = 'trx-verification-success';
        } else {
            modalMessages.feedback.heading = CE_DASHBOARD_LABELS.DAILY.rejectRequestSubmit;
            modalMessages.feedback.icon = 'trx-verification-request-submit';
        }

        updateTransactionStatus(payload);
    };

    // Fn to handle approve reroute order requests
    const handleApproveRequest = () => {
        // set confirmation modal data
        modalMessages.confirmation.heading = `${CE_DASHBOARD_LABELS.DAILY.approveRejectOrderRequest}`;
        modalMessages.confirmation.content = (
            <FlexedDiv direction="column">
                <Subtitle>{CE_DASHBOARD_LABELS.DAILY.approveRejectOrderRequestMessage}</Subtitle>
                <CustomSpacer space={'.5rem'} />
                <Subtitle>{CE_DASHBOARD_LABELS.DAILY.yesOrNoApprove}</Subtitle>
            </FlexedDiv>
        );
        modalMessages.confirmation.type = 'approve request';

        // set feedback modal data
        modalMessages.feedback.heading = `${CE_DASHBOARD_LABELS.DAILY.rejectRequestApproved}`;

        modalMessages.feedback.icon = 'trx-verification-success';
        setShowConfirmationModal((prevState) => !prevState);
    };
    // Fn to handle reject reroute order requests
    const handleRejectRequest = () => {
        // set feedback modal data
        modalMessages.feedback.heading = `${CE_DASHBOARD_LABELS.DAILY.rejectRequestRejected}`;
        modalMessages.feedback.content = (
            <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px', marginTop: '-1rem' }}>
                    Request for&nbsp;
                    <Bold>Order&nbsp;{transaction.orderNo}</Bold>
                    &nbsp;has been rejected. <br /> The maker will be notified.
                </p>
            </FlexedDiv>
        );
        modalMessages.feedback.icon = 'trx-verification-request-approve';
        requestData.requestId !== undefined ? reviewRequest(requestData.requestId, 'reject', rejectRemarks) : null;
    };
    const handleIsRequest = () => {
        setIsRequest(!isRequest);
    };

    // Fn to toggle confirmation modal
    const handleConfirmModal = () => {
        setShowConfirmationModal((prevState) => !prevState);
    };
    // Fn to toggle feedback modal
    const handleFeedbackModal = () => {
        setShowFeedbackModal((prevState) => !prevState);
        // setApprove(!approve);
    };
    /** function to handle the reviewRequest modal messages */
    const handleFeedbackModalMessage = (type: string) => {
        return type !== 'maker' ? (
            <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px' }}>
                    <Bold>Order {transaction.orderNo}</Bold>
                    &nbsp; has been rejected.
                    <br />
                    {pendingTab.reviewApproval.canApproveReject === LABEL.checker
                        ? 'Maker, adviser and CE(HQ) will be notified.'
                        : 'Adviser will be notified.'}
                </p>
                {showTransactionRefs.transactions.length !== 0 ? (
                    <CollapseWrapper removeBorder={showTransactionRefs.showTransactions}>
                        <CollapsibleDisplay
                            displayTextForExpand={CE_DASHBOARD_LABELS.DAILY.viewMore}
                            displayTextForCollapse={CE_DASHBOARD_LABELS.DAILY.viewLess}
                            textColor="#002043"
                            expanded={showTransactionRefs.showTransactions}
                            handleExpand={() =>
                                setShowTransactionRefs({
                                    ...showTransactionRefs,
                                    showTransactions: !showTransactionRefs.showTransactions,
                                })
                            }
                            testId="confirm"
                            content={
                                <div>
                                    <Bold style={{ fontSize: '0.75rem' }}>{`Rejected transactions in this order`}</Bold>
                                    <TransactionRefList>
                                        {showTransactionRefs.transactions.map((item, index) => (
                                            <li key={index + 1}>{item}</li>
                                        ))}
                                    </TransactionRefList>
                                </div>
                            }
                            contentStyle={{ textAlign: 'left' }}
                            buttonStyle={{ alignSelf: 'center' }}
                        />
                    </CollapseWrapper>
                ) : null}
            </FlexedDiv>
        ) : (
            <p
                style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px' }}
                dangerouslySetInnerHTML={{ __html: LABEL.dueDateRequestSubtitle }}
            />
        );
    };

    // Fn to expand selcted section
    const getToggledSection = (id: string): boolean => {
        const _tempObj = { ...formState };
        let temp = false;
        const _index = _tempObj.sectionToggle.findIndex((item) => item.id === id);
        temp = _index >= 0 ? _tempObj.sectionToggle[_index].checked : false;

        return temp;
    };
    // Fn to handle multiselect drop down options
    const handleMultiSelectDropdown = (item: React.ReactText[], id: string) => {
        const _tempObj = { ...formState };
        switch (id) {
            case 'payment': //payment select
                _tempObj.payment = item;
                break;
            case 'document': //NTB document select
                _tempObj.document = item;
                break;
            case 'invalidInformation': //NTB document select
                _tempObj.invalidInformation = item;
                break;
        }

        filterDispatch({ data: _tempObj, action: 'SET_SELECTED_OPTIONS' });
    }; // Fn to get selected mutliselect options
    const getMultiSelectedValues = (id: string): React.ReactText[] => {
        let _temp: React.ReactText[] = [];
        switch (id) {
            case 'payment': //payment select
            case 'document': //NTB document select
            case 'invalidInformation':
                formState[id] && formState[id].length !== 0 ? (_temp = formState[id]) : null;
                break;
        }

        return _temp;
    };

    // Fn to handle section expand
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const _tempObj = { ...formState };
        const { id, checked } = e.target;

        if (!_tempObj.sectionToggle.find((item) => item.id === id)) {
            _tempObj.sectionToggle.push({ id: id, checked: checked });
        } else {
            const _index = _tempObj.sectionToggle.findIndex((item) => item.id === id);

            _index >= 0 && (_tempObj.sectionToggle[_index].checked = !_tempObj.sectionToggle[_index].checked);
            //clear relevant select state if toggle is off
            if (!checked && (id === 'payment' || id === 'document' || id === 'invalidInformation')) {
                _tempObj[id].length = 0;
            }

            if (!checked && id === 'remarks') _tempObj[id] = '';
        }

        filterDispatch({ data: _tempObj, action: 'SET_TOGGLE' });
    };

    //Fn to update form state when in review mode
    const getUpdateFormStateCb = useCallback(() => {
        const _updatedFormState = updateFormState(rejectResult, formState);

        filterDispatch({ data: _updatedFormState, action: 'SET_UPDATE' });
    }, [memoizedDashboardInput]);

    /** * @maintainableListEndpoint */
    useEffect(() => {
        /** @function checks the mode of the page for review,if true initiate the hook to call Request Api else calls the reasons list Api */
        const checkMode = () => {
            transaction.approvalType === 'reject' && transaction.status.toLowerCase() === 'pending approval'
                ? setIsRequest(true)
                : getTransactionReasonList();
        };
        /** * @maintainableListEndpoint */
        const getTransactionReasonList = async () => {
            loadingHandler();
            try {
                const response: any = await API.graphql(
                    graphqlOperation(transactionReasonList, {
                        input: {
                            actionType: 'Reject',
                            transactionType: ['switch', 'redeem'].includes(transaction?.transactionType.toLowerCase())
                                ? transaction?.transactionType
                                : 'Sales',
                            source: 'ce',
                            transactionRef: transaction.transactionRef,
                        },
                    }),
                    idTokenHeader,
                );

                const { reasons }: IRejectResult = response.data.transactionReasonList.data.result;

                const _formConfig = buildRejectFormConfig(reasons, transaction?.transactionType);

                setRejectResult(reasons);
                setFormConfig(_formConfig);
                loadingHandler();
            } catch (error) {
                const _error = error as IErrorHandling;
                loadingHandler();
                if (Object.keys(error as IErrorHandling).includes('errorCode')) {
                    handleErrorHandler();
                    setErrorMessage({
                        ...errorMessage,
                        message: _error.message,
                        errorCode: _error.errorCode,
                        title: 'Cannot Fetch Reject Reasons',
                        testId: 'reject-transaction-error-modal',
                    });
                } else {
                    setErrorMessage({
                        errorCode: '',
                        message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                        title: 'Something Went Wrong',
                        testId: 'gql-error-modal',
                    });
                    handleErrorHandler();
                }
            }
        };
        checkMode();
    }, [rejectReq]);

    useEffect(() => {
        /** @requestDetails */
        const getRequestDetails = async () => {
            loadingHandler();
            try {
                const response: any = await API.graphql(
                    graphqlOperation(ceCheckerViewRequest, {
                        input: {
                            requestId: transaction.requestId,
                        },
                    }),
                    idTokenHeader,
                );
                const resultCheck = response.data.transactionRequest;
                if (resultCheck.error !== null) throw resultCheck.error;
                const result = response.data.transactionRequest.data.result;
                const { orderNo, reasons } = result;

                transaction.orderNo = orderNo;

                const _formConfig = buildRejectFormConfig(reasons, transaction?.transactionType);

                if (result.approval !== null) {
                    setApproval(result.approval);
                }

                setRejectResult(reasons);

                setFormConfig(_formConfig);
                setRequestData(result);
                loadingHandler();
            } catch (error) {
                const _error = error as IErrorHandling;
                loadingHandler();
                if (Object.keys(error as IErrorHandling).includes('errorCode')) {
                    handleErrorHandler();
                    setErrorMessage({
                        ...errorMessage,
                        message: _error.message,
                        errorCode: _error.errorCode,
                        title: 'Cannot Fetch Request Details',
                        testId: 'reject-transaction-error-modal',
                    });
                } else {
                    setErrorMessage({
                        errorCode: '',
                        message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                        title: 'Something Went Wrong',
                        testId: 'gql-error-modal',
                    });
                    handleErrorHandler();
                }
            }
        };
        isRequest ? getRequestDetails() : null;
        if (isRejectRequest === true) setRejectReq(true);
    }, [isRequest]);

    useEffect(() => {
        isRequest && getUpdateFormStateCb();
    }, [getUpdateFormStateCb]);
    return (
        <Fragment>
            {rejectReq == false ? (
                <Fragment>
                    <PreviousPage
                        title={isRequest ? LABEL.reviewRejectRequest : LABEL.rejectOrder}
                        subTitle={
                            isRequest
                                ? approval.status === ''
                                    ? LABEL.reviewUserDescription
                                    : ''
                                : LABEL.rejectOrderDescription
                        }
                        secondSubtitle={
                            isRequest
                                ? approval.status === ''
                                    ? LABEL.reviewUserDescription2
                                    : ''
                                : LABEL.rejectOrderDescription_part2
                        }
                    />
                    <StyledInner paddingBottom={bannerHeight}>
                        {approval.status.length !== 0 ? (
                            <ApprovalBanner direction="column">
                                <Title>{`This request was ${approval.status.toLowerCase()} by ${approval.name}`}</Title>

                                <FlexedDiv
                                    style={{
                                        paddingTop: '0.5rem',
                                        width: '100%',
                                        whiteSpace: 'break-spaces',
                                        wordBreak: 'break-all',
                                    }}
                                    justifyContent="space-between"
                                >
                                    {approval.remarks !== undefined && approval.remarks !== null
                                        ? `${LABEL.rejectedReason}: ${approval.remarks}`
                                        : ''}
                                </FlexedDiv>
                            </ApprovalBanner>
                        ) : null}
                        <WrapperDiv>
                            <ContentWrapperWithTitle
                                title={LABEL.reasonsToReject}
                                subtitle="Select one of the category below and fill up the information required."
                                padding="0px"
                            >
                                {/* <ReasonsLabel>{LABEL.reasonsToReject}</ReasonsLabel>
                            <TextDarkGrey5>
                                Select one of the category below and fill up the information required.
                            </TextDarkGrey5> */}

                                {formConfig.map((section) => (
                                    <>
                                        <ToggleWrap
                                            key={`${section.id}-togglewrap`}
                                            expanded={getToggledSection(section.toggle.id)}
                                        >
                                            <Toggle
                                                handleToggle={handleToggle}
                                                selected={getToggledSection(section.toggle.id)}
                                                id={section.toggle.id}
                                                label={section.toggle.label}
                                                disabled={isRequest}
                                            />
                                        </ToggleWrap>
                                        {getToggledSection(section.toggle.id) && (
                                            <SectionBody>
                                                {section?.subTitle && (
                                                    <TextDarkGrey4 size="12px" style={{ marginBottom: '1rem' }}>
                                                        {section?.subTitle}
                                                    </TextDarkGrey4>
                                                )}

                                                {section.body.map((elem, elmIndex) => {
                                                    if (elem.elementType === 'multiselect') {
                                                        return (
                                                            <InputWrap maxWidth="328px" key={elmIndex}>
                                                                <MultiSelect
                                                                    handleMultiSelect={(item: React.ReactText[]) =>
                                                                        handleMultiSelectDropdown(item, elem.id)
                                                                    }
                                                                    checkedState={getMultiSelectedValues(elem.id)}
                                                                    options={
                                                                        elem.multiSelectOptions
                                                                            ? elem.multiSelectOptions
                                                                            : []
                                                                    }
                                                                    testId={`${elem.id}-dropdown-filter`}
                                                                    selectId={1}
                                                                    placeHolder={elem && elem.placeHolder}
                                                                    label={elem.label}
                                                                    noOverlay={false}
                                                                    disabled={isRequest}
                                                                    expandable={isRequest}
                                                                />
                                                            </InputWrap>
                                                        );
                                                    }
                                                    if (elem.elementType === 'checkboxWithInput') {
                                                        return (
                                                            <InputWrap maxWidth="784px" key={elmIndex}>
                                                                <TextArea
                                                                    maxLength={255}
                                                                    disabled={isRequest}
                                                                    label={elem.label}
                                                                    value={formState.remarks}
                                                                    handleInput={handleRemarks}
                                                                    placeHolder={elem.placeHolder}
                                                                />
                                                            </InputWrap>
                                                        );
                                                    }
                                                })}
                                            </SectionBody>
                                        )}
                                        {section.toggle.id === 'remarks' ? null : (
                                            <StyledDividerContentWrapper id={`separator-${section.toggle.id}`} />
                                        )}
                                    </>
                                ))}
                            </ContentWrapperWithTitle>
                        </WrapperDiv>
                        {!isRequest && (
                            <ButtonDiv>
                                <CustomButton
                                    style={reasonsPageButtons}
                                    onClick={() => history.goBack()}
                                    disabled={isRequest}
                                    id="reject-transaction-cancel-btn"
                                >
                                    {LABEL.cancel}
                                </CustomButton>
                                <CustomButton
                                    primary
                                    style={reasonsPageButtons}
                                    disabled={getDisabledStatus(formState) || isRequest}
                                    onClick={() => handleSubmit()}
                                    id="reject-transaction-confirm-btn"
                                >
                                    {LABEL.submit}
                                </CustomButton>
                            </ButtonDiv>
                        )}
                    </StyledInner>
                    {isRequest &&
                        (pendingTab.reviewApproval.canApproveReject === LABEL.checker ||
                            pendingTab.reviewApproval.canApproveReject === LABEL.autoAuthorizer) &&
                        approval.status === '' && (
                            <Fragment>
                                <Banner
                                    title={SYSTEM_ADMIN.LABEL_REQUEST_PENDING_APPROVAL}
                                    isRequestBanner={false}
                                    toggle={isRequest}
                                    handleClose={handleIsRequest}
                                    description={`${LABEL.requestedBy}`}
                                    descriptionEmText={requestData?.requestorName}
                                    testId="rejecttransaction-banner"
                                    primaryButtonObject={{
                                        label: LABEL.approve,
                                        handlePrimaryBtn: () => handleApproveRequest(),
                                        width: '276px',
                                        icon: 'check',
                                    }}
                                    secondaryButtonObject={{
                                        label: LABEL.reject,
                                        handleSecondaryBtn: () => {
                                            setRejectReq(!rejectReq);
                                        },
                                        width: '276px',
                                        icon: 'terminate',
                                        color: '#E84C3D',
                                    }}
                                    setBannerHeight={setBannerHeight}
                                />
                            </Fragment>
                        )}

                    {/* Yes or No modal */}
                    {showConfirmationModal ? (
                        <Modal
                            title={modalMessages.confirmation.heading}
                            modalActive={showConfirmationModal}
                            setModalActive={handleConfirmModal}
                            primaryBtn={{
                                onClick:
                                    modalMessages.confirmation.type === 'approve request'
                                        ? () => reviewRequest(requestData?.requestId, 'approve')
                                        : () => reviewRequest(requestData?.requestId, 'reject', rejectRemarks),
                                label: LABEL.yes,
                                primary: true,
                            }}
                            secondaryBtn={{ onClick: handleConfirmModal, label: LABEL.noNo }}
                            testId="rejct-order-confirmation"
                        >
                            {modalMessages.confirmation.content}
                        </Modal>
                    ) : null}
                </Fragment>
            ) : (
                <Fragment>
                    <RejectRemarks
                        handleCancel={() => {
                            setRejectReq(!rejectReq);
                            history.goBack();
                        }}
                        handleSubmit={handleRejectRequest}
                        handleRejectRemarks={(e) => {
                            setRejectRemarks(e.target.value);
                        }}
                        rejectRemarks={rejectRemarks}
                    />
                </Fragment>
            )}
            {showFeedbackModal ? (
                <Modal
                    modalActive={showFeedbackModal}
                    setModalActive={handleFeedbackModal}
                    title={modalMessages.feedback.heading}
                    icon={modalMessages.feedback.icon}
                    primaryBtn={{
                        onClick: () => handleDone(),
                        label: LABEL.done,
                        primary: true,
                    }}
                    testId="reject-order-feedback"
                    contentAlignment="center"
                >
                    {rejectReq
                        ? modalMessages.feedback.content
                        : handleFeedbackModalMessage(pendingTab.actions.canReject)}
                </Modal>
            ) : null}

            {loading ? <ComponentLoader /> : null}
        </Fragment>
    );
};

// const ReasonsLabel = styled.div`
//     font-size: 1.125rem;
//     letter-spacing: -0.2px;
//     font-weight: 700;
//     color: #333333;
//     margin-bottom: 0.25rem;
// `;
const WrapperDiv = styled.div`
    /* margin-top: 1rem; */
    margin-bottom: 2rem;
    max-width: 1088px;
`;
const ButtonDiv = styled((props) => <FlexedDiv {...props} />)`
    height: 3rem;
    max-width: 25vw;
    margin-top: 3rem;
    margin-bottom: 4.56rem;
`;
const Title = styled((props) => <FlexedDiv {...props} />)`
    font-weight: 800;
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
`;
const ApprovalBanner = styled((props) => <FlexedDiv {...props} />)`
    background: #fefaf2;
    border: 1px solid #e89700;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 1.5rem;
    align-items: flex-start;
    margin-bottom: 1rem;
`;
const Subtitle = styled.div`
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
`;
const Bold = styled.span`
    font-weight: 700;
`;
const CollapseWrapper = styled.div<IOnPropStyles>`
    box-shadow: ${(props) => (props.removeBorder ? `inset 0px 1px 0px #66798e` : `unset`)};
    padding-top: ${(props) => (props.removeBorder ? '1rem' : '0')};
    margin-top: 1rem;
`;
const TransactionRefList = styled.ul`
    margin: 0;
    list-style-position: inside;
    padding: 0px 8px;
    font-weight: 400;
    font-size: 0.75rem;
    color: #333333;
    max-height: 9.5rem;
    overflow: auto;
`;
const StyledDividerContentWrapper = styled.div`
    height: 1px;
    mix-blend-mode: normal;
    opacity: 0.1;
    border: 1px solid #333333;
    width: auto !important;
`;
const StyledInner = styled.div<StyledInnerProps>`
    margin-left: 2.5rem;
    padding-bottom: ${(props) => props.paddingBottom}px;
`;
const ToggleWrap = styled.div<ToggleWrapProps>`
    background-color: #ffffff;
    margin-bottom: ${(props) => (props.expanded ? '0' : '0.75rem')};
    /* box-shadow: 0px -1px 0px 0px #333333 inset; */
    padding: 0.25rem 2rem;
    margin-top: 0.75rem;
`;
const InputWrap = styled.div<InputWrapProps>`
    width: 100%;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '360px')};
    margin-right: 1rem;
    margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`;
const SectionBody = styled.div`
    padding: 1rem 2.5rem 1.75rem;
`;

export default RejectTransaction;
