/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, Fragment, useEffect, useContext } from 'react';
import { LABEL } from '../../constants';
import { StaffProfile } from './StaffProfile';
import { Tabs } from '../../components/organisms/Tabs/Tabs';
import { API, graphqlOperation } from 'aws-amplify';
import { staffProfileQuery } from '../../_graphql/queries/common/staffProfile';
import { handleStaffProfile } from '../../utils';
import { useComponentLoader } from '../../customHooks';
import { ComponentLoader, LogoSpinner } from '../../components';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';

export const INITIAL_PROFILE = {
    name: '',
    status: '-',
    details: [
        {
            title: 'User Profile',
            sections: [
                {
                    data: [
                        { label: 'Home Branch', data: '-' },
                        { label: 'Department', data: '-' },
                    ],
                },
            ],
        },
        {
            title: 'Contact Details',
            sections: [
                {
                    data: [
                        { label: 'Email Address', data: '-' },
                        { label: 'Mobile Number', data: '-' },
                    ],
                },
            ],
        },
    ],
};
const Profile: React.FC = () => {
    const [profile, setProfile] = useState<IStaffProfileDetails>(INITIAL_PROFILE);
    const { userLoginContext } = useContext(AuthContext);
    const { loading, loadingHandler } = useComponentLoader();
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const fetchStaffProfile = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(graphqlOperation(staffProfileQuery), idTokenHeader);
            const resultCheck = response.data.userProfile;
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.userProfile.data;
            const temp = handleStaffProfile(result);
            setProfile(temp);
            loadingHandler();
        } catch (error) {
            loadingHandler();
            if (Object.keys(error as IErrorHandling).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: (error as IErrorHandling).message,
                    errorCode: (error as IErrorHandling).errorCode,
                    title: 'Cannot Fetch Profile',
                    testId: 'profile-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    useEffect(() => {
        fetchStaffProfile();
    }, []);

    const tabs = [<StaffProfile profile={profile} key={1} />];
    return (
        <Fragment>
            <Tabs backIcon={false} title={LABEL.staffProfile} tabTitles={[LABEL.profile]} tabs={tabs} primary />
            {/* {loading ? <LogoSpinner /> : null} */}
            {loading ? <ComponentLoader /> : null}
        </Fragment>
    );
};

export default Profile;
