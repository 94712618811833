/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useRef } from 'react';
import { Tabs, Modal, ComponentLoader, Banner, FlexedDiv, TrackingStepper, PreviousPage } from '../../components';
import { LABEL, TextDarkBlack, TextNavyBlue, SYSTEM_ADMIN } from '../../constants';

import styled from 'styled-components';
import RejectRemarks from '../RejectRemarks/RejectRemarks';
import { IcoMoon } from '../../icons';
import { useOrderDetailsTrxHandler } from './useOrderSummaryTrxHandler';

const OrderDetails: React.FC = () => {
    const {
        disableVerify,
        handleAlert,
        handleApproveRequest,
        handleBack,
        handleConfirmModal,
        handleModal,
        handleRejectCancel,
        handleVerifyOrder,
        handleViewStatusOverview,
        loading,
        locationState,
        modalMessages,
        ORDER_SUMMARY_LINKS,
        orderSummary,
        pendingTab,
        rejectOrder,
        rejectRemarks,
        rejectReq,
        rejectRequest,
        rerouteOrder,
        reviewRequest,
        setRejectRemarks,
        setRejectReq,
        showConfirmationModal,
        showFeedbackModal,
        statusOverView,
        tabs,
        tabTitles,
        toggle,
        verifyTransactions,
        generateHeaderButtons,
        isPermissionValidToReview,
        handleReviewRerouteRejectRequest,
    } = useOrderDetailsTrxHandler();

    const activeTabRef = useRef<number>(1);
    const getActiveTab = (tab: number) => {
        activeTabRef.current = tab;
    };
    const disableReroute = () => {
        if (orderSummary?.transactionType)
            switch (orderSummary.transactionType.toLowerCase()) {
                case 'switch':
                case 'redeem':
                    const _disabled = locationState.verifyOnly || !orderSummary.documentSummary.hardcopy.required;
                    return _disabled;
                default:
                    return locationState.verifyOnly;
            }
    };
    return (
        <Fragment>
            {rejectReq ? (
                <Fragment>
                    <RejectRemarks
                        handleCancel={handleRejectCancel}
                        handleSubmit={rejectRequest}
                        handleRejectRemarks={(e) => {
                            setRejectRemarks(e.target.value);
                        }}
                        rejectRemarks={rejectRemarks}
                    />
                </Fragment>
            ) : (
                <div style={{ position: 'relative' }}>
                    <PreviousPage
                        title={`${orderSummary?.orderNo ?? ''}${orderSummary?.transactionRef ?? ''}`}
                        buttons={generateHeaderButtons(
                            orderSummary?.documentSummary.accountType ? orderSummary.documentSummary.accountType : '',
                            orderSummary?.isEtb,
                        )}
                        handleBackFunction={handleBack}
                    />
                    <FlexedDiv style={{ alignItems: 'flex-start' }}>
                        <Tabs
                            tabTitles={tabTitles}
                            tabs={tabs}
                            testId="order-summary"
                            primary
                            wrapperStyle={{ flex: 1 }}
                            links={ORDER_SUMMARY_LINKS}
                            linkState={locationState}
                            selectTab={getActiveTab}
                        />
                        <TrackingStatusWrapper id="status-overview">
                            <TextDarkBlack weight="700" size="1.125rem" style={{ marginLeft: '.5rem' }}>
                                {LABEL.statusOverview}
                            </TextDarkBlack>
                            {ORDER_SUMMARY_LINKS.at(-1) !== `/${window.location.pathname.split('/').at(-1)}` && (
                                <FlexedDiv
                                    alignItems={'center'}
                                    style={{ margin: '10px 0px 0px 8px', columnGap: '4px', cursor: 'pointer' }}
                                    onClick={() => handleViewStatusOverview(activeTabRef.current)}
                                >
                                    <TextNavyBlue size="12px" weight="700">
                                        {LABEL.viewDetails}
                                    </TextNavyBlue>
                                    <IcoMoon name="arrow-right" size={'12px'} />
                                </FlexedDiv>
                            )}
                            <TrackEventsWrapper>
                                {statusOverView.map((track, index) => {
                                    return (
                                        <TrackingStepper
                                            steps={track.steps}
                                            title={track.title}
                                            key={index + (track.title ?? 'title')}
                                            expanded={
                                                `/${window.location.pathname.split('/').at(-1)}` ===
                                                ORDER_SUMMARY_LINKS.at(-1)
                                            }
                                        />
                                    );
                                })}
                            </TrackEventsWrapper>
                        </TrackingStatusWrapper>
                    </FlexedDiv>
                    {orderSummary?.status.toLowerCase() === 'in review' &&
                    (pendingTab.actions.canVerifyTransactions === LABEL.maker ||
                        pendingTab.actions.canVerifyTransactions === LABEL.autoAuthorizer) ? (
                        <Fragment>
                            <Banner
                                title="Transaction Review"
                                description="Transaction Number"
                                descriptionEmText={`${orderSummary?.orderNo}${orderSummary?.transactionRef}`}
                                toggle={true}
                                handleClose={handleAlert}
                                disabled={disableVerify}
                                testId="ordersummary-approval-banner"
                                primaryButtonObject={{
                                    label: LABEL.verify,
                                    icon: 'check',
                                    handlePrimaryBtn: handleVerifyOrder,
                                    width: '168px',
                                }}
                                secondaryButtonObject={[
                                    {
                                        label: 'Reroute',
                                        handleSecondaryBtn: rerouteOrder,
                                        icon: 'reroute',
                                        width: '168px',
                                        isDisabled: disableReroute(),
                                    },
                                    {
                                        label: 'Reject',
                                        handleSecondaryBtn: rejectOrder,
                                        icon: 'terminate',
                                        color: '#E84C3D',
                                        width: '168px',
                                        isDisabled: locationState.verifyOnly,
                                    },
                                ]}
                            />
                        </Fragment>
                    ) : null}
                    {orderSummary?.status.toLowerCase() === 'pending approval' &&
                    !locationState.fromTrackOrder &&
                    locationState.tab.includes('daily') &&
                    isPermissionValidToReview(locationState?.approvalType) ? (
                        <Banner
                            title={SYSTEM_ADMIN.LABEL_REQUEST_PENDING_APPROVAL}
                            toggle={toggle}
                            primaryButtonObject={{
                                label: locationState?.approvalType === 'approve' ? LABEL.approve : LABEL.reviewRequest,
                                icon: locationState?.approvalType === 'approve' ? 'check' : 'report-navIcon',
                                handlePrimaryBtn:
                                    locationState?.approvalType === 'approve'
                                        ? handleApproveRequest
                                        : handleReviewRerouteRejectRequest,
                                width: '260px',
                            }}
                            secondaryButtonObject={
                                locationState?.approvalType === 'approve'
                                    ? {
                                          label: LABEL.reject,
                                          icon: 'terminate',
                                          color: '#E84C3D',
                                          handleSecondaryBtn: () => setRejectReq(!rejectReq),
                                          width: '260px',
                                      }
                                    : undefined
                            }
                            description={`${LABEL.requestedBy}`}
                            descriptionEmText={orderSummary?.requesterName}
                            testId="ordersummary-approval-banner"
                        />
                    ) : null}
                    {showConfirmationModal ? (
                        <Modal
                            title={modalMessages.confirmation.heading}
                            modalActive={showConfirmationModal}
                            setModalActive={handleConfirmModal}
                            primaryBtn={{
                                onClick:
                                    modalMessages.confirmation.type === 'verify'
                                        ? () =>
                                              orderSummary?.transactionRef !== undefined &&
                                              verifyTransactions(orderSummary?.transactionRef)
                                        : () =>
                                              orderSummary?.requestId !== undefined
                                                  ? reviewRequest(orderSummary?.requestId?.toString(), 'approve')
                                                  : null,
                                label: LABEL.yes,
                                primary: true,
                            }}
                            secondaryBtn={{ onClick: handleConfirmModal, label: LABEL.noNo }}
                            testId="order-confirmation"
                        >
                            {modalMessages.confirmation.content}
                        </Modal>
                    ) : null}

                    {loading ? <ComponentLoader /> : null}
                </div>
            )}
            {showFeedbackModal ? (
                <Modal
                    modalActive={showFeedbackModal}
                    setModalActive={handleModal}
                    title={modalMessages.feedback.heading}
                    icon={modalMessages.feedback.icon}
                    primaryBtn={{
                        onClick: () => handleModal(),
                        label: LABEL.done,
                        primary: true,
                    }}
                    testId="order-feedback"
                    contentAlignment="center"
                >
                    {modalMessages.feedback.content}
                </Modal>
            ) : null}
        </Fragment>
    );
};
export default OrderDetails;

const TrackingStatusWrapper = styled.div`
    width: 15vw;
    min-width: 216px;
    background: #fbfbfb;
    margin-left: 16px;
    padding: 1rem 0.5rem;
    border-radius: 8px;
    height: fit-content;
    transition: all 0.3s;
`;

const TrackEventsWrapper = styled.div`
    width: 100%;
    padding-top: 16px;
    & > *:not(:last-child) {
        margin-bottom: 16px;
    }
`;
