import React, { Fragment } from 'react';
import { Pointer } from '../../../components';
import CustomDropdown, { IDropdownItem } from '../../../components/molecules/Dropdown/Dropdown';
import { LABEL } from '../../../constants';
import { IcoMoon } from '../../../icons';

interface HistoryActionsProps extends ITableOptions {
    handlePrintTransactionReport: (item: IColumnItemAccordion) => void;
    handleDownloadPPA: (item: IColumnItemAccordion) => void;
    historyTab: IHistoryTab;
}
const HistoryActions = ({
    data,
    handlePrintTransactionReport,
    handleDownloadPPA,
    historyTab,
}: HistoryActionsProps): JSX.Element => {
    const status: string = data.rawData.status.toLowerCase();
    const menu = (status: string) => {
        switch (status) {
            case 'completed':
                return completedMenu;
        }
    };
    const handlePrint = () => {
        handlePrintTransactionReport(data);
    };
    const handlePPA = () => {
        handleDownloadPPA(data);
    };
    const completedMenu: IDropdownItem[] = [];
    if (historyTab.actions.canDownloadTransactionReport === LABEL.maker) {
        data.rawData.utmc === LABEL.kib &&
        data.rawData.fundType.toLowerCase().includes('prs') &&
        data.rawData.transactionType.toLowerCase() === 'sales-ao'
            ? completedMenu.push(
                  {
                      item: LABEL.downloadTransactionReport,
                      icon: 'download',
                      handleItem: handlePrint,
                      testId: `history-download-transactions-btn`,
                  },
                  {
                      item: LABEL.ppaOnlineAccountOpeningFormDownload,
                      icon: 'download',
                      handleItem: handlePPA,
                      testId: `history-download-ppa-btn`,
                  },
              )
            : completedMenu.push({
                  item: LABEL.downloadTransactionReport,
                  icon: 'download',
                  handleItem: handlePrint,
                  testId: `history-download-transactions-btn`,
              });
    }
    let items: IDropdownItem[] = [];
    let showAction = false;
    const item = menu(status);

    if (item !== undefined && item.length !== 0) {
        items = item;
        showAction = true;
    }
    function getSingleActionMenu(status: string): JSX.Element {
        switch (status.toLowerCase()) {
            case 'completed':
                return (
                    <Pointer onClick={() => handlePrint()}>
                        <IcoMoon name="download" size="1.5rem" />
                    </Pointer>
                );
            default:
                return <div />;
        }
    }
    return (
        <Fragment>
            {showAction ? (
                items.length === 1 && (status.toLowerCase() === 'in review' || status.toLowerCase() === 'completed') ? (
                    getSingleActionMenu(status)
                ) : (
                    <CustomDropdown items={items}>
                        <Pointer>
                            <IcoMoon name="action-menu" size="1.5rem" />
                        </Pointer>
                    </CustomDropdown>
                )
            ) : null}
        </Fragment>
    );
};
export default HistoryActions;
