export const selectedTabUploads = (tab: string): string => {
    let temp = '';
    switch (tab) {
        case 'master fund ':
            temp = 'masterfund';
            break;
        case 'NAV ':
            temp = 'navperunit';
            break;
        case 'Distribution ':
            temp = 'distribution';
            break;
        case 'Documents ':
            temp = 'documents';
            break;
        default:
            temp = tab;
            break;
    }
    return temp;
};
