import React, { ChangeEvent, Fragment, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import * as Routes from '../../../../routes';
import {
    CustomButton,
    CustomSpacer,
    FlexedDiv,
    ContentWrapperWithTitle,
    PreviousPage,
    TextArea,
} from '../../../../components';
import { Fs15BoldNeutralWhite, Fs15BoldPrimaryBlack, SYSTEM_ADMIN, LABEL } from '../../../../constants';
import styled from 'styled-components';

export interface RejectRemarksProps {
    remarks: string;
    setRejectRemarks?: (toggle: boolean) => void;
    setRemarks: (text: string) => void;
    setShowModal: (toggle: boolean) => void;
    showModal: boolean;
}

export const RejectRemarks: FunctionComponent<RejectRemarksProps> = ({
    remarks,
    setRejectRemarks,
    setRemarks,
    setShowModal,
}: RejectRemarksProps) => {
    const handleRemarks = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setRemarks(event.target.value);
    };

    const handleSave = () => {
        setShowModal(true);
    };

    const clearStateOnReject = () => {
        if (remarks !== null) {
            setRemarks('');
        }
        if (setRejectRemarks !== undefined) {
            setRejectRemarks(false);
        }
        //history.goBack();
    };

    const handleBack = () => {
        const data = { tab: 'Roles & Permissions' };
        history.push(Routes.dashboardSystemAdmin, data);
    };
    const history = useHistory();

    const disabled = remarks.trim().length === 0;

    return (
        <Fragment>
            <div>
                <PreviousPage
                    backIcon={true}
                    title={SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_REMARKS}
                    handleBackFunction={handleBack}
                    subTitle={SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_REMARKS_SUB}
                />
                <FlexedDiv direction="row" margin="auto">
                    <FlexedDiv direction="column" style={{ margin: 'auto', width: '80.111vw' }}>
                        <ContentWrapperWithTitle title={LABEL.remarks}>
                            <TextAreaWrapper>
                                <TextArea
                                    handleInput={handleRemarks}
                                    label={LABEL.addRemarks}
                                    maxLength={255}
                                    name="rejectRemark"
                                    style={{ margin: 'auto' }}
                                    value={remarks}
                                    placeHolder={LABEL.rejectTextAreaPlaceholder}
                                />
                            </TextAreaWrapper>
                        </ContentWrapperWithTitle>
                    </FlexedDiv>
                </FlexedDiv>
                <CustomSpacer space={'3.5rem'} />
                <FlexedDiv style={{ marginLeft: '0.75rem' }}>
                    <CustomButton
                        primary={false}
                        onClick={() => clearStateOnReject()}
                        style={{ paddingTop: '0.625rem', paddingBottom: '0.625rem', width: '16.667vw' }}
                    >
                        <Fs15BoldPrimaryBlack>{SYSTEM_ADMIN.BUTTON_CANCEL}</Fs15BoldPrimaryBlack>
                    </CustomButton>
                    <CustomSpacer horizontal={true} space={'1rem'} />
                    <CustomButton
                        disabled={disabled}
                        primary={true}
                        onClick={handleSave}
                        style={{ paddingTop: '0.625rem', paddingBottom: '0.625rem', width: '16.667vw' }}
                    >
                        <Fs15BoldNeutralWhite>{SYSTEM_ADMIN.BUTTON_SUBMIT}</Fs15BoldNeutralWhite>
                    </CustomButton>
                </FlexedDiv>
            </div>
        </Fragment>
    );
};

const TextAreaWrapper = styled.div`
    max-width: 1188px;
    width: 100%;
    margin: auto;
`;
