/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FlexedDiv, SelectDropdown, TextArea, ToggleToken } from '../../../components';
import { IcoMoon } from '../../../icons';
import { LABEL } from '../../../constants';

declare interface IGenericInputProps {
    item: any;
    isConclusion: boolean;
    additionalQuestion: string;
}

export const GenericInput = ({ item, isConclusion, additionalQuestion }: IGenericInputProps): JSX.Element => {
    const toggleTokenHandler = (value: string) => {
        // console.log(value, 'toggleTokenHandler');
    };
    return item.options.map((data: any, i: number) => {
        let inputEle;
        switch (data.type) {
            case 'radiobutton':
                inputEle = (
                    <InlineBlock>
                        <FlexedDiv>
                            <ToggleToken
                                label={data.title}
                                testId={i.toString()}
                                disabled={true}
                                selected={false}
                                partial={true}
                                handleToggleToken={(value) => toggleTokenHandler(value)}
                            />
                            {isConclusion && (
                                <div>
                                    <IcoMoon
                                        name={'info'}
                                        size="24px"
                                        color="#002043"
                                        style={{ opacity: 0.6, marginLeft: '8px' }}
                                    />
                                </div>
                            )}
                        </FlexedDiv>
                    </InlineBlock>
                );
                break;
            case 'dropdown':
                inputEle = (
                    <Fragment>
                        <StyledDropDownLabel>{data.title}</StyledDropDownLabel>

                        <SelectWrapper>
                            <SelectDropdown
                                options={[{ label: 'Select one or more', value: 'Select one or more' }]}
                                testId={`eddquestion-dropdown`}
                                selectedValue="Select one or more"
                                isDisabled={true}
                            />
                        </SelectWrapper>
                    </Fragment>
                );
                break;
            case 'checkbox':
                inputEle = (
                    <Fragment>
                        {i > 0 && item.options[i - 1].title === 'Others' ? <StyledBorder /> : null}
                        <input key={i} type="checkbox" value={data.id} id={item.id} disabled={true} />
                        <StyledLabel>{data.title}</StyledLabel>
                        {data.description ? (
                            <Fragment>
                                <div style={{ margin: '-11px 0px 0px 0px' }} />
                                <StyledSubTitle>{data.description}</StyledSubTitle>
                            </Fragment>
                        ) : null}
                        <br />
                    </Fragment>
                );
                break;
            case 'textinput':
                inputEle = (
                    <Fragment>
                        <TextArea
                            name={data.title}
                            label={data.title}
                            value={additionalQuestion}
                            handleInput={undefined}
                            maxLength={255}
                            style={{ color: 'black', width: '672px' }}
                            placeHolder={LABEL.eddPlaceHolder}
                        />
                    </Fragment>
                );
                break;
            default:
                break;
        }
        return inputEle;
    });
};
const StyledLabel = styled.label`
    margin: 0px 0px 0px 13px;
`;
const StyledDropDownLabel = styled.label`
    width: 336px;
    height: 16px;
    font-weight: bold;
    font-size: 12px;
    color: #333333;
    margin: 4px 0px;
`;
const StyledSubTitle = styled.label`
    margin: 0px 0px 0px 25px;
    width: 157px;
    height: 16px;
    font-weight: normal;
    font-size: 10px;
    color: #4d4d4d;
`;
const StyledBorder = styled.div`
    border: 1px solid #ececec;
    margin: 16px 0px;
`;
const InlineBlock = styled.div`
    display: -webkit-inline-box;
    width: 50%;
`;
const SelectWrapper = styled.div`
    margin-right: 1vw;
    width: 19.48vw;
    height: 3rem;
    opacity: 0.6;
`;
