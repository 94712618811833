/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const dailySummaryReportProgress = /* GraphQL */ `
  subscription DailySummaryReportProgress {
    dailySummaryReportProgress {
      data {
        result {
          message
          event
          id
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const onDashboardExportList = /* GraphQL */ `
  subscription OnDashboardExportList {
    onDashboardExportList {
      data {
        result {
          url
          endpoint
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const operationReportProgress = /* GraphQL */ `
  subscription OperationReportProgress {
    operationReportProgress {
      data {
        result {
          message
          event
          id
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
