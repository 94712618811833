import React, { Fragment } from 'react';
import { Pointer } from '../../../../../components';
import CustomDropdown, { IDropdownItem } from '../../../../../components/molecules/Dropdown/Dropdown';

import { LABEL } from '../../../../../constants';
import { IcoMoon } from '../../../../../icons';

interface TargetDateExActions extends ITableOptions {
    handleView: (item: IColumnItemAccordion) => void;
    handleApprove: (item: IColumnItemAccordion) => void;
    handleReject: (item: IColumnItemAccordion) => void;
    permissions: ISystemSettings['epfTab'];
}
export const EPFActions = ({
    data,
    handleView,
    handleApprove,
    handleReject,
    permissions,
}: TargetDateExActions): JSX.Element => {
    const handlePrimaryAction = () => {
        handleApprove(data);
    };
    const handleSecondaryAction = () => {
        handleReject(data);
    };
    const handleTertiaryAction = () => {
        handleView(data);
    };
    const epfMenuActions: IDropdownItem[] = [
        {
            item: LABEL.viewDetails,
            icon: 'eye-show',
            handleItem: handleTertiaryAction,
            testId: `view-details-btn`,
        },
        {
            item: LABEL.approveRequest,
            icon: 'check',
            handleItem: handlePrimaryAction,
            testId: `approve-request-btn`,
        },
        {
            item: LABEL.rejectRequest,
            icon: 'close',
            handleItem: handleSecondaryAction,
            testId: `reject-request-btn`,
            danger: true,
        },
    ];
    const generateCheckerActions = (permission: string, status: string): JSX.Element | null => {
        let _temp = null;
        if (permission === 'checker' && status === 'Pending Approval') {
            _temp = (
                <CustomDropdown items={epfMenuActions}>
                    <Pointer>
                        <IcoMoon name="action-menu" size="1.5rem" />
                    </Pointer>
                </CustomDropdown>
            );
        } else if (permission === 'checker' && status !== 'Pending Approval') {
            _temp = (
                <Pointer onClick={handleTertiaryAction}>
                    <IcoMoon name="eye-show" size="1.5rem" />
                </Pointer>
            );
        }
        return _temp;
    };
    const generateMakerActions = (permission: string, status: string): JSX.Element | null => {
        let _temp = null;
        if (permission === 'maker' && status === 'Pending Approval') {
            _temp = (
                <Pointer onClick={handleTertiaryAction}>
                    <IcoMoon name="eye-show" size="1.5rem" />
                </Pointer>
            );
        } else if (permission === 'maker' && status !== 'Pending Approval') {
            _temp = (
                <Pointer onClick={handleTertiaryAction}>
                    <IcoMoon name="edit-new" size="1.5rem" />
                </Pointer>
            );
        }
        return _temp;
    };
    return (
        <Fragment>
            {generateCheckerActions(permissions.reviewApproval.canApproveChange, data.rawData.status)}
            {generateMakerActions(permissions.actions.canChangeSettings, data.rawData.status)}
        </Fragment>
    );
};
