import React, { Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { EMPTY_STATES, LABEL, TextDarkBlack } from '../../../constants';
import { AdvanceTable } from '../../../components';
import { DocumentsActions } from './';
import { sh38, sh120, sh220, sh288, sh96 } from '../../../styles';

import UploadsContext from '../../../context/UploadsContext';
import * as Routes from '../../../routes';
import CustomItem from '../Shared/CustomItem';
import AuthContext from '../../../context/AuthContext';

export interface IAllDocumentsProps {
    allDocumentsData: ITableData[];
    permissions: IUserActionsValues;
    getTabIndex: () => void;
    handleDashboardModal: (documnetCount: number) => void;
    showResultCount?: boolean;
}
export const Documents: React.FC<IAllDocumentsProps> = ({
    allDocumentsData,
    permissions,
    getTabIndex,
    handleDashboardModal,
    showResultCount,
}: IAllDocumentsProps) => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { bulkDocumentsStatusMutation, searchInput, tempState, dispatch } = useContext(UploadsContext);

    const history = useHistory();
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { documentsTab } = parsedPermission.hq.permission.uploads;
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        if (tempState.sortData.column === keyName) {
            const icon = tempState.sortData.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
            sortIcon = icon;
        } else {
            sortIcon = 'arrow-down';
        }
        return sortIcon;
    };
    const allDocumetnsCols: ITableColumn[] = [
        {
            icon: {
                name: handleSortIcon('fileName'),
                size: '1rem',
            },
            key: [{ key: 'fileName' }],
            viewStyle: {
                width: sh288,
            },
            title: LABEL.fileName,
            titleStyle: {
                fontWeight: tempState.sortData.column === 'fileName' ? 700 : 400,
            },
            onPressHeader: () => handleSort('fileName'),
            customItem: true,
        },
        {
            icon: {
                name: handleSortIcon('totalDocuments'),
                size: '1rem',
            },
            key: [
                {
                    key: 'totalDocuments',
                    textStyle: { fontWeight: tempState.sortData.column === 'totalDocuments' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: sh120,
            },
            title: LABEL.totalDocuments,
            titleStyle: {
                fontWeight: tempState.sortData.column === 'totalDocuments' ? 700 : 400,
            },
            onPressHeader: () => handleSort('totalDocuments'),
        },

        {
            icon: {
                name: handleSortIcon('uploadedOn'),
                size: '1rem',
            },
            key: [{ key: 'uploadedOn' }],
            viewStyle: {
                width: sh220,
            },
            title: LABEL.uploadedOn,
            titleStyle: {
                fontWeight: tempState.sortData.column === 'uploadedOn' ? 700 : 400,
            },
            customItem: true,
            onPressHeader: () => handleSort('uploadedOn'),
        },

        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh220,
            },
            title: LABEL.status,
            titleStyle: { fontWeight: tempState.sortData.column === 'status' ? 700 : 400 },
            customItem: true,
            onPressHeader: () => handleSort('status'),
        },
        {
            key: [{ key: 'remark' }],
            viewStyle: {
                width: sh96,
            },
            title: LABEL.remarks,
            customItem: true,
        },

        {
            key: [],
            viewStyle: {
                width: sh38,
            },
            itemIcon: {
                name: documentsTab.actions.canViewDocuments === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                documentsTab.actions.canViewDocuments === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'documents-view-btn',
        },
    ];
    const handleSort = (keyName: string) => {
        const tempSort = { ...tempState.sortData };
        const sortType = tempState.sortData.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
        tempSort.value = sortType;
        tempSort.column = keyName;
        dispatch({ type: 'sortData', payload: tempSort });
        // setSortData(tempSort);
    };
    const handleViewDetails = (item: IColumnItemAccordion) => {
        history.push({
            pathname: Routes.financeReviewBulkUpload,
            state: {
                fundDocumentId: item.rawData.fundDocumentId,
                title: 'Documents',
                status: item.rawData.status,
                tabIndex: getTabIndex(),
            },
        });
    };
    const handleReject = (item: IColumnItemAccordion) => {
        history.push({
            pathname: Routes.financeRejectImport,
            state: {
                fundDocumentId: item.rawData.fundDocumentId,
                type: 'Bulk Upload Documents',
                tab: 'documents',
                tabIndex: getTabIndex(),
            },
        });
    };

    // fn to approve bulk documents from dashboard
    const handleApprove = async (item: IColumnItemAccordion) => {
        const _checkerModal = {
            fundDocumentId: item.rawData.fundDocumentId,
            status: 'approve',
            remark: '',
        };

        (await bulkDocumentsStatusMutation(_checkerModal)) ? handleDashboardModal(item.rawData.totalDocuments) : null;
    };
    return (
        <Fragment>
            {showResultCount && searchInput.value ? (
                <TextDarkBlack
                    size="16px"
                    weight="700"
                    style={{ margin: '24px' }}
                >{`${allDocumentsData.length} result(s) found for '${searchInput.value}'`}</TextDarkBlack>
            ) : null}
            <AdvanceTable
                data={allDocumentsData}
                columns={allDocumetnsCols}
                onEmptyState={EMPTY_STATES.emptyStateImportDocuments}
                RenderOptions={(props: ITableOptions) => (
                    <DocumentsActions
                        {...props}
                        handleApprove={handleApprove}
                        handleReject={handleReject}
                        permissions={permissions}
                    />
                )}
                RenderItem={(props: ITableCustomItem) => (
                    <CustomItem isSortedColumn={tempState.sortData.column} {...props} />
                )}
            />
        </Fragment>
    );
};
