/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, Fragment, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    CustomButton,
    CustomSpacer,
    FlexedDiv,
    Modal,
    ContentWrapperWithTitle,
    PreviousPage,
    RadioButton,
    TextArea,
} from '../../../../components';
import { TextDarkGrey6, SYSTEM_ADMIN, TextWhite } from '../../../../constants';
import { IcoMoon } from '../../../../icons';
import { submitData } from '../utils/functions';
import { BranchPrivileges, DropdownBase } from './BranchPrivileges/BranchPrivileges';
import {
    adviserInitialData,
    customerExpInitialData,
    IAdviserSection,
    ICustomerExpSection,
    ILoginRoleSection,
    loginAccessInitialData,
} from './BranchPrivileges/branchRolesInitialData';
import { HqPrivileges } from './HqPrivileges';
import {
    activityLogsInitialData,
    branchOfficesInitialData,
    customerExperienceInitialData,
    eddCaseInitialData,
    financeCasesInitialData,
    financeDashboardInitialData,
    fundUploadsInitialData,
    IActivityLogsSection,
    IBranchOfficeSection,
    ICustomerExperienceSection,
    IEDDCasesSection,
    IFinanceCasesSection,
    IFinanceDashboardSection,
    IHQRoleFundUploads,
    IOperationCases,
    IOperationDashboard,
    IHQRoleProductSettings,
    IUserManagementSection,
    operationCasesInitialData,
    operationDashboardInitialData,
    productSettingsInitialData,
    userManagementInitialData,
    hqLoginAccessInitialData,
    ISystemSettingsSection,
    systemSettingsInitialData,
    IReportsSection,
    reportsInitialData,
} from './HqPrivileges/hqRolesInitialData';
import { RejectRemarks } from './RejectRemarks';
import { API, graphqlOperation } from 'aws-amplify';
import { createRoleMutation } from '../../../../_graphql/mutations/systemAdmin/createRole';
import { Validate } from '../../../../validation/validate';
import { IValidation } from '../../../../interfaces/IValidation';
import { CustomInput } from '../../../../components/atoms/Input';

import styled from 'styled-components';
import * as Routes from '../../../../routes';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';

export interface CheckBoxState {
    checked: boolean;
    value: string;
}

export type Privileges = 'branch' | 'hq';

export const AddRoles = (): JSX.Element => {
    const { userLoginContext } = useContext(AuthContext);
    const [roleName, setRoleName] = useState<string>('');
    const [status] = useState<string>('Pending');
    const [roleDescription, setRoleDescription] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [loginAccess, setLoginAccess] = useState<ILoginRoleSection>(
        JSON.parse(JSON.stringify(loginAccessInitialData)),
    );
    const [hqLoginAccess, setHqLoginAccess] = useState<ILoginRoleSection>(
        JSON.parse(JSON.stringify(hqLoginAccessInitialData)),
    );
    const [customerExp, setCustomerExp] = useState<ICustomerExpSection>(
        JSON.parse(JSON.stringify(customerExpInitialData)),
    );
    const [userManagement, setUserManagement] = useState<IUserManagementSection>(
        JSON.parse(JSON.stringify(userManagementInitialData)),
    );
    const [activityLogs, setActivityLogs] = useState<IActivityLogsSection>(
        JSON.parse(JSON.stringify(activityLogsInitialData)),
    );
    const [systemSettings, setSystemSettings] = useState<ISystemSettingsSection>(
        JSON.parse(JSON.stringify(systemSettingsInitialData)),
    );
    const [customerExpDashboard, setCustomerExpDashboard] = useState<ICustomerExperienceSection>(
        JSON.parse(JSON.stringify(customerExperienceInitialData)),
    );
    const [branchOffices, setBranchOffices] = useState<IBranchOfficeSection>(
        JSON.parse(JSON.stringify(branchOfficesInitialData)),
    );
    const [eddCase, setEddCase] = useState<IEDDCasesSection>(JSON.parse(JSON.stringify(eddCaseInitialData)));
    const [financeDashboard, setFinanceDashboard] = useState<IFinanceDashboardSection>(
        JSON.parse(JSON.stringify(financeDashboardInitialData)),
    );
    const [financeCases, setFinanceCases] = useState<IFinanceCasesSection>(
        JSON.parse(JSON.stringify(financeCasesInitialData)),
    );
    const [productSettings, setProductSettings] = useState<IHQRoleProductSettings>(
        JSON.parse(JSON.stringify(productSettingsInitialData)),
    );
    const [fundUploads, setFundUploads] = useState<IHQRoleFundUploads>(
        JSON.parse(JSON.stringify(fundUploadsInitialData)),
    );
    const [operation, setOperation] = useState<IOperationDashboard>(
        JSON.parse(JSON.stringify(operationDashboardInitialData)),
    );
    const [operationCases, setOperationCases] = useState<IOperationCases>(
        JSON.parse(JSON.stringify(operationCasesInitialData)),
    );
    const [advisers, setAdvisers] = useState<IAdviserSection>(JSON.parse(JSON.stringify(adviserInitialData)));
    const [privilege, setPrivilege] = useState<Privileges>('branch');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [rejectRemarks, setRejectRemarks] = useState<boolean>(false);
    const [reports, setReports] = useState<IReportsSection>(JSON.parse(JSON.stringify(reportsInitialData)));
    const [remarks, setRemarks] = useState<string>('');
    const roleNameRef = useRef<HTMLDivElement>(null);
    const history = useHistory();

    const parsedPermission = JSON.parse(userLoginContext.permission);

    const userManagementPermission: IUserManagement = parsedPermission.hq.permission.userManagement;

    const { rolesPermissionTab } = userManagementPermission;
    const { canCreateUserRole } = rolesPermissionTab.actions;
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const handleResetCollapsibleStates = () => {
        setCustomerExp(JSON.parse(JSON.stringify(customerExpInitialData)));
        setUserManagement(JSON.parse(JSON.stringify(userManagementInitialData)));
        setActivityLogs(JSON.parse(JSON.stringify(activityLogsInitialData)));
        setSystemSettings(JSON.parse(JSON.stringify(systemSettingsInitialData)));
        setCustomerExpDashboard(JSON.parse(JSON.stringify(customerExperienceInitialData)));
        setBranchOffices(JSON.parse(JSON.stringify(branchOfficesInitialData)));
        setEddCase(JSON.parse(JSON.stringify(eddCaseInitialData)));
        setFinanceDashboard(JSON.parse(JSON.stringify(financeDashboardInitialData)));
        setFinanceCases(JSON.parse(JSON.stringify(financeCasesInitialData)));
        setProductSettings(JSON.parse(JSON.stringify(productSettingsInitialData)));
        setFundUploads(JSON.parse(JSON.stringify(fundUploadsInitialData)));
        setOperation(JSON.parse(JSON.stringify(operationDashboardInitialData)));
        setOperationCases(JSON.parse(JSON.stringify(operationCasesInitialData)));
        setAdvisers(JSON.parse(JSON.stringify(adviserInitialData)));
        setReports(JSON.parse(JSON.stringify(reportsInitialData)));
    };
    const handleRoleName = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        const att = e.currentTarget.getAttribute('accept');
        let valueMessage = e.currentTarget.getAttribute('itemProp');
        if (valueMessage === null) {
            valueMessage = '';
        }
        const validationSettings = att !== null ? JSON.parse(att) : undefined;

        if (validationSettings !== undefined) {
            const err = Validate(value, validationSettings, valueMessage);
            let errVal = '';
            if (err.code !== 'NoError') {
                errVal = err.message;
            }
            setError(errVal);
            setRoleName(e.target.value);
        }
    };

    const handleCancel = () => {
        history.push(Routes.dashboardSystemAdmin, { tab: 'Roles & Permissions' });
    };

    const handleSave = async () => {
        let branchData = {};
        if (loginAccess.isAll === true) {
            branchData = { ...branchData, accountManagement: loginAccess };
        }
        if (customerExp.isAll === true) {
            branchData = { ...branchData, ceTransaction: customerExp };
        }
        if (advisers.isAll === true) {
            branchData = { ...branchData, adviser: advisers };
        }
        let hqData = {};
        if (hqLoginAccess.isAll === true) {
            hqData = { ...hqData, accountManagement: hqLoginAccess };
        }
        if (customerExpDashboard.isAll === true) {
            hqData = { ...hqData, ceDashboard: customerExpDashboard };
        }
        if (userManagement.isAll === true) {
            hqData = { ...hqData, userManagement };
        }
        if (activityLogs.isAll === true) {
            hqData = { ...hqData, activityLogs };
        }
        if (systemSettings.isAll === true) {
            hqData = { ...hqData, systemSettings };
        }
        if (eddCase.isAll === true) {
            hqData = { ...hqData, eddCase };
        }
        if (branchOffices.isAll === true) {
            hqData = { ...hqData, branchOffices };
        }
        if (financeDashboard.isAll === true) {
            hqData = { ...hqData, financeDashboard };
        }
        if (productSettings.isAll === true) {
            hqData = { ...hqData, productSettings };
        }
        if (fundUploads.isAll === true) {
            hqData = { ...hqData, uploads: fundUploads };
        }
        if (operation.isAll === true) {
            hqData = { ...hqData, operation };
        }
        if (operationCases.isAll === true) {
            hqData = { ...hqData, operationCases };
        }
        if (reports.isAll === true) {
            if (privilege === 'branch') {
                reports.operationalReport.actions.pop();
                branchData = { ...branchData, reports };
            } else hqData = { ...hqData, reports };
        }
        const data = privilege === 'branch' ? branchData : hqData;

        const dataToSend = submitData(data, privilege);
        try {
            const response: any = await API.graphql(
                graphqlOperation(createRoleMutation, {
                    input: {
                        roleName: roleName,
                        description: roleDescription,
                        grant: privilege,
                        permission: dataToSend,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = response.data.createRole;

            if (data !== null) {
                setShowModal(true);
            } else throw error;
        } catch (error) {
            const _error = error as IErrorHandling;
            if (_error.errorCode === 'BO476') {
                setError(_error.message.toString());
                window.scrollTo(0, roleNameRef.current?.offsetTop ?? 0);
            } else {
                if (Object.keys(_error).includes('errorCode')) {
                    setErrorMessage({
                        ...errorMessage,
                        message: _error.message,
                        errorCode: _error.errorCode,
                        title: 'Cannot Create Roles and Permissions',
                        testId: 'add-roles-error-modal',
                    });
                    handleErrorHandler();
                } else {
                    setErrorMessage({
                        ...errorMessage,
                        message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                        title: 'Something Went Wrong',
                        testId: 'gql-error-modal',
                    });
                    handleErrorHandler();
                }
            }
        }
    };

    const handleRoleDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setRoleDescription(event.target.value);
    };

    const handleBranchPrivilege = () => {
        if (privilege !== 'branch') {
            setPrivilege('branch');
            handleResetCollapsibleStates();
        }
    };

    const handleHqPrivilege = () => {
        if (privilege !== 'hq') {
            setPrivilege('hq');
            handleResetCollapsibleStates();
        }
    };

    const handleBack = () => {
        const data = { tab: 'Roles & Permissions' };
        history.push(Routes.dashboardSystemAdmin, data);
    };

    const branchProps = {
        approval: false,
        loginAccess,
        setLoginAccess,
        advisers,
        setAdvisers,
        customerExp,
        setCustomerExp,
        reports,
        setReports,
    };
    const hqProps = {
        approval: false,
        hqLoginAccess,
        setHqLoginAccess,
        userManagement,
        setUserManagement,
        activityLogs,
        setActivityLogs,
        systemSettings,
        setSystemSettings,
        customerExpDashboard,
        setCustomerExpDashboard,
        branchOffices,
        setBranchOffices,
        eddCase,
        setEddCase,
        financeDashboard,
        setFinanceDashboard,
        financeCases,
        setFinanceCases,
        productSettings,
        setProductSettings,
        fundUploads,
        setFundUploads,
        operationDashBoard: operation,
        setOperationDashBoard: setOperation,
        operationCases,
        setOperationCases,
        reports,
        setReports,
    };

    const disabled = roleName === '' || status === '' || error !== '';

    const modalIcon = canCreateUserRole === 'auto-authorizer' ? 'user-modal-success' : 'user-request-pending';
    const modalText =
        canCreateUserRole === 'auto-authorizer'
            ? SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_CREATED
            : SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_ROLE_AND_PERMISSION_REQUEST;
    const modalTextRemarks = rejectRemarks === true ? SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_REQUEST_REJECTED : modalText;
    const approvedModalSub = `${SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_CREATED_SUB}`;
    const modalSubText =
        canCreateUserRole === 'auto-authorizer' ? approvedModalSub : SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_ROLE_REQUEST_SUB;
    const modalRoleName = rejectRemarks === true || modalIcon === 'user-modal-success' ? `${roleName} ` : null;
    const modalSubTextRemarks =
        rejectRemarks === true ? SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_ROLE_REQUEST_REJECTED_SUB_UPDATED : modalSubText;
    const header = SYSTEM_ADMIN.ADD_ROLE.LABEL_ADD_ROLE;
    const subHeader1 = SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_SUBTITLE_1;
    const subHeader2 = SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_SUBTITLE_2;

    return (
        <Container>
            {rejectRemarks === false ? (
                <Fragment>
                    <div>
                        <PreviousPage
                            backIcon={true}
                            title={header}
                            handleBackFunction={handleBack}
                            subTitle={subHeader1}
                            secondSubtitle={subHeader2}
                        />
                        <ContentWrapperWithTitle title={SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_DETAILS}>
                            <FlexedDiv direction="row" margin="auto">
                                <FlexedDiv direction="column" style={{ marginLeft: 'auto' }}>
                                    <TextDarkGrey6
                                        size="12px"
                                        weight="700"
                                        ref={roleNameRef}
                                        style={{ lineHeight: '15.6px' }}
                                    >
                                        {SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_NAME}
                                    </TextDarkGrey6>
                                    <CustomInput
                                        style={{ width: '450px' }}
                                        value={roleName}
                                        onChange={(e) => handleRoleName(e)}
                                        onLostFocus={(e) => handleRoleName(e)}
                                        validation={JSON.stringify({
                                            type: 'alphanumeric',
                                            minChars: 1,
                                            minLength: 3,
                                            maxLength: 30,
                                            required: true,
                                        } as IValidation)}
                                        validationMessage="Role Name"
                                        errorText={error}
                                        canClearContents
                                        handleClearContents={() => setRoleName('')}
                                    />
                                </FlexedDiv>
                                <CustomSpacer horizontal={true} space={'18rem'} />
                                <FlexedDiv
                                    direction="column"
                                    style={{ opacity: 0.5, pointerEvents: 'none', marginRight: 'auto' }}
                                >
                                    <TextDarkGrey6 size="12px" weight="700" style={{ lineHeight: '1.5rem' }}>
                                        {SYSTEM_ADMIN.ADD_ROLE.LABEL_STATUS}
                                    </TextDarkGrey6>
                                    <div style={{ width: '450px' }}>
                                        <DropdownBase>
                                            <BasicInputDropdown disabled value={status} />
                                            <IcoMoon name="caret-down" size={'1.5rem'} />
                                        </DropdownBase>
                                    </div>
                                </FlexedDiv>
                            </FlexedDiv>
                            <CustomSpacer space={'2.5rem'} />
                            <FlexedDiv direction="row" margin="auto">
                                <FlexedDiv direction="column" margin="auto" flex={1} alignItems="center">
                                    <TextAreaWrapper>
                                        <TextArea
                                            handleInput={handleRoleDescription}
                                            label={SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLE_DESCRIPTION}
                                            maxLength={255}
                                            value={roleDescription}
                                        />
                                    </TextAreaWrapper>
                                </FlexedDiv>
                            </FlexedDiv>
                        </ContentWrapperWithTitle>

                        <ContentWrapperWithTitle title={SYSTEM_ADMIN.ADD_ROLE.LABEL_GRANT_PRIVILEGES}>
                            <FlexedDiv direction="row" margin="auto" style={{ maxWidth: '1188px' }}>
                                <TextDarkGrey6 size="12px" weight="700" style={{ lineHeight: '1.5rem' }}>
                                    {SYSTEM_ADMIN.ADD_ROLE.LABEL_GRANT_PRIVILEGES}
                                </TextDarkGrey6>
                            </FlexedDiv>
                            <FlexedDiv direction="row" margin="auto" style={{ maxWidth: '1188px' }}>
                                <RadioButton
                                    label={SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_BACKOFFICE}
                                    selected={privilege === 'branch'}
                                    setSelected={handleBranchPrivilege}
                                />
                                <CustomSpacer horizontal space={'12rem'} />
                                <RadioButton
                                    label={SYSTEM_ADMIN.ADD_ROLE.LABEL_HQ_BACKOFFICE}
                                    selected={privilege === 'hq'}
                                    setSelected={handleHqPrivilege}
                                />
                            </FlexedDiv>
                        </ContentWrapperWithTitle>

                        <ContentWrapperWithTitle
                            title={
                                privilege === 'branch'
                                    ? SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_PRIVILEGES
                                    : SYSTEM_ADMIN.ADD_ROLE.LABEL_HQ_PRIVILEGES
                            }
                        >
                            {privilege === 'branch' ? (
                                <Fragment>
                                    <BranchPrivileges {...branchProps} />
                                </Fragment>
                            ) : null}
                            {privilege === 'hq' ? (
                                <Fragment>
                                    <HqPrivileges {...hqProps} />
                                </Fragment>
                            ) : null}
                        </ContentWrapperWithTitle>
                        <Fragment>
                            <CustomSpacer space={'3.5rem'} />
                            <FlexedDiv>
                                <CustomButton primary={false} onClick={handleCancel} style={{ width: '16.67vw' }}>
                                    <TextDarkGrey6 size="15px" weight="700">
                                        {SYSTEM_ADMIN.BUTTON_CANCEL}
                                    </TextDarkGrey6>
                                </CustomButton>
                                <CustomSpacer horizontal={true} space={'1rem'} />
                                <CustomButton
                                    disabled={disabled}
                                    primary={true}
                                    onClick={handleSave}
                                    style={{ width: '16.67vw', padding: '14px 0' }}
                                >
                                    <TextWhite size="15px" weight="700">
                                        {SYSTEM_ADMIN.BUTTON_CREATE} Role
                                    </TextWhite>
                                </CustomButton>
                            </FlexedDiv>
                        </Fragment>
                        <CustomSpacer space={'1.5rem'} />
                    </div>
                </Fragment>
            ) : (
                <RejectRemarks
                    remarks={remarks}
                    setRemarks={setRemarks}
                    setShowModal={setShowModal}
                    showModal={showModal}
                />
            )}
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalTextRemarks}
                    primaryBtn={{
                        onClick: () => {
                            history.push(Routes.dashboardSystemAdmin, { tab: 'Roles & Permissions' });
                        },
                        label: 'Done',
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="add-roles-modal"
                    icon={modalIcon}
                >
                    <FlexedDiv justifyContent="center" style={{ textAlign: 'center' }}>
                        <Text>{modalRoleName}</Text>&nbsp;
                        {modalSubTextRemarks}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    overflow: hidden;
    position: relative;
`;

export const BasicInputDropdown = styled.input.attrs({ type: 'input' })`
    outline: none;
    border: 0;
    height: 1.5rem;
    //width: 19.25rem;
    width: 450px;
`;

export const BasicTextArea = styled.textarea`
    height: 7.5rem;
    border-radius: 8px;
    width: 22.5rem;
    border: 1px solid #c9c9cd;
    padding-left: 16px;
    padding-right: 16px;
    outline: 0;
    &:focus {
        box-shadow: 0 0 3px red;
    }
`;
const TextAreaWrapper = styled.div`
    max-width: 1188px;
    width: 100%;
`;

const Text = styled.div`
    font-weight: 700;
`;
export default AddRoles;
