import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import FlexSpacer, { Avatar, FlexedDiv } from '../../../components';
import { DataWithLabel, Collapsible } from '../../../components/molecules';
import { LABEL, SYSTEM_ADMIN } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { MultiSection } from '../../../templates';
import { getInitials } from '../../../utils';

export interface IAdviserProfileDetails {
    name: string;
    status: string;
    code: string;
    nric: string;
    details: Array<IAdviserProfileSection>;
}
export interface IAdviserProfileSection {
    title: string;
    sections: ISection[];
}

export interface AdviserProfileProps {
    profile: IAdviserProfileDetails;
}

export const Profile: React.FC<AdviserProfileProps> = ({ profile }: AdviserProfileProps) => {
    const [adviserProfile, setAdviserProfile] = useState<IAdviserProfileDetails>(profile);
    const [keys, setKeys] = useState<string | string[]>(['0']);
    const [expandAll, setExpandAll] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean[]>([true, false, false]);

    const handleCollapseAllText = (): string[] => {
        const text = [];
        keys.length === adviserProfile.details.length
            ? text.push(LABEL.collapseAll, LABEL.collapse)
            : text.push(LABEL.expandAll, LABEL.expand);
        return text;
    };
    const collapseText = handleCollapseAllText();

    const handleExpand = () => {
        setExpandAll(!expandAll);
        let tempArray = [];
        let tempExpanded = [...expanded];
        if (keys.length !== adviserProfile.details.length) {
            for (let i = 0; i < adviserProfile.details.length; i++) {
                tempArray.push(`${i}`);
                tempExpanded[i] = true;
            }
        } else {
            tempArray = [];
            tempExpanded = [false, false, false];
        }
        setKeys(tempArray);
        setExpanded(tempExpanded);
    };
    const handleCollapsible = (index: number) => {
        const keyIndex = index.toString();
        let tempKeys = [...keys];
        const tempExpandedArray = [...expanded];
        if (keys.includes(keyIndex)) {
            tempKeys = tempKeys.filter((ele) => {
                return ele !== keyIndex;
            });
            tempExpandedArray[index] = false;
        } else {
            tempKeys.push(keyIndex);
            tempExpandedArray[index] = true;
        }
        setKeys(tempKeys);
        setExpanded(tempExpandedArray);
    };
    useEffect(() => {
        setAdviserProfile(profile);
    }, [profile]);

    return (
        <Fragment>
            <ProfileContainer id="adviser-profile">
                <FlexedDiv alignItems="flex-start">
                    <Avatar
                        size={120}
                        customStyles={{
                            color: '#fff',
                            fontWeight: 700,
                            fontSize: '2.5rem',
                        }}
                        backgroundColor="#a85846"
                    >
                        {getInitials(adviserProfile.name)}
                    </Avatar>
                    <FlexedDiv direction="column" style={{ marginLeft: '2.5rem', width: '16.667vw' }}>
                        <DataWithLabel
                            direction="column"
                            label={SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_ADVISER_NAME_CODE}
                            data={adviserProfile.name}
                            subText={adviserProfile.code}
                        />
                        <DataWithLabel
                            direction="column"
                            label={SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_NRIC}
                            data={adviserProfile.nric}
                        />
                    </FlexedDiv>
                    <FlexSpacer grow={2} />
                    <FlexedDiv
                        direction="column"
                        grow={1}
                        alignItems="flex-end"
                        justifyContent="space-between"
                        style={{ height: 'inherit' }}
                    >
                        <div style={{ height: '6.5rem' }} />
                        <FlexedDiv style={{ cursor: 'pointer', alignItems: 'center' }} onClick={() => handleExpand()}>
                            <CollapseLabel>{collapseText[0]}</CollapseLabel>
                            <IcoMoon name={collapseText[1]} size="20px" />
                        </FlexedDiv>
                    </FlexedDiv>
                </FlexedDiv>
            </ProfileContainer>

            {adviserProfile
                ? adviserProfile.details.map((elm, index) => {
                      return (
                          <Fragment key={index}>
                              <Collapsible
                                  title={elm.title}
                                  isCheckable={false}
                                  expanded={expanded[index]}
                                  content={<MultiSection sections={elm.sections} />}
                                  handleExpand={() => handleCollapsible(index)}
                                  testId={`${
                                      elm.title && elm.title.toLocaleLowerCase().replace(/,/g, '').replace(/\s/g, '-')
                                  }-accordion`}
                                  data-testid={`${
                                      elm.title && elm.title.toLocaleLowerCase().replace(/,/g, '').replace(/\s/g, '-')
                                  }-accordion`}
                              />
                          </Fragment>
                      );
                  })
                : 'null'}
        </Fragment>
    );
};

const ProfileContainer = styled.div`
    margin: 2rem;
    height: 7.5rem;
    /* background-color: red; */
`;

const CollapseLabel = styled.span`
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    font-weight: 600;
    margin-right: 5px;
`;
export const ContentContainer = styled.div`
    padding: 1.5rem;
`;
