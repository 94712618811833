/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { UserGroupColumnItem, StaffNameColumnItem } from '../../../../templates';

const CustomItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const renderItems = (key: string) => {
        switch (key) {
            case 'name':
                return <StaffNameColumnItem isSortedColumn={isSortedColumn} data={data} itemKey={itemKey} />;
            case 'userGroup':
                return <UserGroupColumnItem isSortedColumn={isSortedColumn} data={data} itemKey={itemKey} />;
        }
    };
    return <Fragment>{renderItems(itemKey[0].key)}</Fragment>;
};
export default CustomItem;
