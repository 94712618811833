import React, { Fragment, useContext } from 'react';
import { EMPTY_STATES, TextDarkBlack } from '../../../constants';
import { AdvanceTable } from '../../../components/organisms/AdvancedTable';
import CustomItem from './CustomItem';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';
import { useUpcomingHandler } from './useUpcomingHandler';

export interface UpcomingProps {
    filters: ISort_FilterInput;
    submittedOnDropdown: string;
    isFilterApplied?: boolean;
    isSearchResult?: boolean;
    searchInput: string;
    upcomingTab: IUpcomingTab;
    setErrorHandler: () => void;
    setErrorMessage: (error: IErrorHandling) => void;
    setFilters: (data: ISort_FilterInput) => void;
    setSubmittedOnDropdown: (value: string) => void;
    showResultCount?: boolean;
}

export const Upcoming: React.FC<UpcomingProps> = ({
    upcomingTab,
    filters,
    submittedOnDropdown,
    isFilterApplied,
    isSearchResult,
    searchInput,
    setFilters,
    setSubmittedOnDropdown,
    showResultCount,
}: UpcomingProps) => {
    const { columnsUpcoming, upcomingGrouped } = useUpcomingHandler({
        filters,
        setFilters,
        setSubmittedOnDropdown,
        submittedOnDropdown,
        upcomingTab,
    });
    const { sortData } = useContext(DashboardBranchContext);
    return (
        <Fragment>
            {showResultCount && searchInput ? (
                <TextDarkBlack
                    size="16px"
                    weight="700"
                    style={{ margin: '24px' }}
                >{`${upcomingGrouped.current.length} result(s) found for '${searchInput}'`}</TextDarkBlack>
            ) : null}
            <AdvanceTable
                data={upcomingGrouped.current}
                columns={columnsUpcoming}
                onEmptyState={
                    isFilterApplied ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateTableUpcoming
                }
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortData[0].column} {...props} />}
                groupedRow
                isSearchResult={isSearchResult}
                searchInput={searchInput}
                testId="branchupcoming"
            />
        </Fragment>
    );
};

export default Upcoming;
