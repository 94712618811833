import React from 'react';

export const getIdsByValue = (formStateOptions: React.ReactText[], result: ReasonsResult[]): string[] => {
    const _temp: string[] = [];
    formStateOptions.forEach((item) => {
        result.forEach((resultItem) => {
            const _id = resultItem.subCategory.find((arr) => arr[1].toLowerCase() === item.toString().toLowerCase());

            _id && _temp.push(_id[0]);
        });
    });
    return _temp;
};
// export const getIdsDoc = (formStateOptions: React.ReactText[], result: RerouteDocumentsElements[]): string[] => {
//     const _temp: string[] = [];
//     formStateOptions.forEach((item) => {
//         result.forEach((resultItem) => {
//             const _id = resultItem.id;

//             _id && _temp.push(_id[0]);
//         });
//     });
//     return _temp;
// };

export const getDocumentsPayload = (formStateOptions: CheckedDocs[]): DocList[] => {
    const _temp: DocList[] = [];

    formStateOptions.forEach((option) => {
        const _doc: DocList = { category: '', subCategory: [] };
        const _arr = [];

        if (_temp.find((item) => item.category === option.category)) {
            // update exisiting DocList obj in _temp arrary
            _arr.push(option.subCategory);
            option.values.forEach((value) => _arr.push(value.value));
            const _existingObj = _temp.find((item) => item.category === option.category);
            _existingObj?.subCategory.push(_arr);
        } else {
            // push as a new DocList obj to _temp arrary
            _doc.category = option.category;
            _arr.push(option.subCategory);
            option.values.forEach((value) => _arr.push(value.value));
            _doc.subCategory.push(_arr);
            _temp.push(_doc);
        }
    });

    return _temp;
};

export const getDisabledStatus = (formState: RerouteFormState, transactionType?: string): boolean => {
    let _disabled = true;
    const isDynamic = transactionType && ['switch', 'redeem'].includes(transactionType.toLowerCase());
    const docsSelected = isDynamic ? formState.reroutedDocs.length !== 0 : true;
    const _toggleArray: boolean[] = [];
    formState.sectionToggle
        .filter((item) => {
            return item.checked == true;
        })
        .map((item) => {
            switch (item.id) {
                case 'document':
                    Array.isArray(formState.document) && formState.document.length !== 0 && docsSelected
                        ? _toggleArray.push(false)
                        : _toggleArray.push(true);
                    break;

                case 'payment':
                    Array.isArray(formState.payment) && formState.payment.length !== 0
                        ? _toggleArray.push(false)
                        : _toggleArray.push(true);
                    break;

                case 'remarks':
                    /\S/.test(formState.remarks) && formState.remarks.length !== 0
                        ? _toggleArray.push(false)
                        : _toggleArray.push(true);
                    break;
                default:
                    _disabled = true;
            }
        });
    const ifChecked = formState.sectionToggle
        .filter((item) => {
            return item.checked == true;
        })
        .some((item) => {
            return item.checked == true ? (_disabled = false) : (_disabled = true);
        });
    const newCond = formState.sectionToggle.length !== 0 ? ifChecked : (_disabled = true);
    _toggleArray.filter(Boolean).length !== 0 ? (_disabled = true) : newCond;

    return _disabled;
};

// Fn to update form state for review
export const updateFormState = (
    data: ReasonsResult[],
    formState: RerouteFormState,
    docs: RerouteDocumentsElements[],
): RerouteFormState => {
    const _tempFormState: RerouteFormState = { ...formState };
    data.forEach((item) => {
        switch (item.category?.toLowerCase()) {
            case 'payment':
            case 'document':
                const _requestOptions = item.subCategory?.map((option) => option[1]) as React.ReactText[];
                item.category?.toLowerCase() === 'payment'
                    ? (_tempFormState.payment = _requestOptions)
                    : (_tempFormState.document = _requestOptions);
                _tempFormState.sectionToggle.push({
                    id: item.category?.toLowerCase(),
                    checked: _requestOptions ? true : false,
                });
                break;
            case 'others':
                _tempFormState.remarks = (item.subCategory && item.subCategory[0][1]) ?? '';
                _tempFormState.sectionToggle.push({ id: 'remarks', checked: _tempFormState.remarks.length !== 0 });
                break;
        }
    });
    docs.forEach((item) => {
        if (item.reRoutedDoc) _tempFormState.reroutedDocs.push(item.id);
    });

    return _tempFormState;
};
