/** @PublicRoute */
export const signIn = '/';
export const signInHome = '/home/signin';
export const passwordRecovery = '/login/password-recovery';
export const resetPassword = '/reset-password';
export const signinSSO = '/sso';
export const callBackRedirect = '/sso/:id_token/:refresh_token/:user';

/** @BranchDashboardRoutes */
export const branchContainer = '/branch/container/dashboard';
export const branch = '/branch';
export const dashboardBranch = '/branch/dashboard';
export const orderSummary = 'order-summary';
export const orderSummaryChangeRequest = 'order-summary-cr';
export const investmentDetails = '/branch/dashboard/order-details/investment-details';
export const rejectTransaction = 'reject-transaction';
export const rerouteTransaction = 'reroute-transaction';
export const extendTargetDate = 'extend-target-date';
export const rejectTargetDate = 'reject-target-date';
export const extendTargetDateRequest = 'extend-target-date-request';
export const advisers = '/branch/advisers';
export const branchAdvisorProfile = '/branch/advisers/profile';
export const profile = '/branch/profile';
export const branchInbox = '/branch/inbox';
export const branchErrorCode404 = '/branch/404-error-code-page';
export const branchErrorCode503 = '/branch/503-error-code-page';
export const branchInboxOrderSummary = '/branch/inbox/order-summary';
export const branchInboxOrderSummaryChangeRequest = '/branch/inbox/order-summary-cr';
export const branchInboxRejectReview = '/branch/inbox/reject-transaction';
export const branchInboxReRouteReview = '/branch/inbox/reroute-transaction';
export const branchTrackOrder = '/branch/dashboard/track-order';
export const branchPendingSubmission = '/branch/dashboard/pending-submission';
export const branchInvestors = '/branch/investors';
export const branchAccountProfile = '/branch/investors/account-profile';
export const branchInvestorProfile = '/branch/investors/investor-profile';
export const branchPendingSubmissionTrackOrder = '/branch/dashboard/pending-submission/track-order';
export const branchReports = '/branch/reports';
export const branchGenerateReports = '/branch/reports/generate-reports';

/** @HQDashboardRoutes */
export const hqContainer = '/hq/container/dashboard';
export const hq = '/hq';

/** * @dashboardLinks */
export const dashboard = '/hq/dashboard';
export const hqTargetDateExtension = '/hq/target-date-extension';
export const hqRejectExtension = '/hq/reject-extension';
export const branches = '/hq/branch-offices';
export const viewBranch = '/hq/dashboard/view-branch';

export const hqOrderSummary = '/hq/view-branch/order-summary';
export const hqRejectTransaction = '/hq/view-branch/reject-transaction';
export const hqReRouteTransaction = '/hq/dashboard/view-branch/reroute-transaction';
export const hqTrackOrder = '/hq/dashboard/track-order';
export const hqSingleBranchTrackOrder = '/hq/dashboard/view-branch/track-order';
export const hqPendingSubmission = '/hq/dashboard/view-branch/pending-submission';
export const hqPendingSubmissionTrackOrder = '/hq/dashboard/view-branch/pending-submission/track-order';
export const hqAllSubmissions = '/hq/all-branches';
export const hqAllSubmissionsTrackOrder = '/hq/all-branches/track-order';
export const hqAllPendingSubmission = '/hq/all-branches/all-pending-submission';
export const hqAllPendingSubmissionTrackOrder = '/hq/all-branches/all-pending-submission/track-order';
/** * @branchOfficeLinks */
export const branchOffices = '/hq/branch-offices';
export const hqAdvisers = '/hq/advisers';
export const hqAdviserProfile = '/hq/advisers/adviser-profile';
export const hqInvestors = '/hq/investors';
export const hqAccountProfile = '/hq/investors/account-profile';
export const hqInvestorProfile = '/hq/investors/investor-profile';

export const hqInbox = '/hq/inbox';
export const hqErrorCode404 = '/hq/404-error-code-page';
export const hqErrorCode503 = '/hq/503-error-code-page';
export const hqInboxOrderSummary = '/hq/inbox/order-summary';
export const hqInboxRejectReview = '/hq/inbox/reject-transaction';
export const hqInboxReRouteReview = '/hq/inbox/reroute-transaction';
export const hqOpsCases = '/hq/op-cases';
export const hqProfile = '/hq/profile';
export const hqReports = '/hq/reports';
export const hqGenerateReports = '/hq/reports/generate-reports';

/** @SystemAdminDashboardRoutes */
export const activityLogs = '/hq/system-admin/activity-logs';
export const activityLogsFund = '/hq/system-admin/activity-logs/fund-import';
export const activityLogsRoles = '/hq/system-admin/activity-logs/roles';
export const activityLogsGroups = '/hq/system-admin/activity-logs/groups';
export const activityLogsUser = '/hq/system-admin/activity-logs/user';
export const activityLogsReroute = '/hq/system-admin/activity-logs/reroute';
export const activityLogsDueDate = '/hq/system-admin/activity-logs/dueDate';
export const activityLogsOrderSummary = '/hq/system-admin/activity-logs/order-summary';
export const activityLogsReject = '/hq/system-admin/activity-logs/reject';
export const activityLogsAdviser = '/hq/system-admin/activity-logs/adviser';
export const activityLogsBulkUpload = '/hq/system-admin/activity-logs/bulk-upload';
export const activityLogsBulkImport = '/hq/system-admin/activity-logs/bulk-import';
export const activityLogsEpfSettings = '/hq/system-admin/activity-logs/configuration/epf';
export const addRoles = '/hq/system-admin/dashboard/add-roles';
export const addUser = '/hq/system-admin/dashboard/add-user';
export const addUserGroup = '/hq/system-admin/dashboard/add-user-group';
export const branchManagement = '/hq/system-admin/staff-management/branch';
export const dashboardSystemAdmin = '/hq/system-admin/dashboard';
export const adviserAccess = '/hq/system-admin/adviser-access';
export const approveRoles = '/hq/system-admin/dashboard/approve-roles';
export const editRoles = '/hq/system-admin/dashboard/edit-roles';
export const approveEditRole = '/hq/system-admin/dashboard/edit-roles/approve';
export const roleSettings = '/hq/system-admin/dashboard/roles-settings';
export const systemAdmin = '/hq/system-admin';
export const systemAdminAdviserView = '/hq/system-admin/adviser-access/adviser';
export const staffManagement = '/hq/system-admin/staff-management';
export const systemAdminInbox = '/hq/system-admin/inbox';
export const systemAdminErrorCode404 = '/hq/system-admin/404-error-code-page';
export const systemAdminErrorCode503 = '/hq/system-admin/503-error-code-page';
export const systemAdminErrorCodeApp = '/hq/system-admin/application-error-code-page';
export const systemAdminProfile = '/hq/system-admin/profile';
export const systemConfiguration = '/hq/system-admin/configuration';
export const editEPFConfiguration = '/hq/system-admin/configuration/edit-epf';
export const systemSettingRemark = '/hq/system-admin/configuration/remark';
export const support = '/hq/system-admin/help-&-support';
export const editUserGroup = '/hq/system-admin/dashboard/edit-user-group';
export const bulkImportUsers = '/hq/system-admin/dashboard/bulk-import-users';
export const viewImportUsers = '/hq/system-admin/dashboard/bulk-import-users/view-import';
export const reviewBulkImportUsers = '/hq/system-admin/dashboard/bulk-import-users/review-import';
export const rejectBulkImportUsers = '/hq/system-admin/dashboard/bulk-import-users/reject-import';

/** @AMLADashboardRoutes */
export const amlaContainer = '/amla/container/dashboard';
export const amla = '/amla';
export const amlaDashboard = '/hq/amla/dashboard';
export const amlaEDDManagement = '/hq/amla/edd-management';
export const openCaseEDD = '/hq/amla/edd-management/open-new-case';
export const investorsList = '/hq/amla/edd-management/investors-list';
// export const investorList = '/hq/amla/edd-management/investor-list';
export const satisfactory = 'satisfactory-case';
export const cancelledSatisfactory = 'cancelled-satisfactory-case';

export const reroutedSatisfactory = 'rerouted/satisfactory-case';
export const newCaseForm = '/hq/amla/edd-management/new-case-details-form';
export const questionTemplate = '/hq/amla/edd-management/new-case-question-template';
export const caseDetails = '/hq/amla/edd-management/case-details';
export const satisfactoryCase = '/hq/amla/edd-management/satisfactory-case';
export const unsatisfactoryCase = '/hq/amla/edd-management/unsatisfactory-case';
export const rerouteEddCase = '/hq/amla/edd-management/reroute-case';
// export const reroutedEddCase = '/hq/amla/edd-management/rerouted-case';
export const reroutedEddCaseSatisfactory = '/hq/amla/edd-management/rerouted/satisfactory-case';
export const reroutedEddCaseUnsatisfactory = '/hq/amla/edd-management/rerouted/unsatisfactory-case';
export const rerouteEddCaseUnsatisfactory = '/hq/amla/edd-management/reroute-case-unsatisfactory';

export const cancelRequest = '/hq/amla/edd-management/cancel-request';
export const cancelSatisfactory = '/hq/amla/edd-management/cancel-satisfactory-case';
export const cancelledSatisfactoryCase = '/hq/amla/edd-management/cancelled-satisfactory-case';
export const downloadMonthlyReport = '/hq/amla/edd-management/download-monthly-report';

/** @FundDataRoutes */
export const fundData = '/fund-data';
/** @FinanceRoutes */
export const finance = '/finance';
export const financeContainer = '/finance/container';
export const financesDashboard = '/hq/finance-dashboard';
export const financeCases = '/finance/cases';
export const financeInbox = '/finance/inbox';
export const financeErrorCode404 = '/finance/404-error-code-page';
export const financeErrorCode503 = '/finance/503-error-code-page';
export const financeProfile = '/finance/profile';
export const financeProductSetingsContainer = '/finance/product-settings/container';
export const financeProductSetingsDashboard = '/hq/product-settings';
export const financeProductSetingsDetails = '/hq/product-settings/fund-details';
export const financeUploads = '/hq/uploads';
export const financeNavDetials = '/hq/product-settings/nav-details';
export const financeImportFileUploader = '/hq/uploads/import';
export const financeImportBulkUploader = '/hq/uploads/bulkUploader/';
export const financeViewImportFile = '/hq/uploads/import/view-import-file';
export const financeReviewImportFile = '/hq/uploads/import/review-import-file';
export const financeRejectImport = '/hq/uploads/import/reject-remarks';
export const financeViewBulkUpload = '/hq/uploads/import/view-bulkuploads';
export const financeReviewBulkUpload = '/hq/uploads/import/review-bulkuploads';
/** @SuperAdminDashboardRoute */
export const dashboardSuperAdmin = '/super-admin/dashboard';
