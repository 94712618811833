import React, { Fragment, useContext } from 'react';
import * as ROUTES from '../../../routes';
import { useHistory, useLocation } from 'react-router-dom';
// Context
import AdvisersContext from '../../../context/AdviserContext/AdvisersContext';
import AuthContext from '../../../context/AuthContext';
// Components
import { AdvanceTable } from '../../../components/organisms';
import { DateColumnItem } from '../../../templates';
import { CreatedOn } from '../CreatedOn';
import { TableGroupingTemplates } from '../../../components/atoms/TableGroupingTemplates/TableGroupingTemplates';
// Type & utils
import { EMPTY_STATES, LABEL, TextDarkBlack } from '../../../constants';
import { getGroupingFromData, getGroupStyling, statusStyle } from '../../../utils';
// Styles
import { subTitleStyle } from '../../../styles';
import { checkIfFiltersHaveValues, getCreatedOnFilter } from '../adviserUtils';

export interface IAdvisersTabProps {
    adviserTab: IAdviserTab | IAdviserPermission;
    hq: boolean;
    testId?: string;
    showResultCount?: boolean;
}

export interface IIconStyle {
    name: string;
    keyName: string;
}

export const AdvisersTab: React.FC<IAdvisersTabProps> = ({ hq, testId, showResultCount }: IAdvisersTabProps) => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { adviserData, filters, sortData, adviserDataGrouped, searchInput, setSortData, adviserCount } =
        useContext(AdvisersContext);

    //Route state
    const history = useHistory();
    const location = useLocation();
    //Permission
    let viewDetails = false;
    const parsedPermission = JSON.parse(userLoginContext.permission);

    const handleViewDetails = (item: IColumnItemAccordion) => {
        if (location.pathname === ROUTES.advisers)
            history.push({ pathname: ROUTES.branchAdvisorProfile, state: { details: item, hq: false } });
        else history.push({ pathname: ROUTES.hqAdviserProfile, state: { details: item, hq: true } });
    };

    const handleSort = (keyName: string) => {
        const tempSort = [...sortData];
        sortData.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortData.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };

    const handleLastUpdatedFilter = (item: string, value: string) => {
        let currentFilter = 'lastUpdated';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
        }
        setSortData([{ column: currentFilter, value: value }]);
    };

    const handleCreatedOn = (menu: IHeaderMenu) => {
        const _createdOnColumn = getCreatedOnFilter(filters, 'column');
        const _createdOnValue = getCreatedOnFilter(filters, 'value');

        return (
            <CreatedOn
                {...menu}
                dropdownData={[_createdOnValue, sortData[0].column === _createdOnColumn ? sortData[0].value : '']}
                setData={handleLastUpdatedFilter}
            />
        );
    };

    viewDetails =
        hq === true
            ? parsedPermission.hq.permission.branchOffices.advisersTab.actions.canViewAdvisersProfile === 'maker'
            : parsedPermission.branch.permission.adviser.advisersTab.actions.canViewAdvisersProfile === 'maker';

    const columns: ITableColumn[] = [
        {
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: '16.67vw',
            },
            onPressHeader: () => handleSort('agentName'),
            title: LABEL.advisorName,
            subtitle: LABEL.code,
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
                style: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 },
            },
        },
        {
            key: [
                { key: 'bdmName', textStyle: { fontWeight: sortData[0].column === 'bdmName' ? 700 : 400 } },
                { key: 'bdmCode', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: '16.1vw',
            },
            titleStyle: {
                fontWeight: sortData[0].column === 'bdmName' ? 700 : 400,
            },
            title: LABEL.bdmName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('bdmName'),
            icon: {
                name: handleSortIcon('bdmName'),
                size: '1rem',
            },
        },
        {
            key: [
                { key: 'uplineName', textStyle: { fontWeight: sortData[0].column === 'uplineCode' ? 700 : 400 } },
                { key: 'uplineCode', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: '16.1vw',
            },
            title: LABEL.uplineName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('uplineCode'),
            titleStyle: {
                fontWeight: sortData[0].column === 'uplineCode' ? 700 : 400,
            },
            icon: {
                name: handleSortIcon('uplineCode'),
                size: '1rem',
            },
        },
        {
            customItem: true,
            key: [{ key: getCreatedOnFilter(sortData, 'column') }],
            viewStyle: {
                width: '12vw',
            },
            title: getCreatedOnFilter(sortData, 'value'),
            icon: {
                name: 'caret-down',
                size: '1rem',
            },
            RenderHeaderMenu: (props: IHeaderMenu) => handleCreatedOn(props),
        },
        {
            key: [{ key: 'status', textStyle: { fontWeight: sortData[0].column === 'status' ? 700 : 400 } }],
            viewStyle: {
                width: '9.652vw',
            },
            title: LABEL.status,
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
            titleStyle: {
                fontWeight: sortData[0].column === 'status' ? 700 : 400,
            },
            onPressHeader: () => handleSort('status'),
        },
        {
            key: [],
            viewStyle: {
                width: '4.44vw',
                padding: 0,
                justifyContent: 'center',
            },
            itemIcon: viewDetails
                ? {
                      name: 'eye-show',
                      size: '1.5rem',
                  }
                : undefined,
            title: LABEL.view,
            onClickItem: viewDetails ? (item: IColumnItemAccordion) => handleViewDetails(item) : undefined,
            testId: 'adviser-view-column',
        },
    ];

    return (
        <Fragment>
            {hq && showResultCount && searchInput.value && (
                <TextDarkBlack size="16px" weight="700" style={{ margin: '24px' }}>
                    {`${adviserCount} result(s) found for '${searchInput.value}'`}
                </TextDarkBlack>
            )}
            {hq ? (
                <AdvanceTable
                    data={getGroupingFromData(adviserDataGrouped, TableGroupingTemplates, getGroupStyling)}
                    groupedRow={true}
                    columns={columns}
                    onEmptyState={EMPTY_STATES.emptyStateAdviserList}
                    RenderItem={(props: ITableCustomItem) => (
                        <DateColumnItem isSortedColumn={sortData[0].column} {...props} />
                    )}
                    isSearchResult={
                        (checkIfFiltersHaveValues(filters) || searchInput.value !== '') &&
                        adviserDataGrouped.length === 0
                            ? true
                            : false
                    }
                    searchInput={searchInput.value}
                    testId={testId}
                />
            ) : (
                showResultCount &&
                searchInput.value && (
                    <TextDarkBlack size="16px" weight="700" style={{ margin: '24px' }}>
                        {`${adviserData.length} result(s) found for '${searchInput.value}'`}
                    </TextDarkBlack>
                )
            )}
            {!hq && (
                <AdvanceTable
                    data={adviserData}
                    columns={columns}
                    onEmptyState={EMPTY_STATES.emptyStateAdviserList}
                    RenderItem={(props: ITableCustomItem) => (
                        <DateColumnItem isSortedColumn={sortData[0].column} {...props} />
                    )}
                    isSearchResult={
                        (checkIfFiltersHaveValues(filters) || searchInput.value !== '') &&
                        adviserDataGrouped.length === 0
                            ? true
                            : false
                    }
                    searchInput={searchInput.value}
                    testId={testId}
                />
            )}
        </Fragment>
    );
};

export default AdvisersTab;
