import IcomoonReact from 'icomoon-react';
import React, { Fragment, useState } from 'react';

import FlexSpacer from '../../../components';
import { Tabs } from '../../../components/organisms/Tabs/Tabs';
import { LABEL } from '../../../constants';
import { IcoMoonSelection } from '../../../icons';

export const BranchInfo = (): JSX.Element => {
    const [tab, setTab] = useState(1);
    return (
        <Fragment>
            <Tabs title="Branch" handleBack="/">
                <div className="tabs">
                    <div className={tab === 1 ? 'tabs_option_selected' : 'tabs_options'} onClick={() => setTab(1)}>
                        {LABEL.managers}
                        {'(12)'}
                    </div>
                    <div className={tab === 2 ? 'tabs_option_selected' : 'tabs_options'} onClick={() => setTab(2)}>
                        {LABEL.agent}
                        {'(50)'}
                    </div>
                    <div className={tab === 3 ? 'tabs_option_selected' : 'tabs_options'} onClick={() => setTab(3)}>
                        {LABEL.clients}
                        {'(100)'}
                    </div>

                    <FlexSpacer />
                    <div className="card_buttons_label">Showing 10 out of 673 results</div>
                    <div className="card_buttons_back">
                        <IcomoonReact iconSet={IcoMoonSelection} icon="caret-left" size="1.5rem" />
                    </div>
                    <div className="card_buttons_next">
                        <IcomoonReact iconSet={IcoMoonSelection} icon="caret-right" size="1.5rem" />
                    </div>
                </div>
            </Tabs>
        </Fragment>
    );
};

export default BranchInfo;
