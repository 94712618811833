/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { RegulartTooltipTemplate, Tooltip } from '../../../components';
import { IcoMoon } from '../../../icons';
import { DateColumnItem } from '../../../templates';

const CustomItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const Remarks = () => {
        const tooltipItems: Array<ITooltip> = [];
        data.remarks !== null
            ? data.remarks.length !== 0
                ? data.remarks.map((item: { title: string; content: string[] }) => {
                      const tempObject = {
                          label: '',
                          content: [''],
                      };

                      tempObject.label = item.title;
                      tempObject.content = item.content;
                      tooltipItems.push(tempObject);
                  })
                : null
            : null;
        return (
            <Fragment>
                {tooltipItems.length !== 0 ? (
                    <Tooltip tooTipContent={<RegulartTooltipTemplate message={tooltipItems} />}>
                        <div>
                            <IcoMoon name="warning" color="#E89700" size="1.667vw" />
                        </div>
                    </Tooltip>
                ) : (
                    <div>-</div>
                )}
            </Fragment>
        );
    };
    const renderItems = (key: string) => {
        switch (key) {
            case 'remarks':
                return <Remarks />;
            case 'createdOn':
            case 'date':
                return <DateColumnItem data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />;
        }
    };
    return <Fragment>{renderItems(itemKey[0].key)}</Fragment>;
};
export default CustomItem;
