import { ReactText } from 'react';

/**
 *Function returns a filter object of desired column type by removing existing data present for same column type and replace with the new one.
 * @param filterArray Array to be filtered
 * @param column The column name
 * @param data data to be pushed
 */
export const tempFilterFunctions = (
    filterArray: ISort_FilterInput,
    column: string,
    data: ReactText[],
): ISort_FilterInput => {
    const newFilter = filterArray.filter((item) => item.column !== column);
    data.map((item) => {
        newFilter.push({ column: column, value: item as string });
    });

    return newFilter;
};
