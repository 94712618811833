import React, { Fragment, useEffect, useState } from 'react';
import { TextDarkBlack } from '../../../../constants';

import { MultiSection } from '../../../../templates';
// import { IAccountSummary } from '../../orderSummaryTypes';
import styled from 'styled-components';

interface IAccountSummaryProps {
    account: IAccountSummary;
}
type BlockContainerProps = {
    isLast: boolean;
};

/**
 * Account tab in order summary page
 * @category Pages
 * @component
 * @namespace AccountTabOrderSummary
 * @returns Account tab in order summary page.
 */

const AccountDetailsTab: React.FC<IAccountSummaryProps> = ({ account }: IAccountSummaryProps) => {
    const [transactionSections, setTransactionSections] = useState<Array<IProfileSection>>([]);
    useEffect(() => {
        const _tempSections: Array<IProfileSection> = [];
        Object.keys(account as IAccountSummary).forEach((key) => {
            switch (key) {
                case 'correspondenceAddress':
                case 'bankInformation':
                case 'settings':
                    if (account[key] !== undefined) {
                        _tempSections.push(account[key] ?? { sections: [] });
                    }
                    break;
            }
        });
        setTransactionSections(_tempSections);
    }, [account]);
    return (
        <Fragment>
            {transactionSections.length !== 0 ? (
                transactionSections.map((elm, index) => {
                    return (
                        <BlockContainer
                            key={`container${index + 1}`}
                            id={`${elm.title?.toLocaleLowerCase()}-container`}
                            isLast={index === transactionSections.length - 1}
                        >
                            <BlockContainerHeader>
                                <TextDarkBlack weight="700" size="18px">
                                    {elm.title}
                                </TextDarkBlack>
                            </BlockContainerHeader>
                            <BlockContainerContent>
                                <MultiSection sections={elm.sections as ISection[]} />
                            </BlockContainerContent>
                        </BlockContainer>
                    );
                })
            ) : (
                <div>Data not available</div>
            )}
        </Fragment>
    );
};

const BlockContainer = styled.div<BlockContainerProps>`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px #0020430f;
    border-radius: 8px;
    margin-bottom: ${({ isLast }) => (isLast ? '0' : '1rem')};
`;
const BlockContainerHeader = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ececec;
    padding: 0.75rem 1.5rem;
    border-radius: 8px 8px 0 0;
`;
const BlockContainerContent = styled.div`
    padding: 1rem 1.5rem 1.5rem;
`;

export default AccountDetailsTab;
