/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Dashboard,
    SearchBar,
    CustomButton,
    CustomSpacer,
    ComponentLoader,
    SelectDropdown,
    DatePicker,
    Modal,
    FlexedDiv,
} from '../../components';
import { LABEL, SearchColumnsUploads } from '../../constants';
import { MasterFund, NAVPerUnit, Distribution, Documents } from '.';
import { API, graphqlOperation } from 'aws-amplify';
import {
    fundsExportList,
    bulkDocumentsExportList,
} from '../../_graphql/queries/fundData/productSettingsDashbaord/fundDocumentDashboardExportList';

import { clearContextOnNavigation, exportListUploadsDashboard, updateIsSeenDashboard } from '../../utils';
import { DocumentNode } from 'graphql';
import { selectedTabUploads } from './selectedTabUploads';
import AuthContext from '../../context/AuthContext';
import UploadsContext from '../../context/UploadsContext';
import { SearchBarFilterTags } from '../../templates/SearchBarFilterTags';
import styled from 'styled-components';
import * as Routes from '../../routes';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import moment from 'moment';
import { toTitleCase } from '../../utils';

/**
 * Uploads Dashboard for Fund Management
 * @category Pages
 * @component
 * @namespace Upload
 * @returns Uploads dashboard component
 */
const UploadsDashboard: React.FC = () => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const {
        tab,
        currentPage,
        maxPages,
        dropDownFilters,
        searchInput,
        masterFundData,
        navPerUnitData,
        distributionData,
        documentsData,
        initialRender,
        loading,
        setTab,
        setCurrentPage,
        setSearchInput,
        getAllMasterFundData,
        getAllNAVPerUnitData,
        getAllDistributionData,
        getAllDocumentsData,
        disableResultLimit,
        debouncedSearchTerm,
        tempState,
        dispatch,
        resultLimit,
        setResultLimit,
    } = useContext(UploadsContext);
    const history = useHistory();

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { uploads } = parsedPermission.hq.permission;
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    // states for dashboard UI components
    const [expand, setExpand] = useState<boolean>(false);
    const [showFundDataModal, setShowFundDataModal] = useState(true);
    const [showBulkDocModal, setShowBulkDocModal] = useState(true);
    const [documentCount, setDocumentCount] = useState<number>(0);
    const [modalHeading, setModalHeading] = useState<string>('');
    const [modalSubHeading, setModalSubHeading] = useState<string>('');
    const [targetRange, setTargetRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
    //Tab permission states

    const [tabPrimaryButtons, setTabPrimaryButtons] = useState<IActionButton>();
    const [tabSecondaryButtons, setTabSecondaryButtons] = useState<IActionButton[]>();
    // datePicker error messages state
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>('');

    //additional states for filter tags
    const [showAllTags, setShowAllTags] = useState<boolean>(false); //FilterTags expansion Panel Visibility State

    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const isSettingFilter = useRef<boolean>(false);

    const tabs: IDashboardTabs[] = [];

    const checkTabSecondaryButtons = (tabIndex: number): IActionButton[] => {
        const _tempSecondary: IActionButton[] = [];

        switch (tabs[tabIndex].dashboardId) {
            case 'masterfund':
                if (
                    uploads.masterFundTab.actions.canExportList === 'maker' ||
                    uploads.masterFundTab.actions.canExportList === 'checker' ||
                    uploads.masterFundTab.actions.canExportList === 'auto-authorizer'
                ) {
                    _tempSecondary.push({
                        type: 'secondary',
                        title: 'Export List',
                        actionIcon: 'export',
                        testId: 'uploads-masterfund-exportlist-btn',
                    });
                }
                break;
            case 'navperunit':
                if (
                    uploads.navPerUnitTab.actions.canExportList === 'maker' ||
                    uploads.navPerUnitTab.actions.canExportList === 'checker' ||
                    uploads.navPerUnitTab.actions.canExportList === 'auto-authorizer'
                ) {
                    _tempSecondary.push({
                        type: 'secondary',
                        title: 'Export List',
                        actionIcon: 'export',
                        testId: 'uploads-nav-exportlist-btn',
                    });
                }
                break;
            case 'distribution':
                if (
                    uploads.distributionTab.actions.canExportList === 'maker' ||
                    uploads.distributionTab.actions.canExportList === 'checker' ||
                    uploads.distributionTab.actions.canExportList === 'auto-authorizer'
                ) {
                    _tempSecondary.push({
                        type: 'secondary',
                        title: 'Export List',
                        actionIcon: 'export',
                        testId: 'uploads-distribution-exportlist-btn',
                    });
                }
                break;
            case 'documents':
                if (
                    uploads.documentsTab.actions.canExportList === 'maker' ||
                    uploads.documentsTab.actions.canExportList === 'checker' ||
                    uploads.documentsTab.actions.canExportList === 'auto-authorizer'
                ) {
                    _tempSecondary.push({
                        type: 'secondary',
                        title: 'Export List',
                        actionIcon: 'export',
                        testId: 'uploads-documents-exportlist-btn',
                    });
                }
                break;
        }
        return _tempSecondary;
    };
    const checkTabPrimaryBtns = (tabIndex: number): IActionButton | undefined => {
        let _tempPrimary: IActionButton = {
            title: '',
            type: 'primary',
            actionIcon: 'import',
            isDropdown: false,
            testId: '',
        };
        switch (tabs[tabIndex].dashboardId) {
            case 'masterfund':
                if (
                    uploads.masterFundTab.actions.canImportMasterFund === 'maker' ||
                    uploads.masterFundTab.actions.canImportMasterFund === 'auto-authorizer'
                ) {
                    _tempPrimary = {
                        type: 'primary',
                        title: `Import Master Fund`,
                        actionIcon: 'import',
                        isDropdown: false,
                        testId: 'uploads-masterfund-import-btn',
                    };
                    return _tempPrimary;
                }

                break;
            case 'navperunit':
                if (
                    uploads.navPerUnitTab.actions.canImportNav === 'maker' ||
                    uploads.navPerUnitTab.actions.canImportNav === 'auto-authorizer'
                ) {
                    _tempPrimary = {
                        type: 'primary',
                        title: `Import NAV`,
                        actionIcon: 'import',
                        isDropdown: false,
                        testId: 'uploads-nav-import-btn',
                    };
                    return _tempPrimary;
                }
                break;
            case 'distribution':
                if (
                    uploads.distributionTab.actions.canImportDistribution === 'maker' ||
                    uploads.distributionTab.actions.canImportDistribution === 'auto-authorizer'
                ) {
                    _tempPrimary = {
                        type: 'primary',
                        title: `Import Distribution`,
                        actionIcon: 'import',
                        isDropdown: false,
                        testId: 'uploads-distribution-import-btn',
                    };
                    return _tempPrimary;
                }

                break;
            case 'documents':
                if (
                    uploads.documentsTab.actions.canBulkUpload === 'maker' ||
                    uploads.documentsTab.actions.canBulkUpload === 'auto-authorizer'
                ) {
                    _tempPrimary = {
                        type: 'primary',
                        title: `Bulk Upload Documents`,
                        actionIcon: 'import',
                        isDropdown: false,
                        testId: 'uploads-documents-import-btn',
                    };
                    return _tempPrimary;
                }
                break;
        }
    };

    const handleFilterDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const update = [...date] as [moment.Moment | null, moment.Moment | null];
        setTargetRange(update);
    };

    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        isSettingFilter.current = true;
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item, '"M/D/YYYY H:mm"').valueOf();
            time.push(milliSeconds);
        });
        const _validFrom = time[0] < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = time[1] < moment().add(1, 'day').startOf('day').valueOf() && time[1] > time[0];
        if (_validFrom && _validTo) {
            // Date range in milliseconds
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
            const range = time.toString().replace(',', '~');
            dispatch({ type: 'dateRange', payload: range });
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            time[1] < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            time[1] < time[0] && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
            dispatch({ type: 'dateRange', payload: '' });
        }
    };

    const handleInput = (column: string, value: string) => {
        if (value === '') {
            return undefined;
        }
        return { column, value };
    };

    const getData = () => {
        const filterInput = [];
        const dateInput = handleInput('date', tempState.date);
        let dateRangeInput;
        if (dateInput) {
            dateRangeInput = handleInput(tempState.date, tempState.dateRange);
            if (dateRangeInput) filterInput.push(dateRangeInput);
        }

        const statusInput = handleInput('status', tempState.status);
        if (statusInput) filterInput.push(statusInput);

        return filterInput;
    };
    const filterInput = getData();

    // call export list query
    const getExportList = async (tabName: string, query: DocumentNode) => {
        // loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(query, {
                    input: {
                        tab: tabName,
                        search: searchInput,
                        sort: [tempState.sortData],
                        filter: filterInput,
                        endpoint: 'funddocumentdashboard',
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.dashboardExportList;

            if (!error) {
                exportListUploadsDashboard(tabName, data.result.funddocumentdashboard);
                // loadingHandler();
            } else throw error;
        } catch (error) {
            // loadingHandler();
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: _error.errorCode,
                    message: _error.message,
                    title: 'Cannot Generate Export List',
                    testId: 'uploads-dashboard-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    const switchTabs = (index: number) => {
        setTab(index);
        handleFilterClose();
        setCurrentPage(1);
    };

    // get tab index for dashboards
    const getTabIndex = (): number => {
        let _tabIndex = 0;
        if (tab !== undefined) _tabIndex = tabs.indexOf(tabs[tab]);
        return _tabIndex;
    };
    //fn to show fund data custom modal
    const handleShowConfirmation = (heading: string, subHeading: string) => {
        setModalHeading(heading);
        setModalSubHeading(subHeading);
        setShowFundDataModal(!showFundDataModal);
    };
    //fn to close fund data custom modal and update dashboard
    const handleCloseConfirmation = () => {
        setShowFundDataModal(!showFundDataModal);
        switch (tabs[tab].dashboardId) {
            case 'masterfund':
                getAllMasterFundData();
                break;
            case 'navperunit':
                getAllNAVPerUnitData();
                break;
            case 'distribution':
                getAllDistributionData();
                break;
            case 'documents':
                getAllDocumentsData();
                break;
        }
    };
    //fn to show  bulk documents custom modal
    const handleBulkDocumnetsConfirmation = (documentCount: number) => {
        setDocumentCount(documentCount);
        setShowBulkDocModal(!showBulkDocModal);
    };
    //fn to close bulk documents custom modal and update dashboard
    const handleCloseBulkDocumentsConfirmation = () => {
        setShowBulkDocModal(!showBulkDocModal);
        switch (tabs[tab].dashboardId) {
            case 'documents':
                getAllDocumentsData();
                break;
        }
    };
    // set dashbaord tabs according to permissions
    if (uploads.masterFundTab.isAll === 'true') {
        const _masterFundTab = {
            name: 'master fund',
            table: (
                <MasterFund
                    allMasterFundData={masterFundData}
                    permissions={parsedPermission && uploads.masterFundTab.reviewApproval}
                    getTabIndex={getTabIndex}
                    handleDashboardModal={handleShowConfirmation}
                />
            ),
            testId: 'masterfund-tab',
            dashboardId: 'masterfund',
        };
        tabs.push(_masterFundTab);
    }
    if (uploads.navPerUnitTab.isAll === 'true') {
        const _navPerUnitTab = {
            name: 'NAV',
            table: (
                <NAVPerUnit
                    allNAVPerUnit={navPerUnitData}
                    permissions={parsedPermission && uploads.navPerUnitTab.reviewApproval}
                    getTabIndex={getTabIndex}
                    handleDashboardModal={handleShowConfirmation}
                />
            ),
            testId: 'nav-tab',
            dashboardId: 'navperunit',
        };
        tabs.push(_navPerUnitTab);
    }
    if (uploads.distributionTab.isAll === 'true') {
        const _distributionTab = {
            name: 'distribution',
            table: (
                <Distribution
                    allDistributionData={distributionData}
                    permissions={parsedPermission && uploads.distributionTab.reviewApproval}
                    getTabIndex={getTabIndex}
                    handleDashboardModal={handleShowConfirmation}
                />
            ),
            testId: 'distribution-tab',
            dashboardId: 'distribution',
        };
        tabs.push(_distributionTab);
    }
    if (uploads.documentsTab.isAll === 'true') {
        const _documentsTab = {
            name: 'documents',
            table: (
                <Documents
                    allDocumentsData={documentsData}
                    permissions={parsedPermission && uploads.documentsTab.reviewApproval}
                    getTabIndex={getTabIndex}
                    handleDashboardModal={handleBulkDocumnetsConfirmation}
                />
            ),
            testId: 'documents-tab',
            dashboardId: 'documents',
        };
        tabs.push(_documentsTab);
    }

    const handleNext = () => {
        if (currentPage < maxPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleImportFile = () => {
        if (tab !== undefined) {
            history.push({
                pathname:
                    tabs[tab].dashboardId === 'documents'
                        ? Routes.financeImportBulkUploader
                        : Routes.financeImportFileUploader,
                state: {
                    uploadType: tabs[tab].name,
                    tabName: tabs[tab].dashboardId,
                    tabIndex: tabs.indexOf(tabs[tab]),
                },
            });
        }
    };
    const handleExportList = (tab: number) => {
        let _tabName = '';
        let _queryName: DocumentNode | null = null;
        switch (tabs[tab].dashboardId) {
            case 'masterfund':
                _tabName = 'masterfund';
                _queryName = fundsExportList;
                break;
            case 'navperunit':
                _tabName = 'navperunit';
                _queryName = fundsExportList;
                break;
            case 'distribution':
                _tabName = 'distribution';
                _queryName = fundsExportList;
                break;
            case 'documents':
                _tabName = 'documents';
                _queryName = bulkDocumentsExportList;
                break;
            default:
        }

        _queryName && getExportList(_tabName, _queryName);
    };
    const searchInputHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const tempValue = event.currentTarget as HTMLInputElement;
        setSearchInput({ ...searchInput, value: tempValue.value });
    };

    const handleFilterApply = () => {
        initialRender.current = false;
        isSettingFilter.current = false;
        let _searchFilterTags: ISelectValue[] = [];
        _searchFilterTags = getFilterTagLabels(_searchFilterTags, tempState);
        dispatch({ type: 'searchbarFilterTags', payload: _searchFilterTags });
        setExpand(false);
    };

    //reset searchFilterForm elements

    const handleFilterClose = () => {
        initialRender.current = true;
        setTargetRange([null, null]);
        setExpand(false);
        if (tempState.searchbarFilterTags.length !== 0) {
            setCurrentPage(1);
        }
        dispatch({ type: 'reset', payload: [] });
    };

    const handleRemoveFilterTags = (item: ISelectValue) => {
        let tempValues = [...tempState.searchbarFilterTags];

        tempValues = tempValues.filter((filter) => {
            return filter.value.toLowerCase() !== item.value.toLowerCase();
        });
        dispatch({ type: 'searchbarFilterTags', payload: tempValues });

        switch (item.label) {
            case LABEL.status:
                dispatch({ type: 'status', payload: '' });
                break;
            case LABEL.lastUpdated:
            case LABEL.uploadedOn:
                dispatch({ type: 'date', payload: '' });
                setTargetRange([null, null]);
                break;
            default:
                break;
        }
    };

    const getFilterTagLabels = (tags: Array<ISelectValue>, _tempState: IUploadStates): Array<ISelectValue> => {
        const temp = [...tags];
        Object.entries(_tempState).forEach(([key, value]) => {
            const tempObj: ISelectValue = {
                label: '',
                value: '',
            };

            const tagLabel = (label: string, value: string) => {
                if (value !== '') {
                    return { label, value };
                }
                return null;
            };
            switch (key) {
                case 'dateRange':
                    if (value !== '') {
                        tempObj.label = _tempState.date === 'uploadedOn' ? LABEL.uploadedOn : LABEL.lastUpdated;
                        tempObj.value = value
                            .split('~')
                            .map((time: any) => moment(time, 'x').format('DD/MM/YYYY'))
                            .join('-');
                    }
                    break;
                case 'status':
                    const statusTag = tagLabel(LABEL.status, toTitleCase(_tempState.status));
                    if (statusTag) {
                        temp.push(statusTag);
                    }
                    break;
                default:
                    break;
            }
            if (tempObj.label !== '') {
                temp.push(tempObj);
            }
        });

        return temp;
    };

    const handleFilterSelect = (value: TUploadInitState | any, elemName: string) => {
        isSettingFilter.current = true;
        switch (elemName.toLowerCase().toString()) {
            case 'date':
                dispatch({ type: 'date', payload: value });
                break;
            case 'dateRange':
                dispatch({ type: 'dateRange', payload: value });
                break;
            case 'status':
                dispatch({ type: 'status', payload: value });
                break;
        }
    };

    const updateInputValue = (elemId: string) => {
        if (elemId === 'lastUpdate') {
            return tempState.date ? tempState.date.toString() : '';
        } else if (elemId === 'dateRange' && tempState.date) {
            return tempState.dateRange ? tempState.dateRange.toString() : '';
        } else if (elemId === 'status') {
            return tempState.status ? tempState.status.toString() : '';
        }

        return '';
    };

    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        SearchColumnsUploads.masterFunds.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInput({ ...searchInput, column: value }),
                testId: `uploads-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };

    const prevTempStateItem = useRef(tempState);

    const clearContext = () => {
        setSearchInput({ value: '', column: 'all' });
        handleFilterClose();
        setCurrentPage(1);
        setResultLimit(10);
    };

    useEffect(() => {
        initialRender.current = true;
        setSearchInput({ value: '', column: 'all' });
        //set primary and secondary button permissions for each tab
        if (tab !== undefined && isSettingFilter.current === false) {
            switch (tabs[tab].dashboardId) {
                case 'masterfund':
                    // set tab primary and secondary buttons and permissions
                    setTabSecondaryButtons(checkTabSecondaryButtons(tab));
                    setTabPrimaryButtons(checkTabPrimaryBtns(tab));
                    getAllMasterFundData();
                    break;
                case 'navperunit':
                    // set tab primary and secondary buttons and permissions
                    setTabSecondaryButtons(checkTabSecondaryButtons(tab));
                    setTabPrimaryButtons(checkTabPrimaryBtns(tab));
                    getAllNAVPerUnitData();

                    break;
                case 'distribution':
                    // set tab primary and secondary buttons and permissions
                    setTabSecondaryButtons(checkTabSecondaryButtons(tab));
                    setTabPrimaryButtons(checkTabPrimaryBtns(tab));
                    getAllDistributionData();
                    break;
                case 'documents':
                    // set tab primary and secondary buttons and permissions
                    setTabSecondaryButtons(checkTabSecondaryButtons(tab));
                    setTabPrimaryButtons(checkTabPrimaryBtns(tab));
                    getAllDocumentsData();
                    break;
                default:
                    setTabSecondaryButtons(checkTabSecondaryButtons(0));
                    setTabPrimaryButtons(checkTabPrimaryBtns(0));
                    getAllMasterFundData();
                    break;
            }
        }
        return function cleanup() {
            tab !== undefined
                ? updateIsSeenDashboard(
                      'fundDocumentDashboard',
                      [selectedTabUploads(tabs[tab].dashboardId as string)],
                      idTokenHeader,
                  )
                : null;
        };
    }, [tab, currentPage, resultLimit]);

    useEffect(() => {
        return function cleanup() {
            clearContextOnNavigation('uploads', history.location.pathname) === false ? clearContext() : null;
        };
    }, []);

    useMemo(() => {
        if (
            tab !== undefined &&
            isSettingFilter.current === false &&
            JSON.stringify(tempState) !== JSON.stringify(prevTempStateItem.current)
        ) {
            switch (tabs[tab].dashboardId) {
                case 'masterfund':
                    getAllMasterFundData();
                    prevTempStateItem.current = tempState;
                    break;
                case 'navperunit':
                    getAllNAVPerUnitData();
                    prevTempStateItem.current = tempState;
                    break;
                case 'distribution':
                    getAllDistributionData();
                    prevTempStateItem.current = tempState;
                    break;
                case 'documents':
                    getAllDocumentsData();
                    prevTempStateItem.current = tempState;
                    break;
                default:
                    getAllMasterFundData();
                    prevTempStateItem.current = tempState;
                    break;
            }
        }
    }, [tempState]);

    useEffect(() => {
        switch (tabs[tab].dashboardId) {
            case 'masterfund':
                getAllMasterFundData();
                prevTempStateItem.current = tempState;
                break;
            case 'navperunit':
                getAllNAVPerUnitData();
                prevTempStateItem.current = tempState;
                break;
            case 'distribution':
                getAllDistributionData();
                prevTempStateItem.current = tempState;
                break;
            case 'documents':
                getAllDocumentsData();
                prevTempStateItem.current = tempState;
                break;
            default:
                getAllMasterFundData();
                prevTempStateItem.current = tempState;
                break;
        }
    }, [debouncedSearchTerm]);

    return (
        <TabWrapper>
            <SearchBar
                expand={expand}
                setExpand={setExpand}
                placeHolder={LABEL.searchPlaceholderUploads}
                searchTitle="Search"
                dropDownOptions={searchOptions()}
                onSearch={searchInputHandler}
                primaryButton={tabPrimaryButtons && tabPrimaryButtons}
                handlePrimary={handleImportFile}
                handleSecondary={tab !== undefined ? () => handleExportList(tab) : () => handleExportList(0)}
                secondaryButton={tabSecondaryButtons && tabSecondaryButtons}
                filterTags={tempState.searchbarFilterTags}
                searchInput={searchInput}
                testId="uploads"
                clearSearchInput={() => {
                    setSearchInput({ ...searchInput, value: '' });
                }}
                filter
            >
                {expand ? (
                    <SearchInner>
                        <SearchInnerTitle>Filter Product & Services by</SearchInnerTitle>
                        {dropDownFilters.map((row: IDropDownFilters[], rowIndex: number) => (
                            <FromRow key={rowIndex}>
                                {row.map((elem: IDropDownFilters, elmIndex) => {
                                    if (elem.elementType === 'select') {
                                        return (
                                            <InputWrap key={elmIndex}>
                                                <SelectDropdown
                                                    onChange={(value) =>
                                                        handleFilterSelect(
                                                            value,
                                                            elem.label === 'Date' ? elem.label : elem.id,
                                                        )
                                                    }
                                                    options={elem.options}
                                                    testId={`${elem.id}-dropdown-filter`}
                                                    selectedValue={updateInputValue(elem.id)}
                                                    placeHolder={elem && elem.placeHolder}
                                                    label={elem.label}
                                                />
                                            </InputWrap>
                                        );
                                    } else if (elem.elementType === 'dateRange') {
                                        return (
                                            <InputWrap style={{ lineHeight: '1rem' }} key={elmIndex}>
                                                <InputLabel>{elem.label}</InputLabel>

                                                <DatePicker
                                                    setTargetDate={(date) => {
                                                        handleFilterDate(
                                                            date as [moment.Moment | null, moment.Moment | null],
                                                        );
                                                    }}
                                                    targetDate={targetRange}
                                                    range={true}
                                                    width="360"
                                                    handleDate={handleDate}
                                                    errorMessage={datePickerInvalidMessage}
                                                    setErrorMessage={setDatePickerInvalidMessage}
                                                    disable={tempState.date.length !== 0 ? false : true}
                                                />
                                            </InputWrap>
                                        );
                                    }
                                })}
                            </FromRow>
                        ))}
                        <FromRow>
                            <ButtonContainer>
                                <CustomButton
                                    onClick={handleFilterClose}
                                    style={{ width: '16.67vw' }}
                                    id="uploads-cancel-btn"
                                >
                                    {LABEL.reset}
                                </CustomButton>
                                <CustomSpacer horizontal={true} space={'1rem'} />
                                <CustomButton
                                    primary={true}
                                    onClick={handleFilterApply}
                                    style={{ width: '16.67vw', padding: '14px 0' }}
                                    id="uploads-apply-btn"
                                >
                                    {LABEL.apply}
                                </CustomButton>
                            </ButtonContainer>
                        </FromRow>
                    </SearchInner>
                ) : (
                    <SearchBarFilterTags
                        tags={tempState.searchbarFilterTags}
                        showAll={showAllTags}
                        handleShowAll={() => setShowAllTags(!showAllTags)}
                        disabled={loading}
                        handleClearAll={handleFilterClose}
                        handleRemoveTag={handleRemoveFilterTags}
                        testId="upload-dashboard-filter-tags"
                    />
                )}
            </SearchBar>
            <Dashboard
                tabs={tabs}
                selectedTab={tab !== undefined ? tab : 0}
                switchTabs={switchTabs}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                setResultLimit={setResultLimit}
                page={currentPage}
                maxPage={maxPages}
                testId="uploads"
                resultLimit={resultLimit}
                setCurrentPage={setCurrentPage}
                disableResultLimit={disableResultLimit}
                adjustTabMargin={tempState.searchbarFilterTags.length !== 0}
                orderCounter={[]}
            />
            {!showBulkDocModal ? (
                <Modal
                    modalActive={!showBulkDocModal}
                    setModalActive={() => setShowBulkDocModal(!showBulkDocModal)}
                    title="Bulk Upload Documents request approved"
                    primaryBtn={{
                        onClick: () => {
                            handleCloseBulkDocumentsConfirmation();
                        },
                        label: 'Okay',
                        primary: true,
                        size: 'large',
                    }}
                    icon="bulk-modal-processing"
                    contentAlignment="center"
                    testId="uploads-dasboard-bulk-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {`${documentCount} fund documents has been updated.`}
                    </FlexedDiv>
                </Modal>
            ) : null}
            {!showFundDataModal ? (
                <Modal
                    modalActive={!showBulkDocModal}
                    setModalActive={() => setShowFundDataModal(!showFundDataModal)}
                    title={`Import ${modalHeading} request approved`}
                    primaryBtn={{
                        onClick: () => {
                            handleCloseConfirmation();
                        },
                        label: 'Okay',
                        primary: true,
                        size: 'large',
                    }}
                    icon="approved-transaction"
                    contentAlignment="center"
                    testId="uploads-dasboard-funddata-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {`${modalSubHeading} been updated and the maker will be notified.`}
                    </FlexedDiv>
                </Modal>
            ) : null}

            {loading ? <ComponentLoader /> : null}
        </TabWrapper>
    );
};

export default UploadsDashboard;

//component styles

const TabWrapper = styled.div`
    position: relative;
`;
const SearchInner = styled.div`
    border-top: 1px solid #eaebee;
    padding: 1rem 0px;
`;
const SearchInnerTitle = styled.h2`
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 1rem;
`;
const FromRow = styled.div`
    display: flex;
    margin: 1rem 0px;
`;
const InputWrap = styled.div`
    width: 100%;
    max-width: 360px;
    margin-right: 1rem;
`;

const InputLabel = styled.label`
    display: block;
    font-weight: 700;
    font-size: 0.8rem;
    color: #333333;
    margin-bottom: 0.4rem;
`;
const ButtonContainer = styled.div`
    display: flex;
    margin: 0 auto;
    padding: 25px 0px;
`;
