import './index.less';
import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import App from './App';
import awsconfig from './aws-exports';
import { COGNITO_CONFIG } from './aws-userpools';
import STORAGE_CONFIG from './aws-storage';
import * as serviceWorker from './serviceWorker';
import packageJson from '../package.json';
import { ErrorBoundaryApp } from './pages/ExceptionHandling/ErrorBoundaryApp';
console.log('Current-Build-Version', packageJson.version);
console.log('Build-Version', process.env.REACT_APP_VERSION);

Amplify.configure({
    ...awsconfig,
    ...COGNITO_CONFIG,
    Storage: {
        AWSS3: {
            ...STORAGE_CONFIG,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorBoundaryApp}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
);

serviceWorker.unregister();
