import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EPFActions } from '../Templates/EPFActions';
import { API, graphqlOperation } from 'aws-amplify';
import { approveRejectSettingsMutation } from '../../../../../_graphql/mutations/systemAdmin/systemSettings/approveRejectSettings';
import { AdvanceTable, FlexedDiv, Modal } from '../../../../../components';
import { CE_DASHBOARD_LABELS, EMPTY_STATES, LABEL } from '../../../../../constants';
import { sh200, sh453 } from '../../../../../styles';

import * as ROUTES from '../../../../../routes';
import AuthContext from '../../../../../context/AuthContext';
import CustomItem from '../Templates/CustomItem';
import ErrorHandlingContext from '../../../../../context/ErrorHandling/ErrorHandlingContext';
import moment from 'moment';
import styled from 'styled-components';

export interface IEPFTabProps {
    epfTabData: ITableData[];
    getTabIndex: () => void;
    handleLoading: () => void;
    handleRefresh: () => void;
}
const modalMessages = {
    confirmation: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
    feedback: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
};
export const EpfTab: React.FC<IEPFTabProps> = ({
    epfTabData,
    getTabIndex,
    handleLoading,
    handleRefresh,
}: IEPFTabProps) => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);

    const { systemSettings } = parsedPermission.hq.permission as IHqPermissions['permission'];
    // For SSO
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const history = useHistory();
    // Data states
    const [requestId, setRequestId] = useState<string>('');
    // Modal and form UI states
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

    //Fn to call mutationn to approve or reject UTMC toggle requests
    const approveRejectMutation = async (requestId: string, action: string) => {
        handleConfirmModal();
        handleLoading();
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(approveRejectSettingsMutation, {
                    input: {
                        tab: 'epf',
                        action: action,
                        remarks: '',
                        requestId: requestId,
                    },
                }),
                idTokenHeader,
            );
            const { result } = await response.data.approveRejectSettings.data;

            if (result.status === true) {
                handleLoading();
                handleFeedbackModal();
                handleRefresh();
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            handleLoading();
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot approve/reject system setting',
                    testId: 'approve-reject-system-setting-error-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    // Fn to route to edit EPF settings page
    const handleEditDetails = (item: IColumnItemAccordion) => {
        history.push({
            pathname: ROUTES.editEPFConfiguration,
            state: {
                tabIndex: getTabIndex(),
                tab: 'epf',
                requestId: item.rawData.requestId,
                status: item.rawData.status,
                settingsId: item.rawData.settingsId,
                currentStatus: item.rawData.currentStatus,
            },
        });
    };

    const epfTabCols: ITableColumn[] = [
        {
            key: [{ key: 'lastUpdated' }],
            viewStyle: {
                width: sh200,
            },
            title: LABEL.lastUpdated,
            customItem: true,
        },
        {
            key: [{ key: 'displayName' }, { key: 'description' }],
            viewStyle: {
                width: sh453,
            },
            title: LABEL.settingsName,
            customItem: true,
        },
        {
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh200,
            },
            title: LABEL.status,
            customItem: true,
        },
        {
            key: [{ key: 'effectiveDate' }],
            viewStyle: {
                width: sh200,

                borderRight: '1px solid #F4F4F4',
            },
            title: LABEL.effectiveDate,
            customItem: true,
        },
    ];
    // fn to approve single/multiple UTMC request
    const handleApproveReject = async () => {
        await approveRejectMutation(requestId, 'approve');
    };
    // Fn to set modal values and states for approval
    const handleApprove = async (item: IColumnItemAccordion) => {
        setRequestId(item.rawData.requestId);
        let _currentStatus: string = item.rawData.currentStatus;
        _currentStatus = _currentStatus.toLocaleLowerCase() === 'disable' ? 'Enable' : 'Disable';
        // set confirmation modal data
        modalMessages.confirmation.heading = (
            <span>{`Approve request to ${_currentStatus.toLowerCase()} multiple UTMC effective ${moment(
                item.rawData.requestEffectiveDate,
                'x',
            ).format('DD/MM/YYYY')}?`}</span>
        ) as unknown as string;

        modalMessages.confirmation.content = (
            <FlexedDiv direction="column">
                <Subtitle>{CE_DASHBOARD_LABELS.DAILY.yesOrNoApprove}</Subtitle>
            </FlexedDiv>
        );
        // set feedback modal data
        modalMessages.feedback.heading = `${_currentStatus} multiple UTMC request approved`;
        modalMessages.feedback.content = (
            <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                <Subtitle>{`${_currentStatus} multiple UTMC request has been approved, maker will be notified.`}</Subtitle>
            </FlexedDiv>
        );
        modalMessages.feedback.icon = 'settings-request-success';
        handleConfirmModal();
    };
    // fn to reject single/multiple UTMC request
    const handleReject = (item: IColumnItemAccordion) => {
        history.push({
            pathname: ROUTES.systemSettingRemark,
            state: {
                tabIndex: getTabIndex(),
                tab: 'epf',
                requestId: item.rawData.requestId,
                currentStatus: item.rawData.currentStatus,
                requestEffectiveDate: moment(item.rawData.requestEffectiveDate, 'x').format('DD/MM/YYYY'),
            },
        });
    };

    // Fn to toggle confirmation modal
    const handleConfirmModal = () => {
        setShowConfirmationModal((prevState) => !prevState);
    };

    // Fn to toggle feedback modal
    const handleFeedbackModal = () => {
        setShowFeedbackModal((prevState) => !prevState);
    };
    return (
        <Fragment>
            <AdvanceTable
                data={epfTabData}
                columns={epfTabCols}
                onEmptyState={EMPTY_STATES.emptyStateAllfund}
                RenderItem={(props: ITableCustomItem) => <CustomItem {...props} />}
                RenderOptions={(props: ITableOptions) => (
                    <EPFActions
                        {...props}
                        handleView={handleEditDetails}
                        handleApprove={handleApprove}
                        handleReject={handleReject}
                        permissions={systemSettings.epfTab}
                    />
                )}
                actionsLabel={LABEL.action}
            />
            {/* Confirmation Modal for approving and rejecting requests */}
            {showConfirmationModal ? (
                <Modal
                    title={modalMessages.confirmation.heading}
                    modalActive={showConfirmationModal}
                    setModalActive={handleConfirmModal}
                    primaryBtn={{
                        onClick: () => handleApproveReject(),
                        label: LABEL.yes,
                        primary: true,
                        width: '212px',
                        size: 'large',
                    }}
                    secondaryBtn={{ onClick: handleConfirmModal, label: LABEL.noNo, width: '212px', size: 'large' }}
                    testId="epf-confirmation"
                >
                    {modalMessages.confirmation.content}
                </Modal>
            ) : null}

            {/* Confirmation Modal for feedback */}
            {showFeedbackModal ? (
                <Modal
                    modalActive={showFeedbackModal}
                    setModalActive={handleFeedbackModal}
                    title={modalMessages.feedback.heading}
                    icon={modalMessages.feedback.icon}
                    primaryBtn={{
                        onClick: () => {
                            handleFeedbackModal();
                        },
                        label: LABEL.done,
                        primary: true,
                        size: 'large',
                    }}
                    testId="epf-feedback"
                    contentAlignment="center"
                >
                    {modalMessages.feedback.content}
                </Modal>
            ) : null}
        </Fragment>
    );
};
const Subtitle = styled.div`
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #333333;
`;
