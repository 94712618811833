/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import { LABEL, EMPTY_STATES, TextDarkBlack } from '../../../constants';
import { AdvanceTable } from '../../../components/organisms/AdvancedTable';
import CustomItem from './CustomItem';
import ReceiveHardCopy from './ReceiveHardCopy';

import { useHardcopyHandler } from './useHardcopyHandler';

interface IWithHardcopyProps {
    handleReceived: () => void;
    hardcopyTab: IHardcopyTab;
    idTokenHeader?: {
        Authorization: string;
        strategy: string;
    };
    isFilterApplied?: boolean;
    submittedOnDropdown: string;
    isSearchResult?: boolean;
    searchInput: string;
    setSortData: (data: ISort_FilterInput) => void;
    sortData: ISort_FilterInput;
    setErrorHandler: () => void;
    setErrorMessage: (error: IErrorHandling) => void;
    setSubmittedOnDropdown: (value: string) => void;
    showResultCount?: boolean;
}

export const WithHardcopy: React.FC<IWithHardcopyProps> = ({
    handleReceived,
    hardcopyTab,
    idTokenHeader,
    isSearchResult,
    isFilterApplied,
    searchInput,
    setSortData,
    sortData,
    setErrorHandler,
    setErrorMessage,
    setSubmittedOnDropdown,
    submittedOnDropdown,
    showResultCount,
}: IWithHardcopyProps) => {
    const { columnsHardcopy, eventClicked, handleReceiveHardcopy, hardcopyGrouped } = useHardcopyHandler({
        handleReceived,
        idTokenHeader,
        sortData,
        setSortData,
        setErrorHandler,
        setErrorMessage,
        hardcopyTab,
        setSubmittedOnDropdown,
        submittedOnDropdown,
    });
    return (
        <Fragment>
            {showResultCount && searchInput ? (
                <TextDarkBlack
                    size="16px"
                    weight="700"
                    style={{ margin: '24px' }}
                >{`${hardcopyGrouped.current.length} result(s) found for '${searchInput}'`}</TextDarkBlack>
            ) : null}
            <AdvanceTable
                actionsLabel={LABEL.receive}
                data={hardcopyGrouped.current}
                groupedRow
                columns={columnsHardcopy}
                onEmptyState={
                    isFilterApplied ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateTableWithHardcopy
                }
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortData[0].column} {...props} />}
                RenderOptions={
                    hardcopyTab.actions.canReceiveHardCopy === LABEL.maker
                        ? (props: ITableOptions) => (
                              <ReceiveHardCopy
                                  {...props}
                                  handleReceiveHardcopy={handleReceiveHardcopy}
                                  hardcopyTab={hardcopyTab}
                                  eventClicked={eventClicked}
                              />
                          )
                        : undefined
                }
                isSearchResult={isSearchResult}
                searchInput={searchInput}
                testId="branchwithhardcopy"
            />
        </Fragment>
    );
};
