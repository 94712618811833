import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FlexedDiv, RegulartTooltipTemplate, Tooltip } from '../../../../components';
import { LABEL } from '../../../../constants';

interface IBranchColumnItem {
    fundCurrency: string;
    investmentAmount: string;
}

export const BranchColumnItem = ({ data, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const branch: string[] = data.branch !== null ? [...data.branch] : ['none...'];

    const tooltipMessage = () => {
        const tempMessage: Array<ITooltip> = [];
        data.branch.map((item: string) => {
            const tempObject: ITooltip = { label: item };
            tempMessage.push(tempObject);
        });
        return tempMessage;
    };

    return (
        <Fragment>
            <FlexedDiv direction="column" justifyContent="center" style={{ padding: '0.5rem' }}>
                {branch.map((item, index) => {
                    return (
                        <Fragment key={`${index}_${data.orderNumber}`}>
                            {index <= 2 ? (
                                <FlexedDiv>
                                    <Title isBold={isSortedColumn === 'branch'}>{item}</Title>
                                </FlexedDiv>
                            ) : null}
                        </Fragment>
                    );
                })}
                {branch.length > 3 ? (
                    <Fragment>
                        <Tooltip tooTipContent={<RegulartTooltipTemplate message={tooltipMessage()} />}>
                            <ShowAll>{LABEL.showAll}</ShowAll>
                        </Tooltip>
                    </Fragment>
                ) : null}
            </FlexedDiv>
        </Fragment>
    );
};

const Title = styled.div<IOnPropStyles>`
    font-size: 0.875rem;
    line-height: 1.2rem;
    letter-spacing: -0.2px;
    color: #002043;
    font-weight: ${(props) => (props.isBold ? 700 : 400)};
`;
const ShowAll = styled.div`
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #0089ec;
`;
