/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PublicRoute } from './helpers';
import { RouteLoader } from './components';
import { AuthProvider } from './context/AuthContext';
import { WebSocketProvider } from './context/WebSocketContext';
import { BranchRoute, CallBackRedirect } from './auth';

import * as ROUTES from './routes';
import { ErrorHandlingProvider } from './context/ErrorHandling/ErrorHandlingProvider';
import Error404 from './pages/ExceptionHandling/Error404';
import { NewHqRoute } from './auth/HqRoute/NewHQRoute';
import { usePreventBackNavigation } from './customHooks/usePreventBackNavigation';

const WebsocketRoutes = lazy<any>(() => import('./auth/WebsocketContainer'));

// SignIn Route
const Signin = lazy<any>(() => import('./pages/Signin'));

// Routes/Pages
function App(): JSX.Element {
    usePreventBackNavigation();
    return (
        <Suspense fallback={<RouteLoader />}>
            <WebSocketProvider>
                <ErrorHandlingProvider>
                    <AuthProvider>
                        <WebsocketRoutes>
                            <Switch>
                                <BranchRoute path="/branch" />
                                <NewHqRoute path={'/hq'} />
                                <PublicRoute path="/sso" component={Signin} />
                                <Route exact path={ROUTES.signIn} component={Signin} />
                                <Route exact path={ROUTES.signInHome} component={Signin} />
                                <Route exact path={ROUTES.callBackRedirect} component={CallBackRedirect} />
                                <Route path="*" component={Error404} />
                            </Switch>
                        </WebsocketRoutes>
                    </AuthProvider>
                </ErrorHandlingProvider>
            </WebSocketProvider>
        </Suspense>
    );
}

export default App;
