import React, { useEffect, useState, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

import { LABEL, MOCK_ADVISER_PROFILE } from '../../constants';
import { Profile } from './Profile';
import { adviserSummaryQuery } from '../../_graphql/queries/hqCE/adviserProfile/Profile';
import { useLocation } from 'react-router-dom';
import { handleRestructureData, IAdviserProfileDetails } from './function';
import { useComponentLoader } from '../../customHooks';
import { ComponentLoader, PreviousPage, Tabs } from '../../components';

import styled from 'styled-components';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';

declare interface PassedDataInside {
    index: number;
    rawData: ITableData;
}

declare interface PassedData {
    details: PassedDataInside;
    hq: boolean;
}

export interface IDropdownWithKeyAndLabel {
    value: string;
    keyName: string;
    label: string;
}

const AdviserProfile: React.FC = () => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const [profile, setProfile] = useState<IAdviserProfileDetails>(MOCK_ADVISER_PROFILE);

    const location: any = useLocation();
    const locationState: PassedData = location.state;
    const data: PassedDataInside = locationState.details;

    //loader
    const { loading, loadingHandler } = useComponentLoader();

    const tabs: Array<JSX.Element | React.FC> = [];
    tabs.push(<Profile profile={profile} key={1} />);

    useEffect(() => {
        const getAdviserData = async () => {
            loadingHandler();
            try {
                const response: any = await API.graphql(
                    graphqlOperation(adviserSummaryQuery, {
                        input: {
                            tab: 'profile',
                            agentId: data.rawData.agentId,
                        },
                    }),
                    idTokenHeader,
                );
                const { result } = await response.data.adviserSummary.data;
                // console.log(result, 'result');
                const restructuredData: IAdviserProfileDetails = handleRestructureData(result.profile);

                setProfile(restructuredData);

                loadingHandler();
            } catch (error) {
                loadingHandler();
                const _error = error as IErrorHandling;
                if (Object.keys(_error).includes('errorCode')) {
                    handleErrorHandler();
                    setErrorMessage({
                        ...errorMessage,
                        message: (error as IErrorHandling).message,
                        errorCode: (error as IErrorHandling).errorCode,
                        title: 'Cannot Fetch Profile Data',
                        testId: 'adviserprofile-modal',
                    });
                } else {
                    setErrorMessage({
                        ...errorMessage,
                        message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                        title: 'Something Went Wrong',
                        testId: 'gql-error-modal',
                    });
                    handleErrorHandler();
                }
            }
        };
        getAdviserData();
    }, []);

    return (
        <TabWrapper>
            <MainWrapper>
                <PreviousPage suffixHelper={data.rawData.status} title={LABEL.adviser} />
                <ProfileWrapper id="adviser-profile-wrapper">
                    <Tabs tabTitles={[LABEL.profile]} tabs={tabs} primary testId="adviser-tabs" />
                </ProfileWrapper>
            </MainWrapper>
            {loading ? <ComponentLoader /> : null}
        </TabWrapper>
    );
};

export default AdviserProfile;
const ProfileWrapper = styled.div`
    background-color: #fbfbfb;
    border-radius: 8px;
    height: inherit;
    margin-bottom: 1.5rem;
`;

const MainWrapper = styled.div`
    border-radius: 24px;
    margin-bottom: 7rem;
`;

const TabWrapper = styled.div`
    position: relative;
`;
