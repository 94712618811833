import { IMultiSelectElements } from '../Investors/dashboardFilterConfig';

export const buildRerouteFormConfig = (
    data: ReasonsResult[],
    transactionType: string,
    rerouteDocs?: RerouteDocumentsElements[],
): IRerouteFormSection[] => {
    const formConfig: IRerouteFormSection[] = [
        {
            id: 'remarks',
            toggle: {
                id: 'remarks',
                label: 'Others',
                elementType: 'toggle',
                placeHolder: '',
                value: '',
            },
            body: [
                {
                    id: 'remarks',
                    label: 'Reason',
                    elementType: 'checkboxWithInput',
                    placeHolder: 'Add your remarks here.',
                    value: '',
                },
            ],
        },
    ];

    data.forEach((item) => {
        switch (item.category.toLowerCase()) {
            case 'payment':
                const _paymentObj: IRerouteFormSection = {
                    id: 'payment',
                    toggle: {
                        id: '',
                        label: 'Payment',
                        elementType: 'toggle',
                        placeHolder: '',
                        value: '',
                    },
                    body: [
                        {
                            id: 'payment',
                            label: 'Reason',
                            elementType: 'multiselect',
                            multiSelectOptions: [],
                            placeHolder: 'Select one or more',
                            value: '',
                        },
                    ],
                };
                // push payment options into multiselect
                if (item.subCategory) {
                    item.subCategory?.forEach((category) => _paymentObj.body[0].multiSelectOptions?.push(category[1]));
                    _paymentObj.toggle.id = item.reasonId ? item.reasonId : 'payment';
                    formConfig.unshift(_paymentObj);
                }

                break;
            case 'physical document':
                const _physicalDoc: IRerouteFormSection = {
                    id: 'physical document',
                    toggle: {
                        id: '',
                        label: 'Physical Document',
                        elementType: 'toggle',
                        placeHolder: '',
                        value: '',
                    },
                    subTitle: 'Select document(s) to be resubmitted to the branch',
                    body: [],
                };
                // push payment options into multiselect
                if (item.subCategory) {
                    item.subCategory?.forEach((category) => {
                        const _checkboxGroup: IRerouteFormElements = {
                            id: '',
                            label: '',
                            elementType: '',
                            placeHolder: '',
                            value: '',
                            groupedElements: { grouptitle: '', elements: [] },
                        };
                        if (_checkboxGroup.groupedElements) _checkboxGroup.groupedElements.grouptitle = category[0];

                        category.forEach((option, index) => {
                            const _checkBox: IMultiSelectElements = {
                                id: '',
                                label: '',
                                elementType: 'checkbox',
                                placeHolder: '',
                                value: '',
                            };
                            if (index !== 0) {
                                _checkBox.id = `${option
                                    .replace(/^[, ]+|[, ]+$|[, ]+/g, '-')
                                    .toLowerCase()}-${category[0].replace(/^[, ]+|[, ]+$|[, ]+/g, '-').toLowerCase()}`;
                                _checkBox.label = option;
                                _checkBox.value = option;
                                _checkboxGroup?.groupedElements?.elements.push(_checkBox);
                            }
                        });

                        _physicalDoc.body.push(_checkboxGroup);
                    });
                    _physicalDoc.toggle.id = item.reasonId ? item.reasonId : 'hardcopy';
                    formConfig.splice(1, 0, _physicalDoc);
                }

                break;
            case 'document':
                if (
                    transactionType.toLowerCase() !== 'sales-ns' &&
                    transactionType.toLowerCase() !== 'sales' &&
                    item.subCategory
                ) {
                    // push document options into multiselect
                    const _document: IRerouteFormSection = {
                        id: 'document',
                        toggle: {
                            id: '',
                            label:
                                transactionType.toLowerCase() === 'switch' || transactionType.toLowerCase() === 'redeem'
                                    ? 'Physical Document'
                                    : 'Document',
                            elementType: 'toggle',
                            placeHolder: '',
                            value: '',
                        },
                        body: [
                            {
                                id: 'document',
                                label: 'Reason',
                                elementType: 'multiselect',
                                multiSelectOptions: [],
                                placeHolder: 'Select one or more',
                                value: '',
                            },
                        ],
                    };
                    ['switch', 'redeem'].includes(transactionType.toLowerCase()) &&
                        _document.body.push({
                            id: 'document',
                            label: '',
                            elementType: 'multiselectcard',
                            placeHolder: '',
                            value: '',
                            subtitle: '',
                            supportingDocs: [],
                        });
                    item.subCategory?.forEach((category) => _document.body[0].multiSelectOptions?.push(category[1]));
                    _document.toggle.id = item.reasonId ? item.reasonId : 'document';
                    if (rerouteDocs && rerouteDocs.length !== 0) {
                        const supportingDocs: IMultiSelectElementSupportingDocs[] = [];
                        const categories: string[] = [];
                        rerouteDocs.forEach((rerouteDoc) => {
                            !categories.includes(rerouteDoc.issuingHouse) && categories.push(rerouteDoc.issuingHouse);
                        });
                        categories.forEach((_category) => {
                            const tempDoc: IMultiSelectElementSupportingDocs = {
                                documentCategory: _category,
                                documents: [],
                            };
                            rerouteDocs.forEach((rerouteDoc) => {
                                _category === rerouteDoc.issuingHouse &&
                                    tempDoc.documents.push({
                                        documentName: rerouteDoc.documentName,
                                        fileName: rerouteDoc.fileName,
                                        id: rerouteDoc.id,
                                    });
                            });
                            supportingDocs.push(tempDoc);
                        });
                        _document.body[1].supportingDocs = [...supportingDocs];
                    }
                    formConfig.length > 1 ? formConfig.splice(1, 0, _document) : formConfig.splice(0, 0, _document);
                }
                break;
        }
    });
    return formConfig;
};
