import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
    FlexedDiv,
    CustomSpacer,
    CustomButton,
    TextArea,
    PreviousPage,
    ContentWrapperWithTitle,
} from '../../components';
import { SYSTEM_ADMIN, LABEL, Fs15BoldPrimaryBlack } from '../../constants';
import { reasonsPageButtons } from '../../styles';

interface IRejectRemarksProps {
    rejectRemarks: string;
    handleRejectRemarks: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleCancel: () => void;
    handleSubmit: () => void;
}
const RejectRemarks = ({
    handleRejectRemarks,
    rejectRemarks,
    handleCancel,
    handleSubmit,
}: IRejectRemarksProps): JSX.Element => {
    return (
        <Fragment>
            <FlexedDiv direction="row">
                <FlexedDiv direction="column" style={{ width: '100%' }} margin="0px">
                    <PreviousPage
                        title={SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_REMARKS}
                        subTitle={SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_REMARKS_SUB}
                    />
                    <RejectRemarksWrap>
                        <ContentWrapperWithTitle title={'Remarks'} marginTop="0px" padding="16px 32px">
                            <TextArea
                                value={rejectRemarks}
                                handleInput={handleRejectRemarks}
                                maxLength={255}
                                style={{ minWidth: '512px', width: '100%', maxWidth: '1028px' }}
                                placeHolder={LABEL.rejectRequestTextAreaPlaceholder}
                            />
                        </ContentWrapperWithTitle>
                    </RejectRemarksWrap>

                    <ButtonDiv>
                        <CustomButton style={reasonsPageButtons} onClick={() => handleCancel()}>
                            <Fs15BoldPrimaryBlack>{LABEL.cancel}</Fs15BoldPrimaryBlack>
                        </CustomButton>
                        <CustomSpacer horizontal={true} space={'1rem'} />
                        <CustomButton
                            disabled={rejectRemarks.trim().length === 0}
                            primary
                            style={reasonsPageButtons}
                            onClick={() => handleSubmit()}
                        >
                            {LABEL.submit}
                        </CustomButton>
                    </ButtonDiv>
                </FlexedDiv>
            </FlexedDiv>
        </Fragment>
    );
};
export default RejectRemarks;

const RejectRemarksWrap = styled.div`
    max-width: 1028px;
    margin-left: 2.5rem;
`;

const ButtonDiv = styled((props) => <FlexedDiv {...props} />)`
    height: 3rem;
    max-width: 25vw;
    margin-top: 3rem;
    margin-bottom: 4.56rem;
    margin-left: 2.5rem;
`;
