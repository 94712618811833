/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import IcomoonReact from 'icomoon-react';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { EDD } from '../../../constants';
import { IEddRespondsProps } from '../../../context/EDDContext/EddTypes';
import { IcoMoon, IcoMoonSelection } from '../../../icons';
import { camelCaseToTitleCase } from '../../../utils';

type AnswerArrayObj = {
    name: string;
    type: string;
    url: string;
};
type AnswerObj = {
    [key: string]: any;
};

declare interface IAnswersProps {
    item: IEddRespondsProps;
    onlyRemarks: boolean;
    status?: string;
}
const ResponseTemplate = ({ item, onlyRemarks, status }: IAnswersProps): JSX.Element => {
    const keyMapping: AnswerObj = {
        'Estimated Value (MYR)': 'Estimated Value',
    };
    const appendCurrency: AnswerObj = {
        'Estimated Value (MYR)': 'MYR',
    };

    return (
        <Fragment>
            <SpaceDivider />
            {!onlyRemarks ? (
                <GridStyle>
                    {item.answers !== '' &&
                        JSON.parse(item.answers).map((answer: AnswerObj, k: number) => {
                            return (
                                <SubCard
                                    key={k}
                                    topSection={answer.answer ? true : false}
                                    bottomSection={Object.keys(answer).length > 1 ? true : false}
                                >
                                    {answer.answer || answer.remark ? (
                                        <SubCardHeader
                                            topSection={answer.remark && item.questionId ? true : false}
                                            bottomSection={
                                                Object.values(answer).filter((n) => n).length > 1 ? true : false
                                            }
                                        >
                                            <Label>{item.questionId ? 'Response' : 'Remark'}</Label>
                                            <Title>
                                                <IcomoonReact
                                                    data-testid="iconset"
                                                    iconSet={IcoMoonSelection}
                                                    icon={'check'}
                                                    size={'15px'}
                                                />
                                                &nbsp;
                                                {item.questionId
                                                    ? typeof answer.answer === 'string'
                                                        ? answer.answer
                                                        : ''
                                                    : typeof answer.remark === 'string'
                                                    ? answer.remark
                                                    : ''}
                                            </Title>
                                        </SubCardHeader>
                                    ) : null}
                                    {Object.keys(answer).map((res, l) => {
                                        if (
                                            res !== 'answer' &&
                                            res !== 'description' &&
                                            answer[res] != null &&
                                            (item.questionId || res === 'document')
                                        ) {
                                            return (
                                                <SubCardBody key={l}>
                                                    <Fragment>
                                                        <Label>
                                                            {!item.questionId && res == 'remark'
                                                                ? null
                                                                : keyMapping[res] || camelCaseToTitleCase(res)}
                                                        </Label>
                                                        {Array.isArray(answer[res])
                                                            ? answer[res].map((value: AnswerArrayObj, h: number) => {
                                                                  return res == 'document' ? (
                                                                      <Fragment>
                                                                          <Title>
                                                                              {answer[res][h].name}
                                                                              &nbsp;
                                                                              <a
                                                                                  href={answer[res][h].url}
                                                                                  rel="noreferrer"
                                                                                  target="_blank"
                                                                                  id="dataIconBtn"
                                                                              >
                                                                                  <IcoMoon
                                                                                      name={'file'}
                                                                                      size="1.125rem"
                                                                                  />
                                                                              </a>
                                                                          </Title>
                                                                      </Fragment>
                                                                  ) : (
                                                                      <Fragment>
                                                                          <Title>
                                                                              &nbsp;
                                                                              {'\u2022'}
                                                                              &nbsp;
                                                                              {value}
                                                                          </Title>
                                                                          <SubTitle>
                                                                              {answer.description
                                                                                  ? answer.description[h]
                                                                                  : null}
                                                                          </SubTitle>
                                                                      </Fragment>
                                                                  );
                                                              })
                                                            : null}
                                                        {item.questionId
                                                            ? !Array.isArray(answer[res]) && (
                                                                  <Title>
                                                                      {appendCurrency[res]
                                                                          ? `${appendCurrency[res]} ${answer[res]}`
                                                                          : answer[res]}
                                                                  </Title>
                                                              )
                                                            : null}
                                                    </Fragment>
                                                </SubCardBody>
                                            );
                                        }
                                    })}
                                </SubCard>
                            );
                        })}
                </GridStyle>
            ) : (
                <GridStyle>
                    {status === 'Pending' ? (
                        <SubCard topSection={true} bottomSection={true}>
                            <SubCardHeader>
                                <Label>{EDD.LABEL_AMLA_REMARKS}</Label>
                                <Title>{item.amlaRemark}</Title>
                            </SubCardHeader>
                        </SubCard>
                    ) : (
                        item.answers !== '' &&
                        JSON.parse(item.answers).map((answer: AnswerObj, k: number) => {
                            return (
                                <SubCard topSection={true} bottomSection={true} key={k}>
                                    <SubCardHeader
                                        topSection={answer.remark ? true : false}
                                        bottomSection={answer.document ? true : false}
                                    >
                                        <Label>{EDD.LABEL_AMLA_REMARKS}</Label>
                                        <Title>{item.amlaRemark}</Title>
                                    </SubCardHeader>
                                    {Object.keys(answer).map((res, l) => {
                                        if (res !== 'answer' && answer[res] != null) {
                                            return (
                                                <SubCardBody key={l}>
                                                    {answer[res].length ? (
                                                        <Fragment>
                                                            <Label>{camelCaseToTitleCase(res)}</Label>
                                                            {Array.isArray(answer[res]) ? (
                                                                res == 'document' ? (
                                                                    <Fragment>
                                                                        <Title>
                                                                            {answer[res][0].name}
                                                                            &nbsp;
                                                                            <a
                                                                                href={answer[res][0].url}
                                                                                rel="noreferrer"
                                                                                target="_blank"
                                                                                id="dataIconBtn"
                                                                            >
                                                                                <IcoMoon
                                                                                    name={'file'}
                                                                                    size="1.125rem"
                                                                                />
                                                                            </a>
                                                                        </Title>
                                                                    </Fragment>
                                                                ) : (
                                                                    <Title>
                                                                        {'\u2022'}
                                                                        &nbsp;
                                                                        {answer[res]}
                                                                    </Title>
                                                                )
                                                            ) : (
                                                                <Title>{answer[res]}</Title>
                                                            )}
                                                        </Fragment>
                                                    ) : null}
                                                </SubCardBody>
                                            );
                                        }
                                    })}
                                </SubCard>
                            );
                        })
                    )}
                </GridStyle>
            )}
        </Fragment>
    );
};

const SpaceDivider = styled.div`
    margin-bottom: 16px;
`;
const GridStyle = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 20px;
    // grid-template-rows: 50% 50%;
    grid-template-columns: 50% 50%;
    padding: 10px 24px;
`;
declare interface SubCardProps {
    topSection?: boolean;
    bottomSection?: boolean;
    isCheckList?: boolean;
}
const SubCard = styled.div<SubCardProps>`
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    // padding: 0px 0px 16px;
    position: static;
    height: fit-content;
    left: 24px;
    top: 0px;
    background: #ffffff;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 32px 0px 32px;
`;

const SubCardHeader = styled.div<SubCardProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    position: static;
    width: 100%;
    height: fit-content;
    left: 0px;
    top: 0px;
    background: #fbfbfb;
    box-shadow: inset 0px -1px 0px #ececec;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: ${(props) => (!props.topSection && !props.bottomSection ? '16px' : '16px 16px 0px 0px')};
`;
const SubCardBody = styled.div`
    padding: 8px 0px 8px 16px;
`;

const Label = styled.div`
    position: static;
    width: 392px;
    height: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    color: #4d4d4d;
    align-self: stretch;
    margin: 4px 0px;
`;
const Title = styled.div`
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    color: #333333;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
`;
const SubTitle = styled.div`
    width: 100%;
    // height: 20px;
    font-style: normal;
    font-size: 16px;
    line-height: 125%;
    color: #333333;
    margin-left: 4%;
`;
export default ResponseTemplate;
