import { API, graphqlOperation } from 'aws-amplify';
// import { IPermissionObject } from '../../utils/permissionTypes';
import { updateSeen } from '../../graphql/mutations';

export const getSource = (permission: IPermissionObject): string[] => {
    let temp = ['ce'];
    if (permission.branch.grant !== undefined) {
        temp = ['ce'];
    } else {
        if (
            permission.hq.permission.userManagement !== undefined &&
            permission.hq.permission.userManagement.isAll === 'true'
        ) {
            temp = ['systemadmin'];
        }
        if (permission.hq.permission.activityLogs !== undefined && permission.hq.permission.activityLogs.isAll) {
            temp = ['systemadmin'];
        }
        if (permission.hq.permission.systemSettings !== undefined && permission.hq.permission.systemSettings.isAll) {
            temp = ['systemadmin'];
        }
        if (permission.hq.permission.productSettings !== undefined && permission.hq.permission.productSettings.isAll) {
            temp = ['finance'];
        }
        if (permission.hq.permission.uploads !== undefined && permission.hq.permission.uploads.isAll) {
            temp = ['finance'];
        }
        if (
            permission.hq.permission.financeDashboard !== undefined &&
            permission.hq.permission.financeDashboard.isAll
        ) {
            temp = ['finance'];
        }
        if (permission.hq.permission.financeCases !== undefined && permission.hq.permission.financeCases.isAll) {
            temp = ['finance'];
        }
        if (permission.hq.permission.eddCase !== undefined && permission.hq.permission.eddCase.isAll) {
            temp = ['amla'];
        }
    }
    return temp;
};
/**@mutation */
export const getUpdateRead = async (
    notificationIds: string,
    idToken:
        | {
              Authorization: string;
              strategy: string;
          }
        | undefined,
): Promise<IGetUpdateReadInbox['updateSeen'] | undefined> => {
    const tmp: IGetUpdateReadInbox['updateSeen'] = {
        data: null,
        error: null,
    };

    try {
        const response: GenericResponse<IGetUpdateReadInbox> = await API.graphql(
            graphqlOperation(updateSeen, {
                input: {
                    dashboard: 'inboxDashboard',
                    tab: ['notification'],
                    referenceKey: notificationIds,
                },
            }),
            idToken,
        );

        const { data, error } = response.data.updateSeen;
        if (data) {
            if (data) {
                if (data.result) {
                    tmp.data = {
                        result: data.result,
                    };
                    return tmp;
                }
            } else {
                throw error;
            }
        }
    } catch (error) {
        tmp.error = error as IErrorHandling;
        return tmp;
    }
};
