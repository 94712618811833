/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { DateColumnItem, FileSizeColumnItem, FileNameColumnItem, RemarksColumnItem } from '../../../templates';
import { statusStyle, toTitleCase } from '../../../utils';
const CustomItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    // let outputDate: JSX.Element;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let outputData: any;
    const generateOutput = () => {
        switch (itemKey[0].key) {
            case 'fileName':
            case 'fileType':
                outputData = <FileNameColumnItem isSortedColumn={isSortedColumn} data={data} itemKey={itemKey} />;
                break;
            case 'fileSize':
                outputData = (
                    <FileSizeColumnItem isSortedColumn={isSortedColumn} data={data} itemKey={itemKey} type="KB" />
                );
                break;
            case 'lastUpdated':
            case 'uploadedOn':
            case 'lastUpdate':
                outputData = <DateColumnItem data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />;
                break;
            case 'remark':
                outputData = <RemarksColumnItem data={data} itemKey={itemKey} />;
                break;
            case 'status':
                outputData = (
                    <div style={statusStyle({ index: 0, rawData: data }, 'status')}>
                        {toTitleCase(data[itemKey[0].key])}
                    </div>
                );
                break;
            default:
                outputData = '';
        }
        return outputData;
    };

    return (
        <Fragment>
            <div className="custom item" data-testid="custom">
                {generateOutput()}
            </div>
        </Fragment>
    );
};
export default CustomItem;
