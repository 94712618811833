/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, Fragment, useEffect, ReactText, ChangeEvent, useContext, useRef } from 'react';
import { SliderOverview, SearchBar, Dashboard, DatePicker, TrackOrderModal } from '../../components/organisms';
import { LABEL, TextPureBlack, Fs12BoldPrimaryBlack, Fs24RegSecNavyBlue, Fs24BoldSecNavyBlue } from '../../constants';
import { API, graphqlOperation } from 'aws-amplify';
import { CustomSpacer, CustomButton, FlexedDiv, ComponentLoader, SelectDropdown } from '../../components';
import { dateSortingDropDown, accountTypes, ProductTypes, FundingOptions } from './filterData';
import { removeArrayElement } from '../../utils';
import { hqceDashboardBranchExportList } from '../../_graphql/queries/hqCE/exportList/hqceDashboard';
import { hqceDashboardExtensionExportListCreatedOn } from '../../_graphql/queries/hqCE/exportList/hqceExtensionCreatedOn';
import { hqceDashboardExtensionExportListLastUpdated } from '../../_graphql/queries/hqCE/exportList/hqceExtensionLastUpdated';
import { SearchOptionsHQCE } from './searchDropdown';
import { useDebounce } from '../../customHooks/useDebounce';
import { useHistory } from 'react-router-dom';
import AllBranch from './AllBranches';
import TargetDateExtension from './TargetDateExtensions';
import moment from 'moment';
import MultiSelect from '../../components/molecules/MultiSelect/MultiSelect';
import styled from 'styled-components';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import HQCEContext from '../../context/HQCEContext/HQCEContext';
import TrackOrderContext from '../../context/TrackOrderContext/TrackOrderContext';
import * as ROUTES from '../../routes';
import { SearchBarFilterTags } from '../../templates';
import * as subscriptions from '../../graphql/subscriptions';

const initialDueDateFilters = [
    { column: 'lastUpdated', value: '' },
    { column: 'transactionType', value: '' },
    { column: 'fundType', value: '' },
    { column: 'paymentMethod', value: '' },
    { column: 'accountType', value: '' },
];

const initialBranchFilter = [
    {
        column: 'state',
        value: '',
    },
];

export interface IDropdownWithKey {
    value: string;
    keyName: string;
}

declare interface ILocationState {
    tab: string;
}

const TrackOrderButton: IActionButton = {
    title: 'Track Order',
    type: 'secondary',
    isDropdown: false,
    actionIcon: 'trackIcon',
    testId: 'track-order',
};

export const DashboardHQ: React.FC = () => {
    const { userLoginContext } = useContext(AuthContext);
    const {
        loading,
        resultLimit,
        currentPage,
        getAllBranchData,
        getDateExtensionData,
        lastUpdatedDropdown,
        maxPages,
        setSortData,
        offices,
        orderCounter,
        overview,
        searchInput,
        setCurrentPage,
        setLastUpdatedDropdown,
        setResultLimit,
        setSearchInput,
        setTab,
        setTargetRange,
        stateList,
        tab,
        targetDate,
        targetDateRange,
        disableResultLimit,
        sortData,
        tempState,
        dispatch,
        initialRender,
    } = useContext(HQCEContext);
    const { trackOrderInput, trackSearchOptions, onTrackOrderSearch, setTrackOrderInput } =
        useContext(TrackOrderContext);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [expand, setExpand] = useState<boolean>(false);
    // const [dateSorting, setDateSorting] = useState<string>('lastUpdated');
    const [showAllTags, setShowAllTags] = useState<boolean>(false); //FilterTags expansion Panel Visibility State
    const [subscription, setSubscription] = useState<ZenObservable.Subscription | null>(null);
    const isSettingFilter = useRef<boolean>(false);

    // datepicker error messages state
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>('');
    // track modal
    const [trackModal, setTrackModal] = useState<boolean>(false);
    const history = useHistory();
    // Debounce
    const debouncedSearchTerm = useDebounce(searchInput, 500);
    const { hq } = JSON.parse(userLoginContext.permission);
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const { permission } = hq;
    const { ceDashboard } = permission;

    const { allBranch, dueDateTab } = ceDashboard;
    const overViewTab = ceDashboard.overview;
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const handleNext = () => {
        if (currentPage < maxPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleCardClick = (tab: number) => {
        if (tab === 1) {
            if (tabs.length > 1) {
                switchTabs(1);
            } else {
                switchTabs(0);
            }
        }
    };

    const handleReset = () => {
        dispatch({ type: 'reset', payload: [] });
    };

    const getFilterTagLabelsHQ = (tags: ISelectValue[], _tempState: IHQCEStates): ISelectValue[] => {
        const temp = [...tags];
        Object.entries(_tempState).forEach(([key, value]) => {
            const tagLabel = (label: string, value: string | string[]) => {
                if (value !== '') {
                    Array.isArray(value)
                        ? value.forEach((_value) => {
                              const tempObj: ISelectValue = {
                                  label: '',
                                  value: '',
                              };
                              tempObj.label = label;
                              if (_value) {
                                  tempObj.value = _value;
                                  temp.push(tempObj);
                              }
                          })
                        : temp.push({ label, value });
                }
                // return { label, value };
            };

            switch (key) {
                case 'dateRange':
                    if (value !== '') {
                        temp.push({
                            label: _tempState.date === 'createdOn' ? LABEL.createdOn : LABEL.lastUpdated,
                            value: value
                                .split('~')
                                .map((time: any) => moment(time, 'x').format('DD/MM/YYYY'))
                                .join('-'),
                        });
                    }
                    break;
                case 'state':
                    tagLabel(LABEL.state, value);
                    break;
                case 'product':
                    tagLabel(LABEL.productTypes, value);
                    break;
                case 'funding':
                    tagLabel(LABEL.fundingOption, value);
                    break;
                case 'account':
                    tagLabel(LABEL.accountTypes, value);
                    break;
                case 'transactionType':
                    tagLabel(LABEL.transTypes, value);
                    break;
                default:
                    break;
            }
        });
        return temp;
    };
    const handleApply = () => {
        initialRender.current = false;
        isSettingFilter.current = false;
        let _searchFilterTags: ISelectValue[] = [];
        _searchFilterTags = getFilterTagLabelsHQ(_searchFilterTags, tempState);
        dispatch({ type: 'searchbarFilterTags', payload: _searchFilterTags });
        setExpand(false);
    };

    const switchTabs = (index: number) => {
        setTab(index);
        setSearchInput({ column: index === 1 ? 'all' : 'name', value: '' });
        setSortData([{ column: index === 1 ? 'clientName' : 'name', value: 'ascending' }]);
        handleReset();
        initialRender.current = false;
    };

    const handleTargetDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const dates = [...date] as [moment.Moment | null, moment.Moment | null];
        setTargetRange(dates);
    };
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        isSettingFilter.current = true;
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item, '"M/D/YYYY H:mm"').valueOf();
            time.push(milliSeconds);
        });
        const _validFrom = time[0] < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = time[1] < moment().add(1, 'day').startOf('day').valueOf() && time[1] > time[0];
        // Date range in milliseconds
        if (_validFrom && _validTo) {
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
            const range = time.toString().replace(',', '~');
            dispatch({ type: 'dateRange', payload: range });
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            time[1] < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            time[1] < time[0] && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
            dispatch({ type: 'dateRange', payload: '' });
        }
    };

    const selectedTab = (tab: string): string => {
        let temp = '';
        switch (tab.toLowerCase()) {
            case 'all branches':
                temp = 'branch';
                break;
            case 'due date extension':
                temp = 'extension';
                break;
        }
        return temp;
    };

    const handleSecondary = () => {
        getExportList();
    };

    //Subscription
    const exportListSubscription = async () => {
        const sub = await (API.graphql(graphqlOperation(subscriptions.onDashboardExportList)) as any).subscribe({
            next: (eventData: ISubscriptionResponse<OnDashboardExportListSubscription>) => {
                const { data } = eventData.value;
                if (data) {
                    if (data.onDashboardExportList?.data) {
                        const { result } = data.onDashboardExportList.data;
                        if (result) {
                            let exportWindow = null;
                            result.url !== null && result.url !== undefined
                                ? (exportWindow = window.open(result.url, '_blank'))
                                : null;
                            exportWindow?.addEventListener('beforeunload', () => {
                                setSubscription(null);
                            });
                        }
                    } else if (
                        data.onDashboardExportList?.error !== null &&
                        data.onDashboardExportList?.error !== undefined
                    ) {
                        const _error = data.onDashboardExportList?.error;
                        if (Object.keys(_error).includes('errorCode')) {
                            handleErrorHandler();
                            setErrorMessage({
                                ...errorMessage,
                                message: _error.message as string,
                                errorCode: _error.errorCode as string,
                                title: 'Cannot Generate Export List',
                                testId: 'hq-dashboard-error-modal',
                            });
                        } else {
                            setErrorMessage({
                                errorCode: '',
                                message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                                title: 'Something Went Wrong',
                                testId: 'gql-error-modal',
                            });
                            handleErrorHandler();
                        }
                    }
                }
            },
            error: (error: any) => {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
                console.warn(error);
            },
        });
        setSubscription(sub);
    };

    const getExportList = async () => {
        const extensionQuery =
            lastUpdatedDropdown.keyName === 'createdOn'
                ? hqceDashboardExtensionExportListCreatedOn
                : hqceDashboardExtensionExportListLastUpdated;
        const query = tabs[tab]?.name === 'all branches' ? hqceDashboardBranchExportList : extensionQuery;
        try {
            const response: any = await API.graphql(
                graphqlOperation(query, {
                    input: {
                        tab: selectedTab(tabs[tab].name),
                        // tab: 'daily',
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        filter: [],
                        endpoint: 'hqcedashboard',
                        backgroundProcess: true,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.dashboardExportList;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            // const { result } = response.data.dashboardExportList.data;
            exportListSubscription();
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Generate Export List',
                    testId: 'hq-dashboard-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    const tabs: IDashboardTabs[] = [];
    if (allBranch.isAll === 'true' || allBranch.actions.canViewAllBranchList === LABEL.maker) {
        tabs.push({
            name: 'all branches',
            table: (
                <AllBranch
                    allBranchData={offices}
                    searchEmpty={
                        JSON.stringify(
                            tabs[tab]?.name === 'Due date extension' ? initialDueDateFilters : initialBranchFilter,
                        ) !== JSON.stringify(tempState)
                    }
                    searchInput={searchInput.value}
                    setSortData={setSortData}
                    sortData={sortData}
                />
            ),
            testId: 'all-branches-tab',
        });
    }
    if (dueDateTab.isAll === 'true' || dueDateTab.actions.canViewDetails === LABEL.maker) {
        tabs.push({
            name: 'Due date extension',
            table: (
                <TargetDateExtension
                    dueDateTab={dueDateTab}
                    filters={[]}
                    lastUpdatedDropdown={lastUpdatedDropdown}
                    searchEmpty={
                        JSON.stringify(
                            tabs[tab]?.name === 'Due date extension' ? initialDueDateFilters : initialBranchFilter,
                        ) !== JSON.stringify(tempState)
                    }
                    searchInput={searchInput.value}
                    setLastUpdatedDropdown={setLastUpdatedDropdown}
                    setShowModal={setShowModal}
                    setSortData={setSortData}
                    showModal={showModal}
                    sortData={sortData}
                    targetDateData={targetDate}
                    setErrorHandler={handleErrorHandler}
                    setErrorMessage={setErrorMessage}
                    idTokenHeader={idTokenHeader}
                />
            ),
            testId: 'due-date-extension-tab',
        });
    }

    const handleRemoveFilter = (item: ISelectValue, index: number) => {
        let tempValues = [...tempState.searchbarFilterTags];
        tempValues = removeArrayElement(tempValues, tempValues[index]);
        dispatch({ type: 'searchbarFilterTags', payload: tempValues });
        switch (item.label) {
            case LABEL.accountTypes:
                dispatch({ type: 'account', payload: '' });
                break;
            case LABEL.productTypes:
                const updatedProduct = tempState.product.filter((product) => product !== item.value);
                dispatch({ type: 'product', payload: updatedProduct });
                break;
            case LABEL.fundingOptions:
                const updatedFunding = tempState.funding.filter((funding) => funding !== item.value);
                dispatch({ type: 'funding', payload: updatedFunding });
                break;
            case LABEL.lastUpdated:
            case LABEL.submittedOn:
            case LABEL.createdOn:
                dispatch({ type: 'dateRange', payload: '' });
                setTargetRange([null, null]);
                break;
            case LABEL.state:
                const updatedStates = tempState.funding.filter((state) => state !== item.value);
                dispatch({ type: 'state', payload: updatedStates });
                break;
        }
    };

    const getTab = () => {
        if (tabs.length > 0) {
            switch (tabs[tab]?.name) {
                case 'all branches':
                    return allBranch;
                    break;
                case 'Due date extension':
                    return dueDateTab;
                    break;
                default:
                    return allBranch;
                    break;
            }
        }
    };
    const currentTab = getTab();
    const secondaryButtons: IActionButton[] = [];
    if (currentTab && currentTab.actions.canExportList === 'maker') {
        secondaryButtons.push({
            type: 'secondary',
            title: 'Export List',
            actionIcon: 'export',
            testId: 'export-list-btn',
            disabled: orderCounter[tab] === 0,
        });
    }
    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        const options =
            tabs.length > 0 && tabs[tab].name === 'all branches' ? SearchOptionsHQCE.branch : SearchOptionsHQCE.dueDate;
        options.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInput({ ...searchInput, column: value }),

                testId: `hq-dashboard-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };

    // opens modal for track order
    const OpenTrackOrderModal = () => {
        setTrackModal(true);
    };

    const trackOrder = () => {
        history.push(ROUTES.hqTrackOrder);
    };

    const closeTrackModal = () => {
        setTrackModal(false);
    };
    const getDashboardData = (tabName: string) => {
        switch (tabName) {
            case 'all branches': {
                getAllBranchData();
                break;
            }
            case 'Due date extension': {
                getDateExtensionData();
                break;
            }
        }
    };

    useEffect(() => {
        if (isSettingFilter.current === false && initialRender.current === false) {
            initialRender.current = true;
        } else {
            getDashboardData(tabs[tab].name);
        }
    }, [tab, debouncedSearchTerm, currentPage, resultLimit, sortData]);

    const prevTempStateItem = useRef(tempState);

    useEffect(() => {
        if (
            initialRender.current === false &&
            isSettingFilter.current === false &&
            JSON.stringify(tempState) !== JSON.stringify(prevTempStateItem.current)
        ) {
            getDashboardData(tabs[tab].name);
            prevTempStateItem.current = tempState;
        }
    }, [tempState]);
    // const sameFilter = JSON.stringify(_initHQCEStates) !== JSON.stringify(tempState);
    // const searchOrFiltered = sameFilter;

    useEffect(() => {
        return () => {
            // Stop receiving data updates from the subscription
            subscription?.unsubscribe();
        };
    }, [subscription]);

    const handleSelectFilters = (type: string, payload: THQInitState | any) => {
        isSettingFilter.current = true;
        switch (type) {
            case 'date':
            case 'state':
            case 'product':
            case 'funding':
            case 'account':
            case 'searchbarFilterTags':
                dispatch({ type: type, payload });
                break;
        }
    };

    return (
        <Fragment>
            <AuthContext.Consumer>
                {({ userLoginContext }) => (
                    <FlexedDiv direction="row">
                        <Fs24RegSecNavyBlue>{LABEL.welcomeBack}</Fs24RegSecNavyBlue>
                        <CustomSpacer horizontal={true} space={'0.25rem'} />
                        <Fs24BoldSecNavyBlue>{userLoginContext.username}</Fs24BoldSecNavyBlue>
                    </FlexedDiv>
                )}
            </AuthContext.Consumer>
            <CustomSpacer space={'1.5rem'} />
            {overViewTab.actions.canViewOverview ? (
                <SliderOverview
                    handleCardClick={handleCardClick}
                    maxVisibleSlides={2}
                    slideMargin={10}
                    overviewData={overview}
                />
            ) : null}
            <FlexedDiv direction="column">
                <SearchBar
                    expand={expand}
                    setExpand={setExpand}
                    placeHolder={
                        tabs.length > 0 && tabs[tab].name === 'all branches'
                            ? LABEL.hqCeAllBranchSearch
                            : LABEL.hqCeDueDateSearch
                    }
                    handleSecondary={handleSecondary}
                    searchInput={searchInput}
                    searchTitle={
                        tabs.length > 0 && tabs[tab].name === 'all branches' ? LABEL.branchTransactions : LABEL.search
                    }
                    onSearch={(event: ChangeEvent<HTMLInputElement>) => {
                        setSearchInput({ ...searchInput, value: event.target.value });
                    }}
                    secondaryButton={secondaryButtons}
                    tertiaryButton={[TrackOrderButton]}
                    handleTertiary={OpenTrackOrderModal}
                    filter={true}
                    dropDownOptions={searchOptions()}
                    data-testid="search-bar"
                    testId="hq-dashboard-searchbar"
                    clearSearchInput={() => {
                        setSearchInput({ ...searchInput, value: '' });
                    }}
                    disableFilterSearchDropDown={searchOptions().length === 1}
                    filterTags={tempState.searchbarFilterTags}
                    showTags={showAllTags}
                    disabled={loading}
                    disableFilters={orderCounter[tab] === 0}
                >
                    {expand ? (
                        <>
                            {/* <div style={{ borderBottom: '1px solid #EAEBEE', margin: '0rem -1.5rem' }} /> */}
                            <TextPureBlack>{LABEL.filterTransactionsBy}</TextPureBlack>
                            <CustomSpacer space={'0.5rem'} />
                            {tabs[tab].name === 'all branches' ? (
                                <div style={{ maxWidth: '30rem' }}>
                                    <MultiSelect
                                        checkedState={tempState.state}
                                        handleMultiSelect={(item: ReactText[]) => handleSelectFilters('state', item)}
                                        label={LABEL.state}
                                        noOverlay={false}
                                        options={stateList !== undefined ? stateList : ['']}
                                        placeHolder={LABEL.select}
                                        testId={`state-dropdown-filter`}
                                    />

                                    <CustomSpacer space={'8vh'} />
                                </div>
                            ) : (
                                <Fragment>
                                    <FlexedDiv direction="row" wrap="wrap">
                                        <FlexedDiv direction="column" style={{ marginRight: '1rem' }}>
                                            <Fs12BoldPrimaryBlack>{LABEL.dateSorting}</Fs12BoldPrimaryBlack>
                                            <SelectWrapper>
                                                <SelectDropdown
                                                    onChange={(item: ReactText) => handleSelectFilters('date', item)}
                                                    options={dateSortingDropDown}
                                                    testId={`date-sorting`}
                                                    selectedValue={tempState.date}
                                                    placeHolder="Select one or more"
                                                />
                                            </SelectWrapper>
                                        </FlexedDiv>
                                        <FlexedDiv direction="column">
                                            <Fs12BoldPrimaryBlack>{LABEL.dateRange}</Fs12BoldPrimaryBlack>
                                            <InputWrap>
                                                <DatePicker
                                                    setTargetDate={handleTargetDate}
                                                    targetDate={targetDateRange}
                                                    range={true}
                                                    width="360"
                                                    handleDate={handleDate}
                                                    errorMessage={datePickerInvalidMessage}
                                                    setErrorMessage={setDatePickerInvalidMessage}
                                                />
                                            </InputWrap>
                                        </FlexedDiv>
                                    </FlexedDiv>
                                    <FlexedDiv wrap="wrap" style={{ rowGap: '1.5rem', columnGap: '1rem' }}>
                                        <FlexedDiv direction="column">
                                            <Fs12BoldPrimaryBlack>{LABEL.transTypes}</Fs12BoldPrimaryBlack>
                                            <SelectWrapper>
                                                <SelectDropdown
                                                    options={[{ value: LABEL.salesAo, label: LABEL.salesAo }]}
                                                    testId={`transactiontype-sorting`}
                                                    selectedValue={LABEL.salesAo}
                                                    isDisabled={true}
                                                />
                                            </SelectWrapper>
                                        </FlexedDiv>
                                        <FlexedDiv direction="column">
                                            <MultiSelect
                                                checkedState={tempState.product}
                                                handleMultiSelect={(item: ReactText[]) =>
                                                    handleSelectFilters('product', item)
                                                }
                                                hasSelectAll={false}
                                                label={LABEL.productTypes}
                                                noOverlay={false}
                                                options={ProductTypes}
                                                placeHolder={LABEL.select}
                                                testId={`producttype-dropdown-filter`}
                                            />
                                        </FlexedDiv>
                                        <FlexedDiv direction="column">
                                            <MultiSelect
                                                checkedState={tempState.funding}
                                                handleMultiSelect={(item: ReactText[]) =>
                                                    handleSelectFilters('funding', item)
                                                }
                                                hasSelectAll={false}
                                                label={LABEL.fundingOption}
                                                noOverlay={false}
                                                options={FundingOptions}
                                                placeHolder={LABEL.select}
                                                testId={`funding-dropdown-filter`}
                                            />
                                        </FlexedDiv>
                                        <FlexedDiv direction="column">
                                            <Fs12BoldPrimaryBlack>{LABEL.accountTypes}</Fs12BoldPrimaryBlack>
                                            <SelectWrapper>
                                                <SelectDropdown
                                                    onChange={(item: ReactText) => handleSelectFilters('account', item)}
                                                    options={accountTypes}
                                                    testId={`date-sorting`}
                                                    selectedValue={tempState.account}
                                                />
                                            </SelectWrapper>
                                        </FlexedDiv>
                                    </FlexedDiv>
                                </Fragment>
                            )}
                            <FlexedDiv style={{ marginTop: '2rem' }} justifyContent="center">
                                <CustomButton
                                    onClick={() => {
                                        handleReset();
                                        setExpand(!expand);
                                    }}
                                    style={{ width: '16.67vw' }}
                                    id="hq-reset-btn"
                                >
                                    <Fs12BoldPrimaryBlack>{LABEL.reset}</Fs12BoldPrimaryBlack>
                                </CustomButton>
                                <CustomSpacer horizontal={true} space={'1rem'} />
                                <CustomButton
                                    primary={true}
                                    onClick={handleApply}
                                    style={{ width: '16.67vw', padding: '14px 0' }}
                                    id="hq-apply-btn"
                                >
                                    {LABEL.apply}
                                </CustomButton>
                            </FlexedDiv>
                        </>
                    ) : (
                        <SearchBarFilterTags
                            tags={tempState.searchbarFilterTags}
                            disabled={loading}
                            showAll={showAllTags}
                            handleShowAll={() => setShowAllTags(!showAllTags)}
                            handleClearAll={handleReset}
                            handleRemoveTag={handleRemoveFilter}
                            testId="branch-dashboard"
                        />
                    )}
                </SearchBar>
                <TabWrapper>
                    <Dashboard
                        tabs={tabs}
                        selectedTab={tab}
                        page={currentPage}
                        maxPage={maxPages}
                        setResultLimit={setResultLimit}
                        orderCounter={orderCounter}
                        switchTabs={switchTabs}
                        handleNext={handleNext}
                        handlePrevious={handlePrevious}
                        testId="hq-dashboard"
                        setCurrentPage={setCurrentPage}
                        resultLimit={resultLimit}
                        disableResultLimit={disableResultLimit}
                        adjustTabMargin={tempState.searchbarFilterTags.length !== 0}
                    />
                    {loading ? <ComponentLoader /> : null}
                </TabWrapper>
            </FlexedDiv>

            {/* track order modal */}
            {trackModal ? (
                <TrackOrderModal
                    modalActive={trackModal}
                    setModalActive={setTrackModal}
                    trackOrder={trackOrder}
                    closeTrackModal={closeTrackModal}
                    trackOrderInput={trackOrderInput}
                    trackSearchOptions={trackSearchOptions()}
                    onTrackOrderSearch={onTrackOrderSearch}
                    setTrackOrderInput={setTrackOrderInput}
                    title="Track Order"
                    testId="track-order-modal"
                />
            ) : null}
        </Fragment>
    );
};

export default DashboardHQ;

const TabWrapper = styled.div`
    position: relative;
`;
const InputWrap = styled.div`
    width: 18.33vw;
    min-width: 250px;
    height: 2.5rem;
    margin-top: 3px;
    margin-right: 1rem;
    margin-bottom: 2rem;
`;
const SelectWrapper = styled.div`
    margin-right: 1vw;
    min-width: 335px;
    max-width: 600px;
`;
