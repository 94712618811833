import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FlexedDiv } from '../../../components';
import { LABEL, TextDarkBlack, TextNavyBlue } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { MultiSection } from '../../../templates';

const physicalCopyAccountData: SectionIntro = {
    title: 'Account',
};

export const DocumentSummary = (props: IDocumentSummaryProps): JSX.Element => {
    const getPhysicalCopyAccountSection = (): ISection[] | ISectionWithSubSections[] => {
        if (!props.jointAcc && props.accDocs?.length) {
            return [
                {
                    sectionTitle: physicalCopyAccountData,
                    data: props.accDocs[0].documents,
                },
            ];
        } else if (props.accDocs?.length && props.jointAcc) {
            return [
                {
                    sectionTitle: physicalCopyAccountData,
                    subSection: props.accDocs.map((acc) => {
                        return {
                            sectionTitle: { title: acc.mainHeader, rightLabel: acc.subHeader },
                            data: acc.documents,
                        };
                    }),
                },
            ];
        } else {
            return [];
        }
    };
    return (
        <Fragment>
            {!props.noSoftCopy && (
                <BlockContainer>
                    <BlockContainerHeader>
                        <TextDarkBlack weight="700" size="18px">
                            Softcopy Documents
                        </TextDarkBlack>
                    </BlockContainerHeader>
                    <BlockContainerContent>
                        <MultiSection
                            sections={props.softCopy}
                            noSectionImage={props.noInfoImage}
                            customGrid={'2.75fr 0.25fr 2.75fr 0.25fr 2.75fr 0fr'}
                        />
                    </BlockContainerContent>
                </BlockContainer>
            )}
            {!props.noPhysical && (
                <BlockContainer>
                    <BlockContainerHeader>
                        <TextDarkBlack weight="700" size="18px">
                            Physical Documents
                        </TextDarkBlack>
                    </BlockContainerHeader>
                    <BlockContainerContent>
                        {props.physicalCopy?.length || props.accDocs?.length ? (
                            <>
                                {props.accDocs && props.accDocs?.length ? (
                                    <MultiSection
                                        sections={getPhysicalCopyAccountSection()}
                                        noShowLabel
                                        customGrid={'2.75fr 0.25fr 2.75fr 0.25fr 2.75fr 0fr'}
                                    />
                                ) : null}
                                {props.physicalCopy && props.physicalCopy?.length ? (
                                    <MultiSection
                                        sections={props.physicalCopy}
                                        noShowLabel
                                        customGrid={'2.75fr 0.25fr 2.75fr 0.25fr 2.75fr 0fr'}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <FlexedDiv direction="column" alignItems={'center'} margin={'32px 0 40px 0'}>
                                <IcoMoon name={props.noInfoImage ?? ''} size={'160px'} />
                                <TextNavyBlue weight="700">{LABEL.noInformation}</TextNavyBlue>
                            </FlexedDiv>
                        )}
                    </BlockContainerContent>
                </BlockContainer>
            )}
        </Fragment>
    );
};
const BlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px #0020430f;
    border-radius: 8px;
    margin-bottom: 1rem;
`;
const BlockContainerHeader = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ececec;
    padding: 0.75rem 1.5rem;
    border-radius: 8px 8px 0 0;
`;
const BlockContainerContent = styled.div`
    padding: 1rem 1.5rem 1.5rem;
`;
