import { LABEL } from '../../../constants';
import { addUserModes } from '../../../constants/addUserModes';
import * as Routes from '../../../routes';

export const initialOverView: IOverviewDataType[][] = [
    [
        {
            title: 'Add User',
            label: 'Request(s)',
            link: '',
            mode: '',
            tabInput: 0,
            source: 'createUser',
            testId: 'overview-adduser-card',
            dataIcon: 'request-icon',
        },
        {
            title: 'Bulk Import Users',
            label: 'Request(s)',
            link: Routes.addUser,
            mode: addUserModes.reviewUser,
            tabInput: 1,
            source: 'userBulkUpload',
            testId: 'overview-bulkimportuser-card',
            dataIcon: 'request-icon',
        },
        {
            title: 'Edit Users',
            label: 'Request(s)',
            link: '',
            mode: '',
            tabInput: 2,
            source: 'editUser',
            testId: 'overview-editusers-card',
            dataIcon: 'request-icon',
        },
        {
            title: 'Suspend Users',
            label: 'Request(s)',
            link: '',
            mode: '',
            tabInput: 3,
            source: 'suspendUser',
            testId: 'overview-suspendusers-card',
            dataIcon: 'request-icon',
        },
        {
            title: 'Reactivate User',
            label: 'Request(s)',
            link: '',
            mode: '',
            tabInput: 4,
            source: 'enableUser',
            testId: 'overview-reactivateuser-card',
            dataIcon: 'request-icon',
        },
        {
            title: 'Terminate User',
            label: 'Request(s)',
            link: '',
            mode: '',
            tabInput: 5,
            source: 'terminateUser',
            testId: 'overview-terminateuser-card',
            dataIcon: 'request-icon',
        },
    ],
    [
        {
            title: 'Add User Groups',
            label: 'Request(s)',
            link: '',
            mode: '',
            handleClick: undefined,
            tabInput: 0,
            source: 'createGroup',
            testId: 'overview-creategroup-card',
            dataIcon: 'request-icon',
        },
        {
            title: 'Edit User Groups',
            label: 'Request(s)',
            link: '',
            mode: '',
            handleClick: undefined,
            tabInput: 1,
            source: 'editGroup',
            testId: 'overview-editgroup-card',
            dataIcon: 'request-icon',
        },
        {
            title: 'Suspend User Groups',
            label: 'Request(s)',
            link: '',
            mode: '',
            handleClick: undefined,
            tabInput: 2,
            source: 'suspendGroup',
            testId: 'overview-suspendgroups-card',
            dataIcon: 'request-icon',
        },
        {
            title: 'Reactivate User Groups',
            label: 'Request(s)',
            link: '',
            mode: '',
            handleClick: undefined,
            tabInput: 3,
            source: 'enableGroup',
            testId: 'overview-enablegroups-card',
            dataIcon: 'request-icon',
        },
        {
            title: 'Delete User Groups',
            label: 'Request(s)',
            link: '',
            mode: '',
            handleClick: undefined,
            tabInput: 4,
            source: 'deleteGroup',
            testId: 'overview-deletegroups-card',
            dataIcon: 'request-icon',
        },
    ],
    [
        {
            title: 'Add Roles & Permissions',
            label: 'Request(s)',
            link: '',
            mode: '',
            handleClick: undefined,
            tabInput: 0,
            source: 'createRole',
            testId: 'overview-addroles-card',
            dataIcon: 'request-icon',
        },
        {
            title: 'Edit Roles & Permissions',
            label: 'Request(s)',
            link: '',
            mode: '',
            handleClick: undefined,
            tabInput: 1,
            source: 'editRole',
            testId: 'overview-editroles-card',
            dataIcon: 'request-icon',
        },
        {
            title: 'Suspend Roles & Permissions',
            label: 'Request(s)',
            link: '',
            mode: '',
            handleClick: undefined,
            tabInput: 2,
            source: 'suspendRole',
            testId: 'overview-suspendroles-card',
            dataIcon: 'request-icon',
        },
        {
            title: 'Reactivate Roles & Permissions',
            label: 'Request(s)',
            link: '',
            mode: '',
            handleClick: undefined,
            tabInput: 3,
            source: 'enableRole',
            testId: 'overview-enableroles-card',
            dataIcon: 'request-icon',
        },
        {
            title: 'Delete Roles & Permissions',
            label: 'Request(s)',
            link: '',
            mode: '',
            handleClick: undefined,
            tabInput: 4,
            source: 'deleteRole',
            testId: 'overview-deleteroles-card',
            dataIcon: 'request-icon',
        },
    ],
];
export const searchPlaceholder: string[] = [
    LABEL.searchPlaceHolderByUserName,
    LABEL.searchPlaceHolderByUserGroup,
    LABEL.searchPlaceHolderByUserRole,
];
export const dateSortingItemsSA = [
    { label: LABEL.createdOn, value: 'createdOn', testId: 'created-on-sorting-option' },
    {
        label: LABEL.lastUpdated,
        value: 'lastUpdated',
        testId: 'last-updated-sorting-option',
    },
];
