import React, { useEffect, useState } from 'react';
import { FlexedDiv, Avatar } from '../../components';
import { TextDarkBlack } from '../../constants';
import { MultiSection } from '../../templates';
import { getInitials } from '../../utils';
import { IAdviserProfileDetails } from './function';

import styled from 'styled-components';

export interface AdviserProfileProps {
    profile: IAdviserProfileDetails;
}

export const Profile: React.FC<AdviserProfileProps> = ({ profile }: AdviserProfileProps) => {
    const [adviserProfile, setAdviserProfile] = useState<IAdviserProfileDetails>(profile);

    useEffect(() => {
        setAdviserProfile(profile);
    }, [profile]);
    return (
        <Container id="advisor-profile">
            <ProfileContainer id="advisor-profile-top">
                <FlexedDiv alignItems="center">
                    <Avatar
                        size={80}
                        customStyles={{
                            boxShadow: '3px 3px 14px rgba(0, 32, 67, 0.12), -2px 2px 8px rgba(0, 32, 67, 0.06);',
                            border: '1px solid #fff',
                            fontWeight: 700,
                            fontSize: '1.5rem',
                        }}
                        color="#fff"
                        backgroundColor="#A82252"
                        testId="account"
                    >
                        {getInitials(adviserProfile.name)}
                    </Avatar>
                    <FlexedDiv direction="column" style={{ marginLeft: '1.5rem', width: '16.667vw' }}>
                        <TextDarkBlack weight="700" size="1.5rem">
                            {adviserProfile.name}
                        </TextDarkBlack>
                        <TextDarkBlack style={{ color: 'rgba(42, 54, 90, 0.8)' }}>{adviserProfile.code}</TextDarkBlack>
                    </FlexedDiv>
                </FlexedDiv>
            </ProfileContainer>

            {adviserProfile &&
                adviserProfile.details.map((elm, index) => {
                    return (
                        <BlockContainer
                            key={index}
                            id={`${
                                elm.title && elm.title.toLocaleLowerCase().replace(/,/g, '').replace(/\s/g, '-')
                            }-container`}
                        >
                            <BlockContainerHeader>
                                <TextDarkBlack weight="700" size="18px">
                                    {elm.title}
                                </TextDarkBlack>
                            </BlockContainerHeader>
                            <BlockContainerContent>
                                <MultiSection sections={elm.sections} />
                            </BlockContainerContent>
                        </BlockContainer>
                    );
                })}
        </Container>
    );
};
const BlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px #0020430f;
    border-radius: 8px;
    margin-bottom: 1rem;
`;
const BlockContainerHeader = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ececec;
    padding: 0.75rem 1.5rem;
    border-radius: 8px 8px 0 0;
`;
const BlockContainerContent = styled.div`
    padding: 1rem 1.5rem 1.5rem;
`;

const Container = styled.div`
    // padding: 2rem;
`;

const ProfileContainer = styled.div`
    margin: 0rem 1.5rem 1.5rem;
`;
