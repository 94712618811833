// export const COGNITO_CONFIG = {
//     aws_cognito_region: 'ap-southeast-1',
//     aws_user_pools_id: 'ap-southeast-1_p35sOAD4D',
//     aws_user_pools_web_client_id: '73lv1nlsi1lkcpa1jbfb5tf69i',
//     aws_cognito_identity_pool_id: 'ap-southeast-1:ba2d26a0-6fb7-46c2-a25c-b3ad959e89ed',
// };

export const COGNITO_CONFIG = {
    aws_cognito_region: 'ap-southeast-1',
    aws_user_pools_id: 'ap-southeast-1_60BxVSGJb',
    aws_user_pools_web_client_id: '1tvvu04mpajgj0q8b55vsjma3s',
    aws_cognito_identity_pool_id: 'ap-southeast-1:65ac0528-7aee-4402-aae3-09728906bee2',
};
