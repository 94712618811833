/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';

import FlexSpacer, { CustomDropdownV2, CustomSpacer, FlexedDiv, Tooltip } from '../../../../../components';
import { Collapsible } from '../../../../../components/molecules/Collapsible/Collapsible';
import { TextDarkGrey6 } from '../../../../../constants';
import { BRANCH_ROLE } from '../../../../../constants/branchRoleCollapsible';
import { SYSTEM_ADMIN } from '../../../../../constants/labels';
import { IcoMoon } from '../../../../../icons';
import { handleChangeData, handleUserChange, hasKey } from '../../utils/functions';
import { CollapsibleContent, ISectionTypes } from '../../AddRoles/CollapsibleContent';
import {
    IAdviserSection,
    ICustomerExpSection,
    ILoginRoleSection,
} from '../../AddRoles/BranchPrivileges/branchRolesInitialData';
import { PermissionLevelInfoTemplate } from '../../../../../components/atoms/TooltipTemplates';
import { IReportsSection } from '../HqPrivileges/hqRolesInitialData';
export type ISetSectionTypes = (data: ISectionTypes) => void;

export interface BranchPrivilegesProps {
    approval: boolean;
    loginAccess: ILoginRoleSection;
    setLoginAccess: (data: ILoginRoleSection) => void;
    customerExp: ICustomerExpSection;
    setCustomerExp: (data: ICustomerExpSection) => void;
    advisers: IAdviserSection;
    setAdvisers: (data: IAdviserSection) => void;
    reports: IReportsSection;
    setReports: (data: IReportsSection) => void;
}

export const CollapsibleTooltipMessage: ITooltip[] = [
    {
        label: SYSTEM_ADMIN.ADD_ROLE.LABEL_GENERAL_ACCESS,
        remarks: SYSTEM_ADMIN.ADD_ROLE.LABEL_GENERAL_ACCESS_CONTENT,
    },
    {
        label: SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER,
        remarks: SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_CONTENT,
    },
    {
        label: SYSTEM_ADMIN.ADD_ROLE.LABEL_CHECKER,
        content: [SYSTEM_ADMIN.ADD_ROLE.LABEL_CHECKER_CONTENT1, SYSTEM_ADMIN.ADD_ROLE.LABEL_CHECKER_CONTENT2],
    },
    {
        label: SYSTEM_ADMIN.ADD_ROLE.LABEL_AUTO_AUTHORIZER,
        remarks: SYSTEM_ADMIN.ADD_ROLE.LABEL_AUTO_AUTHORIZER_CONTENT,
    },
];

export const BranchPrivileges: FunctionComponent<BranchPrivilegesProps> = ({
    approval,
    loginAccess,
    customerExp,
    setCustomerExp,
    setLoginAccess,
    advisers,
    setAdvisers,
    reports,
    setReports,
}: BranchPrivilegesProps) => {
    const [initial, setInitial] = useState<boolean>(true);
    const [expandArray, setExpandArray] = useState<boolean[]>([true, false, false, false]);
    const setInitialValue = () => {
        let accessLevelObject = { label: '', value: '' };
        switch (customerExp.permissionLevel.toLowerCase()) {
            case 'maker':
                accessLevelObject = { label: 'Maker', value: 'maker' };
                break;
            case 'maker (edited)':
                accessLevelObject = { label: 'Maker (Edited)', value: 'maker' };
                break;
            case 'checker':
                accessLevelObject = { label: 'Checker', value: 'checker' };
                break;
            case 'checker (edited)':
                accessLevelObject = { label: 'Checker (Edited)', value: 'checker' };
                break;
            case 'auto-authorizer':
                accessLevelObject = { label: 'Auto-Authorizer', value: 'autoAuthorizer' };
                break;
            case 'auto-authorizer (edited)':
                accessLevelObject = { label: 'Auto-Authorizer (Edited)', value: 'autoAuthorizer' };
                break;
            default:
                accessLevelObject = { label: 'Maker', value: 'maker' };
        }
        return accessLevelObject;
    };
    const [customerExpDropdown, setCustomerExpDropdown] = useState<ISelectValue>(setInitialValue());

    const handleCustomerExp = () => {
        if (approval === false) {
            const temp = { ...customerExp };
            const newData = handleChangeData(temp);
            setCustomerExp(newData as ICustomerExpSection);
        }
    };

    const handleAdvisers = () => {
        if (approval === false) {
            const temp = { ...advisers, permissionLevel: advisers.isAll ? 'General Access' : 'General Access' };
            const newData = handleChangeData(temp);
            setAdvisers(newData as IAdviserSection);
        }
    };
    const handleReports = () => {
        if (!approval) {
            const temp = { ...reports, permissionLevel: reports.isAll ? 'General Access' : 'General Access' };
            const newData = handleChangeData(temp);
            setReports(newData as IReportsSection);
        }
    };

    const handleExpandAll = () => {
        if (approval === false) {
            const toggle = expandArray.filter((element) => element === true).length === 4 ? false : true;
            setExpandArray([toggle, toggle, toggle, toggle]);
        }
    };

    const handleCustomerExpDropdown = (item: string) => {
        let temp: ISelectValue = {
            label: '',
            value: '',
        };
        switch (item) {
            case 'Maker':
                temp = { label: item, value: 'maker' };
                break;
            case 'Checker':
                temp = { label: item, value: 'checker' };
                break;
            case 'Auto-Authorizer':
                temp = { label: item, value: 'autoAuthorizer' };
                break;
        }
        setCustomerExpDropdown({ ...customerExpDropdown, ...temp });
    };

    const handleExpandCollapsible = (index: number) => {
        const tempArray = [...expandArray];
        tempArray[index] = !tempArray[index];
        setExpandArray(tempArray);
    };
    const options = [
        { item: 'Maker', value: 'maker', handleItem: handleCustomerExpDropdown },
        { item: 'Checker', value: 'checker', handleItem: handleCustomerExpDropdown },
        { item: 'Auto-Authorizer', value: 'autoAuthorizer', handleItem: handleCustomerExpDropdown },
    ];
    useEffect(() => {
        if (initial === false) {
            const temp = customerData;
            const stateData = { ...customerExp };
            const newData: ICustomerExpSection = handleUserChange(
                temp!,
                stateData,
                customerExpDropdown,
            ) as ICustomerExpSection;

            setCustomerExp(newData);
        }
    }, [customerExpDropdown]);

    useEffect(() => {
        setInitial(false);
    }, []);

    const expandLabel =
        expandArray.filter((element) => element === true).length === 4
            ? SYSTEM_ADMIN.ADD_ROLE.LABEL_COLLAPSE_ALL
            : SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPAND_ALL;
    const customerData = hasKey(BRANCH_ROLE.ceTransaction.data, customerExpDropdown.value)
        ? BRANCH_ROLE.ceTransaction.data[customerExpDropdown.value]
        : undefined;
    const expandIcon = expandArray.filter((element) => element === true).length === 4 ? 'collapse' : 'expand';

    return (
        <FlexedDiv direction="column" margin="auto" style={{ maxWidth: '1188px' }}>
            <FlexedDiv>
                <FlexSpacer />
                {approval === true ? (
                    <DisabledView>
                        <FlexedDiv
                            direction="row"
                            style={{ cursor: 'pointer', alignItems: 'center' }}
                            onClick={handleExpandAll}
                        >
                            <CollapseLabel>{expandLabel}</CollapseLabel>
                            <IcoMoon name={expandIcon} size="20px" style={{ alignSelf: 'center' }} />
                        </FlexedDiv>
                    </DisabledView>
                ) : (
                    <Fragment>
                        <FlexedDiv
                            direction="row"
                            style={{ cursor: 'pointer', alignItems: 'center' }}
                            onClick={handleExpandAll}
                        >
                            <CollapseLabel>{expandLabel}</CollapseLabel>
                            <IcoMoon name={expandIcon} size="20px" style={{ alignSelf: 'center' }} />
                        </FlexedDiv>
                    </Fragment>
                )}
            </FlexedDiv>
            <TextDarkGrey6 size="12px" weight="700" style={{ lineHeight: '1.5rem' }}>
                {SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_LOGIN}
            </TextDarkGrey6>
            <Collapsible
                index={0}
                checkboxDisabled={true}
                checked={loginAccess.isAll}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv direction="row" style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                <DropdownBase style={{ opacity: 0.5 }}>
                                    <BasicInput disabled={true} value={loginAccess.permissionLevel} />
                                    <IcoMoon name="caret-down" size={'1.5rem'} />
                                </DropdownBase>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={loginAccess.isAll}
                            isDisabled={true}
                            item={BRANCH_ROLE.accountManagement.data.general}
                            data={loginAccess}
                            setData={setLoginAccess as ISetSectionTypes}
                        />
                    </Fragment>
                }
                expanded={expandArray[0]}
                handleExpand={handleExpandCollapsible}
                isCheckable={true}
                title={BRANCH_ROLE.accountManagement.title}
                subtitle={BRANCH_ROLE.accountManagement.subtitle}
                testId="login-access-account-accordion"
                noXMargin={true}
                noMargin={true}
            />
            <CustomSpacer space={'1rem'} />
            <TextDarkGrey6 size="12px" weight="700" style={{ lineHeight: '1.5rem' }}>
                Customer Experience
            </TextDarkGrey6>
            <Collapsible
                index={1}
                checked={customerExp.isAll}
                checkboxDisabled={approval}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv direction="row" style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                <CustomDropdownV2
                                    items={options}
                                    noMinWidth={false}
                                    showDivider={false}
                                    maxWidth={'22.5rem'}
                                    disableDropdown={approval}
                                >
                                    <DropdownBase>
                                        <BasicInput value={customerExp.permissionLevel} />
                                        <IcoMoon name="caret-down" size={'1.5rem'} />
                                    </DropdownBase>
                                </CustomDropdownV2>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={customerExp.isAll}
                            isDisabled={approval}
                            item={customerData}
                            data={customerExp}
                            setData={setCustomerExp as ISetSectionTypes}
                            user={customerExpDropdown.value}
                            offsetArray={[5, 0, 0, 0, 0]}
                        />
                    </Fragment>
                }
                expanded={expandArray[1]}
                handleChecked={handleCustomerExp}
                handleExpand={handleExpandCollapsible}
                isCheckable={true}
                title={BRANCH_ROLE.ceTransaction.title}
                subtitle={BRANCH_ROLE.ceTransaction.subtitle}
                testId="customer-exp-transactions-accordion"
                noXMargin={true}
                noMargin={true}
            />
            <CustomSpacer space={'1rem'} />
            <Collapsible
                index={2}
                checked={advisers.isAll}
                checkboxDisabled={approval}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv direction="row" style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                <DropdownBase style={{ opacity: 0.5 }}>
                                    <BasicInput disabled={true} value={advisers.permissionLevel} />
                                    <IcoMoon name="caret-down" size={'1.5rem'} />
                                </DropdownBase>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={advisers.isAll}
                            isDisabled={approval}
                            item={BRANCH_ROLE.adviser.data.general}
                            data={advisers}
                            setData={setAdvisers as ISetSectionTypes}
                        />
                    </Fragment>
                }
                expanded={expandArray[2]}
                handleChecked={handleAdvisers}
                handleExpand={handleExpandCollapsible}
                isCheckable={true}
                title={BRANCH_ROLE.adviser.title}
                subtitle={BRANCH_ROLE.adviser.subtitle}
                testId="advisers-accordion"
                noXMargin={true}
                noMargin={true}
            />
            <CustomSpacer space={'2rem'} />
            <TextDarkGrey6 weight="700" size="0.75rem" lineHeight="15.6px">
                {SYSTEM_ADMIN.ADD_ROLE.LABEL_RESOURCES}
            </TextDarkGrey6>
            <CustomSpacer space={'1rem'} />

            <Collapsible
                index={3}
                checked={reports.isAll}
                checkboxDisabled={approval}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={reports.isAll}
                            isDisabled={approval}
                            item={BRANCH_ROLE.reports.data.general}
                            data={reports}
                            setData={setReports as ISetSectionTypes}
                            noDefaultActions
                        />
                    </Fragment>
                }
                expanded={expandArray[3]}
                handleChecked={handleReports}
                handleExpand={handleExpandCollapsible}
                isCheckable={true}
                title={BRANCH_ROLE.reports.title}
                subtitle={BRANCH_ROLE.reports.subtitle}
                testId="reports-accordion"
                noXMargin={true}
                noMargin={true}
            />
        </FlexedDiv>
    );
};

export const DropdownBase = styled.div`
    display: flex;
    background-color: 'red';
    flex-direction: row;
    background-color: white;
    border-color: #c9c9cd;
    border-width: 0.0625rem;
    border: 1px solid #c9c9cd;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;
    height: 48px;
    &:focus {
        box-shadow: 0 0 3px red;
    }
`;

export const BasicInput = styled.input.attrs({ type: 'input' })`
    outline: none;
    border: 0;
    height: 1.5rem;
    width: 18.8rem;
`;

export const InputText = styled.div`
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: #333333;
    opacity: 0.5;
`;

export const DisabledView = styled.div`
    pointer-events: none;
    opacity: 0.5;
`;

export const CollapseLabel = styled.span`
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    font-weight: 600;
    margin-right: 5px;
`;
