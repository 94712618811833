import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';

import { BasicInput, CollapsibleTooltipMessage, DropdownBase, InputText } from '..';
import { CustomDropdownV2, CustomSpacer, FlexedDiv, Tooltip } from '../../../../../components';
import { Collapsible } from '../../../../../components/molecules/Collapsible/Collapsible';
import { HQ_ROLE } from '../../../../../constants/hqRoleCollapsible';
import { SYSTEM_ADMIN } from '../../../../../constants/labels';
import { IcoMoon } from '../../../../../icons';
import { handleChangeData, handleUserChange, hasKey } from '../../utils/functions';
import { CollapsibleContent, ISetSectionTypes } from '../../AddRoles/CollapsibleContent';
import { IEDDCasesSection } from '../../AddRoles/HqPrivileges/hqRolesInitialData';
import { PermissionLevelInfoTemplate } from '../../../../../components/atoms/TooltipTemplates';

export interface EddPrivilegesProps {
    approval: boolean;
    eddCase: IEDDCasesSection;
    expanded: boolean;
    handleExpand: (index: number) => void;
    setEddCase: (data: IEDDCasesSection) => void;
}

export const EddPrivileges: FunctionComponent<EddPrivilegesProps> = ({
    approval,
    eddCase,
    expanded,
    handleExpand,
    setEddCase,
}: EddPrivilegesProps) => {
    const setInitialValue = () => {
        let accessLevelObject = { label: '', value: '' };
        switch (eddCase.permissionLevel.toLowerCase()) {
            case 'maker':
                accessLevelObject = { label: 'Maker', value: 'maker' };
                break;
            case 'maker (edited)':
                accessLevelObject = { label: 'Maker (Edited)', value: 'maker' };
                break;
            case 'checker':
                accessLevelObject = { label: 'Checker', value: 'checker' };
                break;
            case 'checker (edited)':
                accessLevelObject = { label: 'Checker (Edited)', value: 'checker' };
                break;
            case 'auto-authorizer':
                accessLevelObject = { label: 'Auto-Authorizer', value: 'autoAuthorizer' };
                break;
            case 'auto-authorizer (edited)':
                accessLevelObject = { label: 'Auto-Authorizer (Edited)', value: 'autoAuthorizer' };
                break;
            default:
                accessLevelObject = { label: 'Auto-Authorizer', value: 'autoAuthorizer' };
        }
        return accessLevelObject;
    };
    const [eddDropdown, setEddDropdown] = useState<ISelectValue>(setInitialValue());
    const [initial, setInitial] = useState<boolean>(true);

    const handleUserManagementDropdown = (item: string) => {
        let temp: ISelectValue = {
            label: '',
            value: '',
        };
        switch (item) {
            case 'Maker':
                temp = { label: item, value: 'maker' };
                break;
            case 'Checker':
                temp = { label: item, value: 'checker' };
                break;
            case 'Auto-Authorizer':
                temp = { label: item, value: 'autoAuthorizer' };
                break;
        }
        setEddDropdown(temp);
    };

    const handleCheckedEddManagement = () => {
        if (approval === false) {
            const temp = { ...eddCase };
            const newData = handleChangeData(temp);
            setEddCase(newData as IEDDCasesSection);
        }
    };

    useEffect(() => {
        if (initial === false) {
            const temp = eddData;
            const stateData = { ...eddCase };
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const newData: IEDDCasesSection = handleUserChange(temp!, stateData, eddDropdown) as IEDDCasesSection;
            setEddCase(newData);
        }
    }, [eddDropdown]);
    useEffect(() => {
        setInitial(false);
    }, []);

    const eddData = hasKey(HQ_ROLE.eddCase.data, eddDropdown.value)
        ? HQ_ROLE.eddCase.data[eddDropdown.value]
        : undefined;
    const options: IDropdownItemV2[] = [
        { item: 'Maker', value: 'maker', handleItem: handleUserManagementDropdown, disabled: true },
        { item: 'Checker', value: 'checker', handleItem: handleUserManagementDropdown, disabled: true },
        {
            item: 'Auto-Authorizer',
            value: 'autoAuthorizer',
            handleItem: handleUserManagementDropdown,
            disabled: false,
        },
    ];
    return (
        <Fragment>
            <Collapsible
                checked={eddCase.isAll}
                checkboxDisabled={approval}
                expanded={expanded}
                content={
                    <Fragment>
                        <Fragment>
                            <CustomSpacer space={'1.5rem'} />
                            <FlexedDiv direction="row" style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                                <FlexedDiv direction="column">
                                    <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                    <CustomDropdownV2
                                        items={options}
                                        noMinWidth={false}
                                        showDivider={false}
                                        maxWidth={'22.5rem'}
                                        disableDropdown={approval}
                                    >
                                        <DropdownBase>
                                            <BasicInput value={eddCase.permissionLevel} />
                                            <IcoMoon name="caret-down" size={'1.5rem'} />
                                        </DropdownBase>
                                    </CustomDropdownV2>
                                </FlexedDiv>
                                <CustomSpacer horizontal={true} space={'1rem'} />
                                <Tooltip
                                    tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                    placement="Right"
                                    style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                                >
                                    <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                        <IcoMoon name="info-filled" size={'2rem'} />
                                    </div>
                                </Tooltip>
                            </FlexedDiv>
                            <CustomSpacer space={'1.5rem'} />
                            <CollapsibleContent
                                checked={eddCase.isAll}
                                isDisabled={approval}
                                item={eddData}
                                data={eddCase}
                                setData={setEddCase as ISetSectionTypes}
                                offsetArray={[5, 3]}
                                user={eddDropdown.value}
                            />
                        </Fragment>
                    </Fragment>
                }
                handleChecked={handleCheckedEddManagement}
                handleExpand={handleExpand}
                isCheckable={true}
                title={HQ_ROLE.eddCase.title}
                subtitle={HQ_ROLE.eddCase.subtitle}
                testId="edd-cases-accordion"
                noXMargin={true}
                noMargin={true}
                index={7}
            />
        </Fragment>
    );
};
