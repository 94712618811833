import React, { Fragment, useState, FunctionComponent, useEffect } from 'react';
import { SYSTEM_ADMIN } from '../../../../../constants/labels';
import { FlexedDiv, CustomSpacer, Tooltip } from '../../../../../components';
import { IcoMoon } from '../../../../../icons';
import { HQ_ROLE } from '../../../../../constants/hqRoleCollapsible';
import { Collapsible } from '../../../../../components/molecules/Collapsible/Collapsible';
import { CollapsibleContent, ISetSectionTypes } from '../../AddRoles/CollapsibleContent';
import { IOperationDashboard, IOperationCases } from '../../AddRoles/HqPrivileges/hqRolesInitialData';
import { InputText, DropdownBase, BasicInput, CollapsibleTooltipMessage } from '..';
import { handleChangeData, handleUserChange, hasKey } from '../../utils/functions';
import { PermissionLevelInfoTemplate } from '../../../../../components/atoms/TooltipTemplates';

export interface OperationPrivilegesProps {
    approval: boolean;
    expanded: boolean;
    operationDashboard: IOperationDashboard;
    setOperationDashboard: (data: IOperationDashboard) => void;
    operationCases: IOperationCases;
    setOperationCases: (data: IOperationCases) => void;
}

export const OperationPrivileges: FunctionComponent<OperationPrivilegesProps> = ({
    expanded,
    operationDashboard,
    setOperationDashboard,
    operationCases,
    setOperationCases,
}: OperationPrivilegesProps) => {
    // TODO Update with new dropdown
    const [operationDashboardDropdown] = useState<ISelectValue>({
        label: 'Maker',
        value: 'maker',
    });
    const operationDashboardData = hasKey(HQ_ROLE.operationDashboard.data, operationDashboardDropdown.value)
        ? HQ_ROLE.operationDashboard.data[operationDashboardDropdown.value]
        : undefined;

    const handleOperationDashboard = () => {
        const temp = { ...operationDashboard };
        const newData = handleChangeData(temp);
        setOperationDashboard(newData as IOperationDashboard);
    };

    const handleOperationCases = () => {
        const temp = { ...operationCases };
        const newData = handleChangeData(temp);
        setOperationCases(newData as IOperationCases);
    };

    const handleExpandCollapsible = (index: number) => {
        console.log(index);
    };
    useEffect(() => {
        const temp = operationDashboardData;
        const stateData = { ...operationDashboard };
        const newData: IOperationDashboard = handleUserChange(
            temp ?? [],
            stateData,
            operationDashboardDropdown,
        ) as IOperationDashboard;
        setOperationDashboard(newData);
    }, [operationDashboardDropdown]);

    return (
        <Fragment>
            <Collapsible
                checked={operationDashboard.isAll}
                expanded={expanded}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={operationDashboard.isAll}
                            item={operationDashboardData}
                            data={operationDashboard}
                            setData={setOperationDashboard as ISetSectionTypes}
                            offsetArray={[0, 3, 3, 3, 3, 3]}
                        />
                    </Fragment>
                }
                handleChecked={handleOperationDashboard}
                isCheckable={true}
                title={HQ_ROLE.operationDashboard.title}
                subtitle={HQ_ROLE.operationDashboard.subtitle}
                testId="operations-dashboard-accordion"
                noXMargin={true}
                noMargin={true}
                handleExpand={handleExpandCollapsible}
            />
            <CustomSpacer space={'1rem'} />
            <Collapsible
                checked={operationCases.isAll}
                expanded={expanded}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                <DropdownBase style={{ opacity: 0.5 }}>
                                    <BasicInput disabled={true} value={operationCases.permissionLevel} />
                                    <IcoMoon name="caret-down" size={'1.5rem'} />
                                </DropdownBase>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={operationCases.isAll}
                            item={HQ_ROLE.operationCases.data.general}
                            data={operationCases}
                            setData={setOperationCases as ISetSectionTypes}
                        />
                    </Fragment>
                }
                handleChecked={handleOperationCases}
                isCheckable={true}
                title={HQ_ROLE.operationCases.title}
                subtitle={HQ_ROLE.operationCases.subtitle}
                testId="operations-dashboard-accordion"
                noXMargin={true}
                noMargin={true}
                handleExpand={handleExpandCollapsible}
            />
        </Fragment>
    );
};
