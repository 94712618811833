import React from 'react';
import { Pointer } from '../../../components';
import CustomDropdown, { IDropdownItem } from '../../../components/molecules/Dropdown/Dropdown';
import { LABEL } from '../../../constants';
import { IcoMoon } from '../../../icons';
interface TargetDateExActions extends ITableOptions {
    handleApprove: (item: IColumnItemAccordion) => void;
    handleReject: (item: IColumnItemAccordion) => void;
}
export const TargetDateExActions = ({ data, handleApprove, handleReject }: TargetDateExActions): JSX.Element => {
    const handlePrimaryAction = () => {
        handleApprove(data);
    };
    const handleSecondaryAction = () => {
        handleReject(data);
    };
    const targetDateExMenuActions: IDropdownItem[] = [
        {
            item: LABEL.approveDueDate,
            icon: 'check',
            handleItem: handlePrimaryAction,
            testId: `targetdate-extension-approve-request-btn`,
        },
        {
            item: LABEL.rejectDueDate,
            icon: 'terminate',
            handleItem: handleSecondaryAction,
            testId: `targetdate-extension-reject-request-btn`,
            danger: true,
        },
    ];

    return (
        <CustomDropdown items={targetDateExMenuActions}>
            <Pointer>
                <IcoMoon name="action-menu" size="1.5rem" />
            </Pointer>
        </CustomDropdown>
    );
};

export default TargetDateExActions;
