import moment from 'moment';
import { LABEL } from '../../constants';

type permissionPropertyType =
    | 'canDownloadThirdPartyDailyBookingReport'
    | 'canDownloadMoneySightedCTAReport'
    | 'canDownloadRegularInvestmentReport'
    | 'canDownloadSwitchingSubmissionReport'
    | 'canDownloadRedemptionSubmissionReport'
    | 'canDownloadBranchControlSalesSubmissionSummaryReport'
    | 'canDownloadSalesSubmissionStatisticSummaryMoneyMarketReport'
    | 'canDownloadSalesSubmissionStatisticDetailsReport'
    | 'canDownloadEpfApplicationStatusReport'
    | 'canDownloadUserAccessAuditTrailReport'
    | 'canDownloadUserAccountStatusReport';

const REPORT_PERMISSIONS_PROPERTIES = {
    [LABEL.dailyBookingReport]: 'canDownloadThirdPartyDailyBookingReport',
    [LABEL.msfCTAReport]: 'canDownloadMoneySightedCTAReport',
    [LABEL.regularInvestmentReport]: 'canDownloadRegularInvestmentReport',
    [LABEL.switchingSubmissionReport]: 'canDownloadSwitchingSubmissionReport',
    [LABEL.userAccessAuditTrailReport]: 'canDownloadUserAccessAuditTrailReport',
    [LABEL.userAccountStatusReport]: 'canDownloadUserAccountStatusReport',
    [LABEL.redemptionSubmissionReport]: 'canDownloadRedemptionSubmissionReport',
    [LABEL.branchControlSalesSubmissionReport]: 'canDownloadBranchControlSalesSubmissionSummaryReport',
    [LABEL.salesSubmissionStaticSummaryMMReport]: 'canDownloadSalesSubmissionStatisticSummaryMoneyMarketReport',
    [LABEL.salesSubmissionStaticDetailsReport]: 'canDownloadSalesSubmissionStatisticDetailsReport',
    [LABEL.epfApplicationStatusReport]: 'canDownloadEpfApplicationStatusReport',
};
//check permission
export const hasReportAccess = (
    report: string,
    permissionObject: IBranchPermissions['permission'] | IHqPermissions['permission'],
): boolean | undefined => {
    const permission = permissionObject.reports.operationalReport.actions;
    if (!permission) {
        throw new Error(`Invalid permission object: ${JSON.stringify(permissionObject)}`);
    }
    const permissionProperty = REPORT_PERMISSIONS_PROPERTIES[report] as permissionPropertyType;

    if (!permissionProperty) {
        throw new Error(`Invalid report name: ${report}`);
    }

    return permission[permissionProperty] === LABEL.maker ? true : undefined;
};

export const getReportDescription = (report: string): string => {
    const descriptionThirdPartyBookingReport = 'Daily completed transactions for non-KIB fund houses';
    const descriptionMSFReport = 'All sales transactions of money-sighted fund paid with Client Trust Account (CTA)';
    const descriptionRegularInvestmentReport = 'All recurring transactions for different product types';
    const descriptionSwitchingSubmissionReport = 'All funds for the switching transactions';
    const descriptionUserAccessAuditTrailReport = 'All login and logout trails for each user';
    const descriptionUserAccountStatusReport = 'All current status of users';
    const descriptionEpfApplicationReport = 'All sales transactions using EPF as the payment method';
    const descriptionRedemptionSubmissionReport = 'All funds for the redemption transactions';
    const descriptionBranchControlSalesSubmissionReport =
        'All cases in Form A based on batch cut off time, and case count based on each Transaction Number';
    const descriptionSalesSubmissionStaticSummaryMMReport =
        'All cases and the total amount of submissions for HQ and branches';
    const descriptionSalesSubmissionStaticDetailsReport = 'All sales submissions for HQ and branches';
    switch (report) {
        case LABEL.dailyBookingReport:
            return descriptionThirdPartyBookingReport;
        case LABEL.msfCTAReport:
            return descriptionMSFReport;
        case LABEL.regularInvestmentReport:
            return descriptionRegularInvestmentReport;
        case LABEL.switchingSubmissionReport:
            return descriptionSwitchingSubmissionReport;
        case LABEL.userAccessAuditTrailReport:
            return descriptionUserAccessAuditTrailReport;
        case LABEL.userAccountStatusReport:
            return descriptionUserAccountStatusReport;
        case LABEL.epfApplicationStatusReport:
            return descriptionEpfApplicationReport;
        case LABEL.redemptionSubmissionReport:
            return descriptionRedemptionSubmissionReport;
        case LABEL.branchControlSalesSubmissionReport:
            return descriptionBranchControlSalesSubmissionReport;
        case LABEL.salesSubmissionStaticSummaryMMReport:
            return descriptionSalesSubmissionStaticSummaryMMReport;
        case LABEL.salesSubmissionStaticDetailsReport:
            return descriptionSalesSubmissionStaticDetailsReport;
        default:
            return LABEL.description;
    }
};

export const handleReportsDashboardResults = (
    results: IGetOperationalReportDashboardResult[],
    permissionObject: IBranchPermissions['permission'] | IHqPermissions['permission'],
): IGetOperationalReportDashboardResult[] => {
    const filteredResults = results.filter((result) => {
        switch (result.reportName) {
            case LABEL.msfCTAReport:
            case LABEL.dailyBookingReport:
            case LABEL.regularInvestmentReport:
            case LABEL.switchingSubmissionReport:
            case LABEL.userAccessAuditTrailReport:
            case LABEL.userAccountStatusReport:
            case LABEL.redemptionSubmissionReport:
            case LABEL.branchControlSalesSubmissionReport:
            case LABEL.salesSubmissionStaticSummaryMMReport:
            case LABEL.salesSubmissionStaticDetailsReport:
            case LABEL.epfApplicationStatusReport:
                return hasReportAccess(result.reportName, permissionObject);
            default:
                return true;
        }
    });
    filteredResults.sort((a, b) => {
        if (a.reportName > b.reportName) return 1;
        if (a.reportName < b.reportName) return -1;
        return 0;
    });
    return filteredResults;
};

export const handleReportsDatePicker = (
    date: [moment.Moment | null, moment.Moment | null],
    filters: IReportFilters,
    setFilters: React.Dispatch<React.SetStateAction<IReportFilters>>,
    reportType: TReportType,
    setDatePickerInvalidMessage: React.Dispatch<React.SetStateAction<string>>,
    dateRangeParams: IDateRangeParams,
): void => {
    const temp = [...date] as [moment.Moment, moment.Moment];
    const [startDate, endDate] = temp.map((item) => (item ? moment(item, '"M/D/YYYY H:mm"').valueOf() : NaN));
    const currentDate = parseInt(dateRangeParams.currentDate, 10);
    const currentLimit = parseInt(dateRangeParams.dateRangeLimitInDay, 10);
    if (!isNaN(endDate)) {
        const _validFrom = startDate < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo =
            endDate < moment().add(1, 'day').startOf('day').valueOf() &&
            endDate > startDate &&
            endDate <= moment(startDate).add(currentLimit, 'days').valueOf() &&
            endDate <= moment(currentDate).endOf('day').valueOf();
        // Date range in milliseconds
        if (_validFrom && _validTo) {
            // Date range in milliseconds
            const range = [startDate, endDate].join('~');
            const existingDateRangeFilterIndex = filters[reportType].findIndex((f) => f.column === 'dateRange');
            if (existingDateRangeFilterIndex !== -1) {
                const updatedFilters = [...filters[reportType]];
                updatedFilters[existingDateRangeFilterIndex] = { column: 'dateRange', value: range };
                setFilters({ ...filters, [reportType]: updatedFilters });
            } else {
                const obj: ISearchInput = {
                    column: '',
                    value: '',
                };
                obj.column = 'dateRange';
                obj.value = range;
                const temp = obj;
                setFilters({ ...filters, [reportType]: [...filters[reportType], temp] });
            }
            setDatePickerInvalidMessage('');
        } else if (!_validFrom && !_validTo) {
            const existingDateRangeFilterIndex = filters[reportType].findIndex((f) => f.column === 'dateRange');
            if (existingDateRangeFilterIndex !== -1) {
                const updatedFilters = [...filters[reportType]];
                updatedFilters[existingDateRangeFilterIndex] = { column: 'dateRange', value: '' };
                setFilters({ ...filters, [reportType]: updatedFilters });
            } else {
                const obj: ISearchInput = {
                    column: '',
                    value: '',
                };
                obj.column = 'dateRange';
                obj.value = '';
                const temp = obj;
                setFilters({ ...filters, [reportType]: [...filters[reportType], temp] });
            }
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            if (endDate < startDate && _validFrom) {
                moment(startDate).add(currentLimit, 'days').valueOf() > moment(currentDate).endOf('day').valueOf()
                    ? setDatePickerInvalidMessage(
                          `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format(
                              'DD/MM/YYYY',
                          )}`,
                      )
                    : setDatePickerInvalidMessage(
                          `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment(startDate)
                              .add(currentLimit, 'days')
                              .format('DD/MM/YYYY')}`,
                      );
            } else if (!_validTo && _validFrom) {
                setDatePickerInvalidMessage(
                    `Selected date range cannot be more than ${dateRangeParams.dateRangeLimitInDay} days`,
                );
            }
        }
    }
};
