/* eslint-disable @typescript-eslint/no-explicit-any */
import { getOperationalReportDashboard } from '../../_graphql/queries/operationalReport/getOperationalReportDashboard';
import { API, graphqlOperation } from 'aws-amplify';
import { getSource } from '../Inbox/inboxUtils';

export const getReportDashboardData = async (
    idToken:
        | {
              Authorization: string;
              strategy: string;
          }
        | undefined,
    searchInput: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    parsedPermission: any,
): Promise<IGetOperationalReportDashboard['getOperationalReportDashboard'] | undefined> => {
    const tmp: IGetOperationalReportDashboard['getOperationalReportDashboard'] = {
        data: null,
        error: null,
    };
    try {
        const response: GenericResponse<IGetOperationalReportDashboard> = await API.graphql(
            graphqlOperation(getOperationalReportDashboard, {
                input: {
                    search: searchInput,
                    source: getSource(parsedPermission)[0] === 'ce' ? 'ce' : 'systemadmin',
                },
            }),
            idToken,
        );

        const { data, error } = response.data.getOperationalReportDashboard;
        if (data) {
            if (Array.isArray(data.result)) {
                tmp.data = {
                    result: data.result,
                };
                return tmp;
            }
        } else {
            throw error;
        }
    } catch (error) {
        tmp.error = error as IErrorHandling;
        return tmp;
    }
};
