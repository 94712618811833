import React, { Fragment } from 'react';
import { LABEL } from '../../../constants';
import { IcoMoon } from '../../../icons';

export interface IReceiveHardCopyProps extends ITableOptions {
    handleReceiveHardcopy: (item: IColumnItemAccordion) => void;
    hardcopyTab: IHardcopyTab;
    eventClicked: boolean;
}

const ReceiveHardCopy = ({
    data,
    handleReceiveHardcopy,
    hardcopyTab,
    eventClicked,
}: IReceiveHardCopyProps): JSX.Element => {
    return (
        <Fragment>
            <div
                onClick={() => {
                    if (eventClicked === false)
                        hardcopyTab.actions.canReceiveHardCopy === LABEL.maker ? handleReceiveHardcopy(data) : null;
                }}
                style={{ cursor: 'pointer' }}
                id="receive-hardcopy-btn"
            >
                <IcoMoon name="check" size="1.5rem"></IcoMoon>
            </div>
        </Fragment>
    );
};
export default ReceiveHardCopy;
