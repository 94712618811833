import { API, graphqlOperation } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
    AdvanceTable,
    ComponentLoader,
    CustomButton,
    CustomDropdownV2,
    FlexedDiv,
    IDropdownItem,
    Snackbar,
} from '../../../components';
import FilterPill from '../../../components/molecules/FilterPills';
import { EMPTY_STATES, Fs12RegSecBlack, Fs18BoldPrimaryBlack, LABEL } from '../../../constants';
import AuthContext from '../../../context/AuthContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';
import { useComponentLoader } from '../../../customHooks';
import { sh272, sh72 } from '../../../styles';
import { statusStyle } from '../../../utils';
import { transactionTrackingReport } from '../../../_graphql/mutations/branchCE/transactionTrackingReport/transactionTrackingReport';
import { getTransactionTrackingQuery } from '../../../_graphql/queries/common/getTransactionTracking';

import CustomItem from './CustomItem';

const initialTransactionTrackingFilters = [
    {
        column: 'level',
        value: 'all',
    },
];
interface ITrackingSummaryProps {
    orderNo?: string;
}
export const TrackingSummary = ({ orderNo }: ITrackingSummaryProps): JSX.Element => {
    const initialPillOrderType = [
        {
            title: 'All',
            testId: 'order-type-all',
            index: 0,
            active: true,
            value: 'all',
        },
    ];

    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const { state } = useLocation<IOrderSummaryRouteProps>();
    const locationState = state;
    const { loading, loadingHandler } = useComponentLoader();
    const [showToast, setShowToast] = useState<boolean>(false);
    const [transactionTrackingResult, setTransactionTrackingResult] = useState<ITableDataWithGroupByLevel[]>([]);
    const [filter, setFilter] = useState<IColumnValue[]>(initialTransactionTrackingFilters);

    const [pillOrderType, setPillOrderType] = useState<IDashboardFilterTags[]>(initialPillOrderType);

    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const ColumnTable: ITableColumn[] = [
        {
            key: [{ key: 'createdOn' }],
            viewStyle: {
                width: sh272,
            },
            title: LABEL.dateSorting,
            customItem: true,
        },
        {
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh272,
            },
            title: LABEL.status,
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
        {
            key: [
                { key: 'name', textStyle: { fontWeight: 700 } },
                { key: 'username', textStyle: { wordBreak: 'break-word' }, noDashOnNull: true },
            ],
            viewStyle: {
                width: sh272,
            },
            title: 'Login ID',
        },
        {
            key: [{ key: 'remarks' }],
            viewStyle: {
                width: sh72,
            },
            title: LABEL.remarks,
            customItem: true,
        },
    ];

    const getValueForFilterTilte = (title: string): string => {
        const _title = title.toLowerCase();
        switch (_title) {
            case 'adviser':
                return 'agent';
            case 'ce (branch)':
                return 'ce';
            case 'ce (hq)':
                return 'hq';
            default:
                return 'all';
        }
    };

    const getDashboardFilterTagsFromFilterArray = (filters: string[]): IDashboardFilterTags[] => {
        let _filterTags: IDashboardFilterTags[] = [];
        _filterTags = filters.map((_filter, index): IDashboardFilterTags => {
            return {
                title: _filter,
                index: index,
                active:
                    filters.length === 1
                        ? true
                        : filter.findIndex((_f) => _f.value === getValueForFilterTilte(_filter)) !== -1,
                testId: `order-type-${_filter ?? ''.replaceAll(' ', '-').toLowerCase()}`,
                value: getValueForFilterTilte(_filter),
            };
        });
        return _filterTags;
    };

    const handleSetReportType = (type: string) => {
        const _type = type === 'PDF (.pdf)' ? 'pdf' : 'xlsx';
        runTransactionTrackingReport(_type);
    };
    const ReportDropDownValue: IDropdownItem[] = [
        {
            item: 'PDF (.pdf)',
            handleItem: handleSetReportType,
        },
        {
            item: 'Excel (.xlsx)',
            handleItem: handleSetReportType,
        },
    ];

    const handlePillFundType = (item: IDashboardFilterTags) => {
        const _pills = [...pillOrderType];
        _pills[_pills.findIndex((pill) => pill.active === true)].active = false;
        _pills[_pills.findIndex((pill) => pill.value === item.value)].active = true;
        setPillOrderType(_pills);
        setFilter([{ column: 'level', value: item.value || 'all' }]);
    };

    const handleToast = () => {
        setShowToast(!showToast);
    };
    useEffect(() => {
        runTransactionTracking();
    }, [filter]);
    const runTransactionTracking = async () => {
        loadingHandler();
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(getTransactionTrackingQuery, {
                    input: {
                        orderNo: locationState.orderNo || locationState.orderNumber,
                        transactionRef: locationState.transactionRef,
                        filter: filter,
                    },
                }),
                idTokenHeader,
            );
            const transactionTrackingResult = response.data.getTransactionTracking;
            if (transactionTrackingResult.error !== null) throw transactionTrackingResult.error;
            const { result } = transactionTrackingResult.data;
            setTransactionTrackingResult(result.trackingSummary);
            result.filters.levels.length <
            pillOrderType.filter((pill) => {
                return pill.title !== 'All';
            }).length
                ? undefined
                : setPillOrderType(getDashboardFilterTagsFromFilterArray(result.filters.levels));

            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(error as IErrorHandling).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Transaction Tracking',
                    testId: 'transactiontracking-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const runTransactionTrackingReport = async (type: string) => {
        loadingHandler();
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(transactionTrackingReport, {
                    input: {
                        orderNo: locationState.orderNo || locationState.orderNumber || orderNo,
                        transactionRef: locationState.transactionRef,
                        reportFormat: type,
                    },
                }),
                idTokenHeader,
            );
            const transactionTrackingReportRes = response.data.transactionTrackingReport;
            if (transactionTrackingReportRes.error !== null) throw transactionTrackingReportRes.error;
            const { result } = transactionTrackingReportRes.data;
            window.open(result.path, '_blank');
            result.status ? setShowToast(!showToast) : null;
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(error as IErrorHandling).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Download Transaction Tracking Report',
                    testId: 'transactiontrackingreport-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    return (
        <>
            <TrackingSummaryWrapper>
                <FlexedDiv justifyContent={'space-between'}>
                    <Fs18BoldPrimaryBlack>{LABEL.statusHistoryTracking}</Fs18BoldPrimaryBlack>
                    <CustomDropdownV2 items={ReportDropDownValue} noMinWidth maxWidth="124px">
                        <CustomButton
                            prefixIcon={''}
                            suffixIcon="caret-down"
                            id={'primaryButton.testId'}
                            data-testid="export-report--btn"
                            secondary
                            style={{
                                padding: '.5rem .75rem',
                                maxWidth: '138px',
                                background: ' #FFFFFF',
                                border: '1px solid #CECECE',
                                boxSizing: 'border-box',
                                borderRadius: '32px',
                            }}
                        >
                            Export Report
                        </CustomButton>
                    </CustomDropdownV2>
                </FlexedDiv>
                <FlexedDiv alignItems={'baseline'}>
                    <Fs12RegSecBlack style={{ marginRight: '8px' }}>{LABEL.level}</Fs12RegSecBlack>
                    {pillOrderType.map((item, index) => (
                        <FilterPill
                            active={item.active}
                            key={index + 1}
                            handleFilterPill={handlePillFundType}
                            item={item}
                            label={`${item.title}`}
                            testId={`${item.title}-${item.testId}-pill-${item.index}`}
                            disable={pillOrderType.length === 1}
                        />
                    ))}
                </FlexedDiv>
            </TrackingSummaryWrapper>

            <AdvanceTable
                data={transactionTrackingResult}
                columns={ColumnTable}
                RenderItem={(props: ITableCustomItem) => <CustomItem {...props} />}
                groupByLevel={true}
                onEmptyState={EMPTY_STATES.emptyStateTrackingSummary}
            ></AdvanceTable>
            {loading ? <ComponentLoader /> : null}
            <Snackbar
                open={showToast}
                onClose={handleToast}
                message={LABEL.transactionTrackingReportDownload}
                closable
                autoHide
            />
        </>
    );
};
const TrackingSummaryWrapper = styled.div``;
