/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, ChangeEvent, useContext } from 'react';
import { CustomButton, CustomSpacer, FlexedDiv, Snackbar } from '../../components/atoms';
import {
    SearchBar,
    ComponentLoader,
    DatePicker,
    SelectDropdown,
    Modal,
    Banner,
    TrackOrderModal,
} from '../../components';
import moment from 'moment';
import { CE_DASHBOARD_LABELS, Fs12BoldPrimaryBlack, TextPureBlack, LABEL } from '../../constants';
import TransactionsDashboard from '../../templates/Branch';

import { dateSorting } from './helpers/filterDropdownsBranch';
import MultiSelect from '../../components/molecules/MultiSelect/MultiSelect';

import { downloadModalContent } from './helpers/downloadModalContent';

import DashboardBranchContext from '../../context/BranchContext/DashboardBranchContext';
import ProgressModal from './ProgressModal';
import styled from 'styled-components';
import TrackOrderContext from '../../context/TrackOrderContext/TrackOrderContext';
import { SearchBarFilterTags } from '../../templates/SearchBarFilterTags';
import { UseDashboardBranchHandler } from './useDashboardBranchHandler';
import ReportDownloadProgressModal from '../../components/organisms/ReportProgressModal/ReportDownloadProgressModal';

export interface IDailySummaryState {
    count: number;
    result: string[];
}

const DashboardBranch: React.FC = () => {
    const {
        dailySummaryTrxCount,
        dateFilter,
        disableResultLimit,
        filterFundingTypes,
        filterProductTypes,
        fundTypePills,
        loading,
        loadingHandler,
        page,
        pages,
        resultLimit,
        searchbarFilterTags,
        searchInput,
        setDisableResultLimit,
        setPage,
        setResultLimit,
        setSearchbarFilterTags,
        setSearchInput,
        setTempTarget,
        tab,
        tabsCount,
        tempTargetDate,
        typeCount,
        fundTypeOptions,
        accountTypeOptions,
        branchStatusOptions,
        transactionTypeOptions,
        paymentMethodOptions,
        filterAccountTypes,
        orderTypePills,
        branchRiskOptions,
        filterBatchCutoffTypes,
        filterRiskTypes,
        filterStatusTypes,
        branchBatchCutoffOptions,
        orderCount,
        filterTransactionTypes,
    } = useContext(DashboardBranchContext);

    const { trackSearchOptions, trackOrderInput, onTrackOrderSearch, setTrackOrderInput } =
        useContext(TrackOrderContext);

    const {
        handleAccountTypes,
        handleApplyFilter,
        handleCancelFilter,
        handleConfirmModal,
        handleDate,
        handleDateSorting,
        handleDownload,
        handleFilterTableForExportReport,
        handleFundingOptions,
        handleNext,
        handlePillFundType,
        handlePlaceHolder,
        handlePrevious,
        handleProductTypes,
        handleTransactionTypes,
        handleReceived,
        handleSearchActions,
        handleRemoveFilter,
        getExternalLink,
        searchOptions,
        getSecondaryButtons,
        OpenTrackOrderModal,
        getExportReportPermissionsCheck,
        switchTabs,
        disableBannerButton,
        SelectAllBannerShortcut,
        trackOrder,
        closeTrackModal,
        bulkVerifyTransactions,
        getModalMessage,
        TrackOrderButton,
        tabs,
        expand,
        setExpand,
        showAllTags,
        showCheckBox,
        showBanner,
        selectedRows,
        showModal,
        bulkModal,
        trackModal,
        bannerType,
        downloadLink,
        progressModal,
        progressWidth,
        timeLeft,
        startTime,
        datePickerInvalidMessage,
        confirmVerify,
        disableSearchBarButtons,
        received,
        pendingTab,
        setShowAllTags,
        setShowCheckbox,
        setShowBanner,
        setSelectedRows,
        setShowModal,
        setBulkModal,
        setTrackModal,
        setBannerType,
        setProgressModal,
        setProgressWidth,
        setTimeLeft,
        setDatePickerInvalidMessage,
        setDisableSearchBarButtons,
        handleOrderTypePills,
        handleRiskTypeFilter,
        handleBatchCutoffTypeFilter,
        handleStatusTypeFilter,
        modalMessages,
        updateStatusAfterTrxReportDownload,
        updateTrxRefStatus,
        disableResultLimitForDownload,
        setToggleDailyReportProgress,
        toggleDailyReportProgress,
        handleCancelDailySummaryReport,
    } = UseDashboardBranchHandler();
    return (
        <Fragment>
            <SearchBar
                expand={expand}
                externalLink={getExternalLink()}
                setExpand={setExpand}
                placeHolder={handlePlaceHolder()}
                searchInput={searchInput}
                searchTitle={LABEL.submittedOrder}
                dropDownOptions={searchOptions()}
                data-testid="branch-searchbar"
                onSearch={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchInput({ ...searchInput, value: e.currentTarget.value });
                }}
                filter={true}
                primaryButton={
                    tabs[tab].name === 'daily' &&
                    (pendingTab.actions.canDownloadDailySummary === LABEL.maker ||
                        pendingTab.actions.canDownloadTransactionReport === LABEL.maker)
                        ? {
                              type: 'primary',
                              title: 'Export Report',
                              actionIcon: 'export',
                              isDropdown: true,
                              testId: 'export-report-dropdown',
                          }
                        : undefined
                }
                secondaryButton={getSecondaryButtons()}
                tertiaryButton={[TrackOrderButton]}
                handlePrimary={handleSearchActions}
                handleSecondary={handleSearchActions}
                handleTertiary={OpenTrackOrderModal}
                actionDropdownMenuItems={getExportReportPermissionsCheck}
                testId="branch-searchbar"
                clearSearchInput={() => {
                    setSearchInput({ ...searchInput, value: '' });
                }}
                disableActionDropdown={disableSearchBarButtons || expand}
                disableFilters={disableSearchBarButtons || loading}
                filterTags={searchbarFilterTags}
                showTags={showAllTags}
                disabled={loading}
            >
                <Fragment>
                    {expand ? (
                        <FlexedDiv direction="column">
                            <CustomSpacer space={'1.5rem'} />
                            <TextPureBlack weight="700">
                                {orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request')
                                    .length !== 0
                                    ? LABEL.filterOrderBy
                                    : LABEL.filterTransactionsBy}
                            </TextPureBlack>
                            <CustomSpacer space={'0.5rem'} />
                            <FlexedDiv direction="row">
                                <FlexedDiv direction="column" style={{ marginRight: '1rem' }}>
                                    <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                                        {LABEL.dateSorting}
                                    </Fs12BoldPrimaryBlack>
                                    <SelectWrapper>
                                        <SelectDropdown
                                            onChange={handleDateSorting}
                                            options={dateSorting}
                                            testId={`date-dropdown-filter`}
                                            selectedValue={dateFilter.dateSort}
                                            placeHolder="Select one"
                                        />
                                    </SelectWrapper>
                                </FlexedDiv>
                                <FlexedDiv direction="column">
                                    <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem', marginBottom: '6px' }}>
                                        {LABEL.dateRange}
                                    </Fs12BoldPrimaryBlack>
                                    <InputWrap>
                                        <DatePicker
                                            // label="Date Range"
                                            setTargetDate={(date) => {
                                                const start: moment.Moment = date?.[0] as moment.Moment;
                                                const end: moment.Moment = date?.[1] as moment.Moment;
                                                date?.[0] !== null ? start.set({ h: 0, m: 0, s: 0 }) : null;
                                                date?.[1] ? end.set({ h: 23, m: 59, s: 59 }) : null;
                                                // Setting Date range state for Date picker
                                                setTempTarget([start, end] as [
                                                    moment.Moment | null,
                                                    moment.Moment | null,
                                                ]);
                                                handleDate([start, end] as [
                                                    moment.Moment | null,
                                                    moment.Moment | null,
                                                ]);
                                            }}
                                            targetDate={tempTargetDate}
                                            range={true}
                                            width="360"
                                            primaryBorderColor="#002043"
                                            errorMessage={datePickerInvalidMessage}
                                            setErrorMessage={setDatePickerInvalidMessage}
                                        />
                                    </InputWrap>
                                </FlexedDiv>
                            </FlexedDiv>
                            <FlexedDiv wrap="wrap" style={{ rowGap: '1.5rem', columnGap: '1rem' }}>
                                {!orderTypePills?.tags.some((tag) => tag.active && tag.title === 'Change Request') && (
                                    <FlexedDiv direction="column">
                                        <MultiSelect
                                            checkedState={filterTransactionTypes.filter((options) =>
                                                transactionTypeOptions.includes(options),
                                            )}
                                            handleMultiSelect={handleTransactionTypes}
                                            label={LABEL.transaction}
                                            placeHolder={LABEL.selectOneOrMore}
                                            options={transactionTypeOptions}
                                            selectId={7}
                                            noOverlay={false}
                                            testId={`transactionType-dropdown-filter`}
                                        />
                                    </FlexedDiv>
                                )}
                                {tabs[tab].name.toLowerCase() !== 'physical doc' ? (
                                    <FlexedDiv direction="column">
                                        <MultiSelect
                                            checkedState={filterStatusTypes.filter((options) =>
                                                branchStatusOptions.includes(options),
                                            )}
                                            handleMultiSelect={handleStatusTypeFilter}
                                            label={LABEL.status}
                                            placeHolder={LABEL.selectOneOrMore}
                                            options={branchStatusOptions}
                                            selectId={4}
                                            noOverlay={false}
                                            testId={`status-dropdown-filter`}
                                        />
                                    </FlexedDiv>
                                ) : null}
                                {!orderTypePills?.tags.some((tag) => tag.active && tag.title === 'Change Request') && (
                                    <>
                                        <FlexedDiv direction="column">
                                            <MultiSelect
                                                checkedState={filterAccountTypes.filter((options) =>
                                                    accountTypeOptions.includes(options),
                                                )}
                                                handleMultiSelect={handleAccountTypes}
                                                label={LABEL.account}
                                                placeHolder={LABEL.selectOneOrMore}
                                                options={accountTypeOptions}
                                                selectId={3}
                                                noOverlay={false}
                                                testId={`account-dropdown-filter`}
                                            />
                                        </FlexedDiv>
                                        <FlexedDiv direction="column">
                                            <MultiSelect
                                                checkedState={filterProductTypes.filter((options) =>
                                                    fundTypeOptions.includes(options),
                                                )}
                                                handleMultiSelect={handleProductTypes}
                                                label={LABEL.products}
                                                placeHolder={LABEL.selectOneOrMore}
                                                options={fundTypeOptions}
                                                selectId={1}
                                                noOverlay={false}
                                                testId={`productType-dropdown-filter`}
                                            />
                                        </FlexedDiv>
                                        <FlexedDiv direction="column">
                                            <MultiSelect
                                                checkedState={filterFundingTypes.filter((options) =>
                                                    paymentMethodOptions.includes(options),
                                                )}
                                                handleMultiSelect={handleFundingOptions}
                                                label={LABEL.funding}
                                                placeHolder={LABEL.selectOneOrMore}
                                                options={paymentMethodOptions}
                                                selectId={2}
                                                noOverlay={false}
                                                testId={`funding-dropdown-filter`}
                                            />
                                        </FlexedDiv>
                                    </>
                                )}
                                {orderTypePills?.tags.some((tag) => tag.active && tag.title === 'Change Request') && (
                                    <FlexedDiv direction="column">
                                        <MultiSelect
                                            checkedState={filterRiskTypes.filter((options) =>
                                                branchRiskOptions.includes(options),
                                            )}
                                            handleMultiSelect={handleRiskTypeFilter}
                                            label={LABEL.risk}
                                            placeHolder={LABEL.selectOneOrMore}
                                            options={branchRiskOptions}
                                            selectId={5}
                                            noOverlay={false}
                                            testId={`risk-dropdown-filter`}
                                        />
                                    </FlexedDiv>
                                )}
                                {!orderTypePills?.tags.some((tag) => tag.active && tag.title === 'Change Request') &&
                                    tabs[tab].name.toLowerCase() === 'daily' && (
                                        <FlexedDiv direction="column">
                                            <MultiSelect
                                                checkedState={filterBatchCutoffTypes.filter((options) =>
                                                    branchBatchCutoffOptions.includes(options),
                                                )}
                                                handleMultiSelect={handleBatchCutoffTypeFilter}
                                                label={LABEL.batchCutOffTime2}
                                                placeHolder={LABEL.selectOneOrMore}
                                                options={branchBatchCutoffOptions}
                                                selectId={6}
                                                noOverlay={false}
                                                testId={`risk-dropdown-filter`}
                                                disabled={branchBatchCutoffOptions.length === 0}
                                            />
                                        </FlexedDiv>
                                    )}

                                <FlexedDiv direction="column" style={{ minWidth: '360px' }} />
                            </FlexedDiv>
                            <FlexedDiv style={{ margin: '2rem 0rem' }} justifyContent="center">
                                <CustomButton
                                    onClick={() => {
                                        handleCancelFilter();
                                        setExpand(!expand);
                                    }}
                                    style={{ width: '16.67vw' }}
                                    size="large"
                                    id="brach-reset-btn"
                                >
                                    {LABEL.reset}
                                </CustomButton>
                                <CustomSpacer horizontal={true} space={'1rem'} />
                                <CustomButton
                                    primary={true}
                                    onClick={handleApplyFilter}
                                    style={{ width: '16.67vw' }}
                                    id="brach-apply-btn"
                                    size="large"
                                >
                                    {LABEL.apply}
                                </CustomButton>
                            </FlexedDiv>
                        </FlexedDiv>
                    ) : (
                        <SearchBarFilterTags
                            tags={searchbarFilterTags.filter((tag) => {
                                if (tag.label === 'Product Type') {
                                    return fundTypeOptions
                                        .map((item) => (item = item.toString().toLowerCase()))
                                        .includes(tag.value.toLowerCase());
                                } else if (tag.label === 'Funding Option') {
                                    return paymentMethodOptions.includes(tag.value);
                                } else if (tag.label === 'Account Type') {
                                    return accountTypeOptions.includes(tag.value);
                                } else if (tag.label === LABEL.batchCutOffTime2) {
                                    return branchBatchCutoffOptions.includes(tag.value);
                                } else if (tag.label === LABEL.risk) {
                                    return branchRiskOptions
                                        .map((item) => (item = item.toString().toLowerCase()))
                                        .includes(tag.value.toLowerCase());
                                } else if (tag.label === LABEL.transTypes) {
                                    return transactionTypeOptions.includes(tag.value);
                                } else {
                                    return true;
                                }
                            })}
                            showAll={showAllTags}
                            handleShowAll={() => setShowAllTags(!showAllTags)}
                            disabled={disableSearchBarButtons || loading}
                            handleClearAll={handleCancelFilter}
                            handleRemoveTag={handleRemoveFilter}
                            testId="branch-dashboard"
                        />
                    )}
                </Fragment>
            </SearchBar>
            <div style={{ position: 'relative' }}>
                <TransactionsDashboard
                    tabs={tabs}
                    selectedTab={tab}
                    orderCounter={tabsCount}
                    switchTabs={switchTabs}
                    pillFundType={
                        orderTypePills?.tags.some((tag) => tag.active && tag.title === 'Change Request')
                            ? undefined
                            : fundTypePills
                    }
                    handlePillFundType={handlePillFundType}
                    // pillCutOffTime={cutOffTimePills}
                    // handlePillCutOffTime={handlePillCutOffTime}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    // batchCutOffCount={batchCutOffCount.batchCount}
                    typeCount={typeCount}
                    orderTypeCount={orderCount}
                    page={page}
                    pages={pages}
                    resultLimit={resultLimit}
                    setResultLimit={setResultLimit}
                    testId="branch"
                    disableResultLimit={disableResultLimit || disableResultLimitForDownload(bannerType)}
                    setPage={setPage}
                    adjustTabMargin={disableSearchBarButtons || searchbarFilterTags.length !== 0}
                    pillOrderType={orderTypePills}
                    handlePillOrderType={handleOrderTypePills}
                />
                {/* Snackbar / Toast to indicate receipt for Hardcopy,action performed inside hardcopy tab */}
                <Snackbar
                    open={received}
                    onClose={handleReceived}
                    message={LABEL.receiveHardCopyMessage}
                    closable
                    autoHide
                />
                {/* Banner for Download/Export Reports and Bulk Verify */}
                <Banner
                    disabled={disableBannerButton()}
                    title={bannerType}
                    description={
                        bannerType === LABEL.dailySummaryReportFormA ? (
                            `All available transactions (${dailySummaryTrxCount.count}) selected`
                        ) : selectedRows.length !== 1 ? (
                            bannerType === LABEL.bulkTransactionVerification ? (
                                <Fragment>
                                    <Bold>{selectedRows.length} </Bold> transactions selected
                                </Fragment>
                            ) : (
                                `${selectedRows.length} transactions selected`
                            )
                        ) : bannerType === LABEL.bulkTransactionVerification ? (
                            <Fragment>
                                <Bold>{selectedRows.length}</Bold> transaction selected
                            </Fragment>
                        ) : (
                            `${selectedRows.length} transactions selected`
                        )
                    }
                    toggle={showBanner}
                    handleClose={() => setShowBanner(!showBanner)}
                    primaryButtonObject={{
                        label:
                            bannerType === LABEL.dailySummaryReportFormA
                                ? LABEL.download
                                : bannerType === LABEL.bulkTransactionVerification
                                ? LABEL.verify
                                : LABEL.download,
                        // : selectedRows.length >= 1
                        // ? selectedRows.length < daily.length
                        //     ? LABEL.downloadSelected
                        //     : LABEL.downloadAll
                        // : LABEL.download
                        handlePrimaryBtn: () => handleDownload(),
                    }}
                    secondaryButtonObject={{
                        label: LABEL.cancel,
                        handleSecondaryBtn: () => {
                            setShowBanner(!showBanner);
                            setSelectedRows([]);
                            setShowCheckbox(!showCheckBox);
                            setBannerType('');
                            setDisableResultLimit(false);
                            setDisableSearchBarButtons(!disableSearchBarButtons);
                            handleFilterTableForExportReport('revert');
                        },
                    }}
                    shorthandActions={
                        bannerType !== LABEL.dailySummaryReportFormA &&
                        bannerType != LABEL.ppaOnlineAccountOpeningForm ? (
                            <SelectAllBannerShortcut />
                        ) : (
                            ''
                        )
                    }
                    data-testid="snackBar"
                    testId="branch-banner"
                />

                {/* Confirmation Modal for Transaction Report, Daily Summary Summary and PPA Online AO Form  */}

                {showModal ? (
                    <Modal
                        title={downloadModalContent(bannerType, selectedRows.length)}
                        modalActive={showModal}
                        setModalActive={setShowModal}
                        primaryBtn={{
                            onClick: async () => {
                                window.open(downloadLink, '_blank');
                                updateTrxRefStatus.length !== 0
                                    ? await updateStatusAfterTrxReportDownload(updateTrxRefStatus)
                                    : null;
                                setShowModal(!showModal);
                                setShowBanner(!showBanner);
                                setShowCheckbox(!showCheckBox);
                                setBannerType('');
                                setDisableSearchBarButtons(!disableSearchBarButtons);
                                setSearchbarFilterTags([]);
                                handleFilterTableForExportReport('revert');
                            },
                            label: LABEL.done,
                            primary: true,
                        }}
                        icon={'report-modal'}
                        testId="branch-dailyreport-modal"
                        contentAlignment="center"
                    >
                        {bannerType !== LABEL.transactionsReport && modalMessages.feedback.content}
                    </Modal>
                ) : null}

                {/* Feedback Modal for Bulk Verify Transactions */}
                {bulkModal ? (
                    <Modal
                        title={`${
                            pendingTab.actions.canVerifyTransactions === LABEL.autoAuthorizer
                                ? `${selectedRows.length} ${LABEL.verifyBulkTransactionsMessage.toLowerCase()}`
                                : CE_DASHBOARD_LABELS.DAILY.reviewBulkVerifyModalTitle
                        }`}
                        modalActive={bulkModal}
                        setModalActive={setBulkModal}
                        primaryBtn={{
                            onClick: () => {
                                setBulkModal(!bulkModal);
                                setShowBanner(!showBanner);
                                setShowCheckbox(!showCheckBox);
                                setBannerType('');
                                setDisableSearchBarButtons(!disableSearchBarButtons);
                                setSearchbarFilterTags([]);
                                handleFilterTableForExportReport('revert');
                            },
                            label: LABEL.done,
                            primary: true,
                        }}
                        icon={
                            pendingTab.actions.canVerifyTransactions === LABEL.autoAuthorizer
                                ? 'trx-verification-success'
                                : 'trx-verification-request-submit'
                        }
                        testId="branch-bulkverify-modal"
                        contentAlignment="center"
                    >
                        {modalMessages.feedback.content}
                    </Modal>
                ) : null}

                {/* track order modal */}
                {trackModal ? (
                    <TrackOrderModal
                        modalActive={trackModal}
                        setModalActive={setTrackModal}
                        trackOrder={trackOrder}
                        closeTrackModal={closeTrackModal}
                        trackOrderInput={trackOrderInput}
                        trackSearchOptions={trackSearchOptions()}
                        onTrackOrderSearch={onTrackOrderSearch}
                        setTrackOrderInput={setTrackOrderInput}
                        title="Track Order"
                        testId="track-order-modal"
                    />
                ) : null}

                {/* Confirmation Modal for Bulk Verify Transactions */}
                {confirmVerify.state ? (
                    <Modal
                        title={modalMessages.confirmation.heading}
                        modalActive={confirmVerify.state}
                        setModalActive={handleConfirmModal}
                        primaryBtn={{
                            onClick: () => {
                                handleConfirmModal();
                                loadingHandler();
                                bulkVerifyTransactions();
                            },
                            label: LABEL.yes,
                            primary: true,
                        }}
                        secondaryBtn={{ onClick: handleConfirmModal, label: LABEL.noNo }}
                        testId="transaction-confirmation"
                    >
                        {getModalMessage(selectedRows)}
                    </Modal>
                ) : null}

                {/* Component that contains all the Modals for Transaction Report download progress */}
                <ProgressModal
                    toggleModal={progressModal}
                    setToggleModal={setProgressModal}
                    progressWidth={progressWidth}
                    numberOfReports={selectedRows.length}
                    setProgressWidth={setProgressWidth}
                    timeLeft={Math.floor(timeLeft)}
                    startTime={Math.floor(startTime)}
                    setTimeLeft={setTimeLeft}
                    testId="branchdashboard-progress-modal"
                />
                {/* Component to show download progress for Daily Summary Report */}
                {toggleDailyReportProgress ? (
                    <ReportDownloadProgressModal
                        progressWidth={progressWidth}
                        reportType={LABEL.dailySummaryReportFormA}
                        reportName={''}
                        setToggleModal={setToggleDailyReportProgress}
                        toggleModal={toggleDailyReportProgress}
                        testId={`operational-report-daily-summary-progress-modal`}
                        onCancel={handleCancelDailySummaryReport}
                    />
                ) : null}
                {/* Loading Component */}
                {loading ? <ComponentLoader /> : null}
            </div>
        </Fragment>
    );
};
const InputWrap = styled.div`
    width: 100%;
    max-width: 360px;
    margin-right: 2rem;
    margin-bottom: 2rem;
`;
const SelectWrapper = styled.div`
    min-width: 360px;
    max-width: 600px;
    width: 18.33vw;
    height: 3rem;
`;
export default DashboardBranch;

const Bold = styled.span`
    font-weight: 700;
`;
