import React, { Fragment, FunctionComponent } from 'react';
import { CustomSpacer } from '../../../../../components';
import { Collapsible } from '../../../../../components/molecules/Collapsible/Collapsible';
import { HQ_ROLE } from '../../../../../constants/hqRoleCollapsible';
import { handleChangeData } from '../../utils/functions';
import { CollapsibleContent, ISetSectionTypes } from '../../AddRoles/CollapsibleContent';
import { IReportsSection } from '../../AddRoles/HqPrivileges/hqRolesInitialData';

export interface ReportPrivilegesProps {
    approval: boolean;
    expanded: boolean[];
    reports: IReportsSection;
    handleExpand: (index: number) => void;
    setReports: (data: IReportsSection) => void;
}

export const ReportPrivileges: FunctionComponent<ReportPrivilegesProps> = ({
    approval,
    expanded,
    reports,
    setReports,
    handleExpand,
}: ReportPrivilegesProps) => {
    const handleCheckReports = () => {
        if (approval === false) {
            const temp = { ...reports };
            const newData = handleChangeData(temp);
            setReports(newData as IReportsSection);
        }
    };

    return (
        <Fragment>
            <Collapsible
                checked={reports.isAll}
                checkboxDisabled={approval}
                expanded={expanded[0]}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            checked={reports.isAll}
                            isDisabled={approval}
                            item={HQ_ROLE.reports.data.general}
                            data={reports}
                            setData={setReports as ISetSectionTypes}
                            noDefaultActions
                        />
                    </Fragment>
                }
                handleChecked={handleCheckReports}
                handleExpand={handleExpand}
                index={9}
                isCheckable={true}
                title={HQ_ROLE.reports.title}
                subtitle={HQ_ROLE.reports.subtitle}
                testId="reports-ux-accordion"
                noXMargin={true}
                noMargin={true}
            />
        </Fragment>
    );
};
