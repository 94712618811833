import React, { Fragment, useContext } from 'react';
import { LABEL, EMPTY_STATES } from '../../../../constants';
import { statusStyle, stringToCamelCase } from '../../../../utils';
import { useHistory } from 'react-router-dom';
import * as Routes from '../../../../routes';
import * as STYLES from '../../../../styles';
import { NewActions } from '..';
import { AdvanceTable } from '../../../../components/organisms';
import eddDashboard from '../../../../context/EDDContext/EDDContext';
import CustomItem from './CustomItem';
import { NewDateHeader } from './NewDateHeader';
interface INewProps {
    filters: ISort_FilterInput;
    isFilterApplied?: boolean;
    isSearchResult?: boolean;
    lastUpdatedDropdown: string;
    newTab: INewTab;
    searchInput?: string;
    setFilters: (data: ISort_FilterInput) => void;
    setLastUpdatedDropdown: (value: string) => void;
    setSortInput: (data: ISort_FilterInput) => void;
    sortInput: ISort_FilterInput;
}
const New: React.FC<INewProps> = ({
    filters,
    isFilterApplied,
    isSearchResult,
    lastUpdatedDropdown,
    newTab,
    searchInput,
    setFilters,
    setLastUpdatedDropdown,
    setSortInput,
    sortInput,
}: INewProps) => {
    const { newCases, dateFilter, setDateFilter } = useContext(eddDashboard);
    const history = useHistory();
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortInput.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };
    const columnsOpenCases: ITableColumn[] = [
        {
            key: [{ key: 'caseNo' }],

            viewStyle: {
                width: STYLES.sh112,
                justifyContent: 'center',
                fontWeight: sortInput[0].column === 'caseNo' ? 700 : 400,
            },
            title: LABEL.eddCaseID,
            icon: {
                name: handleSortIcon('caseNo'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('caseNo'),
            titleStyle: {
                fontWeight: sortInput[0].column === 'caseNo' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'clientName' }, { key: 'clientIdNum', textStyle: STYLES.subTitleStyle }],
            viewStyle: {
                width: STYLES.sh168,
            },
            title: LABEL.investorName,
            subtitle: LABEL.idNumber,
            icon: {
                name: handleSortIcon('clientName'),
                size: '1rem',
            },
            customItem: true,
            onPressHeader: () => handleSort('clientName'),
            titleStyle: {
                fontWeight: sortInput[0].column === 'clientName' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'accountNo' }],
            viewStyle: {
                width: STYLES.sh104,
                fontWeight: sortInput[0].column === 'accountNo' ? 700 : 400,
            },
            title: LABEL.accountNumber,
            icon: {
                name: handleSortIcon('accountNo'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('accountNo'),
            titleStyle: {
                fontWeight: sortInput[0].column === 'accountNo' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'agentName' }, { key: 'agentCode', textStyle: STYLES.subTitleStyle }],
            viewStyle: {
                width: STYLES.sh128,
                fontWeight: sortInput[0].column === 'agentName' ? 700 : 400,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortInput[0].column === 'agentName' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('bdmName'),
                size: '1rem',
            },
            key: [{ key: 'bdmName' }, { key: 'bdmCode', textStyle: STYLES.subTitleStyle }],
            viewStyle: {
                width: STYLES.sh128,
                fontWeight: sortInput[0].column === 'bdmName' ? 700 : 400,
            },
            title: LABEL.bdmName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('bdmName'),
            titleStyle: {
                fontWeight: sortInput[0].column === 'bdmName' ? 700 : 400,
            },
        },
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            customItem: true,
            key: [{ key: stringToCamelCase(lastUpdatedDropdown) }],
            viewStyle: {
                width: STYLES.sh104,
            },
            title: lastUpdatedDropdown,
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
            titleStyle: {
                fontWeight: sortInput[0].column === stringToCamelCase(lastUpdatedDropdown) ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: STYLES.sh160,
                fontWeight: sortInput[0].column === 'status' ? 700 : 400,
            },
            title: LABEL.status,
            onPressHeader: () => handleSort('status'),
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
            titleStyle: {
                fontWeight: sortInput[0].column === 'status' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('targetDate'),
                size: '1rem',
            },
            key: [{ key: 'targetDate' }, { key: 'aging', textStyle: STYLES.subTitleStyle }],
            viewStyle: {
                width: STYLES.sh104,
            },
            customItem: true,
            title: LABEL.dueDate,
            subtitle: LABEL.ageing,
            onPressHeader: () => handleSort('targetDate'),
            titleStyle: {
                fontWeight: sortInput[0].column === 'targetDate' ? 700 : 400,
            },
        },
        {
            key: [],
            itemIcon: {
                name: newTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            viewStyle: {
                width: STYLES.sh48,
                justifyContent: 'center',
            },

            title: LABEL.view,
            onClickItem:
                newTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => {
                          // setCaseId(item.rawData.caseId.toString());
                          sessionStorage.setItem('caseId', item.rawData.caseId);
                          history.push(Routes.satisfactoryCase, { tab: 'new' });
                      }
                    : undefined,
            testId: 'new-view-column',
        },
    ];

    const handleSort = (keyName: string) => {
        const tempSort = [...sortInput];
        sortInput.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortInput(tempSort);
    };

    const handleDateFilter = (item: string, value: string) => {
        setLastUpdatedDropdown(item);
        const temp = [...filters];
        let currentFilter = 'lastUpdated';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
            case LABEL.submittedOn:
                currentFilter = 'submittedOn';
                break;
        }
        const tempObj = {
            column: currentFilter,
            value: '',
        };
        temp[0] = tempObj;
        setDateFilter({ ...dateFilter, dateSort: currentFilter });
        setSortInput([{ column: currentFilter, value: value }]);
        setFilters(temp);
    };
    const dateHeaderFilter = (menu: IHeaderMenu) => {
        return (
            <NewDateHeader
                {...menu}
                dropdownData={[
                    lastUpdatedDropdown,
                    // eslint-disable-next-line react/prop-types
                    sortInput[0].column === stringToCamelCase(lastUpdatedDropdown) ? sortInput[0].value : '',
                ]}
                setData={handleDateFilter}
            />
        );
    };
    return (
        <Fragment>
            <AdvanceTable
                data={newCases}
                columns={columnsOpenCases}
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortInput[0].column} {...props} />}
                RenderOptions={(props: ITableOptions) => <NewActions {...props} newTab={newTab} />}
                onEmptyState={
                    isFilterApplied ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateTableOpenCases
                }
                isSearchResult={isSearchResult}
                searchInput={searchInput}
            />
        </Fragment>
    );
};

export default New;
