import moment from 'moment';

export const makeNotificationObject = (message: INotificationProps[]): INotificationObject[] => {
    const notificationArray: INotificationObject[] = [];
    const days: string[] = [];
    message.sort((a, b) => {
        if (moment(a.createdOn, 'x').unix() > moment(b.createdOn, 'x').unix()) {
            return -1;
        } else {
            return 1;
        }
    });
    message.map((item) => {
        const tempObject: INotificationObject = {
            createdOn: '',
            day: '',
            isRead: false,
            isSeen: false,
            message: '',
            notificationId: '',
            searchKey: '',
            searchId: '',
            searchType: '',
            senderName: '',
            source: '',
            title: '',
            updatedAt: '',
        };

        const now = moment();
        const createdAt = moment(item.createdOn, 'x');

        if (now.startOf('day').unix() === createdAt.startOf('day').unix()) {
            if (days.length === 0 || !days.includes('Today')) {
                tempObject.day = 'Today';
                days.push('Today');
            } else {
                tempObject.day = null;
            }
        } else {
            const day = createdAt.startOf('day').format('DD MMMM YYYY');
            if (days.includes(day)) {
                tempObject.day = null;
            } else {
                tempObject.day = day;
                days.push(day);
            }
        }

        tempObject.createdOn =
            moment().unix() === moment(item.createdOn, 'x').unix()
                ? 'Now'
                : moment(item.createdOn, 'x').format('h:mma');
        tempObject.isRead = item.isRead;
        tempObject.isSeen = item.isSeen;
        tempObject.message = item.message;
        tempObject.notificationId = item.notificationId;
        tempObject.searchKey = item.searchKey;
        tempObject.searchType = item.searchType;
        tempObject.senderName = item.senderName;
        tempObject.source = item.source;
        tempObject.title = item.title;
        tempObject.searchLink = item.searchLink;
        tempObject.searchId = item.searchId !== undefined && item.searchId !== null ? item.searchId.toString() : '';
        tempObject.updatedAt = item.updatedAt !== undefined ? moment(item.updatedAt, 'x').format('h:mma') : undefined;
        notificationArray.push(tempObject);
    });
    return notificationArray;
};
