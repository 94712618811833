interface IFilterOptions {
    [key: string]: string[];
}
interface ISearchFilterElement {
    id: string;
    label: string;
    elementType: string;
    options: FilterOption[];
    placeHolder: string;
    value: string;
}
type FilterOption = {
    label: string;
    value: string;
};

export const buildSearchFiltersConfig = (filters: IFilterOptions, tab: string): ISearchFilterElement[][] => {
    const allfundsConfig: ISearchFilterElement[] = [
        {
            id: 'lastUpdated',
            label: 'Date',
            elementType: 'select',
            options: [
                { label: 'Last Updated', value: 'lastUpdated' },
                { label: 'Fund Start Date', value: 'fundStartDate' },
            ],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'dateRange',
            label: 'Date Range',
            elementType: 'dateRange',
            options: [],
            placeHolder: '',
            value: '',
        },
        {
            id: 'fundType',
            label: 'Product Type',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'fundCategory',
            label: 'Product Category',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'issuingHouse',
            label: 'UTMC',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'isSyariah',
            label: 'Type',
            elementType: 'select',
            options: [
                { label: 'Shariah', value: '1' },
                { label: 'Conventional', value: '0' },
            ],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'fundCurrency',
            label: 'Currency',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'fundClass',
            label: 'Fund Class',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'fundStatus',
            label: 'Status',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
    ];
    const ampConfig: ISearchFilterElement[] = [
        {
            id: 'lastUpdated',
            label: 'Date',
            elementType: 'select',
            options: [{ label: 'Last Updated', value: 'lastUpdated' }],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'dateRange',
            label: 'Date Range',
            elementType: 'dateRange',
            options: [],
            placeHolder: '',
            value: '',
        },
        {
            id: 'fundCategory',
            label: 'AMP Category',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'issuingHouse',
            label: 'UTMC',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'riskCategory',
            label: 'Risk Category',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'fundType',
            label: 'Type',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'fundStatus',
            label: 'Status',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
    ];
    const navDistribution: ISearchFilterElement[] = [
        {
            id: 'lastUpdated',
            label: 'Date',
            elementType: 'select',
            options: [{ label: 'Last Updated', value: 'lastUpdated' }],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'dateRange',
            label: 'Date Range',
            elementType: 'dateRange',
            options: [],
            placeHolder: '',
            value: '',
        },
        {
            id: 'issuingHouse',
            label: 'UTMC',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'fundCurrency',
            label: 'Currency',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'fundClass',
            label: 'Fund Class',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
    ];
    const documentsConfig: ISearchFilterElement[] = [
        {
            id: 'lastUpdated',
            label: 'Date',
            elementType: 'select',
            options: [
                { label: 'Last Updated', value: 'lastUpdated' },
                { label: 'Fund Start Date', value: 'fundStartDate' },
            ],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'dateRange',
            label: 'Date Range',
            elementType: 'dateRange',
            options: [],
            placeHolder: '',
            value: '',
        },
        {
            id: 'issuingHouse',
            label: 'UTMC',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'fundCurrency',
            label: 'Currency',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'fundClass',
            label: 'Fund Class',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
    ];
    const formElements: ISearchFilterElement[][] = [];
    let formElementsRow: ISearchFilterElement[] = [];

    switch (tab) {
        case 'allFunds':
            allfundsConfig.map((elm, index) => {
                Object.keys(filters).map((filterKey) => {
                    if (filterKey === elm.id) {
                        filters[elm.id].map((option) =>
                            allfundsConfig[index].options.push({ label: option, value: option }),
                        );
                    }
                });
                formElementsRow.push(elm);
                if (elm.id === 'dateRange' || elm.id === 'isSyariah' || elm.id === 'fundStatus') {
                    formElements.push(formElementsRow);
                    formElementsRow = [];
                }
            });
            break;
        case 'amp':
            ampConfig.map((elm, index) => {
                Object.keys(filters).map((filterKey) => {
                    if (filterKey === elm.id) {
                        filters[elm.id].map((option) =>
                            ampConfig[index].options.push({ label: option, value: option }),
                        );
                    }
                });
                formElementsRow.push(elm);
                if (elm.id === 'dateRange' || elm.id === 'fundType' || elm.id === 'fundStatus') {
                    formElements.push(formElementsRow);
                    formElementsRow = [];
                }
            });
            break;
        case 'navDistribution':
            navDistribution.map((elm, index) => {
                Object.keys(filters).map((filterKey) => {
                    if (filterKey === elm.id) {
                        filters[elm.id].map((option) =>
                            navDistribution[index].options.push({ label: option, value: option }),
                        );
                    }
                });
                formElementsRow.push(elm);
                if (elm.id === 'dateRange' || elm.id === 'fundClass') {
                    formElements.push(formElementsRow);
                    formElementsRow = [];
                }
            });
            break;
        case 'documentsConfig':
            documentsConfig.map((elm, index) => {
                Object.keys(filters).map((filterKey) => {
                    if (filterKey === elm.id) {
                        filters[elm.id].map((option) =>
                            documentsConfig[index].options.push({ label: option, value: option }),
                        );
                    }
                });
                formElementsRow.push(elm);
                if (elm.id === 'dateRange' || elm.id === 'fundClass') {
                    formElements.push(formElementsRow);
                    formElementsRow = [];
                }
            });
            break;
        default:
    }

    return formElements;
};
