/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, graphqlOperation } from 'aws-amplify';
import React, { Fragment, useEffect, useMemo } from 'react';
import { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ROUTES } from '..';
import { CollapsibleDisplay, CustomSpacer, FlexedDiv, TableGroupingTemplates } from '../../../components';
import { CE_DASHBOARD_LABELS, LABEL } from '../../../constants';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';
import { getGroupingFromData, getGroupStyling } from '../../../utils';
import transactionStatusMutation from '../../../_graphql/mutations/branchCE/afterReviewUpdate/transactionStatus';
import { ceCheckerReviewRequest } from '../../../_graphql/mutations/branchCE/ceCheckerReviewRequest/ceCheckerReviewRequest';
import { downloadPPAQuery } from '../../../_graphql/queries/branchCE/transaction/downloadPPA/downloadPPAQuery';
import { printTransactionReportPdf } from '../../../_graphql/queries/branchCE/transaction/printTransactionReport/printTransactionReport';
import { decoupleGroupedTrx } from '../decoupleGroupedTrx';
import { useDashboardTransactionTableColumn } from './useDailyTableColumn';

const modalMessages = {
    confirmation: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
    feedback: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDailyHandler = ({
    dailySummaryState,
    downloadType,
    idTokenHeader,
    loadingHandler,
    pendingTab,
    selectAllFromBanner,
    selectedRows,
    setErrorHandler,
    setErrorMessage,
    setSelectedRows,
    showCheckBox,
    filters,
    setFilters,
    setSubmittedOnDropdown,
    submittedOnDropdown,
}: IDailyProps) => {
    //Context
    const { dailyV2, orderTypePills, getDailySummaryCount } = useContext(DashboardBranchContext);

    const [headerCheckbox, setHeaderCheckbox] = useState(false);
    const [headerCheckboxInter, setHeaderCheckboxInter] = useState(false);
    const [transactionRef, setTransactionRef] = useState<string>('');
    const [activeRowCount, setActiveRowCount] = useState<number>(0);
    const [requestId, setRequestId] = useState<string>('');
    // const [dailyTableData, setDailyTableData] = useState<
    //     (IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[]
    // >([]);
    const dailyTableData = useRef<(IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[]>([]);
    // Modal and form UI states
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [showRerouteRejectModal, setShowRerouteRejectModal] = useState<boolean>(false);
    const [rerouteRejectTransactionRef, setRerouteRejectTransactionRef] = useState<rerouteRejectModal>({
        refs: [],
        type: '',
        viewMore: false,
    });

    const [type, setType] = useState<string>('');
    // states for user permissions
    const [isAutoAuth, setIsAutoAuth] = useState<boolean>(false);

    const history = useHistory();

    // Fn to call transactionStatus mutation
    const verifyTransactions = async (ref: string) => {
        handleConfirmModal();
        loadingHandler();

        try {
            const response: any = await API.graphql(
                graphqlOperation(transactionStatusMutation, {
                    input: {
                        transactionRef: ref,
                        action: 'Approve',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionStatus;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.transactionStatus.data;
            if (result.status === true) {
                loadingHandler();
                setShowFeedbackModal((prevState) => !prevState);
            }
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorHandler();
                setErrorMessage({
                    title: 'Cannot Verify Transactions',
                    message: _error.message,
                    errorCode: _error.errorCode,
                    testId: 'branch-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                setErrorHandler();
            }
        }
    };

    // Fn to call transactionCheckerStatus mutation
    const approveRequest = async (requestId: string) => {
        handleConfirmModal();
        const payload = {
            requestId: requestId,
            action: 'approve',
        };
        loadingHandler();

        try {
            const response: any = await API.graphql(
                graphqlOperation(ceCheckerReviewRequest, {
                    input: payload,
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionCheckerStatus;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = response.data.transactionCheckerStatus.data;
            if (result.status) {
                loadingHandler();
                if (rerouteRejectTransactionRef.type === 'reject' || rerouteRejectTransactionRef.type === 'reroute') {
                    setRerouteRejectTransactionRef({
                        ...rerouteRejectTransactionRef,
                        refs: result.transactionAffected,
                    });
                    setShowRerouteRejectModal(!showRerouteRejectModal);
                } else setShowFeedbackModal((prevState) => !prevState);
            }
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorHandler();
                setErrorMessage({
                    title: 'Cannot Approve Request',
                    message: _error.message,
                    errorCode: _error.errorCode,
                    testId: 'branch-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                setErrorHandler();
            }
        }
    };

    const downloadPPA = async (ref: string) => {
        loadingHandler();
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(downloadPPAQuery, {
                    input: {
                        transactionRef: ref,
                        tab: 'daily',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.ppaDownloadV2;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.ppaDownloadV2.data;

            loadingHandler();
            window.open(result.link, '_blank');
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(_error).includes('errorCode')) {
                setErrorHandler();
                setErrorMessage({
                    title: 'Cannot Download PPA Account Opening Form',
                    message: _error.message,
                    errorCode: _error.errorCode,
                    testId: 'branch-error-modal',
                    errorList: _error.errorList,
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                setErrorHandler();
            }
        }
    };

    // Fn to handle verify transaction action and set modal values
    const handleApproveTransaction = (item: IColumnItemAccordion) => {
        // set confirmation modal data
        modalMessages.confirmation.heading = (
            <span>
                {CE_DASHBOARD_LABELS.DAILY.verifyThisTransaction} <br></br> {item.rawData.orderNo}
                {item.rawData.transactionRef}?
            </span>
        ) as unknown as string;
        const confirmationModalContent = () => {
            if (item.rawData.isVerified || item.rawData.transactionType.toLowerCase() === 'cr')
                return pendingTab.actions.canVerifyTransactions === LABEL.autoAuthorizer
                    ? CE_DASHBOARD_LABELS.DAILY.verifyOnlyThisTransactionMessage
                    : CE_DASHBOARD_LABELS.DAILY.verifyThisTransactionMessageCr;
            else {
                return pendingTab.actions.canVerifyTransactions === LABEL.autoAuthorizer
                    ? CE_DASHBOARD_LABELS.DAILY.verifyThisTransactionMessage
                    : CE_DASHBOARD_LABELS.DAILY.verifyThisTransactionMessageCr;
            }
        };
        modalMessages.confirmation.content = (
            <FlexedDiv direction="column">
                <Subtitle>{confirmationModalContent()}</Subtitle>
                <CustomSpacer space={'.5rem'} />
                <Subtitle>
                    {pendingTab.actions.canVerifyTransactions === LABEL.autoAuthorizer
                        ? CE_DASHBOARD_LABELS.DAILY.yesOrNoVerify
                        : CE_DASHBOARD_LABELS.DAILY.yesOrNoApprove}
                </Subtitle>
            </FlexedDiv>
        );
        setType('verify');
        if (isAutoAuth) {
            // set feedback modal data
            modalMessages.feedback.heading = 'Transactions verified successfully';
            modalMessages.feedback.content = (
                <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px' }}>
                    <Bold>
                        {item.rawData.orderNo}
                        {item.rawData.transactionRef}
                    </Bold>
                    &nbsp;
                    {item.rawData.transactionType.toLowerCase() !== 'cr'
                        ? `has been verified.Adviser will be notified once all transactions in this order has been
                    verified.`
                        : `has been verified. Adviser will be notified `}
                </p>
            );
            modalMessages.feedback.icon = 'trx-verification-success';
        } else {
            // set feedback modal data
            modalMessages.feedback.heading = CE_DASHBOARD_LABELS.DAILY.submitTransactionRequest;
            modalMessages.feedback.content = (
                <p
                    style={{
                        textAlign: 'center',
                        marginBottom: 0,
                        marginTop: '-0.5rem',
                        fontSize: '16px',
                        lineHeight: '1.5rem',
                    }}
                    dangerouslySetInnerHTML={{
                        __html: 'This request has been submitted and <br /> pending for review by checker.',
                    }}
                />
            );
            modalMessages.feedback.icon = 'trx-verification-request-submit';
        }
        setTransactionRef(item.rawData.transactionRef);
        handleConfirmModal();
    };

    // Fn to handle approve transaction verification requests
    const handleApproveReviewRequest = (item: IColumnItemAccordion, action: string) => {
        if (action.toLowerCase() === 'approve request') {
            setRequestId(item.rawData.requestId);
            modalMessages.feedback.icon = 'trx-verification-request-approve';
            switch (item.rawData.approvalType) {
                case 'approve':
                    // set confirmation modal data
                    modalMessages.confirmation.heading = `${CE_DASHBOARD_LABELS.DAILY.approveTransactionRequest}`;
                    modalMessages.confirmation.content = (
                        <FlexedDiv direction="column">
                            {item.rawData.transactionType && item.rawData.transactionType.toLowerCase() !== 'cr' ? (
                                <Fragment>
                                    <Subtitle>{CE_DASHBOARD_LABELS.DAILY.verifyThisTransactionMessage}</Subtitle>
                                    <CustomSpacer space={'.5rem'} />
                                </Fragment>
                            ) : null}

                            <Subtitle>{CE_DASHBOARD_LABELS.DAILY.yesOrNoApprove}</Subtitle>
                        </FlexedDiv>
                    );
                    setType('approve request');
                    // set feedback modal data
                    modalMessages.feedback.heading = `${CE_DASHBOARD_LABELS.DAILY.transactionRequestApproved}`;
                    modalMessages.feedback.content = (
                        <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                            <Subtitle>
                                Request for&nbsp;
                                <Bold>
                                    {item.rawData.orderNo}
                                    {item.rawData.transactionRef}
                                </Bold>
                                &nbsp;{' '}
                                {item.rawData.transactionType.toLowerCase() !== 'cr'
                                    ? `has been approved. Maker will be notified. Adviser will be notified once all
                                transactions in this order has been verified.`
                                    : `has been approved. Maker and Adviser will be notified.`}
                            </Subtitle>
                        </FlexedDiv>
                    );
                    setRerouteRejectTransactionRef({ ...rerouteRejectTransactionRef, type: 'approve' });
                    break;
                case 'reroute':
                    // set confirmation modal data
                    modalMessages.confirmation.heading = `${CE_DASHBOARD_LABELS.DAILY.approveRerouteOrderRequest}`;
                    modalMessages.confirmation.content = (
                        <FlexedDiv direction="column">
                            <Subtitle>{CE_DASHBOARD_LABELS.DAILY.approveRerouteOrderRequestMessage}</Subtitle>
                            <CustomSpacer space={'.5rem'} />
                            <Subtitle>{CE_DASHBOARD_LABELS.DAILY.yesOrNoApprove}</Subtitle>
                        </FlexedDiv>
                    ); // set feedback modal data
                    modalMessages.feedback.heading = `${CE_DASHBOARD_LABELS.DAILY.rerouteRequestApproved}`;
                    setTransactionRef(`${item.rawData.orderNo}`);
                    setRerouteRejectTransactionRef({ ...rerouteRejectTransactionRef, type: 'reroute' });

                    break;
                case 'reject': // set confirmation modal data
                    modalMessages.confirmation.heading = `${CE_DASHBOARD_LABELS.DAILY.approveRejectOrderRequest}`;
                    modalMessages.confirmation.content = (
                        <FlexedDiv direction="column">
                            <Subtitle>{CE_DASHBOARD_LABELS.DAILY.approveRejectOrderRequestMessage}</Subtitle>
                            <CustomSpacer space={'.5rem'} />
                            <Subtitle>{CE_DASHBOARD_LABELS.DAILY.yesOrNoApprove}</Subtitle>
                        </FlexedDiv>
                    ); // set feedback modal data
                    modalMessages.feedback.heading = `${CE_DASHBOARD_LABELS.DAILY.rejectRequestApproved}`;
                    setTransactionRef(`${item.rawData.orderNo} ${item.rawData.transactionRef}`);
                    setRerouteRejectTransactionRef({ ...rerouteRejectTransactionRef, type: 'reject' });

                    break;
            }
        } else {
            switch (item.rawData.approvalType) {
                case 'approve':
                    //Get order type for routing
                    const _orderType = orderTypePills.tags.find((item) => item.active === true);

                    const data = {
                        transactionRef: item.rawData.transactionRef,
                        tab: 'daily',
                        endpoint: 'branchDashboard',
                        isRejectRequest: true,
                    };
                    redirectViewDetails(history.location.pathname, data, _orderType ? _orderType.title : '');
                    break;
                case 'reroute':
                    handleRerouteTransaction(item, true);
                    break;
                case 'reject':
                    handleRejectTransaction(item, true);
                    break;
            }
        }

        setShowConfirmationModal((prevState) => !prevState);
    };
    // Fn to handle reroute request
    const handleRerouteTransaction = (item: IColumnItemAccordion, isRejectRequest?: boolean) => {
        history.push({
            pathname: `${history.location.pathname}/${ROUTES.rerouteTransaction}`,
            state: { data: item.rawData, isReject: isRejectRequest },
        });
    };
    // Fn to handle reject request
    const handleRejectTransaction = (item: IColumnItemAccordion, isRejectRequest?: boolean) => {
        history.push({
            pathname: `${history.location.pathname}/${ROUTES.rejectTransaction}`,
            state: { data: item.rawData, isReject: isRejectRequest },
        });
    };
    const printReport = async (ref: string) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(printTransactionReportPdf, {
                    input: {
                        transactionId: ref,
                        tab: 'daily',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionPdf;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.transactionPdf.data;

            window.open(result.path, '_blank');
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorHandler();
                setErrorMessage({
                    title: 'Cannot Download Transaction Report',
                    message: _error.message,
                    errorCode: _error.errorCode,
                    testId: 'branch-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                setErrorHandler();
            }
        }
    };
    const handlePrintTransactionReport = (item: IColumnItemAccordion) => {
        printReport(item.rawData.transactionRef);
    };

    //Fn to generate reject and reroute transactions feedback modal messages
    const handleRerouteRejectModalMessage = (ref: string) => {
        return (
            <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                {rerouteRejectTransactionRef.type === 'reject' ? (
                    <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px' }}>
                        <Bold>Order {ref.split(' ')[0]}</Bold>
                        &nbsp; has been rejected.
                        <br /> Maker, adviser and CE(HQ) will be notified.
                    </p>
                ) : (
                    <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px' }}>
                        <Bold>Order {ref}</Bold>
                        &nbsp; has been rerouted to the adviser.
                        <br /> Maker, Adviser and CE(HQ) will be notified.
                    </p>
                )}

                {rerouteRejectTransactionRef.refs.length !== 0 ? (
                    <CollapseWrapper removeBorder={rerouteRejectTransactionRef.viewMore}>
                        <CollapsibleDisplay
                            displayTextForExpand={CE_DASHBOARD_LABELS.DAILY.viewMore}
                            displayTextForCollapse={CE_DASHBOARD_LABELS.DAILY.viewLess}
                            textColor="#002043"
                            expanded={rerouteRejectTransactionRef.viewMore}
                            handleExpand={() =>
                                setRerouteRejectTransactionRef({
                                    ...rerouteRejectTransactionRef,
                                    viewMore: !rerouteRejectTransactionRef.viewMore,
                                })
                            }
                            testId="confirm"
                            content={
                                <div>
                                    <Bold style={{ fontSize: '0.75rem' }}>
                                        {rerouteRejectTransactionRef.type === 'reject'
                                            ? `Rejected transactions in this order`
                                            : `Rerouted transactions in this order`}
                                    </Bold>
                                    <TransactionRefList>
                                        {rerouteRejectTransactionRef.refs.map((item, index) => (
                                            <li key={index + 1}>{item}</li>
                                        ))}
                                    </TransactionRefList>
                                </div>
                            }
                            contentStyle={{ textAlign: 'left' }}
                            buttonStyle={{ alignSelf: 'center' }}
                        />
                    </CollapseWrapper>
                ) : null}
            </FlexedDiv>
        );
    };

    const handleViewDetails = (item: IColumnItemAccordion) => {
        //Get order type for routing
        const _orderType = orderTypePills.tags.find((item) => item.active === true);

        const data = {
            transactionRef: item.rawData.transactionRef,
            tab: 'daily',
            endpoint: 'branchDashboard',
            verifyOnly: item.rawData.isVerified,
            orderNo: item.rawData.orderNo,
            url: history.location.pathname,
            approvalType: item.rawData.approvalType,
            rawData: item.rawData,
        };
        redirectViewDetails(history.location.pathname, data, _orderType ? _orderType.title : '');
    };

    const redirectViewDetails = (path: string, data: any, orderType: string) => {
        orderType.toLowerCase() === 'transactions'
            ? history.push(`${path}/${ROUTES.orderSummary}`, data)
            : history.push(`${path}/${ROUTES.orderSummaryChangeRequest}`, data);
    };

    const handleDownloadPPA = (item: IColumnItemAccordion) => {
        downloadPPA(item.rawData.transactionRef);
    };

    const returnRows = (data: TGroupingInfo[], downloadType: string): ITableData[] => {
        let decoupledRows = decoupleGroupedTrx(data);
        const type = setDisabledRows(downloadType);
        if (type.notValue === false || type.notValue === undefined)
            decoupledRows = decoupledRows.filter((item) => {
                return !type.value.includes(item['status'].toLowerCase());
            });
        else {
            switch (downloadType) {
                case LABEL.ppaOnlineAccountOpeningForm:
                    const tempMultipleColumns: ITableData[] = [];
                    decoupledRows.map((item) => {
                        const isValidItem: number[] = [];
                        type.multipleColumns?.map((ele) => {
                            ele.value.includes(item[ele.key].toLowerCase()) ? isValidItem.push(1) : isValidItem.pop();
                        });
                        isValidItem.length === 2 ? tempMultipleColumns.push(item) : null;
                    });

                    decoupledRows = tempMultipleColumns.filter((item) => {
                        return type.value.includes(item['status'].toLowerCase());
                    });
                    break;

                default:
                    decoupledRows = decoupledRows.filter((item) => {
                        return type.value.includes(item['status'].toLowerCase());
                    });
            }
        }

        return decoupledRows;
    };
    const handleSelectHeaderCheck = () => {
        const activeRows = returnRows(dailyV2, downloadType);
        if (activeRows.length !== 0)
            if (selectedRows.length === 0) {
                const update = [...activeRows];
                setSelectedRows(update);
                setHeaderCheckbox(true);
                setHeaderCheckboxInter(false);
                return;
            }
        setSelectedRows([]);
        setHeaderCheckboxInter(false);
        setHeaderCheckbox(false);
    };

    const onRowSelect = (record: ITableData) => {
        const update = [...selectedRows];
        const activeRows = returnRows(dailyV2, downloadType);
        if (update.indexOf(record) === -1) {
            update.push(record);
        } else {
            update.splice(update.indexOf(record), 1);
        }
        if (update.length === activeRows.length) {
            setHeaderCheckbox(true);
            setHeaderCheckboxInter(false);
        }
        if (update.length < activeRows.length) {
            if (update.length > 0) {
                setHeaderCheckboxInter(true);
            } else {
                setHeaderCheckbox(false);
                setHeaderCheckboxInter(false);
            }
        }

        setSelectedRows(update);
    };

    const setDisabledRows = (downloadType: string): IDisabledRows => {
        const rows: IDisabledRows = {
            key: 'status',
            value: ['in review', 'completed', 'rejected', 'void'],
            activeDisabled: false,
        };
        switch (downloadType) {
            case LABEL.transactionsReport:
                rows.activeDisabled = false;
                rows.key = 'status';
                rows.value = [
                    'in review',
                    'completed',
                    'pending approval',
                    'rejected',
                    'void',
                    'br - rerouted',
                    'hq - rerouted',
                ];
                break;
            case LABEL.dailySummaryReportFormA:
                rows.activeDisabled = false;
                rows.key = 'status';
                rows.value = [
                    'in review',
                    'pending',
                    'pending approval',
                    'rejected',
                    'void',
                    'br - rerouted',
                    'hq - rerouted',
                ];
                break;
            case LABEL.bulkTransactionVerification:
                rows.activeDisabled = false;
                rows.key = 'status';
                rows.value = [
                    'completed',
                    'pending',
                    'pending approval',
                    'rejected',
                    'void',
                    'br - rerouted',
                    'hq - rerouted',
                ];
                break;
            case LABEL.ppaOnlineAccountOpeningForm:
                rows.activeDisabled = false;
                rows.notValue = true;
                rows.key = 'status';
                rows.value = ['completed'];
                rows.isMultipleColumns = true;
                rows.multipleColumns = [
                    {
                        key: 'fundType',
                        value: ['prs', 'prs default'],
                    },
                    {
                        key: 'utmc',
                        value: [LABEL.kib.toLowerCase()],
                    },
                ];
                break;
        }
        return rows;
    };
    const setActiveDisabledRows = (downloadType: string): IDisabledRows => {
        const rows: IDisabledRows = {
            key: 'status',
            value: [''],
        };
        switch (downloadType) {
            case LABEL.dailySummaryReportFormA:
                rows.key = 'transactionRef';
                rows.value = dailySummaryState.result;
                break;
        }

        return rows;
    };

    // Fn to toggle confirmation modal
    const handleConfirmModal = () => {
        setShowConfirmationModal((prevState) => !prevState);
    };

    // Fn to toggle feedback modal

    const handleFeedbackModal = () => {
        setShowFeedbackModal((prevState) => !prevState);
        // setApprove(!approve);
    };

    // check page permissions
    const checkPermissions = () => {
        if (
            pendingTab.actions.canVerifyTransactions === LABEL.autoAuthorizer ||
            pendingTab.reviewApproval.canApproveVerify === LABEL.autoAuthorizer
        ) {
            setIsAutoAuth(true);
        }
    };

    const { columns } = useDashboardTransactionTableColumn({
        handleViewDetails,
        filters,
        setFilters,
        setSubmittedOnDropdown,
        showCheckBox,
        submittedOnDropdown,
        pendingTab,
        changeRequestColumn:
            orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request').length !== 0,
    });

    // Fn to call transactionStatus mutation

    useEffect(() => {
        const rows = returnRows(dailyV2, downloadType);
        setHeaderCheckbox(false);
        setHeaderCheckboxInter(false);
        setSelectedRows([]);
        setActiveRowCount(rows.length);
        checkPermissions();
        let data: (IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[] = [];
        data = getGroupingFromData(dailyV2, TableGroupingTemplates, getGroupStyling);
        dailyTableData.current = [...data];
    }, [downloadType, dailyV2]);

    useMemo(() => {
        showCheckBox ? handleSelectHeaderCheck() : null;
    }, [selectAllFromBanner]);
    return {
        handleApproveReviewRequest,
        handleApproveTransaction,
        handleConfirmModal,
        handleDownloadPPA,
        handleFeedbackModal,
        handlePrintTransactionReport,
        handleRejectTransaction,
        handleRerouteRejectModalMessage,
        handleRerouteTransaction,
        handleSelectHeaderCheck,
        handleViewDetails,
        onRowSelect,
        setDisabledRows,
        setShowRerouteRejectModal,
        verifyTransactions,
        approveRequest,
        setActiveDisabledRows,
        columnsDaily: columns,
        headerCheckbox,
        headerCheckboxInter,
        dailyTableData,
        showFeedbackModal,
        showRerouteRejectModal,
        transactionRef,
        showConfirmationModal,
        type,
        requestId,
        activeRowCount,
        modalMessages,
        getDailySummaryCount,
    };
};

const Subtitle = styled.div`
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #333333;
`;
const Bold = styled.span`
    font-weight: 700;
`;
const CollapseWrapper = styled.div<IOnPropStyles>`
    box-shadow: ${(props) => (props.removeBorder ? `inset 0px 1px 0px #66798e` : `unset`)};
    padding-top: ${(props) => (props.removeBorder ? '1rem' : '0')};
    margin-top: 1rem;
`;
const TransactionRefList = styled.ul`
    margin: 0;
    list-style-position: inside;
    padding: 0px 8px;
    font-weight: 400;
    font-size: 0.75rem;
    color: #333333;
    max-height: 9.5rem;
    overflow: auto;
`;
