import React, { Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
//Context
import AuthContext from '../../context/AuthContext/AuthContext';
import ReportContext from '../../context/ReportContext/ReportContext';

//Components
import { AdvanceTable } from '../../components/organisms/AdvancedTable';
import { EMPTY_STATES, LABEL } from '../../constants/labels';

//Types

//Routes
import * as ROUTES from '../../routes';

import { DateColumnItem } from '../../templates';
import styled from 'styled-components';
import { hasReportAccess, getReportDescription } from './reportUtils';
import { TextDarkBlack } from '../../constants';

export interface IReportTabProps {
    testId?: string;
}

export const ReportTab: React.FC<IReportTabProps> = ({ testId }: IReportTabProps) => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { reportData, searchInput } = useContext(ReportContext);
    const history = useHistory();
    const route = history.location.pathname;

    //Permission

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const permissionObject = history.location.pathname.includes('hq')
        ? (parsedPermission.hq.permission as IHqPermissions['permission'])
        : (parsedPermission.branch.permission as IBranchPermissions['permission']);

    const handleAction = (item: IColumnItemAccordion) => {
        if (item.rawData.reportName) {
            const _reportName = item.rawData.reportName;
            switch (_reportName) {
                case LABEL.dailyBookingReport:
                    history.push(route.includes('hq') ? ROUTES.hqGenerateReports : ROUTES.branchGenerateReports, {
                        type: 'booking',
                    });
                    break;
                case LABEL.msfCTAReport:
                    history.push(route.includes('hq') ? ROUTES.hqGenerateReports : ROUTES.branchGenerateReports, {
                        type: 'msf',
                    });
                    break;
                case LABEL.regularInvestmentReport:
                    history.push(route.includes('hq') ? ROUTES.hqGenerateReports : ROUTES.branchGenerateReports, {
                        type: 'regular',
                    });
                    break;
                case LABEL.redemptionSubmissionReport:
                    history.push(route.includes('hq') ? ROUTES.hqGenerateReports : ROUTES.branchGenerateReports, {
                        type: 'redemption',
                    });
                    break;
                case LABEL.branchControlSalesSubmissionReport:
                    history.push(route.includes('hq') ? ROUTES.hqGenerateReports : ROUTES.branchGenerateReports, {
                        type: 'control',
                    });
                    break;
                case LABEL.salesSubmissionStaticSummaryMMReport:
                    history.push(route.includes('hq') ? ROUTES.hqGenerateReports : ROUTES.branchGenerateReports, {
                        type: 'statisticSummary',
                    });
                    break;
                case LABEL.salesSubmissionStaticDetailsReport:
                    history.push(route.includes('hq') ? ROUTES.hqGenerateReports : ROUTES.branchGenerateReports, {
                        type: 'statisticDetails',
                    });
                    break;
                case LABEL.switchingSubmissionReport:
                    history.push(route.includes('hq') ? ROUTES.hqGenerateReports : ROUTES.branchGenerateReports, {
                        type: 'switching',
                    });
                    break;
                case LABEL.epfApplicationStatusReport:
                    history.push(route.includes('hq') ? ROUTES.hqGenerateReports : ROUTES.branchGenerateReports, {
                        type: 'epf',
                    });
                    break;
                case LABEL.userAccessAuditTrailReport:
                    history.push(route.includes('hq') ? ROUTES.hqGenerateReports : ROUTES.branchGenerateReports, {
                        type: 'userAuditAccess',
                    });
                    break;
                case LABEL.userAccountStatusReport:
                    history.push(route.includes('hq') ? ROUTES.hqGenerateReports : ROUTES.branchGenerateReports, {
                        type: 'userAccountStatus',
                    });
                    break;
            }
        }
    };
    const columns: ITableColumn[] = [
        {
            key: [
                {
                    key: 'lastDownloaded',
                    textStyle: { fontWeight: 400 },
                },
            ],
            viewStyle: {
                width: '15.333vw ',
            },
            title: LABEL.lastDownloadedDate,
            titleStyle: {
                fontWeight: 400,
            },
            customItem: true,
        },

        {
            key: [
                {
                    key: 'reportName',
                    textStyle: {
                        fontWeight: 700,
                    },
                },
            ],
            viewStyle: {
                width: '58.333vw',
            },
            title: LABEL.reportName,

            titleStyle: {
                fontWeight: 700,
            },
            customItem: true,
        },
        {
            key: [{ key: 'action' }],
            customItem: true,
            viewStyle: {
                width: '2.667vw',
                padding: 0,
                justifyContent: 'center',
            },
            title: LABEL.action,
            itemIcon: {
                name: 'arrow-right',
                size: '1.75rem',
            },
            onClickItem: (item: IColumnItemAccordion) =>
                hasReportAccess(item.rawData.reportName, permissionObject) ? handleAction(item) : undefined,

            testId: 'action-report-column',
        },
    ];
    const CustomItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
        const renderItems = (key: string) => {
            switch (key) {
                case 'lastDownloaded':
                    return <DateColumnItem data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />;
                case 'reportName':
                    return (
                        <div>
                            <div>
                                <ReportTitle>{data.reportName}</ReportTitle>
                            </div>
                            <LabelDiv>
                                <LabelDesc>{getReportDescription(data.reportName as string)}</LabelDesc>
                            </LabelDiv>
                        </div>
                    );
            }
        };
        return <Fragment>{renderItems(itemKey[0].key)}</Fragment>;
    };

    return (
        <Fragment>
            {searchInput.value ? (
                <TextDarkBlack
                    size="16px"
                    weight="700"
                    style={{ margin: '24px' }}
                >{`${reportData.length} result(s) found for '${searchInput.value}'`}</TextDarkBlack>
            ) : null}
            <AdvanceTable
                data={reportData}
                columns={columns}
                onEmptyState={EMPTY_STATES.emptyStateReportList}
                searchInput={searchInput.value}
                testId={testId}
                RenderItem={(props: ITableCustomItem) => <CustomItem {...props} />}
            />
        </Fragment>
    );
};

export default ReportTab;
const LabelDesc = styled.text`
    font-weight: 400;
    font-size: 12px;
    line-height: 15.6px;
    color: #4d637b;
`;
const ReportTitle = styled.text`
    font-weight: 700;
    font-size: 14px;
    line-height: 18.2px;
    color: #4d637b;
`;

const LabelDiv = styled.div`
    flex: none;
    order: 1;
    flex-grow: 0;
`;
