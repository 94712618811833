export const groubByAdvisersByBranch = (data: AdviserGrouped[]): TGroupingInfo[] => {
    const _main: TGroupingInfo[] = [];
    data.forEach((row) => {
        const _obj: IGroupingBranch = {
            type: 'branchName',
            groupingData: { value: 'branchName', branchId: '' },
            transactionData: { data: [] },
        };
        _obj.groupingData.value = row.branchName;
        _obj.transactionData.data = row.adviserList;

        _main.push(_obj);
    });

    return _main;
};

// Fn to return required property from createdOn filter
export const getCreatedOnFilter = (filters: IColumnValue[], type: string): string => {
    let _type = '';
    const _obj = filters.find(
        (obj) => obj.column.toLowerCase() === 'createdon' || obj.column.toLowerCase() === 'lastupdated',
    );

    if (_obj) {
        type === 'column'
            ? (_type = _obj.column)
            : (_type = _obj.column.toLowerCase() === 'createdon' ? 'Created On' : 'Last Updated');
        return _type;
    } else {
        _type = type === 'column' ? 'createdOn' : 'Created On';
        return _type;
    }
};

export const checkIfFiltersHaveValues = (filters: IColumnValue[]): boolean => {
    return filters.some((obj) => obj.value !== '');
};
