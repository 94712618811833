import React, { Fragment } from 'react';

export const GroupBy = ({ data, groupBy }: ITableGroupBy): JSX.Element => {
    const groupByLabelStyle: React.CSSProperties = {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        fontWeight: 400,
        letterSpacing: '-0.33px',
        color: '#002043',
    };

    const text: string = data[groupBy];

    return text !== undefined ? (
        <Fragment>
            <div className="group" style={{ ...groupByLabelStyle, fontWeight: 400, opacity: 0.5 }} data-testid="group">
                {text}
            </div>
        </Fragment>
    ) : (
        <Fragment />
    );
};
