export const handleDashbordResults = (results: InvestorsDetailsResult[]): ITableData[] => {
    const _tempTableData: ITableData[] = results.map((result) => {
        const _newItem = Object.entries(result).reduce((acc, current) => {
            if (Array.isArray(current[1])) {
                switch (current[0]) {
                    case 'profile':
                        const _user = {
                            clientName: current[1][0].clientName,
                            riskCategory: current[1][0].riskInfo.riskCategory,
                            jointName: current[1][1] !== undefined ? current[1][1].clientName : '',
                        };

                        acc = { ...acc, ..._user };
                        break;
                }
            } else if (current[1] && typeof current[1] !== 'string') {
                Object.assign(acc, current[1]);
            } else {
                switch (current[0]) {
                    case 'lastUpdated':
                        acc = { ...acc, lastUpdated: current[1] };
                        break;
                    case 'transactionRef':
                        acc = { ...acc, transactionRef: current[1] };
                        break;
                }
            }
            return acc;
        }, {});

        return _newItem;
    });

    return _tempTableData;
};

export const groupInvestorsByBranch = (data: InvestorGrouped[]): TGroupingInfo[] => {
    const _main: TGroupingInfo[] = [];
    data.forEach((row) => {
        const _obj: IGroupingBranch = {
            type: 'branchName',
            groupingData: { value: 'branchName', branchId: '' },
            transactionData: { data: [] },
        };
        _obj.groupingData.value = row.branchName;
        _obj.transactionData.data = handleDashbordResults(row.investorList);

        _main.push(_obj);
    });

    return _main;
};

// Fn to return required property from createdOn filter
export const getCreatedOnFilter = (filters: IColumnValue[], type: string): string => {
    let _type = '';
    const _obj = filters.find(
        (obj) => obj.column.toLowerCase() === 'createdon' || obj.column.toLowerCase() === 'lastupdated',
    );

    if (_obj) {
        type === 'column'
            ? (_type = _obj.column)
            : (_type = _obj.column.toLowerCase() === 'createdon' ? 'Acc Opening Date' : 'Last Account Activity');
        return _type;
    } else {
        _type = type === 'column' ? 'createdOn' : 'Acc Opening Date';
        return _type;
    }
};
