/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, Fragment, useContext } from 'react';
import { EMPTY_STATES, Fs12RegSecNavyBlue, LABEL, TextDarkBlack, TextNavyBlue } from '../../constants';
import { Notifications } from './Notifications';
import { SearchBar, Dashboard } from '../../components/organisms';
import { API, graphqlOperation } from 'aws-amplify';
import { IcoMoon } from '../../icons';
import { EmptyState } from '../../templates/EmptyState';
import { ComponentLoader, CustomSpacer } from '../../components';
import { updateInbox } from '../../_graphql/mutations/common/inbox/updateInbox';
import { updateIsSeenDashboard } from '../../utils';

import styled from 'styled-components';
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import InboxContext from '../../context/InboxContext/InboxContext';
import { getSource } from './inboxUtils';

const initialSearchState: ISearchInput = {
    value: '',
    column: 'all',
};
export const Inbox: React.FC = () => {
    //Context
    const { userLoginContext } = useContext(AuthContext);

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const parsedPermissionPrivilege: IAccountManagement =
        parsedPermission.branch.grant !== undefined
            ? parsedPermission.branch.permission.accountManagement
            : parsedPermission.hq.permission.accountManagement;
    const { inbox } = parsedPermissionPrivilege;
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const {
        loading,
        message,
        newMessageCount,
        page,
        pages,
        resultLimit,
        searchInput,
        unreadIds,
        memoizedDashboardInput,
        getInboxData,
        // getSource,
        setPage,
        setResultLimit,
        setSearchInput,
        setTab,
        setUnreadIds,
        getDashboardCallBack,
    } = useContext(InboxContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const sameFilter = JSON.stringify(initialSearchState) !== JSON.stringify(searchInput);

    const searchOrFiltered = sameFilter || searchInput.value !== '';
    const updateAllRead = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(updateInbox, {
                    input: {
                        source: getSource(parsedPermission),
                    },
                }),
                idTokenHeader,
            );

            const resultCheck = response.data.updateInbox;
            if (resultCheck.error !== null) throw resultCheck.error;
            setUnreadIds([]);
            getInboxData();
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Mark All as Read',
                    testId: 'inbox-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const inboxContainer = (
        <InboxContainer>
            {searchInput.value ? (
                <TextDarkBlack size="16px" weight="700" style={{ margin: '24px' }}>
                    {`${newMessageCount} result(s) found for ${searchInput.value}`}
                </TextDarkBlack>
            ) : null}
            {message.length !== 0 ? (
                <Fragment>
                    <LinkBtn
                        disabled={unreadIds.length === 0}
                        onClick={() => handleMarkAllRead()}
                        id="inbox-mark-all-read-btn"
                    >
                        {LABEL.readALL + `${' '}` + `(${newMessageCount})`}
                    </LinkBtn>
                    {message.map((messageItem, index) => {
                        return <Notifications key={index} notification={messageItem} testId={index + 1} />;
                    })}
                </Fragment>
            ) : searchOrFiltered ? (
                <EmptyState iconName={'search'} searchInput={searchInput.value} />
            ) : (
                <EmptyTable>
                    <IcoMoon {...EMPTY_STATES.emptyStateoInboxList.icon} />
                    <CustomSpacer space={'2.5rem'} />
                    <TextNavyBlue weight="700">{EMPTY_STATES.emptyStateoInboxList.title}</TextNavyBlue>
                    <Fs12RegSecNavyBlue>{EMPTY_STATES.emptyStateoInboxList.helperText}</Fs12RegSecNavyBlue>
                </EmptyTable>
            )}
        </InboxContainer>
    );
    const tabs: IDashboardTabs[] =
        inbox.isAll || inbox.actions.canReadNotifications === LABEL.maker
            ? [
                  {
                      name: 'Notifications',
                      table: inboxContainer,
                      testId: 'notifications-tab',
                  },
              ]
            : [];
    // Fn to clear inbox context
    const clearContext = () => {
        setSearchInput({ value: '', column: 'all' });
        setPage(1);
        setTab(0);
        setResultLimit(10);
    };
    const switchTabs = (index: number) => {
        setTab(index);
    };

    const handleNext = () => {
        if (page < pages) {
            setPage(page + 1);
        }
    };

    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleMarkAllRead = () => {
        updateAllRead();
    };
    /** * @start */
    useEffect(() => {
        getDashboardCallBack();
    }, [getDashboardCallBack]);

    useEffect(() => {
        return function cleanup() {
            updateIsSeenDashboard('inboxDashboard', ['notification'], idTokenHeader);
            clearContext();
        };
    }, []);
    return (
        <div>
            <SearchBar
                placeHolder={LABEL.searchPlaceholderInbox}
                filter={false}
                searchTitle={LABEL.inbox}
                searchInput={searchInput}
                onSearch={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    setSearchInput({ ...searchInput, value: e.currentTarget.value });
                }}
                testId="inbox"
                clearSearchInput={() => {
                    setSearchInput({ ...searchInput, value: '' });
                }}
                isSimpleSearch
            />
            <div style={{ position: 'relative' }}>
                <Dashboard
                    tabs={tabs}
                    selectedTab={memoizedDashboardInput.tab}
                    orderCounter={[newMessageCount]}
                    switchTabs={switchTabs}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    page={memoizedDashboardInput.page}
                    maxPage={pages}
                    setResultLimit={setResultLimit}
                    testId="inbox"
                    setCurrentPage={setPage}
                    resultLimit={resultLimit}
                    disableResultLimit={message.length === 0}
                />
                {loading ? <ComponentLoader /> : null}
            </div>
        </div>
    );
};

const InboxContainer = styled.div`
    width: 100%;
    min-height: 62.3vh;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative;
`;
const LinkBtn = styled.button`
    display: inline-block;
    position: absolute;
    right: 1.5rem;
    top: 0;
    font-weight: 700;
    font-size: 0.75rem;
    border: none;
    background-color: transparent;
    outline: none;
    color: #002043;
    line-height: 0.85rem;
    &:hover {
        color: #c61230;
    }
    &:disabled {
        opacity: 0.5;
        &:hover {
            color: #002043;
            cursor: default;
        }
    }
`;
const EmptyTable = styled.div`
    display: flex;
    justify-content: center;
    height: 68vh;
    align-items: center;
    flex-direction: column;
`;
export default Inbox;
