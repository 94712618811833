import { API, graphqlOperation } from 'aws-amplify';
import React, { Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomDropdown, IDropdownItem } from '../../../../components';
import { SYSTEM_ADMIN, LABEL } from '../../../../constants';
import AuthContext from '../../../../context/AuthContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';
import { IcoMoon } from '../../../../icons';
import * as Routes from '../../../../routes';
import { getIdInformation } from '../../../../_graphql/mutations/systemAdmin/getIdInformation';
interface SystemAdminAllUsersActionsProps extends ITableOptions {
    handleAction: (action: string, rawData?: ITableData) => void;
    permissions: IUserManagementUserGroupTab;
}
export const SystemAdminUserGroupsActions = ({
    data,
    handleAction,
    permissions,
}: SystemAdminAllUsersActionsProps): JSX.Element => {
    const history = useHistory();
    const status: string = data.rawData.status.toLowerCase();
    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const canApprove = () => {
        const source = data.rawData.source;

        if (source) {
            if (
                source === 'createGroup' &&
                (permissions.reviewApproval.canApproveCreateNewUserGroup === 'checker' ||
                    permissions.reviewApproval.canApproveCreateNewUserGroup === 'auto-authorizer')
            )
                return true;
            if (
                source === 'editGroup' &&
                (permissions.reviewApproval.canApproveEditUserGroup === 'checker' ||
                    permissions.reviewApproval.canApproveEditUserGroup === 'auto-authorizer')
            )
                return true;
            if (
                source === 'suspendGroup' &&
                (permissions.reviewApproval.canApproveSuspendUserGroup === 'checker' ||
                    permissions.reviewApproval.canApproveSuspendUserGroup === 'auto-authorizer')
            )
                return true;
            if (
                source === 'enableGroup' &&
                (permissions.reviewApproval.canApproveReactivateUserGroup === 'checker' ||
                    permissions.reviewApproval.canApproveReactivateUserGroup === 'auto-authorizer')
            )
                return true;
            if (
                source === 'deleteGroup' &&
                (permissions.reviewApproval.canApproveDeleteUserGroup === 'checker' ||
                    permissions.reviewApproval.canApproveDeleteUserGroup === 'auto-authorizer')
            )
                return true;
        }
        return false;
    };

    const activeMenu: IDropdownItem[] = [];
    if (
        permissions.actions.canSuspendUserGroup === 'maker' ||
        permissions.actions.canSuspendUserGroup === 'auto-authorizer'
    ) {
        activeMenu.push({
            item: SYSTEM_ADMIN.LABEL_SUSPEND_USER_GROUP,
            icon: 'lock',
            handleItem: () => handleAction('suspend user group', data.rawData),
            testId: `usergroup-suspend-btn`,
        });
    }
    if (
        permissions.actions.canDeleteUserGroup === 'maker' ||
        permissions.actions.canDeleteUserGroup === 'auto-authorizer'
    ) {
        activeMenu.push({
            item: SYSTEM_ADMIN.LABEL_DELETE_USER_GROUP,
            icon: 'terminate',
            danger: true,
            handleItem: () => handleAction('delete user group', data.rawData),
            testId: `usergroup-delete-btn`,
        });
    }

    const suspendedMenu: IDropdownItem[] = [];
    if (
        permissions.actions.canReactivateUserGroup === 'maker' ||
        permissions.actions.canReactivateUserGroup === 'auto-authorizer'
    ) {
        suspendedMenu.push({
            item: SYSTEM_ADMIN.LABEL_REACTIVATE_USER_GROUP,
            icon: 'unlock',
            handleItem: () => handleAction('reactivate user group', data.rawData),
            testId: `usergroup-reactivate-btn`,
        });
    }
    if (
        permissions.actions.canDeleteUserGroup === 'maker' ||
        permissions.actions.canDeleteUserGroup === 'auto-authorizer'
    ) {
        suspendedMenu.push({
            item: SYSTEM_ADMIN.LABEL_DELETE_USER_GROUP,
            icon: 'terminate',
            danger: true,
            handleItem: () => handleAction('delete user group', data.rawData),
            testId: `usergroup-delete-btn`,
        });
    }

    const handleReject = async (rawData: ITableData) => {
        if (rawData) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const response: any = await API.graphql(
                    graphqlOperation(getIdInformation, {
                        input: {
                            id: rawData.groupId,
                            viewType: 'groups',
                        },
                    }),
                    idTokenHeader,
                );
                const { data, error } = await response.data.getIdInformation;
                if (data !== null) {
                    const info = JSON.parse(data.result.info);
                    history.push(Routes.editUserGroup, {
                        mode: 'review',
                        data: info,
                        status: rawData.status,
                        source: rawData.source,
                        reject: true,
                    });
                } else throw error;
            } catch (error) {
                const _error = error as IErrorHandling;
                setErrorMessage({
                    ...errorMessage,
                    title: 'Cannot Fetch Request Data',
                    message: _error.message,
                    errorCode: _error.errorCode,
                    testId: 'usergroup-actions-error-modal',
                });
                handleErrorHandler();
            }
        } else {
        }
    };
    const pendingMenu: IDropdownItem[] = [];

    if (status === 'pending approval' && canApprove()) {
        pendingMenu.push({
            item: LABEL.approveRequest,
            icon: 'check',
            handleItem: () => handleAction('approve', data.rawData),
            rawData: data.rawData,
            testId: `usergroup-approve-btn`,
        });

        pendingMenu.push({
            item: LABEL.rejectRequest,
            icon: 'reject',
            danger: true,
            handleItem: () => handleReject(data.rawData),
            rawData: data.rawData,
            testId: `usergroup-reject-btn`,
        });
    }

    const menu = (status: string) => {
        switch (status) {
            case 'active':
                return activeMenu.length > 0 ? activeMenu : undefined;
            case 'suspended':
                return suspendedMenu.length > 0 ? suspendedMenu : undefined;
            case 'pending approval':
                return pendingMenu.length > 0 ? pendingMenu : undefined;
        }
    };
    let items: IDropdownItem[] = [];
    let showAction = false;
    const item = menu(status);

    if (item !== undefined) {
        items = item;
        showAction = true;
    }

    return (
        <Fragment>
            {showAction ? (
                <CustomDropdown items={items} data={data.rawData}>
                    <IcoMoon name="action-menu" size="1.5rem" />
                </CustomDropdown>
            ) : null}
        </Fragment>
    );
};
