/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TableGroupingTemplates } from '../../../components';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';
import { getGroupingFromData, getGroupStyling, statusStyle, stringToCamelCase } from '../../../utils';

import { getGroupedTrx } from '../decoupleGroupedTrx';
import * as ROUTES from '../../../routes';
import { API, graphqlOperation } from 'aws-amplify';
import { createTransactionsRef } from '../../../_graphql/mutations/branchCE/receiveHardcopy/createTransactionsRef';
import { LABEL } from '../../../constants';
import {
    subTitleStyle,
    sh128,
    sh88,
    sh144,
    sh200,
    sh296,
    sh48,
    sh108,
    sh99,
    sh69,
    sh72,
    sh104,
    sh138,
    sh156,
    sh152,
} from '../../../styles';
import { DateHeader } from '../DateHeaderMenu';

interface IHardcopyHandlerProps {
    handleReceived: () => void;
    idTokenHeader?: {
        Authorization: string;
        strategy: string;
    };
    sortData: ISort_FilterInput;
    setSortData: (data: ISort_FilterInput) => void;
    setErrorHandler: () => void;
    setErrorMessage: (error: IErrorHandling) => void;
    hardcopyTab: IHardcopyTab;
    submittedOnDropdown: string;
    setSubmittedOnDropdown: (value: string) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useHardcopyHandler = ({
    handleReceived,
    idTokenHeader,
    sortData,
    setSortData,
    setErrorHandler,
    setErrorMessage,
    hardcopyTab,
    setSubmittedOnDropdown,
    submittedOnDropdown,
}: IHardcopyHandlerProps) => {
    const { hardcopy, orderTypePills, hardcopyCr, dateFilter, setDateFilter } = useContext(DashboardBranchContext);
    const history = useHistory();
    const hardcopyGroupedTable = useRef<(IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[]>([]);
    const [eventClicked, setEventClicked] = useState<boolean>(false);
    const handleViewDetails = (item: IColumnItemAccordion) => {
        const data = {
            orderNo: item.rawData.orderNo,
            tab: 'hardcopy',
            endpoint: 'branchDashboard',
            url: history.location.pathname,
        };
        const trxType = item.rawData.transactionType as string;
        const result = trxType && trxType.toLowerCase() !== 'cr' && trxType.toLowerCase() !== 'change request';
        result
            ? history.push(`${history.location.pathname}/${ROUTES.orderSummary}`, data)
            : history.push(`${history.location.pathname}/${ROUTES.orderSummaryChangeRequest}`, data);
    };
    const handleSort = (keyName: string) => {
        const tempSort = [...sortData];
        sortData.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortData(tempSort);
    };
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortData.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };

    const handleReceiveHardcopy = async (item: IColumnItemAccordion) => {
        setEventClicked(true);
        try {
            const response: any = await API.graphql(
                graphqlOperation(createTransactionsRef, {
                    input: {
                        orderNo: item.rawData.orderNo,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.createTransactionsRef;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.createTransactionsRef.data;
            if (result.status === true) {
                handleReceived();
                setEventClicked(false);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setEventClicked(false);
                setErrorHandler();
                setErrorMessage({
                    title: 'Cannot mark as Received',
                    message: _error.message,
                    errorCode: _error.errorCode,
                    testId: 'branch-error-modal',
                });
            } else {
                setEventClicked(false);
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                setErrorHandler();
            }
        }
    };
    const dateHeaderFilter = (menu: IHeaderMenu) => {
        return (
            <DateHeader
                {...menu}
                dropdownData={[
                    submittedOnDropdown,
                    // eslint-disable-next-line react/prop-types
                    sortData[0].column === stringToCamelCase(submittedOnDropdown) ? sortData[0].value : '',
                ]}
                setData={handleDateFilter}
            />
        );
    };

    const handleDateFilter = (item: string, value: string) => {
        setSubmittedOnDropdown(item);
        let currentFilter = 'submittedOn';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.submittedOn:
                currentFilter = 'submittedOn';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
        }
        setDateFilter({ ...dateFilter, dateSort: currentFilter });
        setSortData([{ column: currentFilter, value: value }]);
    };
    const columnsTransaction: ITableColumn[] = [
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [{ key: stringToCamelCase(submittedOnDropdown) }],
            viewStyle: {
                width: sh108,
            },
            title: submittedOnDropdown,
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
            customItem: true,
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase(submittedOnDropdown) ? 700 : 400,
            },
            itemStyle: {
                position: 'relative',
            },
        },
        {
            icon: {
                name: handleSortIcon('orderNo'),
                size: '1rem',
            },
            key: [{ key: 'orderNo' }],

            viewStyle: {
                width: sh99,
            },
            title: LABEL.orderNo,
            onPressHeader: () => handleSort('transactionRef'),
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionRef' ? 700 : 400,
            },
            customItem: true,
        },
        {
            key: [
                {
                    key: 'transactionType',
                    textStyle: { fontWeight: sortData[0].column === 'transactionType' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: sh88,
            },
            title: LABEL.transTypes,
            titleStyle: {
                fontWeight: sortData[0].column === 'transactionType' ? 700 : 400,
            },
            onPressHeader: () => handleSort('transactionType'),
            icon: {
                name: handleSortIcon('transactionType'),
                size: 16,
            },
        },
        {
            icon: {
                name: handleSortIcon('fundType'),
                size: '1rem',
            },
            key: [{ key: 'fundType', textStyle: { fontWeight: sortData[0].column === 'fundType' ? 700 : 400 } }],
            viewStyle: {
                width: sh69,
            },
            title: LABEL.products,
            onPressHeader: () => handleSort('fundType'),
            titleStyle: {
                fontWeight: sortData[0].column === 'fundType' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('paymentMethod'),
                size: '1rem',
            },
            key: [
                { key: 'paymentMethod', textStyle: { fontWeight: sortData[0].column === 'paymentMethod' ? 700 : 400 } },
            ],
            viewStyle: {
                width: sh72,
            },
            title: LABEL.funding,
            onPressHeader: () => handleSort('paymentMethod'),
            titleStyle: {
                fontWeight: sortData[0].column === 'paymentMethod' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'totalInvestment' }],
            viewStyle: {
                width: sh156,
            },
            title: LABEL.totalAmount,
            icon: {
                name: handleSortIcon('totalInvestment'),
                size: '1rem',
            },
            customItem: true,
            onPressHeader: () => handleSort('totalInvestment'),
            titleStyle: {
                fontWeight: sortData[0].column === 'totalInvestment' ? 700 : 400,
            },
        },
        {
            key: [{ key: 'totalUnits' }],
            viewStyle: {
                width: sh138,
            },
            title: LABEL.totalUnits,
            icon: {
                name: handleSortIcon('totalUnits'),
                size: '1rem',
            },
            customItem: true,
            onPressHeader: () => handleSort('totalUnits'),
            titleStyle: {
                fontWeight: sortData[0].column === 'totalUnits' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: sh104,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh152,
            },
            title: LABEL.status,
            onPressHeader: () => handleSort('status'),
            titleStyle: {
                fontWeight: sortData[0].column === 'status' ? 700 : 400,
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
        {
            key: [],
            viewStyle: {
                width: sh104,
            },
            itemIcon: {
                name: hardcopyTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                hardcopyTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'withhardcopy-view-column',
        },
    ];

    const columnsChangeRequest: ITableColumn[] = [
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [{ key: stringToCamelCase(submittedOnDropdown) }],
            viewStyle: {
                width: sh128,
            },
            title: submittedOnDropdown,
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
            customItem: true,
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase(submittedOnDropdown) ? 700 : 400,
            },
            itemStyle: {
                position: 'relative',
            },
        },
        {
            icon: {
                name: handleSortIcon('orderNo'),
                size: '1rem',
            },
            key: [{ key: 'orderNo' }],

            viewStyle: {
                width: sh144,
            },
            title: LABEL.orderNo,
            onPressHeader: () => handleSort('orderNo'),
            titleStyle: {
                fontWeight: sortData[0].column === 'orderNo' ? 700 : 400,
            },
            customItem: true,
        },
        {
            key: [
                {
                    key: 'transactionType',
                    textStyle: { fontWeight: sortData[0].column === 'transactionType' ? 700 : 400 },
                },
            ],
            viewStyle: {
                width: sh128,
            },
            title: LABEL.transTypes,
            icon: {
                name: 'arrow-down',
                size: 16,
                style: { opacity: '0.2' },
            },
        },
        {
            icon: {
                name: handleSortIcon('riskCategory'),
                size: '1rem',
            },
            key: [
                { key: 'riskCategory', textStyle: { fontWeight: sortData[0].column === 'riskCategory' ? 700 : 400 } },
            ],
            viewStyle: {
                width: sh128,
            },
            title: LABEL.risk,
            onPressHeader: () => handleSort('riskCategory'),
            titleStyle: {
                fontWeight: sortData[0].column === 'riskCategory' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: sh296,
            },
            title: LABEL.adviserName,
            subtitle: LABEL.code,
            onPressHeader: () => handleSort('agentName'),
            titleStyle: {
                fontWeight: sortData[0].column === 'agentName' ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh200,
            },
            title: LABEL.status,
            onPressHeader: () => handleSort('status'),
            titleStyle: {
                fontWeight: sortData[0].column === 'status' ? 700 : 400,
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
        },
        {
            key: [],
            viewStyle: {
                width: sh48,
            },
            itemIcon: {
                name: hardcopyTab.actions.canViewDetails === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                hardcopyTab.actions.canViewDetails === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'daily-view-column',
        },
    ];

    useEffect(() => {
        let _hardcopy = [];
        if (!window.location.pathname.includes('all-branches')) {
            _hardcopy = getGroupedTrx(hardcopy.current, 'orderNo');
        } else {
            _hardcopy =
                orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request').length !== 0
                    ? hardcopyCr.current
                    : getGroupedTrx(hardcopy.current, 'orderNo');
        }
        let data: (IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[] = [];
        data = getGroupingFromData(_hardcopy, TableGroupingTemplates, getGroupStyling, true);
        hardcopyGroupedTable.current = [...data];
    }, [hardcopy.current, hardcopyCr.current]);
    return {
        columnsHardcopy:
            orderTypePills.tags.filter((tag) => tag.active && tag.title === 'Change Request').length !== 0
                ? columnsChangeRequest
                : columnsTransaction,
        hardcopyGrouped: hardcopyGroupedTable,
        handleReceiveHardcopy,
        eventClicked,
    };
};
