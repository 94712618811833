import { ReactText } from 'react';

// export interface ISelectValue {
//     label: string;
//     value: string;
// }

export const dateSortingDropDown: ISelectValue[] = [
    { label: 'Last Updated', value: 'lastUpdated' },
    { label: 'Submitted On', value: 'submittedOn' },
    { label: 'Created On', value: 'createdOn' },
];
export const ProductTypes: ReactText[] = ['UT', 'PRS', 'AMP'];
export const FundingOptions: ReactText[] = ['Cash', 'EPF', 'DDA'];
export const accountTypes: ISelectValue[] = [
    { label: 'Both', value: '' },
    { label: 'Individual', value: 'individual' },
    { label: 'Joint', value: 'joint' },
];
export const transactionTypes: ISelectValue[] = [{ label: 'Sales - AO', value: 'Sales-AO' }];
