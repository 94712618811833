import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import {
    SearchBar,
    PreviousPage,
    DatePicker,
    SelectDropdown,
    AdvanceTable,
    ComponentLoader,
    DashboardNavigationBar,
} from '../../components';
import { CustomButton, CustomSpacer, FlexedDiv, TableGroupingTemplates } from '../../components/atoms';
import {
    EMPTY_STATES,
    Fs12BoldPrimaryBlack,
    TextPureBlack,
    LABEL,
    SYSTEM_ADMIN,
    TrackOrderSearchOptions,
} from '../../constants';
import MultiSelect from '../../components/molecules/MultiSelect/MultiSelect';
import styled from 'styled-components';
import moment from 'moment';
import { sh120, sh128, sh152, sh200, sh56, sh96, subTitleStyle } from '../../styles';
import {
    clearContextOnNavigation,
    getGroupingFromData,
    getGroupStyling,
    statusStyle,
    stringToCamelCase,
    updateIsSeenDashboard,
} from '../../utils';
import CustomItem from './CustomItem';
import { DateHeaderTrackOrder } from './DateHeaderTrackOrder';
import TrackOrderContext from '../../context/TrackOrderContext/TrackOrderContext';
import { API, graphqlOperation } from 'aws-amplify';
import { trackingDashboard } from '../../_graphql/queries/common/trackingDashboard';
import AuthContext from '../../context/AuthContext';
import { useComponentLoader, useDebounce } from '../../customHooks';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import { SearchBarFilterTags } from '../../templates';

interface ITrackOrderDashboardInput {
    page: number;
    resultLimit: number;
    search: ISearchInput;
    filter: ISort_FilterInput;
    sort: ISort_FilterInput;
}

interface ITempFilters {
    branch: string[];
    transactionType: string[];
    batchCutOff: string[];
    status: string[];
}

interface ITempFilterDispatchPayload {
    item: React.ReactText[];
    filter: string;
}
const TrackOrder = (): JSX.Element => {
    const {
        trackOrderInput,
        setTrackOrderInput,
        onTrackOrderSearch,
        trackSearchOptions,
        filters,
        setFilters,
        sortData,
        setSortData,
        initialFilter,
        dateSorting,
        resultLimit,
        resultNumber,
        page,
        createdOrUpdated,
        tempTargetDate,
        setResultLimit,
        setResultNumber,
        setPage,
        setTempTarget,
        setCreatedOrUpdated,
        branchFilters,
        batchCutOffOptions,
        transactionTypeOptions,
        statusOptions,
        setBranchFilters,
        setStatusOptions,
        setTransactionTypeOptions,
        setBatchCutOffOptions,
        previousPageURL,
    } = useContext(TrackOrderContext);
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, setErrorMessage, errorMessage } = useContext(ErrorHandlingContext);
    const { loading, loadingHandler } = useComponentLoader();
    const debouncedSearch = useDebounce(trackOrderInput, 700);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const [trackOrderTableData, setTrackOrderTableData] = useState<
        (IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[]
    >([]);

    const [expand, setExpand] = useState<boolean>(false); //Filter expansion Panel Visibility State
    const [showAllTags, setShowAllTags] = useState<boolean>(false); //FilterTags expansion Panel Visibility State
    const [datePickerInvalidMessage, setDatePickerInvalidMessage] = useState<string>(''); // datepicker error messages state

    const handleTempFilter = (tempFilterState: ITempFilters, payload: ITempFilterDispatchPayload) => {
        const _tempFilter = { ...tempFilterState };
        switch (payload.filter) {
            case 'branch':
            case 'batchCutOff':
            case 'transactionType':
            case 'status':
                _tempFilter[payload.filter] = payload.item as string[];
                break;
            case 'reset':
                (_tempFilter.branch = []), (_tempFilter.batchCutOff = []), (_tempFilter.status = []);
                _tempFilter.transactionType = [];
                break;
        }
        return _tempFilter;
    };

    const [tempFilterState, dispatch] = useReducer(handleTempFilter, {
        branch: filters.branch,
        batchCutOff: filters.batchCutOff,
        status: filters.status,
        transactionType: filters.transactionType,
    });

    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        sortData.map((item) => {
            if (item.column === keyName) {
                const icon = item.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
                sortIcon = icon;
            } else {
                sortIcon = 'arrow-down';
            }
        });
        return sortIcon;
    };

    // Fn to handle table column sorting
    const handleSort = (keyName: string) => {
        const tempSort = [...sortData];
        sortData.map((item) => {
            const sortType = item.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
            tempSort[0].value = sortType;
            tempSort[0].column = keyName;
        });
        setSortData(tempSort);
    };

    /**
     * gives filter value based on state
     * @returns - gives a Filter for the api call
     */
    const getFilterForDashBoard = (Filters: ITrackFilters = filters): ISort_FilterInput => {
        let _filters: ISort_FilterInput = [];
        const _branchFilter = Filters.branch
            .filter((filter) => branchFilters.includes(filter))
            .map((branch): ISearchInput => {
                return {
                    column: 'branchName',
                    value: branch,
                };
            });
        const _transactionFilter = Filters.transactionType
            .filter((filter) => transactionTypeOptions.includes(filter))
            .map((type): ISearchInput => {
                return {
                    column: 'transactionType',
                    value: type,
                };
            });
        const _statusFilter = Filters.status
            .filter((filter) => statusOptions.includes(filter))
            .map((state): ISearchInput => {
                return {
                    column: 'status',
                    value: state,
                };
            });

        const _branchCutOffFilter = Filters.batchCutOff
            .filter((filter) => batchCutOffOptions.includes(filter))
            .map((cutOff) => {
                return {
                    column: 'batchTime',
                    value: cutOff,
                };
            });
        _filters = [..._branchFilter, ..._transactionFilter, ..._statusFilter, ..._branchCutOffFilter];
        if (filters.dateRange) {
            _filters.push({
                column: Filters.date,
                value: Filters.dateRange,
            });
        }
        return _filters;
    };

    const defaultApiInput = {
        page: page,
        resultLimit: resultLimit,
        search: trackOrderInput,
        filter: getFilterForDashBoard(),
        sort: sortData,
    };

    const TRACK_ORDER_COLUMNS: ITableColumn[] = [
        {
            icon: {
                name: 'caret-down',
                size: 16,
            },
            key: [{ key: stringToCamelCase(createdOrUpdated) }],
            viewStyle: {
                width: sh120,
            },
            customItem: true,
            title: createdOrUpdated,
            itemStyle: {
                position: 'relative',
            },
            RenderHeaderMenu: (props: IHeaderMenu) => dateHeaderFilter(props),
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase(createdOrUpdated) ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('transactionRef'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('transactionRef'),
            key: [{ key: 'transactionRef' }, { key: 'orderNo' }, { key: 'newTransaction' }],
            viewStyle: {
                width: sh120,
            },
            title: 'Trans. No.',
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase('transactionRef') ? 700 : 400,
            },
            customItem: true,
        },

        {
            icon: {
                name: handleSortIcon('transactionType'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('transactionType'),
            key: [
                {
                    key: 'transactionType',
                },
            ],
            viewStyle: {
                width: sh96,
            },
            title: 'Trans. Type',
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase('transactionType') ? 700 : 400,
            },
        },
        {
            key: [{ key: 'fundCode' }],
            viewStyle: {
                width: sh120,
            },
            title: 'Fund Code',
            customItem: true,
        },
        {
            key: [{ key: 'totalInvestment' }],
            viewStyle: {
                width: sh152,
            },
            title: 'Total Investments',
            customItem: true,
        },
        {
            icon: {
                name: handleSortIcon('agentName'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('agentName'),
            key: [
                { key: 'agentName', textStyle: { fontWeight: sortData[0].column === 'agentName' ? 700 : 400 } },
                { key: 'agentCode', textStyle: { ...subTitleStyle, wordBreak: 'break-word' } },
            ],
            viewStyle: {
                width: sh128,
            },
            title: 'Adviser Name',
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase('agentName') ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('bdmName'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('bdmName'),
            key: [{ key: 'bdmName' }],
            viewStyle: {
                width: sh128,
            },
            title: 'BDM Name',
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase('bdmName') ? 700 : 400,
            },
        },
        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('status'),
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh200,
            },
            title: 'Status',
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
            titleStyle: {
                fontWeight: sortData[0].column === stringToCamelCase('status') ? 700 : 400,
            },
        },
        {
            key: [{ key: 'viewable' }],
            viewStyle: {
                width: sh56,
            },
            customItem: true,
            title: 'View',
        },
    ];

    /**
     * Handles Reset of filter for track order
     */
    const handleResetFilter = () => {
        setFilters(initialFilter);
        dispatch({ item: [], filter: 'reset' });
        setTempTarget([null, null]);
        const input = { ...defaultApiInput };
        input.filter = [];
        getTrackingData(input);
        setExpand(false);
    };

    /**
     * removing tag from search bar upon filtering
     * @param item - item being removed
     * @param index - index of item being removed
     */
    const handleRemoveFilter = (item: ISelectValue, index: number) => {
        const _apiInput = { ...defaultApiInput };
        const _filter = _apiInput.filter.splice(index, 1);
        const _filters = { ...filters };
        if (item.label === 'Date' || item.label === 'Date Range') {
            _filters.date = initialFilter.date;
            _filters.dateRange = initialFilter.dateRange;
            setTempTarget([null, null]);
        } else {
            switch (_filter[0].column) {
                case 'branchName':
                    _filters.branch.indexOf(_filter[0].value) !== -1
                        ? _filters.branch.splice(_filters.branch.indexOf(_filter[0].value), 1)
                        : null;
                    dispatch({ item: _filters.branch, filter: 'branch' });
                    break;
                case 'transactionType':
                    _filters.transactionType.indexOf(_filter[0].value) !== -1
                        ? _filters.transactionType.splice(_filters.transactionType.indexOf(_filter[0].value), 1)
                        : null;
                    dispatch({ item: _filters.branch, filter: 'transactionType' });
                    break;
                case 'status':
                    _filters.status.indexOf(_filter[0].value) !== -1
                        ? _filters.status.splice(_filters.status.indexOf(_filter[0].value), 1)
                        : null;
                    dispatch({ item: _filters.branch, filter: 'status' });
                    break;
                case 'batchTime':
                    _filters.batchCutOff.indexOf(_filter[0].value) !== -1
                        ? _filters.batchCutOff.splice(_filters.batchCutOff.indexOf(_filter[0].value), 1)
                        : null;
                    dispatch({ item: _filters.branch, filter: 'batchCutOff' });
                    break;
            }
        }
        setFilters(_filters);
        getTrackingData(_apiInput);
    };

    /**
     * function called in {@link searchbarFilterTags} to get the tags to be shown in the search bar when in non-expanded state
     * @returns - array of all the selected filters as {@link ISelectValue}
     */
    const getTagsForSearchBar = (): ISelectValue[] => {
        const getFilterLabel = (column: string) => {
            switch (column) {
                case 'branchName':
                    return SYSTEM_ADMIN.LABEL_BRANCH;
                case 'transactionType':
                    return LABEL.transactionTypeLong;
                case 'status':
                    return LABEL.status;
                case 'batchTime':
                    return LABEL.batchCutOffTime2;
            }
        };
        const _tags: ISelectValue[] = [];
        getFilterForDashBoard().forEach((filter) => {
            if ((filter.column === 'createdOn' || filter.column === 'lastUpdated') && filter.value) {
                const _dateTag: ISelectValue = {
                    label: 'Date',
                    value:
                        filter.column.charAt(0).toUpperCase() +
                        filter.column.slice(1).replace(/[A-Z]/g, (letter, index) => {
                            return index == 0 ? letter.toLowerCase() : ' ' + letter;
                        }),
                };
                const _dateRangeTag: ISelectValue = {
                    label: 'Date Range',
                    value: filter.value
                        .split('~')
                        .map((time) => moment(time, 'x').format('DD/MM/YYYY'))
                        .join('-'),
                };
                _tags.push(_dateTag);
                _tags.push(_dateRangeTag);
            } else if (filter.column !== '') {
                const _tag: ISelectValue = {
                    label: getFilterLabel(filter.column) || filter.column,
                    value: filter.value,
                };
                _tags.push(_tag);
            }
        });
        return _tags;
    };

    /**
     * applies the filter given and calls the api for track order
     */
    const handleApplyFilter = () => {
        // calls apply filter for track order
        setFilters({ ...filters, ...tempFilterState });
        const _apiInput = {
            ...defaultApiInput,
            filter: getFilterForDashBoard({ ...filters, ...tempFilterState }),
        };
        getTrackingData(_apiInput);
        setExpand(false);
    };

    // Common filters
    const handleDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        const temp = [...date] as [moment.Moment, moment.Moment];
        const time: number[] = [];
        temp.map((item) => {
            const milliSeconds = moment(item, '"M/D/YYYY H:mm"').valueOf();
            time.push(milliSeconds);
        });
        const _validFrom = time[0] < moment().add(1, 'day').startOf('day').valueOf();
        const _validTo = time[1] < moment().add(1, 'day').startOf('day').valueOf() && time[1] > time[0];
        // Date range in milliseconds
        if (_validFrom && _validTo) {
            // Date range in milliseconds
            datePickerInvalidMessage !== '' ? setDatePickerInvalidMessage('') : null;
            const range = time.toString().replace(',', '~');
            const _tempFilter = { ...filters };
            _tempFilter.dateRange = range;
            setFilters(_tempFilter);
        } else {
            _validFrom === false
                ? setDatePickerInvalidMessage(
                      `Start Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`,
                  )
                : null;
            time[1] < moment().add(1, 'day').startOf('day').valueOf() === false && _validFrom
                ? setDatePickerInvalidMessage(`End Date cannot be from ${moment().add(1, 'day').format('DD/MM/YYYY')}`)
                : null;
            time[1] < time[0] && _validFrom
                ? setDatePickerInvalidMessage(
                      `The date must be between ${temp[0].format('DD/MM/YYYY')} and ${moment().format('DD/MM/YYYY')}`,
                  )
                : null;
            const tmp = { ...filters };
            tmp.dateRange = '';
            setFilters(tmp);
        }
    };

    /**
     * sets temporary select state for multi select item on open
     * @param item - selected list returned by multi select component
     * @param filter - filter type specified fo each multiSelect
     */
    const handleMultiSelectFilters = (item: React.ReactText[], filter: string) => {
        switch (filter) {
            case 'branch':
            case 'batchCutOff':
            case 'transactionType':
            case 'status':
                dispatch({ item, filter: filter });
                break;
        }
    };
    const handleDateSorting = (value: string | number) => {
        const tmp = { ...filters };
        tmp.date = value.toString();
        setFilters(tmp);
    };

    const handleClearSearchBar = () => {
        setTrackOrderInput({ ...trackOrderInput, value: '' });
    };

    const setLimitOfResult = (limit: number) => {
        if (resultLimit !== limit) {
            const _input = { ...defaultApiInput };
            setResultLimit(limit);
            _input.resultLimit = limit;
            if (resultNumber / limit < page) {
                setPage(1);
                _input.page = 1;
            }
            getTrackingData(_input);
        }
    };

    const handleSetPage = (_page: number) => {
        if (_page !== page) {
            setPage(_page);
            const _input = { ...defaultApiInput, page: _page };
            getTrackingData(_input);
        }
    };

    const handleNextPagination = () => {
        if (Math.ceil(resultNumber / resultLimit) >= page && page < Math.ceil(resultNumber / resultLimit)) {
            setPage(page + 1);
            const _input = { ...defaultApiInput, page: page + 1 };
            getTrackingData(_input);
        }
    };
    const handlePreviousPagination = () => {
        if (Math.ceil(resultNumber / resultLimit) >= 1 && page > 1) {
            setPage(page - 1);
            const _input = { ...defaultApiInput, page: page - 1 };
            getTrackingData(_input);
        }
    };

    const dateHeaderFilter = (menu: IHeaderMenu) => {
        return (
            <DateHeaderTrackOrder
                {...menu}
                dropdownData={[
                    createdOrUpdated,
                    // eslint-disable-next-line react/prop-types
                    sortData[0].column === stringToCamelCase(createdOrUpdated) ? sortData[0].value : '',
                ]}
                setData={handleDateFilter}
            />
        );
    };

    const handleDateFilter = (item: string, value: string) => {
        const _tempSortData = [...sortData];
        (_tempSortData[0].column = stringToCamelCase(item)), (_tempSortData[0].value = value);
        setCreatedOrUpdated(item);
        setSortData(_tempSortData);
    };

    /**
     * function to get tracking data for the dashboard table from trackingDashboard query
     * @param apiInput {@link ITrackOrderDashboardInput} input to be passed into query
     */
    const getTrackingData = async (apiInput: ITrackOrderDashboardInput) => {
        try {
            loadingHandler();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(trackingDashboard, {
                    input: apiInput,
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.trackingDashboard;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            let data: (IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[] = [];
            data = getGroupingFromData(
                resultCheck.data.result.groupedTransactions.grouping,
                TableGroupingTemplates,
                getGroupStyling,
                true,
            );
            setTrackOrderTableData([...data]);
            resultNumber !== resultCheck.data.result.totalResultCount
                ? setResultNumber(resultCheck.data.result.totalResultCount)
                : null;
            setBranchFilters(resultCheck.data.result.filters.branchName);
            setBatchCutOffOptions(resultCheck.data.result.filters.batchTime);
            setTransactionTypeOptions(resultCheck.data.result.filters.transactionType);
            setStatusOptions(resultCheck.data.result.filters.branchStatus);

            resultCheck.data.result.pages < page || resultCheck.data.result.pages < 1
                ? setPage(resultCheck.data.result.page)
                : null;
            loadingHandler();
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Tracking data',
                    testId: 'edd-error-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    const clearContext = () => {
        setPage(1);
        setTrackOrderInput({ ...trackOrderInput, value: '' });
        setFilters(initialFilter);
        setSortData([{ column: 'lastUpdated', value: 'ascending' }]);
        dispatch({ item: [], filter: 'reset' });
        setTempTarget([null, null]);
        setCreatedOrUpdated('Last Updated');
    };
    useEffect(() => {
        getTrackingData(defaultApiInput);
        return function cleanup() {
            window.location.pathname !== '/'
                ? updateIsSeenDashboard('trackingDashboard', ['all'], idTokenHeader)
                : null;
            clearContextOnNavigation('tracking', window.location.pathname) === false ? clearContext() : null;
        };
    }, [debouncedSearch, sortData]);

    const getTags = useMemo(() => getTagsForSearchBar(), [getFilterForDashBoard()]);
    return (
        <>
            <PreviousPage
                title="Track Order"
                subTitle="Select the transaction and it will display the tracking information."
                handleBack={previousPageURL}
            />
            <SearchBar
                expand={expand}
                setExpand={(toggle: boolean) => {
                    if (toggle) {
                        dispatch({ item: filters.branch, filter: 'branch' });
                        dispatch({ item: filters.status, filter: 'status' });
                        dispatch({ item: filters.transactionType, filter: 'transactionType' });
                        dispatch({ item: filters.batchCutOff, filter: 'batchCutOff' });
                    }
                    setExpand(toggle);
                }}
                placeHolder={`Search by ${
                    TrackOrderSearchOptions.find((x) => x.value === trackOrderInput.column)?.item
                }`}
                searchInput={trackOrderInput}
                searchTitle={'Orders'}
                dropDownOptions={trackSearchOptions()}
                data-testid="branch-track-order-searchbar"
                filter={true}
                onSearch={onTrackOrderSearch}
                clearSearchInput={handleClearSearchBar}
                filterTags={getTags}
                showTags={showAllTags}
                disableActionDropdown={expand}
                disabled={loading}
            >
                {expand ? (
                    <>
                        <FlexedDiv direction="column">
                            <CustomSpacer space={'1.5rem'} />
                            <TextPureBlack weight="bold" size="16px">
                                {LABEL.filterOrderBy}
                            </TextPureBlack>
                            <CustomSpacer space={'0.5rem'} />
                            <FilterGrid>
                                <FlexedDiv direction="column" style={{ marginRight: '1rem' }}>
                                    <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                                        {LABEL.dateSorting}
                                    </Fs12BoldPrimaryBlack>
                                    <FilterInputWrapper>
                                        <SelectDropdown
                                            options={dateSorting}
                                            selectedValue={filters.date}
                                            testId={'track-order-filter-date'}
                                            onChange={handleDateSorting}
                                        />
                                    </FilterInputWrapper>
                                </FlexedDiv>

                                <FlexedDiv direction="column">
                                    <Fs12BoldPrimaryBlack style={{ lineHeight: '1.5rem' }}>
                                        {LABEL.dateRange}
                                    </Fs12BoldPrimaryBlack>
                                    <FilterInputWrapper>
                                        <DatePicker
                                            setTargetDate={(date) => {
                                                const start: moment.Moment = date?.[0] as moment.Moment;
                                                const end: moment.Moment = date?.[1] as moment.Moment;
                                                date?.[0] !== null ? start.set({ h: 0, m: 0, s: 0 }) : null;
                                                date?.[1] ? end.set({ h: 23, m: 59, s: 59 }) : null;
                                                // Setting Date range state for Date picker
                                                setTempTarget([start, end] as [
                                                    moment.Moment | null,
                                                    moment.Moment | null,
                                                ]);
                                                handleDate([start, end] as [
                                                    moment.Moment | null,
                                                    moment.Moment | null,
                                                ]);
                                            }}
                                            targetDate={tempTargetDate}
                                            range={true}
                                            width="360"
                                            primaryBorderColor="#002043"
                                            errorMessage={datePickerInvalidMessage}
                                            setErrorMessage={setDatePickerInvalidMessage}
                                        />
                                    </FilterInputWrapper>
                                </FlexedDiv>
                            </FilterGrid>

                            <FilterSplitBorder />

                            <FilterGrid>
                                <FlexedDiv direction="column">
                                    <FilterInputWrapper>
                                        <MultiSelect
                                            checkedState={
                                                expand
                                                    ? tempFilterState.transactionType.filter((filter) => {
                                                          return transactionTypeOptions.includes(filter);
                                                      })
                                                    : filters.transactionType.filter((filter) =>
                                                          transactionTypeOptions.includes(filter),
                                                      )
                                            }
                                            handleMultiSelect={(item: React.ReactText[]) =>
                                                handleMultiSelectFilters(item, 'transactionType')
                                            }
                                            label={LABEL.transactionTypeLong}
                                            placeHolder={LABEL.selectOneOrMore}
                                            options={transactionTypeOptions}
                                            selectId={1}
                                            noOverlay={false}
                                            testId={`track-order-transaction-type-dropdown-filter`}
                                        />
                                    </FilterInputWrapper>
                                </FlexedDiv>

                                <FlexedDiv direction="column">
                                    <FilterInputWrapper>
                                        <MultiSelect
                                            checkedState={
                                                expand
                                                    ? tempFilterState.status.filter((filter) => {
                                                          return statusOptions.includes(filter);
                                                      })
                                                    : filters.status.filter((filter) => statusOptions.includes(filter))
                                            }
                                            handleMultiSelect={(item: React.ReactText[]) =>
                                                handleMultiSelectFilters(item, 'status')
                                            }
                                            label={LABEL.status}
                                            placeHolder={LABEL.selectOneOrMore}
                                            options={statusOptions}
                                            selectId={1}
                                            noOverlay={false}
                                            testId={`track-order-status-dropdown-filter`}
                                        />
                                    </FilterInputWrapper>
                                </FlexedDiv>

                                <FlexedDiv direction="column">
                                    <FilterInputWrapper>
                                        <MultiSelect
                                            checkedState={
                                                expand
                                                    ? tempFilterState.branch.filter((filter) => {
                                                          return branchFilters.includes(filter);
                                                      })
                                                    : filters.branch.filter((filter) => branchFilters.includes(filter))
                                            }
                                            handleMultiSelect={(item: React.ReactText[]) =>
                                                handleMultiSelectFilters(item, 'branch')
                                            }
                                            label={SYSTEM_ADMIN.LABEL_BRANCH}
                                            placeHolder={LABEL.selectOneOrMore}
                                            options={branchFilters}
                                            selectId={1}
                                            noOverlay={false}
                                            testId={`track-order-branch-dropdown-filter`}
                                        />
                                    </FilterInputWrapper>
                                </FlexedDiv>
                                <FlexedDiv direction="column">
                                    <FilterInputWrapper
                                        style={{
                                            pointerEvents: `${batchCutOffOptions.length === 0 ? 'none' : 'auto'}`,
                                            marginTop: '24px',
                                        }}
                                    >
                                        <MultiSelect
                                            checkedState={
                                                expand
                                                    ? tempFilterState.batchCutOff.filter((filter) => {
                                                          return batchCutOffOptions.includes(filter);
                                                      })
                                                    : filters.batchCutOff.filter((filter) =>
                                                          batchCutOffOptions.includes(filter),
                                                      )
                                            }
                                            handleMultiSelect={(item: React.ReactText[]) =>
                                                handleMultiSelectFilters(item, 'batchCutOff')
                                            }
                                            label={LABEL.batchCutOffTime2}
                                            placeHolder={LABEL.selectOneOrMore}
                                            options={batchCutOffOptions}
                                            selectId={1}
                                            noOverlay={false}
                                            disabled={batchCutOffOptions.length === 0}
                                            testId={`track-order-batch-cutoff-dropdown-filter`}
                                        />
                                    </FilterInputWrapper>
                                </FlexedDiv>
                            </FilterGrid>
                            <FlexedDiv style={{ margin: '2rem 0rem' }} justifyContent="center">
                                <CustomButton
                                    onClick={handleResetFilter}
                                    style={{ width: '16.67vw' }}
                                    size="large"
                                    id="brach-reset-btn"
                                >
                                    {LABEL.reset}
                                </CustomButton>
                                <CustomSpacer horizontal={true} space={'1rem'} />
                                <CustomButton
                                    primary={true}
                                    onClick={handleApplyFilter}
                                    style={{ width: '16.67vw' }}
                                    id="brach-apply-btn"
                                    size="large"
                                >
                                    {LABEL.apply}
                                </CustomButton>
                            </FlexedDiv>
                        </FlexedDiv>
                    </>
                ) : (
                    <SearchBarFilterTags
                        tags={getTags}
                        showAll={showAllTags}
                        handleShowAll={() => setShowAllTags(!showAllTags)}
                        handleClearAll={handleResetFilter}
                        handleRemoveTag={handleRemoveFilter}
                        testId="tracking-dashboard"
                    />
                )}
            </SearchBar>

            <div style={{ paddingTop: getTags.length !== 0 ? '172px' : '132px', position: 'relative' }}>
                <ResultWrapper>
                    <DashboardNavigationBar
                        limits={[10, 20, 50]}
                        resultLimit={resultLimit}
                        setResultLimit={setLimitOfResult}
                        page={page}
                        setCurrentPage={handleSetPage}
                        maxPage={Math.ceil(resultNumber / resultLimit)}
                        handleNext={handleNextPagination}
                        handlePrevious={handlePreviousPagination}
                        resultInfoText={`${resultNumber} result(s) found for '${trackOrderInput.value}'`}
                    />

                    <AdvanceTable
                        columns={TRACK_ORDER_COLUMNS}
                        data={trackOrderTableData}
                        onEmptyState={{
                            ...EMPTY_STATES.emptyStateTrackOrder,
                            helperText: (EMPTY_STATES.emptyStateTrackOrder.helperText as string).replace(
                                '<input>',
                                trackOrderInput.value,
                            ),
                        }}
                        RenderItem={(props: ITableCustomItem) => (
                            <CustomItem isSortedColumn={sortData[0].column} {...props} />
                        )}
                        groupedRow={true}
                        isSearchResult
                        searchInput={trackOrderInput.value}
                        testId="track-order-results"
                    />
                </ResultWrapper>
                {loading ? <ComponentLoader /> : null}
            </div>
        </>
    );
};
export default TrackOrder;
const FilterGrid = styled.div`
    display: grid;
    column-gap: 1.25rem;
    grid-template-columns: repeat(3, 1fr);
`;

const FilterInputWrapper = styled.div`
    width: 25vw;
`;

const FilterSplitBorder = styled.div`
    width: 100%;
    border: 1px dashed #cecece;
    margin: 24px 0;
`;

const ResultWrapper = styled.div`
    padding: 24px 0px 40px 0px;
    background: #ffff;
`;
