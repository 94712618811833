import React from 'react';

interface IFilterOptions {
    [key: string]: string[];
}
export interface IMultiSelectElements {
    id: string;
    label: string;
    elementType: string;
    options?: FilterOption[];
    multiSelectOptions?: React.ReactText[];
    placeHolder: string;
    value: string;
}
type FilterOption = {
    label: string;
    value: string;
};
export const buildSearchFiltersConfig = (filters: IFilterOptions): IMultiSelectElements[][] => {
    const investorsFilterConfig: IMultiSelectElements[] = [
        {
            id: 'lastUpdated',
            label: 'Date',
            elementType: 'select',
            options: [
                { label: 'Acc Opening Date', value: 'createdOn' },
                { label: 'Last Account Activity', value: 'lastUpdated' },
            ],
            placeHolder: 'Select one or more',
            value: '',
        },
        {
            id: 'dateRange',
            label: 'Date Range',
            elementType: 'dateRange',
            placeHolder: '',
            value: '',
        },

        {
            id: 'riskCategory',
            label: 'Risk Category',
            elementType: 'multiselect',
            multiSelectOptions: [],
            placeHolder: 'Select one or more',
            value: '',
        },
        {
            id: 'paymentMethod',
            label: 'Funding',
            elementType: 'multiselect',
            multiSelectOptions: [],
            placeHolder: 'Select one or more',
            value: '',
        },
    ];

    const formElements: IMultiSelectElements[][] = [];
    let formElementsRow: IMultiSelectElements[] = [];
    investorsFilterConfig.forEach((elm, index) => {
        Object.keys(filters).forEach((filterKey) => {
            if (filterKey === elm.id) {
                filters[elm.id].forEach((option) => investorsFilterConfig[index].multiSelectOptions?.push(option));
            }
        });
        formElementsRow.push(elm);

        if (elm.id === 'dateRange' || elm.id === 'paymentMethod') {
            formElements.push(formElementsRow);
            formElementsRow = [];
        }
    });

    return formElements;
};
