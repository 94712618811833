import React, { Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { EMPTY_STATES, LABEL, TextDarkBlack } from '../../../constants';
import { AdvanceTable } from '../../../components';
import { NAVActions } from './NAVActions';
import { sh144, sh96, sh120, sh247, sh48 } from '../../../styles';

import UploadsContext from '../../../context/UploadsContext';
import * as Routes from '../../../routes';
import CustomItem from '../Shared/CustomItem';

import AuthContext from '../../../context/AuthContext';
import WebSocketContext from '../../../context/WebSocketContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';
export interface IAllNAVProps {
    allNAVPerUnit: ITableData[];
    permissions: IUserActionsValues;
    getTabIndex: () => void;
    handleDashboardModal: (heading: string, subHeading: string) => void;
    showResultCount?: boolean;
}

export const NAVPerUnit: React.FC<IAllNAVProps> = ({
    allNAVPerUnit,
    permissions,
    getTabIndex,
    handleDashboardModal,
    showResultCount,
}: IAllNAVProps) => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { ws } = useContext(WebSocketContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const { fundStatusMutation, loadingHandler, searchInput, tempState, dispatch } = useContext(UploadsContext);

    const history = useHistory();
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { navPerUnitTab } = parsedPermission.hq.permission.uploads;
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        if (tempState.sortData.column === keyName) {
            const icon = tempState.sortData.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
            sortIcon = icon;
        } else {
            sortIcon = 'arrow-down';
        }
        return sortIcon;
    };
    const allNAVCols: ITableColumn[] = [
        {
            icon: {
                name: handleSortIcon('fileName'),
                size: '1rem',
            },
            key: [{ key: 'fileName', textStyle: { textTransform: 'none', opacity: 1 } }],
            viewStyle: {
                width: sh247,
            },
            title: LABEL.fileName,
            titleStyle: {
                fontWeight: tempState.sortData.column === 'fileName' ? 700 : 400,
            },
            customItem: true,
            onPressHeader: () => handleSort('fileName'),
        },
        {
            icon: {
                name: handleSortIcon('fileSize'),
                size: '1rem',
            },
            key: [{ key: 'fileSize' }],
            viewStyle: {
                width: sh120,
            },
            title: LABEL.fileSize,
            titleStyle: {
                fontWeight: tempState.sortData.column === 'fileSize' ? 700 : 400,
            },
            customItem: true,
            onPressHeader: () => handleSort('fileSize'),
        },
        {
            key: [{ key: 'fileType', textStyle: { textTransform: 'lowercase' } }],
            viewStyle: {
                width: sh120,
            },
            title: LABEL.type,
            customItem: true,
        },
        {
            icon: {
                name: handleSortIcon('uploadedOn'),
                size: '1rem',
            },
            key: [{ key: 'uploadedOn' }],
            viewStyle: {
                width: sh120,
            },
            title: LABEL.uploadedOn,
            titleStyle: {
                fontWeight: tempState.sortData.column === 'uploadedOn' ? 700 : 400,
            },
            customItem: true,
            onPressHeader: () => handleSort('uploadedOn'),
        },
        {
            icon: {
                name: handleSortIcon('lastUpdate'),
                size: '1rem',
            },
            key: [{ key: 'lastUpdate' }],
            viewStyle: {
                width: sh120,
            },
            title: LABEL.lastUpdated,
            titleStyle: {
                fontWeight: tempState.sortData.column === 'lastUpdate' ? 700 : 400,
            },
            customItem: true,
            onPressHeader: () => handleSort('lastUpdate'),
        },
        {
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh144,
            },
            title: LABEL.status,
            titleStyle: { fontWeight: tempState.sortData.column === 'status' ? 700 : 400 },
            customItem: true,
            onPressHeader: () => handleSort('status'),
        },
        {
            key: [{ key: 'remark' }],
            viewStyle: {
                width: sh96,
            },
            title: LABEL.remarks,
            customItem: true,
        },

        {
            key: [],
            viewStyle: {
                width: sh48,
            },
            itemIcon: {
                name: navPerUnitTab.actions.canPreviewNav === LABEL.maker ? 'eye-show' : '',
                size: '1.75rem',
            },
            title: LABEL.view,
            onClickItem:
                navPerUnitTab.actions.canPreviewNav === LABEL.maker
                    ? (item: IColumnItemAccordion) => handleViewDetails(item)
                    : undefined,
            testId: 'nav-view-btn',
        },
    ];
    const handleSort = (keyName: string) => {
        const sortType =
            keyName === tempState.sortData.column
                ? tempState.sortData.value === 'Ascending'
                    ? 'Descending'
                    : 'Ascending'
                : 'Descending';
        dispatch({ type: 'sortData', payload: { column: keyName, value: sortType } });
    };
    // fn to route to import NAV page
    const handleViewDetails = (item: IColumnItemAccordion) => {
        history.push({
            pathname: Routes.financeReviewImportFile,
            state: {
                fundDocumentId: item.rawData.fundDocumentId,
                title: 'NAV',
                status: item.rawData.status,
                tabIndex: getTabIndex(),
            },
        });
    };
    // fn to route to reject page
    const handleReject = (item: IColumnItemAccordion) => {
        history.push({
            pathname: Routes.financeRejectImport,
            state: {
                fundDocumentId: item.rawData.fundDocumentId,
                type: 'NAV',
                tab: 'navperunit',
                tabIndex: getTabIndex(),
            },
        });
    };

    // fn to approve NAV from dashboard
    const handleApprove = async (item: IColumnItemAccordion) => {
        const _checkerModal = {
            fundDocumentId: item.rawData.fundDocumentId,
            tab: 'navperunit',
            status: 'approve',
            remark: '',
        };

        initiateWebsocket() ? handleWebsocket(_checkerModal) : console.log('Unable to establlish WS');
    };
    //Fn to handle websocket listener for fundStatus
    const handleWebsocket = async (checkerModal: IFundApproval) => {
        if (ws !== undefined) {
            fundStatusMutation(checkerModal);
            ws.addEventListener('message', (event) => {
                if (event.data !== 'PONG' && event.data !== 'pong') {
                    const data = JSON.parse(event.data);
                    if (data.body.data !== undefined) {
                        const { result } = data.body.data;
                        if (result.status) {
                            loadingHandler();
                            handleDashboardModal('NAV', 'NAV Data');
                        }
                    }
                    if (data.body.error !== undefined) {
                        const { error } = data.body;
                        setErrorMessage({
                            ...errorMessage,
                            errorCode: error.errorCode,
                            message: error.message,
                            title: `Approving NAV request failed`,
                            testId: 'fund-status-error-modal',
                        });
                        handleErrorHandler();
                        loadingHandler();
                    }
                }
            });
        } else {
            return false;
        }
    };
    //Fn to initiate websocket connection
    const initiateWebsocket = (): boolean => {
        if (ws !== undefined) {
            loadingHandler();
            ws.send('Ping');
            return true;
        } else {
            return false;
        }
    };
    return (
        <Fragment>
            {showResultCount && searchInput.value ? (
                <TextDarkBlack
                    size="16px"
                    weight="700"
                    style={{ margin: '24px' }}
                >{`${allNAVPerUnit.length} result(s) found for '${searchInput.value}'`}</TextDarkBlack>
            ) : null}
            <AdvanceTable
                data={allNAVPerUnit}
                columns={allNAVCols}
                onEmptyState={EMPTY_STATES.emptyStateImportNAV}
                RenderOptions={(props: ITableOptions) => (
                    <NAVActions
                        {...props}
                        handleApprove={handleApprove}
                        permissions={permissions}
                        handleReject={handleReject}
                    />
                )}
                RenderItem={(props: ITableCustomItem) => (
                    <CustomItem isSortedColumn={tempState.sortData.column} {...props} />
                )}
            />
        </Fragment>
    );
};
