import React, { FunctionComponent, useContext } from 'react';
import styled from 'styled-components';

import { Card, FlexedDiv, TextArea } from '../../../components';
import { Fs12BoldPrimaryBlack, LABEL } from '../../../constants';
import EDDContext from '../../../context/EDDContext/EDDContext';
import { IEddRespondsProps } from '../../../context/EDDContext/EddTypes';
import CustomRadioButton from './CustomRadioButton';
import ResponseTemplate from './ResponseTemplate';

declare interface QuestionaryTemplateProps {
    content: IEddRespondsProps[];
    handleInput?: (
        e: React.ChangeEvent<HTMLTextAreaElement>,
        item: { questionId: string; title: string },
        index: number,
    ) => void;
    onlyRemarks: boolean;
    status?: string;
    type: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    remark?: any;
    handleTextBox?: () => void;
    showConclusion: boolean;
}

export const QuestionaryTemplate: FunctionComponent<QuestionaryTemplateProps> = ({
    content,
    handleInput,
    onlyRemarks,
    status,
    type,
    remark,
    handleTextBox,
    showConclusion,
}: QuestionaryTemplateProps) => {
    const { isSelected, setIsSelected } = useContext(EDDContext);
    // const [isSelected, setIsSelected] = useState<boolean[]>([]);

    const handleRadioSelect = (index: number) => {
        setIsSelected((prev) => {
            const updateSelected = [...prev];
            if (updateSelected[index] == undefined) {
                updateSelected[index] = true;
            } else if (updateSelected[index] == true) {
                updateSelected[index] = !updateSelected[index];
                remark[index] = undefined;
            } else {
                updateSelected[index] = true;
            }
            return updateSelected;
        });
    };
    return (
        <ContentContainer>
            {type === 'satisfactory'
                ? content.map((item, j) => {
                      return (
                          <CardStyle key={j}>
                              <Card
                                  key={j}
                                  title={item.title}
                                  subTitle={item.description ? item.description : ''}
                                  customTitle={j + 1 === content.length && showConclusion ? 'Conclusion' : `Q${j + 1}`}
                                  showCustomTitle={true}
                                  titleSize={1}
                                  backgroundColor="#E7EBF1"
                                  isTitleBadge={j + 1 === content.length}
                              ></Card>
                              <SpaceDivider />
                              <ResponseTemplate item={item} onlyRemarks={onlyRemarks} />
                          </CardStyle>
                      );
                  })
                : content &&
                  content.map((item, j) => {
                      return (
                          <CardStyle key={j}>
                              <Card
                                  key={j}
                                  title={item.title}
                                  subTitle={item.description ? item.description : ''}
                                  customTitle={j + 1 === content.length && showConclusion ? 'Conclusion' : `Q${j + 1}`}
                                  showCustomTitle={true}
                                  titleSize={1}
                                  backgroundColor="#E7EBF1"
                                  isTitleBadge={j + 1 === content.length}
                              ></Card>
                              <ResponseTemplate item={item} onlyRemarks={onlyRemarks} status={status} />
                              <SeparatorLineV2 />
                              <FlexedDiv
                                  direction="row"
                                  style={{ width: '6.67vw', marginLeft: '57px', justifyContent: 'space-evenly' }}
                                  data-testid="row"
                                  onClick={() => (handleTextBox ? handleTextBox() : undefined)}
                              >
                                  <CustomRadioButton
                                      selected={isSelected[j]}
                                      onClick={() => handleRadioSelect(j)}
                                      index={j}
                                  />
                                  <Fs12BoldPrimaryBlack>{'Add Remark'}</Fs12BoldPrimaryBlack>
                              </FlexedDiv>
                              {isSelected[j] && (
                                  <TextArea
                                      name={'reason' + j}
                                      label={''}
                                      value={remark[j] && remark[j].remark ? remark[j].remark : ''}
                                      handleInput={
                                          handleInput
                                              ? (e) =>
                                                    handleInput(
                                                        e,
                                                        {
                                                            questionId: item.questionId,
                                                            title: item.title,
                                                        },
                                                        j,
                                                    )
                                              : undefined
                                      }
                                      maxLength={255}
                                      style={{
                                          margin: '26px 0px 48px 65px',
                                          width: '672px',
                                          height: '96px',
                                      }}
                                      placeHolder={LABEL.eddPlaceHolder}
                                  />
                              )}
                          </CardStyle>
                      );
                  })}
        </ContentContainer>
    );
};

declare interface CollapsedHeaderProps {
    container?: string;
}

const ContentContainer = styled.div`
    padding: 1.5rem;
`;
const CardStyle = styled.div`
    padding: 0px 0px 16px 0px;
    max-width: 1153px;
    left: 41px;
    top: 50px;
    background: #ffffff;
    border: 1px solid #ececec;
    box-sizing: border-box;
    border-radius: 16px;
    flex: none;
    margin: 16px 0px;
    max-height: 100%;
`;
const SpaceDivider = styled.div`
    margin-bottom: 16px;
`;
const SeparatorLineV2 = styled.div`
    opacity: 0.1;
    border: 1px solid #333333;
    margin: 32px 0px;
    max-width: 100%;
`;
