export const SearchOptionsHQCE = {
    branch: [
        // { item: 'All', value: 'all' },
        { item: 'Branch Name', value: 'name' },
        // { item: 'Branch Code', value: 'code' },
    ],
    dueDate: [
        { item: 'All', value: 'all' },
        { item: 'Branch Name', value: 'branchName' },
        { item: 'Adviser Name', value: 'agentName' },
        { item: 'Adviser Code', value: 'agentCode' },
        { item: 'Investor Name', value: 'clientName' },
        { item: 'Investor ID', value: 'clientIdNum' },
        { item: 'Order Number', value: 'orderNo' },
        { item: 'User ID', value: 'userId' },
    ],
    branchOffices: [
        { item: 'All', value: 'all' },
        { item: 'Branch Name', value: 'name' },
        { item: 'Branch Code', value: 'code' },
        { item: 'Adviser Name', value: 'agentName' },
        { item: 'Adviser Code', value: 'agentCode' },
    ],
};
