/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { AdvanceTable, FlexedDiv, Modal } from '../../../../components';
import { EMPTY_STATES, SYSTEM_ADMIN, LABEL, TextDarkBlack } from '../../../../constants';
import { sh136, sh160, sh216, sh224, subTitleStyle } from '../../../../styles';
import { SystemAdminUserGroupsActions } from './SystemAdminUserGroupsAction';
import { statusStyle, stringToCamelCase } from '../../../../utils';
import { useHistory } from 'react-router-dom';
import { getIdInformation } from '../../../../_graphql/mutations/systemAdmin/getIdInformation';
import { userUpdateStatus } from '../../../../_graphql/mutations/systemAdmin/userUpdateStatus';
import { DateHeaderSA } from '../AllUsers/DateHeaderSA';
import { IApiData } from '../RolesPermissions/RolesPermissions';

import * as Routes from '../../../../routes';
import AuthContext from '../../../../context/AuthContext';
import SystemAdminContext from '../../../../context/SystemAdminContext/SAContext';
import ErrorHandlingContext from '../../../../context/ErrorHandling/ErrorHandlingContext';
import approveReject from '../../../../_graphql/mutations/systemAdmin/approveReject';
import CustomItem from './CustomItem';

interface SystemAdminUserGroupsProps {
    isFilterApplied?: boolean;
    isSearchResult?: boolean;
    showResultCount?: boolean;
}

export const SystemAdminUserGroups: React.FC<SystemAdminUserGroupsProps> = ({
    isFilterApplied,
    isSearchResult,
    showResultCount,
}: SystemAdminUserGroupsProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [apiData, setApiData] = useState<IApiData>({
        id: '',
        action: '',
        name: '',
        status: '',
        icon: 'user-modal-success',
    });
    const [showModal, setShowModal] = useState<boolean>(false);
    const history = useHistory();
    const [action, setAction] = useState('');
    const [validationModal, setValidationModal] = useState<boolean>(false);
    const [isChecker, setIsChecker] = useState<boolean>(false);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const userManagementPermission: IUserManagement = parsedPermission.hq.permission.userManagement;
    const { userGroupTab } = userManagementPermission;

    const {
        allGroups,
        createdOnDropdown,
        fetchAllUsers,
        searchInput,
        setCreatedOnDropdown,
        setTarget,
        tempState,
        dispatch,
    } = useContext(SystemAdminContext);
    useEffect(() => {
        if (
            userGroupTab.reviewApproval.canApproveCreateNewUserGroup === 'checker' ||
            userGroupTab.reviewApproval.canApproveDeleteUserGroup === 'checker' ||
            userGroupTab.reviewApproval.canApproveEditUserGroup === 'checker' ||
            userGroupTab.reviewApproval.canApproveReactivateUserGroup === 'checker' ||
            userGroupTab.reviewApproval.canApproveSuspendUserGroup === 'checker'
        ) {
            setIsChecker(true);
        } else setIsChecker(false);
    }, [allGroups]);

    const handleUserAction = async (action: string, rawData?: ITableData) => {
        const userAction = action.toLowerCase();
        let apiAction = '';
        switch (userAction) {
            case 'suspend user group':
                apiAction = 'suspend';
                setAction(apiAction);
                break;
            case 'delete user group':
                apiAction = 'delete';
                setAction(apiAction);
                break;
            case 'reactivate user group':
                apiAction = 'enable';
                setAction(apiAction);
                break;
            default:
                apiAction = userAction;
                break;
        }

        if (rawData) {
            const id = rawData ? rawData.groupId : '';

            try {
                const response: any = await API.graphql(
                    graphqlOperation(getIdInformation, {
                        input: {
                            id: id,
                            viewType: 'groups',
                        },
                    }),
                    idTokenHeader,
                );
                const { data, error } = response.data.getIdInformation;
                const info = JSON.parse(data.result.info);
                setApiData({
                    ...apiData,
                    action: apiAction,
                    id: rawData !== undefined ? rawData.groupId : '',
                    status: rawData !== undefined ? rawData.status : '',
                    name: rawData !== undefined ? info.groupName : '',
                    requestId: rawData !== undefined ? rawData.requestId : '',
                    source: rawData !== undefined ? rawData.source : '',
                });

                if (data) {
                    if (info) {
                        switch (apiAction) {
                            case 'suspend':
                            case 'delete':
                            case 'enable':
                                setConfirmModal(true);
                                break;
                            case 'approve': {
                                try {
                                    const response: any = await API.graphql(
                                        graphqlOperation(approveReject, {
                                            input: {
                                                requestId: rawData !== undefined ? rawData.requestId : undefined,
                                                action: apiAction,
                                            },
                                        }),
                                        idTokenHeader,
                                    );

                                    if (response.data.approveReject.data !== null) {
                                        setShowModal(true);
                                    } else {
                                        setErrorMessage({
                                            ...errorMessage,
                                            message: error.message,
                                            errorCode: error.errorCode,
                                            title: 'Cannot Review Request',
                                            testId: 'usergroup-actions-error-modal',
                                        });
                                        handleErrorHandler();
                                    }
                                } catch (error) {}
                            }
                        }
                    }
                }
                if (error) throw error;
            } catch (error) {
                const _error = error as IErrorHandling;
                if (Object.keys(_error).includes('errorCode')) {
                    setErrorMessage({
                        ...errorMessage,
                        message: _error.message,
                        errorCode: _error.errorCode,
                        title: 'Cannot Fetch User Group Data',
                        testId: 'usergroup-actions-error-modal',
                    });
                    handleErrorHandler();
                } else {
                    setErrorMessage({
                        ...errorMessage,
                        message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                        title: 'Something Went Wrong',
                        testId: 'gql-error-modal',
                    });
                    handleErrorHandler();
                }
            }
        } else {
        }
    };

    const validationModalTitle =
        action === 'suspend' ? LABEL.unableToSuspendUserGroup : LABEL.unableToTerminateUserGroup;
    const validationModalSubTitle =
        action === 'suspend' ? LABEL.unableToSuspendUserGroupSubTitle : LABEL.unableToTerminateUserGroupSubTitle;
    const handleItemIcon = (item: IColumnItemAccordion) => {
        const itemIcon: ITableIcon = {
            name: 'edit',
            size: '1.75rem',
        };
        if (userGroupTab.reviewApproval.canApproveEditUserGroup === 'checker' && item.rawData.status === 'Active') {
            itemIcon.name = 'eye-show';
        }
        if (userGroupTab.reviewApproval.canApproveEditUserGroup === 'checker') {
            itemIcon.name = 'eye-show';
        }
        if (item.rawData.status === 'Pending Approval') {
            itemIcon.name = 'eye-show';
        }
        if (item.rawData.status === 'Terminated') {
            itemIcon.name = '';
        }

        return itemIcon;
    };

    const handleRedirect = async (item: IColumnItemAccordion) => {
        const status = item.rawData.status as string;
        try {
            const response: any = await API.graphql(
                graphqlOperation(getIdInformation, {
                    input: {
                        id: item.rawData.groupId,
                        viewType: 'groups',
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.getIdInformation;

            if (data) {
                const info = JSON.parse(data.result.info);

                if (info) {
                    status.toLowerCase() === 'pending approval' || isChecker
                        ? history.push(Routes.editUserGroup, {
                              mode: 'review',
                              data: info,
                              status: item.rawData.status,
                              source: item.rawData.source,
                          })
                        : history.push(Routes.editUserGroup, {
                              mode: 'edit',
                              data: info,
                              status: item.rawData.status,
                              source: item.rawData.source,
                          });
                }
            }
            if (error) throw error;
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Request Data',
                    testId: 'usergroup-actions-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const runUserUpdateStatus = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(userUpdateStatus, {
                    input: {
                        id: apiData.id,
                        action: apiData.action,
                        viewType: 'groups',
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = response.data.userUpdateStatus;
            if (data) {
                setConfirmModal(false);
                setShowModal(true);
                fetchAllUsers();
            }

            if (error) throw error;
        } catch (error) {
            setConfirmModal(false);
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                if (_error.errorCode === 'BO478' || _error.errorCode === 'BO479') setValidationModal(true);
                else {
                    setErrorMessage({
                        ...errorMessage,
                        message: _error.message,
                        errorCode: _error.errorCode,
                        title: 'Cannot Update User Group',
                        testId: 'usergroup-actions-error-modal',
                    });
                    handleErrorHandler();
                }
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    const handlePendingText = () => {
        const modalContent = {
            title: '',
            subTitle: '',
        };
        switch (apiData.source) {
            case 'createGroup':
                modalContent.title =
                    apiData.action === 'approve' ? LABEL.newUserGroupApproved : LABEL.newUserGroupRejected;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${LABEL.newUserGroupApprovedSubtitle}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
            case 'editGroup':
                modalContent.title =
                    apiData.action === 'approve' ? LABEL.userGroupEditApproved : LABEL.userGroupEditRejected;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${LABEL.userGroupEditApprovedSubtitle}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
            case 'suspendGroup':
                modalContent.title =
                    apiData.action === 'approve' ? LABEL.suspendUserGroupApproved : LABEL.suspendUserGroupRejected;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${LABEL.suspendUserGroupApprovedSubtitle}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
            case 'enableGroup':
                modalContent.title =
                    apiData.action === 'approve'
                        ? LABEL.reactivateUserGroupApproved
                        : LABEL.reactivateUserGroupRejected;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${LABEL.reactivateUserGroupApprovedSubtitle}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
            case 'deleteGroup':
                modalContent.title =
                    apiData.action === 'approve' ? LABEL.deleteUserGroupApproved : LABEL.deleteUserGroupRejected;
                modalContent.subTitle =
                    apiData.action === 'approve'
                        ? `${apiData.name} ${LABEL.deleteUserGroupApprovedSubtitle}`
                        : SYSTEM_ADMIN.ADD_ROLE.LABEL_MAKER_NOTIFIED;
                break;
        }
        return modalContent;
    };

    const handleModalText = () => {
        const modalContent = {
            title: '',
            subTitle: '',
            confirmModalTitle: '',
            confirmModalSubTitle: '',
            icon: 'user-modal-success',
        };
        switch (apiData.action) {
            case 'suspend': {
                if (userGroupTab.actions.canSuspendUserGroup === 'maker') {
                    modalContent.title = LABEL.suspendUserGroupRequest;
                    modalContent.subTitle = LABEL.submittedMsg;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_SUSPEND_USER_GROUP_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_SUSPEND_USER_GROUP_MODAL_SUB;
                    modalContent.icon = 'user-request-pending';
                } else {
                    modalContent.title = SYSTEM_ADMIN.LABEL_USER_GROUP_SUSPEND_SUCCESS;
                    modalContent.subTitle = `${apiData.name} ${SYSTEM_ADMIN.LABEL_GROUP_HAS_BEEN_SUSPENDED}`;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_SUSPEND_USER_GROUP_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_SUSPEND_USER_GROUP_MODAL_SUB;
                }
                break;
            }
            case 'delete': {
                if (userGroupTab.actions.canDeleteUserGroup === 'maker') {
                    modalContent.title = LABEL.deleteUserGroupRequest;
                    modalContent.subTitle = LABEL.submittedMsg;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_DELETE_USER_GROUP_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_DELETE_USER_GROUP_MODAL_SUB;
                    modalContent.icon = 'user-request-pending';
                } else {
                    modalContent.title = SYSTEM_ADMIN.LABEL_USER_GROUP_DELETE_SUCCESS;
                    modalContent.subTitle = `${apiData.name} ${SYSTEM_ADMIN.LABEL_GROUP_HAS_BEEN_DELETED}`;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_DELETE_USER_GROUP_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_DELETE_USER_GROUP_MODAL_SUB;
                }
                break;
            }
            case 'enable': {
                if (userGroupTab.actions.canReactivateUserGroup === 'maker') {
                    modalContent.title = LABEL.reactivateUserGroupRequest;
                    modalContent.subTitle = LABEL.submittedMsg;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_REACTIVATE_USER_GROUP_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_REACTIVATE_USER_GROUP_MODAL_SUB;
                    modalContent.icon = 'user-request-pending';
                } else {
                    modalContent.title = SYSTEM_ADMIN.LABEL_USER_GROUP_REACTIVATED_SUCCESS;
                    modalContent.subTitle = `${apiData.name} ${SYSTEM_ADMIN.LABEL_GROUP_HAS_BEEN_ACTIVATED}`;
                    modalContent.confirmModalTitle = SYSTEM_ADMIN.LABEL_REACTIVATE_USER_GROUP_MODAL;
                    modalContent.confirmModalSubTitle = SYSTEM_ADMIN.LABEL_REACTIVATE_USER_GROUP_MODAL_SUB;
                }
                break;
            }
            case 'approve': {
                const pendingText = handlePendingText();
                modalContent.title = pendingText.title;
                modalContent.subTitle = pendingText.subTitle;
                break;
            }
        }
        return modalContent;
    };
    // Fn to handle table column sorting
    const handleSort = (keyName: string) => {
        const tempSort = { ...tempState.sortInput };
        const type = tempState.sortInput.value.toLowerCase() === 'ascending' ? 'descending' : 'ascending';
        tempSort.value = type;
        tempSort.column = keyName;
        dispatch({ type: 'sortInput', payload: tempSort });
    };
    // Fn to handle sort arrow icon
    const handleSortIcon = (keyName: string) => {
        let sortIcon = '';
        if (tempState.sortInput.column === keyName) {
            sortIcon = tempState.sortInput.value.toLowerCase() === 'ascending' ? 'arrow-up' : 'arrow-down';
        } else {
            sortIcon = 'arrow-down';
        }
        return sortIcon;
    };
    const columns: ITableColumn[] = [
        {
            key: [{ key: 'groupName' }],
            onPressHeader: () => handleSort('groupName'),
            viewStyle: {
                width: sh224,
            },
            icon: {
                name: handleSortIcon('groupName'),
                size: '1rem',
            },
            customItem: true,
            title: SYSTEM_ADMIN.LABEL_USER_GROUP_NAME,
            titleStyle: { fontWeight: tempState.sortInput.column === 'groupName' ? 700 : 400 },
        },
        {
            key: [{ key: 'branch' }],
            viewStyle: {
                width: sh216,
            },
            title: SYSTEM_ADMIN.LABEL_BRANCH,
            titleStyle: { fontWeight: tempState.sortInput.column === 'branch' ? 700 : 400 },
            icon: {
                name: handleSortIcon('branch'),
                size: '1rem',
            },
            customItem: true,
            onPressHeader: () => handleSort('branch'),
        },
        {
            key: [
                { key: 'userCount', textStyle: { fontWeight: tempState.sortInput.column === 'userCount' ? 700 : 400 } },
            ],
            viewStyle: {
                width: sh136,
            },
            title: SYSTEM_ADMIN.LABEL_TOTAL_USERS,
            titleStyle: { fontWeight: tempState.sortInput.column === 'userCount' ? 700 : 400 },
            icon: {
                name: handleSortIcon('userCount'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('userCount'),
        },
        {
            key: [
                { key: 'roleCount', textStyle: { fontWeight: tempState.sortInput.column === 'roleCount' ? 700 : 400 } },
            ],
            viewStyle: {
                width: sh136,
            },
            title: SYSTEM_ADMIN.LABEL_NUMBER_OF_ROLES,
            titleStyle: { fontWeight: tempState.sortInput.column === 'roleCount' ? 700 : 400 },
            icon: {
                name: handleSortIcon('roleCount'),
                size: '1rem',
            },
            onPressHeader: () => handleSort('roleCount'),
        },
        {
            key: [
                {
                    key: 'createdOn',
                    textStyle: {
                        fontWeight: tempState.sortInput.column === stringToCamelCase(createdOnDropdown) ? 700 : 400,
                    },
                },
                { key: 'createdOnTime', textStyle: subTitleStyle },
            ],
            viewStyle: {
                width: '8.47vw',
            },
            title: createdOnDropdown,
            titleStyle: { fontWeight: tempState.sortInput.column === stringToCamelCase(createdOnDropdown) ? 700 : 400 },
            icon: {
                name: 'caret-down',
                size: '1rem',
            },
            RenderHeaderMenu: () => handleCreatedOn(),
        },
        {
            key: [{ key: 'status' }],
            viewStyle: {
                width: sh160,
            },
            title: SYSTEM_ADMIN.LABEL_STATUS,
            titleStyle: { fontWeight: tempState.sortInput.column === 'status' ? 700 : 400 },
            icon: {
                name: handleSortIcon('status'),
                size: '1rem',
            },
            itemTextStyle: (item: IColumnItemAccordion) => statusStyle(item, 'status'),
            onPressHeader: () => handleSort('status'),
        },
        {
            key: [],
            getItem: handleItemIcon,
            viewStyle: {
                width: '3.19vw',
                paddingLeft: '0.5rem',
            },
            title: SYSTEM_ADMIN.LABEL_EDIT_VIEW,
            onClickItem: (item: IColumnItemAccordion) => {
                if (handleItemIcon(item).name !== '') {
                    handleRedirect(item);
                } else {
                    undefined;
                }
            },
            testId: 'usergroups-action-btn',
        },
    ];

    const modalText = handleModalText();
    const handleCreatedOn = () => {
        return (
            <DateHeaderSA
                dropdownData={[
                    createdOnDropdown,
                    tempState.sortInput.column === stringToCamelCase(createdOnDropdown)
                        ? tempState.sortInput.value
                        : '',
                ]}
                setData={handleDateFilter}
            />
        );
    };
    const handleDateFilter = (item: string, value: string) => {
        setCreatedOnDropdown(item);
        let currentFilter = 'createdOn';
        switch (item) {
            case LABEL.lastUpdated:
                currentFilter = 'lastUpdated';
                break;
            case LABEL.createdOn:
                currentFilter = 'createdOn';
                break;
        }
        const tempObj = {
            column: currentFilter,
            value: '',
        };
        // setDateFilter({ ...dateFilter, dateSort: currentFilter });
        dispatch({ type: 'date', payload: currentFilter });
        setTarget(tempObj);
        dispatch({ type: 'sortInput', payload: { column: currentFilter, value: value } });
    };
    return (
        <Fragment>
            {showResultCount && searchInput.value ? (
                <TextDarkBlack
                    size="16px"
                    weight="700"
                    style={{ margin: '24px' }}
                >{`${allGroups.length} result(s) found for '${searchInput.value}'`}</TextDarkBlack>
            ) : null}
            <AdvanceTable
                data={allGroups}
                columns={columns}
                RenderOptions={(props: ITableOptions) => (
                    <SystemAdminUserGroupsActions
                        // {...props}
                        data={props.data}
                        handleAction={handleUserAction}
                        permissions={userGroupTab}
                    />
                )}
                onEmptyState={
                    isFilterApplied
                        ? EMPTY_STATES.emptyStateFilterNoResultGroups
                        : EMPTY_STATES.emptyStateTableUserGroups
                }
                RenderItem={(props: ITableCustomItem) => (
                    <CustomItem isSortedColumn={tempState.sortInput.column} {...props} />
                )}
                isSearchResult={isSearchResult}
                searchInput={searchInput.value}
                testId="systemadmin"
            />
            {confirmModal ? (
                <Modal
                    modalActive={confirmModal}
                    setModalActive={setConfirmModal}
                    title={modalText.confirmModalTitle}
                    primaryBtn={{
                        onClick: () => {
                            runUserUpdateStatus();
                        },
                        label: SYSTEM_ADMIN.BUTTON_YES,
                        primary: true,
                        size: 'large',
                    }}
                    secondaryBtn={{
                        onClick: () => {
                            setConfirmModal(false);
                        },
                        label: SYSTEM_ADMIN.BUTTON_NO,
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="left"
                    testId="user-groups-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'left' }}>
                        {modalText.confirmModalSubTitle}
                    </FlexedDiv>
                </Modal>
            ) : null}
            {showModal ? (
                <Modal
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    title={modalText.title}
                    primaryBtn={{
                        onClick: () => {
                            setShowModal(false);
                            history.push(Routes.dashboardSystemAdmin, { tab: 'User Groups' });
                            fetchAllUsers();
                        },
                        label: 'Done',
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="user-groups-success-modal"
                    icon={modalText.icon}
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {modalText.subTitle}
                    </FlexedDiv>
                </Modal>
            ) : null}
            {validationModal ? (
                <Modal
                    modalActive={validationModal}
                    setModalActive={setValidationModal}
                    title={validationModalTitle}
                    primaryBtn={{
                        onClick: () => {
                            setShowModal(false);
                            setValidationModal(false);
                            history.push(Routes.dashboardSystemAdmin, { tab: 'User Groups' });
                        },
                        label: 'Okay',
                        primary: true,
                        size: 'large',
                    }}
                    contentAlignment="center"
                    testId="validation-modal"
                    icon="suspend-failed"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {validationModalSubTitle}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};
