/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { caseDetailsStatusQuery } from '../../_graphql/queries/edd/caseDetails/caseDetailsStatus';
import { getRequestDetailsCEApprove } from '../../_graphql/queries/inbox/getRequestDetailsCEApprove';
import { FlexedDiv, Modal, Avatar } from '../../components';
import { LABEL } from '../../constants';
import { getRequestDetailsCEOthers } from '../../_graphql/queries/inbox/getRequestDetailsCEOthers';
import { getSystemAdminInboxData } from '../../_graphql/queries/inbox/getSystemAdminInboxData';
import { getInitials, userColorPicker } from '../../utils';
import { IcoMoon } from '../../icons';

import * as ROUTES from '../../routes';
import AuthContext from '../../context/AuthContext';
import getRequestDetailsFundUpload from '../../_graphql/queries/inbox/getRequestDetailsFundUpload';
import styled from 'styled-components';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
import { getRequestDetailsCEApproveCr } from '../../_graphql/queries/inbox/getRequestDetailsCEApproveCr';
import { getSource, getUpdateRead } from './inboxUtils';
import { useComponentLoader } from '../../customHooks';
import InboxContext from '../../context/InboxContext/InboxContext';

type notificationStyleProps = {
    isExpand?: boolean;
    isUnRead?: boolean;
};
interface CustomNotificationProps {
    notification: INotificationObject;
    testId?: number;
}

const inboxModal = {
    title: '',
    subTitle: LABEL.requestApprovedMessage,
    label: LABEL.viewDetails,
    icon: 'inbox-approved',
};
export const Notifications: React.FC<CustomNotificationProps> = ({ notification, testId }: CustomNotificationProps) => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const {
        day,
        searchType,
        notificationId,
        searchKey,
        searchId,
        searchLink,
        title,
        senderName,
        source,
        isRead,
        isSeen,
        message,
        createdOn,
    } = notification;
    const { getDashboardCallBack } = useContext(InboxContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const [expand, setExpand] = useState<boolean>(false);
    const history = useHistory();
    const [requestApprovedModal, setRequestApprovedModal] = useState<boolean>(false);

    // a state for when it is read
    const [isReadUpdated, setIsReadUpdated] = useState<boolean>(false);

    const [eventType, setEventType] = useState<'approved' | 'rejected'>();
    const [redirectState, setRedirectState] = useState<string>('');

    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { systemSettings } = parsedPermission.hq.permission;

    // Error handling and loading states
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const { loadingHandler } = useComponentLoader();

    const branchUser: boolean = parsedPermission.branch.grant !== undefined ? true : false;
    /** To check whether the routes to be redirected are hq or branch routes */
    const getRoute = () => {
        let temp = '';
        branchUser ? (temp = ROUTES.branchInbox) : (temp = ROUTES.hqInbox);
        return temp;
    };

    const updateRead = async (notificationIds: string) => {
        //await API call

        async function fetchData() {
            try {
                loadingHandler();
                const response = await getUpdateRead(notificationIds, idTokenHeader);
                const data = response?.data?.result;
                const error = response?.error;
                if (data) {
                    const resultCheckStatus = data.status;
                    if (resultCheckStatus === true) {
                        getDashboardCallBack();
                    }
                } else {
                    throw error;
                }
            } catch (error) {
                const _error = error as IErrorHandling;
                if (Object.keys(_error).includes('errorCode')) {
                    setErrorMessage({
                        ...errorMessage,
                        message: _error.message,
                        errorCode: _error.errorCode,
                        title: 'Cannot Mark Notification as Read',
                        testId: 'notifications-user-error-modal',
                    });
                    handleErrorHandler();
                } else {
                    setErrorMessage({
                        errorCode: '',
                        message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                        title: 'Something Went Wrong',
                        testId: 'gql-error-modal',
                    });
                    handleErrorHandler();
                }
            }
        }
        await fetchData();
    };
    /** Mark the notification as read i.e changes the background color and update the isRead BE flag */
    const markRead = () => {
        updateRead(notificationId);
    };
    const fundObj = { fundDocumentId: '', title: 'Documents', status: 'Pending Approval', fromInbox: false };
    const rerouteState: ITableData = {
        status: 'Pending Approval',
        transactionRef: '',
        approvalType: '',
        requestId: '',
        fromInbox: false,
    };
    const systemAdminObj = { id: '', source: '', status: 'pending' };
    /** @apis to find approval status */
    //  CE
    const getApprovalStatusEventApprove = async (ref: string, requestId: string, isCR?: boolean) => {
        // loadingHandler();
        try {
            const query = isCR ? getRequestDetailsCEApproveCr : getRequestDetailsCEApprove;
            const response: any = await API.graphql(
                graphqlOperation(query, {
                    input: {
                        transactionRef: ref,
                        requestId: requestId,
                        tab: 'daily',
                        endpoint: 'branchDashboard',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = isCR ? response.data.getOrderSummaryV2Cr : response.data.getOrderSummaryV2;
            if (resultCheck.error) throw resultCheck.error;
            const { result } = resultCheck.data;
            if (result.approval !== null) {
                if (result.approval.remarks !== null) {
                    inboxModal.title = LABEL.requestRejected;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('rejected');
                    setRedirectState(isCR ? 'request-approve cr' : 'request-approve');
                } else {
                    inboxModal.title = LABEL.requestApproved;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('approved');
                    setRedirectState(isCR ? 'request-approve cr' : 'request-approve');
                }
            } else {
                redirectRequestAll(isCR ? 'request-approve cr' : 'request-approve');
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Notification Status',
                    testId: 'notification-status-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    const getEDDCaseStatus = async (caseId: string, tab: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(caseDetailsStatusQuery, {
                    input: {
                        caseId: caseId,
                        tab: tab,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.caseDetails;
            if (error !== null) throw error;
            if (data) {
                sessionStorage.setItem('caseId', caseId);
                return data.result;
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Case details',
                    testId: 'edd-case-details-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const getApprovalStatusEventOther = async (ref: string, event: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(getRequestDetailsCEOthers, {
                    input: {
                        requestId: ref,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionRequest;
            if (resultCheck.error !== null) throw resultCheck.error;
            const result = response.data.transactionRequest.data.result;
            if (result.approval !== null) {
                if (result.approval.remarks !== null) {
                    inboxModal.title = LABEL.requestRejected;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('rejected');
                    handleRedirectStateForCE(event);
                } else {
                    inboxModal.title = LABEL.requestApproved;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('approved');
                    handleRedirectStateForCE(event);
                }
            } else {
                event === 'reroute' ? redirectRequestAll('request-reroute') : null;
                event === 'reject' ? redirectRequestAll('request-reject') : null;
                event === 'duedate' ? redirectRequestAll('request-duedate') : null;
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Notification Status',
                    testId: 'notification-status-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    const handleRedirectStateForCE = (event: string) => {
        switch (event) {
            case 'reroute':
                setRedirectState('request-reroute');
                break;
            case 'reject':
                setRedirectState('request-reject');
                break;
            case 'duedate':
                setRedirectState('request-duedate');
                break;
        }
    };
    // Uploads Dashboard (Fund)
    const getApprovalStatusFund = async (fundId: string, event: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(getRequestDetailsFundUpload, {
                    input: {
                        fundDocumentId: fundId,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.fundUploadData;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = resultCheck.data;
            if (result.transaction.user !== null) {
                if (result.transaction.remark !== null) {
                    inboxModal.title = LABEL.requestRejected;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('rejected');
                    event === 'fund-upload' ? setRedirectState('fund-upload') : setRedirectState('bulk-upload');
                } else {
                    inboxModal.title = LABEL.requestApproved;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('approved');
                    event === 'fund-upload' ? setRedirectState('fund-upload') : setRedirectState('bulk-upload');
                }
            } else {
                redirectRequestAll(event);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Notification Status',
                    testId: 'notification-status-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    //  System Admin
    const getApprovalStatusSystemAdmin = async (id: string, event: string) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(getSystemAdminInboxData, {
                    input: {
                        id: id,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.systemAdminInboxData;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = resultCheck.data;
            const value = JSON.parse(result.data.value);
            if (event === 'system-settings' && result.data.approvalStatus.toLowerCase() !== 'pending') {
                inboxModal.title = (
                    <span>
                        {value.value === 'true' ? 'Enable' : 'Disable'} Multiple UTMC <br /> request has{' '}
                        {result.data.isExpired ? 'expired' : 'been ' + result.data.approvalStatus.toLowerCase()}
                    </span>
                ) as unknown as string;
                inboxModal.label = 'Okay';
                inboxModal.subTitle = (result.data.isExpired ? (
                    <Subtitle>
                        This request is no longer available.
                        <br /> Please submit a new request to the checker.
                    </Subtitle>
                ) : (
                    <Subtitle>
                        This request is no longer available.
                        <br /> The checker has already completed this request.
                    </Subtitle>
                )) as unknown as string;
                inboxModal.icon = 'setting-inbox';
                setRequestApprovedModal(!requestApprovedModal);
                setEventType('approved');
                setRedirectState(event);
            } else if (result.data.approvalStatus.toLowerCase() !== 'pending') {
                if (result.data.approvalStatus.toLowerCase() === 'approved') {
                    inboxModal.title = LABEL.requestApproved;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('approved');
                    setRedirectState(event);
                }
                if (result.data.approvalStatus.toLowerCase() === 'rejected') {
                    inboxModal.title = LABEL.requestRejected;
                    setRequestApprovedModal(!requestApprovedModal);
                    setEventType('rejected');
                    setRedirectState(event);
                }
            } else {
                redirectRequestAll(event);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Notification Status',
                    testId: 'notification-status-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    // The function that handle the view Notification Link

    const handleAllNotification = async () => {
        markRead();
        switch (searchType.toLowerCase()) {
            case 'order':
            case 'order cr':
            case 'transaction-approve':
            case 'transaction':
            case 'transaction cr':
                const data = {
                    transactionRef: ['transaction', 'transaction cr', 'transaction-approve'].includes(searchType)
                        ? searchKey
                        : undefined,
                    endpoint: 'branchDashboard',
                    fromInbox: true,
                    ...(['order', 'order cr'].includes(searchType)
                        ? {
                              orderNo: searchKey,
                              tab: 'hardcopy',
                          }
                        : {
                              orderNo: undefined,
                              tab: 'daily',
                          }),
                };

                const pageRoute = ['order cr', 'transaction cr'].includes(searchType)
                    ? `${getRoute()}/${ROUTES.orderSummaryChangeRequest}`
                    : `${getRoute()}/${ROUTES.orderSummary}`;
                history.push(pageRoute, data);
                break;

            case 'request-approve':
            case 'request-approve cr':
            case 'request-reject':
            case 'request-reroute':
            case 'request-duedate':
                const split = searchType.toLowerCase().split('-');
                if (split[1].includes('approve')) {
                    getApprovalStatusEventApprove(searchKey, searchId, split[1].includes('cr'));
                } else {
                    getApprovalStatusEventOther(searchId, split[1]);
                }
                break;
            case 'groups':
            case 'roles':
            case 'users':
                getApprovalStatusSystemAdmin(searchKey, searchType.toLowerCase());
                break;
            case 'bulk-upload':
            case 'fund-upload':
            case 'user-bulk-upload':
                const splitUpload = searchType.toLowerCase().split('-');
                if (splitUpload.length > 2) {
                    getApprovalStatusSystemAdmin(searchKey, searchType.toLowerCase());
                } else {
                    getApprovalStatusFund(searchKey, searchType.toLowerCase());
                }
                break;
            case 'branch-pending':
                const pendingObj = {
                    fromInbox: {
                        status: true,
                        currentBranchId: branchUser ? '' : searchKey,
                    },
                    currentTab: 'daily',
                };
                if (branchUser === false) {
                    sessionStorage.setItem('branchId', searchKey);
                    sessionStorage.setItem('branchName', senderName);
                }
                const pendingRoute = branchUser ? ROUTES.dashboardBranch : ROUTES.viewBranch;
                history.push(pendingRoute, pendingObj);
                break;
            case 'case-submit-new':
            case 'case-submit-reroute':
                const caseSubmitSplit = searchType.toLowerCase().split('-');
                const allCaseDetailsData = {
                    caseId: searchKey,
                    tab: caseSubmitSplit[2].includes('new') ? 'new' : 'reroute',
                    endpoint: 'caseDetails',
                    fromInbox: true,
                };
                const allCaseStatus = await getEDDCaseStatus(searchKey, allCaseDetailsData.tab);
                allCaseStatus.status === 'Completed' || allCaseStatus === 'Cancelled'
                    ? history.push(`${getRoute()}/${ROUTES.cancelledSatisfactory}`, allCaseDetailsData)
                    : history.push(`${getRoute()}/${ROUTES.satisfactory}`, allCaseDetailsData);
                break;
            case 'auto-pull':
                window.open(searchLink, '_blank');
                break;
            case 'system-settings':
                const sourceSystemAdmin = getSource(parsedPermission);
                if (
                    sourceSystemAdmin.includes('systemadmin') &&
                    systemSettings.epfTab.reviewApproval.canApproveChange === 'checker'
                )
                    getApprovalStatusSystemAdmin(searchKey, 'system-settings');
                else
                    sourceSystemAdmin.includes('systemadmin')
                        ? history.push({
                              pathname: ROUTES.systemConfiguration,
                          })
                        : undefined;
                break;
        }
    };

    const redirectRequestAll = (type: string) => {
        markRead();
        switch (type.toLowerCase()) {
            case 'request-approve':
            case 'request-approve cr':
                const request_approveData = {
                    transactionRef: searchKey,
                    tab: 'daily',
                    endpoint: 'branchDashboard',
                    fromInbox: true,
                    isRequestFromInbox: true,
                    requestIdFromInbox: searchId,
                    approvalType: type === 'request-approve cr' ? undefined : 'approve',
                };
                const pageRouteRequestApprove =
                    type === 'request-approve cr'
                        ? `${getRoute()}/${ROUTES.orderSummaryChangeRequest}`
                        : `${getRoute()}/${ROUTES.orderSummary}`;
                history.push(pageRouteRequestApprove, request_approveData);
                break;
            case 'request-reject':
            case 'request-reroute':
            case 'request-duedate':
                const splitRoute = type.toLowerCase().split('-');
                const requestRouteData = {
                    tab: 'daily',
                    endpoint: 'branchDashboard',
                    fromInbox: true,
                    isRequestFromInbox: true,
                    requestIdFromInbox: searchId,
                    transactionRef: searchKey,
                    approvalType: splitRoute[1] === 'reject' ? 'reject' : 'reroute',
                    rawData: rerouteState,
                };
                rerouteState.transactionRef = searchKey;
                rerouteState.requestId = searchId;
                rerouteState.approvalType = splitRoute[1].toLowerCase();
                const splitRouteOrderSummary =
                    splitRoute.includes('reject') || splitRoute.includes('reroute')
                        ? `${getRoute()}/${ROUTES.orderSummary}`
                        : `${getRoute()}/due-date-extension`;
                const splitRouteRequestData =
                    splitRoute.includes('reject') || splitRoute.includes('reroute')
                        ? requestRouteData
                        : { item: rerouteState };
                history.push(splitRouteOrderSummary, splitRouteRequestData);
                break;
            case 'bulk-upload':
            case 'fund-upload':
                const splitUpload = type.toLowerCase().split('-');

                fundObj.fundDocumentId = searchKey;
                eventType !== undefined && eventType === 'rejected'
                    ? (fundObj.status = 'rejected')
                    : eventType === 'approved'
                    ? (fundObj.status = 'active')
                    : null;
                fundObj.fromInbox = true;
                fundObj.title = splitUpload.includes('fund') ? getFundTitle(title) : '';
                history.push(
                    splitUpload.includes('fund')
                        ? `${ROUTES.hqInbox}/review-import-file`
                        : `${ROUTES.hqInbox}/review-bulk-upload`,
                    fundObj,
                );
                break;
            case 'users':
            case 'roles':
            case 'groups':
                systemAdminObj.id = searchKey;
                history.push(`${ROUTES.hqInbox}/review-${type.toLowerCase()}`, systemAdminObj);
                break;
            case 'user-bulk-upload':
                history.push(`${ROUTES.hqInbox}/bulk-import-users/review-import`, {
                    uploadId: searchKey,
                    fromInbox: true,
                });
                break;
            case 'system-settings':
                history.push({
                    pathname: ROUTES.systemConfiguration,
                });
                break;
        }
    };

    const getFundTitle = (title: string): string => {
        let temp = '';
        const nav = title.toLowerCase().includes('nav');
        const distribution = title.toLowerCase().includes('distribution');
        const masterList = title.toLowerCase().includes('master');
        if (nav) temp = 'NAV';
        if (distribution) temp = 'Distribution';
        if (masterList) temp = 'Master Fund';
        return temp;
    };
    // Avatar background Color
    const color = userColorPicker(source.toLowerCase());
    const viewLinkText = () => {
        let temp = 'View Transactions';
        switch (searchType) {
            case 'order':
            case 'transaction':
            case 'branch-pending':
                temp = 'View Transaction';
                break;
            case 'auto-pull':
                temp = 'Download Reports';
                break;
            case 'case-submit-reroute':
            case 'case-submit-new':
                temp = 'View EDD Case';
                break;
            case 'system-settings':
                title.split(' ')[0] === 'Request' || title.includes('Reminder')
                    ? (temp = 'View Details')
                    : (temp = 'View EPF Settings');

                break;
            default:
                temp = 'View Details';
                break;
        }
        return temp;
    };

    const generateViewMore = (): JSX.Element | null => {
        let _temp = (
            <MessageLink>
                {title !== 'Excess Payment' && (
                    <ViewMoreLink
                        onClick={handleAllNotification}
                        isExpand={expand}
                        id={`notification-expand-btn-${testId}`}
                    >
                        {viewLinkText()}
                        <IcoMoon name="arrow-right" size="1rem" />
                    </ViewMoreLink>
                )}
            </MessageLink>
        );

        const _source = getSource(parsedPermission);
        const hideMessageLink =
            searchType === 'system-settings' &&
            (systemSettings === undefined || (systemSettings && systemSettings.epfTab.isAll === 'false'));
        switch (_source[0]) {
            case 'ce':
                if (hideMessageLink) _temp = <MessageLink></MessageLink>;
                break;
            case 'amla':
            case 'systemadmin':
                if (hideMessageLink) _temp = <MessageLink></MessageLink>;
                break;
            case 'finance':
                if (searchType === 'system-settings') _temp = <MessageLink></MessageLink>;
                break;
        }

        return _temp;
    };
    useMemo(() => {
        if (isReadUpdated === true && !isRead) {
            markRead();
        }
    }, [isReadUpdated, isRead]);

    return (
        <Fragment>
            <NotificationContainer className="notifications_container" id={`notification-${testId}`}>
                <NotificationRow>
                    <Day id={`${day && day.replace(/^[, ]+|[, ]+$|[, ]+/g, '-').toLowerCase()}`}>{day}</Day>
                </NotificationRow>
                <NotificationsMessage
                    className="notifications_message"
                    onClick={() => {
                        if (title === 'Excess Payment') {
                            setIsReadUpdated(!isReadUpdated);
                            setExpand(!expand);
                        } else {
                            setExpand(!expand);
                        }
                    }}
                    // add notification
                    isUnRead={isRead !== undefined && isRead ? false : !isRead}
                >
                    <Avatar backgroundColor={color} testId={`${testId}-notification`}>
                        <span id={`notification-name-${testId}`}>
                            {senderName === 'HQ - Kuala Lumpur' ? 'HQ' : getInitials(senderName)}
                        </span>
                    </Avatar>
                    <MessageContainer>
                        <MessageTitle id={`notification-subject-${testId}`}>
                            {title}
                            {isSeen !== undefined && !isSeen ? <Badge /> : ''}
                        </MessageTitle>
                        <MessageBody isExpand={expand} id={`notification-message-${testId}`}>
                            {message}
                        </MessageBody>
                        {generateViewMore()}
                    </MessageContainer>
                    <MessageTime id={`notification-time-${testId}`}> {createdOn}</MessageTime>
                </NotificationsMessage>
            </NotificationContainer>
            {requestApprovedModal && (
                <Modal
                    title={inboxModal.title}
                    modalActive={requestApprovedModal}
                    setModalActive={setRequestApprovedModal}
                    primaryBtn={{
                        onClick: () => {
                            setRequestApprovedModal(!requestApprovedModal);
                            redirectRequestAll(redirectState);
                        },
                        label: inboxModal.label,
                        primary: true,
                        size: 'large',
                    }}
                    icon={inboxModal.icon}
                    contentAlignment="center"
                    testId="notification-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {inboxModal.subTitle}
                    </FlexedDiv>
                </Modal>
            )}
        </Fragment>
    );
};

const NotificationContainer = styled.div`
    margin: 1.5rem 0px;
    padding: 0px 1.5rem;
`;

const NotificationRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Day = styled.p`
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 0.85rem;
    color: #002043;
    mix-blend-mode: normal;
    opacity: 0.5;
    margin-bottom: 0;
`;
const Subtitle = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #333333;
`;
const NotificationsMessage = styled.div<notificationStyleProps>`
    cursor: pointer;
    margin: 0.5rem 0rem;
    padding: 1rem;
    transition: height 0.2s ease-in;
    background: ${(props) => (props.isUnRead ? '#fff' : '#F2F4F6')};
    box-shadow: ${(props) => (props.isUnRead ? '0px 0px 32px rgba(18, 39, 120, 0.12)' : 'none')};
    border: ${(props) => (props.isUnRead ? '0px' : '1px solid #EAEBEE;')};
    border-radius: 8px;
    display: flex;
    /* width: 76.67vw;
    min-width: 1104px; */
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 44.86vw;
    min-width: 646px; */
    margin: 0rem 1.22rem;
    white-space: nowrap;
    /* height: 3.5rem; */
    max-width: 90%;
    word-break: break-all;
    width: 100%;
`;

const MessageTitle = styled.h1`
    font-weight: 700;
    line-height: 24px;
    font-size: 1rem;
    /* display: flex; */
    /* width: 14.72vw; */
`;

const MessageBody = styled.div<notificationStyleProps>`
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => (props.isExpand ? 'unset' : 1)};
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 0.75rem;
    color: #333333;
    // width: 590px;
    white-space: pre-wrap;
`;

const MessageTime = styled.div`
    color: #002043;
    mix-blend-mode: normal;
    opacity: 0.5;
    font-size: 0.625rem;
`;
const LinkBtn = styled.a`
    display: inline-block;
    font-weight: 700;
    font-size: 0.75rem;
    color: #0089ec;
    line-height: 0.85rem;
    &:hover {
        color: #006dbd;
    }
`;
const MessageLink = styled.div`
    display: flex;
    align-items: center;
    fill: #0089ec;
    &:hover {
        fill: #006dbd;
    }
`;
const ViewMoreLink = styled(LinkBtn)<notificationStyleProps>`
    display: ${(props) => (props.isExpand ? '' : 'none')};
    margin-top: 0.5em;
`;
const Badge = styled.div`
    width: 0.5rem;
    height: 0.5rem;
    background: #c61230;
    border-radius: 50%;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem !important;
    display: inline-block;
`;
