/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import { timestampToDate } from '../../../utils/timestampToDate';

declare interface ResponseInfoProps {
    name: string;
    timestamp: string;
    status: string;
    badgeStyle: React.CSSProperties;
    style?: React.CSSProperties;
}

export const ResponseInfo = ({ name, timestamp, status, badgeStyle, style }: ResponseInfoProps): JSX.Element => {
    return (
        <ParagraphStyle style={style}>
            <strong style={badgeStyle}>{`|`}</strong>&nbsp;&nbsp;
            <strong style={{ color: 'black' }}>{`${name}`}</strong>
            &nbsp;&nbsp;
            {timestampToDate(timestamp, 'DD/MM/YYYY hh:mm a')}
            &nbsp;|&nbsp;
            {`Status : ${status}`}
        </ParagraphStyle>
    );
};
declare interface ParagraphStyleProps {
    margin?: string;
}
const ParagraphStyle = styled.p<ParagraphStyleProps>`
    display: flex;
    font-size: 12px;
    color: #4d4d4d;
    margin: ${(props) => (props.margin ? props.margin : '0px 32px 10px')};
`;
