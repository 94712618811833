/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../routes';
import { getURLParams } from '../utils';

export const PublicRoute = ({ component: Component, ...rest }: any): JSX.Element => {
    const _SSOObj = getURLParams();
    return (
        <Route
            {...rest}
            render={(props) =>
                _SSOObj.id_token !== '' ? (
                    <Redirect to={`${ROUTES.signinSSO}/${_SSOObj.id_token}/${_SSOObj.refresh_token}/${_SSOObj.user}`} />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};
