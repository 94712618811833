import { ReactText } from 'react';
import { LABEL } from '../../../constants';

export const dateSorting: ISelectValue[] = [
    { label: 'Last Updated', value: 'lastUpdated' },
    { label: 'Submitted On', value: 'submittedOn' },
    { label: 'Created On', value: 'createdOn' },
];
export const productTypes: ISelectValue[] = [
    { label: 'UT', value: 'utf' },
    { label: 'PRS', value: 'prs' },
    { label: 'AMP', value: 'amp' },
];
export const productTypesMultiSelect: ReactText[] = ['UT', 'PRS', 'AMP'];

export const fundingOptions: ISelectValue[] = [
    { label: 'Cash', value: 'cash' },
    { label: 'EPF', value: 'epf' },
    { label: 'DDA', value: 'dda' },
    // { label: 'EPF-Conventional', value: 'epf' },
    // { label: 'EPF-Shariah', value: 'epf' },
];
export const fundingOptionsMultiSelect: ReactText[] = ['Cash', 'EPF', 'DDA'];
export const accountTypes: ISelectValue[] = [
    { label: 'Individual', value: 'individual' },
    { label: 'Joint', value: 'joint' },
    { label: 'Both', value: '' },
];
export const statusHistory: ISelectValue[] = [
    { label: LABEL.completed, value: 'completed' },
    { label: LABEL.rejected, value: 'rejected' },
    { label: LABEL.void, value: 'void' },
];
export const statusDaily: ISelectValue[] = [
    { label: LABEL.pending, value: 'pending' },
    { label: LABEL.inReview, value: 'in review' },
    { label: LABEL.completed, value: 'completed' },
    { label: LABEL.pendingApproval, value: 'pending approval' },
];
export const transactionTypes: ISelectValue[] = [{ label: 'Sales - AO', value: 'Sales-AO' }];

export const transactionTypesMultiSelect: ReactText[] = ['Sales', 'Sales-AO', 'Switch', 'Top-up', 'Redeem'];

export const statusTypesMultiSelect: ReactText[] = [
    'Completed',
    'HQ-Rerouted',
    'Pending Doc',
    'Pending Doc & Payment',
    'Pending Payment',
];

export const branchTypesMultiSelect: ReactText[] = ['Batu Pahat – PW Office', 'Damansara Uptown'];
/**
 *       { item:LABEL.pending, handleItem: handleAction },
        { item: LABEL.inReview, handleItem: handleAction },
        { item: LABEL.completed, handleItem: handleAction },
        { item: LABEL.pendingApproval, handleItem: handleAction },
 */
export const dateSortingPendingSubmission: ISelectValue[] = [
    { label: 'Created On', value: 'createdOn' },
    { label: 'Last Updated', value: 'lastUpdated' },
];
