import { DocumentNode } from 'graphql';
import { branchDashboardExportListQueryCreatedOn } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListCreatedOn';
import { branchDashboardExportListQueryLastUpdated } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListLastUpdated';
import { branchDashboardExportListQuerySubmittedOn } from '../../../_graphql/queries/branchCE/transaction/exportList/exportListSubmittedOn';

export const exportListQueryPicker = (dateType: string): DocumentNode => {
    let temp = branchDashboardExportListQueryLastUpdated;
    switch (dateType.toLowerCase()) {
        case 'created on':
            temp = branchDashboardExportListQueryCreatedOn;
            break;
        case 'submitted on':
            temp = branchDashboardExportListQuerySubmittedOn;
            break;
        default:
            temp = branchDashboardExportListQueryLastUpdated;
            break;
    }
    return temp;
};
