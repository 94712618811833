/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { RegulartTooltipTemplate, Tooltip } from '../../../components';
import { IcoMoon } from '../../../icons';
import { DateColumnItem, InvestorColumnItem, TotalInvestmentColumnItem } from '../../../templates';
import { TargetDateColumnItem } from './TargetDateColumnItem';

const CustomItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const Remarks = () => {
        const tooltipItems: Array<ITooltip> = [];
        if (data.remarks !== null) {
            data.remarks.length !== 0
                ? data.remarks.map((item: { title: string; content: string[] }) => {
                      const tempObject = {
                          label: '',
                          content: [''],
                      };
                      tempObject.label = item.title;
                      tempObject.content = item.content;
                      tooltipItems.push(tempObject);
                  })
                : null;
        }

        return (
            <Fragment>
                {data.remarks !== null && data.remarks.length !== 0 ? (
                    <Tooltip
                        tooTipContent={<RegulartTooltipTemplate message={tooltipItems} />}
                        style={{ padding: '1rem', textAlign: 'center' }}
                    >
                        <div>
                            <IcoMoon name="warning" color="#E89700" size="1.667vw" />
                        </div>
                    </Tooltip>
                ) : (
                    <div>--</div>
                )}
            </Fragment>
        );
    };
    const renderItems = (key: string) => {
        switch (key) {
            case 'remarks':
                return <Remarks />;
            case 'totalInvestment':
                return <TotalInvestmentColumnItem isSortedColumn={isSortedColumn} data={data} itemKey={itemKey} />;
            case 'createdOn':
            case 'submittedOn':
            case 'lastActivity':
            case 'lastUpdated':
                return <DateColumnItem data={data} itemKey={itemKey} isSortedColumn={isSortedColumn} />;
            case 'targetDate':
                return <TargetDateColumnItem data={data} itemKey={itemKey} />;
        }
    };
    return (
        <Fragment>
            {itemKey.length === 1 ? (
                renderItems(itemKey[0].key)
            ) : (
                <Fragment>
                    <InvestorColumnItem isSortedColumn={isSortedColumn} data={data} itemKey={itemKey} />
                </Fragment>
            )}
        </Fragment>
    );
};
export default CustomItem;
