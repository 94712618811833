/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, ReactText, ChangeEvent, useContext } from 'react';
import { SearchBar, Dashboard } from '../../../components/organisms';
import { LABEL, TextPureBlack, Fs15BoldPrimaryBlack, Fs15BoldNeutralWhite } from '../../../constants';
import { API, graphqlOperation } from 'aws-amplify';
import { CustomSpacer, FlexedDiv, CustomButton, ComponentLoader } from '../../../components';
import { branchOfficesExportList } from '../../../_graphql/queries/hqCE/exportList/branchOffices';
import { exportList } from '../../../utils';
import { SearchOptionsHQCE } from '../searchDropdown';
import { useDebounce } from '../../../customHooks/useDebounce';
import MultiSelect from '../../../components/molecules/MultiSelect/MultiSelect';
import styled from 'styled-components';
import AuthContext from '../../../context/AuthContext';
import BranchOfficeContext from '../../../context/HQCEContext/branchOffice';
import AllBranchOffices from './AllBranchOffices';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';

const BranchOffices: React.FC = () => {
    const { userLoginContext } = useContext(AuthContext);
    const {
        allBranchData,
        branchCounter,
        expand,
        filters,
        getAllBranchData,
        loading,
        loadingHandler,
        maxPages,
        page,
        resultLimit,
        searchInput,
        setExpand,
        setFilters,
        setPage, //page
        setResultLimit, // setLimit
        setSearchInput,
        setSortData,
        setStates,
        setTab,
        setTempFilters,
        sortData,
        stateList,
        states,
        tab,
        tempFilters,
        disableResultLimit,
    } = useContext(BranchOfficeContext);

    // Debounce
    const debouncedSearchTerm = useDebounce(searchInput, 700);

    const { hq } = JSON.parse(userLoginContext.permission);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const { branchOffices } = hq.permission;

    const { allBranchTab } = branchOffices;

    const switchTabs = (index: number) => {
        setTab(index);
    };

    const getExportList = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(branchOfficesExportList, {
                    input: {
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        filter: filters,
                        endpoint: 'branchoffices',
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.dashboardExportList.data;
            // checks if error exists
            if (resultCheck.error !== undefined) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.dashboardExportList.data;

            exportList('Hq', 'branchOffices', result.branchoffices);
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Generate Export List',
                    testId: 'hq-branches-error-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const handleNext = () => {
        if (page < maxPages) {
            setPage(page + 1);
        }
    };

    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleMultiSelectDropdown = (data: ReactText[]) => {
        const temp = [...tempFilters];
        temp.splice(1);
        data.map((state) => {
            temp.push({ column: 'state', value: state as string });
        });
        setStates(data);
        setTempFilters(temp);
    };

    const handleReset = () => {
        setStates([]);
        setTempFilters([{ column: 'state', value: '' }]);
        setExpand(!expand);
    };

    const handleSave = () => {
        setFilters(tempFilters);
        setExpand(false);
    };

    const handleSecondary = () => {
        getExportList();
    };

    useEffect(() => {
        getAllBranchData();
    }, [page, resultLimit, sortData, debouncedSearchTerm, filters]);

    useEffect(() => {
        getAllBranchData();
    }, []);

    const sameFilter = JSON.stringify([{ column: 'state', value: '' }]) !== JSON.stringify(filters);
    const searchOrFiltered = sameFilter || searchInput.value !== '';

    const tabs: IDashboardTabs[] = [];
    if (allBranchTab.isAll === 'true' || allBranchTab.actions.canViewAllBranchList) {
        tabs.push({
            name: 'all branches',
            table: (
                <AllBranchOffices
                    allBranchData={allBranchData}
                    searchEmpty={searchOrFiltered}
                    searchInput={searchInput.value}
                    setSortData={setSortData}
                    sortData={sortData}
                    allBranchTab={allBranchTab}
                />
            ),
            testId: 'all-branches-tab',
        });
    }
    /** Function to create the options for the searchFilter
     * @description the function returns an array of type IDropdownItemV2 by adding the onclick function handler to each item of the array
     */
    const searchOptions = (): IDropdownItemV2[] => {
        const tempOptions: IDropdownItemV2[] = [];
        SearchOptionsHQCE.branchOffices.map(({ item, value }) => {
            tempOptions.push({
                item: item,
                value: value,
                handleItem: () => setSearchInput({ ...searchInput, column: value }),
                testId: `hq-branches-search-filter-${item.split(' ').join('-').toLowerCase()}`,
            });
        });
        return tempOptions;
    };
    return (
        <Fragment>
            {/* <BranchOfficeContext.Consumer> */}
            <SearchBar
                placeHolder={LABEL.hqBranchOfficesSearch}
                searchTitle={LABEL.branchOffices}
                dropDownOptions={searchOptions()}
                handleSecondary={handleSecondary}
                secondaryButton={
                    allBranchTab.actions.canExportList
                        ? [{ type: 'secondary', title: 'Export List', actionIcon: 'export', testId: 'export-list-btn' }]
                        : []
                }
                searchInput={searchInput}
                expand={expand}
                setExpand={setExpand}
                onSearch={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchInput({ ...searchInput, value: e.currentTarget.value });
                }}
                testId="hq-branches-searchbar"
                clearSearchInput={() => {
                    setSearchInput({ ...searchInput, value: '' });
                }}
            >
                <div>
                    <TextPureBlack>{LABEL.filterTransactionsBy}</TextPureBlack>
                    <CustomSpacer space={'0.5rem'} />
                    <FlexedDiv direction="column">
                        <MultiSelect
                            checkedState={states}
                            handleMultiSelect={handleMultiSelectDropdown}
                            hasSelectAll={true}
                            label={LABEL.state}
                            noOverlay
                            options={stateList}
                            placeHolder={LABEL.select}
                            testId={`state-dropdown-filter`}
                        />
                    </FlexedDiv>
                    <CustomSpacer space={'8vh'} />
                    <FlexedDiv style={{ justifyContent: 'center' }}>
                        <CustomButton
                            primary={false}
                            onClick={handleReset}
                            style={{ width: '16.67vw' }}
                            id="hq-branches-reset-btn"
                        >
                            <Fs15BoldPrimaryBlack>{LABEL.reset}</Fs15BoldPrimaryBlack>
                        </CustomButton>
                        <CustomSpacer horizontal={true} space={'1rem'} />
                        <CustomButton
                            primary={true}
                            onClick={handleSave}
                            style={{ width: '16.67vw', padding: '14px 0' }}
                            id="hq-branches-apply-btn"
                        >
                            <Fs15BoldNeutralWhite>{LABEL.apply}</Fs15BoldNeutralWhite>
                        </CustomButton>
                    </FlexedDiv>
                </div>
            </SearchBar>
            <TabWrapper>
                <Dashboard
                    tabs={tabs}
                    page={page}
                    maxPage={maxPages}
                    selectedTab={tab}
                    orderCounter={branchCounter}
                    setResultLimit={setResultLimit}
                    switchTabs={switchTabs}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    testId="hq-branches"
                    setCurrentPage={setPage}
                    resultLimit={resultLimit}
                    disableResultLimit={disableResultLimit}
                />
                {loading ? <ComponentLoader /> : null}
            </TabWrapper>
        </Fragment>
    );
};

export default BranchOffices;

const TabWrapper = styled.div`
    position: relative;
`;
