export const selectedTabEdd = (tab: string): string => {
    let temp = '';
    switch (tab.toLowerCase()) {
        case 'rerouted':
            temp = 'reroute';
            break;
        case 'history':
            temp = 'close';
            break;
        default:
            temp = tab.toLowerCase();
            break;
    }
    return temp;
};
