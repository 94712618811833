/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext } from 'react';
import { EMPTY_STATES, TextDarkBlack } from '../../../constants';

import HistoryActions from './HistoryActions';
import CustomItem from './CustomItem';
import { AdvanceTable } from '../../../components/organisms/AdvancedTable';
import { useHistoryHandler } from './useHistoryHandler';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';

interface HistoryProps {
    filters: ISort_FilterInput;
    historyTab: IHistoryTab;
    idTokenHeader?: {
        Authorization: string;
        strategy: string;
    };
    isFilterApplied?: boolean;
    isSearchResult?: boolean;
    lastUpdatedDropdown: string;
    loadingHandler: () => void;
    searchInput: string;
    setErrorHandler: () => void;
    setErrorMessage: (error: IErrorHandling) => void;
    setFilters: (data: ISort_FilterInput) => void;
    setLastUpdatedDropdown: (value: string) => void;
    setSortData: (data: ISort_FilterInput) => void;
    sortData: ISort_FilterInput;
    showResultCount?: boolean;
}

export const History: React.FC<HistoryProps> = ({
    filters,
    historyTab,
    idTokenHeader,
    isFilterApplied,
    isSearchResult,
    lastUpdatedDropdown,
    loadingHandler,
    searchInput,
    setErrorHandler,
    setErrorMessage,
    setFilters,
    setLastUpdatedDropdown,
    setSortData,
    sortData,
    showResultCount,
}: HistoryProps) => {
    const { handlePrintTransactionReport, handleDownloadPPA, columnsHistory, historyGrouped } = useHistoryHandler({
        filters,
        historyTab,
        idTokenHeader,
        isFilterApplied,
        isSearchResult,
        lastUpdatedDropdown,
        loadingHandler,
        searchInput,
        setErrorHandler,
        setErrorMessage,
        setFilters,
        setLastUpdatedDropdown,
        setSortData,
        sortData,
    });
    const { orderCount } = useContext(DashboardBranchContext);

    return (
        <Fragment>
            {showResultCount && searchInput ? (
                <TextDarkBlack size="16px" weight="700" style={{ margin: '24px' }}>
                    {`${orderCount[0] + orderCount[1]} result(s) found for '${searchInput}'`}
                </TextDarkBlack>
            ) : null}

            <AdvanceTable
                data={historyGrouped.current}
                columns={columnsHistory}
                RenderOptions={(props: ITableOptions) => (
                    <HistoryActions
                        {...props}
                        handlePrintTransactionReport={handlePrintTransactionReport}
                        handleDownloadPPA={handleDownloadPPA}
                        historyTab={historyTab}
                    />
                )}
                onEmptyState={
                    isFilterApplied ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateTableHistory
                }
                RenderItem={(props: ITableCustomItem) => <CustomItem isSortedColumn={sortData[0].column} {...props} />}
                isSearchResult={isSearchResult}
                searchInput={searchInput}
                groupedRow
                testId="branchhistory"
            />
        </Fragment>
    );
};

export default History;
