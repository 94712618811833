/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const accountInfo = /* GraphQL */ `
  query AccountInfo($input: accountInfoInput) {
    accountInfo(input: $input) {
      data {
        result {
          investmentSummary {
            accountFund
            distributionInstructions
            feaTagged
            fundClass
            fundCode
            fundCurrency
            fundName
            investmentAmount
            investmentType
            productType
            recurring
            salesCharge
            utmc
            isTopup
          }
          orderNo
          profile {
            isEtb
            addressInformation {
              mailingAddress {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
              permanentAddress {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
            }
            bankInformation {
              foreignBank {
                id
                bankAccountName
                bankAccountNumber
                bankLocation
                bankName
                bankSwiftCode
                currency
                isNewBank
              }
              localBank {
                id
                bankAccountName
                bankAccountNumber
                bankLocation
                bankName
                bankSwiftCode
                currency
                isNewBank
              }
            }
            contactDetails {
              email
              faxNumber
              homeNumber
              mobileNumber
              officeNumber
            }
            crsDeclaration {
              taxResident
              tin {
                country
                reason
                tinNumber
              }
            }
            employmentInformation {
              address {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
              annualIncome
              nameOfEmployer
              natureOfBusiness
              occupation
            }
            epfDetails {
              epfAccountType
              epfMemberNumber
            }
            fatcaDeclaration {
              certificate {
                url
                base64
                date
                name
                path
                size
                type
              }
              confirmAddress
              correspondenceDeclaration
              reason
              usBorn
              usCitizen
              formW9 {
                url
                base64
                date
                name
                path
                size
                type
              }
              formW8Ben {
                url
                base64
                date
                name
                path
                size
                type
              }
              pendingDoc
            }
            feaDeclaration {
              balance
              borrowingFacility
              resident
            }
            idFile
            idNumber
            idType
            name
            personalDetails {
              bumiputera
              countryOfBirth
              dateOfBirth
              educationLevel
              gender
              maritalStatus
              monthlyHouseholdIncome
              mothersMaidenName
              nationality
              placeOfBirth
              race
              relationship
              salutation
              expirationDate
              riskProfile
            }
            riskInfo {
              fundChoice
              hnwStatus
              message
              rangeOfReturn
              riskCategory
              typeOfFunds
            }
            lastUpdated
            principal
            accountHolder
            origin
          }
          transactionDetails {
            accountNo
            accountOperationMode
            accountType
            bdmName
            kibProcessingBranch
            registrationDate
            servicingAdviserCode
            servicingAdviserName
            utaId
          }
          transactionRef
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const activityLogDashboard = /* GraphQL */ `
  query ActivityLogDashboard($input: DashboardInput) {
    activityLogDashboard(input: $input) {
      data {
        result {
          logs {
            id
            operationName
            name
            code
            roles
            eventType
            createdOn
            status
            action
            fileSize
            userName
          }
          page
          pages
          activityCount
          reportCount
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const adviserDashboard = /* GraphQL */ `
  query AdviserDashboard($input: AdviserDashboardInput) {
    adviserDashboard(input: $input) {
      data {
        result {
          advisers {
            branchName
            adviserList {
              agentId
              agentName
              agentCode
              bdmName
              bdmCode
              uplineName
              uplineCode
              status
              createdOn
              lastUpdated
              branchName
            }
          }
          adviserCount
          page
          pages
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const adviserSummary = /* GraphQL */ `
  query AdviserSummary($input: AdviserSummaryInput) {
    adviserSummary(input: $input) {
      data {
        result {
          profile {
            name
            code
            nric
            personalDetails {
              licenseCode
              homeBranch
              omniEnabled
              bdmName
              bdmCode
              bdmEmail
              uplineName
              uplineCode
              region
              adviserChannel
              adviserRank
              agency
            }
            contactDetails {
              email
              mobileNo
            }
            addressInfo {
              address
              postcode
              addrTown
              state
              country
            }
          }
          investor {
            clientId
            clientName
            clientIdNum
            riskCategory
            individualCount
            principalCount
            jointholderCount
            totalAccount
            lastUpdated
            lastActivity
          }
          totalResultCount
          investorCount
          typeCount
          page
          pages
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const agentProfile = /* GraphQL */ `
  query AgentProfile($input: AgentProfileInput) {
    agentProfile(input: $input) {
      data {
        result {
          userId
          nric
          name
          status
          personalDetails {
            licenseCode
            homeBranch
            omniEnabled
            bdmName
            bdmCode
            bdmEmail
            uplineName
            uplineCode
            region
            adviserChannel
            adviserRank
            agency
          }
          contactDetails {
            email
            mobileNo
          }
          addressInfo {
            address
            postcode
            addrTown
            state
            country
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const allPendingDashboard = /* GraphQL */ `
  query AllPendingDashboard($input: BranchDashboardInput) {
    allPendingDashboard(input: $input) {
      data {
        result {
          groupedTransactions {
            grouping {
              type
              groupingData {
                key
                value
              }
              transactionData {
                grouping {
                  groupingData {
                    jointName
                    jointNumber
                    orderNo
                    principalName
                    principalNumber
                    viewable
                    accountNo
                    noTransactions
                  }
                  transactionData {
                    data {
                      accountType
                      agentCode
                      agentName
                      aging
                      approvalType
                      batchTime
                      bdmName
                      branchName
                      clientIdNum
                      clientName
                      createdOn
                      cutOffTime
                      extendTargetDate
                      fundAbbr
                      fundCategory
                      fundCode
                      fundType
                      isSeen
                      jointName
                      jointNumber
                      lastUpdated
                      orderNo
                      paymentMethod
                      remarks {
                        content
                        title
                      }
                      requestId
                      requestedBy
                      riskCategory
                      status
                      submittedOn
                      targetDate
                      totalUnits
                      totalInvestment {
                        fundCurrency
                        investmentAmount
                      }
                      transactionRef
                      transactionType
                      utmc
                      isVerified
                      viewable
                      accountNo
                      noTransactions
                      extendRemarks {
                        content
                        title
                      }
                      isPhysicalDocRequired
                    }
                  }
                  type
                }
              }
            }
          }
          totalResultCount
          statuses {
            tab
            count
          }
          page
          pages
          filters {
            accountType
            fundType
            paymentMethod
            transactionType
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const allSubmittedDashboard = /* GraphQL */ `
  query AllSubmittedDashboard($input: BranchDashboardInput) {
    allSubmittedDashboard(input: $input) {
      data {
        result {
          batchCount {
            batchTime
            count
          }
          changeRequestsCount
          cutOffCount
          dailyCount
          enableBulkVerify
          filters {
            accountType
            branchStatus
            fundType
            paymentMethod
            riskCategory
            transactionType
            cutOffTime
          }
          groupedTransactions {
            grouping {
              groupingData {
                key
                value
              }
              transactionData {
                grouping {
                  groupingData {
                    key
                    value
                  }
                  transactionData {
                    grouping {
                      groupingData {
                        jointName
                        jointNumber
                        orderNo
                        principalName
                        principalNumber
                        viewable
                        accountNo
                        noTransactions
                      }
                      type
                    }
                  }
                  type
                }
              }
              type
            }
          }
          groupedTransactionsCr {
            grouping {
              groupingData {
                key
                value
              }
              transactionData {
                grouping {
                  groupingData {
                    jointName
                    jointNumber
                    orderNo
                    principalName
                    principalNumber
                    viewable
                    accountNo
                    noTransactions
                  }
                  transactionData {
                    data {
                      accountType
                      agentCode
                      agentName
                      aging
                      approvalType
                      batchTime
                      bdmName
                      branchName
                      clientIdNum
                      clientName
                      createdOn
                      cutOffTime
                      extendTargetDate
                      fundAbbr
                      fundCategory
                      fundCode
                      fundType
                      isSeen
                      jointName
                      jointNumber
                      lastUpdated
                      orderNo
                      paymentMethod
                      remarks {
                        content
                        title
                      }
                      requestId
                      requestedBy
                      riskCategory
                      status
                      submittedOn
                      targetDate
                      totalUnits
                      totalInvestment {
                        fundCurrency
                        investmentAmount
                      }
                      transactionRef
                      transactionType
                      utmc
                      isVerified
                      viewable
                      accountNo
                      noTransactions
                      extendRemarks {
                        content
                        title
                      }
                      isPhysicalDocRequired
                    }
                  }
                  type
                }
              }
              type
            }
          }
          hardcopyCount
          historyCount
          page
          pages
          rerouteCount
          transactions {
            accountType
            agentCode
            agentName
            aging
            approvalType
            batchTime
            bdmName
            branchName
            clientIdNum
            clientName
            createdOn
            cutOffTime
            extendTargetDate
            fundAbbr
            fundCategory
            fundCode
            fundType
            isSeen
            jointName
            jointNumber
            lastUpdated
            orderNo
            paymentMethod
            remarks {
              content
              title
            }
            requestId
            requestedBy
            riskCategory
            status
            submittedOn
            targetDate
            totalUnits
            totalInvestment {
              fundCurrency
              investmentAmount
            }
            transactionRef
            transactionType
            utmc
            isVerified
            viewable
            accountNo
            noTransactions
            extendRemarks {
              content
              title
            }
            isPhysicalDocRequired
          }
          transactionsCount
          typeCount
          typeSeen
          upcomingCount
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const branchDashboardV2 = /* GraphQL */ `
  query BranchDashboardV2($input: BranchDashboardInput) {
    branchDashboardV2(input: $input) {
      data {
        result {
          batchCount {
            batchTime
            count
          }
          changeRequestsCount
          cutOffCount
          dailyCount
          enableBulkVerify
          filters {
            accountType
            branchStatus
            fundType
            paymentMethod
            riskCategory
            transactionType
            cutOffTime
          }
          groupedTransactions {
            grouping {
              groupingData {
                key
                value
              }
              transactionData {
                grouping {
                  groupingData {
                    jointName
                    jointNumber
                    orderNo
                    principalName
                    principalNumber
                    viewable
                    accountNo
                    noTransactions
                  }
                  transactionData {
                    data {
                      accountType
                      agentCode
                      agentName
                      aging
                      approvalType
                      batchTime
                      bdmName
                      branchName
                      clientIdNum
                      clientName
                      createdOn
                      cutOffTime
                      extendTargetDate
                      fundAbbr
                      fundCategory
                      fundCode
                      fundType
                      isSeen
                      jointName
                      jointNumber
                      lastUpdated
                      orderNo
                      paymentMethod
                      remarks {
                        content
                        title
                      }
                      requestId
                      requestedBy
                      riskCategory
                      status
                      submittedOn
                      targetDate
                      totalUnits
                      totalInvestment {
                        fundCurrency
                        investmentAmount
                      }
                      transactionRef
                      transactionType
                      utmc
                      isVerified
                      viewable
                      accountNo
                      noTransactions
                      extendRemarks {
                        content
                        title
                      }
                      isPhysicalDocRequired
                    }
                  }
                  type
                }
              }
              type
            }
          }
          hardcopyCount
          historyCount
          page
          pages
          rerouteCount
          transactions {
            accountType
            agentCode
            agentName
            aging
            approvalType
            batchTime
            bdmName
            branchName
            clientIdNum
            clientName
            createdOn
            cutOffTime
            extendTargetDate
            fundAbbr
            fundCategory
            fundCode
            fundType
            isSeen
            jointName
            jointNumber
            lastUpdated
            orderNo
            paymentMethod
            remarks {
              content
              title
            }
            requestId
            requestedBy
            riskCategory
            status
            submittedOn
            targetDate
            totalUnits
            totalInvestment {
              fundCurrency
              investmentAmount
            }
            transactionRef
            transactionType
            utmc
            isVerified
            viewable
            accountNo
            noTransactions
            extendRemarks {
              content
              title
            }
            isPhysicalDocRequired
          }
          transactionsCount
          typeCount
          typeSeen
          upcomingCount
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const branchDashboard = /* GraphQL */ `
  query BranchDashboard($input: BranchDashboardInput) {
    branchDashboard(input: $input) {
      data {
        result {
          batchCount {
            batchTime
            count
          }
          cutOffCount
          dailyCount
          hardcopyCount
          historyCount
          page
          pages
          enableBulkVerify
          rerouteCount
          totalResultCount
          transactions {
            accountType
            agentCode
            agentName
            aging
            approvalType
            batchTime
            bdmName
            branchName
            clientIdNum
            clientName
            createdOn
            cutOffTime
            extendTargetDate
            fundAbbr
            fundCategory
            fundCode
            fundType
            isSeen
            jointName
            jointNumber
            lastUpdated
            orderNo
            paymentMethod
            remarks {
              content
              title
            }
            requestId
            requestedBy
            riskCategory
            status
            submittedOn
            targetDate
            totalUnits
            totalInvestment {
              fundCurrency
              investmentAmount
            }
            transactionRef
            transactionType
            utmc
            isVerified
            viewable
            accountNo
            noTransactions
            extendRemarks {
              content
              title
            }
            isPhysicalDocRequired
          }
          typeCount
          typeSeen
          upcomingCount
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const branchOffices = /* GraphQL */ `
  query BranchOffices($input: BranchOfficesInput) {
    branchOffices(input: $input) {
      data {
        result {
          branches {
            branchId
            name
            code
            state
            agentCount
            clientCount
            dailyCount
            rerouteCount
            hardcopyCount
          }
          stateList
          totalResultCount
          branchCount
          page
          pages
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const caseCheck = /* GraphQL */ `
  query CaseCheck($input: CaseCheckInput) {
    caseCheck(input: $input) {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const caseDetails = /* GraphQL */ `
  query CaseDetails($input: CaseDetailsInput) {
    caseDetails(input: $input) {
      data {
        result {
          name
          clientIdNum
          idFile
          idType
          officerName
          remark {
            content
            title
          }
          status
          caseSummary {
            caseDetails {
              caseId
              caseNo
              createdOn
              closedDate
              eddTrigger {
                content
                title
              }
              eddReason {
                content
                title
              }
              satisfactoryStatus
            }
            personalDetails {
              nationality
              email
              phone
              occupation
              employerName
              grossIncome
            }
            accountSummary {
              accountNo
              accountCreatedOn
              investorType
              accountHolder
              agentId
              agentCode
              receipt {
                url
                base64
                date
                name
                path
                size
                type
              }
              productType
              fundOption
            }
          }
          assignedAdviser {
            personalDetails {
              agentName
              agentCode
              nric
              licenseCode
              branch
              region
              channel
              omniEnabled
              bdmName
              bdmCode
              bdmEmail
              uplineName
              uplineCode
              rank
              agency
            }
            contactDetails {
              email
              mobileNo
            }
            addressInformation {
              address
              postcode
              addrTown
              state
              country
            }
          }
          headings {
            responseId
            submittedOn
            remarkOn
          }
          response {
            questionNo
            title
            description
            questionId
            answers
            amlaRemark
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const dailySummaryCount = /* GraphQL */ `
  query DailySummaryCount($input: DailySummaryCountInput) {
    dailySummaryCount(input: $input) {
      data {
        result {
          result
          count
          enableBulkVerify
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const dashboardExportList = /* GraphQL */ `
  query DashboardExportList($input: DashboardExportInput) {
    dashboardExportList(input: $input) {
      data {
        result {
          message
          endpoint
          adviserdashboard {
            agentId
            agentName
            agentCode
            bdmName
            bdmCode
            uplineName
            uplineCode
            status
            createdOn
            lastUpdated
            branchName
          }
          branchoffices {
            branchId
            name
            code
            state
            agentCount
            clientCount
            dailyCount
            rerouteCount
            hardcopyCount
          }
          branchdashboard {
            accountType
            agentCode
            agentName
            aging
            approvalType
            batchTime
            bdmName
            branchName
            clientIdNum
            clientName
            createdOn
            cutOffTime
            extendTargetDate
            fundAbbr
            fundCategory
            fundCode
            fundType
            isSeen
            jointName
            jointNumber
            lastUpdated
            orderNo
            paymentMethod
            remarks {
              content
              title
            }
            requestId
            requestedBy
            riskCategory
            status
            submittedOn
            targetDate
            totalUnits
            totalInvestment {
              fundCurrency
              investmentAmount
            }
            transactionRef
            transactionType
            utmc
            isVerified
            viewable
            accountNo
            noTransactions
            extendRemarks {
              content
              title
            }
            isPhysicalDocRequired
          }
          hqcedashboardBranch {
            branchId
            name
            code
            state
            agentCount
            clientCount
            dailyCount
            rerouteCount
            hardcopyCount
          }
          hqcedashboardExtension {
            accountType
            agentCode
            agentName
            aging
            approvalType
            batchTime
            bdmName
            branchName
            clientIdNum
            clientName
            createdOn
            cutOffTime
            extendTargetDate
            fundAbbr
            fundCategory
            fundCode
            fundType
            isSeen
            jointName
            jointNumber
            lastUpdated
            orderNo
            paymentMethod
            remarks {
              content
              title
            }
            requestId
            requestedBy
            riskCategory
            status
            submittedOn
            targetDate
            totalUnits
            totalInvestment {
              fundCurrency
              investmentAmount
            }
            transactionRef
            transactionType
            utmc
            isVerified
            viewable
            accountNo
            noTransactions
            extendRemarks {
              content
              title
            }
            isPhysicalDocRequired
          }
          systemadmindashboard {
            staffName
            userId
            loginId
            department
            userGroup
            lastLogin
            groupId
            groupName
            branch
            userCount
            roleCount
            roleId
            roleName
            description
            accessLevel
            requestId
            timestamp
            status
            source
            isSeen
            lastUpdated
            action
          }
          activitylogdashboard {
            id
            operationName
            name
            code
            roles
            eventType
            createdOn
            status
            action
            fileSize
            userName
          }
          systemadminadviserdashboard {
            branch
            advisers {
              agentId
              agentName
              bdmName
              bdmCode
              channel
              agency
              licenseCode
              createdOn
              status
              omniEnabled
              agentCode
            }
          }
          funddatadashboard {
            fundAbbr
            fundId
            fundName
            fundClass
            fundCurrency
            lastUpdated
            fundType
            fundCategory
            fundStatus
            isSyariah
            latestNavPerUnit
            previousNavPerUnit
            latestNavDate
            previousNavDate
            grossDistribution
            netDistribution
            unitSplit
            document {
              name
              url
              documentType
            }
            riskCategory
            latestGrossDate
            latestNetDate
            timestamp
            issuingHouse
            closingDate
            unitClosingDate
            startDate
          }
          funddocumentdashboard {
            fileName
            lastUpdate
            status
            fileSize
            remark
            fileType
            uploadedOn
            totalDocuments
            fundDocumentId
            isSeen
          }
          edddashboard {
            caseId
            caseNo
            accountType
            clientIdNum
            clientName
            accountNo
            agentName
            agentCode
            bdmName
            bdmCode
            status
            remarks {
              content
              title
            }
            targetDate
            lastUpdated
            submittedOn
            createdOn
            aging
            isSeen
          }
          branchdashboardv2 {
            accountType
            agentCode
            agentName
            aging
            approvalType
            batchTime
            bdmName
            branchName
            clientIdNum
            clientName
            createdOn
            cutOffTime
            extendTargetDate
            fundAbbr
            fundCategory
            fundCode
            fundType
            isSeen
            jointName
            jointNumber
            lastUpdated
            orderNo
            paymentMethod
            remarks {
              content
              title
            }
            requestId
            requestedBy
            riskCategory
            status
            submittedOn
            targetDate
            totalUnits
            totalInvestment {
              fundCurrency
              investmentAmount
            }
            transactionRef
            transactionType
            utmc
            isVerified
            viewable
            accountNo
            noTransactions
            extendRemarks {
              content
              title
            }
            isPhysicalDocRequired
          }
          branchdashboardv2NonGrouping {
            accountType
            agentCode
            agentName
            aging
            approvalType
            batchTime
            bdmName
            branchName
            clientIdNum
            clientName
            createdOn
            cutOffTime
            extendTargetDate
            fundAbbr
            fundCategory
            fundCode
            fundType
            isSeen
            jointName
            jointNumber
            lastUpdated
            orderNo
            paymentMethod
            remarks {
              content
              title
            }
            requestId
            requestedBy
            riskCategory
            status
            submittedOn
            targetDate
            totalUnits
            totalInvestment {
              fundCurrency
              investmentAmount
            }
            transactionRef
            transactionType
            utmc
            isVerified
            viewable
            accountNo
            noTransactions
            extendRemarks {
              content
              title
            }
            isPhysicalDocRequired
          }
          pendingsubmissiondashboard {
            accountType
            agentCode
            agentName
            aging
            approvalType
            batchTime
            bdmName
            branchName
            clientIdNum
            clientName
            createdOn
            cutOffTime
            extendTargetDate
            fundAbbr
            fundCategory
            fundCode
            fundType
            isSeen
            jointName
            jointNumber
            lastUpdated
            orderNo
            paymentMethod
            remarks {
              content
              title
            }
            requestId
            requestedBy
            riskCategory
            status
            submittedOn
            targetDate
            totalUnits
            totalInvestment {
              fundCurrency
              investmentAmount
            }
            transactionRef
            transactionType
            utmc
            isVerified
            viewable
            accountNo
            noTransactions
            extendRemarks {
              content
              title
            }
            isPhysicalDocRequired
          }
          allsubmitteddashboard {
            accountType
            agentCode
            agentName
            aging
            approvalType
            batchTime
            bdmName
            branchName
            clientIdNum
            clientName
            createdOn
            cutOffTime
            extendTargetDate
            fundAbbr
            fundCategory
            fundCode
            fundType
            isSeen
            jointName
            jointNumber
            lastUpdated
            orderNo
            paymentMethod
            remarks {
              content
              title
            }
            requestId
            requestedBy
            riskCategory
            status
            submittedOn
            targetDate
            totalUnits
            totalInvestment {
              fundCurrency
              investmentAmount
            }
            transactionRef
            transactionType
            utmc
            isVerified
            viewable
            accountNo
            noTransactions
            extendRemarks {
              content
              title
            }
            isPhysicalDocRequired
          }
          allsubmitteddashboardNonGrouping {
            accountType
            agentCode
            agentName
            aging
            approvalType
            batchTime
            bdmName
            branchName
            clientIdNum
            clientName
            createdOn
            cutOffTime
            extendTargetDate
            fundAbbr
            fundCategory
            fundCode
            fundType
            isSeen
            jointName
            jointNumber
            lastUpdated
            orderNo
            paymentMethod
            remarks {
              content
              title
            }
            requestId
            requestedBy
            riskCategory
            status
            submittedOn
            targetDate
            totalUnits
            totalInvestment {
              fundCurrency
              investmentAmount
            }
            transactionRef
            transactionType
            utmc
            isVerified
            viewable
            accountNo
            noTransactions
            extendRemarks {
              content
              title
            }
            isPhysicalDocRequired
          }
          allpendingdashboard {
            accountType
            agentCode
            agentName
            aging
            approvalType
            batchTime
            bdmName
            branchName
            clientIdNum
            clientName
            createdOn
            cutOffTime
            extendTargetDate
            fundAbbr
            fundCategory
            fundCode
            fundType
            isSeen
            jointName
            jointNumber
            lastUpdated
            orderNo
            paymentMethod
            remarks {
              content
              title
            }
            requestId
            requestedBy
            riskCategory
            status
            submittedOn
            targetDate
            totalUnits
            totalInvestment {
              fundCurrency
              investmentAmount
            }
            transactionRef
            transactionType
            utmc
            isVerified
            viewable
            accountNo
            noTransactions
            extendRemarks {
              content
              title
            }
            isPhysicalDocRequired
          }
          investordashboard {
            clientName
            jointClientName
            agentName
            agentCode
            paymentMethod
            orderType
            transactionRef
            transactionType
            lastUpdated
            accountType
            accountNo
            registrationDate
            agentId
            branchId
            branchName
            riskCategory
            aging
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const distributionHistory = /* GraphQL */ `
  query DistributionHistory($input: DistributionHistoryInput) {
    distributionHistory(input: $input) {
      data {
        result {
          message
          status
          result {
            fundCurrency
            latestNavPerUnit
            navChange
            navChangePercentage
            netDistribution
            netChange
            netChangePercentage
            grossDistribution
            grossChange
            grossChangePercentage
            unitSplit
            latestNavDate
            change
            changePercentage
            closingDate
          }
          fundClass
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const eddCaseHistory = /* GraphQL */ `
  query EddCaseHistory($input: CaseHistoryInput) {
    eddCaseHistory(input: $input) {
      data {
        result {
          principal {
            caseId
            caseNo
            eddTrigger {
              content
              title
            }
            eddReason {
              content
              title
            }
            createdOn
            closedOn
            remarks {
              content
              title
            }
            status
            name
            clientIdNumber
          }
          joint {
            caseId
            caseNo
            eddTrigger {
              content
              title
            }
            eddReason {
              content
              title
            }
            createdOn
            closedOn
            remarks {
              content
              title
            }
            status
            name
            clientIdNumber
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const eddClientAccounts = /* GraphQL */ `
  query EddClientAccounts($input: EddClientAccountsInput) {
    eddClientAccounts(input: $input) {
      data {
        result {
          accounts {
            utaId
            accountNumber
            clientId
            clientName
            clientIdNum
            jointId
            jointName
            jointIdNum
            registrationDate
            servicingAdviserName
            servicingAdviserCode
            origin
            hasEddCase
          }
          totalResultCount
          page
          pages
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const eddClientList = /* GraphQL */ `
  query EddClientList($input: EddClientListInput) {
    eddClientList(input: $input) {
      data {
        result {
          clients {
            clientId
            clientName
            clientIdNum
            clientAccountCount
          }
          totalResultCount
          page
          pages
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const eddDashboard = /* GraphQL */ `
  query EddDashboard($input: DashboardInput) {
    eddDashboard(input: $input) {
      data {
        result {
          cases {
            caseId
            caseNo
            accountType
            clientIdNum
            clientName
            accountNo
            agentName
            agentCode
            bdmName
            bdmCode
            status
            remarks {
              content
              title
            }
            targetDate
            lastUpdated
            submittedOn
            createdOn
            aging
            isSeen
          }
          newCount
          rerouteCount
          closeCount
          page
          pages
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const eddModuleList = /* GraphQL */ `
  query EddModuleList($input: EddModuleListInput) {
    eddModuleList(input: $input) {
      data {
        result {
          module {
            moduleId
            name
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const eddReasonList = /* GraphQL */ `
  query EddReasonList($input: EddReasonListInput) {
    eddReasonList(input: $input) {
      data {
        result {
          reasons {
            reasonId
            category
            subCategory
          }
          targetDate
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const fileCheck = /* GraphQL */ `
  query FileCheck($input: FileCheckInput) {
    fileCheck(input: $input) {
      data {
        result {
          status
          message
          fundData {
            fundAbbr
            fundName
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const financeCasesDashboard = /* GraphQL */ `
  query FinanceCasesDashboard($input: DashboardInput) {
    financeCasesDashboard(input: $input) {
      data {
        result {
          transactions {
            clientName
            clientIdNum
            lastUpdated
            transactionType
            transactionRef
            agentCode
            accountNo
            agentName
            fundType
            totalInvestment
            status
            remarks
          }
          page
          pages
          branchCount
          moneyMarketFundCount
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const financeDashboard = /* GraphQL */ `
  query FinanceDashboard($input: DashboardInput) {
    financeDashboard(input: $input) {
      data {
        result {
          transactions {
            clientName
            clientIdNum
            lastUpdated
            transactionType
            transactionRef
            agentCode
            accountNo
            agentName
            fundType
            totalInvestment
            status
          }
          branchVerifiedCount
          totalResultCount
          branchCount
          page
          pages
          moneyMarketFundCount
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const fundDataDashboard = /* GraphQL */ `
  query FundDataDashboard($input: DashboardInput) {
    fundDataDashboard(input: $input) {
      data {
        result {
          transactions {
            fundAbbr
            fundId
            fundName
            fundClass
            fundCurrency
            lastUpdated
            fundType
            fundCategory
            fundStatus
            isSyariah
            latestNavPerUnit
            previousNavPerUnit
            latestNavDate
            previousNavDate
            grossDistribution
            netDistribution
            unitSplit
            document {
              name
              url
              documentType
            }
            riskCategory
            latestGrossDate
            latestNetDate
            timestamp
            issuingHouse
            closingDate
            unitClosingDate
            startDate
          }
          page
          pages
          allFundsCount
          ampFundCount
          filters {
            fundCategory
            fundType
            issuingHouse
            fundClass
            fundCurrency
            fundStatus
            riskCategory
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const fundDocumentDashboard = /* GraphQL */ `
  query FundDocumentDashboard($input: DashboardInput) {
    fundDocumentDashboard(input: $input) {
      data {
        result {
          transactions {
            fileName
            lastUpdate
            status
            fileSize
            remark
            fileType
            uploadedOn
            totalDocuments
            fundDocumentId
            isSeen
          }
          page
          pages
          filters {
            status
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const fundSummary = /* GraphQL */ `
  query FundSummary($input: FundSummaryInput) {
    fundSummary(input: $input) {
      data {
        result {
          fundName
          utmc
          fundSummary {
            nonBusinessDayFrom
            nonBusinessDayTo
            fundCode
            fundCategory
            fundProcessingGroup
            fundDescription
            fundType
            isSyariah
            fundClass
            trustee
            scProcessingGroup
            fundStartDate
            iopEndDate
            maturityDate
            commercialStartDate
            lipperCode
            felSharing
            ageGroup
            riskCategory
            closedEnd
            transactionsAfterIopAllow
            minimumBalanceHoldingUnits
            distributedBy
            isFea
            landingFund
          }
          investmentSummary {
            instruction
            dividentTiming
            minimumDividentPayoutAmount
            dailyIncomeDistribution
            fundBaseCurrency
            fundClassCurrency
            isAipAllowed
            coolOffDays
            moneySightedFund
            dedicatedFund
            differenceFundPrice
            salesSettlementDay
          }
          feesSummary {
            issuePrice
            annualManagementFee
            annualTrusteeFee
            ampFee
            minSalesFees
            maxSalesFees
            exitFeesCharge
            exitFeesChargeAmount
            gstParameter
          }
          epfSummary {
            epfApproved
            epfStatus
            minSalesFee
            maxSalesFee
            maxNewSalesAmountEpf
            minNewSalesAmountEpf
            minTopupAmountEpf
            exitFeesCharge
            exitFeesChargeAmount
            maxTopupAmountEpf
          }
          redemptionSummary {
            redemptionType
            minRedemptionUnits
            redemptionSettleDate
            redemption
            redemptionCoolOff
            redemptionCutOffTime
            batchCutOffTime
          }
          switchingSummary {
            switchingType
            switchingFee
            transactionsAfterIopSwitchIn
            transactionsAfterIopSwitchOut
            minSwitchOutUnits
            switchingSettlementDate
            switchingGroup
            minSalesAmountEpf
            minSalesAmountNonEpf
            minTopUpEpf
            minTopUpNonEpf
          }
          salesSummary {
            transactionsAfterIopNewSales
            minNewSalesAmount
            maxNewSalesAmount
            minBalanceHoldingUnits
            salesCutOffTime
            batchCutOffTime
          }
          topUpSummary {
            transactionsAfterIopTopUpSales
            minTopUpAmount
            maxTopUpAmount
          }
          transferSummary {
            transactionsAfterIopTransfer
            minTransferOutUnits
            transferFee
          }
          description
          filters {
            fundClass
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const fundUploadData = /* GraphQL */ `
  query FundUploadData($input: FundUploadDataInput) {
    fundUploadData(input: $input) {
      data {
        result {
          message
          data
          user
          transaction {
            status
            user
            remark
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const generateEddReportMonthly = /* GraphQL */ `
  query GenerateEddReportMonthly($input: GenerateEddReportMonthlyInput) {
    generateEddReportMonthly(input: $input) {
      data {
        result {
          message
          url
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const getDropDownList = /* GraphQL */ `
  query GetDropDownList {
    getDropDownList {
      data {
        result {
          departments {
            id
            value
          }
          accessLevel {
            id
            value
          }
          branches {
            id
            value
          }
          groups {
            id
            value
          }
          roles {
            id
            value
          }
          agency {
            id
            value
          }
          channel {
            id
            value
          }
          eventTypes
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const getEddModule = /* GraphQL */ `
  query GetEddModule($input: GetEddModuleInput) {
    getEddModule(input: $input) {
      data {
        result {
          title
          description
          options {
            id
            position
            parent
            title
            description
            info
            type
            hasRemark
            hasDoc
            multiSelection
            autoHide
            values
            valuesDescription
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const getHolidays = /* GraphQL */ `
  query GetHolidays {
    getHolidays {
      data {
        result {
          holidays
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const getIdInformation = /* GraphQL */ `
  query GetIdInformation($input: IdInfoInput) {
    getIdInformation(input: $input) {
      data {
        result {
          info
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const getLogData = /* GraphQL */ `
  query GetLogData($input: GetLogDataInput) {
    getLogData(input: $input) {
      data {
        result {
          message
          status
          data {
            code
            operationName
            fileSize
            roles
            createdOn
            name
            eventType
            operationStatus
            data
            type
            timestamp
            remark
            status
            fundData
            authorName
            approverName
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const getOperationalReportFilters = /* GraphQL */ `
  query GetOperationalReportFilters($input: getOperationalReportFiltersInput) {
    getOperationalReportFilters(input: $input) {
      data {
        result {
          column
          value
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const getOperationalReport = /* GraphQL */ `
  query GetOperationalReport($input: getOperationalReportFiltersInput) {
    getOperationalReport(input: $input) {
      data {
        result {
          reportName
          message
          status
          id
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const getOrderSummaryV2Cr = /* GraphQL */ `
  query GetOrderSummaryV2Cr($input: OrderSummaryInputV2) {
    getOrderSummaryV2Cr(input: $input) {
      data {
        result {
          requestId
          requesterName
          approval {
            eventType
            name
            remarks
            status
          }
          status
          orderNo
          transactionRef
          transactionType
          isEtb
          remarks {
            content
            title
          }
          extensionRemarks {
            date
            remarks
          }
          totalInvestment {
            fundCurrency
            investmentAmount
          }
          totalRedeemAmount {
            fundCurrency
            redeemAmount
          }
          transactionDetails {
            accountNo
            accountOperationMode
            accountType
            bdmName
            kibProcessingBranch
            registrationDate
            servicingAdviserCode
            servicingAdviserName
            utaId
          }
          investmentSummary {
            accountFund
            distributionInstructions
            feaTagged
            fundClass
            fundCode
            fundCurrency
            fundName
            investmentAmount
            investmentType
            productType
            recurring
            salesCharge
            utmc
            isTopup
          }
          paymentSummary {
            bankAccountName
            bankAccountNumber
            bankName
            checkNumber
            clientName
            clientTrustAccountNumber
            epfAccountNumber
            epfReferenceNo
            frequency
            fundCurrency
            investmentAmount
            kibBankAccountNumber
            kibBankName
            paymentMethod
            paymentOn
            proofOfPayment {
              url
              base64
              date
              name
              path
              size
              type
            }
            recurringBank
            recurringType
            referenceNumber
            remark
            surplusNote
            isCombined
            status
          }
          profile {
            isEtb
            addressInformation {
              mailingAddress {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
              permanentAddress {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
            }
            bankInformation {
              foreignBank {
                id
                bankAccountName
                bankAccountNumber
                bankLocation
                bankName
                bankSwiftCode
                currency
                isNewBank
              }
              localBank {
                id
                bankAccountName
                bankAccountNumber
                bankLocation
                bankName
                bankSwiftCode
                currency
                isNewBank
              }
            }
            contactDetails {
              email
              faxNumber
              homeNumber
              mobileNumber
              officeNumber
            }
            crsDeclaration {
              taxResident
              tin {
                country
                reason
                tinNumber
              }
            }
            employmentInformation {
              address {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
              annualIncome
              nameOfEmployer
              natureOfBusiness
              occupation
            }
            epfDetails {
              epfAccountType
              epfMemberNumber
            }
            fatcaDeclaration {
              certificate {
                url
                base64
                date
                name
                path
                size
                type
              }
              confirmAddress
              correspondenceDeclaration
              reason
              usBorn
              usCitizen
              formW9 {
                url
                base64
                date
                name
                path
                size
                type
              }
              formW8Ben {
                url
                base64
                date
                name
                path
                size
                type
              }
              pendingDoc
            }
            feaDeclaration {
              balance
              borrowingFacility
              resident
            }
            idFile
            idNumber
            idType
            name
            personalDetails {
              bumiputera
              countryOfBirth
              dateOfBirth
              educationLevel
              gender
              maritalStatus
              monthlyHouseholdIncome
              mothersMaidenName
              nationality
              placeOfBirth
              race
              relationship
              salutation
              expirationDate
              riskProfile
            }
            riskInfo {
              fundChoice
              hnwStatus
              message
              rangeOfReturn
              riskCategory
              typeOfFunds
            }
            lastUpdated
            principal
            accountHolder
            origin
          }
          documentSummary {
            accountType
            softcopy {
              required
              documents {
                mainHeader
                subHeader
                documents {
                  title
                  name
                  url
                  type
                  label
                }
              }
            }
            hardcopy {
              required
              utmcDocs {
                mainHeader
                subHeader
                documents {
                  title
                  name
                  url
                  type
                  label
                }
              }
              accDocs {
                mainHeader
                subHeader
                documents {
                  title
                  name
                  url
                  type
                  label
                }
              }
            }
          }
          riskSubmitted {
            fundChoice
            hnwStatus
            message
            rangeOfReturn
            riskCategory
            typeOfFunds
          }
          totalSwitchOutUnits
          totalRedeemUnits
          switchingSummary {
            switchInInfo {
              utmc
              fundCode
              fundName
              fundClass
              currency
              availableUnits
              salesCharge
              distributionInstructions
              exitFee
              switchingFee
              switchingLeadTime
              units
              switchOutUnits
              isNewFund
            }
            switchOutInfo {
              utmc
              fundCode
              fundName
              fundClass
              currency
              availableUnits
              salesCharge
              distributionInstructions
              exitFee
              switchingFee
              switchingLeadTime
              units
              switchOutUnits
              isNewFund
            }
          }
          redemptionSummary {
            fundId
            fundName
            utmc
            fundAbbr
            fundCode
            units
            fundProcessingGroup
            fundType
            fundCategory
            isSyariah
            fundingOption
            isEpf
            fundIssuer
            availableUnits
            availableAmounts
            fundClass
            fundCurrency
            distributionInstruction
            exitFee
            redeemAmount
            currency
          }
          redeemSummary {
            redeemType
            redemptionMethod
            incomeTaxDetails {
              taxNumber
              reason
            }
            proceedPayable {
              bankSummary {
                localBank {
                  id
                  bankAccountName
                  bankAccountNumber
                  bankLocation
                  bankName
                  bankSwiftCode
                  currency
                  isNewBank
                }
                foreignBank {
                  id
                  bankAccountName
                  bankAccountNumber
                  bankLocation
                  bankName
                  bankSwiftCode
                  currency
                  isNewBank
                }
              }
              ctaSummary {
                ctaNumber
                name
              }
              epfDetails {
                epfNumber
                epfType
              }
            }
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const getOrderSummaryV2 = /* GraphQL */ `
  query GetOrderSummaryV2($input: OrderSummaryInputV2) {
    getOrderSummaryV2(input: $input) {
      data {
        result {
          requestId
          requesterName
          approval {
            eventType
            name
            remarks
            status
          }
          status
          orderNo
          transactionRef
          transactionType
          isEtb
          remarks {
            content
            title
          }
          extensionRemarks {
            date
            remarks
          }
          totalInvestment {
            fundCurrency
            investmentAmount
          }
          totalRedeemAmount {
            fundCurrency
            redeemAmount
          }
          transactionDetails {
            accountNo
            accountOperationMode
            accountType
            bdmName
            kibProcessingBranch
            registrationDate
            servicingAdviserCode
            servicingAdviserName
            utaId
          }
          investmentSummary {
            accountFund
            distributionInstructions
            feaTagged
            fundClass
            fundCode
            fundCurrency
            fundName
            investmentAmount
            investmentType
            productType
            recurring
            salesCharge
            utmc
            isTopup
          }
          paymentSummary {
            bankAccountName
            bankAccountNumber
            bankName
            checkNumber
            clientName
            clientTrustAccountNumber
            epfAccountNumber
            epfReferenceNo
            frequency
            fundCurrency
            investmentAmount
            kibBankAccountNumber
            kibBankName
            paymentMethod
            paymentOn
            proofOfPayment {
              url
              base64
              date
              name
              path
              size
              type
            }
            recurringBank
            recurringType
            referenceNumber
            remark
            surplusNote
            isCombined
            status
          }
          profile {
            isEtb
            addressInformation {
              mailingAddress {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
              permanentAddress {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
            }
            bankInformation {
              foreignBank {
                id
                bankAccountName
                bankAccountNumber
                bankLocation
                bankName
                bankSwiftCode
                currency
                isNewBank
              }
              localBank {
                id
                bankAccountName
                bankAccountNumber
                bankLocation
                bankName
                bankSwiftCode
                currency
                isNewBank
              }
            }
            contactDetails {
              email
              faxNumber
              homeNumber
              mobileNumber
              officeNumber
            }
            crsDeclaration {
              taxResident
              tin {
                country
                reason
                tinNumber
              }
            }
            employmentInformation {
              address {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
              annualIncome
              nameOfEmployer
              natureOfBusiness
              occupation
            }
            epfDetails {
              epfAccountType
              epfMemberNumber
            }
            fatcaDeclaration {
              certificate {
                url
                base64
                date
                name
                path
                size
                type
              }
              confirmAddress
              correspondenceDeclaration
              reason
              usBorn
              usCitizen
              formW9 {
                url
                base64
                date
                name
                path
                size
                type
              }
              formW8Ben {
                url
                base64
                date
                name
                path
                size
                type
              }
              pendingDoc
            }
            feaDeclaration {
              balance
              borrowingFacility
              resident
            }
            idFile
            idNumber
            idType
            name
            personalDetails {
              bumiputera
              countryOfBirth
              dateOfBirth
              educationLevel
              gender
              maritalStatus
              monthlyHouseholdIncome
              mothersMaidenName
              nationality
              placeOfBirth
              race
              relationship
              salutation
              expirationDate
              riskProfile
            }
            riskInfo {
              fundChoice
              hnwStatus
              message
              rangeOfReturn
              riskCategory
              typeOfFunds
            }
            lastUpdated
            principal
            accountHolder
            origin
          }
          documentSummary {
            accountType
            softcopy {
              required
              documents {
                mainHeader
                subHeader
                documents {
                  title
                  name
                  url
                  type
                  label
                }
              }
            }
            hardcopy {
              required
              utmcDocs {
                mainHeader
                subHeader
                documents {
                  title
                  name
                  url
                  type
                  label
                }
              }
              accDocs {
                mainHeader
                subHeader
                documents {
                  title
                  name
                  url
                  type
                  label
                }
              }
            }
          }
          riskSubmitted {
            fundChoice
            hnwStatus
            message
            rangeOfReturn
            riskCategory
            typeOfFunds
          }
          totalSwitchOutUnits
          totalRedeemUnits
          switchingSummary {
            switchInInfo {
              utmc
              fundCode
              fundName
              fundClass
              currency
              availableUnits
              salesCharge
              distributionInstructions
              exitFee
              switchingFee
              switchingLeadTime
              units
              switchOutUnits
              isNewFund
            }
            switchOutInfo {
              utmc
              fundCode
              fundName
              fundClass
              currency
              availableUnits
              salesCharge
              distributionInstructions
              exitFee
              switchingFee
              switchingLeadTime
              units
              switchOutUnits
              isNewFund
            }
          }
          redemptionSummary {
            fundId
            fundName
            utmc
            fundAbbr
            fundCode
            units
            fundProcessingGroup
            fundType
            fundCategory
            isSyariah
            fundingOption
            isEpf
            fundIssuer
            availableUnits
            availableAmounts
            fundClass
            fundCurrency
            distributionInstruction
            exitFee
            redeemAmount
            currency
          }
          redeemSummary {
            redeemType
            redemptionMethod
            incomeTaxDetails {
              taxNumber
              reason
            }
            proceedPayable {
              bankSummary {
                localBank {
                  id
                  bankAccountName
                  bankAccountNumber
                  bankLocation
                  bankName
                  bankSwiftCode
                  currency
                  isNewBank
                }
                foreignBank {
                  id
                  bankAccountName
                  bankAccountNumber
                  bankLocation
                  bankName
                  bankSwiftCode
                  currency
                  isNewBank
                }
              }
              ctaSummary {
                ctaNumber
                name
              }
              epfDetails {
                epfNumber
                epfType
              }
            }
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const getOrderSummary = /* GraphQL */ `
  query GetOrderSummary($input: OrderSummaryInput) {
    getOrderSummary(input: $input) {
      data {
        result {
          approval {
            eventType
            name
            remarks
            status
          }
          extensionRemarks {
            date
            remarks
          }
          investmentSummary {
            accountFund
            distributionInstructions
            feaTagged
            fundClass
            fundCode
            fundCurrency
            fundName
            investmentAmount
            investmentType
            productType
            recurring
            salesCharge
            utmc
            isTopup
          }
          orderNo
          paymentSummary {
            bankAccountName
            bankAccountNumber
            bankName
            checkNumber
            clientName
            clientTrustAccountNumber
            epfAccountNumber
            epfReferenceNo
            frequency
            fundCurrency
            investmentAmount
            kibBankAccountNumber
            kibBankName
            paymentMethod
            paymentOn
            proofOfPayment {
              url
              base64
              date
              name
              path
              size
              type
            }
            recurringBank
            recurringType
            referenceNumber
            remark
            surplusNote
            isCombined
            status
          }
          profile {
            isEtb
            addressInformation {
              mailingAddress {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
              permanentAddress {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
            }
            bankInformation {
              foreignBank {
                id
                bankAccountName
                bankAccountNumber
                bankLocation
                bankName
                bankSwiftCode
                currency
                isNewBank
              }
              localBank {
                id
                bankAccountName
                bankAccountNumber
                bankLocation
                bankName
                bankSwiftCode
                currency
                isNewBank
              }
            }
            contactDetails {
              email
              faxNumber
              homeNumber
              mobileNumber
              officeNumber
            }
            crsDeclaration {
              taxResident
              tin {
                country
                reason
                tinNumber
              }
            }
            employmentInformation {
              address {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
              annualIncome
              nameOfEmployer
              natureOfBusiness
              occupation
            }
            epfDetails {
              epfAccountType
              epfMemberNumber
            }
            fatcaDeclaration {
              certificate {
                url
                base64
                date
                name
                path
                size
                type
              }
              confirmAddress
              correspondenceDeclaration
              reason
              usBorn
              usCitizen
              formW9 {
                url
                base64
                date
                name
                path
                size
                type
              }
              formW8Ben {
                url
                base64
                date
                name
                path
                size
                type
              }
              pendingDoc
            }
            feaDeclaration {
              balance
              borrowingFacility
              resident
            }
            idFile
            idNumber
            idType
            name
            personalDetails {
              bumiputera
              countryOfBirth
              dateOfBirth
              educationLevel
              gender
              maritalStatus
              monthlyHouseholdIncome
              mothersMaidenName
              nationality
              placeOfBirth
              race
              relationship
              salutation
              expirationDate
              riskProfile
            }
            riskInfo {
              fundChoice
              hnwStatus
              message
              rangeOfReturn
              riskCategory
              typeOfFunds
            }
            lastUpdated
            principal
            accountHolder
            origin
          }
          remarks {
            content
            title
          }
          requestId
          requestorName
          status
          totalInvestment {
            fundCurrency
            investmentAmount
          }
          transactionDetails {
            accountNo
            accountOperationMode
            accountType
            bdmName
            kibProcessingBranch
            registrationDate
            servicingAdviserCode
            servicingAdviserName
            utaId
          }
          transactionRef
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const getTransactionStatusOverview = /* GraphQL */ `
  query GetTransactionStatusOverview($input: TransactionStatusOverviewInput) {
    getTransactionStatusOverview(input: $input) {
      data {
        result {
          statusOverview {
            level
            steps {
              createdOn
              status
              current
            }
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const getTransactionTracking = /* GraphQL */ `
  query GetTransactionTracking($input: TransactionTrackingInput) {
    getTransactionTracking(input: $input) {
      data {
        result {
          trackingSummary {
            level
            data {
              createdOn
              status
              name
              username
              remarks {
                title
                content
              }
            }
          }
          filters {
            levels
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const hqCeDashboard = /* GraphQL */ `
  query HqCeDashboard($input: DashboardInput) {
    hqCeDashboard(input: $input) {
      data {
        result {
          overview {
            daily
            reroute
            extension
            hardcopy
          }
          totalResultCount
          page
          pages
          branches {
            branchId
            name
            code
            state
            agentCount
            clientCount
            dailyCount
            rerouteCount
            hardcopyCount
          }
          stateList
          branchCount
          transactions {
            accountType
            agentCode
            agentName
            aging
            approvalType
            batchTime
            bdmName
            branchName
            clientIdNum
            clientName
            createdOn
            cutOffTime
            extendTargetDate
            fundAbbr
            fundCategory
            fundCode
            fundType
            isSeen
            jointName
            jointNumber
            lastUpdated
            orderNo
            paymentMethod
            remarks {
              content
              title
            }
            requestId
            requestedBy
            riskCategory
            status
            submittedOn
            targetDate
            totalUnits
            totalInvestment {
              fundCurrency
              investmentAmount
            }
            transactionRef
            transactionType
            utmc
            isVerified
            viewable
            accountNo
            noTransactions
            extendRemarks {
              content
              title
            }
            isPhysicalDocRequired
          }
          extensionCount
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const inboxDashboard = /* GraphQL */ `
  query InboxDashboard($input: InboxInput) {
    inboxDashboard(input: $input) {
      data {
        result {
          inbox {
            notificationId
            title
            message
            senderName
            source
            searchKey
            searchType
            searchLink
            searchId
            isRead
            updatedAt
            createdOn
            isSeen
          }
          newMessageCount
          page
          pages
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const investorDashboard = /* GraphQL */ `
  query InvestorDashboard($input: DashboardInput) {
    investorDashboard(input: $input) {
      data {
        result {
          filters {
            paymentMethod
            riskCategory
          }
          investorsCount
          investors {
            branchName
            investorList {
              investmentSummary {
                accountFund
                distributionInstructions
                feaTagged
                fundClass
                fundCode
                fundCurrency
                fundName
                investmentAmount
                investmentType
                productType
                recurring
                salesCharge
                utmc
                isTopup
              }
              lastUpdated
              profile {
                isEtb
                addressInformation {
                  mailingAddress {
                    address {
                      line1
                      line2
                      line3
                      line4
                    }
                    city
                    country
                    postCode
                    state
                  }
                  permanentAddress {
                    address {
                      line1
                      line2
                      line3
                      line4
                    }
                    city
                    country
                    postCode
                    state
                  }
                }
                bankInformation {
                  foreignBank {
                    id
                    bankAccountName
                    bankAccountNumber
                    bankLocation
                    bankName
                    bankSwiftCode
                    currency
                    isNewBank
                  }
                  localBank {
                    id
                    bankAccountName
                    bankAccountNumber
                    bankLocation
                    bankName
                    bankSwiftCode
                    currency
                    isNewBank
                  }
                }
                contactDetails {
                  email
                  faxNumber
                  homeNumber
                  mobileNumber
                  officeNumber
                }
                crsDeclaration {
                  taxResident
                  tin {
                    country
                    reason
                    tinNumber
                  }
                }
                employmentInformation {
                  address {
                    address {
                      line1
                      line2
                      line3
                      line4
                    }
                    city
                    country
                    postCode
                    state
                  }
                  annualIncome
                  nameOfEmployer
                  natureOfBusiness
                  occupation
                }
                epfDetails {
                  epfAccountType
                  epfMemberNumber
                }
                fatcaDeclaration {
                  certificate {
                    url
                    base64
                    date
                    name
                    path
                    size
                    type
                  }
                  confirmAddress
                  correspondenceDeclaration
                  reason
                  usBorn
                  usCitizen
                  formW9 {
                    url
                    base64
                    date
                    name
                    path
                    size
                    type
                  }
                  formW8Ben {
                    url
                    base64
                    date
                    name
                    path
                    size
                    type
                  }
                  pendingDoc
                }
                feaDeclaration {
                  balance
                  borrowingFacility
                  resident
                }
                idFile
                idNumber
                idType
                name
                personalDetails {
                  bumiputera
                  countryOfBirth
                  dateOfBirth
                  educationLevel
                  gender
                  maritalStatus
                  monthlyHouseholdIncome
                  mothersMaidenName
                  nationality
                  placeOfBirth
                  race
                  relationship
                  salutation
                  expirationDate
                  riskProfile
                }
                riskInfo {
                  fundChoice
                  hnwStatus
                  message
                  rangeOfReturn
                  riskCategory
                  typeOfFunds
                }
                lastUpdated
                principal
                accountHolder
                origin
              }
              transactionDetails {
                accountNo
                accountOperationMode
                accountType
                bdmName
                kibProcessingBranch
                registrationDate
                servicingAdviserCode
                servicingAdviserName
                utaId
              }
              transactionRef
              branchName
            }
          }
          page
          pages
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const investorProfile = /* GraphQL */ `
  query InvestorProfile($input: investorProfileInput) {
    investorProfile(input: $input) {
      data {
        result {
          documentSummary {
            softCopy {
              path
              name
              docName
              url
              type
            }
            hardCopy {
              path
              name
              docName
              url
              type
            }
          }
          profile {
            addressInformation {
              mailingAddress {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
              permanentAddress {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
            }
            bankInformation {
              foreignBank {
                id
                bankAccountName
                bankAccountNumber
                bankLocation
                bankName
                bankSwiftCode
                currency
                isNewBank
              }
              localBank {
                id
                bankAccountName
                bankAccountNumber
                bankLocation
                bankName
                bankSwiftCode
                currency
                isNewBank
              }
            }
            contactDetails {
              email
              faxNumber
              homeNumber
              mobileNumber
              officeNumber
            }
            crsDeclaration {
              taxResident
              tin {
                country
                reason
                tinNumber
              }
            }
            employmentInformation {
              address {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
              annualIncome
              nameOfEmployer
              natureOfBusiness
              occupation
            }
            epfDetails {
              epfAccountType
              epfMemberNumber
            }
            fatcaDeclaration {
              certificate
              confirmAddress
              correspondenceDeclaration
              reason
              usBorn
              usCitizen
              formW9
              formW8Ben
            }
            feaDeclaration {
              balance
              borrowingFacility
              resident
            }
            idFile
            idNumber
            idType
            name
            personalDetails {
              bumiputera
              countryOfBirth
              dateOfBirth
              educationLevel
              gender
              maritalStatus
              monthlyHouseholdIncome
              mothersMaidenName
              nationality
              placeOfBirth
              race
              relationship
              salutation
              expirationDate
              riskProfile
            }
            riskInfo {
              fundChoice
              hnwStatus
              message
              rangeOfReturn
              riskCategory
              typeOfFunds
            }
            lastUpdated
            principal
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const logOut = /* GraphQL */ `
  query LogOut {
    logOut {
      data {
        result {
          status
          message
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const newCaseDefinition = /* GraphQL */ `
  query NewCaseDefinition($input: NewCaseDefinitionInput) {
    newCaseDefinition(input: $input) {
      data {
        result {
          targetDate
          eddTrigger {
            reasonId
            category
            subCategory
          }
          eddReason {
            reasonId
            category
            subCategory
          }
          investmentSummary {
            accountFund
            distributionInstructions
            feaTagged
            fundClass
            fundCode
            fundCurrency
            fundName
            investmentAmount
            investmentType
            productType
            recurring
            salesCharge
            utmc
            isTopup
          }
          transactionDetails {
            accountNo
            accountOperationMode
            accountType
            bdmName
            kibProcessingBranch
            registrationDate
            servicingAdviserCode
            servicingAdviserName
            utaId
          }
          profile {
            isEtb
            addressInformation {
              mailingAddress {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
              permanentAddress {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
            }
            bankInformation {
              foreignBank {
                id
                bankAccountName
                bankAccountNumber
                bankLocation
                bankName
                bankSwiftCode
                currency
                isNewBank
              }
              localBank {
                id
                bankAccountName
                bankAccountNumber
                bankLocation
                bankName
                bankSwiftCode
                currency
                isNewBank
              }
            }
            contactDetails {
              email
              faxNumber
              homeNumber
              mobileNumber
              officeNumber
            }
            crsDeclaration {
              taxResident
              tin {
                country
                reason
                tinNumber
              }
            }
            employmentInformation {
              address {
                address {
                  line1
                  line2
                  line3
                  line4
                }
                city
                country
                postCode
                state
              }
              annualIncome
              nameOfEmployer
              natureOfBusiness
              occupation
            }
            epfDetails {
              epfAccountType
              epfMemberNumber
            }
            fatcaDeclaration {
              certificate {
                url
                base64
                date
                name
                path
                size
                type
              }
              confirmAddress
              correspondenceDeclaration
              reason
              usBorn
              usCitizen
              formW9 {
                url
                base64
                date
                name
                path
                size
                type
              }
              formW8Ben {
                url
                base64
                date
                name
                path
                size
                type
              }
              pendingDoc
            }
            feaDeclaration {
              balance
              borrowingFacility
              resident
            }
            idFile
            idNumber
            idType
            name
            personalDetails {
              bumiputera
              countryOfBirth
              dateOfBirth
              educationLevel
              gender
              maritalStatus
              monthlyHouseholdIncome
              mothersMaidenName
              nationality
              placeOfBirth
              race
              relationship
              salutation
              expirationDate
              riskProfile
            }
            riskInfo {
              fundChoice
              hnwStatus
              message
              rangeOfReturn
              riskCategory
              typeOfFunds
            }
            lastUpdated
            principal
            accountHolder
            origin
          }
          onboardingReceipt {
            url
            base64
            date
            name
            path
            size
            type
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const getOperationalReportDashboard = /* GraphQL */ `
  query GetOperationalReportDashboard($input: ReportDashboardInput) {
    getOperationalReportDashboard(input: $input) {
      data {
        result {
          reportName
          lastDownloaded
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const opsCasesDashboard = /* GraphQL */ `
  query OpsCasesDashboard($input: DashboardInput) {
    opsCasesDashboard(input: $input) {
      data {
        result {
          transactions {
            accountType
            agentCode
            agentName
            aging
            approvalType
            batchTime
            bdmName
            branchName
            clientIdNum
            clientName
            createdOn
            cutOffTime
            extendTargetDate
            fundAbbr
            fundCategory
            fundCode
            fundType
            isSeen
            jointName
            jointNumber
            lastUpdated
            orderNo
            paymentMethod
            remarks {
              content
              title
            }
            requestId
            requestedBy
            riskCategory
            status
            submittedOn
            targetDate
            totalUnits
            totalInvestment {
              fundCurrency
              investmentAmount
            }
            transactionRef
            transactionType
            utmc
            isVerified
            viewable
            accountNo
            noTransactions
            extendRemarks {
              content
              title
            }
            isPhysicalDocRequired
          }
          totalResultCount
          rerouteCount
          rejectCount
          historyCount
          pages
          page
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const opsDashboard = /* GraphQL */ `
  query OpsDashboard($input: DashboardInput) {
    opsDashboard(input: $input) {
      data {
        result {
          transactions {
            accountType
            agentCode
            agentName
            aging
            approvalType
            batchTime
            bdmName
            branchName
            clientIdNum
            clientName
            createdOn
            cutOffTime
            extendTargetDate
            fundAbbr
            fundCategory
            fundCode
            fundType
            isSeen
            jointName
            jointNumber
            lastUpdated
            orderNo
            paymentMethod
            remarks {
              content
              title
            }
            requestId
            requestedBy
            riskCategory
            status
            submittedOn
            targetDate
            totalUnits
            totalInvestment {
              fundCurrency
              investmentAmount
            }
            transactionRef
            transactionType
            utmc
            isVerified
            viewable
            accountNo
            noTransactions
            extendRemarks {
              content
              title
            }
            isPhysicalDocRequired
          }
          branchCount
          totalResultCount
          verifiedCount
          sightedCount
          epfCount
          ddaCount
          financeCount
          pages
          page
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const pendingSubmissionDashboard = /* GraphQL */ `
  query PendingSubmissionDashboard($input: BranchDashboardInput) {
    pendingSubmissionDashboard(input: $input) {
      data {
        result {
          groupedTransactions {
            grouping {
              groupingData {
                jointName
                jointNumber
                orderNo
                principalName
                principalNumber
                viewable
                accountNo
                noTransactions
              }
              transactionData {
                data {
                  accountType
                  agentCode
                  agentName
                  aging
                  approvalType
                  batchTime
                  bdmName
                  branchName
                  clientIdNum
                  clientName
                  createdOn
                  cutOffTime
                  extendTargetDate
                  fundAbbr
                  fundCategory
                  fundCode
                  fundType
                  isSeen
                  jointName
                  jointNumber
                  lastUpdated
                  orderNo
                  paymentMethod
                  remarks {
                    content
                    title
                  }
                  requestId
                  requestedBy
                  riskCategory
                  status
                  submittedOn
                  targetDate
                  totalUnits
                  totalInvestment {
                    fundCurrency
                    investmentAmount
                  }
                  transactionRef
                  transactionType
                  utmc
                  isVerified
                  viewable
                  accountNo
                  noTransactions
                  extendRemarks {
                    content
                    title
                  }
                  isPhysicalDocRequired
                }
              }
              type
            }
          }
          totalResultCount
          statuses {
            tab
            count
          }
          page
          pages
          filters {
            accountType
            fundType
            paymentMethod
            transactionType
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const ppaDownloadV2 = /* GraphQL */ `
  query PpaDownloadV2($input: TransactionRefArrayInput) {
    ppaDownloadV2(input: $input) {
      data {
        result {
          message
          link
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const ppaDownload = /* GraphQL */ `
  query PpaDownload($input: TransactionRefArrayInput) {
    ppaDownload(input: $input) {
      data {
        result {
          message
          link
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const statusDropDown = /* GraphQL */ `
  query StatusDropDown($input: StatusDropDownInput) {
    statusDropDown(input: $input) {
      data {
        result {
          statusList
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const systemAdminAdviserDashboard = /* GraphQL */ `
  query SystemAdminAdviserDashboard($input: DashboardInput) {
    systemAdminAdviserDashboard(input: $input) {
      data {
        result {
          advisers {
            branch
            advisers {
              agentId
              agentName
              bdmName
              bdmCode
              channel
              agency
              licenseCode
              createdOn
              status
              omniEnabled
              agentCode
            }
          }
          page
          pages
          adviserCount
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const systemAdminDashboard = /* GraphQL */ `
  query SystemAdminDashboard($input: DashboardInput) {
    systemAdminDashboard(input: $input) {
      data {
        result {
          transactions {
            staffName
            userId
            loginId
            department
            userGroup
            lastLogin
            groupId
            groupName
            branch
            userCount
            roleCount
            roleId
            roleName
            description
            accessLevel
            requestId
            timestamp
            status
            source
            isSeen
            lastUpdated
            action
          }
          overview {
            totalSuspendedUsers
            totalActiveUsers
            totalActiveGroups
            totalActiveRoles
            totalEditUserRequest
            totalBulkImportRequest
            totalCreatedUserRequest
            totalSuspendedUserRequest
            totalReactivateUserRequest
            totalTerminateUserRequest
            totalGroupRequest
            totalEditGroupRequest
            totalSuspendedGroupRequest
            totalReactivateGroupRequest
            totalDeleteGroupRequest
            totalRoleRequest
            totalEditRoleRequest
            totalSuspendedRoleRequest
            totalReactivateRoleRequest
            totalDeleteRoleRequest
          }
          page
          pages
          bulkId
          totalUserCount
          totalGroupCount
          totalRoleCount
          groupCount
          roleCount
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const systemAdminExport = /* GraphQL */ `
  query SystemAdminExport($input: ExportInput) {
    systemAdminExport(input: $input) {
      data {
        result {
          message
          link
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const systemAdminInboxData = /* GraphQL */ `
  query SystemAdminInboxData($input: SystemAdminInboxDataInput) {
    systemAdminInboxData(input: $input) {
      data {
        result {
          message
          status
          data {
            value
            authorName
            approverName
            approvalStatus
            isExpired
            source
            type
            createdOn
            remarks
            fundData
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const systemSettingsDashboard = /* GraphQL */ `
  query SystemSettingsDashboard($input: DashboardInput) {
    systemSettingsDashboard(input: $input) {
      data {
        result {
          settings {
            settingsId
            displayName
            description
            status
            currentStatus
            requestStatus
            requestEffectiveDate
            requestId
            effectiveDate
            lastUpdated
            isSeen
          }
          totalResultCount
          page
          pages
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const trackingDashboard = /* GraphQL */ `
  query TrackingDashboard($input: BranchDashboardInput) {
    trackingDashboard(input: $input) {
      data {
        result {
          groupedTransactions {
            grouping {
              type
              groupingData {
                key
                value
              }
              transactionData {
                grouping {
                  groupingData {
                    jointName
                    jointNumber
                    orderNo
                    principalName
                    principalNumber
                    viewable
                    accountNo
                    noTransactions
                  }
                  transactionData {
                    data {
                      accountType
                      agentCode
                      agentName
                      aging
                      approvalType
                      batchTime
                      bdmName
                      branchName
                      clientIdNum
                      clientName
                      createdOn
                      cutOffTime
                      extendTargetDate
                      fundAbbr
                      fundCategory
                      fundCode
                      fundType
                      isSeen
                      jointName
                      jointNumber
                      lastUpdated
                      orderNo
                      paymentMethod
                      remarks {
                        content
                        title
                      }
                      requestId
                      requestedBy
                      riskCategory
                      status
                      submittedOn
                      targetDate
                      totalUnits
                      totalInvestment {
                        fundCurrency
                        investmentAmount
                      }
                      transactionRef
                      transactionType
                      utmc
                      isVerified
                      viewable
                      accountNo
                      noTransactions
                      extendRemarks {
                        content
                        title
                      }
                      isPhysicalDocRequired
                    }
                  }
                  type
                }
              }
            }
          }
          totalResultCount
          filters {
            branchName
            transactionType
            branchStatus
            batchTime
          }
          page
          pages
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const transactionPdf = /* GraphQL */ `
  query TransactionPdf($input: TransactionPdfInput) {
    transactionPdf(input: $input) {
      data {
        result {
          message
          status
          path
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const transactionReasonListV2 = /* GraphQL */ `
  query TransactionReasonListV2($input: TransactionReasonListInputV2) {
    transactionReasonListV2(input: $input) {
      data {
        result {
          reasons {
            reasonId
            category
            subCategory
          }
          targetDate
          rerouteDocuments {
            fileName
            issuingHouse
            documentName
            id
            reRoutedDoc
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const transactionReasonList = /* GraphQL */ `
  query TransactionReasonList($input: TransactionReasonListInput) {
    transactionReasonList(input: $input) {
      data {
        result {
          reasons {
            reasonId
            category
            subCategory
          }
          targetDate
          rerouteDocuments {
            fileName
            issuingHouse
            documentName
            id
            reRoutedDoc
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const transactionRequest = /* GraphQL */ `
  query TransactionRequest($input: TransactionRequestInput) {
    transactionRequest(input: $input) {
      data {
        result {
          orderNo
          requestId
          requestorName
          reasons {
            reasonId
            category
            subCategory
          }
          eventType
          approval {
            eventType
            name
            remarks
            status
          }
          targetDate
          rerouteDocuments {
            fileName
            issuingHouse
            documentName
            id
            reRoutedDoc
          }
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
export const userProfile = /* GraphQL */ `
  query UserProfile {
    userProfile {
      data {
        result {
          name
          status
          homeBranch
          department
          email
          mobileNo
        }
      }
      error {
        errorCode
        message
        statusCode
        errorList
      }
    }
  }
`;
