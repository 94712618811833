/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, graphqlOperation } from 'aws-amplify';
import React, { Fragment, ReactText, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FlexSpacer, {
    Banner,
    CustomButton,
    FlexedDiv,
    PreviousPage,
    SelectDropdown,
    TextArea,
    Modal,
    ComponentLoader,
} from '../../../components';
import { CustomInput } from '../../../components/atoms/Input';
import { PhoneInput } from '../../../components/atoms/Input/PhoneInput';
import { LABEL, TextDarkGrey6, SYSTEM_ADMIN } from '../../../constants';
import { addUserModes } from '../../../constants/addUserModes';
import { TextBold12, TextBold18, TextBold24, TextNormal16 } from '../../../constants/textStyles';

import { IValidation } from '../../../interfaces/IValidation';
import { sh16, sh24, sh240, sh32, sh56, sh64, sh80 } from '../../../styles';
import { Validate } from '../../../validation/validate';
import { getIdInformation } from '../../../_graphql/mutations/systemAdmin/getIdInformation';
import { approveReject } from '../../../_graphql/mutations/systemAdmin/approveReject';

import styled from 'styled-components';
import SystemAdminContext from '../../../context/SystemAdminContext/SAContext';
import AuthContext from '../../../context/AuthContext';
import ErrorHandlingContext from '../../../context/ErrorHandling/ErrorHandlingContext';
import MultiSelectV2 from '../../../components/molecules/MultiSelect';
import * as Routes from '../../../routes';
import createUser from '../../../_graphql/mutations/systemAdmin/createUser';
import editUser from '../../../_graphql/mutations/systemAdmin/editUser';

interface IUser {
    loginID: string;
    author: string;
    name: string;
    ssoID: string;
    verified: boolean;
    status: string;
    homeBranch: string;
    department: string;
    email: string;
    mobile: string;
    description: string;
    userGroups: ReactText[];
    rejectRemark: string;
    userID: string;
    requestId: string;
}

const initialUser: IUser = {
    loginID: '',
    author: '',
    name: '',
    ssoID: '',
    verified: false,
    status: 'Pending',
    homeBranch: '',
    department: '',
    email: '',
    mobile: '',
    description: '',
    userGroups: [],
    rejectRemark: '',
    userID: '',
    requestId: '',
};
type SourceObj = {
    title: string;
    message: string;
    rejectTitle: string;
};
export interface locationData {
    mode: string;
    data?: ITableData;
    canApprove: boolean;
    reject?: boolean;
    isAA: boolean;
}

declare type actionType = 'approve' | 'reject' | '';

const modalMessages = {
    confirmation: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
    feedback: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
};
const AddUserPage = (): JSX.Element => {
    //Context
    const { userLoginContext } = useContext(AuthContext);
    const { loading, branchOptions, departmentOptions, groupsOptions, getDropdown, loadingHandler } =
        useContext(SystemAdminContext);
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const history = useHistory();
    const state = useLocation<locationData>().state;

    // Modal and form UI states
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

    const [showModal, setShowModal] = useState(false);
    const [showModalApproved, setShowModalApproved] = useState(false);

    const [isRejected, setIsRejected] = useState(false);
    const [validationError, setValidationError] = useState({
        errorCount: 0,
        loginID: '',
        name: '',
        email: '',
        mobile: '',
    });

    const [userData, setUserData] = useState<IUser>({ ...initialUser });
    const [oldUserData, setOldUserData] = useState<IUser>({ ...initialUser });
    const [pageMode, setPageMode] = useState('');
    const [isAA, setIsAA] = useState(false);
    const [modified, setModified] = useState(false);
    const [statusValue, setStatusValue] = useState<string>('');
    const [currentStatus, setCurrentStatus] = useState<string>('');

    const statusOptions = ['Pending', 'Active', 'Suspended', 'Terminated'];

    const getUserDetails = async () => {
        const mode = state.mode;

        if (mode !== addUserModes.addUser) {
            const BEData = state.data as ITableData;

            const tempUser = { ...initialUser };
            tempUser.requestId = BEData.requestId;
            loadingHandler();
            try {
                const response: any = await API.graphql(
                    graphqlOperation(getIdInformation, {
                        input: {
                            id: BEData.apiCode,
                            viewType: 'users',
                        },
                    }),
                    idTokenHeader,
                );
                const { data, error } = await response.data.getIdInformation;
                if (error !== null) throw error;
                const info = JSON.parse(data.result.info);

                if (data) {
                    loadingHandler();

                    if (info) {
                        tempUser.loginID = info.username;
                        tempUser.author = info.author ? info.author : '';
                        tempUser.name = info.name ? info.name : '';
                        tempUser.email = info.email;
                        tempUser.description = info.description ? info.description : '';
                        tempUser.mobile = info.mobileNo ? info.mobileNo : '';
                        tempUser.verified = false;
                        tempUser.homeBranch = info.branchName ?? '';
                        if (info.requestedStatus) {
                            tempUser.status = mode === addUserModes.reviewUser ? info.requestedStatus : info.status;
                            tempUser.userGroups =
                                mode === addUserModes.reviewUser &&
                                info.groupIds !== null &&
                                info.groupIds !== undefined
                                    ? info.groupIds.ids.map((id: React.ReactText) => id.toString())
                                    : [];
                        } else {
                            tempUser.status = info.status;
                            tempUser.userGroups =
                                info.userGroupIds !== null && info.userGroupIds !== undefined ? info.userGroupIds : [];
                        }

                        tempUser.department = info.departmentName ?? '';

                        tempUser.userID = info.userId;
                        setCurrentStatus(info.currentStatus);
                        setStatusValue(tempUser.status);
                        setUserData({ ...tempUser });
                        setOldUserData({ ...tempUser });
                    }
                }
            } catch (error) {
                loadingHandler();
                const _error = error as IErrorHandling;
                if (Object.keys(_error).includes('errorCode')) {
                    setErrorMessage({
                        ...errorMessage,
                        message: _error.message,
                        errorCode: _error.errorCode,
                        title: 'Cannot Fetch User Data',
                        testId: 'add-user-error-modal',
                    });
                } else {
                    setErrorMessage({
                        ...errorMessage,
                        message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                        title: 'Something Went Wrong',
                        testId: 'gql-error-modal',
                    });
                }
                handleErrorHandler();
            }
        }
        setPageMode(mode);
        setIsAA(state.isAA);
    };

    const hasDataChanged = () => {
        if (!modified) return false;
        if (
            userData.department !== oldUserData.department ||
            userData.email !== oldUserData.email ||
            userData.description !== oldUserData.description ||
            userData.mobile !== oldUserData.mobile ||
            userData.name !== oldUserData.name ||
            userData.status !== oldUserData.status ||
            userData.homeBranch !== oldUserData.homeBranch
        )
            return true;
        if (userData.userGroups.length !== oldUserData.userGroups.length) return true;
        const oldData = oldUserData.userGroups.concat().sort();
        const newData = userData.userGroups.concat().sort();

        for (let i = 0; i < oldData.length; i++) {
            if (oldData[i] !== newData[i]) {
                return true;
            }
        }
        return false;
    };

    const allFieldsEntered =
        userData.department !== '' &&
        userData.email !== '' &&
        userData.homeBranch?.toString() !== '' &&
        userData.loginID !== '' &&
        userData.mobile !== '' &&
        userData.name !== '' &&
        userData.userGroups.length > 0 &&
        hasDataChanged() &&
        validationError.errorCount === 0;

    const handleModal = (show: boolean) => {
        setShowModal(show);
        if (show === false) {
            history.push(Routes.dashboardSystemAdmin);
        }
    };

    const getHomeBranchKey = () => {
        for (let i = 0; i < branchOptions.length; i++) {
            if (branchOptions[i].value === userData.homeBranch) {
                return branchOptions[i].key;
            }
        }
        return '';
    };

    const getDepartmentKey = () => {
        for (let i = 0; i < departmentOptions.length; i++) {
            if (departmentOptions[i].value === userData.department) {
                return departmentOptions[i].key;
            }
        }
        return '';
    };

    const createUserMutation = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(createUser, {
                    input: {
                        username: userData.loginID,
                        ssoId: userData.ssoID,
                        name: userData.name,
                        status: userData.status,
                        branch: getHomeBranchKey(),
                        department: getDepartmentKey(),
                        email: userData.email,
                        mobileNumber: userData.mobile,
                        description: userData.description,
                        userGroup: userData.userGroups,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.createUser;
            if (data !== null) {
                if (data.result.status) {
                    handleFeedbackModal();
                }
            }
            if (error !== null) throw error;
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                if (_error.message === 'Email already exists.') {
                    const update = { ...validationError, email: _error.message };
                    update.errorCount += 1;
                    setValidationError(update);
                } else {
                    if (_error.message === 'Username already exists.') {
                        // set error
                        setValidationError({
                            ...validationError,
                            loginID: _error.message,
                            errorCount: validationError.errorCount + 1,
                        });
                    } else if (_error.errorCode === 'BO400') {
                        if (_error.errorList !== undefined && _error.errorList !== null) {
                            if (_error.errorList.length !== 0) {
                                setErrorMessage({
                                    ...errorMessage,
                                    message: _error.message,
                                    errorCode: _error.errorCode,
                                    title: 'Cannot Create User',
                                    testId: 'add-user-error-modal',
                                    errorList: _error.errorList,
                                });
                                handleErrorHandler();
                            }
                        } else {
                            setErrorMessage({
                                ...errorMessage,
                                message: _error.message,
                                errorCode: _error.errorCode,
                                title: 'Cannot Create User',
                                testId: 'add-user-error-modal',
                            });
                            handleErrorHandler();
                        }
                    } else {
                        setErrorMessage({
                            ...errorMessage,
                            message: _error.message,
                            errorCode: _error.errorCode,
                            title: 'Cannot Create User',
                            testId: 'add-user-error-modal',
                        });
                        handleErrorHandler();
                    }
                }
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const editUserMutation = async () => {
        setShowConfirmationModal(false);

        try {
            const response: any = await API.graphql(
                graphqlOperation(editUser, {
                    input: {
                        userId: userData.userID.trim(),
                        ssoId: userData.ssoID.trim(),
                        name: userData.name.trim(),
                        status: userData.status?.toString().toLowerCase(),
                        branch: getHomeBranchKey(),
                        department: getDepartmentKey(),
                        email: userData.email.trim(),
                        mobileNumber: userData.mobile.trim(),
                        description: userData.description.trim(),
                        userGroup: userData.userGroups,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.editUser;
            if (data !== null) {
                if (data.result.status.toString().toLowerCase()) {
                    setValidationError({ ...validationError, loginID: '', errorCount: validationError.errorCount - 1 });
                    handleFeedbackModal();
                }
            }
            if (error !== null) throw error;
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    errorList: _error.errorList,
                    title: 'Cannot Edit User',
                    testId: 'add-user-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    const approveRejectUserMutation = async (action: actionType) => {
        initFeedbackModal();

        // handleFeedbackModal();
        try {
            const response: any = await API.graphql(
                graphqlOperation(approveReject, {
                    input: {
                        requestId: userData.requestId,
                        action: action,
                        remarks: userData.rejectRemark,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.approveReject;

            if (data !== null) {
                handleFeedbackModal();
                if (data.result.status) {
                    // if (action === 'approve') setShowModalApproved(true);
                    // if (action === 'reject') {
                    //     setIsRejectedConfirm(true);
                    //     setShowModal(true);
                    // }
                    // setAction('');
                }
            }
            if (error !== null) throw error;
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Review Request',
                    testId: 'add-user-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    const handleSave = () => {
        initFeedbackModal();
        switch (pageMode) {
            case addUserModes.addUser:
                createUserMutation();
                break;
            case addUserModes.editUser:
                modalMessages.confirmation.heading = `Edit ${userData.name} ?`;
                modalMessages.confirmation.content = (
                    <FlexedDiv direction="column">
                        <TextDarkGrey6 weight="600" size="16px">
                            {LABEL.areYouSure}
                        </TextDarkGrey6>
                    </FlexedDiv>
                );
                setShowConfirmationModal(true);
                break;
        }
    };

    const updateUserData = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const value = e.currentTarget.value;
        const key = e.currentTarget.name;
        const att = e.currentTarget.getAttribute('accept');
        setModified(true);
        let valueMessage = e.currentTarget.getAttribute('itemprop');
        if (valueMessage === null) {
            valueMessage = '';
        }
        if (key === 'email' && validationError.email !== '') {
            const update = { ...validationError, email: '' };
            update.errorCount -= 1;
            setValidationError(update);
        }
        const validationSettings = att !== null ? JSON.parse(att) : undefined;
        if (validationSettings !== undefined) {
            const err = Validate(value, validationSettings, valueMessage);
            let errVal = '';
            if (err.code !== 'NoError') {
                errVal = err.message;
            }
            const update = { ...validationError, [key]: errVal };
            update.errorCount = 0;
            for (const [key, val] of Object.entries(update)) {
                if (key !== 'errorCount') {
                    if (typeof val === 'string' && val.length > 0) {
                        update.errorCount += 1;
                    }
                }
            }
            setValidationError(update);
        }
        if (key === 'verify') {
            setUserData({ ...userData, verified: !userData.verified });
        } else {
            setUserData({ ...userData, [key]: value });
        }
    };
    const updateUserDataSelect = (value: string, field: string) => {
        let _tempValue = value;
        if (field === 'status') {
            switch (value) {
                case 'Suspended':
                    _tempValue = 'suspend';
                    break;
                case 'Terminated':
                    _tempValue = 'terminate';
                    break;
                default:
                    break;
            }
        }
        setModified(true);
        setStatusValue(value);
        setUserData({ ...userData, [field]: _tempValue });
    };

    const getSelectedList = (list: Array<ReactText>) => {
        setModified(true);
        setUserData({ ...userData, userGroups: list });
    };

    const handleBack = () => {
        const data = { tab: 'All Users' };
        history.push(Routes.dashboardSystemAdmin, data);
    };

    const clearStateOnReject = () => {
        if (userData.rejectRemark !== null) {
            userData.rejectRemark = '';
        }
        setIsRejected(false);
    };
    // Fn to handle checker specific modal messages.
    const getCheckerModalText = (): SourceObj => {
        const sourceObj: SourceObj = { title: '', message: '', rejectTitle: '' };
        switch (statusValue.toLowerCase()) {
            // case 'editUser':
            //     sourceObj.title = LABEL.newUserEditApproved;
            //     sourceObj.message = LABEL.newUserEdited;
            //     sourceObj.rejectTitle = LABEL.editUserRejected;
            //     break;
            case 'active':
                if (currentStatus.toLowerCase() === 'suspended') {
                    sourceObj.title = LABEL.enableUserApproved;
                    sourceObj.message = LABEL.userEnabled;
                    sourceObj.rejectTitle = LABEL.enableUserRejected;
                }
                if (currentStatus.toLowerCase() === 'active') {
                    sourceObj.title = LABEL.newUserEditApproved;
                    sourceObj.message = LABEL.newUserEdited;
                    sourceObj.rejectTitle = LABEL.editUserRejected;
                }

                break;
            case 'suspended':
                sourceObj.title = LABEL.newUserSuspendApproved;
                sourceObj.message = LABEL.newUserSuspended;
                sourceObj.rejectTitle = LABEL.suspendUserRejected;
                break;
            case 'terminated':
                sourceObj.title = LABEL.newUserTerminationApproved;
                sourceObj.message = LABEL.newUserTerminated;
                sourceObj.rejectTitle = LABEL.terminateUserRejected;
                break;
            default:
                //create new user
                sourceObj.title = LABEL.newUserApproved;
                sourceObj.message = LABEL.newUserCreated;
                sourceObj.rejectTitle = LABEL.newUserRejected;
        }
        return sourceObj;
    };
    const initFeedbackModal = () => {
        switch (state.mode) {
            case 'addNew':
                modalMessages.feedback.heading = isAA ? LABEL.newUserCreatedAA : LABEL.newUserSubmit;
                modalMessages.feedback.content = isAA ? (
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <TextDarkGrey6 weight="600" size="16px">
                            <Fragment>
                                <strong>{userData.name}</strong> {LABEL.submittedMsgAA}
                            </Fragment>
                        </TextDarkGrey6>
                    </FlexedDiv>
                ) : (
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <TextDarkGrey6 weight="600" size="16px">
                            This request has been submitted and
                            <br /> pending for review.
                        </TextDarkGrey6>
                    </FlexedDiv>
                );
                modalMessages.feedback.icon = isAA ? 'user-modal-success' : 'user-request-pending';
                break;
            case 'editUser':
                modalMessages.feedback.heading = isAA ? LABEL.newUserEditedAA : LABEL.editUserSubmitted;
                modalMessages.feedback.content = isAA ? (
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <TextDarkGrey6 weight="600" size="16px">
                            <Fragment>
                                <strong>{userData.name}</strong> {LABEL.editedMsgAA}
                            </Fragment>
                        </TextDarkGrey6>
                    </FlexedDiv>
                ) : (
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <TextDarkGrey6 weight="600" size="16px">
                            This request has been submitted and
                            <br /> pending for review.
                        </TextDarkGrey6>
                    </FlexedDiv>
                );
                modalMessages.feedback.icon = isAA ? 'user-modal-success' : 'user-request-pending';
                break;
            case 'reviewUser':
                const _sourceObj = getCheckerModalText();
                modalMessages.feedback.heading = isRejected ? _sourceObj.rejectTitle : _sourceObj.title;
                modalMessages.feedback.content = (
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <TextDarkGrey6 weight="600" size="16px">
                            {isRejected ? (
                                `The maker will be notified.`
                            ) : (
                                <Fragment>
                                    <strong>{userData.name}</strong> {_sourceObj.message}
                                </Fragment>
                            )}
                        </TextDarkGrey6>
                    </FlexedDiv>
                );
                modalMessages.feedback.icon = 'user-modal-success';
                break;
        }
    };

    // Fn to toggle feedback modal
    const handleFeedbackModal = () => {
        setShowFeedbackModal((prevState) => !prevState);
        // setApprove(!approve);
    };

    useEffect(() => {
        getDropdown();
        getUserDetails();

        if (state.reject === true) {
            setIsRejected(true);
        }
    }, []);

    return (
        <Container>
            {isRejected ? (
                <div style={{ marginLeft: sh16 }}>
                    <TextBold24 style={{ display: 'block' }}>{LABEL.rejectRemark}</TextBold24>
                    <TextNormal16 style={{ marginBottom: sh32, display: 'block' }}>
                        {LABEL.rejectRemarkDescription}
                    </TextNormal16>
                    <TextArea
                        name="rejectRemark"
                        label={LABEL.remarks}
                        value={userData.rejectRemark}
                        handleInput={(e) => updateUserData(e)}
                        maxLength={255}
                        style={{ width: '520px' }}
                    />
                    <FlexedDiv direction="column">
                        <FlexedDiv alignItems="center" style={{ marginTop: sh80, justifyContent: 'flex-start' }}>
                            <CustomButton onClick={() => clearStateOnReject()} style={{ width: sh240, height: '40px' }}>
                                {LABEL.cancel}
                            </CustomButton>
                            <CustomButton
                                disabled={userData.rejectRemark.trim().length === 0}
                                primary={true}
                                style={{ marginLeft: sh16, width: sh240, height: '40px' }}
                                onClick={() => approveRejectUserMutation('reject')}
                            >
                                {LABEL.submit}
                            </CustomButton>
                        </FlexedDiv>
                        <div style={{ height: sh56 }} />
                    </FlexedDiv>
                </div>
            ) : (
                <Fragment>
                    <FlexedDiv alignItems="center">
                        {pageMode === addUserModes.reviewUser ? (
                            <PreviousPage
                                backIcon={true}
                                title={LABEL.reviewUser}
                                handleBackFunction={handleBack}
                                subTitle={LABEL.reviewUserDescription}
                            />
                        ) : (
                            <PreviousPage
                                backIcon={true}
                                title={pageMode === addUserModes.editUser ? LABEL.editUser : LABEL.addNewUser}
                                handleBackFunction={handleBack}
                                subTitle={LABEL.addNewUserSubHeading}
                            />
                        )}
                    </FlexedDiv>

                    <div style={{ paddingLeft: sh32 }}>
                        <TextBold18 style={{ marginBottom: sh24 }}> {LABEL.userProfile}</TextBold18>
                        <FlexedDiv alignItems="center" style={{ marginBottom: sh24 }}>
                            <CustomInput
                                label={LABEL.loginID}
                                name="loginID"
                                disabled={pageMode !== addUserModes.addUser}
                                style={{ marginRight: '64px', width: '450px' }}
                                value={userData.loginID}
                                inputStyle={{ fontSize: '16px', fontWeight: 700 }}
                                onChange={(e) => updateUserData(e)}
                                onLostFocus={(e) => updateUserData(e)}
                                validation={JSON.stringify({
                                    type: 'alphanumeric',
                                    minLength: 3,
                                    maxLength: 20,
                                    required: true,
                                    noSpace: true,
                                } as IValidation)}
                                validationMessage="Login ID"
                                errorText={validationError.loginID}
                                canClearContents
                                handleClearContents={() => setUserData({ ...userData, loginID: '' })}
                            />
                        </FlexedDiv>
                        <FlexedDiv alignItems="center" style={{ marginBottom: sh24, display: 'flex' }}>
                            <CustomInput
                                label={LABEL.name}
                                name="name"
                                disabled={pageMode === addUserModes.reviewUser}
                                onChange={(e) => updateUserData(e)}
                                style={{ marginRight: '64px', width: '450px' }}
                                inputStyle={{ fontSize: '16px', fontWeight: 700 }}
                                value={userData.name}
                                validation={JSON.stringify({
                                    type: 'string',
                                    minLength: 3,
                                    maxLength: 100,
                                    required: true,
                                    singleSpace: true,
                                } as IValidation)}
                                validationMessage="Name"
                                errorText={validationError.name}
                                canClearContents
                                handleClearContents={() => setUserData({ ...userData, name: '' })}
                            />
                            <FlexedDiv direction="column">
                                <TextBold12 style={{ color: '#333333' }}>{LABEL.status}</TextBold12>
                                <SelectWrapper
                                    isDisabled={pageMode !== addUserModes.editUser}
                                    style={{ width: '450px' }}
                                >
                                    <SelectDropdown
                                        onChange={(value) => updateUserDataSelect(value.toString(), 'status')}
                                        options={statusOptions.map((item) => {
                                            return { label: item, value: item };
                                        })}
                                        testId={`status-dropdown`}
                                        selectedValue={statusValue}
                                        isDisabled={pageMode !== addUserModes.editUser}
                                        fontWeight={700}
                                    />
                                </SelectWrapper>
                            </FlexedDiv>
                        </FlexedDiv>
                        <FlexedDiv alignItems="center" style={{ display: 'flex' }}>
                            <FlexedDiv direction="column" style={{ marginRight: '64px' }}>
                                <SelectWrapper style={{ width: '450px' }}>
                                    <SelectDropdown
                                        onChange={(value) => updateUserDataSelect(value.toString(), 'homeBranch')}
                                        options={branchOptions.map((item) => {
                                            return { label: item.value, value: item.value };
                                        })}
                                        testId={`homeBranch-dropdown`}
                                        selectedValue={userData.homeBranch as string}
                                        isDisabled={pageMode === addUserModes.reviewUser}
                                        placeHolder={LABEL.selectOne}
                                        // isSearchEnabled
                                        label={LABEL.homeBranch}
                                    />
                                </SelectWrapper>
                            </FlexedDiv>
                            <FlexedDiv direction="column">
                                <SelectWrapper style={{ width: '450px' }}>
                                    <SelectDropdown
                                        onChange={(value) => updateUserDataSelect(value.toString(), 'department')}
                                        options={departmentOptions.map((item) => {
                                            return { label: item.value, value: item.value };
                                        })}
                                        testId={`department-dropdown`}
                                        selectedValue={userData.department as string}
                                        isDisabled={pageMode === addUserModes.reviewUser}
                                        // isSearchEnabled
                                        placeHolder={LABEL.selectOne}
                                        label={LABEL.departments}
                                    />
                                </SelectWrapper>
                            </FlexedDiv>
                        </FlexedDiv>
                        <FlexedDiv alignItems="center" style={{ marginBottom: sh24, marginTop: sh24, display: 'flex' }}>
                            <CustomInput
                                label={LABEL.emailAddress}
                                name="email"
                                disabled={pageMode === addUserModes.reviewUser}
                                onChange={(e) => updateUserData(e)}
                                style={{ marginRight: '64px', width: '450px' }}
                                inputStyle={{ fontSize: '16px', fontWeight: 700 }}
                                value={userData.email}
                                validation={JSON.stringify({ type: 'email', required: true } as IValidation)}
                                validationMessage="Email"
                                errorText={validationError.email}
                                canClearContents
                                handleClearContents={() => setUserData({ ...userData, email: '' })}
                            />
                            <PhoneInput
                                label={LABEL.mobileNo}
                                name="mobile"
                                onChange={(e) => updateUserData(e)}
                                prefix="+60"
                                disabled={pageMode === addUserModes.reviewUser}
                                style={{ marginRight: '64px', width: '450px' }}
                                value={userData.mobile.trim()}
                                validation={JSON.stringify({
                                    type: 'phone',
                                    minLength: 9, // TODO check mobile number length change to 10,11 after confirming
                                    maxLength: 10,
                                    required: true,
                                } as IValidation)}
                                validationMessage="Mobile number"
                                maxLength={10}
                                errorText={validationError.mobile}
                                // canClearContents
                                // handleClearContents={() => setUserData({ ...userData, mobile: '' })}
                            />
                        </FlexedDiv>
                        <TextArea
                            name="description"
                            label={LABEL.description}
                            value={userData.description}
                            handleInput={(e) => updateUserData(e)}
                            maxLength={255}
                            disabled={pageMode === addUserModes.reviewUser}
                            style={{ width: '450px' }}
                        />
                        <TextBold18 style={{ marginBottom: sh24, marginTop: sh64 }}>{LABEL.assignUserGroup}</TextBold18>
                        <MultiSelectV2
                            label={LABEL.userGroup}
                            options={groupsOptions as IMultiSelectLabel[]}
                            placeHolder={LABEL.selectOneOrMultiple}
                            checkedState={userData.userGroups}
                            noOverlay={false}
                            expandable={pageMode === addUserModes.reviewUser}
                            disabled={pageMode === addUserModes.reviewUser}
                            handleMultiSelect={(item) => getSelectedList(item)}
                            selectId={1}
                            hasSelectAll
                        />

                        <FlexSpacer />
                    </div>
                    {state.canApprove && state.mode === 'reviewUser' ? (
                        <div style={{ marginBottom: '180px' }}>
                            <Banner
                                toggle={true}
                                title={SYSTEM_ADMIN.LABEL_REQUEST_PENDING_APPROVAL}
                                descriptionEmText={userData.author}
                                description="Requested by"
                                handleClose={() => setShowModalApproved(false)}
                                testId="adduser-banner"
                                primaryButtonObject={{
                                    handlePrimaryBtn: () => approveRejectUserMutation('approve'),
                                    label: LABEL.approve,
                                    icon: 'check',
                                }}
                                secondaryButtonObject={{
                                    handleSecondaryBtn: () => setIsRejected(true),
                                    label: LABEL.reject,
                                    color: '#E84C3D',
                                    icon: 'terminate',
                                }}
                            />
                        </div>
                    ) : (
                        <FlexedDiv direction="column" style={{ paddingLeft: sh32 }}>
                            {pageMode !== addUserModes.reviewUser ? (
                                <FlexedDiv
                                    alignItems="center"
                                    style={{ marginTop: sh56, justifyContent: 'flex-start' }}
                                >
                                    <CustomButton
                                        onClick={() => history.push(Routes.dashboardSystemAdmin)}
                                        style={{ width: sh240, height: '40px' }}
                                    >
                                        {LABEL.cancel}
                                    </CustomButton>
                                    <CustomButton
                                        primary={true}
                                        disabled={!allFieldsEntered}
                                        style={{ marginLeft: sh16, width: sh240, height: '40px' }}
                                        // onClick={runAPI}
                                        onClick={() => handleSave()}
                                    >
                                        {LABEL.save}
                                    </CustomButton>
                                </FlexedDiv>
                            ) : null}
                            <div style={{ height: sh56 }} />
                        </FlexedDiv>
                    )}
                </Fragment>
            )}

            {showConfirmationModal ? (
                <Modal
                    modalActive={showConfirmationModal}
                    setModalActive={setShowConfirmationModal}
                    title={modalMessages.confirmation.heading}
                    icon={modalMessages.confirmation.icon}
                    primaryBtn={{
                        onClick: () => editUserMutation(),
                        label: LABEL.yes,
                        primary: true,
                    }}
                    secondaryBtn={{
                        onClick: () => setShowConfirmationModal(false),
                        label: LABEL.cancel,
                        width: '212px',
                    }}
                    testId="edit-user-feedback"
                    contentAlignment="left"
                >
                    {modalMessages.confirmation.content}
                </Modal>
            ) : null}
            {showModal || showModalApproved || showFeedbackModal ? (
                <Modal
                    modalActive={showFeedbackModal}
                    setModalActive={setShowFeedbackModal}
                    title={modalMessages.feedback.heading}
                    icon={modalMessages.feedback.icon}
                    primaryBtn={{
                        onClick: () => {
                            handleModal(false);
                        },
                        label: LABEL.done,
                        primary: true,
                    }}
                    testId="add-user-feedback"
                    contentAlignment="center"
                >
                    {modalMessages.feedback.content}
                </Modal>
            ) : null}
            {loading && <ComponentLoader />}
        </Container>
    );
};
type SelectWrapperProps = {
    isDisabled?: boolean;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SelectWrapper = styled.div<SelectWrapperProps>`
    width: 360px;
    cursor: not-allowed;
    color: ${(props) => (props.isDisabled ? 'rgba(0, 0, 0, 0.25)' : undefined)};
    background: #f5f5f5;
    margin-top: -7px;
`;

const AddUser = AddUserPage;

export default AddUser;
