export const initialAdviser: IProfileDetails = {
    name: '',
    id: '',
    idProof: '',
    details: [
        {
            title: 'Personal Details',
            sections: [
                {
                    data: [{ label: '', data: '' }],
                },
            ],
        },
        {
            title: 'Contact Details',
            sections: [
                {
                    data: [{ label: '', data: '' }],
                },
            ],
        },
        {
            title: 'Address Information',
            sections: [
                {
                    data: [{ label: '', data: '' }],
                },
            ],
        },
    ],
};
