import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { CustomButton } from '../../../../components/atoms';
import { FlexedDiv } from '../../../../components/atoms/FlexDiv';
import { LABEL } from '../../../../constants';
import { TextDarkBlack, TextDarkGrey6 } from '../../../../constants/styles/text';
import { MultiSection } from '../../../../templates/MultipleSections';

/**
 * Profile tab in order summary page
 * @category Pages
 * @component
 * @namespace ProfileTab
 * @returns Profile tab in order summary page.
 */

interface IProfileTabProps {
    profile: Array<TOrderSummaryProfileSections>;
    isEtb?: boolean;
    isHybrid?: boolean;
}

const ProfileTab: React.FC<IProfileTabProps> = ({ profile, isEtb, isHybrid }: IProfileTabProps) => {
    const [isPrincipal, setIsPrincipal] = useState<boolean>(true);

    const generateProfileSection = (profile: Array<TOrderSummaryProfileSections>): JSX.Element[] => {
        const _principleProfile = profile.find((item) => item.principal === isPrincipal);
        const _sections: JSX.Element[] = [];
        if (_principleProfile) {
            Object.keys(_principleProfile).forEach((key, index) => {
                switch (key) {
                    case 'personalDetails':
                    case 'contactDetails':
                    case 'addressInformation':
                    case 'employmentInformation':
                    case 'fatcaDeclaration':
                    case 'crsDeclaration':
                        if (_principleProfile[key] !== undefined) {
                            _sections.push(
                                <BlockContainer
                                    key={`container${index + 1}`}
                                    id={`${_principleProfile[key]?.title?.toLocaleLowerCase()}-container`}
                                >
                                    <BlockContainerHeader>
                                        <TextDarkBlack weight="700" size="18px">
                                            {_principleProfile[key]?.title}
                                        </TextDarkBlack>
                                    </BlockContainerHeader>
                                    <BlockContainerContent>
                                        <MultiSection sections={_principleProfile[key]?.sections as ISection[]} />
                                    </BlockContainerContent>
                                </BlockContainer>,
                            );
                        }
                        break;
                }
            });
        } else if (!_principleProfile && profile.length === 1) {
            Object.keys(profile[0]).forEach((key, index) => {
                switch (key) {
                    case 'personalDetails':
                    case 'contactDetails':
                    case 'addressInformation':
                    case 'employmentInformation':
                    case 'fatcaDeclaration':
                    case 'crsDeclaration':
                        if (profile[0][key] !== undefined) {
                            _sections.push(
                                <BlockContainer
                                    key={`container${index + 1}`}
                                    id={`${profile[0][key]?.title?.toLocaleLowerCase()}-container`}
                                >
                                    <BlockContainerHeader>
                                        <TextDarkBlack weight="700" size="18px">
                                            {profile[0][key]?.title}
                                        </TextDarkBlack>
                                    </BlockContainerHeader>
                                    <BlockContainerContent>
                                        <MultiSection sections={profile[0][key]?.sections as ISection[]} />
                                    </BlockContainerContent>
                                </BlockContainer>,
                            );
                        }
                        break;
                }
            });
        }
        return _sections;
    };

    return (
        <Fragment>
            {profile.length > 1 ? (
                <FlexedDiv justifyContent={'flex-end'} style={{ marginBottom: '2rem' }}>
                    <ButtonContainer>
                        <CustomButton
                            style={{
                                minWidth: '120px',
                                height: '32px',
                                borderColor: '#002043',
                                lineHeight: '1',
                                border: 'none',
                                borderRadius: '0px',
                            }}
                            onClick={() => setIsPrincipal(true)}
                        >
                            Principal Profile
                        </CustomButton>
                        <CustomButton
                            style={{
                                minWidth: '120px',
                                height: '32px',
                                borderColor: '#002043',
                                lineHeight: '1',
                                border: 'none',
                                borderLeft: '1px solid #002043',
                                borderRadius: '0px',
                            }}
                            onClick={() => setIsPrincipal(false)}
                        >
                            Joint Profile
                        </CustomButton>
                    </ButtonContainer>
                </FlexedDiv>
            ) : profile.length === 1 && !isEtb && isHybrid ? (
                <FlexedDiv
                    justifyContent={'flex-start'}
                    margin="0 0 2rem 0"
                    style={{ borderBottom: '1px solid #C61230' }}
                >
                    <HolderDetails>
                        <TextDarkGrey6 size="10px" lineHeight="13px">
                            {profile[0].principal ? LABEL.principalHolder : LABEL.jointHolder}
                        </TextDarkGrey6>
                        <TextDarkBlack size="12px" lineHeight="15.6px" weight="700">
                            {profile[0].name}
                        </TextDarkBlack>
                    </HolderDetails>
                </FlexedDiv>
            ) : null}
            {generateProfileSection(profile)}
        </Fragment>
    );
};

export default ProfileTab;

const ButtonContainer = styled.div`
    display: flex;
    width: fit-content;
    border-radius: 24px;
    border: 1px solid #002043;
    overflow: hidden;
`;

const BlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px #0020430f;
    border-radius: 8px;
    margin-bottom: 1rem;
`;
const BlockContainerHeader = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ececec;
    padding: 0.75rem 1.5rem;
    border-radius: 8px 8px 0 0;
`;
const BlockContainerContent = styled.div`
    padding: 1rem 1.5rem 1.5rem;
`;
const HolderDetails = styled.div`
    display: flex;
    width: 100%;
    column-gap: 1rem;
    align-items: center;
    padding: 0 0 4px;
`;
