import React, { Fragment, FunctionComponent } from 'react';
import styled from 'styled-components';

import { CollapsibleTooltipMessage } from '..';
import { CustomSpacer, FlexedDiv, PermissionLevelInfoTemplate, Tooltip } from '../../../../../components';
import { Collapsible } from '../../../../../components/molecules/Collapsible/Collapsible';
import { HQ_ROLE } from '../../../../../constants/hqRoleCollapsible';
import { SYSTEM_ADMIN } from '../../../../../constants/labels';
import { IcoMoon } from '../../../../../icons';
import { CollapsibleContent, ISetSectionTypes } from '../../AddRoles/CollapsibleContent';
import { ILoginRoleSection } from '../../AddRoles/HqPrivileges/hqRolesInitialData';

export interface LoginPrivilegesProps {
    approval: boolean;
    expanded: boolean;
    handleExpand: (index: number) => void;
    loginAccess: ILoginRoleSection;
    setLoginAccess: (data: ILoginRoleSection) => void;
}

export const LoginPrivileges: FunctionComponent<LoginPrivilegesProps> = ({
    expanded,
    handleExpand,
    loginAccess,
    setLoginAccess,
}: LoginPrivilegesProps) => {
    return (
        <Fragment>
            <Collapsible
                checked={loginAccess.isAll}
                checkboxDisabled={true}
                expanded={expanded}
                index={0}
                content={
                    <Fragment>
                        <CustomSpacer space={'1.5rem'} />
                        <FlexedDiv direction="row" style={{ paddingLeft: '4rem', paddingRight: '14.5rem' }}>
                            <FlexedDiv direction="column">
                                <InputText>{SYSTEM_ADMIN.ADD_ROLE.LABEL_ASSIGN_PERM}</InputText>
                                <DropdownBase style={{ opacity: 0.5 }}>
                                    <BasicInput disabled={true} value={loginAccess.permissionLevel} />
                                    <IcoMoon name="caret-down" size={'1.5rem'} />
                                </DropdownBase>
                            </FlexedDiv>
                            <CustomSpacer horizontal={true} space={'1rem'} />
                            <Tooltip
                                tooTipContent={<PermissionLevelInfoTemplate message={CollapsibleTooltipMessage} />}
                                placement="Right"
                                style={{ minWidth: '344px', padding: '16px 16px 16px 16px' }}
                            >
                                <div style={{ alignSelf: 'flex-end', paddingBottom: '0.25rem' }}>
                                    <IcoMoon name="info-filled" size={'2rem'} />
                                </div>
                            </Tooltip>
                        </FlexedDiv>
                        <CustomSpacer space={'1.5rem'} />
                        <CollapsibleContent
                            isDisabled={true}
                            checked={loginAccess.isAll}
                            item={HQ_ROLE.accountManagement.data.general}
                            data={loginAccess}
                            setData={setLoginAccess as ISetSectionTypes}
                        />
                    </Fragment>
                }
                handleExpand={handleExpand}
                isCheckable={true}
                title={HQ_ROLE.accountManagement.title}
                subtitle={HQ_ROLE.accountManagement.subtitle}
                testId="login-access-account-accordion"
                noXMargin={true}
                noMargin={true}
            />
        </Fragment>
    );
};

const DropdownBase = styled.div`
    display: flex;
    /* background-color: red; */
    flex-direction: row;
    background-color: white;
    border-color: #c9c9cd;
    border-width: 0.0625rem;
    border: 1px solid #c9c9cd;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;
    height: 2.5rem;
    width: 22.5rem;
    &:focus {
        box-shadow: 0 0 3px red;
    }
`;

const BasicInput = styled.input.attrs({ type: 'input' })`
    outline: none;
    border: 0;
    height: 1.5rem;
    width: 18.8rem;
`;

const InputText = styled.div`
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: #333333;
    opacity: 0.5;
`;
