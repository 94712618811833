/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { DateColumnItem, EPFSettingColumnItem } from '../../../../../templates';
import { statusStyle, toTitleCase } from '../../../../../utils';
const CustomItem = ({ data, itemKey }: ITableCustomItem): JSX.Element => {
    // let outputDate: JSX.Element;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let outputData: any;
    const generateOutput = () => {
        switch (itemKey[0].key) {
            case 'displayName':
                outputData = <EPFSettingColumnItem data={data} itemKey={itemKey} />;
                break;
            case 'lastUpdated':
            case 'effectiveDate':
                outputData = <DateColumnItem data={data} itemKey={itemKey} />;
                break;
            case 'status':
                outputData = (
                    <div style={statusStyle({ index: 0, rawData: data }, 'status')}>
                        {toTitleCase(data[itemKey[0].key])}
                    </div>
                );
                break;
            default:
                outputData = '';
        }
        return outputData;
    };

    return (
        <Fragment>
            <div data-testid="custom">{generateOutput()}</div>
        </Fragment>
    );
};
export default CustomItem;
