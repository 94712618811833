import React, { Fragment } from 'react';
import { Pointer } from '../../../components';
import CustomDropdown, { IDropdownItem } from '../../../components/molecules/Dropdown/Dropdown';
import { LABEL } from '../../../constants';
import { IcoMoon } from '../../../icons';
interface DocumentsActionsProps extends ITableOptions {
    handleApprove: (item: IColumnItemAccordion) => void;
    handleReject: (item: IColumnItemAccordion) => void;
    permissions: IUserActionsValues;
}
export const DocumentsActions = ({
    data,
    handleApprove,
    handleReject,
    permissions,
}: DocumentsActionsProps): JSX.Element => {
    const handlePrimaryAction = () => {
        handleApprove(data);
    };
    const handleSecondaryAction = () => {
        handleReject(data);
    };
    const documentsMenuActions: IDropdownItem[] = [
        {
            item: LABEL.approveRequest,
            icon: 'check',
            handleItem: handlePrimaryAction,
            testId: `documents-approve-request-btn`,
        },
        {
            item: LABEL.rejectRequest,
            icon: 'terminate',
            handleItem: handleSecondaryAction,
            testId: `documents-reject-request-btn`,
            danger: true,
        },
    ];
    return (
        <Fragment>
            {permissions &&
            (permissions.canApproveBulkUpload === 'auto-authorizer' ||
                permissions.canApproveBulkUpload === 'checker') &&
            data.rawData.status === 'Pending Approval' ? (
                <CustomDropdown items={documentsMenuActions}>
                    <Pointer>
                        <IcoMoon name="action-menu" size="1.5rem" />
                    </Pointer>
                </CustomDropdown>
            ) : (
                <Fragment />
            )}
        </Fragment>
    );
};
