export const selectedTab = (tab: string): string => {
    let temp = '';
    switch (tab) {
        case 'with physical doc':
            temp = 'hardcopy';
            break;
        case 'rerouted':
            temp = 'reroute';
            break;
        default:
            temp = tab;
            break;
    }
    return temp;
};

export const getTabDisplayName = (tab: string): string => {
    switch (tab) {
        case 'hardcopy':
            return 'with physical doc';
        case 'reroute':
            return 'rerouted';
        default:
            return tab;
    }
};
