/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext } from 'react';
import { AdvanceTable, Modal } from '../../../components';
import { EMPTY_STATES, LABEL, TextDarkBlack } from '../../../constants';

import { DailyOptions } from './DailyActions';
import { IDailySummaryState } from '..';

import CustomItemDaily from './CustomItemDaily';

import { useDailyHandler } from './useDailyHandler';
import DashboardBranchContext from '../../../context/BranchContext/DashboardBranchContext';

export * as ROUTES from '../../../routes';

interface IDailyProps {
    dailySummaryState: IDailySummaryState;
    downloadType: string;
    filters: ISort_FilterInput;
    idTokenHeader?: {
        Authorization: string;
        strategy: string;
    };
    isFilterApplied?: boolean;
    isSearchResult?: boolean;
    submittedOnDropdown: string;
    loadingHandler: () => void;
    pendingTab: IPendingTab;
    redirect: (tab: string) => void;
    searchInput: string;
    selectAllFromBanner: boolean;
    selectedRows: Array<ITableData>;
    setErrorHandler: () => void;
    setErrorMessage: (error: IErrorHandling) => void;
    setFilters: (data: ISort_FilterInput) => void;
    setSubmittedOnDropdown: (value: string) => void;
    setSelectedRows: (value: ITableData[]) => void;
    setSortData: (data: ISort_FilterInput) => void;
    showCheckBox: boolean;
    sortData: ISort_FilterInput;
    showResultCount?: boolean;
}

const Daily: React.FC<IDailyProps> = ({
    dailySummaryState,
    downloadType,
    filters,
    idTokenHeader,
    isFilterApplied,
    isSearchResult,
    submittedOnDropdown,
    loadingHandler,
    pendingTab,
    redirect,
    searchInput,
    selectAllFromBanner,
    selectedRows,
    setErrorHandler,
    setErrorMessage,
    setFilters,
    setSubmittedOnDropdown,
    setSelectedRows,
    setSortData,
    showCheckBox,
    sortData,
    showResultCount,
}: IDailyProps) => {
    const {
        handleApproveReviewRequest,
        handleApproveTransaction,
        handleConfirmModal,
        handleDownloadPPA,
        handleFeedbackModal,
        handlePrintTransactionReport,
        handleRejectTransaction,
        handleRerouteRejectModalMessage,
        handleRerouteTransaction,
        handleSelectHeaderCheck,
        handleViewDetails,
        onRowSelect,
        setDisabledRows,
        setShowRerouteRejectModal,
        verifyTransactions,
        approveRequest,
        setActiveDisabledRows,
        headerCheckbox,
        headerCheckboxInter,
        columnsDaily,
        dailyTableData,
        showFeedbackModal,
        showRerouteRejectModal,
        transactionRef,
        showConfirmationModal,
        type,
        requestId,
        activeRowCount,
        modalMessages,
        getDailySummaryCount,
    } = useDailyHandler({
        dailySummaryState,
        downloadType,
        filters,
        idTokenHeader,
        isFilterApplied,
        isSearchResult,
        submittedOnDropdown,
        loadingHandler,
        pendingTab,
        redirect,
        searchInput,
        selectAllFromBanner,
        selectedRows,
        setErrorHandler,
        setErrorMessage,
        setFilters,
        setSubmittedOnDropdown,
        setSelectedRows,
        setSortData,
        showCheckBox,
        sortData,
    });
    const { orderCount } = useContext(DashboardBranchContext);

    return (
        <Fragment>
            {showResultCount && searchInput ? (
                <TextDarkBlack size="16px" weight="700" style={{ margin: '24px' }}>
                    {`${orderCount[0] + orderCount[1]} result(s) found for '${searchInput}'`}
                </TextDarkBlack>
            ) : null}
            <AdvanceTable
                columns={columnsDaily}
                data={dailyTableData.current}
                onEmptyState={
                    isFilterApplied ? EMPTY_STATES.emptyStateTableCEFilters : EMPTY_STATES.emptyStateTableDaily
                }
                rowSelection={showCheckBox ? selectedRows : undefined}
                RenderOptions={(props: ITableOptions) => (
                    <DailyOptions
                        {...props}
                        handleApproveTransaction={handleApproveTransaction}
                        handleApproveReviewRequest={handleApproveReviewRequest}
                        handleRejectTransaction={handleRejectTransaction}
                        handleRerouteTransaction={handleRerouteTransaction}
                        handleViewDetails={handleViewDetails}
                        handlePrintTransaction={handlePrintTransactionReport}
                        pendingTab={pendingTab}
                        handleDownloadPPA={handleDownloadPPA}
                    />
                )}
                RenderItem={(props: ITableCustomItem) => (
                    <CustomItemDaily isSortedColumn={sortData[0].column} {...props} />
                )}
                groupedRow={true}
                hasHeaderCheckbox={showCheckBox}
                headerCheckbox={headerCheckbox}
                headerCheckboxDisabled={downloadType === LABEL.dailySummaryReportFormA || activeRowCount === 0}
                headerCheckboxInter={headerCheckboxInter}
                handleSelectHeaderCheck={handleSelectHeaderCheck}
                onRowSelect={onRowSelect}
                disabledRows={
                    downloadType !== '' && downloadType !== null && downloadType !== LABEL.dailySummaryReportFormA
                        ? setDisabledRows(downloadType)
                        : undefined
                }
                activeDisabledRows={
                    downloadType === LABEL.dailySummaryReportFormA ? setActiveDisabledRows(downloadType) : undefined
                }
                isSearchResult={isSearchResult}
                searchInput={searchInput}
                testId="branchdaily"
            />

            {showFeedbackModal ? (
                <Modal
                    modalActive={showFeedbackModal}
                    setModalActive={handleFeedbackModal}
                    title={modalMessages.feedback.heading}
                    icon={modalMessages.feedback.icon}
                    primaryBtn={{
                        onClick: () => {
                            handleFeedbackModal();
                            redirect('daily');
                            getDailySummaryCount();
                        },
                        label: LABEL.done,
                        primary: true,
                    }}
                    testId="transaction-feedback"
                    contentAlignment="center"
                >
                    {modalMessages.feedback.content}
                </Modal>
            ) : null}

            {/* Feedback Modal for approving reroute and reject request */}
            {showRerouteRejectModal ? (
                <Modal
                    modalActive={showRerouteRejectModal}
                    setModalActive={setShowRerouteRejectModal}
                    title={modalMessages.feedback.heading}
                    icon={modalMessages.feedback.icon}
                    primaryBtn={{
                        onClick: () => {
                            setShowRerouteRejectModal((prevState) => !prevState);
                            redirect('daily');
                            getDailySummaryCount();
                        },
                        label: LABEL.done,
                        primary: true,
                    }}
                    testId="reject-reroute-feedback"
                    contentAlignment="center"
                >
                    {handleRerouteRejectModalMessage(transactionRef)}
                </Modal>
            ) : null}
            {showConfirmationModal ? (
                <Modal
                    title={modalMessages.confirmation.heading}
                    modalActive={showConfirmationModal}
                    setModalActive={handleConfirmModal}
                    primaryBtn={{
                        onClick:
                            type === 'verify'
                                ? () => verifyTransactions(transactionRef)
                                : () => approveRequest(requestId),
                        label: LABEL.yes,
                        primary: true,
                        width: '212px',
                    }}
                    secondaryBtn={{ onClick: handleConfirmModal, label: LABEL.noNo, width: '212px' }}
                    testId="transaction-confirmation"
                >
                    {modalMessages.confirmation.content}
                </Modal>
            ) : null}
        </Fragment>
    );
};

export default Daily;
