import { IValidation, IValidationAll, ValidationRespond } from '../interfaces/IValidation';

export const Validate = (value: string, options: IValidation, msg?: string): ValidationRespond => {
    const defaultMSG = msg ? msg : 'Value';
    const settings: IValidationAll = {
        type: options.type,
        maxLength: options.maxLength ? options.maxLength : -1,
        minLength: options.minLength ? options.minLength : -1,
        minSpecialChars: options.minSpecialChars ? options.minSpecialChars : -1,
        maxSpecialChars: options.maxSpecialChars ? options.maxSpecialChars : -1,
        minChars: options.minChars ? options.minChars : -1,
        maxChars: options.maxChars ? options.maxChars : -1,
        maxDigits: options.maxDigits ? options.maxDigits : -1,
        minDigits: options.minDigits ? options.minDigits : -1,
        required: options.required ? options.required : false,
        dotAllowed: options.dotAllowed ? options.dotAllowed : true,
        noSpace: options.noSpace ? options.noSpace : false,
        singleSpace: options.singleSpace ? options.singleSpace : false,
    };

    if (value.length < 1) {
        if (settings.required) {
            return { code: 'Empty', message: `${defaultMSG} cannot be empty` };
        } else {
            return { code: 'NoError', message: `${defaultMSG} is valid` };
        }
    }

    switch (options.type) {
        case 'password': {
            settings.minLength = 8;
            settings.minDigits = 1;
            settings.minChars = 1;
            settings.minSpecialChars = 1;
            break;
        }
        case 'phone': {
            settings.minLength = 9;
            settings.dotAllowed = false;
            break;
        }
    }
    const hasCharacter = /^(?=.*[A-Za-z]).*$/.test(value);
    const hasWhiteSpace = /\s/.test(value);
    const hasDigit = /^(?=.*\d).*$/.test(value);
    const hasSpecial = /[-!$%^&*()_+|~=`{}\[\]:";'<@#>?,.\/]/.test(value);
    const hasDot = /[.\/]/.test(value);
    const isValidEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value,
        );
    const hasTwoSpaces = /\s\s/.test(value);

    if (options.type === 'string' && hasDigit) {
        return { code: 'NumbersNotAllowed', message: `${defaultMSG} must not have digits.` };
    }

    if (settings.noSpace && hasWhiteSpace) {
        return { code: 'SpaceNotAllowed', message: `${defaultMSG} must not have space.` };
    }

    if (settings.singleSpace && hasTwoSpaces) {
        return { code: 'TwoSpacesNotAllowed', message: `${defaultMSG} must not have two spaces.` };
    }

    if (settings.minChars > 0 && !hasCharacter) {
        return { code: 'MinCharacters', message: `${defaultMSG} must have minimum ${settings.minChars} letters.` };
    }
    if (settings.minDigits > 0 && !hasDigit) {
        return { code: 'MinCharacters', message: `${defaultMSG} must have minimum ${settings.minDigits} digits.` };
    }
    if (settings.minSpecialChars > 0 && !hasSpecial) {
        return {
            code: 'MinSpecialCharacters',
            message: `${defaultMSG} must have minimum ${settings.minSpecialChars} special characters.`,
        };
    }
    if (value.length < settings.minLength && settings.minLength > 0) {
        return { code: 'MinLength', message: `${defaultMSG} must be minimum ${settings.minLength} characters` };
    }
    if (value.length > settings.maxLength && settings.maxLength > 0) {
        return {
            code: 'MaxLength',
            message: `${defaultMSG} must not be more than ${settings.maxLength} characters`,
        };
    }
    if (settings.type === 'alphanumeric' && hasSpecial) {
        if (settings.minSpecialChars < 1)
            return { code: 'SpecialCharacters', message: `${defaultMSG} must not have special characters` };
    }
    if (settings.type === 'email' && !isValidEmail) {
        return { code: 'Email', message: `${defaultMSG} is not a valid email` };
    }
    if (settings.type === 'number' && (hasCharacter || hasSpecial)) {
        return { code: 'NotNumber', message: `${defaultMSG} is not a valid number` };
    }
    if (settings.type === 'phone' && (hasCharacter || hasSpecial || hasDot)) {
        return { code: 'NotNumber', message: `${defaultMSG} is not a valid number` };
    }

    return { code: 'NoError', message: `${defaultMSG} is valid` };
};
