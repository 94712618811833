import React from 'react';

export const getDisabledStatus = (formState: IRejectFormState): boolean => {
    let _disabled = true;

    const _toggleArray: boolean[] = [];
    formState.sectionToggle
        .filter((item) => {
            return item.checked == true;
        })
        .map((item) => {
            switch (item.id) {
                case 'document':
                    Array.isArray(formState.document) && formState.document.length !== 0
                        ? _toggleArray.push(false)
                        : _toggleArray.push(true);
                    break;

                case 'payment':
                    Array.isArray(formState.payment) && formState.payment.length !== 0
                        ? _toggleArray.push(false)
                        : _toggleArray.push(true);
                    break;
                case 'invalidInformation':
                    Array.isArray(formState.invalidInformation) && formState.invalidInformation.length !== 0
                        ? _toggleArray.push(false)
                        : _toggleArray.push(true);
                    break;
                case 'remarks':
                    /\S/.test(formState.remarks) && formState.remarks.length !== 0
                        ? _toggleArray.push(false)
                        : _toggleArray.push(true);
                    break;
                default:
                    _disabled = true;
            }
        });
    const ifChecked = formState.sectionToggle
        .filter((item) => {
            return item.checked == true;
        })
        .some((item) => {
            return item.checked == true ? (_disabled = false) : (_disabled = true);
        });
    const newCond = formState.sectionToggle.length !== 0 ? ifChecked : (_disabled = true);
    _toggleArray.filter(Boolean).length !== 0 ? (_disabled = true) : newCond;

    return _disabled;
};

// Fn to update form state for review
export const updateFormState = (data: ReasonsResult[], formState: IRejectFormState): IRejectFormState => {
    const _tempFormState: IRejectFormState = { ...formState };
    data.forEach((item) => {
        switch (item.category?.toLowerCase()) {
            case 'payment':
            case 'document':
                const _requestOptions =
                    item.subCategory && (item.subCategory?.map((option) => option[1]) as React.ReactText[]);
                item.category?.toLowerCase() === 'payment'
                    ? (_tempFormState.payment = _requestOptions)
                    : (_tempFormState.document = _requestOptions);
                _tempFormState.sectionToggle.push({
                    id: item.category?.toLowerCase(),
                    checked: _requestOptions ? true : false,
                });
                break;
            case 'invalid information':
                const _invalidOptions =
                    item.subCategory && (item.subCategory?.map((option) => option[1]) as React.ReactText[]);
                _tempFormState.invalidInformation = _invalidOptions;
                _tempFormState.sectionToggle.push({
                    id: 'invalidInformation',
                    checked: _invalidOptions ? true : false,
                });
                break;
            case 'others':
                _tempFormState.remarks = (item.subCategory && item.subCategory[0][1]) ?? '';
                _tempFormState.sectionToggle.push({ id: 'remarks', checked: _tempFormState.remarks.length !== 0 });
                break;
        }
    });

    return _tempFormState;
};
