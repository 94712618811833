interface IFilterOptions {
    [key: string]: string[];
}
interface ISearchFilterElement {
    id: string;
    label: string;
    elementType: string;
    options: FilterOption[];
    placeHolder: string;
    value: string;
}
type FilterOption = {
    label: string;
    value: string;
};

export const buildSearchFiltersConfig = (filters: IFilterOptions): ISearchFilterElement[][] => {
    const uploadFilterConfig: ISearchFilterElement[] = [
        {
            id: 'lastUpdate',
            label: 'Date',
            elementType: 'select',
            options: [
                { label: 'Last Updated', value: 'lastUpdate' },
                { label: 'Uploaded On', value: 'uploadedOn' },
            ],
            placeHolder: 'Select one',
            value: '',
        },
        {
            id: 'dateRange',
            label: 'Date Range',
            elementType: 'dateRange',
            options: [],
            placeHolder: '',
            value: '',
        },

        {
            id: 'status',
            label: 'Status',
            elementType: 'select',
            options: [],
            placeHolder: 'Select one',
            value: '',
        },
    ];

    const formElements: ISearchFilterElement[][] = [];
    let formElementsRow: ISearchFilterElement[] = [];

    uploadFilterConfig.map((elm, index) => {
        Object.keys(filters).map((filterKey) => {
            if (filterKey === elm.id) {
                filters[elm.id].map((option) =>
                    uploadFilterConfig[index].options.push({ label: option, value: option }),
                );
            }
        });
        formElementsRow.push(elm);
        if (elm.id === 'dateRange' || elm.id === 'status') {
            formElements.push(formElementsRow);
            formElementsRow = [];
        }
    });

    return formElements;
};
