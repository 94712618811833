/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
    Fragment,
    ReactText,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
//Libraries
import styled from 'styled-components';
import moment from 'moment';
//Routes
import * as ROUTES from '../../routes';
//Context
import AuthContext from '../../context/AuthContext';
import ErrorHandlingContext from '../../context/ErrorHandling/ErrorHandlingContext';
//graphQL
import transactionStatusMutation from '../../_graphql/mutations/branchCE/afterReviewUpdate/transactionStatus';
import { transactionReasonList } from '../../_graphql/queries/common/transactionReasonList';
import { ceCheckerViewRequest } from '../../_graphql/queries/branchCE/checkerViewRequest/checkerViewRequest';
import { ceCheckerReviewRequest } from '../../_graphql/mutations/branchCE/ceCheckerReviewRequest/ceCheckerReviewRequest';
//Components
import RejectRemarks from '../RejectRemarks/RejectRemarks';
import { Modal } from '../../components/organisms';

import { PreviousPage } from '../../components';
import { CustomButton, CustomSpacer, FlexedDiv, TextArea } from '../../components/atoms';
import { Banner, CollapsibleDisplay, ComponentLoader } from '../../components/molecules';
//Utils Types
import { useComponentLoader } from '../../customHooks';

import { CE_DASHBOARD_LABELS, LABEL, TextDarkBlack, TextDarkGrey4, TextDarkGrey5, SYSTEM_ADMIN } from '../../constants';
import { reasonsPageButtons } from '../../styles';

import CustomCheckbox from '../../components/atoms/Checkbox/Checkbox';
import { ContentWrapperWithTitle } from '../../components/organisms/ContentWrapperWithTitle';
import { buildRerouteFormConfig } from './rerouteFormConfig';
import { Toggle } from '../../components/atoms/Toggle/Toggle';

import MultiSelect from '../../components/molecules/MultiSelect/MultiSelect';
import { getDisabledStatus, getIdsByValue, updateFormState } from './rerouteUtils';
import { MultiSelectCard } from '../../components/molecules/MultiSelectCard';

interface IUpdateTransactionStatusProps {
    remark: string;
    transactionRef: any | string;
    action: string;
    reasonId: Array<ReactText>;
    targetDate: string;
    docList: IDocList;
}

interface IDocList {
    category: string;
    docId: any | string;
}

interface IRerouteTransactionPageProps {
    data: ITableData;
    isReject?: boolean;
}

type listTransactionRefs = {
    transactions: string[];
    showTransactions: boolean;
};

type InputWrapProps = {
    maxWidth: string;
    mb?: string;
    mt?: string;
};
type ToggleWrapProps = {
    expanded: boolean;
};

type MemoizedRerouteInput = {
    requestData: IRerouteRequest;
};

interface StyledInnerProps {
    paddingBottom: number;
}
const modalMessages = {
    confirmation: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
    feedback: {
        heading: '',
        content: <span></span>,
        icon: '',
        type: '',
    },
};

export const RerouteTransaction: React.FC = () => {
    const initialState: RerouteFormState = {
        targetDate: '',
        payment: [],
        document: [],
        checkedDocs: [],
        sectionToggle: [],
        remarksCheck: { id: 'remarks', checked: false },
        remarks: '',
        reroutedDocs: [],
    };

    //Context
    const { userLoginContext } = useContext(AuthContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;

    const parsedPermissionPrivilege: ICeDashboard =
        parsedPermission.branch.grant !== undefined
            ? parsedPermission.branch.permission.ceTransaction
            : parsedPermission.hq.permission.ceDashboard;

    const { pendingTab } = parsedPermissionPrivilege;

    // Data states
    const [rerouteResult, setRerouteResult] = useState<ReasonsResult[]>([]);
    const [dueDate, setDueDate] = useState<string>('');
    const [reroutesDocs, setReroutedDocs] = useState<RerouteDocumentsElements[]>([]);

    const newDueDate = moment(parseInt(dueDate, 10)).format('DD/MM/YYYY');

    const [isRequest, setIsRequest] = useState<boolean>(false);
    const [requestData, setRequestData] = useState<IRerouteRequest>({
        eventType: '',
        requestorName: '',
        reasons: [],
        requestId: '',
        approval: '',
        orderNo: '',
        targetDate: '',
        rerouteDocuments: [],
    });
    const [approval, setApproval] = useState<IApproval>({
        eventType: 'reroute',
        name: '',
        remarks: null,
        status: '',
    });
    const [showTransactionRefs, setShowTransactionRefs] = useState<listTransactionRefs>({
        transactions: [],
        showTransactions: false,
    });
    const [rejectReq, setRejectReq] = useState<boolean>(false);
    const [rejectRemarks, setRejectRemarks] = useState<string>('');
    // Form reducer state
    const handleFilterDispatch = (formState: RerouteFormState, payload: RerouteFormPayload) => {
        const _temp = { ...formState };
        switch (payload.action) {
            case 'SET_TOGGLE':
                return payload.data;
            case 'SET_PAYMENT':
                return payload.data;
            case 'SET_UPDATE':
                return payload.data;
            case 'SET_REMARKS':
                return payload.data;
            default:
                return _temp;
        }
    };

    const [formState, filterDispatch] = useReducer(handleFilterDispatch, initialState);
    // Form UI elements state
    const [formConfig, setFormConfig] = useState<IRerouteFormSection[]>([]);
    const [bannerHeight, setBannerHeight] = useState<number>(0);
    // Modal states
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    // Location states
    const history = useHistory();
    const location = useLocation<IRerouteTransactionPageProps>();
    const branchId = sessionStorage.getItem('branchId');
    const transaction = location.state.data as ITableData;
    const isRejectRequest = location.state?.isReject;
    const transactionType = useRef<string>('');

    const { loading, loadingHandler } = useComponentLoader();

    const memoizedDashboardInput: MemoizedRerouteInput = useMemo(() => {
        return {
            requestData,
        };
    }, [requestData]);

    // Fn to call transactionStatus mutation
    const updateTransactionStatus = async (payload: IUpdateTransactionStatusProps) => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(transactionStatusMutation, {
                    input: payload,
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionStatus;
            if (resultCheck.error !== null) throw resultCheck.error;
            if (resultCheck.data.result && resultCheck.data.result.status === true) {
                setShowTransactionRefs({
                    ...showTransactionRefs,
                    transactions: resultCheck.data.result.transactionAffected,
                });
                handleFeedbackModal();
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Update Transaction Status',
                    testId: 'reroute-transaction-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    // Fn to call transactionCheckerStatus mutation
    const reviewRequest = async (requestId: string, action: string, remarks?: string) => {
        if (action === 'approve request') handleConfirmModal();
        const payload =
            remarks !== undefined
                ? {
                      requestId: requestId,
                      action: action,
                      remarks: remarks,
                  }
                : {
                      requestId: requestId,
                      action: action,
                  };

        try {
            const response: any = await API.graphql(
                graphqlOperation(ceCheckerReviewRequest, {
                    input: payload,
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.transactionCheckerStatus;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = response.data.transactionCheckerStatus.data;
            if (result.status) {
                setShowTransactionRefs({
                    ...showTransactionRefs,
                    transactions: resultCheck.data.result.transactionAffected,
                });
                setShowFeedbackModal((prevState) => !prevState);
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Review Request',
                    testId: 'reroute-transaction-error-modal',
                });
            } else {
                setErrorMessage({
                    errorCode: '',
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    // Fn to handle reroute order request
    const handleSubmit = () => {
        const category = ['Switch', 'Redeem'].includes(location.state.data.transactionType)
            ? 'Physical Document'
            : 'Document';
        const payload: IUpdateTransactionStatusProps = {
            transactionRef: [transaction.transactionRef],
            action: 'Reroute',
            targetDate: dueDate,
            reasonId: [],
            remark: formState.remarks.replace(/\s+/g, ' ').trim(),
            docList: {
                category,
                docId: formState.reroutedDocs,
            },
        };

        const _paymentIds = getIdsByValue(formState.payment, rerouteResult);
        const _documentIds = getIdsByValue(formState.document, rerouteResult);
        payload.reasonId = _paymentIds.concat(_documentIds);

        // set feedback modal data
        if (pendingTab.actions.canReroute === LABEL.autoAuthorizer) {
            modalMessages.feedback.heading = LABEL.orderRerouted;
            modalMessages.feedback.icon = 'trx-verification-success';
        } else {
            modalMessages.feedback.heading = CE_DASHBOARD_LABELS.DAILY.rerouteRequestSubmit;
            modalMessages.feedback.icon = 'trx-verification-request-submit';
        }
        updateTransactionStatus(payload);
    };
    // Fn to handle approve reroute order requests
    const handleApproveRequest = () => {
        // set confirmation modal data
        modalMessages.confirmation.heading = `${CE_DASHBOARD_LABELS.DAILY.approveRerouteOrderRequest}`;
        modalMessages.confirmation.content = (
            <FlexedDiv direction="column">
                <Subtitle>{CE_DASHBOARD_LABELS.DAILY.approveRerouteOrderRequestMessage}</Subtitle>
                <CustomSpacer space={'.5rem'} />
                <Subtitle>{CE_DASHBOARD_LABELS.DAILY.yesOrNoApprove}</Subtitle>
            </FlexedDiv>
        );
        modalMessages.confirmation.type = 'approve request';

        // set feedback modal data
        modalMessages.feedback.heading = `${CE_DASHBOARD_LABELS.DAILY.rerouteRequestApproved}`;

        modalMessages.feedback.icon = 'trx-verification-success';
        setShowConfirmationModal((prevState) => !prevState);
    };

    /** function to handle the reviewRequest modal messages */
    const handleFeedbackModalMessage = (type: string) => {
        return type !== 'maker' ? (
            <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px' }}>
                    <Bold>Order {transaction.orderNo}</Bold>
                    &nbsp;has been rerouted to the adviser.
                    <br />
                    {pendingTab.reviewApproval.canApproveReRoute === LABEL.checker
                        ? 'Maker, Adviser and CE(HQ) will be notified.'
                        : 'Adviser will be notified.'}
                </p>
                {showTransactionRefs.transactions.length !== 0 ? (
                    <CollapseWrapper removeBorder={showTransactionRefs.showTransactions}>
                        <CollapsibleDisplay
                            displayTextForExpand={CE_DASHBOARD_LABELS.DAILY.viewMore}
                            displayTextForCollapse={CE_DASHBOARD_LABELS.DAILY.viewLess}
                            textColor="#002043"
                            expanded={showTransactionRefs.showTransactions}
                            handleExpand={() =>
                                setShowTransactionRefs({
                                    ...showTransactionRefs,
                                    showTransactions: !showTransactionRefs.showTransactions,
                                })
                            }
                            testId="confirm"
                            content={
                                <div>
                                    <Bold style={{ fontSize: '0.75rem' }}>{`Rerouted transactions in this order`}</Bold>
                                    <TransactionRefList>
                                        {showTransactionRefs.transactions.map((item, index) => (
                                            <li key={index + 1}>{item}</li>
                                        ))}
                                    </TransactionRefList>
                                </div>
                            }
                            contentStyle={{ textAlign: 'left' }}
                            buttonStyle={{ alignSelf: 'center' }}
                        />
                    </CollapseWrapper>
                ) : null}
            </FlexedDiv>
        ) : (
            <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px', marginTop: '-1rem' }}>
                This request has been submitted and pending <br />
                for review by checker.
            </p>
        );
    };
    // Fn to handle reject reroute order requests
    const handleRejectRequest = () => {
        // set feedback modal data
        modalMessages.feedback.heading = `${CE_DASHBOARD_LABELS.DAILY.rerouteRequestRejected}`;
        modalMessages.feedback.content = (
            <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '16px', marginTop: '-1rem' }}>
                    Request for&nbsp;
                    <Bold>Order&nbsp;{transaction.orderNo}</Bold>
                    &nbsp;has been rejected.
                    <br /> The maker will be notified.
                </p>
            </FlexedDiv>
        );
        modalMessages.feedback.icon = 'trx-verification-request-approve';
        requestData.requestId !== undefined ? reviewRequest(requestData.requestId, 'reject', rejectRemarks) : null;
    };

    // Fn to handle route back from feedback modal
    const handleDone = () => {
        if (parsedPermission.hq.grant !== undefined) {
            if (window.location.pathname.includes(ROUTES.hqAllSubmissions)) history.push(ROUTES.hqAllSubmissions);
            else if (branchId !== null && transaction.fromInbox !== true) history.push(ROUTES.viewBranch);
            else history.push(ROUTES.dashboard);
        } else {
            history.push(ROUTES.dashboardBranch);
        }
    };

    const handleRemarks = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const _tempObj = { ...formState };
        _tempObj.remarks = event.target.value;

        filterDispatch({ data: _tempObj, action: 'SET_REMARKS' });
        // setRemark(event.target.value);
    };

    const handleIsRequest = () => {
        setIsRequest(!isRequest);
    };

    // Fn to toggle confirmation modal
    const handleConfirmModal = () => {
        setShowConfirmationModal((prevState) => !prevState);
    };
    // Fn to toggle feedback modal
    const handleFeedbackModal = () => {
        setShowFeedbackModal((prevState) => !prevState);
        // setApprove(!approve);
    };
    const handleCancel = () => {
        setRejectReq(false);
        setRejectRemarks('');
        history.goBack();
    };
    // Fn to expand selcted section
    const getToggledSection = (id: string): boolean => {
        const _tempObj = { ...formState };
        let temp = false;
        const _index = _tempObj.sectionToggle.findIndex((item) => item.id === id);
        temp = _index >= 0 ? _tempObj.sectionToggle[_index].checked : false;

        return temp;
    };
    // Fn to handle section expand
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const _tempObj: RerouteFormState = JSON.parse(JSON.stringify(formState));
        const { id, checked } = e.target;

        if (!_tempObj.sectionToggle.find((item) => item.id === id)) {
            _tempObj.sectionToggle.push({ id: id, checked: checked });
        } else {
            const _index = _tempObj.sectionToggle.findIndex((item) => item.id === id);

            _index >= 0 && (_tempObj.sectionToggle[_index].checked = !_tempObj.sectionToggle[_index].checked);
            //clear relevant select state if toggle is off
            if (!checked && (id === 'payment' || id === 'document' || id === 'checkedDocs')) {
                _tempObj[id].length = 0;
                id === 'document' ? (_tempObj.reroutedDocs = []) : null;
            }

            if (!checked && id === 'remarks') _tempObj[id] = '';
        }

        const documentObj: IRerouteFormSection | undefined = formConfig.find((ele) => ele.id === 'document');
        if (documentObj?.hasOwnProperty('id')) {
            const reasonsList: any = documentObj.body.find(
                (ele) => ele.elementType === 'multiselect',
            )?.multiSelectOptions;
            if (reasonsList.length === 1) {
                _tempObj.document = reasonsList;
            }
        }

        filterDispatch({ data: _tempObj, action: 'SET_TOGGLE' });
    };

    // Fn to handle multiselect drop down options
    const handleMultiSelectDropdown = (item: React.ReactText[], id: string) => {
        const _tempObj = { ...formState };
        switch (id) {
            case 'payment': //payment select
                _tempObj.payment = item;
                break;
            case 'document': //NTB document select
                _tempObj.document = item;
                break;
        }

        filterDispatch({ data: _tempObj, action: 'SET_PAYMENT' });
    };
    const handleMultiSelectCard = (e: React.ChangeEvent<HTMLInputElement>) => {
        const _tempObj = { ...formState };
        const event = e.target;
        const newArray = _tempObj.reroutedDocs;
        if (newArray.includes(event.id)) newArray.splice(newArray.indexOf(event.id), 1);
        else newArray.push(event.id);
        filterDispatch({ data: _tempObj, action: 'SET_PAYMENT' });
    };
    // Fn to get selected mutliselect options
    const getMultiSelectedValues = (id: string): React.ReactText[] => {
        let _temp: React.ReactText[] = [];
        switch (id) {
            case 'payment': //payment select
            case 'document': //NTB document select
                formState[id] && formState[id].length !== 0 ? (_temp = formState[id]) : null;
                break;
        }

        return _temp;
    };

    // Fn to handle checkboxes inside sections
    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, sectionId?: string, group?: string) => {
        const { value: mainValue, checked, id } = e.target;
        const _tempObj = { ...formState };

        //fn to check if id matches with state checkbox ids
        const checkInValuesArrary = (): boolean => {
            let _found = false;
            _tempObj.checkedDocs.forEach((item) =>
                item.values.forEach((value) => {
                    if (value.id === id) _found = true;
                }),
            );
            return _found;
        };

        //related to document checkboxes
        if (sectionId && group) {
            if (checkInValuesArrary()) {
                //update existing checkbox checked state
                _tempObj.checkedDocs.forEach((item) =>
                    item.values.forEach((obj, index) => {
                        if (obj.id === id) {
                            checked ? (obj.checked = checked) : item.values.splice(index, 1);
                        }
                    }),
                );
            } else if (
                _tempObj.checkedDocs.find((item) => item.category === sectionId) &&
                _tempObj.checkedDocs.find((item) => item.subCategory === group)
            ) {
                //add new checkbox obj if category and subcategory exists
                _tempObj.checkedDocs.forEach((item) => {
                    if (item.subCategory === group) item.values.push({ id: id, value: mainValue, checked: checked });
                });
            } else {
                //add new checkbox obj to state
                const _obj = {
                    category: sectionId,
                    subCategory: group,
                    values: [{ id: id, checked: checked, value: mainValue }],
                };

                _tempObj.checkedDocs.push(_obj);
            }
        } else {
            //related to remarks checkbox
            _tempObj.remarksCheck.checked = checked;
        }

        filterDispatch({ data: _tempObj, action: 'SET_TOGGLE' });
    };
    // Fn to get chcked state of checkboxes
    const getCheckboxStatus = (id: string): boolean => {
        let temp = false;
        const _tempObj = { ...formState };

        if (isRequest === false) {
            if (id === 'remarks') {
                temp = _tempObj.remarksCheck.checked;
            } else {
                _tempObj.checkedDocs.forEach((item) =>
                    item.values.forEach((value) => {
                        if (value.id === id) temp = value.checked;
                    }),
                );
            }
        }

        return temp;
    };

    // const getCheckboxDocId = (id: string): boolean => {
    //     let temp = false;
    //     const _tempObj = { ...formState };
    // };

    //Fn to update form state when in review mode
    const getUpdateFormStateCb = useCallback(() => {
        const _updatedFormState = updateFormState(rerouteResult, formState, reroutesDocs);

        filterDispatch({ data: _updatedFormState, action: 'SET_UPDATE' });
    }, [memoizedDashboardInput]);

    useEffect(() => {
        /** @function checks the mode of the page for review,if true initiate the hook to call Request Api else calls the reasons list Api */
        const checkMode = () => {
            transaction.approvalType === 'reroute' && transaction.status.toLowerCase() === 'pending approval'
                ? setIsRequest(true)
                : getTransactionReasonList();
        };
        /** * @maintainableListEndpoint */
        const getTransactionReasonList = async () => {
            loadingHandler();
            transactionType.current = location.state.data?.transactionType;
            const _transactionReasonList = 'transactionReasonList';
            // transactionType.current === 'Sales-NS' ? 'transactionReasonListV2' : 'transactionReasonList';
            try {
                const response: any = await API.graphql(
                    graphqlOperation(transactionReasonList, {
                        input: {
                            actionType: 'Reroute',
                            transactionType:
                                transaction?.transactionType === 'Switch' || transaction?.transactionType === 'Redeem'
                                    ? transaction?.transactionType
                                    : 'Sales',
                            //if transaction?.trans
                            source: 'ce',
                            transactionRef: transaction.transactionRef,
                        },
                    }),
                    //For later module implementation
                    // graphqlOperation(transactionReasonListV2, {
                    //     input: {
                    //         actionType: 'Reroute',
                    //         transactionType: 'Sales-NS',
                    //         source: 'ce',
                    //         transactionRef: 'S0525A004',
                    //     },
                    // }),
                    idTokenHeader,
                );
                const { reasons, targetDate, rerouteDocuments }: IRerouteResult =
                    response.data[_transactionReasonList].data.result;
                const _formConfig = buildRerouteFormConfig(reasons, transactionType.current ?? '', rerouteDocuments);
                setDueDate(targetDate);
                setRerouteResult(reasons);
                setFormConfig(_formConfig);
                loadingHandler();
            } catch (error) {
                const _error = error as IErrorHandling;
                loadingHandler();
                if (Object.keys(_error).includes('errorCode')) {
                    handleErrorHandler();
                    setErrorMessage({
                        ...errorMessage,
                        message: _error.message,
                        errorCode: _error.errorCode,
                        title: 'Cannot Fetch Reroute Reasons',
                        testId: 'reroute-transaction-error-modal',
                    });
                } else {
                    setErrorMessage({
                        errorCode: '',
                        message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                        title: 'Something Went Wrong',
                        testId: 'gql-error-modal',
                    });
                    handleErrorHandler();
                }
            }
        };
        checkMode();
    }, [rejectReq]);

    useEffect(() => {
        /** @requestDetails */
        const getRequestDetails = async () => {
            loadingHandler();
            transactionType.current = location.state.data?.transactionType;
            try {
                const response: any = await API.graphql(
                    graphqlOperation(ceCheckerViewRequest, {
                        input: {
                            requestId: transaction.requestId,
                        },
                    }),
                    idTokenHeader,
                );
                const resultCheck = response.data.transactionRequest;
                if (resultCheck.error !== null) throw resultCheck.error;
                const result = response.data.transactionRequest.data.result;
                const { targetDate, orderNo, reasons, rerouteDocuments } = result;

                transaction.orderNo = orderNo;

                const _formConfig = buildRerouteFormConfig(reasons, transactionType.current ?? '', rerouteDocuments);

                if (result.approval !== null) {
                    setApproval(result.approval);
                }
                setDueDate(targetDate);
                setRerouteResult(reasons);
                setReroutedDocs(rerouteDocuments);
                setRequestData(result);
                setFormConfig(_formConfig);
                loadingHandler();
            } catch (error) {
                const _error = error as IErrorHandling;
                loadingHandler();
                if (Object.keys(_error).includes('errorCode')) {
                    handleErrorHandler();
                    setErrorMessage({
                        ...errorMessage,
                        message: _error.message,
                        errorCode: _error.errorCode,
                        title: 'Cannot Fetch Request Details',
                        testId: 'reroute-transaction-error-modal',
                    });
                } else {
                    setErrorMessage({
                        errorCode: '',
                        message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                        title: 'Something Went Wrong',
                        testId: 'gql-error-modal',
                    });
                    handleErrorHandler();
                }
            }
        };

        isRequest ? getRequestDetails() : null;
        if (isRejectRequest === true) setRejectReq(true);
    }, [isRequest]);

    useEffect(() => {
        isRequest && getUpdateFormStateCb();
    }, [getUpdateFormStateCb]);

    return (
        <Fragment>
            {rejectReq ? (
                <Fragment>
                    <RejectRemarks
                        handleCancel={handleCancel}
                        handleSubmit={handleRejectRequest}
                        handleRejectRemarks={(e) => {
                            setRejectRemarks(e.target.value);
                        }}
                        rejectRemarks={rejectRemarks}
                    />
                </Fragment>
            ) : (
                <Fragment>
                    {isRequest ? (
                        <PreviousPage
                            title={LABEL.reviewRerouteRequest}
                            subTitle={LABEL.reviewUserDescription}
                            secondSubtitle={LABEL.reviewUserDescription2}
                        />
                    ) : (
                        <PreviousPage title={LABEL.rerouteOrder} subTitle={LABEL.rerouteOrderDescription} />
                    )}
                    <StyledInner paddingBottom={bannerHeight}>
                        {approval.status.length !== 0 ? (
                            <ApprovalBanner direction="column">
                                <Title>{`This request was ${approval.status.toLowerCase()} by ${approval.name}`}</Title>

                                <FlexedDiv
                                    style={{
                                        paddingTop: '0.5rem',
                                        width: '100%',
                                        whiteSpace: 'break-spaces',
                                        wordBreak: 'break-all',
                                    }}
                                    justifyContent="space-between"
                                >
                                    {approval.remarks !== undefined && approval.remarks !== null
                                        ? `${LABEL.rejectedReason}: ${approval.remarks}`
                                        : ''}
                                </FlexedDiv>
                            </ApprovalBanner>
                        ) : null}
                        <WrapperDiv>
                            <ContentWrapperWithTitle
                                title={LABEL.reasonsToReroute}
                                subtitle="Select one of the category below and fill up the information required."
                                padding="0px 0px"
                            >
                                {formConfig.map((section) => (
                                    <>
                                        <ToggleWrap
                                            key={`${section.id}-togglewrap`}
                                            expanded={getToggledSection(section.toggle.id)}
                                        >
                                            <Toggle
                                                handleToggle={handleToggle}
                                                selected={getToggledSection(section.toggle.id)}
                                                id={section.toggle.id}
                                                label={section.toggle.label}
                                                disabled={isRequest}
                                            />
                                        </ToggleWrap>

                                        {getToggledSection(section.toggle.id) && (
                                            <SectionBody>
                                                {section?.subTitle && (
                                                    <TextDarkGrey4 size="12px" style={{ marginBottom: '1rem' }}>
                                                        {section?.subTitle}
                                                    </TextDarkGrey4>
                                                )}

                                                {section.body.map((elem, elmIndex) => {
                                                    if (elem.elementType === 'multiselect') {
                                                        return (
                                                            <InputWrap maxWidth="328px" key={elmIndex}>
                                                                <MultiSelect
                                                                    handleMultiSelect={(item: React.ReactText[]) =>
                                                                        handleMultiSelectDropdown(item, elem.id)
                                                                    }
                                                                    checkedState={getMultiSelectedValues(elem.id)}
                                                                    options={
                                                                        elem.multiSelectOptions
                                                                            ? elem.multiSelectOptions
                                                                            : []
                                                                    }
                                                                    testId={`${elem.id}-dropdown-filter`}
                                                                    selectId={1}
                                                                    placeHolder={elem && elem.placeHolder}
                                                                    label={elem.label}
                                                                    noOverlay={false}
                                                                    disabled={
                                                                        isRequest ||
                                                                        (elem.multiSelectOptions
                                                                            ? elem.multiSelectOptions
                                                                            : []
                                                                        ).length < 2
                                                                    }
                                                                    expandable={isRequest}
                                                                />
                                                            </InputWrap>
                                                        );
                                                    }
                                                    if (
                                                        elem.elementType === 'multiselectcard' &&
                                                        getMultiSelectedValues(elem.id).length !== 0
                                                    ) {
                                                        return (
                                                            <div key={elmIndex} style={{ margin: '1.5rem 0' }}>
                                                                <InputWrap maxWidth="328px" mb="0.5rem">
                                                                    <TextDarkGrey4 size="12px">
                                                                        {LABEL.selectDocument}
                                                                    </TextDarkGrey4>
                                                                </InputWrap>

                                                                {elem.supportingDocs?.map((supportingDoc) => (
                                                                    <>
                                                                        <DocumentCategoryWrapper>
                                                                            <TextDarkBlack
                                                                                size="12px"
                                                                                lineHeight="130%"
                                                                                weight="700"
                                                                            >
                                                                                {supportingDoc.documentCategory}
                                                                            </TextDarkBlack>
                                                                            <LineBlock />
                                                                        </DocumentCategoryWrapper>
                                                                        <MultiSelectCardWrap>
                                                                            {supportingDoc.documents.map((doc) => (
                                                                                <MultiSelectCard
                                                                                    documentName={doc.documentName}
                                                                                    fileName={doc.fileName}
                                                                                    checked={{
                                                                                        checked:
                                                                                            formState.reroutedDocs.includes(
                                                                                                doc.id,
                                                                                            ),
                                                                                        id: doc.id,
                                                                                        handleCheckbox:
                                                                                            handleMultiSelectCard,
                                                                                    }}
                                                                                    disabled={isRequest}
                                                                                    key={`${doc.id}-abc`}
                                                                                />
                                                                            ))}
                                                                        </MultiSelectCardWrap>
                                                                    </>
                                                                ))}
                                                            </div>
                                                        );
                                                    }

                                                    if (elem.elementType === 'checkboxWithInput') {
                                                        return (
                                                            <InputWrap maxWidth="100%" key={elmIndex}>
                                                                <TextArea
                                                                    maxLength={255}
                                                                    disabled={isRequest}
                                                                    label={elem.label}
                                                                    value={formState.remarks}
                                                                    handleInput={handleRemarks}
                                                                    style={{
                                                                        minWidth: '512px',
                                                                        width: '100%',
                                                                    }}
                                                                    placeHolder={elem.placeHolder}
                                                                />
                                                            </InputWrap>
                                                        );
                                                    }

                                                    if (elem.groupedElements) {
                                                        return (
                                                            <Grouped key={elmIndex}>
                                                                <GroupedHeader>
                                                                    <TextDarkBlack size="12px" weight="700">
                                                                        {elem.groupedElements.grouptitle}
                                                                    </TextDarkBlack>
                                                                    <LineDiv />
                                                                </GroupedHeader>
                                                                <ul>
                                                                    {elem.groupedElements.elements.map((checkbox) => (
                                                                        <li
                                                                            key={checkbox.id}
                                                                            style={{ marginBottom: '16px' }}
                                                                        >
                                                                            <CustomCheckbox
                                                                                key={checkbox.id}
                                                                                label={checkbox.label}
                                                                                value={checkbox.value}
                                                                                checked={getCheckboxStatus(checkbox.id)}
                                                                                id={checkbox.id}
                                                                                handleCheckbox={(e) =>
                                                                                    handleCheckbox(
                                                                                        e,
                                                                                        section.id,
                                                                                        elem.groupedElements
                                                                                            ?.grouptitle,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </Grouped>
                                                        );
                                                    }
                                                })}
                                            </SectionBody>
                                        )}
                                        {section.toggle.id === 'remarks' ? null : (
                                            <StyledDividerContentWrapper id={`separator-${section.toggle.id}`} />
                                        )}
                                    </>
                                ))}
                                {/* </WrapperDiv> */}
                            </ContentWrapperWithTitle>
                        </WrapperDiv>
                        {dueDate !== '' ? (
                            <>
                                <StyledDivider />
                                <WrapperDiv>
                                    <ContentWrapperWithTitle title={LABEL.rerouteDueDate}>
                                        <TextDarkGrey5 weight="700" size={'12px'}>
                                            {LABEL.dueDate}
                                        </TextDarkGrey5>
                                        <TextDarkBlack weight="700">{newDueDate.toString()}</TextDarkBlack>
                                    </ContentWrapperWithTitle>
                                </WrapperDiv>
                            </>
                        ) : null}

                        {!isRequest && (
                            <ButtonDiv>
                                <CustomButton
                                    style={reasonsPageButtons}
                                    onClick={() => history.goBack()}
                                    disabled={isRequest}
                                    id="reroute-transaction-cancel-btn"
                                >
                                    {LABEL.cancel}
                                </CustomButton>
                                <CustomButton
                                    primary
                                    style={reasonsPageButtons}
                                    disabled={getDisabledStatus(formState, transactionType.current) || isRequest}
                                    onClick={() => handleSubmit()}
                                    id="reroute-transaction-confirm-btn"
                                >
                                    {LABEL.submit}
                                </CustomButton>
                            </ButtonDiv>
                        )}
                    </StyledInner>

                    {isRequest &&
                        (pendingTab.reviewApproval.canApproveReRoute === LABEL.checker ||
                            pendingTab.reviewApproval.canApproveReRoute === LABEL.autoAuthorizer) &&
                        approval.status === '' && (
                            <Banner
                                title={SYSTEM_ADMIN.LABEL_REQUEST_PENDING_APPROVAL}
                                isRequestBanner={false}
                                toggle={isRequest}
                                handleClose={handleIsRequest}
                                description={`${LABEL.requestedBy}`}
                                descriptionEmText={requestData?.requestorName}
                                testId="reroute-transaction-banner"
                                primaryButtonObject={{
                                    label: LABEL.approve,
                                    handlePrimaryBtn: () => handleApproveRequest(),
                                    width: '276px',
                                    icon: 'check',
                                }}
                                secondaryButtonObject={{
                                    label: LABEL.reject,
                                    handleSecondaryBtn: () => {
                                        setRejectReq(!rejectReq);
                                    },
                                    width: '276px',
                                    icon: 'terminate',
                                    color: '#E84C3D',
                                }}
                                setBannerHeight={setBannerHeight}
                            />
                        )}
                    {/* Yes or No modal */}
                    {showConfirmationModal ? (
                        <Modal
                            title={modalMessages.confirmation.heading}
                            modalActive={showConfirmationModal}
                            setModalActive={handleConfirmModal}
                            primaryBtn={{
                                onClick:
                                    modalMessages.confirmation.type === 'approve request'
                                        ? () => reviewRequest(requestData?.requestId, 'approve')
                                        : () => reviewRequest(requestData.requestId, 'reject', rejectRemarks),
                                label: LABEL.yes,
                                primary: true,
                            }}
                            secondaryBtn={{ onClick: handleConfirmModal, label: LABEL.noNo }}
                            testId="reroute-request-confirmation"
                        >
                            {modalMessages.confirmation.content}
                        </Modal>
                    ) : null}
                    {loading ? <ComponentLoader /> : null}
                </Fragment>
            )}
            {showFeedbackModal ? (
                <Modal
                    modalActive={showFeedbackModal}
                    setModalActive={handleFeedbackModal}
                    title={modalMessages.feedback.heading}
                    icon={modalMessages.feedback.icon}
                    primaryBtn={{
                        onClick: () => handleDone(),
                        label: LABEL.done,
                        primary: true,
                    }}
                    testId="order-feedback"
                    contentAlignment="center"
                >
                    {rejectReq
                        ? modalMessages.feedback.content
                        : handleFeedbackModalMessage(pendingTab.actions.canReroute)}
                </Modal>
            ) : null}
        </Fragment>
    );
};

// const ReasonsLabel = styled.div`
//     font-size: 1.125rem;
//     letter-spacing: -0.2px;
//     font-weight: 700;
//     color: #333333;
//     margin-bottom: 0.25rem;
// `;
const WrapperDiv = styled.div`
    /* margin-top: 1rem; */
    margin-bottom: 2rem;
    max-width: 75%;
`;
const ButtonDiv = styled((props) => <FlexedDiv {...props} />)`
    height: 3rem;
    max-width: 25vw;
    margin-top: 3rem;
    margin-bottom: 4.56rem;
`;
// const DateLabel = styled.div`
//     margin-left: 0.5rem;
//     font-weight: 700;
//     font-size: 0.75rem;
//     line-height: 1.5rem;
//     color: #333333;
//     opacity: 0.5;
// `;
export const StyledDivider = styled.div`
    height: 1px;
    mix-blend-mode: normal;
    opacity: 0.1;
    border: 1px solid #333333;
    margin: 2rem -2vw 2rem -4.2vw;
    width: auto !important;
`;
const StyledDividerContentWrapper = styled.div`
    height: 1px;
    mix-blend-mode: normal;
    opacity: 0.1;
    border: 1px solid #333333;
    width: auto !important;
`;
const LineDiv = styled.span`
    width: 80%;
    height: 1px;
    background-color: #66798e;
`;
const Title = styled((props) => <FlexedDiv {...props} />)`
    font-weight: 800;
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
`;
const ApprovalBanner = styled((props) => <FlexedDiv {...props} />)`
    background: #fefaf2;
    border: 1px solid #e89700;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 1.5rem;
    align-items: flex-start;
    margin-bottom: 1rem;
`;
const Subtitle = styled.div`
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
`;
const Bold = styled.span`
    font-weight: 700;
`;

const SectionBody = styled.div`
    padding: 1rem 2.5rem 1.75rem;
`;

const InputWrap = styled.div<InputWrapProps>`
    width: 100%;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '360px')};
    margin-right: 1rem;
    margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
    margin-top: ${(props) => (props.mt ? props.mt : '0px')};
`;
const Grouped = styled.div``;
const GroupedHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
`;

const CollapseWrapper = styled.div<IOnPropStyles>`
    box-shadow: ${(props) => (props.removeBorder ? `inset 0px 1px 0px #66798e` : `unset`)};
    padding-top: ${(props) => (props.removeBorder ? '1rem' : '0')};
    margin-top: 1rem;
`;
const TransactionRefList = styled.ul`
    margin: 0;
    list-style-position: inside;
    padding: 0px 8px;
    font-weight: 400;
    font-size: 0.75rem;
    color: #333333;
    max-height: 9.5rem;
    overflow: auto;
`;

const ToggleWrap = styled.div<ToggleWrapProps>`
    background-color: #ffffff;
    margin-bottom: ${(props) => (props.expanded ? '0rem' : '0.75rem')};
    /* box-shadow: 0px -1px 0px 0px #333333 inset; */
    padding: 0.25rem 2rem;
    margin-top: 0.75rem;
`;

const MultiSelectCardWrap = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`;

const StyledInner = styled.div<StyledInnerProps>`
    margin-left: 2.5rem;
    padding-bottom: ${(props) => props.paddingBottom}px;
`;
const DocumentCategoryWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 0.5rem;
`;
const LineBlock = styled.div`
    flex-grow: 1;
    border: 1px solid #c6cbd4;
`;
export default RerouteTransaction;
