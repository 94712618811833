interface IBatches {
    batchTime: string;
    count: string;
}
export const makeBatchTimeTags = (batches: IBatches[], tags: IDashboardFilterTags[]): IBatchTimeTagsProps => {
    const temp: IBatchTimeTagsProps = {
        batchCount: [],
        batchTags: [...tags],
    };
    const tempBatchTags: IDashboardFilterTags[] = [];
    batches.length !== 0
        ? batches.map((batch, index) => {
              const obj: IDashboardFilterTags = {
                  index: index,
                  title: batch.batchTime,
                  active: false,
              };
              tempBatchTags.push(obj);
              temp.batchCount.push(parseInt(batch.count));
          })
        : null;

    if (temp.batchTags.length < tempBatchTags.length) {
        if (temp.batchTags.length !== 0) {
            tempBatchTags.map((item, i) => {
                if (temp.batchTags[i] !== undefined && item.title !== undefined) {
                    item.title !== temp.batchTags[i].title ? (temp.batchTags[i] = item) : null;
                } else {
                    temp.batchTags.push(item);
                }
            });
        } else temp.batchTags = tempBatchTags;
    }
    return temp;
};
